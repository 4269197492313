
import React from 'react';
import img_encuesta from '../../../assets/img/512PX/ico_message_encuesta.png';
import img_encuesta_child from '../../../assets/img/126PX/icon_message_child.png';
import '../QualityMessage_Encuestas/style.scss';



export const Quiality_Messages_Page = ({valor = "cerrada",cxNumber}) => {

  const tipoValor = valor;
/*   console.log(tipoValor); */

  


  return (
    <div className='precontainer-qlt'>
      <div className="qlt-general">

        <div className="qlt-images">
          <img  oncl className="message-img" src={img_encuesta} alt="" />
          <img className="message-img-child" src={img_encuesta_child} alt="" />
        </div>
        <p className="Quality_finish-subtitle">{`ENCUESTA ${tipoValor == 'cerrada' ? "CERRADA" : "GUARDADA"}`}</p>
        <p className={"QualityAtq-number"}>CX #6</p>

        <div className='Quality-Messa-Button'>
          <button className='peson-button'>
            <span>OK</span>
          </button>

        </div>
      </div>
    </div>

  )
}
