import React, { useState } from 'react';
import { Mess_medics_req_der } from '../Messenger/MedicsRequirements/Mess_medics_req_der';
import { Mess_medics_Req_Izq_Comp } from '../Messenger/MedicsRequirements/Mess_medics_Req_Izq_Comp';
import './styleRequeriments.scss';

export const QualityMagerMedicsRequeriments = () => {
    const [selectedMedics, setSelectedMedics] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [list1, setList1] = useState('1');  
  return (
    <div className="MedicsHome-principal-container1">
         <div className={"MedicsHome-main-page-container1"}>
            <Mess_medics_Req_Izq_Comp  
                selected={selectedMedics} 
                setSelected={setSelectedMedics} 
                list1={list1}
                setList1={setList1}
            />
        <div className={"MedicsHome-right-bar1"}>
            <Mess_medics_req_der
                setSelected = {setSelectedMedics}
                selected = {selectedMedics}
                page = {'medicos'}
                selectedMedics = {selectedMedics}
                currentPage = {currentPage}
                setList1={setList1}
                readonlyPage={1}
            />
        </div>
    </div>
</div>
  );
};
