import shortid from 'shortid';

export const ATQModalData = [
    {
      id: shortid.generate(),
      name:'Sin ATQ'
    },
    {
      id: shortid.generate() ,
      name:'Asignar ATQ'
    },
]