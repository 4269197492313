import React from 'react';
import checkImgButton from '../../../assets/img/126PX/Icono_Check_126px.png';
import alertImgButton from '../../../assets/img/126PX/Icono_Warning_126px.png';
import errorImgButton from '../../../assets/img/126PX/Icono_Advertencia_126px.png';
import './sass/styles.scss';

export const StoreHouseStockRigthIconografia = () => {
    return (
        <div className={'storeHouseApproveOrderScreen-section-rigth-iconografia'}>
            <div className="storeHouseApproveOrderScreen-section-rigth-iconografia-subcontainer">
                <img src={checkImgButton} alt="" className="storeHouseApproveOrderScreen-section-rigth-iconografia-img" />
                <h3 className="storeHouseApproveOrderScreen-section-rigth-iconografia-title">Material Disponible</h3>
            </div>
            <div className="storeHouseApproveOrderScreen-section-rigth-iconografia-subcontainer">
                <img src={alertImgButton} alt="" className="storeHouseApproveOrderScreen-section-rigth-iconografia-img" />
                <h3 className="storeHouseApproveOrderScreen-section-rigth-iconografia-title">Poco Material Disponible</h3>
            </div>
            <div className="storeHouseApproveOrderScreen-section-rigth-iconografia-subcontainer">
                <img src={errorImgButton} alt="" className="storeHouseApproveOrderScreen-section-rigth-iconografia-img" />
                <h3 className="storeHouseApproveOrderScreen-section-rigth-iconografia-title">Material No Disponible</h3>
            </div>
        </div>
    )
}
