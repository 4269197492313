import React, { useState } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseStockLeftButtons } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockLeftButtons';
import '../../../pages/StoreHouseScreen/StoreHouseStockScreen/sass/styles.scss';
import { StoreHouseRigthComponentReception } from '../../../components/StoreHouseComponents/StoreHouseStockReceptionMaterialComponents/StoreHouseRigthComponentReception';

export const StoreHouseStockReceptionMaterial = () => {
    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');
 
    

  return (
  <div className="container_storeHouseStock">
   <SubNavBarComponent title={'RECEPCIÓN Y REVISIÓN DE MATERIAL'} historyPage={`/StoreHouseMenu`} typeSearch={"Status"}
        specificType={'id'} searchActive={true} />
      <section className="storeHouseStock_container">
      <div className="storeHouseStock_container_toggle">
                    <nav className="storeHouseStock_toggle_container_buttons">
                        <button className={activeSwitch === 'ACTIVE'
                             ? "storeHouseStock_toggle_button storeHouseStock_toggle_button_left storeHouseStock_toggle_button_active "
                             : "storeHouseStock_toggle_button storeHouseStock_toggle_button_left " } onClick ={() => setActiveSwitch('ACTIVE')}>
                             PENDIENTES
                           
                        </button>
                        <button className={ activeSwitch === 'INACTIVE'
                            ? "storeHouseStock_toggle_button storeHouseStock_toggle_button_rigth storeHouseStock_toggle_button_active"
                            :"storeHouseStock_toggle_button storeHouseStock_toggle_button_rigth"} onClick ={() => setActiveSwitch('INACTIVE')}>

                           FINALIZADAS
                        </button>
                    </nav>
                </div>

      <div className=" storeHouseStock_container_elements ">

                    <StoreHouseStockLeftButtons page={"StoreHouseStockScreen"}/>

                    <StoreHouseRigthComponentReception activeSwitch={activeSwitch} />
                </div>

                </section>
  </div>
  )
}
