import React from 'react'
import { storeHomeReceivingMaterialReportIncomingRigthInfoData } from './storeHomeReceivingMaterialReportIncomingRigthInfoData';
import './sass/styles.scss';
import addImgButton from '../../../assets/img/512PX/Icono_Mas_512px.png';
import doveImg from '../../../assets/img/126PX/icon_dove_almacen.png';
import closeImgButton from '../../../assets/img/126PX/Icono_Cerrar2_126px.png';
import { Modal_Comp } from '../../../components/ATQ/ATQ_CONSUM_REPORT/Modal_Comp';
import { ATQ_Modal_CXList } from '../../../pages/ATQ/ATQ_CONSUM_REPORT/ATQ_Modal_CXList';
import { ATQ_Modify_Product } from '../../../pages/ATQ/ATQ_CONSUM_REPORT/ATQ_Modify_Product';

export const StoreHomeReceivingMaterialReportIncomingRigthTableComponent = ({setOpenModal,updateMainObject
    ,closeModal,setCxSelected,openModal,cxSelected}) => {



    return (
        <div className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-container-table">
            <div className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-precontainer-table">
                <table className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table">
                    <thead className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-head">
                        <tr className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-tr">
                            <th className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-title">Descripción</th>
                            <th className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-title">Cantidad</th>
                            <th className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-title">ID Producto</th>
                            <th className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-title shtmriric-button"></th>
                            <th className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-title shtmriric-button"></th>
                        </tr>
                    </thead>
                    <tbody className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-body">
                    {
                        storeHomeReceivingMaterialReportIncomingRigthInfoData.map( el => (
                            <tr key={el.uid} className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-table-tr" >
                                <td className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-data" >
                                    {el.Descripción}
                                </td>
                                <td className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-data">
                                    {el.cantidad}
                                </td>
                                <td className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-data">
                                    {el.id_Producto}
                                </td>
                                <td className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-data shtmriric-button">
                                    <button className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button"> 
                                        <img className='StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button-img1' src={doveImg} alt="" />
                                    </button>
                                </td>
                                <td className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-body-data shtmriric-button">
                                    <button className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button">
                                        <img className='StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button-img' src={closeImgButton} alt=""  onClick={() =>setOpenModal('MODAL-PRODUCTS')}/>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <button className='StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-section-rigth-card-container-add-container'>
                    <img className='StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-section-rigth-card-container-add' src={addImgButton} alt="" onClick={() =>setOpenModal('MODAL-LIST')} />
                </button>

                {
                    openModal === 'OPEN' && 
                        <Modal_Comp
                                isOpen
                                toggle={closeModal}
                            />
                }
                {
                openModal === "MODAL-LIST" &&
                    <ATQ_Modal_CXList
                        isOpen
                        toggle={closeModal}
                        updateMainObject={updateMainObject}
                        setOpenModal={setOpenModal}
                        setCxSelected={setCxSelected}
                    />
                }
                {
                openModal === "MODAL-PRODUCTS" &&
                    <ATQ_Modify_Product
                        isOpen
                        toggle={closeModal}
                        updateMainObject={updateMainObject}
                        setOpenModal={setOpenModal}
                        setCxSelected={setCxSelected}
                    />
                }
                
                
            </div>
        </div>
    )
}
