import React, { useState } from 'react';
import { ATQ_Transfer_CX_List_Component } from '../../../components/ATQ/ATQ_NOTIFICATION/ATQ_Transfer_CX_List_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './style.scss';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';

export const ATQ_Trans_CX_List = () => {
    const [activeSwitch, setActiveSwitch] = useState('PENDIENTES');
    return (
        <>
        <ComingSoonComponent/>
        {/*<div className = "precontainer_page_transfer">
        <SubNavBarComponent title={'TRANSFERENCIA DE CX'} searchActive={false} historyPage={'/ATQ_Inicio'} />
            <div className="container_page_transfer">
                <div className="container_buttons_transfer">
                     <nav className="toogle_container_buttons_transfer">
                            <button className={ activeSwitch === 'PENDIENTES'
                               ? "normal_button_transfer btn_left_transfer btn_active_transfer"
                               : "normal_button_transfer btn_left_transfer"} onClick ={() => setActiveSwitch('PENDIENTES')}>
                                PENDIENTES
                            </button>
                            <button className={ activeSwitch === 'ACEPTADAS'
                               ? "normal_button_transfer btn_center btn_active_transfer"
                               : "normal_button_transfer btn_center"} onClick ={() => setActiveSwitch('ACEPTADAS')}>
                                ACEPTADAS 
                            </button>
                            <button className={ activeSwitch === 'RECHAZADAS'
                               ? "normal_button_transfer btn_rigth btn_active_transfer"
                               : "normal_button_transfer btn_rigth"} onClick ={() => setActiveSwitch('RECHAZADAS')}>
                                RECHAZADAS 
                            </button>
                     </nav>
                </div>
                <div className="section_list_transfer">
                <ATQ_Transfer_CX_List_Component
                    activeSwitch = {activeSwitch}
                />
                </div>
                
            </div>
        </div>*/}
        </>
    )
};
