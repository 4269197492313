import React from "react";
import './sass/styles.scss';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import excelLogo from '../../assets/img/512PX/excelLogo.png';
import SendIcon from '@material-ui/icons/Send';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@material-ui/core";
import { useState } from "react";

const columnsModal = [
  { id: 'box', label: '', minWidth: 20 },
  { id: 'id', label: 'ID', minWidth: 200 },
  { id: 'producto', label: 'PRODUCTO', minWidth: 200 },
  { id: 'cantidad', label: 'CANTIDAD', minWidth: 200 },
  { id: 'precio', label: 'PRECIO', minWidth: 200 },
  { id: 'descuento', label: 'DESCUENTO', minWidth: 200 },
  { id: 'total', label: 'TOTAL', minWidth: 200 },
  { id: 'icon', label: 'Ver', minWidth: 20 }
];

function createData(id, cotizacionesGuardadas, cliente, tipo, subtotal, iva, total, icon) {
  return { id, cotizacionesGuardadas, cliente, tipo, subtotal, iva, total, icon };
}

function createDataModal(id, producto, cantidad, precio, descuento, total, icon) {
  return { id, producto, cantidad, precio, descuento, total, icon };
}

const rowsPrincipal = [
  createData('CS 8201-08', 'Productos de cirugia', 'Productos de cirugia', 'Cirugias', '$2,500', '-', '$2,500', '-'),
  createData('CS 2600-6-45', 'Productas de paciente', 'Productas de paciente', 'Complementos y protección', '$1,000', '10%', '$900', '-'),
  createData('170001', 'Batas blancas', 'Batas blancas', 'Batas blacas', '$50', '-', '-', '-'),
];

const rows2 = [
  createDataModal('CS 8201-08', 'CERV-X CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '1', '1,500', '99999', '1,500'),
  createDataModal('CS 2600-6-45', 'COSMICMIA- TORNILLO D6.5 MM, LONGITUD 45MM', '50', '1,000', '10%', '900'),
  createDataModal('170001', 'CONTACT - TORNILLO DE FIJACION', '0', '50', '0', '0'),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const QuotesEdit = () => {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [openDisc, setOpenDisc] = React.useState(false);

  const handleOpenDisc = () => {
    setOpenDisc(true);
  };

  const handleCloseDisc = () => {
    setOpenDisc(false);
  };

  const [openQuote, setOpenQuote] = React.useState(false);

  const handleOpenQuote = () => {
    setOpenQuote(true);
  };

  const handleCloseQuote = () => {
    setOpenQuote(false);
  };

  const [openDownQuote, setOpenDownQuote] = React.useState(false);

  const handleOpenDownQuote = () => {
    setOpenDownQuote(true);
  };

  const handleCloseDownQuote = () => {
    setOpenDownQuote(false);
  };

  const [openSaveQuote, setOpenSaveQuote] = React.useState(false);

  const handleOpenSaveQuote = () => {
    setOpenSaveQuote(true);
  };

  const handleCloseSaveQuote = () => {
    setOpenSaveQuote(false);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const productDetail = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DialogTitle id="form-dialog-title">Chaleco tipo doctor | ID 001</DialogTitle>
      <DialogContent style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <DialogTitle id="form-dialog-title">32 disponibles</DialogTitle>
        <DialogTitle id="form-dialog-title">$100.00</DialogTitle>
        <FormControl component="fieldset">
          <TextField id="outlined-basic" label="Color" variant="outlined" style={{ marginTop: 10 }} />
          <TextField id="outlined-basic" label="Talla" variant="outlined" style={{ marginTop: 10 }} />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
        <button className="btn" style={{ width: 220 }}>Actualizar</button>
      </DialogActions>
    </div>
  );


  return (

    <div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
          <button className="btnDelete" onClick={handleOpenDelete}>
            <DeleteIcon className='c-home-c-programar-img' fontSize='small' style={{ marginRight: 10 }} />
            Eliminar Seleccionados
          </button>

          <button className="btnDisc" style={{ marginLeft: 20 }} onClick={handleOpenDisc}>
            <LocalOfferIcon className='c-home-c-programar-img' fontSize='small' style={{ marginRight: 10 }} />
            Agregar Descuento
          </button>
        </div>

        <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnsModal.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.code}>
                    {columnsModal.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {
                            column.id == 'cantidad' ?
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}>
                                <IconButton onClick={value + 1}>
                                  <RemoveIcon />
                                </IconButton>
                                <div>{value}</div>
                                <IconButton>
                                  <AddIcon />
                                </IconButton>
                              </div>
                              :
                              column.id == 'box' ?
                                <Checkbox /> :
                                column.id == 'icon' ?
                                  <VisibilityIcon onClick={toggleDrawer('right', true)} /> :
                                  column.format && typeof value === 'number' ?
                                    column.format(value) :
                                    column.id == 'producto' ?
                                      <Tooltip title={value}>
                                        <div>{value}</div>
                                      </Tooltip> : value
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell rowSpan={3} />
              <TableCell rowSpan={3} />
              <TableCell>Subtotal</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>750</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Impuesto</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>100</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>2,860</TableCell>
            </TableRow>
          </Table>
        </TableContainer>

        {/* Eliminar Seleccionado */}
        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle id="form-dialog-title">Eliminar Seleccionado</DialogTitle>
          <DialogContent style={{ alignSelf: 'center' }}>
            <DialogTitle id="form-dialog-title">¿Esta seguro de eliminar los productos seleccionados de la cotización?</DialogTitle>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btnDelete" style={{ width: 220 }}>Eliminar</button>
          </DialogActions>
        </Dialog>

        {/* Agregar Descuento */}
        <Dialog
          open={openDisc}
          onClose={handleCloseDisc}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle id="form-dialog-title">Agregar Descuento</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <div>Agrega el descuento para el o los productos seleccionados</div>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel value="5" control={<Radio color="primary" />} label="5%" />
                <FormControlLabel value="10" control={<Radio color="primary" />} label="10%" />
                <FormControlLabel value="15" control={<Radio color="primary" />} label="15%" />
              </RadioGroup>
              <TextField id="outlined-basic" label="Descuento" variant="outlined" />
            </FormControl>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btn" style={{ width: 220 }}>Agregar</button>
          </DialogActions>
        </Dialog>

        {/* Enviar Cotizacion*/}
        <Dialog
          open={openQuote}
          onClose={handleCloseQuote}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          style={{ height: '150%' }}
        >
          <DialogTitle id="form-dialog-title">Enviar Cotizacion</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <DialogTitle id="form-dialog-title">Ingresa los datos necesarios</DialogTitle>
            <FormControl component="fieldset">
              <TextField id="outlined-basic" label="Correo" variant="outlined" style={{ marginTop: 10 }} />
              <TextField
                id="outlined-textarea"
                label="Descripcion"
                placeholder="Descripcion"
                multiline
                rows={4}
                variant="outlined"
                style={{ marginTop: 10 }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btn" style={{ width: 220 }}>Agregar</button>
          </DialogActions>
        </Dialog>

        {/* Descargar Cotizacion*/}
        <Dialog
          open={openDownQuote}
          onClose={handleCloseDownQuote}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          style={{ height: '120%' }}
        >
          <DialogTitle id="form-dialog-title">Descargar Cotizacion</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: '500px'
          }}>
            <DialogTitle id="form-dialog-title">Ingresa los datos necesarios</DialogTitle>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <FormControl style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10
              }}>
                <TextField id="outlined-basic" label="Titulo del archivo * " variant="outlined" style={{ marginTop: 10 }} />
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">ATN * </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Institución</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Forma de pago</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Tiempo de entrega</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="outlined-basic" label="Correo Electronico * " variant="outlined" style={{ marginTop: 10 }} />
              </FormControl>

              <FormControl style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 10
              }}>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Fecha * </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Paciente * </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Ciudad y estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Condiciones de pago</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Validez de la oferta</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="outlined-basic" label="Numero de telefono * " variant="outlined" style={{ marginTop: 10 }} />
              </FormControl>

            </div>
          </DialogContent>
          <DialogActions style={{ alignContent: 'right', alignItems: 'right', alignSelf: 'right' }}>
            <button className="btn" style={{ width: 220 }}>Descargar PDF</button>
            <button className="btn" style={{ width: 220 }}>Descargar Excel</button>
          </DialogActions>
        </Dialog>

        {/* Guardar Cotizacion*/}
        <Dialog
          open={openSaveQuote}
          onClose={handleCloseSaveQuote}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          style={{ height: '160%' }}
        >
          <DialogTitle id="form-dialog-title">Guardar borrador</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <div>Ingresa los datos necesarios</div>
            <FormControl component="fieldset">
              <TextField id="outlined-basic" label="Nombre del borrador" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} />
              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel value="5" control={<Radio color="primary" />} label="Cotización pendiente" />
                <FormControlLabel value="10" control={<Radio color="primary" />} label="Generar pedido" />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btn" style={{ width: 220 }}>Guardar</button>
          </DialogActions>
        </Dialog>

        <React.Fragment key={'right'}>
          <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
            {productDetail('right')}
          </Drawer>
        </React.Fragment>


      </div>
      <nav className='c-quote-navbarBottom' >
        <button className="btnExcel" style={{ marginTop: 10, marginLeft: 30 }}>
          <img src={excelLogo} alt="" className='c-home-c-programar-img' width={20} height={20} style={{ marginRight: 10 }} />
          Importar
        </button>

        <button className="btnBottom" style={{ marginTop: 10, marginLeft: '50%' }} onClick={handleOpenQuote}>
          <SendIcon className='c-home-c-programar-img' fontSize='small' style={{ marginRight: 10 }} />
          COTIZACIÓN
        </button>

        <button className="btnBottom" style={{ marginTop: 10, marginLeft: 30 }} onClick={handleOpenDownQuote}>
          <SaveAltIcon className='c-home-c-programar-img' fontSize='small' style={{ marginRight: 10 }} />
          COTIZACION
        </button>

        <button className="btnBottom" style={{ marginTop: 10, marginLeft: 30 }} onClick={handleOpenSaveQuote}>
          GUARDAR BORRADOR
        </button>

      </nav>
    </div >
  );
};
