import React,{useState} from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../../../components/shared/Modal';
import './styles_Encuestas.scss';
import { QualityEncuestasModal } from '../../../components/QualityManager/QualityEncuestasModal';

export const Quality_Modal_Encuestas = () => {

    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const modalBody = () => {
        return (
        <QualityEncuestasModal   
            toggle={toggle}
            isOpen={isOpen}
           

        />
    );
    };

    return (
        <CustomModal
                isOpen={isOpen}
                toggle={toggle} 
                body={modalBody()}
        />
    );
}

Quality_Modal_Encuestas.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};
