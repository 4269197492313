import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import icoSave from '../../../assets/img/512PX/exp_rep_save.png';
import './styleMensagge.scss';

export const ATQ_exp_rep_men_sav = ({Id=23}) => {
    
  const surgerySelected = useSelector((state) => state.atqAdministrador.surgeryChosed);
  const history = useHistory();
    return (
        <div className={"Final_exp_rep_finish"}>
        <div className={"Final_exp_rep_finish_general_c"}>
          <div className={"Final_exp_rep-images"}>
             <img className="Final_exp_rep-success-img" src={icoSave} alt="" />
          </div>
                <p className={"Final_exp_rep_finish-subtitle"}>{`REPORTE DE EXPERIENCIA EN LA CX#${surgerySelected.id}`} </p>
                <p className={"Final_exp_rep_finish-subtitle"}>{'GUARDADO'} </p>
          <div className={"Final_exp_rep_order_buttons"}>
            <button className="Final_exp_rep-white-button" onClick={() => history.replace('/ATQ_Inicio/Reporte_de_experiencia')} >
              <span> ACEPTAR</span>
            </button>
          </div>
        </div>
      </div>
    )
}
