import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.sass';
import icoAgenda from '../../../assets/img/512PX/iconoAgenda512.png';
import icoRecepcion from '../../../assets/img/512PX/iconoRecepcion512.png';
import icoLogistica from '../../../assets/img/512PX/iconoLogistica512.png';
import icoSurtirCX from '../../../assets/img/512PX/Icono_SurtirCX_512px.png';
import icoPedidos from '../../../assets/img/512PX/Icono_Pedidos_512px.png';
import icoEncuestas from '../../../assets/img/512PX/Icono_Encuestas_512px.png';
import icoDisponibilidad from '../../../assets/img/512PX/Icono Disponibilidad512.png';
import icoEstatus from '../../../assets/img/512PX/Icono_Status_512px.png';



export const StoreHouseMenuScreen = () => {
    return (
        <div className='c-storeHouse-principal'>
            <div className='c-storeHouse animate__animated animate__fadeIn'>

                <div className='c-storeHouse-sec c-storeHouse-sec1' >

                         <NavLink className='c-storeHouse-item-simpleform c-storeHouse-item c-storeHouse-c-programar'
                                to={'/StoreHouse'}
                        >
                            <img src={icoSurtirCX} alt="" className='c-storeHouse-c-programar-img'/>
                            <h3 className='storeHouse-item-title'> Surtir CX</h3>
                        </NavLink>

                        
                         <NavLink className='c-storeHouse-item-horizontalform c-storeHouse-item c-storeHouse-c-facturas'
                                to={`/Encuestas`}
                        >
                            <img src={icoEncuestas} alt="" className='c-storeHouse-c-facturas-img'/>
                            <h3 className='storeHouse-item-title'> Encuestas </h3>
                        </NavLink>   


                        {/* <button className='c-storeHouse-item-horizontalform c-storeHouse-item c-storeHouse-c-facturas c-storeHouse-item-simpleform-disabled' 
                                
                        >
                            <img src={icoEncuestas} alt="" className='c-storeHouse-c-facturas-img'/>
                            <h3 className='storeHouse-item-title'> Encuestas </h3>
                        </button>  */}



                        <NavLink className='c-storeHouse-item-horizontalform c-storeHouse-item c-storeHouse-c-historial'
                                to={`/StoreHomeAbailableSystemsScreen`}
                        >
                            <img src={icoDisponibilidad} alt="" className='c-storeHouse-c-historial-img'/>
                            <h3 className='storeHouse-item-title'> Disponibilidad de equipos </h3>
                        </NavLink>

                        {/* < NavLink to={'/'}
                                className='c-storeHouse-item-simpleform c-storeHouse-item c-storeHouse-c-cotizar'
                        >
                            <img src={icoPedidos } alt="" className='c-storeHouse-c-cotizar-img'/>
                            <h3 className='storeHouse-item-title'> Pedidos </h3>
                        </NavLink> */} 

                        <button className='c-storeHouse-item-simpleform c-storeHouse-item c-storeHouse-c-cotizar disabled' disabled>
                        <img src={icoPedidos} alt="" className='c-storeHouse-c-cotizar-img'/>
                        <h3 className='storeHouse-item-title'> Pedidos</h3>
                    </button> 
                </div>

                <div className='c-storeHouse-sec c-storeHouse-sec2' >

                    <NavLink className='c-storeHouse-item-simpleform c-storeHouse-item c-storeHouse-c-pedidos' 
                    to={'/AppointmentLogistics/Logistics'}>
                        <img src={icoLogistica} alt="" className='c-storeHouse-c-pedidos-img'/> 
                        <h3 className='storeHouse-item-title'> Logistica </h3>
                    </NavLink>

                    <NavLink className='c-storeHouse-item-horizontalform c-storeHouse-item c-storeHouse-c-encuestas'
                    to={'/agenda/agenda'}>
                        <img src={icoAgenda} alt="" className='c-storeHouse-c-encuestas-img'/>
                        <h3 className='storeHouse-item-title'> Agenda </h3>
                    </NavLink>
                                            
                            
                       {/* <NavLink className='c-storeHouse-item-horizontalform c-storeHouse-item c-storeHouse-c-informacion'
                    to={'/Reception'}>
                        <img src={icoRecepcion} alt="" className='c-storeHouse-c-cotizar-img'/>
                        <h3 className='storeHouse-item-title'> Recepcion y Revision de Material </h3>
                    </NavLink>   
 */}
                    
                       <button className='c-storeHouse-item-horizontalform c-storeHouse-item c-storeHouse-c-informacion disabled' disabled>
                        <img src={icoRecepcion} alt="" className='c-storeHouse-c-cotizar-img'/>
                        <h3 className='storeHouse-item-title'> Recepcion y Revision de Material </h3>
                    </button>                       
    
                     <NavLink className='c-storeHouse-item-simpleform c-storeHouse-item c-storeHouse-c-estatus'
                    to={'/ATQAdmin_EstatusCX'}>
                        <img src={icoEstatus} alt="" className='c-storeHouse-c-estatus-img'/> 
                        <h3 className='storeHouse-item-title'> Estatus </h3>
                    </NavLink>
                    
                    {/* <button className='c-storeHouse-item-simpleform c-storeHouse-item c-storeHouse-c-estatus c-storeHouse-item-simpleform-disabled' disabled>
                        <img src={icoEstatus} alt="" className='c-storeHouse-c-estatus-img'   /> 
                        <h3 className='storeHouse-item-title'> Estatus </h3>
                    </button> */}
                </div>

            </div>
        </div>
    )
}
