

export const storeHouseApproveStatusRigthData = [
    {
        id: 1,
        name: 'Cirugía Programada'
    },
    {
        id: 2,
        name: 'Programación atendida'
    },
    {
        id: 3,
        name: 'Material listo para recogerse'
    },
    {
        id: 4,
        name: 'Material en proceso de entrega'
    },
    {
        id: 5,
        name: 'Material entregado'
    },
    {
        id: 6,
        name: 'Cirugia iniciada'
    },
    {
        id: 7,
        name: 'Cirugia finalizada'
    },
    {
        id: 8,
        name: 'Material retornado a almacén'
    },
    {
        id: 9,
        name: 'Material en proceso de revisión'
    },
    {
        id: 10,
        name: 'Cirugía facturada'
    },
]
