import React from 'react'
import PropTypes from 'prop-types';
import CustomModal from '../../shared/Modal';
import { ModalCreateTeamATQ } from './ModalCreateTeamATQ';
import { useSelector } from 'react-redux';

export const ModalCreateTeamATQComponent = (props) => {

    const listAtqs = useSelector( (state) => state.atqAdministrador.rols);

    const modalBody = () => {
        return (
            <ModalCreateTeamATQ 
            toggle={props.toggle} 
            listItems={listAtqs}   
            page = {props.page} 
            idGroup = {props.idGroup}
            atqList = {props.atqList}
            setATQList = {props.setATQList}
            changeBan1 = {props.changeBan1}
            ban1={props.ban1}
            />
        );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle}
                title="CREAR EQUIPO"
                setATQList = {props.setATQList}
                body={modalBody()}
        />
    );
}

ModalCreateTeamATQComponent.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    modalOrSystem: PropTypes.any,
    itemProductBundle: PropTypes.any,
    itemProduct: PropTypes.any,
};

