import React, { useEffect, useState } from 'react';
import './style_inf.scss';
import shortid from 'shortid';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import bigBlueBorderImage from '../../../assets/img/512PX/boy-blue-border.png';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { useHistory, useParams } from 'react-router';
import { Atq_continue_list } from '../../../components/ATQAdministrator/ATQ_Availability/Atq_continue_list';
import { Atq_Availability_Modal_asign } from './Atq_Availability_Modal_asign';
import { useDispatch, useSelector } from 'react-redux';
import { getLastAppointments,getNextAppointments} from '../../../actions/storeHouseAction';
import { reasigATQ, setSurgeryChoosed } from '../../../actions/atqAdministradorAction';


export const Atq_Availability_Inf_Page = () => {
const { activeSwitch,page } = useParams();
const dispatch = useDispatch();
const [openModal, setOpenModal] = useState(null);
const history = useHistory();

const lastSurgery = useSelector(state => state.storeHouse.lastAppointment);
const nextSurgery = useSelector(state => state.storeHouse.nextAppointment);
const atqChooseed = useSelector(state => state.atqAdministrador.ATQChosed);
const surgerySelected = useSelector( (state) => state.atqAdministrador.surgeryChosed);

const searchBy = 'BY_ID';

useEffect(() => {
    atqChooseed.lastSurgeryId &&
        dispatch(getLastAppointments(searchBy,atqChooseed.lastSurgeryId));

    atqChooseed.nextSurgeryId &&
        dispatch(getNextAppointments(searchBy,atqChooseed.nextSurgeryId));
}, []);


const status = (id) =>{

    switch (id) {
        case 1:
            return 'Cirugía Programada'
        case 2:
            return 'Programación atendida'
        case 3:
            return 'Material listo para recogerse'
        case 4:
            return 'Material en proceso de entrega'
        case 5:
            return 'Material entregado'
        case 6:
            return 'Cirugia iniciada'
        case 7:
            return 'Cirugia finalizada'
        case 8:
            return 'Material retornado a almacén'
        case 9:
            return 'Material en proceso de revisión'
        case 10:
            return 'Cirugía facturada'
        default:
            return 'descnocido'
    }

}

const toggleModal = () => {
    setOpenModal(null);
};



const handleAddAtq = () =>
{
    dispatch (reasigATQ(surgerySelected.id,atqChooseed.userID))
    dispatch(setSurgeryChoosed(surgerySelected));
    history.replace(`/ATQ_Asignado_Message`);
} 
const handleAddAtqApoyo = () =>
{
    if(surgerySelected.atq !== null){
         dispatch (reasigATQ(surgerySelected.id,surgerySelected.atq.userID,atqChooseed.userID))
    }else{
        dispatch (reasigATQ(surgerySelected.id,atqChooseed.userID))
    }
    dispatch(setSurgeryChoosed(surgerySelected));
    history.replace(`/ATQ_Asignado_Message`);
} 
const handleAddAtqChange = () =>
{
    dispatch (reasigATQ(surgerySelected.id,atqChooseed.userID))
    dispatch(setSurgeryChoosed(surgerySelected));
    history.replace(`/ATQ_Asignado_Message`);
} 
const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Última CX realizada',
            value: 'Lumbar',
        },

        {
            uid: shortid.generate(),
            title: 'Fecha de última CX',
            type: 'DATE',
            value: lastSurgery  ? lastSurgery[0].surgeryDate :'',
        },
        {
            uid: shortid.generate(),
            title: 'Estatus',
            type:  'GREEN',
            value: lastSurgery ? status(lastSurgery[0].surgeryStatusId) : '--',
        },
        {
            uid: shortid.generate(),
            title: 'PQX',
            value: lastSurgery  ? lastSurgery[0].id : '--',
        },
        {
            uid: shortid.generate(),
            title: 'Médico',
            value: (lastSurgery && lastSurgery[0].medic)  ?(`${lastSurgery[0].medic.name && lastSurgery[0].medic.name} 
                                        ${lastSurgery[0].medic.secondName && lastSurgery[0].medic.secondName}
                                        ${lastSurgery[0].medic.firstLastname && lastSurgery[0].medic.firstLastname}
                                        ${lastSurgery[0].medic.secondLastname && lastSurgery[0].medic.secondLastname}`) :'--' ,
        },
        {
            uid: shortid.generate(),
            title: 'Institución',
            value: (lastSurgery && lastSurgery[0].hospital) ? lastSurgery[0].hospital.name : '--',
        },
        {
            uid: shortid.generate(),
            title: 'Ciudad',
            value: (lastSurgery && lastSurgery[0].hospital) ? lastSurgery[0].hospital.city : '--',
        },
        {
            uid: shortid.generate(),
            title: 'Estado',
            value: (lastSurgery && lastSurgery[0].hospital) ? lastSurgery[0].hospital.state : '--',
        }
    ];
    const arrayListFieldsNext = [
        {
            uid: shortid.generate(),
            title: 'Próxima CX',
            value: 'Lumbar',
        },

        {
            uid: shortid.generate(),
            title: 'Fecha de Próxima CX',
            type: 'DATE',
            value: nextSurgery  ? nextSurgery[0].surgeryDate :'',
        },
        {
            uid: shortid.generate(),
            title: 'Estatus',
            type:  'GREEN',
            value: nextSurgery ? status(nextSurgery[0].surgeryStatusId) : '--',
        },
        {
            uid: shortid.generate(),
            title: 'PQX',
            value: nextSurgery  ? nextSurgery[0].id : '--',
        },
        {
            uid: shortid.generate(),
            title: 'Médico',
            value: (nextSurgery && nextSurgery[0].medic)  ?(`${nextSurgery[0].medic.name && nextSurgery[0].medic.name} 
                                        ${nextSurgery[0].medic.secondName && nextSurgery[0].medic.secondName}
                                        ${nextSurgery[0].medic.firstLastname && nextSurgery[0].medic.firstLastname}
                                        ${nextSurgery[0].medic.secondLastname && nextSurgery[0].medic.secondLastname}`) :'--' ,
        },
        {
            uid: shortid.generate(),
            title: 'Institución',
            value: (nextSurgery && nextSurgery[0].hospital) ? nextSurgery[0].hospital.name : '--',
        },
        {
            uid: shortid.generate(),
            title: 'Ciudad',
            value: (nextSurgery && nextSurgery[0].hospital) ? nextSurgery[0].hospital.city : '--',
        },
        {
            uid: shortid.generate(),
            title: 'Estado',
            value: (nextSurgery && nextSurgery[0].hospital) ? nextSurgery[0].hospital.state : '--',
        }
    ];
    
    const handleAceptar = () =>{
        history.replace(`/ATQAdministrator_atq_availability`);
    }

    return (
        <div className="precontainer_Atq_Inf">

        {
            page === 'asignarcx' &&
            <SubNavBarComponent title={'ASIGNAR ATQ'} searchActive={false} historyPage={'/ATQAdministrator_atq_availability'}/>
        }
        {
            page === 'asignar' &&
            <SubNavBarComponent title={'ASIGNAR ATQ'} searchActive={false} historyPage={'/ATQAdmin_Disponibilidad/asignar'}/>
        }
        {
            (page === 'apoyo' || page === 'cambiar')  && 
            <SubNavBarComponent title={'ASIGNAR ATQ'} searchActive={false} historyPage={'/ATQAdmin_Disponibilidad/apoyo'}/>
        }
        {/* {
            (page === 'asignar2')  && 
            <SubNavBarComponent title={'ASIGNAR ATQ'} searchActive={false} historyPage={'/ATQAdmin_Disponibilidad/asignar'}/>
        } */}

            <div className="container_img">
                <div className="container_atq">
                    <div className="colum_right">

                        <div className="img_atq">
                        <img src={bigBlueBorderImage} />
                        </div>
                        <div className="letter">
                        {
                            activeSwitch && activeSwitch === 'ACTIVE'
                              ? <> <span className="point"></span> <span className="available"> Disponible</span> </>
                              : <>  <span className="pointred"></span> <span className="available">No Disponible</span> </> 
                        }       
                        </div>
                    </div>
                    <div className="colum_left">
                        <span className="name">{`${atqChooseed.firstName && atqChooseed.firstName} 
                        ${atqChooseed.secondName && atqChooseed.secondName} 
                       ${atqChooseed.firstLastName && atqChooseed.firstLastName} 
                        ${atqChooseed.secondLastName && atqChooseed.secondLastName}`}
                        </span>
                        <span className="phone">{atqChooseed.phone && atqChooseed.phone}</span>
                        <span className="phone">{atqChooseed.email && atqChooseed.email}</span>
                    </div>
                </div>
            </div>
            <div>
            </div>
            <div className="second_section">
                <div className="left">
                    <StoreHouseListComponent 
                                typeView={{type:'CX', title: `ÚLTIMA CX`}}
                                listFields={arrayListFields} 
                    />
                </div>
                {
                activeSwitch && activeSwitch === 'ACTIVE'
                ? <StoreHouseListComponent 
                            typeView={{type:'CX', title: `PRÓXIMA CX`}}
                            listFields={arrayListFieldsNext} 
                />
                : <Atq_continue_list/>
                }
            </div>
                {
                page === 'asignarcx' &&(
                activeSwitch && activeSwitch === 'ACTIVE' 
                    ?<div className="section-3">
                        <button className="btn_asignar" onClick={() => setOpenModal("SELECT_CX")}>
                             ASIGNAR CX
                        </button>
                    </div>
                    :<div className="section-3">
                        <button className="btn_asignar" onClick={ () => handleAceptar()}>
                             CONTINUAR
                        </button>
                    </div>
                    )
                }
                {
                page === 'asignar' &&
                    <div className="section-3">
                        <button className="btn_asignar" onClick={() => handleAddAtq()}>
                            ASIGNAR ATQ
                        </button>
                    </div>
                }
                {
                page === 'cambiar' &&
                    <div className="section-3">
                        <button className="btn_asignar" onClick={() => handleAddAtqChange()}>
                            CAMBIAR ATQ
                        </button>
                    </div>
                }
                {
                page === 'apoyo' &&
                    <div className="section-3">
                        <button className="btn_asignar" onClick={() => handleAddAtqApoyo()}>
                        ASIGNAR ATQ
                        </button>
                    </div>
                }
                {
                page === 'asignar2' &&
                    <div className="section-3">
                        <button className="btn_asignar" onClick={() => handleAddAtqApoyo()}>
                        ASIGNAR ATQ
                        </button>
                    </div>
                }

                {
                openModal === "SELECT_CX" && 
                <Atq_Availability_Modal_asign
                    isOpen={!!openModal}
                    toggle={toggleModal}
                />
                }
        </div>
    )
}
