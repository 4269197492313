import React, { useEffect, useRef, useState } from 'react'
import CustomModal from '../../shared/Modal';
import PropTypes, { element } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import './sass/styles.scss';
import arrowBlackUp from "../../../assets/img/126PX/arrow-black-up.png";
import arrowBlackDown from "../../../assets/img/126PX/arrow-black-down.png";
import {addMembersToGroupGuard, addMemberToGroupSuccess, getGroupGuard, getGroupGuardById, principalorderMembers, removeMembersToGroupGuard, reorderPrincipalOrderSuccess, setGroupSuccess, setGuardGroup} from '../../../actions/atqAdministradorAction';
import { useSelector } from 'react-redux';
import _ from "lodash";


export const ModalOrderTeamATQ = ({toggle,page='nada',idGroup=0,changeBan,ban,atqList,setATQList}) => {

    const dispatch = useDispatch();
    const [listGroup, setListGroup] = useState(0);


    const {listAtqSelectioned, listTeamGroup,teamGuard, member, listTeamGroupById  } = useSelector( state => {
        return {
            listAtqSelectioned: state.atqAdministrador.team ,
            listTeamGroup: state.atqAdministrador.group,
            teamGuard: state.atqAdministrador.teamGuard,
            member: state.atqAdministrador.member,
            listTeamGroupById: state.atqAdministrador.groupById,
        };
    });
    
    const [list,setList ]=  useState();
    
    const numberRender = useRef(null);
    const countTeamMember = useRef(0);
    let render=0;
    const [finishRender,setFinishRender] = useState(null);

    useEffect(() => {
        setList(atqList)
    }, [])
    

/*     useEffect(() => {
        if (_.isNil(list) && !_.isNil(listAtqSelectioned.atqList)) {
            setList(_.get(listAtqSelectioned,'atqList', []));
        }

    }, [listAtqSelectioned.atqList]);
     */
    
 /*    useEffect(() => {
        console.error('listAtqSelectioned',listAtqSelectioned);
        console.error('listTeamGroup',listTeamGroup);
        console.error('teamGuard',teamGuard);
        console.error('member',member);
        
    }, [listAtqSelectioned,listTeamGroup,teamGuard,member]) */
    



    useEffect(() => {
        if(ban.current === 0 && page === 'modify'){

            dispatch(getGroupGuardById(idGroup));
            changeBan();
        }else{
        }
    }, [])


    let byOrder = null;
    const orderMembers = (members) => {
        byOrder = members.slice(0);
        byOrder.sort(function(a,b) {
            return a.orderInGroup - b.orderInGroup;
        });
        
    }
    

    const handleCompare = () => {
        const idNewList = []
          list.forEach((element,index) => {     
                     idNewList.push(element.userID)
       })  

        const idOldList = [];
        const reorderAtq = [];
        listTeamGroupById[0].members.forEach((element,index) => {    
            idOldList.push(element.user.userID)    
         }) 
        const atqAdd = list.filter(element => !idOldList.includes(element.userID));
            const atqdel=(listTeamGroupById[0].members).filter(element =>  !idNewList.includes(element.user.userID));
            const atqQuedantes = (listTeamGroupById[0].members).filter(element => idNewList.includes(element.user.userID));
            orderMembers(atqQuedantes);


            byOrder.forEach((element, index) => {
                 if(element.user.userID != idNewList[index]){
                    reorderAtq.push(element);
                 }
             })
             
                //SI FUNCIONA ES PARA ELIMINAR -----------
                if(atqdel.length > 0){

                    atqdel.forEach((element, index) => {
                        dispatch(removeMembersToGroupGuard(element.id));
                        if(atqAdd.length > 0){
                            add(atqdel,atqAdd,list,reorderAtq);
                        }
                        else if(atqAdd.length === 0){
                            reorder(atqdel,list,reorderAtq)
                        }
                    })
                } else if(atqdel.length === 0){
                    if(atqAdd.length > 0){
                        add(atqdel,atqAdd,list,reorderAtq);
                    }
                    else if(atqAdd.length === 0){
                        reorder(atqdel,list,reorderAtq)
                    }
                }
            
        }

        //FUNCION PARA AGREGAR CUANDO SE ESTA MODIFICANDO
        const add = (atqdel,atqAdd,list,reorderAtq) => {
            let contador= 0;
            if(atqdel.length > 0){
                countTeamMember.current = countTeamMember.current + 1;
                    if (countTeamMember.current === atqdel.length){
                        countTeamMember.current = 0;
                        
                        list.forEach((element,index) => {     
                            if(atqAdd && contador <= atqAdd.length){
                                if(element.userID === atqAdd[contador].userID){
                                    dispatch(addMembersToGroupGuard({
                                        GuardGroupId: idGroup,
                                        OrderInGroup: index+1,
                                        User:
                                        {
                                            UserID: atqAdd[contador].userID,
                                            ATQCode: atqAdd[contador].atqCode,
                                        }
                                    }));
                                    contador = contador + 1;
                                    reorder(atqAdd,list,reorderAtq)
                                }
                            } 
                        });
                    }
    
            }else if(atqdel.length === 0){
                
                    list.forEach((element,index) => {     
                        if(atqAdd && contador <= atqAdd.length){
                            if(element.userID === atqAdd[contador].userID){
                                dispatch(addMembersToGroupGuard({
                                    GuardGroupId: idGroup,
                                    OrderInGroup: index+1,
                                    User:
                                    {
                                        UserID: atqAdd[contador].userID,
                                        ATQCode: atqAdd[contador].atqCode,
                                    }
                                }));
                                contador = contador + 1;
                                reorder(atqAdd,list,reorderAtq)
                            }
                        } 
                    });
            }

        }

        //REORDENAMIENTO DE LOS ATQ
        const reorder = (listValidate,list,reorderAtq) =>{
             if(listValidate.length > 0){
                countTeamMember.current = countTeamMember.current + 1;
                if (countTeamMember.current === (listValidate && listValidate.length)){
                    countTeamMember.current = 0;
                        dispatch(principalorderMembers({
                                Id: idGroup,
                                Members:
                                list.map( (member,index) => ({
                                    OrderInGroup: index+1,
                                    User:
                                    {
                                        UserID: member.userID,
                                    },
                            }))
                        })); 
                            finish();
                        }
                    }
                    //PARA VALIDAR QUE SI NO EXISTE NINGUNA DE LAS ANTERIORRES SOLO EL ORDEN LO HAGA
                   else if(listValidate.length === 0 && reorderAtq.length > 0 ){
                        dispatch(principalorderMembers({
                                Id: idGroup,
                                Members:
                                list.map( (member,index) => ({
                                    OrderInGroup: index+1,
                                    User:
                                    {
                                        UserID: member.userID,
                                    },
                            }))
                        })); 
                            finish();
                    }
                    else if(listValidate.length === 0 && reorderAtq.length === 0){
                    finish();
            }
        }

        //FINALIZAR MODIFICACION: ELIMINAR, AGREGAR, REORDENAR
        
        const finish = () => {
                dispatch(addMemberToGroupSuccess([]));
                dispatch(setGroupSuccess([]));
                dispatch(reorderPrincipalOrderSuccess([]));
                dispatch(removeMembersToGroupGuard([]));
                dispatch(getGroupGuard());
                setATQList([]);
                toggle(null);
            
        }
        //--------------------------------------------

    /* useEffect(() => {
        let contador = numberTeam.current
        if(page != 'modify' && contador != 1){
            numberTeam.current = numberTeam.current + 1;
                dispatch(getGroupGuard());
                setListGroup(listTeamGroupById && listTeamGroupById.length+1);
        }
    }, [listTeamGroup]) */

const nameTeam = (cantidad) => {
    dispatch(setGuardGroup(`Grupo ${cantidad+1}`));
}

const AddNewAtq = () => {
    /* teamGuard && */
    /*  console.warn('atqList',atqList) */
    //debugger
    atqList.forEach((member,index) => {
        dispatch(addMembersToGroupGuard({
            GuardGroupId: teamGuard.id,
            OrderInGroup: index+1,
            User:
            {
                UserID: member.userID,
                ATQCode: member.atqCode,
            }
        }));
        finishNew(atqList);
    });
    setFinishRender("finish"); 
}



     useEffect(() => {
        if(teamGuard && teamGuard.id && page != 'modify'){
            AddNewAtq();
        }
    }, [teamGuard]) 

    
    const finishNew = (list) => {
            countTeamMember.current = countTeamMember.current + 1;
            if (countTeamMember.current === (list && list.length)){
                countTeamMember.current = 0;

                dispatch(addMemberToGroupSuccess([]));
                dispatch(setGroupSuccess([]));
                toggle(null);
        }
    }
    

        const handleCreateTeam  = () => {
           if (page != 'modify'){
                dispatch(getGroupGuard());
                const cantidad =  listTeamGroup ? listTeamGroup.length :0
                nameTeam(cantidad);
           }
         else if(page === 'modify'){
               handleCompare();
           }
        }


    const handleSubir = (item,index) => {
            if (index != 0) {
                const arrayaux = {...list}
                for(let x=0; x<list.length; x++){
                    if(item.userID === list[x].userID){
                        const aux = list[x-1]
                        arrayaux[x-1] = item
                        arrayaux[x] = aux
                    }
                    setList(Object.values(arrayaux))
                }
            }

    }
    const handleBajar = (item,index) => {
        if (index != list.length-1) {
            const arrayaux = {...list}
            for(let x=0; x<list.length; x++){
                if(item.userID === list[x].userID){
                    const aux = list[x+1]
                    arrayaux[x+1] = item
                    arrayaux[x] = aux
                }
                setList(Object.values(arrayaux))
            }
        }
    }
        const handleAtn = (e) => {
            e.preventDefault();
        }
/* 
        console.log("lista",list) */
        return (
            <div className="container-ModalOrderTeamATQ-all">
                <form className="container-ModalOrderTeamATQ-list-items" onSubmit={handleAtn}>
                    <div className="container-ModalOrderTeamATQ-items-scroll">

                        <div className='container-ModalOrderTeamATQ'>
                            {
                                list &&
                                list.map((item, index) => (
                                    <div key={item.userID} className="ModalOrderTeamATQ-c-item">
                                        <div className={
                                            
                                            (index + 1) % 2 == 0 ? "ModalOrderTeamATQ-c-item-numbers-position ModalOrderTeamATQ-c-item-numbers-position-first"
                                            : "ModalOrderTeamATQ-c-item-numbers-position ModalOrderTeamATQ-c-item-numbers-position-second"
                                          
                                            }>
                                            <h3 className="ModalOrderTeamATQ-c-item-numbers-position-number">{index + 1}</h3> 
                                        </div>
                                        <span className="ModalOrderTeamATQ-item-name">{item.firstName + " " + item.secondName + " " + item.firstLastName + " " + item.secondLastName }</span>
                                        <img src={arrowBlackUp} alt="" className="ModalOrderTeamATQ-item-arrow" onClick={() => handleSubir(item,index)}/>
                                        <img src={arrowBlackDown} alt="" className="ModalOrderTeamATQ-item-arrow" onClick={() =>handleBajar(item,index)}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <button className="ModalOrderTeamATQ-button" onClick={handleCreateTeam}>
                        ACEPTAR
                    </button>
                </form>
            </div>
        );
    };

ModalOrderTeamATQ.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    listItems: PropTypes.any,
    lastOpenModal: PropTypes.any,
    setOpenModal: PropTypes.any,
    setOpenModalError: PropTypes.any,
};