import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, openSurgery, setSugeryIdSelected } from '../../../actions/allUsersAction';
import imgMessenger from '../../../assets/img/126PX/imgChat.png';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import './ListSurgery.scss';

export const ListSurgery = () => {

    const startDate = '2019-01-22T00:00:00';
    const endDate = '2024-01-22T23:59:00';
    const dispatch = useDispatch();
    const offset = 15;
    const surgerys = useSelector(state => state.allUsers.appointmentsList)
    const data = useSelector(state => state.allUsers)
    const [listSurgery, setListSurgery] = useState([]);
    const search = useSelector(state => state.allUsers.searchList);
    const selectedUserId = useSelector(state => state.allUsers.selectedUserId);

    const [currentPage, setPage] = useState(0);

    useEffect(() => {
        if (data.userRolSelected === 'doctor') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId, currentPage, offset))
        } else if (data.userRolSelected === 'atq') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 2, selectedUserId, currentPage, offset))
        } else if (data.userRolSelected === 'seller') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 9, selectedUserId, currentPage, offset))
        }
    }, [currentPage])

    // useEffect(() => {
    //     if(data.userRolSelected === 'doctor'){
    //         dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId,0,offset))
    //     }

    // }, [])



    /* console.warn('listSurgery', listSurgery) */

    useEffect(() => {
        if (surgerys && surgerys.length > 0) {
            setListSurgery([...listSurgery, ...surgerys])
        }
    }, [surgerys])


    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    const handleNavigate = (id) => {
        dispatch(openSurgery(false))
        dispatch(setSugeryIdSelected(id));
        dispatch(getAppointmentsStoreHouseSuccess(null))

    }

    return (

        <div className="SesionUsers-container-search" onScroll={handleScroll}>
            {
                (!_.isNil(search) && search.length > 0)
                    ? (
                        search && search.length > 0 &&
                        search.map(item => (


                            <div key={item.id} className={"SesionUsers-preContainer-search SesionUsers-preContainer-inactive-search"} >
                                <div className="SesionUsers-info-container-search" onClick={() => handleNavigate(item.id)} >


                                    <div className="SesionUsers-container-info-search" >

                                        <p className="SesionUsers-info-Date" >{item.surgeryDate && (formatDateToPresent(item.surgeryDate))}</p>
                                        <p className="SesionUsers-info-Hour" >{item.surgeryDate && (formatHourToPresent(item.surgeryDate)) + 'hrs' + ' - '}</p>
                                        <p className="SesionUsers-info-Medic" >{(item.medic.name && item.medic.name) + ' ' +
                                            (item.medic.secondName && item.medic.secondName) + ' ' +
                                            (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                            (item.medic.secondLastname && item.medic.secondLastname)}</p>
                                        <p className="SesionUsers-info-hospital" >{(item.hospital && item.hospital.name)}</p>
                                        <p className="SesionUsers-info-id" >CX#{item.id && item.id}</p>
                                    </div>
                                    <img src={imgMessenger} alt="" className="SesionUsers-info-imgMessage" />
                                </div>
                            </div>
                        )))
                    :

                    (listSurgery &&
                        listSurgery.length > 0 &&
                        listSurgery.map(item => (
                            <div key={item.id} className={"SesionUsers-preContainer SesionUsers-preContainer-inactive"} >
                                <div className="SesionUsers-info-container" onClick={() => handleNavigate(item.id)} >


                                    <div className="SesionUsers-container-info" >

                                        <p className="SesionUsers-info-Date" >{item.surgeryDate && (formatDateToPresent(item.surgeryDate))}</p>
                                        <p className="SesionUsers-info-Hour" >{item.surgeryDate && (formatHourToPresent(item.surgeryDate)) + 'hrs' + ' - '}</p>
                                        <p className="SesionUsers-info-Medic" >{(item.medic.name && item.medic.name) + ' ' +
                                            (item.medic.secondName && item.medic.secondName) + ' ' +
                                            (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                            (item.medic.secondLastname && item.medic.secondLastname)}</p>
                                        <p className="SesionUsers-info-hospital" >{(item.hospital && item.hospital.name)}</p>
                                        <p className="SesionUsers-info-id" >CX#{item.id && item.id}</p>
                                    </div>
                                    <img src={imgMessenger} alt="" className="SesionUsers-info-imgMessage" />
                                </div>
                            </div>
                        )))
            }

        </div>
    )
}
