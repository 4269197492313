import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAppointmentsStoreHouseSuccessAtq } from '../../../actions/storeHouseAction';
import { Asignar_List_Component } from '../../../components/ATQAdministrator/ATQ_CX_ASIGNAR_ATQ/Asignar_List_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { StoreHouseStockLeftButtons } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockLeftButtons';

import './styleMenu.scss';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';

export const ATQAdmin_CX_ASIGNAR_ATQ_Page = () => {
    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');
    const distpatch = useDispatch();

    useEffect(() => {
        return () => {
            distpatch(getAppointmentsStoreHouseSuccessAtq(null));
        }
    }, [])

    
    useEffect(() => {
            distpatch(getAppointmentsStoreHouseSuccessAtq(null));
    }, [activeSwitch])

    return (
        <>
        <ComingSoonComponent/>
        {/*<div className="container_menu_asignar">

        { activeSwitch === "INACTIVE" &&

            <SubNavBarComponent 
            title={'ASIGNAR ATQ'}  
            historyPage={'/Inicio'} 
            searchActive={true}   
            typeSearch= {'AssigmentATQ'}
            specificType = {'id'}
            />
        }


        { activeSwitch === "ACTIVE" &&

            <SubNavBarComponent 
            title={'ASIGNAR ATQ'}  
            historyPage={'/Inicio'} 
            searchActive={true}   
            typeSearch= {'NoAssigmentATQ'}
            specificType = {'id'}
            />
        }

            <section className="menu_asignar_container">
                <div className="menu_asignar_container_toggle">
                    <nav className="menu_asignar_container_buttons">
                        <button className={ activeSwitch === 'ACTIVE'
                                ? "normal_button btn_left btn_active"
                                : "normal_button btn_left"} onClick ={() => setActiveSwitch('ACTIVE')}>
                                    CX SIN ASIGNAR ATQ
                        </button>
                        <button className={ activeSwitch === 'INACTIVE'
                               ? "normal_button btn_rigth btn_active"
                               : "normal_button btn_rigth"} onClick ={() => setActiveSwitch('INACTIVE')}>
                                    CX CON ATQ ASIGNADO
                        </button>
                    </nav>
                </div>

                 <div className="menu_asignar_container_elements">

                    <StoreHouseStockLeftButtons page={"ATQAdmin_CX_ASIGNAR_ATQ_Page"}/>
                    <Asignar_List_Component
                        switch={activeSwitch}
                       

                    />
                </div> 
            </section>

        </div>*/}
        </>
    )
}
