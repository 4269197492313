import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    getAppointmentsStoreHouse,
    getAppointmentsStoreHouseSuccess,
    getInstitutions, getMedicsList,
    getPatients,
    getSearchATQ,
    getSearchList,
    getSearchSuccess,
    getUsersByRoleATQId,
    getUsersByRoleIdATQSuccess,
    searchATQSuccess,
    searchHospital,
    searchHospitalSuccess,
    searchMedics,
    searchPatients,
    searchPatientsSuccess,
    searchUserSuccess
} from '../../../../../actions/allUsersAction';
import icoBuscarGris from '../../../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import { routesFilt, SearchTypes } from '../../Vars';
import './SearchUser.scss'

export const SearchUser = () => {
    const [searchUser, setSearchUser] = useState('');
    const dispatch = useDispatch();
    const [busqueda, setBusqueda] = useState(null)
    const data = useSelector(state => state.allUsers);
    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';
    const selectedUserId = useSelector(state => state.allUsers.selectedUserId);
    const option = useSelector(state => state.allUsers.optionsSelected);



    useEffect(() => {
        if (busqueda !== null) {
            dispatch(searchGroupSurgery(busqueda))
        }
    }, [busqueda])


    const handleSearch = (e) => {

        e.preventDefault();

        if (data.userRolSelected === 'doctor') {
            if (searchUser === '') {
               // dispatch(getMedicsList(0, 100, routesFilt[data.userRolSelected]));
                dispatch(searchUserSuccess([]))
            } else {
                dispatch(searchMedics(searchUser))
            }
            setSearchUser('');

        } else if (data.userRolSelected === 'institutions') {
            if (searchUser === '') {
                //dispatch(getInstitutions(0, 100));
                dispatch(searchHospitalSuccess([]))
            } else {
                dispatch(searchHospital(searchUser))
            }
            setSearchUser('');

        } else if (data.userRolSelected === 'patients') {
            if (searchUser === '') {
                //dispatch(getPatients(0, 100));
                dispatch(searchPatientsSuccess([]))
            } else {
                dispatch(searchPatients(searchUser))
            }
            setSearchUser('');

        } 
        //else if (data.userRolSelected === 'doctor' && option === 5) {
        //     if (searchUser === '') {
        //         //dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId));
        //         dispatch(getSearchSuccess([]))
        //     } else {
        //         dispatch(getSearchList(startDate, endDate, 15, searchUser))
        //         //dispatch(getAppointmentsStoreHouseSuccess([]))
        //     }
        //     setSearchUser('');

        // }
         else if (data.userRolSelected === 'atq') {
            if (searchUser === '') {
               //dispatch(getUsersByRoleATQId(5))
                dispatch(searchATQSuccess([]))
            } else {
                dispatch(getSearchATQ("ATQ",searchUser))
                //dispatch(getUsersByRoleIdATQSuccess([]))

            }
            setSearchUser('');
        } else if (data.userRolSelected === "adminAtq") {
            if (searchUser === '') {
               //dispatch(getUsersByRoleATQId(5))
                dispatch(searchATQSuccess([]))
            } else {
                dispatch(getSearchATQ("AdminATQ",searchUser))
                //dispatch(getUsersByRoleIdATQSuccess([]))

            }
            setSearchUser('');
        }else if (data.userRolSelected === "seller") {
            if (searchUser === '') {
               //dispatch(getUsersByRoleATQId(5))
                dispatch(searchATQSuccess([]))
            } else {
                dispatch(getSearchATQ("Sales",searchUser))
                //dispatch(getUsersByRoleIdATQSuccess([]))

            }
            setSearchUser('');
        }


    };

    //console.error('aaa', searchUser)

    return (
        <form onSubmit={handleSearch} className="SearchUserComponent">
            <button className="SearchUserComponent-search-icobuscar" type='submit'>
                <img className='img-search-icon' src={icoBuscarGris} alt="" />
            </button>
            <input
                type="text"
                placeholder={`${'Buscar'} ${SearchTypes[data.userRolSelected]}${'...'}`}
                name="search"
                onChange={({ target }) => setSearchUser(target.value)}
                value={searchUser}
                className="SearchUserComponent-tools-input-search"
                autoComplete="off"
            />
        </form>
    )
}

