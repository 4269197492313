import React from 'react'
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import ico_close from '../../../assets/img/126PX/ico_analisis_close.png';
import ico_open from '../../../assets/img/126PX/ico_analisis_open.png';
import icon_searchA from '../../../assets/img/126PX/Icono_Buscar_Azul_126px.png';
import icon_arrow from '../../../assets/img/126PX/black_row.png';
import '../QualityManager_Analisis/style.scss';
import { useHistory } from 'react-router-dom';

const list_encuestas_analisis = [
    {
        icon: ico_open,
        date: '09/06/21 - 13:00 hrs',
        name: '-Dr. Juan Perez Coronado',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },

    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #01'
    },

    {
        icon: ico_open,
        date: '01/01/21 - 13:00 hrs',
        name: '-Dr. Leo Perez Guzman',
        hospital: '-Hospital San Jacinto ',
        cx: '-CX #06'
    },

    {
        icon: ico_close,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Perez Coronado',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },


    {
        icon: ico_close,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Perez Coronado',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #03333'
    },
    {
        icon: ico_open,
        date: '07/02/21 - 13:00 hrs',
        name: '-Dr. Juan Diego Duran',
        hospital: '-Hospital San Javier ',
        cx: '-CX #0'
    },

];



export const Quality_Analisis_Page = () => {

    const history = useHistory();

    const handleNivagateToApproveOrder = (appointment) => {
        
            history.replace('/Quality_Details_Analisis');
            
    }


    return (

        <div className='precontainer-analisis'>
            <SubNavBarComponent title={'Analisis de encuestas'} searchActive={true} historyPage={'/Quality_Menu'} />
            <div className='container-analisis'>
                <div className='analisis-header'>
                    <div className='cont-left'>
                        <div className='cont-fecha'>
                             <span className='span-cx'>Fecha CX</span> 
                            <select className='select-1'>
                                <option selected="true" disabled="disabled">MM</option>
                                <option value="saab">01</option>
                                <option value="vw">01</option>
                                <option value="audi">03</option>
                            </select>
                        </div>

                        <select className='select-2'>
                            <option selected="true" disabled="disabled">YYYY</option>
                            <option value="saab">Enero</option>
                            <option value="vw">Febrero</option>
                            <option value="audi">Marzo</option>
                        </select>

                        <img src={icon_searchA} className="img-searchs" />

                    </div>

                    <div className='cont-rigth' >
                        <div className='cont-close'>
                            <img src={ico_close} className="img-close" />
                            <p>Encuesta cerrada</p>
                        </div>
                        <div className='cont-open'>
                            <img src={ico_open} className="img-open" />
                            <p>Encuesta cerrada</p>
                        </div>
                    </div>
                </div>


                <div className='container-analisis-list'>
                    {
                        list_encuestas_analisis.map((list) => (
                            <div className='analisis-list' onClick={() => handleNivagateToApproveOrder(list)}>
                                <div className='list'>
                                    <img src={list.icon} className='icon' />
                                    <p className='list-p'>{list.date}</p>
                                    <p className='list-p'>{list.name}</p>
                                    <p className='list-p'>{list.hospital}</p>
                                    <p className='list-p'>{list.cx}</p>
                                </div>
                                <img src={icon_arrow} className='icon-arrow' />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
