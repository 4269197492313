import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
// import './DoctorsLeftBar.sass';
import ListItem1 from '../../../../../components/shared/Molecules/ListItem1';
import grayBorderImage from '../../../../../assets/img/512PX/Logo_TSI_512PX.jpg';
// import grayBorderImage from '../../../../../assets/img/126PX/boy-border-gray.png';
import {
    getAppointmentsStoreHouseSuccess,
    getChatGroup,
    getInstitutions,
    getInstitutionsSucess,
    getMedicsList,
    getMedicsListSuccess,
    getMessagesToChatGroup,
    getPatients,
    getPatientsParams,
    getSearchSuccess,
    getUsersByRoleATQId,
    getUsersByRoleId,
    getUsersByRoleIdAdmin,
    getUsersByRoleIdAdminSuccess,
    getUsersByRoleIdATQSuccess,
    getUsersByRoleIdStorer,
    getUsersByRoleIdStorerSuccess,
    getUsersByRoleIdSuccess,
    get_chat_group_success,
    openSurgery,
    optionIdSuccess,
    searchATQSuccess,
    searchHospitalSuccess,
    searchPatientsSuccess,
    searchUserSuccess,
    setSugeryIdSelected,
    setSurgeryIdSuccess,
    setUserIdSelected,
    setUserIdSuccess,
    setUserRolSelectedSuccess
} from '../../../../../actions/allUsersAction';

import { routesFilt, rolsTypes } from '../../Vars';
import PropTypes from 'prop-types';
import icon_atras from '../../../../../assets/img/126PX/Icono_atras_126px.png'
// STYLES 
import './UserLeftBar.scss';
import { useParams, withRouter } from 'react-router-dom';

import { SearchUser } from './SearchUser';
import { ListSurgery } from '../../../../../components/shared/Molecules/ListSurgery';
import { GroupSurgery } from '../../../../../components/shared/Molecules/GroupSurgery';
import { SearchChatComponent } from '../../../../../components/ChatComponent/SearchChatComponent';
import { SearchChatGroupComponent } from '../../../../../components/ChatComponent/SearchChatGroupComponent';


const UserLeftBar = ({ selectedUser, setSelectedUser }) => {



    const dispatch = useDispatch()
    const data = useSelector(state => state.allUsers);
    const userMedics = useSelector(state => state.allUsers.getMedicsList);
    const institutions = useSelector(state => state.allUsers.getInstitution);
    const patients = useSelector(state => state.allUsers.Patient)
    const atq = useSelector(state => state.allUsers.listUsersByRoleId)
    const atqAdmin = useSelector(state => state.allUsers.listUsersAdmin)
    const storer = useSelector(state => state.allUsers.listUsersStorer)
    const atq2 = useSelector(state => state.allUsers.listUsersATQ)
    const optionSelected = useSelector(state => state.allUsers.optionsSelected)
    const [list, setList] = useState(null);
    const types = ['doctor', 'seller', 'institutions', 'patients', 'atq', 'adminAtq', 'storer']
    const userID = ['atq', 'adminAtq', 'seller', 'storer']
    const openSurgey = useSelector(state => state.allUsers.openSurgery)
    const idSurgery = useSelector(state => state.allUsers.selectedSurgeryId);
    const chatId = useSelector(state => state.allUsers.selectedChatId);
    const otherTypes = ['atq', 'adminAtq', 'seller', 'storer'];
    const infoListComplete = useRef();
    const search = useSelector(state => state.allUsers.searchUser)
    const searchHospital = useSelector(state => state.allUsers.searchHospitals)
    const searchPatients = useSelector(state => state.allUsers.searchPatients)
    const surgery = useSelector(state => state.allUsers.appointmentsList)
    const userSelect = useSelector(state => state.allUsers.selectedUserId)
    const searchATQ = useSelector(state => state.allUsers.searchATQ)
    const selectedSurgeryId = useSelector(state => state.allUsers.selectedSurgeryId)


    //Scroll
    const [listDoctors, setListDoctors] = useState([])
    const [listInstitutions, setListInstitutions] = useState([])
    const [listPatients, setListPatients] = useState([])
    const [currentPage, setPage] = useState(0);
    const offset = 10;
    const { id } = useParams();

    //Pertenece a los vendedores, cuando se duplicaban
    const idSellers = []
    const listOrigin = []
    const [listOriginSeller, setListOriginSeller] = useState([])





    useEffect(() => {
        list && list.map(element => {
            if (!idSellers.includes(element.userID)) {
                idSellers.push(element.userID)
                listOrigin.push(element)
                setListOriginSeller(listOrigin)
            }
        })

    }, [list])


    useEffect(() => {
        if (data.userRolSelected === 'doctor') {
            if (userMedics && userMedics.response.length > 0) {
                setListDoctors([...listDoctors, ...userMedics.response])
            }
        } else if (data.userRolSelected === 'institutions') {
            if (institutions && institutions.length > 0) {
                setListInstitutions([...listInstitutions, ...institutions])
            }
        } else if (data.userRolSelected === "patients") {
            if (patients && patients.length > 0 && !id) {
                setListPatients([...listPatients, ...patients])
            }
            else if (patients && patients !== null || patients !== undefined && id) {
                setListPatients([{ ...patients }])
            }
        }
    }, [userMedics, institutions, patients])



    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    //Peticiones al entrar
    useEffect(() => {
        if (data.userRolSelected === 'doctor' && optionSelected != 5) {
            dispatch(getMedicsList(currentPage, offset, routesFilt[data.userRolSelected]));

        } else if (data.userRolSelected === "institutions") {
            dispatch(getInstitutions(currentPage, offset));

        } else if (data.userRolSelected === "patients" && !id) {
            dispatch(getPatients(currentPage, offset));

        } else if (data.userRolSelected === 'patients' && id) {
            dispatch(getPatientsParams(id))
        }
        else if (data.userRolSelected === "adminAtq") {
            dispatch(getUsersByRoleIdAdmin(11));

        } else if (data.userRolSelected === "atq") {
            dispatch(getUsersByRoleATQId(5));

        } else if (data.userRolSelected === 'seller') {

            if (!list && !atq) {
                dispatch(getUsersByRoleId({ currentPage: 0, offset: 100, roleId: 1 }))
            }
            else if (list && !infoListComplete.current) {
                infoListComplete.current = 'begin';
                dispatch(getUsersByRoleId({ currentPage: 0, offset: 100, roleId: 6 }))
            }
            else if (!list && atq) {
                setList(atq);
                dispatch(getUsersByRoleIdSuccess(null))
            }
            else if (list && atq && infoListComplete.current === 'begin') {
                infoListComplete.current = 'complete';
                setList([...list, ...atq])
            }
        } else if (data.userRolSelected === 'storer') {
            dispatch(getUsersByRoleIdStorer(13));
        }
    }, [data.userRolSelected, currentPage, atq])


    useEffect(() => {
        if (optionSelected === 4 && surgery) {
            dispatch(getAppointmentsStoreHouseSuccess(null))
        }
    }, [userSelect])




    useEffect(() => {

        if (idSurgery) {
            dispatch(getChatGroup(idSurgery, 0, 100, true));

        }
        return () => {
            dispatch(get_chat_group_success([]))
        }

    }, [idSurgery])

    useEffect(() => {
        if (chatId) {
            dispatch(getMessagesToChatGroup(chatId, 0, 50))
        }
    }, [chatId])


    useEffect(() => {
        return () => {
            dispatch(setUserRolSelectedSuccess(null))
            dispatch(getUsersByRoleIdSuccess(null))
            dispatch(getMedicsListSuccess(null))
            dispatch(setUserIdSuccess(null))
            dispatch(optionIdSuccess(null))
            dispatch(getAppointmentsStoreHouseSuccess(null))
            dispatch(getInstitutionsSucess(null))
            dispatch(searchUserSuccess(null))
            dispatch(searchHospitalSuccess(null))
            dispatch(searchPatientsSuccess(null))
            dispatch(setSurgeryIdSuccess(null))
            dispatch(getSearchSuccess(null))
            dispatch(getUsersByRoleIdATQSuccess(null))
            dispatch(getUsersByRoleIdAdminSuccess(null))
            dispatch(getUsersByRoleIdStorerSuccess(null))
            dispatch(searchATQSuccess(null))


        }
    }, [])

    const selectedTabIdContent = (item) => {
        setSelectedUser(item)
        dispatch(setUserIdSelected(`${userID.includes(data.userRolSelected) ? item.userID : item.id}`));

        // dispatch(getUserIdSelected(item.id, [responsesFilt[data.userRolSelected]]));
        // setSelectedSeller(item)
    }

    const renderProfileCard = (item) => {
        return (
            <div className='list-user-sesions' onClick={() => selectedTabIdContent(item)}>
                {

                    <ListItem1
                        id={otherTypes.includes(data.userRolSelected) ? item.userID : item.id}
                        key={item.userID}
                        img={grayBorderImage}
                        subtitle={item.name}
                        firstLastname={item.firstLastname || item.firstLastName}
                        subtitle2={item.email}
                        selectedTabId={selectedUser}
                        name={item.firstName}
                        secondLastName={item.secondLastName}
                        secondName={item.secondName}
                        phone={item.phone} />


                }

            </div>
        );
    };

    const navigateBack = () => {
        dispatch(openSurgery(true))
        dispatch(setSugeryIdSelected(null));

        // if (data.userRolSelected === 'doctor') {
        //     dispatch(getMedicsList(currentPage, offset, routesFilt[data.userRolSelected]));
        // }

    }

    const handleListRender = (user, list,search) => {
        return (
            data.userRolSelected === user &&
                optionSelected !== 5 && !openSurgey ?
                search && search.length > 0 ?
                    search.map(renderProfileCard)
                    :
                    list && list.length > 0 &&
                    list.map(renderProfileCard)


                : openSurgey
                    ? <ListSurgery />
                    :
                    <GroupSurgery />
        )
    }


    



    return (
        <div className='general-left-containers'>

            {
                !openSurgey && selectedSurgeryId === null ?
                    <h1 className={"leftTitle"}>Directorio {rolsTypes[data.userRolSelected]}</h1>

                    : openSurgery && selectedSurgeryId === null ?
                        <h1 className={'leftTitle'}>Selecciona una cirugia</h1>

                        :

                        !openSurgey && selectedSurgeryId &&
                        <h1 className={'leftTitle'}>Selecciona un grupo</h1>
            }
            {
                !openSurgey && idSurgery ?
                    <div className={"search_container"}>
                        <img src={icon_atras} className='img_back' onClick={() => navigateBack()} />
                        {!openSurgey ?
                            <SearchChatGroupComponent
                                tipo={'sesion'} />
                            :

                            <SearchUser />
                        }
                    </div>

                    :

                    <div className={"search_container-normal"}>
                        {openSurgey ?
                            <SearchChatComponent
                                type={'sesion'} />
                            :
                            <SearchUser />

                        }
                    </div>
            }

            <div className={'profile-list'} onScroll={handleScroll} >
                {
                    data.userRolSelected === "institutions" &&
                        searchHospital && searchHospital.length > 0 ?
                        searchHospital.map(renderProfileCard)
                        :
                        listInstitutions && listInstitutions.length > 0 &&
                        listInstitutions.map(renderProfileCard)
                }
                {
                    data.userRolSelected === 'patients' &&
                        searchPatients && searchPatients.length > 0 ?
                        searchPatients.map(renderProfileCard)
                        :
                        listPatients && listPatients.length > 0 &&
                        listPatients.map(renderProfileCard)

                }

                {data.userRolSelected === 'doctor' && handleListRender('doctor', listDoctors,search)}
                {data.userRolSelected === 'seller' && handleListRender('seller', listOriginSeller,searchATQ)}
                {data.userRolSelected === 'atq' && handleListRender('atq', atq2,searchATQ)}
                {data.userRolSelected === 'adminAtq' && handleListRender('adminAtq', atqAdmin,searchATQ)}


                {
                    data.userRolSelected === 'storer' && storer && storer.length > 0 &&
                    storer.map(renderProfileCard)
                }
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}

UserLeftBar.propTypes = {
    selectedSeller: PropTypes.any,
    setSelectedSeller: PropTypes.any,
}


export default withRouter(connect(mapStateToProps)(UserLeftBar));
