import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getChatGroup,
    getChatMembers, get_chat_group_success,
    get_chat_members_success,
    get_chat_mesagges_success,
    setChatIdSelected,
    setChatIdSuccess
} from '../../../actions/allUsersAction'

import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import img_msj_group from '../../../assets/img/512PX/Icono_GroupMsj_Azul.png'
import './GroupSurgery.scss';

export const GroupSurgery = () => {


    const dispatch = useDispatch();
    const group = useSelector(state => state.allUsers.getGroup);
    const searchGroup = useSelector(state => state.allUsers.searchGroup)
    const open = useSelector(state => state.allUsers.openSurgery);

    const render = useRef(0)





    useEffect(() => {
        return () => {
            dispatch(get_chat_group_success(null))
            dispatch(get_chat_members_success(null))
            dispatch(get_chat_mesagges_success(null))
            dispatch(setChatIdSuccess(null))
        }
    }, [])



    const navigateDetails = (id) => {
        dispatch(setChatIdSelected(id))
        dispatch(getChatMembers(id))
        // dispatch(getMessagesToChatGroup(id,0,50))
    }

    return (
        <div className="sesions-container-ChatGroup">

            {
                (!_.isNil(searchGroup) && searchGroup.length > 0) ?

                    (searchGroup && searchGroup.length > 0 &&
                        searchGroup.map(item => (

                            <div className={"sesions-preContainer-ChatGroup sesions-preContainer-inactive-ChatGroup"}
                                onClick={() => navigateDetails(item.id)}>
                                <div className="sesions-info-container-ChatGroup">
                                    <img src={img_msj_group} className='img-user-chat-modal-group'></img>

                                    <div className="sesions-container-info-ChatGroup" >

                                        <p className="sesions-info-nameChat" >{item.chatGroupName && item.chatGroupName}</p>

                                    </div>
                                    <img src={black_arrow} alt="" className="sesions-info-arrow-ChatGroup" />
                                </div>
                            </div>
                        )))

                    :
                    group &&
                        group.length > 0 ?
                        group.map(item => (

                            <div className={"sesions-preContainer-ChatGroup sesions-preContainer-inactive-ChatGroup"}
                                onClick={() => navigateDetails(item.id)}>
                                <div className="sesions-info-container-ChatGroup">
                                    <img src={img_msj_group} className='img-user-chat-modal-group'></img>

                                    <div className="sesions-container-info-ChatGroup" >

                                        <p className="sesions-info-nameChat" >{item.chatGroupName && item.chatGroupName}</p>

                                    </div>
                                    <img src={black_arrow} alt="" className="sesions-info-arrow-ChatGroup" />
                                </div>
                            </div>
                        ))

                        :
                        group && 
                        group.length <= 0 && !open &&
                        <p className='group-isnull'>No cuenta con grupos </p>
            }
        </div>

    )
}
