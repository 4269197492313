import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import './styles.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentsStoreHouseAtqWithParams, getAppointmentsStoreHouseSuccessAtq, getSearchListHouseSuccessAtq } from '../../../actions/storeHouseAction';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { setSurgeryChoosed } from '../../../actions/atqAdministradorAction';

export const ATQ_List_Rep_Exp_Com = ({activeSwitch}) => {

    const history = useHistory();


    const handleClick = (item) => {
        distpatch(setSurgeryChoosed(item));
        history.replace(`/ATQ_Inicio/exp_report/Add`);
    }
    const handleNivagate = () => {
        // if(activeSwitch === "INACTIVE"){
        //     history.replace('/ATQAdministrator_Materials_History/Material_Info');
        // }
        if( activeSwitch === "ACTIVE" ){
            history.replace(`/ATQ_Inicio/Reporte_de_experiencia`);
        }
    };


    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';
    const searchBy2 = 'ATQ_EXPERIENCE_REPORT_COMPLETE';
    const searchBy = 'ATQ_EXPERIENCE_REPORT_INCOMPLETE';
    const offset=10;
    const render = useRef(0);
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState(null); 
    const distpatch = useDispatch();
    const listApoiment = useSelector(state => state.storeHouse.appointmentsListATQ);
    const user = useSelector(state => state.user.user);
    const listSearch = useSelector(state => state.storeHouse.listSearch);
    const appoimentList = useSelector(state => state.atqAdministrador.AppoimentListSpecific);


    useEffect(() => {

        return () => {
            distpatch(getSearchListHouseSuccessAtq(null))  
        }
    }, [])
    

    useEffect(() => {

        if(currentPage === 0){
            if( activeSwitch === "ACTIVE" ){
                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy,user.identity.userID)) 
                }
                if( activeSwitch === "INACTIVE" ){
                    distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy2,user.identity.userID,)) 
                }
        }else if(currentPage > 0 && listApoiment !== null) {
            if( activeSwitch === "ACTIVE" ){
                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy,user.identity.userID)) 
                }
                if( activeSwitch === "INACTIVE" ){
                    distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy2,user.identity.userID)) 
                }
        } 
    }, [currentPage])


    //ESTE ES PARA USAR EL LIST 
    useEffect(() => {
        if(list && list.length > 0 && listApoiment.length > 0){
        setList([...list,...listApoiment])
        }else if(listApoiment && listApoiment.length > 0){
        setList(listApoiment)
        }
    },[listApoiment])

    //scroll
    const handleScroll = (event) => {
          /*   const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
            if((scrollHeight-Math.round(scrollTop) - clientHeight) <= 1){
                setPage(prev => prev + 1)
            } */

    }
      //DE AQUI EN ADELANTE ES LA MODIFICACION
      
      //AQUI ES PARA CUANDO CAMBIA DE BOTON
                useEffect(() => {
                    if(render.current > 0){
                        setList(null);
                        setPage(0);
                        distpatch(getAppointmentsStoreHouseSuccessAtq([]));
                        distpatch(getSearchListHouseSuccessAtq(null))  

                        if(currentPage === 0) {
                            if( activeSwitch === "ACTIVE" ){
                                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy,user.identity.userID)) 
                            }
                            if( activeSwitch === "INACTIVE" ){
                                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy2,user.identity.userID)) 
                            }
                        }
                    }else{
                        setList(null);
                        render.current = 1;
                    }
                }, [activeSwitch])

    
    return (
        <div className="preconter_list_rep_exp" onScroll={handleScroll}>
        
        {
        !listSearch ?
        (listApoiment &&
            list  !== null &&
            list.map( item => (
            <div className="content_info_rep_exp" key={item.id} onClick={() => handleClick(item)}>
                <div className="text_info_rep_exp">
                    <span className="info_date_number">
                         <p>{ item.surgeryDate && (formatDateToPresent(item.surgeryDate) + ' - ' + formatHourToPresent(item.surgeryDate)) + ' - '}</p>
                         <p>{item.medic &&  ((item.medic.name && item.medic.name) + ' ' +
                                (item.medic.secondName && item.medic.secondName) + ' ' +
                                (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                (item.medic.secondLastname && item.medic.secondLastname))}</p>
                    </span>
                    <span className="info_date_number">
                        <p>{item.hospital && item.hospital.name}</p>
                        <p>
                        { 'ATQ ' + item.atq !== null && ((item.atq.firstName && item.atq.firstName) + ' ' + (item.atq.secondName && item.atq.secondName)
                         + ' ' + (item.atq.firstLastName && item.atq.firstLastName) + ' ' + (item.atq.secondLastName && item.atq.secondLastName) )}
                        </p>
                        <p>
                         {'CX#' + item.id}
                        </p>
                    </span>
                </div>
                    
                    <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                
            </div>
            )))

            :
            (listSearch.length > 0 &&
            listSearch.map(item => (
            <div className="content_info_rep_exp" key={item.id} onClick={() => handleClick(item)}>
                <div className="text_info_rep_exp">
                    <span className="info_date_number">
                         <p>{ item.surgeryDate && (formatDateToPresent(item.surgeryDate) + ' - ' + formatHourToPresent(item.surgeryDate)) + ' - '}</p>
                         <p>{item.medic &&  ((item.medic.name && item.medic.name) + ' ' +
                                (item.medic.secondName && item.medic.secondName) + ' ' +
                                (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                (item.medic.secondLastname && item.medic.secondLastname))}</p>
                    </span>
                    <span className="info_date_number">
                        <p>{item.hospital && item.hospital.name}</p>
                        <p>
                        { 'ATQ ' + item.atq !== null && ((item.atq.firstName && item.atq.firstName) + ' ' + (item.atq.secondName && item.atq.secondName)
                         + ' ' + (item.atq.firstLastName && item.atq.firstLastName) + ' ' + (item.atq.secondLastName && item.atq.secondLastName) )}
                        </p>
                        <p>
                         {'CX#' + item.id}
                        </p>
                    </span>
                </div>
                    
                    <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                
            </div>
            )))

        }
        </div>
    )
}


ATQ_List_Rep_Exp_Com.propTypes = {
    activeSwitch: PropTypes.any,

}
