import React,{useEffect,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import shortid from 'shortid';
import { Atq_continue_list } from '../../../components/ATQAdministrator/ATQ_Availability/Atq_continue_list';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { useParams } from 'react-router-dom';
import './style.scss';
import { getQuotationSeller } from '../../../actions/atqAdministradorAction';
import { storeHouseGetQuoteToShowInView } from '../../../actions/storeHouseAction';
import { getUserById } from '../../../actions/allUsersAction';

export const ATQAadmin_StatusCX_Info_Page = () => {

    const dispatch = useDispatch();

    const [infoSeller, setInfoSeller] = useState(null)

    const apoiment = useSelector(state => state.storeHouse.appoitmentSelected);
    const seller = useSelector(state => state.allUsers.getUserById);
    const history = useHistory();
    const {page} = useParams();


     useEffect(() => {
         
         if(  apoiment && apoiment.creatorUserId) {
             dispatch(getUserById(apoiment.creatorUserId));
         }
     }, [])

     useEffect(() => {
         if(seller) {
            const { firstName, secondName, firstLastName, secondLastName } = seller;
            const name = { firstName, secondName, firstLastName, secondLastName };
           
            setInfoSeller(name);
         }
     }, [seller])
     
    
    const handleAceptar = () => {
        history.replace(`/ATQAdmin_EstatusCX`);
    }




    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Institución',
            value: apoiment.hospital.name && apoiment.hospital.name,
        },
        {
            uid: shortid.generate(),
            title: 'Fecha y hora',
            type: 'DATE-COMPLETE',
            value: apoiment.surgeryDate && apoiment.surgeryDate,
        },
        {
            uid: shortid.generate(),
            title: 'Descripción',
            type:'DESCRIPTION',
            value: apoiment.productRequirements && apoiment.productRequirements,
        },
        {
            uid: shortid.generate(),
            title: 'Médico',
            value: apoiment.medic &&  (`${apoiment.medic.name && apoiment.medic.name} 
                                        ${apoiment.medic.secondName && apoiment.medic.secondName}
                                        ${apoiment.medic.firstLastname && apoiment.medic.firstLastname}
                                        ${apoiment.medic.secondLastname && apoiment.medic.secondLastname}`),
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value:apoiment.atq 
            ? (`${apoiment.atq.firstName && apoiment.atq.firstName} ${apoiment.atq.secondName && apoiment.atq.secondName} ${apoiment.atq.firstLastName && apoiment.atq.firstLastName} ${apoiment.atq.secondLastName && apoiment.atq.secondLastName}
            ${'/'}
            ${apoiment.secondaryATQ ? (`${ apoiment.secondaryATQ.firstName && apoiment.secondaryATQ.firstName} ${apoiment.secondaryATQ.secondName && apoiment.secondaryATQ.secondName}  ${apoiment.secondaryATQ.firstLastName && apoiment.secondaryATQ.firstLastName} ${apoiment.secondaryATQ.secondLastName && apoiment.secondaryATQ.secondLastName}`) 
            : '' }`
            )  
            : apoiment.atqNeeded === false  ? 'No requiere ATQ' : 'Sin Asignar ATQ',
        },{
            uid: shortid.generate(),
            title: 'Vendedor',
            value: infoSeller && Object.values(infoSeller).join(' '),
        }
    ];

    return (
        <div  className="precontainer_estatus">
            <SubNavBarComponent title={`ESTATUS CX`} searchActive={false} historyPage={`/ATQAdmin_EstatusCX`}/>
            <div className="container-columns">
                <section className="section-left">
                    <StoreHouseListComponent 
                                    typeView={{type:'STORE', title: `CX #${apoiment.id}`}}
                                    listFields={arrayListFields} 
                    />
                </section>
                <section className="section-rigth">
                    <Atq_continue_list/>
                </section>
            </div>
            <div className="section-3" onClick={() => handleAceptar()}>
                    <button className="btn_aceptar">
                      ACEPTAR
                    </button>
                </div>
        </div>
    )
}
