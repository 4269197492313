import React, { useState } from 'react';
import PropTypes from 'prop-types';
import icoAgenda from '../../../../assets/img/512PX/Icono_Agenda_512.png';
import icoEncuesta from '../../../../assets/img/512PX/Icono_Encuestra_512.png';
import reportConsumo from '../../../../assets/img/512PX/report_consumo512.png';
import btnDelete from '../../../../assets/img/126PX/btn_delete.png';
import btnDowload from '../../../../assets/img/126PX/btn_dowload.png';
import iconCheckCircle from '../../../../assets/img/126PX/Icon_awesome-check-circle.png'
import iconFailureCircle from '../../../../assets/img/126PX/Icon_material-error-outline.png';

export const ModalImg = (props) => {
    const images = [icoAgenda, icoEncuesta, reportConsumo, icoAgenda, icoEncuesta, reportConsumo, icoAgenda, icoEncuesta, reportConsumo];
    const [imageView, setImageView] = useState(null)
    const handleClosed = () => {
        props.toggle();
    };

    return (

        props.type && props.type === "Generate-order" ?

            props.state === true ?


                <div className='content-modal-check-circle'>
                    <img className='modal-img-check' src={iconCheckCircle}></img>
                    <p className='modal-txt-check-circle'>SE GENERO ÉXITOSAMENTE</p>
                    <button onClick={() => handleClosed()} className='btn-modal-check'>ACEPTAR</button>
                </div>

                :

                <div className='content-modal-check-circle'>
                    <img className='modal-img-check' src={iconFailureCircle}></img>
                    <p className='modal-txt-check-circle'>{props.textError && props.textError}</p>
                    <button onClick={() => handleClosed()} className='btn-modal-check'>ACEPTAR</button>
                </div>
                
            :

            <div className="c-container3">
                <div className='container-img'>
                    <img src={imageView && imageView} />
                </div>
                <div className='container-carrousel'>
                    {
                        images &&
                        images.map(item => (
                            <div
                                className={imageView === item ? 'container-img activo' : 'container-img'}
                                onClick={() => setImageView(item)}>
                                <img src={item} />
                            </div>
                        ))
                    }

                </div>
                <div className='container-btn'>
                    <img className='btn-do' src={btnDowload} />
                    <img className='btn-de' src={btnDelete} />
                </div>
            </div>
    );

};
ModalImg.propTypes = {
    toggle: PropTypes.any,
};
