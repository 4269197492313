import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchUsersSuccess } from '../../../../actions/userActions'
import { SearchComponent } from '../../../QualityManager/Components/SearchComponent'

export const AsignarRes = ({setAsignar,handleChange,value,asigar}) => {
    const user = useSelector(state => state.user.allUsers)
    const searchUserState = useSelector(state => state.user.searchUsers);
    const dispatch = useDispatch()

    useEffect(() => {
      return () => {
        dispatch(SearchUsersSuccess([]))
      }
    }, [])
    
  return (
    <div className='container-asignar'>
            <div className='body-asignar'>
                <div className='header-search'>
                        <SearchComponent/>
                </div>
                <div className='cont-sel-re'>
                    <RadioGroup  name="asignar" value={value} onChange={handleChange} className="radio_asignar">
                    {
                        searchUserState &&
                        searchUserState.length > 0 

                        ?searchUserState.map(list => (
                            <FormControlLabel className="radioButtonsAsignar" value={list.email} control={<Radio/>} 
                            label={`${list.firstName && list.firstName} ${list.secondName  && list.secondName} ${list.firstLastName  && list.firstLastName} ${list.secondLastName  && list.secondLastName}`} />
                        ))
                       :user.map(list => (
                            <FormControlLabel className="radioButtonsAsignar" value={list.email} control={<Radio/>} 
                            label={`${list.firstName && list.firstName} ${list.secondName  && list.secondName} ${list.firstLastName  && list.firstLastName} ${list.secondLastName  && list.secondLastName}`} />
                        ))
                    }
                    </RadioGroup>
                </div>
                <div className='cont-button-asignar' onClick={() => setAsignar(!asigar)}>
                    <button className='btn-asignar'>ACEPTAR</button>
                </div>
            </div>
    </div>
  )
}
