/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useRef } from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import SyncIcon from '@material-ui/icons/Sync';
import CropFreeIcon from '@material-ui/icons/CropFree';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Drawer,
  Button,
  Divider,
} from "@material-ui/core";
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import { NavLink } from "react-router-dom";

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addRemissionAsync, addRemissionDetailAsync, getLocationsAsync, getOrderBillingData, getOrderClientData, getOrderConfig, getOrderDeliveryData,
  getOrderDetails, getRemissionByIdAsync, getRemissionDetailAsync, getRemissionsByClientAsync, getRemissionsByOrderAsync, getRemissionsByProductAsync,
  getWarehouseAsync, UpdateStatusOrderDetail
} from "../../actions/userActions";
import cfdiCatalog from '../../assets/catalogs/cfdi.json'
import regimenCatalog from '../../assets/catalogs/regimen.json'
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getColors, GetTicketPDF } from "../../actions/systemsAction";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const OrderDetailsNew = (props) => {
  const requestH = useSelector((state) => state.user.request);
  const [openToasty, setOpenToasty] = React.useState(false);
  const [openToastyError, setOpenToastyError] = React.useState(false);

  const scanCodeRef = useRef(null)

  const previousOrder = props.location.state
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.user.user.identity)

  const orderInformation = useSelector((state) => state.user.getOrderDetail);
  const orderClientData = useSelector((state) => state.user.getOrderClientData);
  const orderBilling = useSelector((state) => state.user.getOrderBilling);
  const orderDelivery = useSelector((state) => state.user.getOrderDelivery);
  const orderConfig = useSelector((state) => state.user.getOrderConfig);

  const [vestConfigurations, setVestConfigurations] = useState([])

  useEffect(() => {
    if (orderConfig) {
      const newArray = mergeAndSumDuplicates(orderConfig)
      setVestConfigurations(newArray)
    }
  }, [orderConfig])

  function mergeAndSumDuplicates(inputArray) {
    const mergedArray = [];

    inputArray.forEach(item => {
      // Buscar si ya existe un objeto similar en mergedArray
      const existingItem = mergedArray.find(existing => (
        existing.idOrder === item.idOrder &&
        existing.productCode === item.productCode &&
        existing.idColor === item.idColor &&
        existing.idSize === item.idSize &&
        existing.idType === item.idType &&
        existing.productEmbroidery === item.productEmbroidery
      ));

      if (existingItem) {
        // Si existe, aumentar el quantity
        existingItem.quantity += 1;
      } else {
        // Si no existe, agregarlo al mergedArray con quantity 1
        mergedArray.push({ ...item, quantity: 1 });
      }
    });

    return mergedArray;
  }

  const { searchQuoteFinished } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [searched, setSearched] = useState("");
  const [inputValue, setInputValue] = React.useState('todos');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [stateDrawer, setStateDrawer] = useState(false);

  const [rowSelectedVest, setRowSelectedVest] = useState()

  const toggleDrawer = (row) => {
    setRowSelectedVest(row)
    setStateDrawer(!stateDrawer);
  };

  useEffect(() => {
    getWarehouse()
    getLocations()
    getColorsFunction()
  }, [])

  const getWarehouse = async () => {
    await getWarehouseAsync(requestH).then((data) => {
      setAlmacenList(data.response)
    })
  }

  const getLocations = async () => {
    await getLocationsAsync(requestH).then((data) => {
      setLocationList(data.response)
    }).catch((e) => { console.log(e) })
  }

  useEffect(() => {
    if (previousOrder) {
      dispatch(getOrderDetails(previousOrder.order.id))
      dispatch(getOrderClientData(previousOrder.order.id))
      dispatch(getOrderBillingData(previousOrder.order.id))
      dispatch(getOrderDeliveryData(previousOrder.order.id))
      dispatch(getOrderConfig(previousOrder.order.id))
    }
  }, [previousOrder])

  useEffect(() => {
    setRowsAll(groupAndSumProducts(orderInformation))
  }, [orderInformation])

  function groupAndSumProducts(productsArray) {
    if (productsArray) {
      const groupedProducts = {};

      // Iterar sobre cada objeto en el array
      for (const product of productsArray) {
        const productCode = product.product.productCode;

        // Si el producto aún no está en el objeto agrupado, crear una entrada para él
        if (!groupedProducts[productCode]) {
          groupedProducts[productCode] = {
            ...product,
            quantity: 0,
            quantityPending: 0
          };
        }

        // Sumar las cantidades y cantidades pendientes para el producto
        groupedProducts[productCode].quantity += product.quantity;
        groupedProducts[productCode].quantityPending += product.quantityPending;
      }
      // Convertir el objeto agrupado en un array de resultados
      const groupedArray = Object.values(groupedProducts);

      return groupedArray;
    }

  }

  function getRegimenByCode(arr, code) {
    const item = arr.find(item => item.code === code);
    return item ? item.value : null;
  }

  /* Drawer Client */
  const [stateDrawerClient, setStateDrawerClient] = useState(false);

  const toggleDrawerClient = () => {
    setStateDrawerClient(!stateDrawerClient);
  };

  /* Tabla Todo */
  const columnsTodo = [
    { id: 'id', label: 'ID', minWidth: '10%', align: 'left', background: 'red' },
    { id: 'producto', label: 'PRODUCTO', minWidth: '60%', align: 'left', background: 'blue' },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: '5%', align: 'center', background: 'green' },
    { id: 'surtido', label: 'SURTIDO', minWidth: '5%', align: 'center', background: 'orange' },
    { id: 'stock', label: 'STOCK', minWidth: '5%', align: 'center', background: 'purple' },
    { id: 'status', label: 'STATUS', minWidth: '10%', align: 'center', background: 'pink' },
    { id: 'eye', label: '', minWidth: '5%', align: 'center', background: 'yellow' },
  ];


  function createDataTodo(id, producto, cantidad, surtido, stock, status, eye) {
    return { id, producto, cantidad, surtido, stock, status, eye };
  }

  const rowsTodo = [
    createDataTodo('CS 8201-08', 'CERV-X-CONVEX, Altura 8MM, ANCHO 14mm, Longitud 12MM', '1', '1', '0', 'ENVIAR A COMPRAS', 'eye'),
    createDataTodo('CS 2600-6-45', 'COSMICMIA -  TORNILLO D6.5MM, LONGITUD 45MM', '50', '15', '0', 'ENVIAR A COMPRAS', 'eye'),
    createDataTodo('006', 'ADD -D 12MM, ALTURA 16-25 MM ,6°', '2', '2', '10', 'SURTIDO', 'eye'),
    createDataTodo('007', 'DIANA - IMPLANTE 19/18 MM, 30MM', '2', '0', '5', 'PENDIENTE', 'eye'),
    createDataTodo('CS 8201-08', 'CERV-X-CONVEX, Altura 8MM, ANCHO 14mm, Longitud 12MM', '1', '0', '50', 'PENDIENTE', 'eye'),
    createDataTodo('CS 2600-6-45', 'COSMICMIA -  TORNILLO D6.5MM, LONGITUD 45MM', '3', '3', '0', 'ENVIAR A COMPRAS', 'eye'),
  ];

  const [rowsAll, setRowsAll] = useState([]);

  /* Tabla Pendiente */
  const columnsPendiente = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'producto', label: 'PRODUCTO', minWidth: 100 },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: 100 },
    { id: 'stock', label: 'STOCK', minWidth: 170 },
    { id: 'status', label: 'STATUS', minWidth: 170, },
    { id: 'eye', label: '', minWidth: 170, },
  ];

  function createDataPendiente(id, producto, cantidad, stock, status, eye) {
    return { id, producto, cantidad, stock, status, eye };
  }

  const rowsPendiente = [
    createDataPendiente('007', 'DIANA - IMPLANTE 19/18 MM, 30MM', '2', '5', 'PENDIENTE', 'eye'),
    createDataPendiente('CS 8201-08', 'CERV-X-CONVEX, Altura 8MM, ANCHO 14mm, Longitud 12MM', '1', '50', 'PENDIENTE', 'eye'),
  ];

  const [rowsPending, setRowsPending] = useState(rowsPendiente);

  /* Tabla Enviados */
  const columnsEnviado = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'producto', label: 'PRODUCTO', minWidth: 100 },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: 100 },
    { id: 'stock', label: 'STOCK', minWidth: 170 },
    { id: 'status', label: 'STATUS', minWidth: 170, },
    { id: 'eye', label: '', minWidth: 170, },
  ];

  function createDataEnviado(id, producto, cantidad, stock, status, eye) {
    return { id, producto, cantidad, stock, status, eye };
  }

  const rowsEnviado = [
    createDataEnviado('CS 8201-08', 'CERV-X-CONVEX, Altura 8MM, ANCHO 14mm, Longitud 12MM', '1', '0', 'ENVIAR A COMPRAS', 'eye'),
    createDataEnviado('CS 2600-6-45', 'COSMICMIA -  TORNILLO D6.5MM, LONGITUD 45MM', '50', '0', 'ENVIAR A COMPRAS', 'eye'),
    createDataEnviado('CS 2600-6-45', 'COSMICMIA -  TORNILLO D6.5MM, LONGITUD 45MM', '3', '0', 'ENVIAR A COMPRAS', 'eye'),
  ];

  const [rowsSent, setRowsSent] = useState(rowsEnviado);

  const [openModalScan, setOpenModalScan] = React.useState(false);

  const handleOpenModalScan = () => {
    setOpenModalScan(true);
  };

  // const handleGeneratePDF = () => {
  //   setOpenModalScan(true);
  // };

  const getTicketPDFbyIdOrder = (orderId) => {
    dispatch(GetTicketPDF(orderId));
  };

  const handleCloseModalScan = () => {
    setOpenModalScan(false);
  }

  const [openModalManualScan, setOpenModalManualScan] = React.useState(false);

  const handleOpenModalManualScan = () => {
    setOpenModalManualScan(true);
  };

  const handleCloseModalManualScan = () => {
    setOpenModalManualScan(false);
  }

  /* Tabla Scan */
  const columnsEscanear = [
    { id: 'codigo', label: 'Codigo', minWidth: 100 },
    { id: 'cantidad', label: 'Cantidad', minWidth: 100 },
    { id: 'lote', label: 'Lote', minWidth: 100 },
    { id: 'pedimento', label: 'Pedimento', minWidth: 170 },
    { id: 'almacen', label: 'Almacen', minWidth: 170 },
    { id: 'ubicacion', label: 'Ubicacion', minWidth: 170, },
    { id: 'borrar', label: '', minWidth: 170, },
  ];

  function createDataEscanear(codigo, cantidad, lote, pedimento, almacen, ubicacion, borrar) {
    return { codigo, cantidad, lote, pedimento, almacen, ubicacion, borrar };
  }

  const rowsEscanear = [
    createDataEscanear('5901234123457', 2, '00321', 'Ninguno', 'Bodega 1', '00321'),
    createDataEscanear('5901234123555', 5, '00321', 'Ninguno', 'Bodega 1', '00321'),
  ];

  const [rowsScan, setRowsScan] = useState([]);

  function searchProductByCode(jsonData, productCode) {
    for (let i = 0; i < jsonData.length; i++) {
      if (jsonData[i].productCode === productCode) {
        return jsonData[i];
      }
    }
    return null;
  }

  function existProductByCode(jsonData, productCode) {
    for (let i = 0; i < jsonData.length; i++) {
      if (jsonData[i].productCode === JSON.stringify(productCode)) {
        return true;
      } else if (JSON.stringify(jsonData[i].productCode) === JSON.stringify(productCode)) {
        return true;
      }
    }
    return false;
  }

  /* Remision */

  const [productCode, setProductCode] = useState('')
  const [lote, setLote] = useState('')
  const [pedimento, setPedimento] = useState('')


  const [valueAlmacen, setValueAlmacen] = React.useState('');
  const [inputValueAlmacen, setInputValueAlmacen] = React.useState('');
  const [almacenList, setAlmacenList] = useState([])

  const handleInputValueAlmacen = (value) => {
    setInputValueAlmacen(value)
  }

  const [valueLocation, setValueLocation] = React.useState('');
  const [inputValueLocation, setInputValueLocation] = React.useState('');
  const [locationList, setLocationList] = useState([])

  const handleInputValueLocation = (value) => {
    setInputValueLocation(value)
  }

  const [scanLineCode, setScanLineCode] = useState('')

  const [localShoppingCart, setLocalShoppingCart] = useState([])

  function reemplazarCaracteres(string) {
    return string.replace(/]/g, "|");
  }

  function procesarCadena(cadena) {
    let separador = "|";
    let inicio = "@";
    let inicio2 = "";

    if (cadena.startsWith(inicio)) {
      let temporalString = reemplazarCaracteres(cadena);
      return temporalString.slice(1).split(separador);
    } else if (cadena.startsWith(inicio2)) {
      let temporalString = reemplazarCaracteres(cadena);
      return temporalString.slice(0).split(separador);
    } else {
      setOpenToastyError(true)
      return "La cadena no cumple con el formato requerido";
    }
  }

  function productCodeExist(productCode) {
    const temporalArray = structuredClone(orderInformation);
    for (let i = 0; i < temporalArray.length; i++) {
      const producto = temporalArray[i];

      let string1 = producto.product.productCode.replace(/\s/g, "");
      let string2 = productCode.replace(/\s/g, "");

      string1 = string1.replace('-', '')
      string2 = string2.replace('-', '')

      if (producto.product.productCode == productCode) {
        return producto;
      }
    }
    return false;
  }

  function productCodeExistInPreRemissionCart(productCode) {
    const temporalCart = structuredClone(rowsScan)
    for (let i = 0; i < rowsScan.length; i++) {
      const producto = temporalCart[i];

      if (producto.product.productCode == productCode) {
        return { status: true, id: i };
      }
    }
    return { status: false, id: null };
  }

  function productCodeExistAndQuantity(productCode, quantity) {
    for (let i = 0; i < orderInformation.length; i++) {
      const producto = orderInformation[i];

      if (producto.product.productCode == productCode) {
        return producto;
      }
    }
    return false;
  }

  const addToPreRemission = () => {
    const valueToAdd = productCodeExist(productCode)
    if (valueToAdd != false && valueToAdd != null) {
      const temporalCart = structuredClone(rowsScan)
      const preRemissionCart = productCodeExistInPreRemissionCart(valueToAdd.product.productCode)

      if (preRemissionCart.status) {
        if (temporalCart[preRemissionCart.id].quantity < valueToAdd.quantity)
          temporalCart[preRemissionCart.id].quantity = temporalCart[preRemissionCart.id].quantity + 1
      } else {
        if (lote && pedimento && valueAlmacen && valueLocation) {
          valueToAdd.lote = lote
          valueToAdd.pedimento = pedimento
          valueToAdd.quantity = 1
          valueToAdd.locationStorage = valueAlmacen
          valueToAdd.location = valueLocation
          temporalCart.push(valueToAdd)
        }
      }

      setRowsScan(temporalCart)
    } else {
      setOpenToastyError(true)
    }
  }

  const addToPreRemissionAutomatic = (productCode, automaticLote, AutomaticPediment) => {
    const valueToAdd = productCodeExist(productCode)
    if (valueToAdd != false && valueToAdd != null) {
      const temporalCart = structuredClone(rowsScan)
      const preRemissionCart = productCodeExistInPreRemissionCart(valueToAdd.product.productCode)

      if (preRemissionCart.status) {
        if (temporalCart[preRemissionCart.id].quantity < valueToAdd.quantity)
          temporalCart[preRemissionCart.id].quantity = temporalCart[preRemissionCart.id].quantity + 1
      } else {
        if (valueAlmacen && valueLocation) {
          valueToAdd.lote = automaticLote
          valueToAdd.pedimento = AutomaticPediment
          valueToAdd.quantity = 1
          valueToAdd.locationStorage = valueAlmacen
          valueToAdd.location = valueLocation
          temporalCart.push(valueToAdd)
        }
      }
      setRowsScan(temporalCart)
    } else {
      console.log('Entro')
      setOpenToastyError(true)
    }
  }

  function removeItemByIndex(array, index) {
    if (index > -1 && index < array.length) {
      array.splice(index, 1);
    }
    return array;
  }


  const removeFromPreRemission = (index) => {
    const temporalCart = structuredClone(rowsScan)
    const resultArray = removeItemByIndex(temporalCart, index)
    setRowsScan(resultArray)
  }

  const addRemision = async () => {
    if (rowsScan && rowsScan.length > 0) {
      const dataToSendRemission = {
        "totvsId": '1',
        "userId": loginData.userID,
        "orderId": previousOrder.order.id
      }

      await addRemissionAsync(dataToSendRemission, requestH).then((data) => {
        rowsScan.map(async (singleElement) => {
          const dataToSendRemissionDetail = {
            "id": 0,
            "remissionId": data.response.id,
            "orderDetailId": singleElement.id,
            "quantity": singleElement.quantity,
            "code": singleElement.product.productCode,
            "lot": singleElement.lote,
            "pedimento": singleElement.pedimento,
            "warehouseId": singleElement.locationStorage.id,
            "locationId": singleElement.location.id,
            "status": 0
          }

          await addRemissionDetailAsync(dataToSendRemissionDetail, requestH).then((dataDetail) => {
            console.log(dataDetail)
          })
        })
      })

      dispatch(getOrderDetails(previousOrder.order.id))
      setOpenToasty(true)
      setRowsScan([])
      handleCloseModalManualScan()

      dispatch(getOrderDetails(previousOrder.order.id))
      dispatch(getOrderClientData(previousOrder.order.id))
      dispatch(getOrderBillingData(previousOrder.order.id))
      dispatch(getOrderDeliveryData(previousOrder.order.id))
      dispatch(getOrderConfig(previousOrder.order.id))
    }
  }

  function countQuantityByCode(array, code) {
    let sum = 0;

    for (let i = 0; i < array.length; i++) {
      if (array[i].code === code) {
        sum += array[i].quantity;
      }
    }

    return sum;
  }

  function esFechaValida(fechaString) {
    var fecha = new Date(fechaString);
    return !isNaN(fecha);
  }

  const handleAddAutomatic = (scanLine, almacen, localizacion) => {
    if (scanLine)
      setScanLineCode(scanLine.target.value)
    else if (almacen)
      handleInputValueAlmacen(almacen)
    else if (localizacion)
      handleInputValueLocation(localizacion)
  }

  useEffect(() => {
    handleAddToArrayAtomatic()
  }, [scanLineCode, valueAlmacen, valueLocation])

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleAddToArrayAtomatic()
    }
  };

  const handleAddToArrayAtomatic = () => {
    if (scanLineCode && valueAlmacen && valueLocation) {
      const cadenaProcesada = procesarCadena(scanLineCode)
      if (cadenaProcesada != 'La cadena no cumple con el formato requerido') {
        setScanLineCode('')
        const pedimentoProcesado = esFechaValida(cadenaProcesada[2])
        if (pedimentoProcesado) {
          addToPreRemissionAutomatic(cadenaProcesada[0], cadenaProcesada[1])
        } else {
          addToPreRemissionAutomatic(cadenaProcesada[0], cadenaProcesada[1], cadenaProcesada[2])
        }
      }
    }
  }
  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [openerror, setOpenError] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpenConfirm = () => {
    setOpenconfirm(true);
  };

  const handleCloseconfirm = () => {
    /*  */
    dispatch(getOrderDetails(previousOrder.order.id))
    dispatch(getOrderClientData(previousOrder.order.id))
    dispatch(getOrderBillingData(previousOrder.order.id))
    dispatch(getOrderDeliveryData(previousOrder.order.id))
    dispatch(getOrderConfig(previousOrder.order.id))
    setOpenconfirm(false);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };





  const handleChange = async (id) => {
    if (id) {
      let idproducto = id.target.value.split('-')[1];
      let estatus = id.target.value.split('-')[0];

      /** EL WS A CONSUMIR ES: UpdateStatusOrderDetail */
      await UpdateStatusOrderDetail({ "id": parseInt(idproducto), "status": parseInt(estatus) }, requestH).then(resp => {
        if (resp.sucess) {
          handleClickOpenConfirm()
        } else {
          handleClickError()
        }
      });
    } else {
      setOpenError(true);
    }
  };

  const [colorList, setColorList] = useState([])

  const getColorsFunction = async () => {
    await getColors(requestH).then((data) => {
      setColorList(data.response)
    })
  }

  function getColorById(id) {
    return colorList.find(color => color.id === id);
  }



  return (
    <div>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={`/orders`} title={`Detalle Pedido`} />
      <div >

        <Snackbar
          open={openToasty}
          onClose={() => { setOpenToasty(false) }}
          message="I love snacks"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          severity="success"
        >
          <Alert severity="success">
            {`La Remisión fue generada con exito.`}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openToastyError}
          onClose={() => { setOpenToastyError(false) }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          severity="error"
        >
          <Alert severity="error">
            {`La cadena no cumple con el formato requerido`}
          </Alert>
        </Snackbar>


        {/* TopBar */}
        <div style={{ display: 'flex', background: 'transparent', marginTop: '16vh' }}>
          <div style={{ width: '70%', marginTop: '2rem' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', justifyContent: 'space-around' }}>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Tipo y número de solicitud</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{previousOrder && previousOrder.order.id} - Pedido</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Vendedor</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{previousOrder.order.sellerName || ''}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Domicilio de Entrega</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderBilling && orderBilling.billingAddress || ''}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabla All */}
        {inputValue == 'todos' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columnsTodo.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          width={column.minWidth}
                        >
                          <div style={{ display: 'flex', justifyContent: column.align }}>
                            {column.label}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsAll && rowsAll.map((row) => {
                    let infoRemissionsCount = null
                    if (previousOrder && previousOrder.remissionInformation && previousOrder.remissionInformation.details)
                      infoRemissionsCount = countQuantityByCode(previousOrder.remissionInformation.details, row.product.productCode)
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnsTodo.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align} width={column.minWidth}>
                              {
                                column.id == 'id' ? row.product.productCode :
                                  column.id == 'producto' ? row.product.productDescription :
                                    column.id == 'cantidad' ? row.quantity :
                                      column.id == 'surtido' ? infoRemissionsCount || 0 :
                                        column.id == 'stock' ? 0 :
                                          column.id == 'eye' && (orderConfig && existProductByCode(orderConfig, row.product.productCode)) ?
                                            <VisibilityIcon onClick={() => { toggleDrawer(row) }} /> :
                                            column.id == 'status' ? <div>
                                              {
                                                row.status == 0 || row.status == 1 ?
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    key={row.product.productCode}
                                                    value={row.status}
                                                    onChange={handleChange}
                                                    style={{ width: '100%' }}
                                                    displayEmpty
                                                    className={classes.selectEmpty}
                                                  >
                                                    <MenuItem value={0} style={{ display: 'none' }}><em>Pendiente</em></MenuItem>
                                                    <MenuItem value={1}><em>Pendiente</em></MenuItem>
                                                    {/*                                                     <MenuItem value={2 + '-' + row.id}><em>Surtido</em></MenuItem>
 */}                                                    <MenuItem value={3 + '-' + row.id}>Enviar a compras</MenuItem>
                                                  </Select> :
                                                  <div>
                                                    <p style={{ fontWeight: 'bold', color: row.status == 3 ? '#094070' : row.status == 2 ? '#355e10' : '#c70808' }}>{value == 1 ? 'Pendiente' : value == 2 ? 'Surtido' : value == 3 ? 'Enviar a compras' : 'Sin Status'}</p>
                                                  </div>}
                                            </div> : value
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Drawer anchor={'right'} open={stateDrawer} onClose={toggleDrawer}>
              <div style={{ margin: 20, maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px', marginTop: 20 }}>{rowSelectedVest && (rowSelectedVest && rowSelectedVest.product && rowSelectedVest.product.productCode || '')}</p>
                <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>{rowSelectedVest && (rowSelectedVest && rowSelectedVest.product && rowSelectedVest.product.productDescription || '')}</p>

                {rowSelectedVest && orderConfig && orderConfig.length > 0 && vestConfigurations && vestConfigurations.length > 0 && vestConfigurations.map((vest, index) => {
                  const colorSelected = getColorById(vest.idColor)
                  console.log(colorSelected)
                  if (rowSelectedVest && (rowSelectedVest && rowSelectedVest.product && rowSelectedVest.product.productCode == vest.productCode)) {
                    return (
                      <div style={{ marginTop: 20 }} key={index}>
                        <p style={{ fontFamily: 'arial', fontSize: '15px', marginTop: 20, fontWeight: 'bold' }}>{`CHALECO (x${vest.quantity})`}</p>

                        {
                          vest.colorName &&
                          <div style={{ display: 'flex', marginTop: 5 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '15px' }}>COLOR:</p>
                            <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>{`${vest.colorName} - (${colorSelected.code})`}</p>
                          </div>
                        }
                        {colorSelected &&
                          <img src={`https://asistenteapi.tsi.mx:8080/images/${colorSelected.url}`} alt="Logo" style={{ width: '100px', height: '50px' }} />
                        }
                        {
                          vest.sizeName &&
                          <div style={{ display: 'flex', marginTop: 5 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '15px' }}>TALLA:</p>
                            <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>{vest.sizeName}</p>
                          </div>
                        }
                        {vest.productEmbroidery &&
                          <div style={{ display: 'flex', marginTop: 5 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{vest.idType == 1 ? 'Marcado: ' : 'Bordado: '}</p>
                            <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>{vest.productEmbroidery}</p>
                          </div>
                        }
                      </div>
                    )
                  }
                })}

                {/* <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold', marginTop: 100 }}>Backorder</p>
                <Divider />

                <div style={{ display: 'flex', marginTop: 5 }}>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>19/08/2022</p>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>ENVIO DE SOLICITUD</p>
                </div>

                <div style={{ display: 'flex', marginTop: 5 }}>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>20/08/2022</p>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>SE REALIZA LA SOLICITUD AL PROVEEDOR</p>
                </div>

                <div style={{ display: 'flex', marginTop: 5 }}>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>25 /08/2022</p>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>PRODUCTOS EN CAMINO, FECHA ESTIMADA DE ENTREGA 30/08/2022</p>
                </div>

                <div style={{ display: 'flex', marginTop: 5 }}>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>30/08/2022</p>
                  <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>PRODUCTOS ENTREGADOS A ALMACEN, RECIBIO JUAN PEREZ</p>
                </div> */}



              </div>
            </Drawer>
          </div>
        }

        {inputValue == 'pendientes' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columnsPendiente.map((column) => {
                      const id = column.id
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <div style={{ display: 'flex' }}>
                            {column.label}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsPending && rowsPending.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnsPendiente.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {
                                column.id == 'eye' ?
                                  <VisibilityIcon onClick={toggleDrawer} /> :
                                  column.id == 'status' ?
                                    <p style={{ fontWeight: 'bold', color: value == 'ENVIAR A COMPRAS' ? '#094070' : value == 'SURTIDO' ? '#355e10' : '#c70808' }}>{value}</p> :
                                    value
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }

        {inputValue == 'enviado' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columnsEnviado && columnsEnviado.map((column) => {
                      const id = column.id
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <div style={{ display: 'flex' }}>
                            {column.label}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsSent && rowsSent.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnsEnviado.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {
                                column.id == 'eye' ?
                                  <VisibilityIcon onClick={toggleDrawer} /> :
                                  column.id == 'status' ?
                                    <p style={{ fontWeight: 'bold', color: value == 'ENVIAR A COMPRAS' ? '#094070' : value == 'SURTIDO' ? '#355e10' : '#c70808' }}>{value}</p> :
                                    value
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }

        {/* Drawer Client */}
        <Drawer anchor={'left'} open={stateDrawerClient} onClose={toggleDrawerClient} PaperProps={{
          sx: { width: "20%" },
        }}>
          <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontFamily: 'arial', fontSize: '30px', marginTop: 20, fontWeight: 'bold' }}>Datos del cliente</p>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Cliente</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.clientName || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Correo</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.email || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: '30px', marginLeft: '30px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Facturación</p>
            </div>

            {/* console.log(orderClientData)
            console.log(orderBilling)
            console.log(orderDelivery)
            console.log(userById) */}

            <div style={{ marginTop: '20px', marginLeft: '0px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>RFC</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderBilling ? orderBilling.billingRFC : ''}</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Uso de CFDI</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.cfdi : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Regimen Fiscal</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderBilling ? orderBilling.billingRaxRegime : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Condicion de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentCondition : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Metodo de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentMethod : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Forma de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentForm : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Numero de Recibo de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentReceipt : ''}</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Envio</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Instrucciones o referecias</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.referencesAddress : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Direccion de Envio</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderClientData ? orderClientData.address : ''}</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Obervaciones</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.warehouseMessage : ''}</p>
            </div>

          </div>
          {/* <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontFamily: 'arial', fontSize: '30px', marginTop: 20, fontWeight: 'bold' }}>Datos del cliente</p>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Cliente</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.clientName || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Correo</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.email || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Cliente de Facturación</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.clientName || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Correo de Facturación</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.email || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Uso de CFDI</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderBilling && getRegimenByCode(regimenCatalog, orderBilling.billingRaxRegime ? orderBilling.billingRaxRegime : '') || ''}</p>
              </div>
            </div>

          </div> */}
        </Drawer>

        <Dialog
          open={openModalScan}
          onClose={handleCloseModalScan}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle id="form-dialog-title"><div style={{ display: 'flex', marginBottom: 40 }}>
            <p style={{ marginRight: 30, fontWeight: 'bold' }}>Escanear</p>
          </div></DialogTitle>
          <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'column', marginRight: '100px', fontFamily: 'arial' }}>
            <div style={{ width: '100%' }}>
              <TextField
                id="outlined-basic"
                label="Línea de captura de escaneo"
                variant="outlined"
                style={{ width: '100%' }}
                defaultValue={scanLineCode}
                value={scanLineCode}
                onChange={(e) => { handleAddAutomatic(e, null, null); }}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
              <Autocomplete
                value={valueAlmacen}
                onChange={(event, newValue) => { setValueAlmacen(newValue) }}
                inputValue={inputValueAlmacen}
                onInputChange={(event, newInputValue) => { handleAddAutomatic(null, newInputValue, null) }}
                id="controllable-states-demo"
                options={almacenList}
                getOptionLabel={(option) => option ? `${option.name}` : 'Almacen'}
                style={{ width: '49%' }}
                renderInput={(params) => <TextField {...params} label="Almacen" variant="outlined" />}
              />
              <Autocomplete
                value={valueLocation}
                onChange={(event, newValue) => { setValueLocation(newValue) }}
                inputValue={inputValueLocation}
                onInputChange={(event, newInputValue) => { handleAddAutomatic(null, null, newInputValue) }}
                id="controllable-states-demo"
                options={locationList}
                getOptionLabel={(option) => option ? `${option.name}` : 'Ubicación'}
                style={{ width: '49%' }}
                renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />}
              />
            </div>
            {rowsScan && rowsScan.length > 0 &&
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        {columnsEscanear.map((column) => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              <div style={{ display: 'flex' }}>
                                {column.label}
                              </div>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsScan && rowsScan.map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columnsEscanear.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id == 'codigo' && row.product.productCode}
                                  {column.id == 'cantidad' && <TextField id="outlined-basic" variant="outlined" value={row.quantity} />}
                                  {column.id == 'lote' && row.lote}
                                  {column.id == 'pedimento' && row.pedimento}
                                  {column.id == 'almacen' && row.locationStorage.name}
                                  {column.id == 'ubicacion' && row.location.name}
                                  {column.id == 'borrar' && < DeleteIcon style={{ color: 'red' }} onClick={() => { removeFromPreRemission(index) }} />}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </div>
          <DialogContent style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'row', marginRight: '100px', fontFamily: 'arial' }}>
            <div style={{ width: '50%' }}>
              <button className="btn" style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }} onClick={() => {
                setOpenModalManualScan(true)
                setOpenModalScan(false)
              }}>CAPTURA MANUAL</button>
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
              <button className="btn" style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }}>GENERAR REMISIÓN</button>
            </div>
          </DialogContent>
          <DialogActions style={{ marginTop: 100 }}>
          </DialogActions>
        </Dialog>

        {/* DIALOG DE QUE SE HIZO EL MOVIMIENTO - ÉXITO*/}
        <Dialog
          fullScreen={fullScreen}
          open={openconfirm}
          onClose={handleCloseconfirm}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Notificación de cambio de Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se ha hecho el cambio de status con éxito.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseconfirm} color="primary" autoFocus>
              Cerrar y continuar
            </Button>
          </DialogActions>
        </Dialog>

        {/* DIALOG DE QUE SE HIZO EL MOVIMIENTO - ERROR*/}
        <Dialog
          fullScreen={fullScreen}
          open={openerror}
          onClose={handleCloseError}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Notificación de cambio de Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ha ocurrido un error, intentalo más tarde.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError} color="primary" autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        {/* FIN DIALOG DE QUE SE HIZO EL MOVIMIENTO */}
        <Dialog
          open={openModalManualScan}
          onClose={handleCloseModalManualScan}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle id="form-dialog-title"><div style={{ display: 'flex', marginBottom: 40 }}>
            <p style={{ marginRight: 30, fontWeight: 'bold' }}>Captura Manual</p>
          </div></DialogTitle>
          <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'column', marginRight: '100px', fontFamily: 'arial' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <TextField id="outlined-basic" label="Código de Producto" variant="outlined" style={{ width: '32%' }} value={productCode} onChange={(e) => { setProductCode(e.target.value) }} error={true} />
              <TextField id="outlined-basic" label="Lote" variant="outlined" style={{ width: '32%' }} value={lote} onChange={(e) => { setLote(e.target.value) }} />
              <TextField id="outlined-basic" label="Pedimento" variant="outlined" style={{ width: '32%' }} value={pedimento} onChange={(e) => { setPedimento(e.target.value) }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
              <Autocomplete
                value={valueAlmacen}
                onChange={(event, newValue) => { setValueAlmacen(newValue) }}
                inputValue={inputValueAlmacen}
                onInputChange={(event, newInputValue) => { handleInputValueAlmacen(newInputValue) }}
                id="controllable-states-demo"
                options={almacenList}
                getOptionLabel={(option) => option ? `${option.name}` : 'Almacen'}
                style={{ width: '32%' }}
                renderInput={(params) => <TextField {...params} label="Almacen" variant="outlined" />}
              />
              <Autocomplete
                value={valueLocation}
                onChange={(event, newValue) => { setValueLocation(newValue) }}
                inputValue={inputValueLocation}
                onInputChange={(event, newInputValue) => { handleInputValueLocation(newInputValue) }}
                id="controllable-states-demo"
                options={locationList}
                getOptionLabel={(option) => option ? `${option.name}` : 'Ubicación'}
                style={{ width: '32%' }}
                renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />}
              />
              <div style={{ width: '32%' }}>
                <button className="btn" style={{ width: 220, fontFamily: 'arial', fontWeight: 'bold' }} onClick={addToPreRemission}>AGREGAR</button>
              </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnsEscanear.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsScan && rowsScan.map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnsEscanear.map((column) => {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id == 'codigo' && row.product.productCode}
                                {column.id == 'cantidad' && <TextField id="outlined-basic" variant="outlined" value={row.quantity} />}
                                {column.id == 'lote' && row.lote}
                                {column.id == 'pedimento' && row.pedimento}
                                {column.id == 'almacen' && row.locationStorage.name}
                                {column.id == 'ubicacion' && row.location.name}
                                {column.id == 'borrar' && < DeleteIcon style={{ color: 'red' }} onClick={() => { removeFromPreRemission(index) }} />}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <DialogContent style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'row', marginRight: '100px', fontFamily: 'arial' }}>
            <div style={{ width: '50%' }}>
              <button className="btn" style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }} onClick={() => {
                setOpenModalManualScan(false)
                setOpenModalScan(true)
              }}>CAPTURA AUTOMATICA</button>
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
              <button className="btn" style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }} onClick={addRemision}>ENVIAR A REMISIÓN</button>
            </div>
          </DialogContent>
          <DialogActions style={{ marginTop: 100 }}>
          </DialogActions>
        </Dialog>

        <div style={{
          width: '100%',
          height: '10vh',
          position: 'fixed',
          bottom: '0',
          alignSelf: 'center',
          zIndex: 1
        }}>
          <div style={{
            background: '#e5e9f2',
            height: '6vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }} >
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>

              <div style={{ width: '25%' }}>
                <div style={{
                  marginTop: 10, marginRight: 80,
                  background: '#d9d9d9',
                  color: '#fff',
                  width: '100%',
                  height: '40%',
                  padding: '.5vw',
                  borderRadius: '50',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 30
                }} onClick={toggleDrawerClient}>
                  <AccountCircleIcon style={{ marginRight: 20, color: 'black' }} />
                  <p style={{ fontFamily: 'arial', fontWeight: 'bold', color: 'black' }}>Datos del Cliente</p>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'end' }}>
              {inputValue == 'todos' &&
                <>
                <div style={{
                    marginTop: 10, marginRight: 10,
                    background: 'linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%)',
                    color: '#fff',
                    width: '20%',
                    height: '40%',
                    padding: '.5vw',
                    borderRadius: '2vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} onClick={() => {getTicketPDFbyIdOrder(previousOrder.order.id)}}>
                    <p style={{ fontFamily: 'arial', fontWeight: 'bold' }}>IMPRIMIR TICKET</p>
                  </div>
                  <div style={{
                    marginTop: 10, marginRight: 80,
                    background: 'linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%)',
                    color: '#fff',
                    width: '20%',
                    height: '40%',
                    padding: '.5vw',
                    borderRadius: '2vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} onClick={handleOpenModalScan}>
                    <CropFreeIcon style={{ marginRight: 20 }} />
                    <p style={{ fontFamily: 'arial', fontWeight: 'bold' }}>SURTIR</p>
                  </div>
                  {/* <div style={{
                    marginTop: 10, marginRight: 80,
                    background: 'linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%)',
                    color: '#fff',
                    minWidth: '20%',
                    maxWidth: '30%',
                    height: '40%',
                    padding: '.5vw',
                    cursor: 'pointer',
                    border: 'none',
                    borderRadius: '2vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <p style={{ fontFamily: 'arial', fontWeight: 'bold' }}>GUARDAR</p>
                  </div> */}
                </>
              }
              {inputValue == 'pendientes' &&
                <>
                  <div style={{
                    marginTop: 10, marginRight: 80,
                    background: 'linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%)',
                    color: '#fff',
                    minWidth: '20%',
                    maxWidth: '30%',
                    height: '30%',
                    padding: '.5vw',
                    cursor: 'pointer',
                    border: 'none',
                    borderRadius: '2vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <p style={{ fontFamily: 'arial', fontWeight: 'bold' }}>SURTIR SELECCIONADO</p>
                  </div>
                </>
              }
            </div>

          </div>
        </div>
      </div >
    </div >
  );
};
