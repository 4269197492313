import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSurvey, getSurveySuccess, setSurveySelected, surveySearchATQSuccess } from '../../../actions/surveyAction';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import './style_list.scss';

export const Survery_List_Component = ({ switch1 = null, setActiveSwitch,setFrom,from}) => {


    const history = useHistory();
    const distpatch = useDispatch();
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState(null);
    const render = useRef(0);
    const survey = useSelector(state => state.survey.getSurvey);
    const listSearch = useSelector(state => state.survey.getSearchSurvey)


    const handleNivagate = (id, item) => {
        distpatch(setSurveySelected({ ...item }))
        history.replace(`/ATQAdministratorMenuSurvery/ATQAdministrator/${switch1}/${id}`);
    };


    useEffect(() => {
        return () => {
            distpatch(getSurveySuccess(null));
            distpatch(surveySearchATQSuccess(null))
        }
    }, [])

    useEffect(() => {
        if(currentPage !== null && from === 'dad'){
            if (currentPage === 0) {
                if (switch1 === "ACTIVE") {
                    distpatch(getSurvey(1, 11, currentPage, 10))
                }
                if (switch1 === "INACTIVE") {
                    distpatch(getSurvey(5, 11, currentPage, 10))
                }

            } else if (currentPage > 0 && survey !== null) {
                if (switch1 === "ACTIVE") {
                    distpatch(getSurvey(1, 11, currentPage, 10))
                } else if (switch1 === "INACTIVE") {
                    distpatch(getSurvey(5, 11, currentPage, 10))
                }
            }
        }else{
            setPage(0);
        }
    }, [currentPage])


    useEffect(() => {
        if (list && list.length > 0 && survey.length > 0) {
            setList([...list, ...survey])
        } else if (survey && survey.length > 0) {
            setList(survey)
        }
    }, [survey])


    useEffect(() => {
        if (render.current > 0) {
            setList(null);
            setPage(0);
            distpatch(getSurveySuccess(null));
            if(from === 'dad'){
                distpatch(surveySearchATQSuccess(null))
            }
            if(currentPage === 0){
                setPage(null)
            }
        } else {
            setList(null);
            render.current = 1;
        }
    }, [switch1])


    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    useEffect(() => {
        /* console.log('listSearc',listSearch) */
        if(listSearch && listSearch.length > 0){
            setFrom('child')
            if(listSearch[0].adminATQSurvey && listSearch[0].adminATQSurvey.id){
                setActiveSwitch('INACTIVE')
            }else{
                setActiveSwitch('ACTIVE')
            }
        }
    }, [listSearch])


    return (
        <div className="preconter_list_sur-atq-admin" onScroll={handleScroll}>
            {
                !listSearch ?
                   ( survey &&
                    list && list.length > 0 &&
                    list.map(item => (
                        <div className="conter_list_sur-ventas-item" key={item.id} onClick={() => handleNivagate(item.surgeryAppointment.id, item)}>
                            <div className="survey_text_info_encuesta_ventas" >
                                <span className="survey_info_date_encuesta_ventas">
                                    {item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) +
                                        ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' hrs' +
                                        ' - ' + ' Dr. ' + (item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                        (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                        (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                        (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)}
                                </span>
                                <span className="survey_info_date_encuesta_ventas">
                                    <p>
                                        {(item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name) + ' - '
                                            + 'CX# ' + (item.surgeryAppointment.id && item.surgeryAppointment.id)}
                                    </p>
                                </span>
                            </div>
                            <img src={black_arrow} alt="arrow" className={'survey_elements_img_arrow_ventas'} />
                        </div>
                    )))

                    :
                    (
                        listSearch &&
                        listSearch.map(item => (
                            <div className="conter_list_sur-ventas-item" key={item.surgeryAppointment.item} onClick={() => handleNivagate(item.surgeryAppointment.id, item)}>
                            <div className="survey_text_info_encuesta_ventas" >
                                <span className="survey_info_date_encuesta_ventas">
                                    {item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) +
                                        ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' hrs' +
                                        ' - ' + ' Dr. ' + (item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                        (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                        (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                        (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)}
                                </span>
                                <span className="survey_info_date_encuesta_ventas">
                                    <p>
                                        {(item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name) + ' - '
                                            + 'CX# ' + (item.surgeryAppointment.id && item.surgeryAppointment.id)}
                                    </p>
                                </span>
                            </div>
                            <img src={black_arrow} alt="arrow" className={'survey_elements_img_arrow_ventas'} />
                        </div>
                        ))
                    )
            }
        </div>
    )
}
