import React, { useEffect, useState } from 'react';
import './style.scss';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { Atq_Availability_List_Component } from '../../../components/ATQAdministrator/ATQ_Availability/Atq_Availability_List_Component';
import ico_search from '../../../assets/img/126PX/Icono_Buscar_Azul_126px.png';
import { formatFinalDate } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { useDispatch, useSelector } from 'react-redux';
import { getAtqAvailability, getAtqAvailableSuccess, setDateChoosed } from '../../../actions/atqAdministradorAction';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';


export const Atq_Availability_Page = () => {
    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');
    const atqAvailable = useSelector((state) => state.atqAdministrador.atqAvailable);
    const [date, setDate] = useState();
    const [time, setTime] = useState();
    const dispatch = useDispatch();

    const handleDate = (value) => {
        setDate(value);
    }
    const handleTime = (value) => {
        setTime(value);
    }

    useEffect(() => {
        return () => {
            dispatch(getAtqAvailableSuccess([]));
        }
    }, [])
    const handleSearch = () => {
        if (date && time) {
            /* console.warn('fecha',formatFinalDate(date,time));  */
            activeSwitch === 'ACTIVE'
                ? dispatch(getAtqAvailability(true, formatFinalDate(date, time).date))
                : dispatch(getAtqAvailability(false, formatFinalDate(date, time).date))

            dispatch(setDateChoosed(formatFinalDate(date, time).date));
        }


    }

    useEffect(() => {
        dispatch(getAtqAvailableSuccess([]));
        if (date && time) {
            activeSwitch === 'ACTIVE'
                ? dispatch(getAtqAvailability(true, formatFinalDate(date, time).date))
                : dispatch(getAtqAvailability(false, formatFinalDate(date, time).date))
        }
    }, [activeSwitch])


    return (

        <>
        <ComingSoonComponent/>
        {/*<div className="precontainer_page">
            <SubNavBarComponent title={'ESTATUS DE ATQ'} searchActive={false} historyPage={'/ATQAdmin_Inicio'} />
            <div className="container_page">
                <div className="container_buttons">
                    <input className="calendar_atq" type="date" onChange={e => handleDate(e.target.value)} />
                    <div className='container-time'>
                        <input className="time_atq" type="time" onChange={e => handleTime(e.target.value)} />
                        <img className='img_search' src={ico_search} onClick={() => handleSearch()} />
                    </div>
                    <nav className="toogle_container_buttons">
                        <button className={activeSwitch === 'ACTIVE'
                            ? "normal_button-atq btn_left-atq btn_active-atq"
                            : "normal_button-atq btn_left-atq"} onClick={() => setActiveSwitch('ACTIVE')}>
                            ATQ`S DISPONIBLES
                        </button>
                        <button className={activeSwitch === 'INACTIVE'
                            ? "normal_button-atq btn_rigth-atq btn_active-atq"
                            : "normal_button-atq btn_rigth-atq"} onClick={() => setActiveSwitch('INACTIVE')}>
                            ATQ`S NO DISPONIBLES
                        </button>
                    </nav>
                </div>
                <div className="section_list">
                    <Atq_Availability_List_Component
                        activeSwitch={activeSwitch}
                    />
                </div>
            </div>
        </div>*/}
        </>
    )
}
