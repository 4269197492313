import React, { useEffect, useState } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import './styleConsum.scss';
import shortid from 'shortid';
import upload from '../../../assets/img/126PX/Icon-upload-img.png';
import image from '../../../assets/img/126PX/Icon-image-view.png';
import share from '../../../assets/img/126PX/Ico_share.png';
import save from '../../../assets/img/126PX/Ico_save.png';
import { ATQ_Con_Table } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Con_Table';
import { useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { getFinishedAllQoute } from '../../../actions/systemsAction';
import { Modal_Comp } from '../../../components/ATQ/ATQ_CONSUM_REPORT/Modal_Comp';
import { ATQ_Modal_CXList } from './ATQ_Modal_CXList';
import { ATQ_Modal_with_Img } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_with_Img';
import { ATQ_Modal_Share } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_Share';
import { ATQ_Img_View } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Img_View';

export const ATQ_Con_Rep_Pen = () => {
    const [checked, setChecked] = useState(false);
    const currentPage = 0;
    const [openModal, setOpenModal] = useState(null);
    const [cxSelected,setCxSelected] = useState(null)
    const [imgState,setImgState] = useState(null);
     const dispatch = useDispatch();
    const {Switch,id} = useParams();
    const updateMainObject = () => {};

    const closeModal = (item) => {
        if(openModal === 'OPEN'){
            setOpenModal(null);
        }
       else if(openModal === 'MODAL-LIST'){
            setOpenModal(null);
        }
       else if(openModal === 'saveReport'){
            setOpenModal(null);
        }
        else if(openModal === 'chat'){
            setOpenModal(null);
        }
        else if(openModal === 'delete'){
            setOpenModal(null);
        }
        setOpenModal(null);
    }

  const handleChange = () => {
    setChecked(!checked);
  };
  useEffect(() => {
      dispatch(getFinishedAllQoute(currentPage));
  }, [])
    const quoteFinish = null;
    const globalSubtotal = null;
    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Tipo de CX',
            type: 'TEXT',
            value: 'Cervical - Lumbar'
        },
        {
            uid: shortid.generate(),
            title: 'Fecha',
            type: 'DATE',
            value: "2021-08-30T01:01:55"
        },
        {
            uid: shortid.generate(),
            title: 'No. Folio',
            type: 'TEXT-EDIT',
            value: '00 0000 0000'
        },
        {
            uid: shortid.generate(),
            title: 'Paciente',
            type: 'TEXT-EDIT',
            value: 'Jerry Federico Flores'
        },
        {
            uid: shortid.generate(),
            title: 'Hospital',
            type: 'TEXT',
            value: 'Real San José'
        },
        {
            uid: shortid.generate(),
            title: 'No. Piso/Cama',
            type: 'TEXT-EDIT',
            value: '00/00'
        },
        {
            uid: shortid.generate(),
            title: 'Ciudad y Estado',
            type: 'TEXT',
            value: 'Guadalajara, Jalisco'
        },
        {
            uid: shortid.generate(),
            title: 'Lista de precios',
            type: 'TEXT',
            value: 'Lista de Precios 1'
        },
        {
            uid: shortid.generate(),
            title: 'Vendedor',
            type: 'TEXT',
            value: 'Jesus Garcia'
        },
    ];


    const openView = (e) =>{
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2){
                setImgState({
                    img: reader.result
                });
            }
        }
        reader.readAsDataURL(e.target.files[0])

    }

    return (
        <div className='container-rep-com'>
        <SubNavBarComponent title={'REPORTE DE CONSUMO'}  historyPage = {'/ATQ_Inicio/Rep_Con'} />
            <div className='precontainer-rep-com'>
                <section className='section_right_repo'>
                    <StoreHouseListComponent
                    typeView={{type:'STORE', title: `CX #${id}`}}
                    listFields={arrayListFields} 
                    />
                        <div className='btn-container-repo'>
                        <div>
                                <input type='file' id='carga' accept='image/*' className='file' name='image' onChange={openView}/>
                                <label className="LeftInfoComponent-button" for='carga'>
                                    <img src={upload}/>
                                </label>
                        </div>
                                 <button className="LeftInfoComponent-button" onClick={() => setOpenModal('ViewImg')}>
                                    <img src={image}/>
                                 </button>
                                 <button className="LeftInfoComponent-button" onClick={() => setOpenModal('SHARE')}>
                                    <img src={share}/>
                                 </button>
                                 {
                                    Switch === 'ACTIVO' &&
                                 <button className="LeftInfoComponent-button" onClick={() => setOpenModal('delete')}>
                                    <img src={save}/>
                                 </button>
                                 }
                        </div>
                </section>
                <section className='section_left_repo'>
                        <ATQ_Con_Table 
                            setOpenModal = {setOpenModal}
                        />
                        <div className='totalF_contaier'>
                        <div className='container-check'>
                                <input
                                        className="style-check"
                                        type="checkbox"
                                        checked={checked}
                                        value={{id:'CX', description:'hola'}}
                                        name={'CX'}
                                        onChange={handleChange}
                                    />
                                     <span className='txt-check'> CX sin consumo</span>
                              </div>

                            <div className={'totalprice'}>
                                <div className={'totalpriceelement'}>
                                    <div className={'totalpriceelementtitle'}>Subtotal</div>
                                    <div className={'totalpriceelementvalue'}>
                                            {quoteFinish &&
                                                quoteFinish.realPrice 
                                            ?parseFloat(
                                                globalSubtotal.subtotal2
                                            ).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                                currencyDisplay: 'symbol',
                                            }): '$0.00'}
                                    </div>
                                </div>
                                <div className={'totalpriceelement'}>
                                    <div className={'totalpriceelementtitle'}>IVA</div>
                                    <div className={'totalpriceelementvalue'}>
                                        {globalSubtotal && globalSubtotal.iva &&
                                        Number(globalSubtotal.iva).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            currencyDisplay: 'symbol',
                                        })}
                                    </div>
                                </div>
                                <div className={'totalpriceelement'}>
                                    <div className={'totalpriceelementtitle'}>Total</div>
                                    <div className={'totalpriceelementvalue'}>
                                        {   quoteFinish &&
                                            quoteFinish.finalPrice ?
                                        Number(quoteFinish.finalPrice).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            currencyDisplay: 'symbol',
                                        }): '$0.00'}
                                    </div>
                                </div>
                           </div>
                        </div>
                        <div className='btnF_container'>
                        <NavLink className="des-button-view" to={`/facturacion2/317/${Switch}/${id}`}>
                        <span className="quote-item-button-name"> VER COTIZACION </span>
                      </NavLink>
                      {
                      Switch === 'ACTIVO'
                        ?(<button
                                className="des-button-finish"
                                onClick={() => getPDFByIDQuote(quoteFinish.id)}
                            >
                                <span className="quote-item-button-name">
                                {' '}
                                FINALIZAR{' '}
                                </span>
                      </button>)
                      :(<button
                                className="des-button-finish"
                                onClick={() => setOpenModal('saveReport')}
                            >
                                <span className="quote-item-button-name">
                                {' '}
                                    ACEPTAR{' '}
                                </span>
                      </button>)
                      }
                        </div>
                </section>
                {
                    openModal === 'OPEN' && 
                        <Modal_Comp
                                isOpen
                                toggle={closeModal}
                            />
                }

                {
                openModal === "MODAL-LIST" &&
                <ATQ_Modal_CXList
                    isOpen
                    toggle={closeModal}
                    updateMainObject={updateMainObject}
                    setOpenModal={setOpenModal}
                    setCxSelected={setCxSelected}
                />
            }
            {
                openModal === "SHARE" &&
                <ATQ_Modal_Share
                    isOpen
                    toggle={closeModal}
                    updateMainObject={updateMainObject}
                    setOpenModal={setOpenModal}
                    setCxSelected={setCxSelected}
                />
            }
            {
                openModal === "saveReport" &&
                <ATQ_Modal_with_Img
                    isOpen
                    toggle={closeModal}
                    type={'normal'}
                    text1={'REPORTE DE CONSUMO'}
                    text2={'GUARDADO'}
                />
            }
            {
                openModal === "ViewImg" &&
                <ATQ_Img_View
                    isOpen
                    toggle={closeModal}
                />
            }
            {
                openModal === "chat" &&
                <ATQ_Modal_with_Img
                    isOpen
                    toggle={closeModal}
                    type={'normal'}
                    text1={'Se ha enviado reporte de consumo al chat live.'}
                />
            }
            {
                openModal === "delete" &&
                <ATQ_Modal_with_Img
                    isOpen
                    toggle={closeModal}
                    type={'sinImg'}
                    text1={'Se ha eliminado imagen.'}
                />
            }
            </div>    
        </div>
    )
}
