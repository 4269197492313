import React from 'react';
import './style_list.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setATQChoosed } from '../../../actions/atqAdministradorAction';


export const Atq_Availability_List_Component = ({activeSwitch,page}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const atqAvailable = useSelector( (state) => state.atqAdministrador.atqAvailable);


    const handleNivagate = (item) => {
        dispatch(setATQChoosed(item));

        
        history.replace(`/ATQAdministrator_atq_availability/Atq_Info/${activeSwitch}/asignarcx`);
        (page && page === "asignar" &&
        history.replace(`/ATQAdministrator_atq_availability/Atq_Info/${activeSwitch}/asignar`));
        (page && page === "apoyo" &&
        history.replace(`/ATQAdministrator_atq_availability/Atq_Info/${activeSwitch}/apoyo`));
        (page && page === "cambiar" &&
        history.replace(`/ATQAdministrator_atq_availability/Atq_Info/${activeSwitch}/cambiar`));
    }

    return (
        <div className="precontainer_list">
        {
            atqAvailable&&
            atqAvailable.map(item => (
            <div className="container_list" onClick={() => handleNivagate(item)}>
                <span className='item-disponibilidad-atq'>
                {item.firstName && item.firstName} {item.secondName && item.secondName} {item.firstLastName && item.firstLastName} {item.secondLastName && item.secondLastName}
                </span>
                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
            </div>
            ))
            }
        </div>
    )
}
Atq_Availability_List_Component.propTypes = {
    activeSwitch: PropTypes.any,
}