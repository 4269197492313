import React, { useState } from 'react';
import './styleAdd.scss';
import imgAdd from '../../../assets/img/126PX/IcoAgregarCi.png';
import imgDelete from '../../../assets/img/126PX/iconDeleteComp.png'
export const AddComentarioComponent = ({setNewCommentSurvey,newCommentSurvey}) => {

    const {
        Commentary
    } = newCommentSurvey
    const [addAgregar, setAdddAgregar] = useState(null);
    return (
        <section className='container-component-comments-AddComent'>
            <div className='header-title-AddComent'>
                <div className={`container-body-add ${addAgregar === true && 'container-body-add-conditional'}`} >
                    <p className='title-AddComent'>Agregar Comentario</p>
                    {
                        addAgregar === true &&
                        <>
                            <textarea
                                placeholder='Agregar Comentario...'
                                className='style-text'
                                value={Commentary && Commentary}
                                onChange={(e) => setNewCommentSurvey({ ...newCommentSurvey, Commentary: e.target.value })}
                            />
                        </>
                    }
                    {addAgregar === true
                        ? <img src={imgDelete} className='img-delete-AddComent'  onClick={() => setAdddAgregar(!addAgregar)} ></img>
                        :
                        <img src={imgAdd} className='img-add-AddComent'   onClick={() => setAdddAgregar(!addAgregar)}></img>
                    }
                </div>
            </div>
        </section>
    )
}

