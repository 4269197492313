import React from 'react';
import './styleComingSComponent.css';

export const ComingSoonComponent = () => {
    return (
        <div className='mainContainer'>
            <div className='textContainer'>
                <div className='logoContainer'>
                    <img src='../../../logoFromLogin.png' alt='TSI Logo' width={'126px'}/>
                </div>
                <h1>COMING <br />SOON</h1>
                <p>We're currently working 🛠</p>
            </div>

            <div className='box'>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    )
}
