import React from 'react'
import { useParams } from 'react-router-dom';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { StoreHouseApproveOrderPreViewComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveOrderPreView/StoreHouseApproveOrderPreViewComponent'
import './sass/styles.scss';

export const StoreHouseApproveOrderPreViewScreen = () => {
    const {status} = useParams();



    return (
        <div className="StoreHouseApproveOrderPreViewScreen-container">
            {
                status === 'incomplete' 
                    ?<SubNavBarComponent title={'ALMACÉN'}  historyPage={'/StoreHouse/StoreHouseApproveOrder/OrderPreView/OrderPreView'}/>
                    :<SubNavBarComponent title={'ALMACÉN'}  historyPage={'/StoreHouse'}/>

            }
            <section className="StoreHouseApproveOrderPreViewScreen-primary-container">
                
                <StoreHouseApproveOrderPreViewComponent 
                   status ={status} 
                />

            </section>

        </div>
    )
}
