import React from 'react';
import PropTypes from 'prop-types';


export const StoreHouseAssignSystemsNotes = ({asignationNotes, title}) => {
    return (
        <div>
        {
            asignationNotes &&
            asignationNotes.length > 0 &&
            <article className="StoreHouseAssignSystemsNotes-left-item">
            
            <div className='StoreHouseAssignSystemsNotes-left-item-items'>
                    <h3 className='StoreHouseAssignSystemsNotes-left-item-item-title'>{title}</h3>
                    <ul className='StoreHouseAssignSystemsNotes-left-item-list'>
                        {
                            asignationNotes.map((el) => (
                                <li className='StoreHouseAssignSystemsNotes-left-item-list-item' key={el.id}>
                                    <div className="StoreHouseAssignSystemsNotes-left-item-list-item-container-Text">
                                    <span className="StoreHouseAssignSystemsNotes-left-item-list-item-Text">{`${'*'} `}</span><span>{` ${el.description}`}</span>

                                    </div>
                                </li>
                            ))
                        }
                    </ul>
            </div>
            </article>
        }
        </div>
    )
}

StoreHouseAssignSystemsNotes.propTypes = {
    asignationNotes: PropTypes.any,
    title: PropTypes.any,
}


