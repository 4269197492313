import React from 'react'
import PropTypes from 'prop-types';
import { StoreHouseStockModalForm } from './StoreHouseStockModalForm';
import CustomModal from '../../shared/Modal';

export const StoreHouseStockModal = (props) => {

    const modalBody = () => {
        return (
            <StoreHouseStockModalForm typeModal={props.typeModal} 
            toggle={props.toggle} 
            itemToAddUbication={props.itemToAddUbication} 
            type = {props.type}
            addDeleteLocations = {props.addDeleteLocations}
            />
        );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle}
                title="DISPONIBILIDAD"
                body={modalBody()}
        />
    );
}

StoreHouseStockModal.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    itemToAddUbication: PropTypes.any,
    typeModal: PropTypes.any,
};
