import React, { useEffect, useState } from 'react';
import './SearchInput.scss';

// ICONS
import icoSearch from '../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, getSearchList, getSearchSuccess } from '../../../actions/allUsersAction';


const SearchInput = (props) => {

        const [searchProduct, setSearchProduct] = useState('');
        const dispatch = useDispatch();
        const startDate = '1900-01-01T01:01:57';
        const endDate = '2200-05-06T01:01:33';
        const selectedUserId = useSelector(state => state.allUsers.selectedUserId);


        useEffect(() => {

          return () => {
            dispatch(getSearchSuccess([]));
          }
        }, [])
        

        const handleSearch = (e) => {
                e.preventDefault();
                if (searchProduct === '') {
                        dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId,0,5));
                        dispatch(getSearchSuccess(null))
                } else {
                        dispatch(getSearchList(startDate, endDate, 38, selectedUserId,searchProduct))
                        dispatch(getAppointmentsStoreHouseSuccess(null))
                }
                setSearchProduct('');


        };

        return (

                <form onSubmit={handleSearch} className="SearchUserComponent">
                        <button className="SearchUserComponent-search-icobuscar" type='submit'>
                                <img className='img-search-icon' src={icoSearch} alt="" />
                        </button>
                        <input
                                type="text"
                                placeholder="Buscar proceso..."
                                name="search"
                                onChange={({ target }) => setSearchProduct(target.value)}
                                value={searchProduct}
                                className="SearchUserComponent-tools-input-search"
                                autoComplete="off"
                        />
                </form>
        )
}

export default SearchInput;
