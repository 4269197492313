import shortid from "shortid";
import imgEncuestas from '../../../assets/img/126PX/Icono_Encuestas_126px.png';
import imgDisponibilidadEquipos from '../../../assets/img/126PX/Icono_DisponibilidadEquipos_126px.png';
import imgLogistica from '../../../assets/img/126PX/Icono_Logistica_126px.png';
import imgRecepcionRevisionMaterial from '../../../assets/img/126PX/Icono_RecepcionRevisionMaterial_126px.png';
import imgAgenda from '../../../assets/img/126PX/Icono_Agenda_512px.png';
import imgSurtir from '../../../assets/img/512PX/Icono_SurtirCX_512px.png';

export const infoButtonsStoreHouseLeft = [
    { 
        uid: shortid.generate(),
        img: imgLogistica,
        name: 'Logística',
        path: '/AppointmentLogistics/Logistics',
    },
    {
        uid: shortid.generate(),
        img: imgSurtir,
        name: 'Surtir CX',
        path: '/StoreHouse',
    },
    {
        uid: shortid.generate(),
        img: imgDisponibilidadEquipos,
        name: 'Disponibilidad de equipo',
        path: '/StoreHomeAbailableSystemsScreen',
        
        
    },
    {
        uid: shortid.generate(),
        img: imgRecepcionRevisionMaterial,
        name: 'Recepción de Material',
        path: '/StoreHouse',
    },
    {
        uid: shortid.generate(),
        img: imgEncuestas,
        name: 'Encuesta',
        path: 'Encuestas',
    },
    {
        uid: shortid.generate(),
        img: imgAgenda,
        path: 'agenda/agenda',
        name: 'Agenda',
    },
    
]