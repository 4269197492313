import React from 'react'
import shortid from 'shortid';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import image from '../../../assets/img/126PX/Icon-image-view.png';
import add from '../../../assets/img/126PX/icon_add_almacen.png';
import { ATQ_Img_View } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Img_View';
import './sass/styles.scss';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export const StoreHomeReceivingMaterialReportIncomingLeftInfoComponent = ({closeModal,setOpenModal,openModal,type}) => {
    const surgerySelected = useSelector((state) => state.atqAdministrador.surgeryChosed);
   
    

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Tipo de CX',
            type: 'TEXT',
            value: 'Cervical - Lumbar'
        },
        {
            uid: shortid.generate(),
            title: 'Fecha',
            type: 'DATE',
            value: _.get(surgerySelected,'surgeryDate','1999-01-01T00:00:00')
        },
        {
            uid: shortid.generate(),
            title: 'No. Folio',
            type: 'TEXT-EDIT',
            value: '00 0000 0000'
        },
        {
            uid: shortid.generate(),
            title: 'Paciente',
            type: 'TEXT-EDIT',
            value: `${_.get(surgerySelected,'patient.firstName','')} ${_.get(surgerySelected,'patient.secondName','')} ${_.get(surgerySelected,'patient.firstLastName','')} ${_.get(surgerySelected,'patient.secondLastNames','')}`.trim()
        },
        {
            uid: shortid.generate(),
            title: 'Hospital',
            type: 'TEXT',
            value: _.get(surgerySelected,'hospital.name','')
        },
        {
            uid: shortid.generate(),
            title: 'No. Piso/Cama',
            type: 'TEXT-EDIT',
            value: '00/00'
        },
        {
            uid: shortid.generate(),
            title: 'Ciudad y Estado',
            type: 'TEXT',
            value: `${_.get(surgerySelected,'hospital.city','')}, ${_.get(surgerySelected,'hospital.state','')}`
        },
        {
            uid: shortid.generate(),
            title: 'Lista de precios',
            type: 'TEXT',
            value: _.get(surgerySelected,'priceList.description','')
        },
    ];

    const openView = (e) =>{
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2){
                setImgState({
                    img: reader.result
                });
            }
        }
        reader.readAsDataURL(e.target.files[0])

    }

    return (
        <div className="StoreHomeReceivingMaterialReportIncomingLeftInfoComponent-component">

            <StoreHouseListComponent
                typeView={{type:'STORE', title: `CX #${_.get(surgerySelected,'id','')}`}}
                listFields={arrayListFields} 
            />
{
   !type &&
            <div className="StoreHomeReceivingMaterialReportIncomingLeftInfoComponent-buttons-component">
                <button className="StoreHomeReceivingMaterialReportIncomingLeftInfoComponent-button" 
                onClick={() => setOpenModal('ViewImg')}>
                 <img  className='StoreHomeReceivingImgane' src={image}/> 
                </button>
                <div className='StoreHomeReceivingMaterialReportIncomingLeftInfoComponent-button'>
                <input 
                type='file' accept='image/*' name='image'  onChange={openView}/>
                <img  className='StoreHomeReceivingImgane' src={add}/>
                </div>
            </div>
}
            {
                openModal === "ViewImg" &&
                <ATQ_Img_View
                    isOpen
                    toggle={closeModal}
                />
            }
            
            </div>    
    )
}
