import React from 'react';
import { useSelector } from 'react-redux';
import './styleInd.scss';

export const ATQ_Individual_Compo = ({ tipo, titulo, listadoComentarios, setNewCommentSurvey, comentario,

    valueSurvey = {
        AllOk: false,
        AllOkCommentary: "",
        INSD6: false,
        INSD6Commentary: "",
    }, setValueSurvey,

    valueWareHouse = {
        CheckInCommentary: "",
        CheckOutCommentary: "",
        CoordinatorCommentary: "",
    }, setValueWareHouse,
},


    newCommentSurvey = {
        Commentary: ''
    }) => {

    const {
        AllOk,
        AllOkCommentary,
        INSD6,
        INSD6Commentary
    } = valueSurvey;

    const {
        Commentary
    } = newCommentSurvey


    const {
        CheckInCommentary,
        CheckOutCommentary,
        CoordinatorCommentary
    } = valueWareHouse


    const surveySelected = useSelector(state => state.survey.setSurveyAppoimentSelected)
    const variables = ['si', 'no'];

    return (
        <>
            {
                tipo === 'atq' &&

                <div className="section-question">
                    <span class="title-question">{titulo}</span>
                    <span className="title-question-intern">¿Todo bien en CX?</span>
                    <span className="text-answer"> {
                        surveySelected.atqSurvey === null
                            ? 'Sin comentarios de ATQ'
                            : `${surveySelected.atqSurvey.allOk === true ? 'Si' : 'No'}, ${surveySelected.atqSurvey.allOkCommentary ? surveySelected.atqSurvey.allOkCommentary : ''}`
                    }

                    </span>
                    <span className="title-question-intern" >Desinfección según INS-D6. Lavado de instrumental efectuado.</span>
                    <span className="text-answer"> {
                        surveySelected.atqSurvey === null
                            ? 'Sin comentarios de ATQ'
                            : `${surveySelected.atqSurvey.insD6 === true ? 'Si' : 'No'}, ${surveySelected.atqSurvey.insD6Commentary ? surveySelected.atqSurvey.insD6Commentary : ''}`
                    }
                    </span>
                </div>

            }
            {/* {
                tipo === 'Nuevo' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>
                    <textarea
                        className='style-text'
                        value={Commentary}
                        placeholder='Agregar Comentario'
                        onChange={(e) => setNewCommentSurvey({ ...newCommentSurvey, Commentary: e.target.value })}
                    />

                </div>

            } */}
            {
                tipo === 'Nuevo' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>

                    <textarea
                        className='style-text'
                        value={Commentary}
                        placeholder='Agregar Comentario'
                        onChange={(e) => setNewCommentSurvey({ ...newCommentSurvey, Commentary: e.target.value })}/>
                </div>

            }
            {
                tipo === 'Entrada Almacen' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>

                    <textarea
                        className='style-text'
                        value={CheckInCommentary}
                        placeholder='Agregar Comentario'
                        onChange={(e) => setValueWareHouse({ ...valueWareHouse, CheckInCommentary: e.target.value })}/>
                </div>

            }
            {
                tipo === 'Salida Almacen' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>

                    <textarea
                        className='style-text'
                        value={CheckOutCommentary}
                        placeholder='Agregar Comentario'
                        onChange={(e) => setValueWareHouse({ ...valueWareHouse, CheckOutCommentary: e.target.value })}/>
                </div>

            }
            {
                tipo === 'Coordinador Almacen' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>

                    <textarea
                        className='style-text'
                        value={CoordinatorCommentary}
                        placeholder='Agregar Comentario'
                        onChange={(e) => setValueWareHouse({ ...valueWareHouse, CoordinatorCommentary: e.target.value })}/>
                </div>

            }
            {
                tipo === 'Agregado' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>
                    <textarea
                        className='style-text'
                        value={comentario && comentario}
                        disabled
                    />

                </div>

            }
            {
                tipo === 'Almacen' &&
                <div className="section-question-almacen">
                    <span class="title-question-almcen">{titulo}</span>
                    <span
                        className='style-text-almacen'>
                        {comentario && comentario}</span>

                </div>

            }

            {
                tipo === 'ATQAgregar' &&

                <div className="section-question">
                    <span class="title-question">{titulo}</span>
                    <span className="title-question-intern">¿Todo bien en CX?</span>
                    <div className='container-radio'>
                        {
                            variables.map(item => (
                                <>
                                    <span className="item-name">{item}</span>
                                    <input
                                        className='input-radio'
                                        type="radio"
                                        name='first'
                                        onChange={(e) => setValueSurvey({ ...valueSurvey, AllOk: item === "si" ? true : false })}
                                    />
                                </>
                            ))
                        }
                    </div>
                    <textarea
                        className='style-text'
                        value={AllOkCommentary ? AllOkCommentary : ''}
                        placeholder='Agregar Comentario'
                        onChange={(e) => setValueSurvey({ ...valueSurvey, AllOkCommentary: e.target.value })}
                    />
                    <span className="title-question-intern" >Desinfección según INS-D6. Lavado de instrumental efectuado.</span>
                    <div className='container-radio' >
                        {
                            variables.map(item => (
                                <>
                                    <span className="item-name">{item}</span>
                                    <input
                                        className='input-radio'
                                        type="radio"
                                        name='second'
                                        onChange={(e) => setValueSurvey({ ...valueSurvey, INSD6: item === "si" ? true : false })}
                                    />
                                </>
                            ))
                        }
                    </div>
                    <textarea
                        className='style-text'
                        value={INSD6Commentary ? INSD6Commentary : ''}
                        placeholder='Agregar Comentario'
                        onChange={(e) => setValueSurvey({ ...valueSurvey, INSD6Commentary: e.target.value })}
                    />
                </div>
            }


            {
                tipo === 'list-items-notes' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>
                    {
                        listadoComentarios.map(item => (
                            <li key={item.id}>
                                {item.comentario}
                            </li>
                        ))
                    }
                </div>
            }

        </>
    )
}
