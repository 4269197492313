import React from 'react';
import './style.scss';

export const Mess_Not_Com = () => {

    const information = [
        {
            Sistema: 'Add plus',
            Ubicacion:'ADD PLUS 12',
            Cantidad:'4'
        },
        {
            Sistema: 'Tetris',
            Ubicacion:'TETRIS 7',
            Cantidad:'1'
        },
        {
            Sistema: 'Tetris',
            Ubicacion:'TETRIS 8',
            Cantidad:'1'
        },
        {
            Sistema: 'Set de Cucharillas',
            Ubicacion:'CUCHARILLAS',
            Cantidad:'1'
        },
        {
            Sistema: 'Phantom Cervical',
            Ubicacion:'PHANTOM 2',
            Cantidad:'1'
        },
        {
            Sistema: 'Tava',
            Ubicacion:'TAVA 4',
            Cantidad:'1'
        },
        {
            Sistema: 'Maletin Fuente de Luz',
            Ubicacion:'Maletín 1',
            Cantidad:'1'
        },
        {
            Sistema: 'Instrumental Lumbar',
            Ubicacion:'INSTRUMENTAL 9',
            Cantidad:'1'
        },
    ]
    return (
        <>
        <div className="TableUbication-precontainer">
            <div className="TableUbication-container">
                <table className="TableUbication-table-container">
                    <thead className="TableUbication-table-thead">
                        <tr className="TableUbication-table-row">
                            <th className="TableUbication-table-title">{'Sistema'}</th>
                            <th className="TableUbication-table-title">{'Ubicacion'}</th>
                            <th className="TableUbication-table-title">{'Cantidad'}</th>
                        </tr>
                    </thead>
                    <tbody className="TableUbication-table-body">
                    {
                        information.map(item => (
                            <tr className="TableUbication-table-body-row">
                                <td className="TableUbication-table-data">{item.Sistema}</td>
                                <td className="TableUbication-table-data">{item.Ubicacion}</td>
                                <td className="TableUbication-table-data">{item.Cantidad}</td>
                            </tr>
                        ))        
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </>
    )
}
