import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { Mess_hosp_Req_Comp } from '../../../components/Messenger/HospitalRequirements/Mess_hosp_Req_Comp';
import { Mess_medics_req_der } from '../../../components/Messenger/MedicsRequirements/Mess_medics_req_der';
import './styleReq.scss';

export const Messenger_hosp_Requ_Page = () => {

    const [selectedHospital, setSelectedHotpital] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [list1, setList1] = useState('1');  

    return (
        <div className="HospitalHome-principal-container">
            <SubNavBarComponent title={`Requerimientos de Hospitales`} historyPage={'/Inicio'} />
        <div className={"HospitalHome-main-page-container"}>
                <Mess_hosp_Req_Comp  
                    selected={selectedHospital} 
                    setSelected={setSelectedHotpital} 
                    list1={list1}
                    setList1={setList1}
                />
            <div className={"HospitalHome-right-bar"}>
                <Mess_medics_req_der
                    setSelected = {setSelectedHotpital}
                    selected = {selectedHospital}
                    page = {'hospital'}
                    selectedHospitals = {selectedHospital}
                    currentPage = {currentPage}
                    setList1={setList1}
                />
            </div>
        </div>
    </div>
    )
}
