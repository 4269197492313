import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import './sass/styles.scss';
import { createTeam } from '../../../actions/atqAdministradorAction';
import { Error_Modal } from '../../../pages/ATQAdministrator/ATQAdministradorModals/Error_Modal';



export const ModalCreateTeamATQ = ({isOpen, toggle, listItems,page='nada',idGroup=0,atqList,setATQList,ban1,changeBan1}) => {
    
    const dispatch = useDispatch();
    const [openModalError, setOpenModalError] = useState(null);
    const listAtqSelectioned = useSelector( (state) => state.atqAdministrador.team);
    const[mensajeModalError,setmensajeModalError] = useState(null);
    const countTeamMember = useRef(0);
    const ban = useRef(0);
    let list = null;
    let byOrder = null;
    
    const orderMembers = () => {
        if(list){
        byOrder = list.slice(0);
        byOrder.sort(function(a,b) {
            return a.orderInGroup - b.orderInGroup;
        });
        }
    }

    if(page === 'modify' && listAtqSelectioned){
        list =  listAtqSelectioned.atqList;
         
        orderMembers();
    }
    

       useEffect ( () =>{
        let nArray = [];
        if(listAtqSelectioned && page === 'modify' && ban1.current === 0){
            changeBan1()
           if(byOrder && byOrder.length > 0){
            byOrder.map((item,index) => {
                    nArray.push(item.user);
               })
           }
           setATQList([...nArray]);
        }
       },[])


      /*  useEffect ( () =>{
       
            dispatch(createTeam({teamList:{atqList}}));
       },atqList)
        */

       const toggleModal = () => {
        setOpenModalSchedule(null);
    };

    
        const handleAddOptionalItem = (item) => {
            let newArray = [];
        
            if(atqList.some( el => el.userID === item.userID )){
                newArray = atqList.filter(el => el.userID !== item.userID);
            }else{
                newArray = atqList.map(el => el);
                newArray.push(item);
            }
            setATQList(newArray);
        }
        const handleAtn = (e) => {
            e.preventDefault();
        }



        const handleChangeModal = () => {

            if (atqList.length > 0 ) {
                    toggle("ORDER-TEAM")
                }
                else if(atqList.length === 0){
                    setmensajeModalError("NO HAS SELECCIONADO ATQ’S");
                    setOpenModalError("errorModal");
                }
                // else if(atqList.length > 3){
                //     setmensajeModalError ("NO PUEDES AGREGAR MAS DE 3 ATQ’S");
                //     setOpenModalError("errorModal");
                // }

            }
        
        
 
        return (
            <div className="container-ModalCreateTeamATQ-all">
                <h3 className="ModalCreateTeamATQ-title">Seleccionar ATQ’s:</h3>
                <form className="container-ModalCreateTeamATQ-list-items" onSubmit={handleAtn}>
                    <div className="container-ModalCreateTeamATQ-items-scroll">

                        <div className='container-ModalCreateTeamATQ'>
                            {
                                listItems &&
                                listItems.map((item) => (
                                    <div key={item.userID} className="ModalCreateTeamATQ-c-item">
                                    <span className="ModalCreateTeamATQ-item-name">{item.firstName + " " + item.secondName + " " + item.firstLastName + " " + item.secondLastName }</span>
                                    <input
                                        className="c-scheduleSurgeryModal-item-check"
                                        type="checkbox"
                                        checked={ atqList.some(el=>  el && item && (el.userID === item.userID)) }
                                        value={item.userID}
                                        name={item.userID}
                                        onChange={() => handleAddOptionalItem(item)}
                                        isChecked
                                    />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <button className="ModalCreateTeamATQ-button" onClick={() => handleChangeModal()}>
                        ACEPTAR
                    </button>
                    {
                openModalError === "errorModal" &&
                <Error_Modal
                    isOpen
                    toggle={toggleModal}
                    setOpenModalError={setOpenModalError}
                    messageModal={mensajeModalError}
                    buttonName={'ACEPTAR'}
                />
                 }
                </form>
            
            </div>
        );
    };

ModalCreateTeamATQ.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    listItems: PropTypes.any,
    lastOpenModal: PropTypes.any,
    setOpenModal: PropTypes.any,
    setOpenModalError: PropTypes.any,
};