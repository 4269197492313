import React, { useState } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { Mess_medics_req_der } from '../../../components/Messenger/MedicsRequirements/Mess_medics_req_der';
import { Mess_medics_Req_Izq_Comp } from '../../../components/Messenger/MedicsRequirements/Mess_medics_Req_Izq_Comp';
import './styleRequeriments.scss';

export const Mess_medics_Requ_Page = () => {
    const [selectedMedics, setSelectedMedics] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [list1, setList1] = useState('1');  
   
    return (
        <div className="MedicsHome-principal-container">
            <SubNavBarComponent title={`Requerimientos de Médicos`} historyPage={'/Inicio'} />
            <div className={"MedicsHome-main-page-container"}>
                    <Mess_medics_Req_Izq_Comp  
                        selected={selectedMedics} 
                        setSelected={setSelectedMedics} 
                        list1={list1}
                        setList1={setList1}
                    />
                <div className={"MedicsHome-right-bar"}>
                    <Mess_medics_req_der
                        setSelected = {setSelectedMedics}
                        selected = {selectedMedics}
                        page = {'medicos'}
                        selectedMedics = {selectedMedics}
                        currentPage = {currentPage}
                        setList1={setList1}
                    />
                </div>
            </div>
        </div>
    )
}
