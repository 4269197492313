import React from 'react'
import './styleUbicaciones.scss';


export const UbicacionComponent = ({listUbicacion}) => {

    

    return (

        <div className='section-general-analisis'>
            <div className='header-title-analisis'>
                <p className='header-text-title-analisis'>Ubicaciones</p>
            </div>
            <div className='section-location-analisis'>

                {
                    listUbicacion &&
                    listUbicacion.map((item) => (
                        <p className='text-item-analisis'>{item.value}</p>
                    ))
                }


            </div>

        </div>


    )
}
