import React from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDriveAccess } from '../../actions/systemsAction';
import '../QualityManager/styles.sass';

import icon_quality_analisis from '../../assets/img/512PX/icon_quality_analisis.png';
import Icono_Estatus_CX from '../../assets/img/512PX/Icono_Estatus_CX.png';
import icon_quality_informacion from '../../assets/img/512PX/icon_quality_informacion.png';
import icon_quality_indicadores from '../../assets/img/512PX/ico_quality_indicadores.png';
import icon_quality_requerimientos from '../../assets/img/512PX/ico_quality_requerimientos.png';
import icon_quality_agenda from '../../assets/img/512PX/ico_quality_agenda.png';
import icon_quality_direcorio from '../../assets/img/512PX/icono_quality_directorio.png';
import icon_quality_encuestas from '../../assets/img/512PX/Icono_Encuestra_512.png';

export const Quality_Manager_Page = () => {

    const dispatch = useDispatch();


    return (
        <div className="precontainer_qualityHome">
            {/*  ESTA ES LA PRIMERA SECCION  */}
            <div className="container_mod_rigth animate__animated animate__fadeIn">
                <div className="container-sec section-1">

                    
                    {/* <NavLink className='quality-item-simpleform quality-item quality-historial'
                        to={'/Quality_Analisis_Page'}
                    >
                        <img src={icon_quality_analisis} alt="" className='quality-historial-img' />
                        <h3 className='quality-item-title'> Analisis de Encuesta </h3>

                </NavLink> */}

                    <button className='quality-item-simpleform quality-item quality-historial disabled' disabled >
                        <img src={icon_quality_analisis} alt="" className='quality-historial-img' />
                        <h3 className='quality-item-title'> Analisis de Encuesta </h3>
                    </button>



                    {/* <NavLink className='quality-item-horizontalform quality-item quality-disponibilidad'
                        to={'/'}
                    >
                        <img src={icon_quality_direcorio} alt="" className='quality-disponibilidad-img' />
                        <h3 className='quality-item-title'> Directorio </h3>

                    </NavLink> */}

                    <button className='quality-item-horizontalform quality-item quality-disponibilidad disabled' disabled>
                        <img src={icon_quality_direcorio} alt="" className='quality-disponibilidad-img' />
                        <h3 className='quality-item-title'> Directorio </h3>
                    </button>


                     <NavLink className='quality-item-horizontalform quality-item quality-asignar'
                        to={'/Quality_Encuestas_Page'}
                    >
                        <img src={icon_quality_encuestas} alt="" className='quality-asignar-img' />
                        <h3 className='quality-item-title'> Encuestas </h3>

                    </NavLink> 

                    {/* <button className='quality-item-horizontalform quality-item quality-asignar' >
                    <img src={icon_quality_encuestas} alt="" className='quality-asignar-img' />
                        <h3 className='quality-item-title'> Encuestas </h3>
                    </button> */}

                    <NavLink className='quality-item-simpleform quality-item quality-rol-guardias'
                        to={'/Quality_Menu/Requeriments'}
                    >
                        <img src={icon_quality_requerimientos} alt="" className='quality-rol-guardia-img' />
                        <h3 className='quality-item-title'> Requerimientos Hospital y Medicos </h3>

                    </NavLink>
                </div>

                {/* /* ESTA ES LA SEGUNDA SECCION */}



                {/* ESTA ES LA SEGUNDA SECCION  */}
                <div className="container-sec section-2">


                    {/* <NavLink  className='quality-item-simpleform quality-item quality-encuesta'
                        to={'/'}
                        >
                            <img src={icon_quality_indicadores} alt="" className='quality-encuesta-img'/>
                            <h3 className='quality-item-title'> Indicadores </h3>

                        </NavLink> */}


                    <button className='quality-item-simpleform quality-item quality-encuesta disabled' disabled>
                        <img src={icon_quality_indicadores} alt="" className='quality-encuesta-img' />
                        <h3 className='quality-item-title'> Indicadores </h3>
                    </button>


                    <NavLink className='quality-item-horizontalform quality-item quality-transferir'
                        to={'/agenda/agenda'}
                    >
                        <img src={icon_quality_agenda} alt="" className='quality-transferir-img' />
                        <h3 className='quality-item-title'> Agenda </h3>

                    </NavLink>

                    {/* <NavLink   className='quality-item-horizontalform quality-item quality-agenda'
                        to = {'/'}
                        >
                            <img src={icon_quality_informacion} alt="" className='quality-agenda-img'/>
                            <h3 className='quality-item-title'> Informacion <br/> Productos TSI </h3>

                        </NavLink> */}


                    <button className='quality-item-horizontalform quality-item quality-agenda' onClick={() => dispatch(getDriveAccess())} >
                        <img src={icon_quality_informacion} alt="" className='quality-agenda-img' />
                        <h3 className='quality-item-title'> Información Productos TSI  </h3>
                    </button>

                    <NavLink className='quality-item-simpleform quality-item quality-rol-guardias'
                        to={'/ATQAdmin_EstatusCX/GERENTECALIDAD'}
                    >
                        <img src={Icono_Estatus_CX} alt="" className='quality-rol-guardia-img' />
                        <h3 className='quality-item-title'>Estatus </h3>

                    </NavLink>

                </div>
                {/* SEGUNDA SECCION  */}

            </div>
        </div>
    )
}
