import React, {  useState } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { QualityMagerMedicsRequeriments } from '../../../components/QualityManager/QualityMagerMedicsRequeriments';
import { QualityManagerHospitalRequeriments } from '../../../components/QualityManager/QualityManagerHospitalRequeriments';
import './style.scss';
export const QualityManagerRequerimentsPage = () => {
    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');

/*     useEffect(() => {
      console.warn('valor',activeSwitch);
    }, [activeSwitch]); */
    

  return (
  <div className='containerRequeriments'>
            <SubNavBarComponent title={'Requerimientos de Medicos & Hospitales'} searchActive={false} historyPage={'/Inicio'}/>
            <div className='precontainerRequeriments'>
                    <div className='containerButtonsChossed'>
                        <nav className='containerButtons'>
                            <button className={ activeSwitch === 'ACTIVE'
                                    ? "normal_button1 btn_left1 btn_active1"
                                    : "normal_button1 btn_left1"} onClick ={() => setActiveSwitch('ACTIVE')}> Hospitales
                            </button>
                            <button className={ activeSwitch === 'INACTIVE'
                                ? "normal_button1 btn_rigth1 btn_active1"
                                : "normal_button1 btn_rigth1"} onClick ={() => setActiveSwitch('INACTIVE')} >

                                Medicos</button>
                            </nav>
                    </div>
                    {
                        activeSwitch === 'ACTIVE'
                            ? <QualityManagerHospitalRequeriments/>
                            :<QualityMagerMedicsRequeriments/>

                    }
            </div>
  </div>
  );
};
