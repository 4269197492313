import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import { ATQAdministrator_Mat_History_List_Info_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Material_History/ATQAdministrator_Mat_History_List_Info_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { Error_Modal } from '../ATQAdministradorModals/Error_Modal';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, getSearchListHouseSuccessAtq, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';

import './style.scss';
import { useHistory } from 'react-router-dom';

export const ATQAdministrator_Mat_History_List_Info_Page = () => {

    const surgerySelected = useSelector((state) => state.atqAdministrador.surgeryChosed);
    const [openModalError, setOpenModalError] = useState(null);
    const [openModalSchedule, setOpenModalSchedule] = useState(null);
    const dispatch = useDispatch();



    const appimentSelected = useSelector(state => state.atqAdministrador.surgeryChosed)
    const [surtido, setSurtido] = useState(null);
    const [noSurtido, setNoSurtido] = useState(null);
    const [listado, setListado] = useState(null);
    const history = useHistory();
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const [newProductsReq, setNewProductsReq] = useState(null);

    const toggleModal = () => {
        setOpenModalSchedule(null);
    };
    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Fecha:',
            type: 'DATE',
            value: surgerySelected.surgeryDate && surgerySelected.surgeryDate,
        },
        {
            uid: shortid.generate(),
            title: 'Hora:',
            type: 'HOUR',
            value: surgerySelected.surgeryDate && surgerySelected.surgeryDate,
        },
        {
            uid: shortid.generate(),
            title: 'Origen:',
            value: 'Almacén',
        },
        {
            uid: shortid.generate(),
            title: 'Destino',
            value: surgerySelected.hospital && surgerySelected.hospital.name,
        }
    ];

    const handleNavigate = () => {
        history.replace(`/ATQAdministrator_Material_History`);
    }



    //------------------Aqui empieza para ubicaciones --------------------------
    useEffect(() => {
        if (appimentSelected && appimentSelected.id) {
            dispatch(getProductsToAppointmentStoreHose(appimentSelected.id))
            dispatch(getAppointmentLocationsStoreHose(appimentSelected.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [appimentSelected])


    useEffect(() => {
        if (AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
            AppointmentWithLocations.productRequirements.map(item => {
                if ((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)) {
                    arraySurtidos.push(item);
                } else {
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
            setListado([...arraySurtidos, ...arrayNoSurtidos])
        }
    }, [AppointmentWithLocations])


    useEffect(() => {
        if (AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0) {
            if (appimentSelected.productRequirements && appimentSelected.productRequirements.length > 0) {
                setNoSurtido([appimentSelected.productRequirements && appimentSelected.productRequirements]);
            }
        }

    }, [AppointmentLocations, AppointmentProducts])

    useEffect(() => {
        if (AppointmentLocations && appimentSelected && appimentSelected) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, appimentSelected)
        }
    }, [AppointmentLocations])


    useEffect(() => {
        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
            {
                if (!AppointmentWithLocations || AppointmentWithLocations === null) {
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, appimentSelected)));
                }
            }
        }

    }, [newProductsReq])

    useEffect(() => {
        dispatch(getSearchListHouseSuccessAtq([]));
    }, [])
    

    //---------------------------------------------------------------------------

    return (
        <div className="container_Mat">
            <SubNavBarComponent title={'HISTORIAL DE MATERIAL'} searchActive={false} historyPage={'/ATQAdministrator_Material_History'} />
            <div className="container-colums_Mat">
                <section>
                    <StoreHouseListComponent
                        typeView={{ type: 'PQX', title: `PQX #${surgerySelected.id}` }}
                        listFields={arrayListFields}
                    />
                </section>
                <section>
                    <ATQAdministrator_Mat_History_List_Info_Component listado ={surtido} />
                    <div className="container-button_Mat">
                        <button className="btn_aceptar" onClick={() => handleNavigate()}>
                            Aceptar
                        </button>
                    </div>
                </section>
            </div>
            {
                openModalError === "errorModal" &&
                <Error_Modal
                    isOpen
                    toggle={toggleModal}
                    setOpenModalError={setOpenModalError}
                    messageModal={'NO HAS SELECCIONADO DÍAS'}
                    buttonName={'ACEPTAR'}
                />
            }
        </div>
    )
}
