import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateToPresent, formatHourToPresent } from '../../hooks/useFormatDateInitialAndFinalDay';
import imgMessenger from '../../assets/img/126PX/imgChat.png';
import { getAppointmentsAtq, getAppointmentsSuccessAtq, getChatGroup, getSearchSurgerySuccess, setChatSurgerySelected } from '../../actions/notification-chatAction';
import _ from "lodash";

export const MessagesChatData = ({ setSurgeriesList, surgerieslist, setTitle, title }) => {

    const listSurgery = useSelector(state => state.notificationChat.getAppoiments);
    const listSearch = useSelector(state => state.notificationChat.searchList);
    const distpatch = useDispatch();
    const user = useSelector(state => state.user.user.identity.userID);
    const [currentPage, setPage] = useState(0);
    const startDate = '2019-01-22T00:00:00';
    const endDate = '2024-01-22T23:59:00';
    const [list, setList] = useState([]);
    const offset = 15;



    useEffect(() => {
        if(user && user === 76){
            distpatch(getAppointmentsAtq(startDate, endDate, 14, null, currentPage, offset))
        }else if(user && user === 189){
            distpatch(getAppointmentsAtq(startDate, endDate, 2, 189, currentPage, offset))
        }
        return () => {
            distpatch(getAppointmentsSuccessAtq([]))
        }
    }, [currentPage])

    const handleNavigate = (id) => {
        distpatch(setChatSurgerySelected(id))
        setTitle(!title)
        setSurgeriesList(!surgerieslist);
        distpatch(getChatGroup(id, 0, 10, true));
    }

    useEffect(() => {
        if (list && list.length > 0) {
            setList([...list, ...listSurgery])
        } else if (listSurgery && listSurgery.length > 0) {
            setList(listSurgery)
        }
    }, [listSurgery])

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    return (
        <div className="MessagesChatComponent-container-search" onScroll={handleScroll}>
            {
             (!_.isNil(listSearch) && listSearch.length > 0) 
                
                ? (
                    listSearch &&
                    listSearch.map(item => (
                    <div key={item.id} className={"MessagesChatComponent-preContainer-search MessagesChatComponent-preContainer-inactive-search"} >
                        <div className="MessagesChatComponent-info-container-search" onClick={() => handleNavigate(item.id)} >


                            <div className="MessagesChatComponent-container-info-search" >

                                <p className="MessagesChatComponent-info-Date" >{item.surgeryDate && (formatDateToPresent(item.surgeryDate))}</p>
                                <p className="MessagesChatComponent-info-Hour" >{item.surgeryDate && (formatHourToPresent(item.surgeryDate)) + 'hrs' + ' - '}</p>
                                <p className="MessagesChatComponent-info-Medic" >{(item.medic.name && item.medic.name) + ' ' +
                                    (item.medic.secondName && item.medic.secondName) + ' ' +
                                    (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                    (item.medic.secondLastname && item.medic.secondLastname)}</p>
                                <p className="MessagesChatComponent-info-hospital" >{(item.hospital && item.hospital.name)}</p>
                                <p className="MessagesChatComponent-info-id" >CX#{item.id && item.id}</p>
                            </div>
                            <img src={imgMessenger} alt="" className="MessagesChatComponent-info-imgMessage" />
                        </div>
                    </div>
                ))

                )
                
                
                :
                (listSurgery &&
                list &&
                list.length > 0 &&
                list.map(item => (
                    <div key={item.id} className={"MessagesChatComponent-preContainer MessagesChatComponent-preContainer-inactive"} >
                        <div className="MessagesChatComponent-info-container" onClick={() => handleNavigate(item.id)} >


                            <div className="MessagesChatComponent-container-info" >

                                <p className="MessagesChatComponent-info-Date" >{item.surgeryDate && (formatDateToPresent(item.surgeryDate))}</p>
                                <p className="MessagesChatComponent-info-Hour" >{item.surgeryDate && (formatHourToPresent(item.surgeryDate)) + 'hrs' + ' - '}</p>
                                <p className="MessagesChatComponent-info-Medic" >{(item.medic.name && item.medic.name) + ' ' +
                                    (item.medic.secondName && item.medic.secondName) + ' ' +
                                    (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                    (item.medic.secondLastname && item.medic.secondLastname)}</p>
                                <p className="MessagesChatComponent-info-hospital" >{(item.hospital && item.hospital.name)}</p>
                                <p className="MessagesChatComponent-info-id" >CX#{item.id && item.id}</p>
                            </div>
                            <img src={imgMessenger} alt="" className="MessagesChatComponent-info-imgMessage" />
                        </div>
                    </div>
                ))

                )
               
            }
        </div>
    )
}
