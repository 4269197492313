import React, { useEffect, useState } from 'react'
import './style_AsignarStatus.scss'

import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { Atq_Availability_List_Component } from '../../../components/ATQAdministrator/ATQ_Availability/Atq_Availability_List_Component';
import { getAtqAvailability, getAtqAvailableSuccess, setDateChoosed } from '../../../actions/atqAdministradorAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const ATQAdmin_StatusAsignar_Page = () => {

    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');
    const dispatch = useDispatch();
    const { page } = useParams();
    const atqAvailable = useSelector((state) => state.atqAdministrador);



    useEffect(() => {
        return () => {
            dispatch(getAtqAvailableSuccess([]));
        }
    }, [])

    useEffect(() => {
        dispatch(getAtqAvailableSuccess([]));


        if (atqAvailable && atqAvailable.surgeryChosed && atqAvailable.surgeryChosed.surgeryDate) {
           /*  console.log('imprimir', atqAvailable.surgeryChosed.surgeryDate) */
            activeSwitch === 'ACTIVE'
                ? dispatch(getAtqAvailability(true, (atqAvailable.surgeryChosed.surgeryDate)))
                : dispatch(getAtqAvailability(false, (atqAvailable.surgeryChosed.surgeryDate)))
        }
    }, [activeSwitch])


    return (

        <div className="precontainer_page-asigStatus">

            {/* <SubNavBarComponent title={'ASIGNAR ATQ'} historyPage={'/ATQAdministrator_Material_History/transfer_CX/asignar'} searchActive={true}/> */}
            {
                page && page === 'asignar' &&
                <SubNavBarComponent title={'ASIGNAR ATQ'} searchActive={true} historyPage={'/ATQAdministrator_Material_History/transfer_CX/asignar'} />
            }

            {
                (page && page === 'apoyo' || page === 'cambiar') &&
                <SubNavBarComponent title={'ASIGNAR ATQ'} searchActive={false} historyPage={'/ATQAdministrator_Material_History/transfer_CX/apoyo'} />
            }

            <div className="container_page-asigStatus">
                <div className="container_buttons-asigStatus">
                    <nav className="toogle_container_buttons-asigStatus">
                        <button className={activeSwitch === 'ACTIVE'
                            ? "normal_button-atq-asigStatus btn_left-atq-asigStatus btn_active-atq-asigStatus"
                            : "normal_button-atq-asigStatus btn_left-atq-asigStatus"} onClick={() => setActiveSwitch('ACTIVE')}>
                            ATQ`S DISPONIBLES
                        </button>
                        <button className={activeSwitch === 'INACTIVE'
                            ? "normal_button-atq-asigStatus  btn_rigth-atq-asigStatus btn_active-atq-asigStatus"
                            : "normal_button-atq-asigStatus  btn_rigth-atq-asigStatus"} onClick={() => setActiveSwitch('INACTIVE')}>
                            ATQ`S NO DISPONIBLES
                        </button>
                    </nav>
                </div>
                <div className="section_list-asigStatus">
                    <Atq_Availability_List_Component 
                        page={page}
                        activeSwitch={activeSwitch}
                    />
                </div>

            </div>
        </div>
    )
}
