import React from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import SyncIcon from '@material-ui/icons/Sync';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TablePagination,
  Checkbox,
  FormControlLabel,
  Drawer,
  KeyboardDatePicker,
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';

import { useState } from "react";
import { NavLink } from "react-router-dom";

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { getOrders } from "../../actions/userActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SearchBar from "material-ui-search-bar";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoomIcon from '@material-ui/icons/Room';
import CreateIcon from '@material-ui/icons/Create';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getOrderDetailsAsync, getRemissionDetailAsync, GetSolPurchaseOrderDetailAsync, GetPurchaseOrderDetailRequestAsync, SetSupplyDetailAsync, getSupplyDetailAsync, deleteSupplyDetailAsync } from "../../actions/userActions";



const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const ShoppingRequestDetails = (props) => {
  console.log('PROPS SHOPPING: ', props)
  const requestH = useSelector((state) => state.user.request);
  const loginData = useSelector((state) => state.user.user.identity)
  const previousShopping = props.location.state.remission;
  const previousShopping2 = props.location.state.state

  const classes = useStyles();
  const [rowShopping, setRowrowShopping] = useState([])

  const getPurchaseOrderDetailRequest = async () => {
    if (previousShopping2) {
      await GetPurchaseOrderDetailRequestAsync(previousShopping2.id, requestH).then((resp) => {
        setRowrowShopping(resp.response)
      })
    } else if (previousShopping) {
      await GetSolPurchaseOrderDetailAsync(previousShopping.orderId, requestH).then((resp) => {
        setRowrowShopping(resp.response)
      })
    }
  }

  useEffect(() => {
    getPurchaseOrderDetailRequest()
  }, [])

  const [showBy, setShowBy] = React.useState('shoppingRequest'); /* Mostrar Por Variable */
  const [productSelected, setProductSelected] = useState(false)

  /* START Search Bar Section */
  const [searchedValuesGeneral, setSearchedValuesGeneral] = useState("");
  const requestSearch = (searchedVal) => {
  };
  const cancelSearch = () => {
  };
  /* END Search Bar Section */

  /* START SHOPPING REQUEST */
  const columnShoppingRequest = [
    { id: 'select', label: '', minWidth: 100 },
    { id: 'solicitud', label: 'SOLICITUD', minWidth: 100 },
    { id: 'pactado', label: 'PACTADO', minWidth: 100 },
    { id: 'pedido', label: '# PEDIDO', minWidth: 170 },
    { id: 'productos', label: '# PRODUCTOS', minWidth: 170 },
    { id: 'vendedor', label: 'VENDEDOR', minWidth: 170 },
    { id: 'icon', label: '', minWidth: 170 },
  ];

  function createDataShoppingRequest(select, solicitud, pactado, pedido, productos, vendedor, icon) {
    return { select, solicitud, pactado, pedido, productos, vendedor, icon };
  }

  const rowsShoppingRequestData = [
    createDataShoppingRequest('', '2222222', '19/SEP/2022', '0000026', '5', 'Maria Montes', 'icon'),
    createDataShoppingRequest('', '3333333', '22/SEP/2022', '0000025', '1', 'Carlos Torres Montes', 'icon'),
    createDataShoppingRequest('', '4444444', '22/SEP/2022', '0000023', '10', 'Maria Montes', 'icon')
  ];

  const [rowsShoppingRequest, setRowsShoppingRequest] = useState(rowsShoppingRequestData);
  const [rowsShoppingRequestOriginal, setRowsShoppingRequestOriginal] = useState(rowsShoppingRequestData);
  const [rowsPerPageShoppingRequest, setRowsPerPageShoppingRequest] = useState(7);
  const [pageShoppingRequest, setPageShoppingRequest] = useState(0);

  const handleChangePageShoppingRequest = (event, newPage) => {
    setPageShoppingRequest(newPage);
  };

  const handleChangeRowsPerPageShoppingRequest = (event) => {
    setRowsPerPageShoppingRequest(+event.target.value);
    setPageShoppingRequest(0);
  };
  /* END SHOPPING REQUEST */

  /* START SHOPPING REQUEST - PRODUCT */
  const columnShoppingRequestProducts = [
    { id: 'select', label: '', minWidth: 100 },
    { id: 'solicitud', label: 'SOLICITUD', minWidth: 100 },
    { id: 'pactado', label: 'PACTADO', minWidth: 100 },
    { id: 'pedido', label: '# PEDIDO', minWidth: 170 },
    { id: 'vendedor', label: 'VENDEDOR', minWidth: 170 },
    { id: 'id', label: 'ID', minWidth: 170 },
    { id: 'productos', label: 'PRODUCTOS', minWidth: 170 },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: 170 },
    { id: 'icon', label: '', minWidth: 170 },
  ];

  function createDataShoppingRequestProducts(select, solicitud, pactado, pedido, vendedor, id, productos, cantidad, icon) {
    return { select, solicitud, pactado, pedido, vendedor, id, productos, cantidad, icon };
  }

  const rowsShoppingRequestProductsData = [
    createDataShoppingRequestProducts('', '2222222', '19/SEP/2022', '00000026', 'Maria Montes', 'CS 8201-08 ', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '5', false),
    createDataShoppingRequestProducts('', '3333333', '22/SEP/2022', '00000022', 'Carlos Torres', 'CS 2600-6-45', 'COSMICMIA - TORNILLO D6.5 MM LONGITUD 45 MM', '60', false),
    createDataShoppingRequestProducts('', '4444444', '22/SEP/2022', '00000026', 'Maria Montes', 'CS 8201-08 ', 'CHALECOS', '2', true)
  ];

  const [rowsShoppingRequestProducts, setRowsShoppingRequestProducts] = useState(rowsShoppingRequestProductsData);
  const [rowsShoppingRequestProductsOriginal, setRowsShoppingRequestProductsOriginal] = useState(rowsShoppingRequestProductsData);
  const [rowsPerPageShoppingRequestProducts, setRowsPerPageShoppingRequestProducts] = useState(7);
  const [pageShoppingRequestProducts, setPageShoppingRequestProducts] = useState(0);

  const handleChangePageShoppingRequestProducts = (event, newPage) => {
    setPageShoppingRequestProducts(newPage);
  };

  const handleChangeRowsPerPageShoppingRequestProducts = (event) => {
    setRowsPerPageShoppingRequestProducts(+event.target.value);
    setPageShoppingRequestProducts(0);
  };
  /* END SHOPPING REQUEST - PRODUCT */

  /* START SHOPPING ORDER */
  const columnShoppingOrder = [
    { id: 'solicitud', label: 'SOLICITUD', minWidth: 100 },
    { id: 'fecha', label: 'FECHA 0.C', minWidth: 100 },
    { id: 'vendedor', label: 'VENDEDOR', minWidth: 170 },
    { id: 'solicitudes', label: '# SOLICITUDES', minWidth: 170 },
    { id: 'productos', label: '# PRODUCTOS', minWidth: 170 },
    { id: 'icon', label: '', minWidth: 170 },
  ];

  function createDataShoppingOrder(solicitud, fecha, vendedor, solicitudes, productos, icon) {
    return { solicitud, fecha, vendedor, solicitudes, productos, icon };
  }

  const rowsShoppingOrderData = [
    createDataShoppingOrder('2222222', '19/SEP/2022', 'Maria Montes', '5', '10', true),
    createDataShoppingOrder('3333333', '22/SEP/2022', 'Carlos Torres', '10', '50', true),
    createDataShoppingOrder('4444444', '22/SEP/2022', 'Eugenia Martinez', '1', '1', true),
    createDataShoppingOrder('4444444', '22/SEP/2022', 'Carlos Torres', '2', '2', true)
  ];

  const [rowsShoppingOrder, setRowsShoppingOrder] = useState(rowsShoppingOrderData);
  const [rowsShoppingOrderOriginal, setRowsShoppingOrderOriginal] = useState(rowsShoppingOrderData);
  const [rowsPerPageShoppingOrder, setRowsPerPageShoppingOrder] = useState(7);
  const [pageShoppingOrder, setPageShoppingOrder] = useState(0);

  const handleChangePageShoppingOrder = (event, newPage) => {
    setPageShoppingOrder(newPage);
  };

  const handleChangeRowsPerPageShoppingOrder = (event) => {
    setRowsPerPageShoppingOrder(+event.target.value);
    setPageShoppingOrder(0);
  };
  /* END SHOPPING ORDER */

  /* START SHOPPING ORDER - PRODUCTS */
  const columnShoppingOrderProducts = [
    { id: 'solicitud', label: 'SOLICITUD', width: '5%' },
    { id: 'proveedor', label: 'PROVEEDOR', width: '5%', },
    { id: 'fecha', label: 'FECHA O.C', width: '5%' },
    { id: 'pedido', label: '# PEDIDO', width: '5%' },
    { id: 'id', label: 'ID', width: '5%' },
    { id: 'productos', label: 'PRODUCTOS', width: '5%' },
    { id: 'cantidad', label: 'CANTIDAD', width: '5%' },
    { id: 'eta', label: 'ETA', width: '5%' },
    { id: 'proceso', label: 'PROCESO', width: '5%' },
    { id: 'icon', label: '', width: '5%' },
  ];

  function createDataShoppingOrderProducts(solicitud, proveedor, fecha, pedido, id, productos, cantidad, eta, proceso, icon) {
    return { solicitud, proveedor, fecha, pedido, id, productos, cantidad, eta, proceso, icon };
  }

  const rowsShoppingOrderProductsData = [
    createDataShoppingOrderProducts('2222222', '19/SEP/2022', '20/SEP/2022', '00000026', 'CS 8201-08 ', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '5', '29/SEP/2022', 'PENDIENTE', 'pencil'),
    createDataShoppingOrderProducts('3333333', '2/SEP/2022', '22/SEP/2022', '00000023', 'CS 2600-6-45  ', 'COSMICMIA - TORNILLO D6.5 MM, LONGITUD 45 MM', '50', '30/SEP/2022', 'EN PROCESO', 'pencil'),
    createDataShoppingOrderProducts('4444444', '22/SEP/2022', '22/SEP/2022', '00000022', 'CS 8201-08  ', 'ADD - D 12 MM, ALTURA 16-25 MM, 6°', '2', '22/SEP/2022', 'SURTIDO', 'eye')
  ];

  const [rowsShoppingOrderProducts, setRowsShoppingOrderProducts] = useState(rowsShoppingOrderProductsData);
  const [rowsShoppingOrderProductsOriginal, setRowsShoppingOrderProductsOriginal] = useState(rowsShoppingOrderProductsData);
  const [rowsPerPageShoppingOrderProducts, setRowsPerPageShoppingOrderProducts] = useState(7);
  const [pageShoppingOrderProducts, setPageShoppingOrderProducts] = useState(0);

  const handleChangePageShoppingOrderProducts = (event, newPage) => {
    setPageShoppingOrderProducts(newPage);
  };

  const handleChangeRowsPerPageShoppingOrderProducts = (event) => {
    setRowsPerPageShoppingOrderProducts(+event.target.value);
    setPageShoppingOrderProducts(0);
  };
  /* END SHOPPING ORDER PRODUCTS */

  /* START SHOPPING ORDER - SUPLIER */
  const columnShoppingOrderSuplier = [
    { id: 'fecha', label: 'FECHA O.C', width: '5%' },
    { id: 'proveedor', label: 'PROVEEDOR', width: '5%' },
    { id: 'ordenes', label: '# ORDENES', width: '5%' },
    { id: 'solicitudes', label: '# SOLICITUDES', width: '5%' },
    { id: 'productos', label: '# PRODUCTOS', width: '5%' },
    { id: 'proceso', label: 'PROCESO', width: '5%' },
    { id: 'icon', label: '', width: '5%' },
  ];

  function createDataShoppingOrderSuplier(fecha, proveedor, ordenes, solicitudes, productos, proceso, icon) {
    return { fecha, proveedor, ordenes, solicitudes, productos, proceso, icon };
  }

  const rowsShoppingOrderSuplierData = [
    createDataShoppingOrderSuplier('19/SEP/2022', 'Maria Montes', '5', '5', '5', 'PENDIENTE', 'eye'),
    createDataShoppingOrderSuplier('12/SEP/2022', 'Carlos Torres', '10', '50', '50', 'EN PROCESO', 'eye'),
    createDataShoppingOrderSuplier('22/SEP/2022', 'Eugenia MArtinez', '2', '2', '2', '22/SEP/2022', 'SURTIDO', 'eye')
  ];

  const [rowsShoppingOrderSuplier, setRowsShoppingOrderSuplier] = useState(rowsShoppingOrderSuplierData);
  const [rowsShoppingOrderSuplierOriginal, setRowsShoppingOrderSuplierOriginal] = useState(rowsShoppingOrderSuplierData);
  const [rowsPerPageShoppingOrderSuplier, setRowsPerPageShoppingOrderSuplier] = useState(7);
  const [pageShoppingOrderSuplier, setPageShoppingOrderSuplier] = useState(0);

  const handleChangePageShoppingOrderSuplier = (event, newPage) => {
    setPageShoppingOrderSuplier(newPage);
  };

  const handleChangeRowsPerPageShoppingOrderSuplier = (event) => {
    setRowsPerPageShoppingOrderSuplier(+event.target.value);
    setPageShoppingOrderSuplier(0);
  };
  /* END SHOPPING ORDER SUPLIER */

  /* START ZONE */
  const columnZone = [
    { id: 'solicitud', label: 'SOLICITUD', width: '5%' },
    { id: 'proveedor', label: 'PROVEEDOR', width: '5%' },
    { id: 'fecha', label: 'FECHA O.C', width: '5%' },
    { id: 'pedido', label: '# PEDIDO', width: '5%' },
    { id: 'id', label: 'ID', width: '5%' },
    { id: 'productos', label: 'PRODUCTOS', width: '5%' },
    { id: 'cantidad', label: 'CANTIDAD', width: '5%' },
    { id: 'pendiente', label: 'PENDIENTE', width: '5%' },
    { id: 'eta', label: 'ETA', width: '5%' },
    { id: 'proceso', label: 'PROCESO', width: '5%' },
  ];

  function createDataZone(solicitud, proveedor, fecha, pedido, id, productos, cantidad, pendiente, eta, proceso) {
    return { solicitud, proveedor, fecha, pedido, id, productos, cantidad, pendiente, eta, proceso };
  }

  const rowsZoneData = [
    createDataZone('222222', 'Maria', '20/SEP/2022', '0000026', 'CS 8201-08', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '5', '3', '29/SEP/2022', 'PENDIENTE')
  ];

  const [rowsZone, setRowsZone] = useState(rowsZoneData);
  const [rowsZoneOriginal, setRowsZoneOriginal] = useState(rowsZoneData);
  const [rowsPerPageZone, setRowsPerPageZone] = useState(7);
  const [pageZone, setPageZone] = useState(0);

  const handleChangePageZone = (event, newPage) => {
    setPageZone(newPage);
  };

  const handleChangeRowsPerPageZone = (event) => {
    setRowsPerPageZone(+event.target.value);
    setPageZone(0);
  };
  /* END ZONE */

  /* START Products Shopping */
  const columnShoppingOrderProductsModal = [
    { id: 'eta', label: 'ETA', width: '5%' },
    { id: 'cantidad', label: 'Cantidad', width: '5%' },
    { id: 'deleteIcon', label: '', width: '5%' },
    // { id: 'editIcon', label: '', width: '5%' },
    // { id: 'confirmIcon', label: '', width: '5%' },
  ];

  const columnShoppingOrderProductsModal2 = [
    { id: 'color', label: 'Color', width: '5%' },
    { id: 'talla', label: 'Talla', width: '5%' },
    { id: 'bordado', label: 'Bordado', width: '5%' },
  ];

  function createDataShoppingOrderProductsModal(color, talla, bordado, eta, cantidad, deleteIcon, editIcon, confirmIcon) {
    return { color, talla, bordado, eta, cantidad, deleteIcon, editIcon, confirmIcon };
  }

  const rowsShoppingOrderProductsModalData = [
    createDataShoppingOrderProductsModal('Blanco', 'S', 'Dra. Victoria Castro Cortés', '12/10/2022', '5', 'deleteIcon', 'editIcon', 'confirmIcon'),
    createDataShoppingOrderProductsModal('Blanco', 'SM', 'Dra. Victoria Castro Cortés', '12/10/2022', '5', 'deleteIcon', 'editIcon', 'confirmIcon'),
    createDataShoppingOrderProductsModal('Blanco', 'S', 'Dra. Victoria Castro Cortés', '12/10/2022', '5', 'deleteIcon', 'editIcon', 'confirmIcon')
  ];

  const [rowsShoppingOrderProductsModal, setRowsShoppingOrderProductsModal] = useState(rowsShoppingOrderProductsModalData);
  const [rowsShoppingOrderProductsModalOriginal, setRowsShoppingOrderProductsModalOriginal] = useState(rowsShoppingOrderProductsModalData);
  const [rowsPerPageShoppingOrderProductsModal, setRowsPerPageShoppingOrderProductsModal] = useState(7);
  const [pageShoppingOrderProductsModal, setPageShoppingOrderProductsModal] = useState(0);

  const handleChangePageShoppingOrderProductsModal = (event, newPage) => {
    setPageShoppingOrderProductsModal(newPage);
  };

  const handleChangeRowsPerPageShoppingOrderProductsModal = (event) => {
    setRowsPerPageShoppingOrderProductsModal(+event.target.value);
    setPageShoppingOrderProductsModal(0);
  };
  /* END  Products Shopping */

  /* Drawer Client */
  const [stateDrawerClient, setStateDrawerClient] = useState(false);

  const toggleDrawerClient = () => {
    setStateDrawerClient(!stateDrawerClient);
  };

  const [rowSelected, setrowSelected] = useState({});
  const [rowSupply, setrowSupply] = useState([]);

  const [openModalShoppingProducts, setOpenModalShoppingProducts] = React.useState(false);
  const [openConfirmMessage, setOpenConfirmMessage] = React.useState(false);



  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedQuantity, setselectedQuantity] = React.useState();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  // rowSupply
  // quantitySupply
  const saveSupplier = async () => {
    let contador = 0;
    for (let i = 0; i < rowSupply.length; i++) {
      contador += rowSupply[i].quantitySupply
    }
    if (parseInt(selectedQuantity) == rowSelected.quantityOrder || parseInt(selectedQuantity) < rowSelected.quantityOrder) {
      let cont = contador + selectedQuantity
      // if(cont < rowSelected.quantityOrder){
      let data = {
        "id": 0,
        "purchaseOrderDetailId": rowSelected.purchaseOrderDetailID,
        "usuId": loginData.userID,
        "quantitySupply": parseInt(selectedQuantity),
        "dateSupply": selectedDate,
        "statusSupply": 0
      }
      await SetSupplyDetailAsync(data, requestH).then((resp) => {
        getSupplyDetail(resp.response.purchaseOrderDetailId);
        setOpenConfirmMessage(true)
      });

      // } else {
      // console.log("LA CANTIDAD ES MAYOR A LA SOLICITADA II")

      // } 
    } else {
      console.log("LA CANTIDAD ES MAYOR A LA SOLICITADA I")
    }
  }

  const getSupplyDetail = async (param) => {
    await getSupplyDetailAsync(param, requestH).then((resp) => {
      setrowSupply(resp.response)
    })
  }

  const deleteSupplyDetail = async (params) => {
    await deleteSupplyDetailAsync(params, requestH).then((resp) => {
      setOpenConfirmMessage(true)
      setrowSupply(resp.response)
    })
  }



  // useEffect(() => {
  //   getSupplyDetail();
  // },[])

  return (
    <div style={{
      width: '100%',
      paddingTop: '16vh',
      height: '100%'
    }}>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={`/shopping`} title={`DETALLE COMPRA POR SOLICITUD`} />
      <div >

        {/* TopBar */}
        <div style={{ display: 'flex', background: 'transparent', marginTop: '0vh' }}>
          <div style={{ width: '70%', marginTop: '2rem', justifyContent: 'flex-start', display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', justifyContent: 'flex-start', width: '100%' }}>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}># Solicitud</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>123</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabla Orden de Compras - Productos */}
        <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
          <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {columnShoppingOrderProducts.map((column) => {
                    const id = column.id
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <div style={{ display: 'flex' }}>
                          {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                          {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                          {column.label}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowShopping.map((row) => {
                  console.log(row)
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columnShoppingOrderProducts.map((column) => {
                        const type = row.tipo
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {
                              column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                column.id == 'solicitud' ? row.orderId :
                                  column.id == 'proveedor' ? '-----' :
                                    column.id == 'fecha' ? row.purchaseOrderDate :
                                      column.id == 'pedido' ? '-----' :
                                        column.id == 'id' ? row.productCode :
                                          column.id == 'productos' ? row.productDescription :
                                            column.id == 'cantidad' ? (row.quantityOrder || row.productCount) :
                                              column.id == 'eta' ? '-----' :
                                                column.id == 'proceso' ? <p style={{ fontWeight: 'bold', color: row.status == 1 ? '#EB5500' : row.status == 2 ? '#37A7B9' : row.status == 3 ? '#275300' : '#000' }}>{row.status == 1 ? 'Pendiente' : row.status == 2 ? 'En proceso' : row.status == 3 ? 'Surtido' : ''}</p> :
                                                  column.id == 'icon' && !previousShopping ? value == 'eye' ? <VisibilityIcon style={{ color: 'black' }} /> : <CreateIcon style={{ color: 'black' }} onClick={() => { setOpenModalShoppingProducts(true); setrowSelected(row); getSupplyDetail(row.purchaseOrderDetailID); }} /> :
                                                    value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Dialog - ShoppingOrder - PRODUCTS */}
        <Dialog
          open={openModalShoppingProducts}
          onClose={() => { setOpenModalShoppingProducts(false) }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle id="form-dialog-title"><div style={{ display: 'flex', marginBottom: 40 }}>
            <p style={{ marginRight: 30, fontWeight: 'bold', fontSize: 25 }}>Escanear</p>
          </div></DialogTitle>
          <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'column', marginRight: '100px', fontFamily: 'arial' }}>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ width: '24%' }}>
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '20px',
                  lineHeight: '17px',
                  color: '#484F59'
                }}>{rowSelected.productCode}</p>
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  lineHeight: '20px',
                  color: '#000000',
                  marginTop: 5
                }}>{rowSelected.productDescription}</p>
                <small>{rowSelected.quantitySupply}</small>
              </div>
              {/* <TextField id="outlined-basic" label="Fecha" variant="outlined" style={{ width: '24%' }} /> */}
              <TextField
                id="date"
                type="date"
                variant="outlined"
                value={selectedDate}
                onChange={(e) => handleDateChange(e.target.value)}
                style={{ width: '24%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField id="outlined-basic" label="Cantidad a entregar" value={selectedQuantity} onChange={(e) => setselectedQuantity(e.target.value)} variant="outlined" style={{ width: '24%' }} />
              <button className="btn" style={{ width: '10%', fontFamily: 'arial', fontWeight: 'bold' }} onClick={saveSupplier}>AGREGAR</button>
            </div>
            <Collapse in={openConfirmMessage}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenConfirmMessage(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Se han actualizado los cambios con éxito
              </Alert>
            </Collapse>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
              <div style={{ width: '24%' }}>
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#000000'
                }}>Datos del producto</p>
                <Divider style={{ color: '#000' }} />
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>

              {/* 1RA TABLA - COLOR, TALLA Y BORDADO */}
              {/* <TableContainer className={classes.container} style={{ maxHeight: 300 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnShoppingOrderProductsModal2.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsShoppingOrderProductsModal && rowsShoppingOrderProductsModal.slice(pageShoppingOrderProductsModal * rowsPerPageShoppingOrderProductsModal, pageShoppingOrderProductsModal * rowsPerPageShoppingOrderProductsModal + rowsPerPageShoppingOrderProductsModal).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingOrderProductsModal2.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {
                                  column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                  column.id == 'color' ? ' - ' :
                                  column.id == 'talla' ? ' - ' :
                                  column.id == 'bordado' ? ' - ' :
                                  '  '}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer> */}

              {/* 2DA TABLA - FECHA DE ENTREGA Y CANTIDAD */}
              <TableContainer className={classes.container} style={{ maxHeight: 300 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnShoppingOrderProductsModal.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowSupply && rowSupply.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingOrderProductsModal.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {
                                  column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                    column.id == 'eta' ? row.dateSupply.split(' ')[0] :
                                      column.id == 'cantidad' ? row.quantitySupply :
                                        column.id == 'deleteIcon' ? <button onClick={(e) => deleteSupplyDetail(row)}><DeleteIcon style={{ color: '#C70808' }} /></button> :
                                          value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>

            </div>
          </div>
          <DialogContent style={{ alignSelf: 'end' }}>
            <button className="btn" style={{ width: 220, fontFamily: 'arial', fontWeight: 'bold' }} onClick={() => { setOpenModalShoppingProducts(false); setSelectedDate(''); setselectedQuantity('') }}>Cerrar</button>
            {/* <button className="btn" style={{ width: 220, fontFamily: 'arial', fontWeight: 'bold' }} onClick={saveSupplier}>ACTUALIZAR</button> */}
          </DialogContent>
          <DialogActions style={{ marginTop: 100 }}>
          </DialogActions>
        </Dialog>

      </div >
    </div >
  );
};
