import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RemoveHospitalRequirement, RemoveMedicRequirement, UpdateHospitalRequirement, UpdateMedicRequirement } from '../../../actions/systemsAction';
import icoEdit from '../../../assets/img/126PX/IcoEditCi.png';
import icoDelete from '../../../assets/img/126PX/Icon-delete.png';

export const InputRender = ({item,setList1,id,page,selected,readonlyPage}) => {
    const [letter,setLetter] = useState(item);
    const [compare,setCompare] = useState(item)
    const [changeState,setChangeState] = useState(false);
    const dispatch = useDispatch();
  /*   console.warn({selected}); */

    const handleUpdate = () => {
        if(page === 'medicos' && compare !== letter){
            dispatch(UpdateMedicRequirement({
                id: id,
                medicId : selected,
                requirement: letter,
            }));
            setList1(null);
        }
        else if(page === 'hospital' && compare !== letter){
            dispatch(UpdateHospitalRequirement({
                id: id,
                hospitalId : selected,
                requirement: letter,
            }));
            setList1(null);
        }
        
    }

    const handleChange = (e) => {
           setLetter(e.target.value);
    }

    const handleDelete = () => {
        if(page === 'medicos'){
            dispatch(RemoveMedicRequirement(id));
        }
        else if(page === 'hospital'){
            dispatch(RemoveHospitalRequirement(id));
        }
       
        setList1(null);
    }

    return(
        <div className='container-list'>

        {
            
            ( <input className='inputText'
                type='text'
                value={letter}
                onChange={handleChange}
                readOnly={readonlyPage === 0 ?false :true}
                
            />)
        }
        {
            readonlyPage === 0 &&
            <img className='icoEdit1' src={icoEdit} onClick={() => handleUpdate()}/>
        }
        {

            readonlyPage === 0 &&
            <div className='container-del'>
            <img className='icoDelete' src={icoDelete} onClick={() => handleDelete()}/>
            </div>
        }
        
        </div>

    )
}
