
import React, { useEffect, useState } from 'react'
import { ObjectConverter, StringConverter } from '../../shared/ObjectConverter';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationByLocationCodeStoreHose, getLocationByLocationCodeStoreHoseSuccess, searchProductsStoreHose, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';

export const  StoreHouseStockRigthTableItemsForm = ({productItemSelected, itemObtained}) => {
    
    const [locationObjectConverted, setLocationObjectConverted] = useState('');
    const [itemSelected, setItemSelected] = useState('');
    const [inputValue, setInputValue] = useState(itemObtained ?itemObtained  :'');
    const locationItemToAdd = useSelector(state => state.storeHouse.locationItemToAdd);
    const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);
    // const listScanningProducts = useSelector(state => state.storeHouse.listScanningProducts);
    const dispatch = useDispatch();
    
    useEffect(() => {
      return () => {
        dispatch(getLocationByLocationCodeStoreHoseSuccess(null))
      }
    }, [])
    

    useEffect(() => {
            setInputValue(itemObtained)
    }, [itemObtained])

    useEffect(() => {
        if(locationItemToAdd && Object.keys(locationItemToAdd).length > 0 && locationItemToAdd !== null && itemSelected && Object.keys(itemSelected).length > 0){
            let listItemsNew = [...appointmentItem.productRequirements];
            listItemsNew.splice(
                listItemsNew.findIndex(item => item.id === itemSelected.id), 1, 
                {
                    ...itemSelected,
                    locastionItem: locationItemToAdd.ubicacion,
                    locastionInfo: locationItemToAdd,  
                }); 
                let newAppointmentItem = {
                    ...appointmentItem,
                    productRequirements: listItemsNew,
                  }
                dispatch(setAppointmentsStoreHouse(newAppointmentItem))
                setLocationObjectConverted(null); 
                setItemSelected(null)
                dispatch(getLocationByLocationCodeStoreHoseSuccess(null));
        }
    }, [locationItemToAdd])
    

    const handleSubmitLocation = (e) => {
        e.preventDefault();
        // const exampleObject = '{"Warehouse”:”Cucharillas 2","NombeUbicacion":"Cucharillas 2","LinkSource":"http://linkparageneralelpdf.tsi.mx","NumberOfBox”:2,"TotalOfBoxes":5}';
        const locationObjectLocation = ObjectConverter(inputValue);
        const locationObjectProduct = StringConverter(inputValue);
        // const locationObject = ObjectConverter(exampleObject);
        if(locationObjectLocation.codigoUbicacion){
            
            let reg = locationObjectLocation.codigoUbicacion.replace(/['']+/g, '')
            setLocationObjectConverted(locationObjectLocation);
            dispatch(getLocationByLocationCodeStoreHose(reg));
        }
        if(locationObjectLocation.NombreUbicacion){
            setLocationObjectConverted(locationObjectLocation);
            dispatch(getLocationByLocationCodeStoreHose(locationObjectLocation.NombreUbicacion));
        }

        if(locationObjectProduct){
            dispatch( searchProductsStoreHose(locationObjectProduct, "productCode", true) );
        }
        
        setInputValue('');
    }


    return (
        <form action="" onSubmit={handleSubmitLocation} >
            <input type='text' className='storeHouseStockRigthTableItems-section-rigth-table-data-input'
                onClick={()=> setItemSelected(productItemSelected)}
                value={ inputValue }
                onChange={({target}) => setInputValue(target.value)}
            />
        </form>

    )
}
StoreHouseStockRigthTableItemsForm.propTypes = {
    productItemSelected: PropTypes.any,
    setListItemsState: PropTypes.any,
    listItemsState: PropTypes.any,
    setListSystemScann: PropTypes.any,
    listScanningProducts: PropTypes.any,
    itemObtained: PropTypes.any,
}