import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../../../components/shared/Modal';
import './styles_Modal.CXList.sass';
import { SearchCXModal } from '../../../components/ATQ/ATQ_CONSUM_REPORT/SearchCXModal';


export const ATQ_Modal_CXList = (props) => {
  
    const modalBody = () => {
        return (
        <SearchCXModal    
            toggle={props.toggle}
            setOpenModal={props.setOpenModal}
            setCxSelected  = {props.setCxSelected}

        />
    );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle} 
                body={modalBody()}
        />
    );
}

ATQ_Modal_CXList.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};
