
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useSelector } from 'react-redux';
import { useNotify } from './hooks/useNotify';

//const dispatch = useDispatch();


var firebaseConfig = {

  apiKey: "AIzaSyBzuSWX5p2EIZqBmQvI7aR2DZ-c9bopnVc",
  authDomain: "tsi-assistant-43085.firebaseapp.com",
  projectId: "tsi-assistant-43085",
  storageBucket: "tsi-assistant-43085.appspot.com",
  messagingSenderId: "1049012356519",
  appId: "1:1049012356519:web:402517681708870354a282"
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
       //console.log('Desde firebaseInit',payload)
      useNotify(payload)

      // let data = payload.data.ContentData.replace(new RegExp("}|{|\\\"", "g"), "").split(",").reduce((obj, keyValue) => {
      //   //console.log(keyValue);
      //   const [key, value] = keyValue.split(":");
      //   // console.log(key)
      //   obj = {
      //     ...obj,
      //     [key]: value
      //   }
      //   return obj;
      // }, {});


      // console.log("dataa", data);

      // if (data.NotificationAction === "1") {

      //   toast.info("Nuevo mensaje en " + data.Title, {
      //     position: "top-right",
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      // } else if (data.NotificationAction === "2") {
      //   toast.info(payload.data.title, {
      //     position: "top-right",
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });

      //   debugger
      // } else if (data.NotificationAction === "3" && payload.data.Body === "El material de tu cirugia esta en proceso de entrega") {
      //   toast.info(payload.data.title, {
      //     position: "top-right",
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",

      //   });
      // } else if (data.NotificationAction === "3" && payload.data.message === "El material de tu cirguia fue entregado") {
      //   toast.info(payload.data.title, {
      //     position: "top-right",
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      // } else if (data.NotificationAction === "3" && payload.data.message === "El material fue retornado a almacen") {
      //   toast.info(payload.data.title, {
      //     position: "top-right",
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });

      // }


      // let notificaciones = {

      //   Notificacion: [
      //     {
      //       uid: shortid.generate(),
      //       NotificationAction: data.NotificationAction,
      //       AppointmentId: data.AppointmentId,
      //       ChatGroupId: data.ChatGroupId,
      //       Title: data.Title,
      //       Message: data.Body,
      //       Estado: true,
      //       Tiempo: moment().format(),
      //     },
      //   ]
      // }

      // const local = getNewNotification("Newnotificacion")

      // if (local && local.length > 0) {
      //   let notificaciones1 = [...notificaciones.Notificacion, ...local,];
      //   localStorage.setItem("Newnotificacion", JSON.stringify(notificaciones1));
      // } else {
      //   localStorage.setItem("Newnotificacion", JSON.stringify(notificaciones.Notificacion));
      // }

      resolve(payload);
    });
  });




// const getNewNotification = (valor) => {
//   let newNotification = [];
//   if (localStorage.getItem(valor)) {
//     newNotification = JSON.parse(localStorage.getItem(valor));
//   }

//   return newNotification;
// }

export const getToken2 = (setTokenFound) => {
  
  return getToken(messaging, { vapidKey: 'BO1HXjMrlZQjsKlGVaCUUK64jcVpMHNLfTh24lk9CdmWi5u9C3l3Ayzg5Roi4wAPM7T0JVGWP2jDf2cW4P6PK20' }).then((currentToken) => {
    
    if (currentToken) {
      /* console.log('current token for client: ', currentToken); */
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      //console.log('No registration token available. Request permission to generate one.');
      setTokenFound(null);

      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    //console.error('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

