import React from 'react'
import { ModalImg } from './ModalImg';
import PropTypes from 'prop-types';
import CustomModal from '../../../shared/Modal';
import './styleImg.scss';

export const ATQ_Img_View = (props) => {

    const modalBody = () => {
        return (
            <ModalImg
                type={props.type}
                toggle={props.toggle}
                state={props.state}
                textError={props.textError}
            />
        );
    };

    return (
        <CustomModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            body={modalBody()}
        />
    );
}

ATQ_Img_View.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};