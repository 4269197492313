import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './sass/_GroupChat.scss'
import black_arrow from '../../assets/img/126PX/black_arrow.png';
import img_msj_group from '../../assets/img/512PX/Icono_GroupMsj_Azul.png'
import { create_chat_group_success, getChatGroup, get_chat_group_success, openMessageGeneral, searchGroupSurgery, setChatGroupSelected } from '../../actions/notification-chatAction';


export const GroupChat = ({ setTitle, title }) => {

    const chatGroup = useSelector(state => state.notificationChat.getGroup)
    const dispatch = useDispatch();
    const groupCreate = useSelector(state => state.notificationChat.createGroup)
    const openMessagesGeneral = useSelector(state => state.notificationChat.openMessageGeneral);
    const appoimentId = useSelector(state => state.notificationChat.setSurgeryChatSelected);
    const searchGroup = useSelector(state => state.notificationChat.searchGroup)
    

    useEffect(() => {
        dispatch(searchGroupSurgery([]))
        return () => {
            dispatch(create_chat_group_success([]))
            dispatch(get_chat_group_success([]))
        }
    }, [])

    
  useEffect(() => {
    if(groupCreate && groupCreate.id){
      dispatch(getChatGroup(appoimentId, 0, 10, true))
    }
  }, [groupCreate])

    const openModalChat = (idchat, nameChat) => {
        dispatch(openMessageGeneral(!openMessagesGeneral, "CHAT"))
        dispatch(setChatGroupSelected(true, idchat, nameChat))

    }

    return (
        <div className="MessagesChatComponent-container-ChatGroup">
            {
           
                chatGroup && chatGroup.length > 0 && searchGroup && searchGroup.length > 0 ?
                searchGroup &&
                searchGroup.map(item => (
                    <div className={"MessagesChatComponent-preContainer-ChatGroup MessagesChatComponent-preContainer-inactive-ChatGroup"}
                        onClick={() => openModalChat(item.id, item.chatGroupName)}>
                        <div className="MessagesChatComponent-info-container-ChatGroup">
                            <img src={img_msj_group} className='img-user-chat-modal-group'></img>

                            <div className="MessagesChatComponent-container-info-ChatGroup" >

                                <p className="MessagesChatComponent-info-nameChat" >{item.chatGroupName && item.chatGroupName}</p>

                            </div>
                            <img src={black_arrow} alt="" className="MessagesChatComponent-info-arrow-ChatGroup" />
                        </div>
                    </div>
                ))


                :

                chatGroup &&
                chatGroup.map(item => (
                    <div className={"MessagesChatComponent-preContainer-ChatGroup MessagesChatComponent-preContainer-inactive-ChatGroup"}
                        onClick={() => openModalChat(item.id, item.chatGroupName)}>
                        <div className="MessagesChatComponent-info-container-ChatGroup">
                            <img src={img_msj_group} className='img-user-chat-modal-group'></img>

                            <div className="MessagesChatComponent-container-info-ChatGroup" >

                                <p className="MessagesChatComponent-info-nameChat" >{item.chatGroupName && item.chatGroupName}</p>

                            </div>
                            <img src={black_arrow} alt="" className="MessagesChatComponent-info-arrow-ChatGroup" />
                        </div>
                    </div>
                ))
            }

        </div>
    )
}
