
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import "./Calendar.scss";
import * as DateHolidays from 'date-holidays';
import moment from 'moment';
import { getMonth } from '../../hooks/useFormatDateInitialAndFinalDay';

// mport { 
//   HolidayRule,
//   Holidays,
// } from 'date-holidays'

const {
  format,
  isSameMonth,
  isSunday,
  isSameDay,

  startOfMonth,
  startOfWeek,
  startOfDay,

  endOfMonth,
  endOfWeek,

  addDays,
  getYear,
  addMonths,
  subMonths,
} = require('date-fns');


const setMonth = {
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  August: 'Agosto',
  September: 'Septiembre',
  October: 'Octubre',
  November: 'Noviembre',
  December: 'Diciembre'
};

function takeWeek(start) {
  let date = startOfWeek(startOfDay(start));

  return function () {
    const week = [...Array(7)].map((_, i) => addDays(date, i));
    date = addDays(week[6], 1);
    return week;
  };
}



function CalendarComponent({ selectedDateOnChange, selectedDate: selectedDateItem, setNewFinalDayMonth, page = 'agenda', newFinalDayMonth, changeMonth }) {

  const [selectedDate, setSelectedDate] = useState(new Date());
  const appointmentsListCalendar = useSelector(state => state.storeHouse.appointmentsListCalendar);
  const listGuardDays = useSelector(state => state.atqAdministrador.holidays);
  const [holidayMessage, setHolidayMessage] = useState("");
  const appointmentDate = useSelector(state => state.storeHouse.nextAppointment);





  const holidays = new DateHolidays.default();
  holidays.init('MX');
  //console.warn(holidays)

  const formatDate = () => {
    return `${moment(selectedDate).format('DD')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('YYYY')}`;
  }


  useEffect(() => {
    selectedDateOnChange(selectedDate);
  }, [selectedDate])


  function takeMonth(start = selectedDate) {
    let month = [];
    let date = start;

    function lastDayOfRange(range) {
      return range[range.length - 1][6];
    }

    return function () {
      const weekGen = takeWeek(startOfMonth(date));
      const endDate = startOfDay(endOfWeek(endOfMonth(date)));
      month.push(weekGen());

      while (lastDayOfRange(month) < endDate) {
        month.push(weekGen());
      }

      const range = month;
      month = [];
      date = addDays(lastDayOfRange(range), 1);

      return range;
    };
  }

  const compareDates = () => {
    if (format(selectedDate, 'dd/MM/yyyy') === format(new Date(), 'dd/MM/yyyy')) {
      return "Hoy"
    } else {
      return format(selectedDate, 'dd/MM/yyyy')
    }
  }

  const dayColor = (day) => {
    if (!isSameMonth(day, selectedDate)) {
      return 'dissapear-text';
    } else {
      if (isSameDay(day, selectedDate)) {
        //return 'active';
      } else {
        if (isSunday(day, selectedDate)) {
          return 'green-sunday';
        }
      }
    }
  }

  const prevMonth = () => {
    if (page === 'agenda') {
      changeMonth()

    }
    setHolidayMessage("")
    setSelectedDate(subMonths(selectedDate, 1));

  }

  const nextMonth = () => {
    if (page === 'agenda') {
      changeMonth()
    }
    setHolidayMessage("")
    setSelectedDate(addMonths(selectedDate, 1));
  }

  const data = takeMonth()();
  useEffect(() => {
    let finalDayDate = 0;
    data.forEach(arrayDate => {
      arrayDate.forEach(item => {
        if (isSameMonth(item, selectedDate)) {
          finalDayDate++;
        }
      })
    });

    setNewFinalDayMonth(finalDayDate);

  }, [selectedDate])


  const showHoliday = (day, message = "") => {

    setSelectedDate(day)
    //console.log('message2', message)
    setHolidayMessage(message);
  }

  useEffect(() => {
    if (appointmentDate) {
      setSelectedDate(new Date(appointmentDate[0].surgeryDate))
    }
  }, [appointmentDate])







  return (
    <div className={"admin-schedule-content-box"}>
      <div className={"admin-schedule-calendar"}>
        <div className="month">
          <ul>

            <li className="prev" onClick={prevMonth}>«</li>
            <li className="next" onClick={nextMonth}>»</li>

            <li>
              {setMonth[format(selectedDate, 'LLLL')]}
              <span> {getYear(selectedDate)}</span>
            </li>
          </ul>
        </div>

        <ul className="weekdays">
          <li style={{ padding: '10px' }}>Dom</li>
          <li style={{ padding: '10px' }}>Lun</li>
          <li style={{ padding: '10px' }}>Mar</li>
          <li style={{ padding: '10px' }}>Mié</li>
          <li style={{ padding: '10px' }}>Jue</li>
          <li style={{ padding: '10px' }}>Vie</li>
          <li style={{ padding: '10px' }}>Sáb</li>
        </ul>

        <ul className="days">
          {data.map((week, indexWeek) => (
            <>
              {week.map((day) => {
               
                const ele = Object.keys(holidays.holidays).find(el => {
                  if (el.search(/[a-z]/ig) < 0) { //Entra mientras no incluya letra
                    const currentYear = new Date().getFullYear();
                    const holiday = new Date(el + "-" + currentYear)
                    if (format(holiday, "dd").includes(format(day, "dd")) && isSameMonth(day, holiday)) {
                      return true;
                    }
                    return false;
                  }
                })
                return (
                  <li key={day.id}
                  // onClick={() => {page === 'agenda' ? () => setSelectedDate(day) : showHoliday(day)}}
                  onClick={() => { ele && page === "roldeGuardia" ? showHoliday(day, `${format(new Date(day), "dd")} ${holidays.holidays[ele].name.es}`) : showHoliday(day) }}
                  >

                    {
                      page === 'roldeGuardia' && page != 'agenda'
                        ? (listGuardDays && listGuardDays.length > 0 && listGuardDays.map(el => format(new Date(el.holidayDate), "dd")).includes(format(day, "dd")) && isSameMonth(day, selectedDate)
                          ? <span
                            onClick={() => { ele && page === "roldeGuardia" ? showHoliday(day, `${format(new Date(day), "dd")} ${holidays.holidays[ele].name.es}`) : showHoliday(day) }}
                            className={"guardDaysActive"}>{format(day, "dd")}</span>
                          : <span
                            onClick={() => { ele && page === "roldeGuardia" ? showHoliday(day, `${format(new Date(day), "dd")} ${holidays.holidays[ele].name.es}`) : showHoliday(day) }}
                            className={`${dayColor(day)}`}>{format(day, "dd")}</span>)

                        : <></>

                    }

                    {
                      page === 'agenda' &&

                      <>
                        <span className={`${dayColor(day)}`}>{format(day, "dd")}</span>
                        {
                          appointmentsListCalendar && appointmentsListCalendar.map(el => format(new Date(el.surgeryDate), "dd")).includes(format(day, "dd")) && isSameMonth(day, selectedDate)
                          &&
                          <span className={"CalendarComponent-day-appointment"} > </span>
                        }
                      </>

                    }
                    {
                      page === 'roldeGuardia' &&
                      appointmentsListCalendar && appointmentsListCalendar.map(item => format(new Date(item.surgeryDate), "dd")).includes(format(day, "dd")) && isSameMonth(day, selectedDate) &&
                      <span className={"CalendarComponent-day-appointment"}>
                      </span>
                    }
                    {
                      page === 'roldeGuardia' &&
                      ele &&
                      <span className={"CalendarComponent-day-holiday-appointment"} onClick={() => setHolidayMessage(holidays.holidays[ele].name.es)}
                      ></span>

                    }
                    {/* {
                      moment(selectedDateItem).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD') && page === "agenda" &&
                         <span style={{background: "red"}}>{format(day, "dd")}</span>
                        
                    }  */}


                  </li>
                )
              })}
            </>
          ))}
        </ul>
      </div>



      {holidayMessage !== "" &&

        <div className='container-show-holiday'>

          <div className='CalendarComponent-day-appointment'>
          </div>

          <span className={"CalendarComponent-day-holiday-view-appointment"}> {holidayMessage}</span>

        </div>

      }









      <span className={"admin-schedule-title"}>
        {//compareDates()
        }
      </span>


    </div>

  );
}

export default CalendarComponent;
