import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import { useSelector } from 'react-redux';
import './styleContactos.scss';
import { SubNavBarComponent } from "../../components/layouts/layout1/subnavbar/SubNavBarComponent";
import { Button, TextField } from '@material-ui/core';
import { getContacts } from "../../actions/userActions";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { type } from "jquery";

export const Contactos = () => {
    const requestH = useSelector((state) => state.user.request);
    const gridRef = useRef();

    const [rfcFilterValue, setRfcFilterValue] = useState('')
    const [emailFilterValue, setEmailFilterValue] = useState('')
    const [contactNameValue, setContactNameValue] = useState('')
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        getContactos();
    }, [])

    const getContactos = async () => {
        await getContacts(requestH).then(resp => {
            if (resp.response && resp.response.length > 0) {
                setContacts(resp.response)
            }
        });
    }

    /* Table */
    const pagination = true;
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [5, 10, 15, 20, 25, 50, 100, 500, 1000];
    const rowHeight = 70;
    const showScroll = true;

    const tableColumns = [
        {
            field: 'id', headerName: 'ID', suppressMovable: true, sortable: true, 
            minWidth: 100, maxWidth: 150, flex: 1 
        },
        {
            field: 'firstName', headerName: 'Nombre', type: 'contactName', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true
        },
        {
            field: 'firstLastName', headerName: 'Apellido Paterno', type: 'apPat', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        },
        {
            field: 'secondLastName', headerName: 'Apellido Materno', type: 'apMat', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        },
        {
            field: 'phone', headerName: 'Teléfono', type: 'phone', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        },
        {
            field: 'email', headerName: 'Email', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        },
        {
            field: 'rfc', headerName: 'RFC', type: 'rfc', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        },
        {
            field: 'comentario', headerName: 'Comentario', type: 'comment', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        },
        {
            field: 'state', headerName: 'Estado', type: 'state', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        },
        {
            field: 'city', headerName: 'Ciudad', type: 'city', suppressMovable: true, sortable: false, 
            wrapText: true, autoHeight: true 
        }
    ]

    const defaultColDef = useMemo(() => {
        return {
            wrapHeaderText: true,
            autoHeaderHeight: true,
            headerStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
            }),
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            })
        };
    }, []);

    const columnTypes = useMemo(() => {
        return { 
            clientName: {
                valueFormatter: getClientName
            },
            apPat: {
                valueFormatter: getLastName
            },
            apMat: {
                valueFormatter: getSecondLastName
            },
            phone: {
                valueFormatter: phoneFormat
            },
            rfc: {
                valueFormatter: getRFC
            },
            comment: {
                valueFormatter: getComment
            },
            state: {
                valueFormatter: getState
            },
            city: {
                valueFormatter: getCity
            }
        };
    }, []);

    const autoSizeStrategy = {
        type: 'fitGridWidth',
        //type: 'fitCellContents',
        defaultMinWidth: 100,
    };

    function getClientName(data) {
        if (data.data.firstName) {
            if (data.data.firstName == 'null') {
                return '-'
            }
            return data.data.firstName
        } else {
            return '-'
        }
    }

    function getLastName(data) {
        if (data.data.firstLastName) {
            if (data.data.firstLastName == 'null') {
                return '-'
            }
            return data.data.firstLastName
        } else {
            return '-'
        }
    }

    function getSecondLastName(data) {
        if (data.data.secondLastName) {
            if (data.data.secondLastName == 'null') {
                return '-'
            }
            return data.data.secondLastName
        } else {
            return '-'
        }
    }

    function phoneFormat(data) {
        if (data.data.phone) {
            if (data.data.phone == 'null') {
                return '-'
            }
            return `(${data.data.phone.slice(0,3)}) ${data.data.phone.slice(3,6)}-${data.data.phone.slice(6,10)}`;
        } else {
            return '-'
        }
    }

    function getRFC(data) {
        if (data.data.rfc) {
            if (data.data.rfc == 'null') {
                return '-'
            }
            return data.data.rfc
        } else {
            return '-'
        }
    }

    function getComment(data) {
        if (data.data.comentario) {
            if (data.data.comentario == 'null') {
                return '-'
            }
            return data.data.comentario
        } else {
            return '-'
        }
    }

    function getState(data) {
        if (data.data.state) {
            if (data.data.state == 'null') {
                return '-'
            }
            return data.data.state
        } else {
            return '-'
        }
    }

    function getCity(data) {
        if (data.data.city) {
            if (data.data.city == 'null') {
                return '-'
            }
            return data.data.city
        } else {
            return '-'
        }
    }

    //FILTROS DE BÚSQUEDA
    var rfcFilter = "";
    var emailFilter = "";
    var contactNameFilter = "";

    const cleanFilters = () => {
        setRfcFilterValue('');
        setEmailFilterValue('');
        setContactNameValue('');

        rfcFilter = "";
        emailFilter = "";
        contactNameFilter = "";

        externalFilterChanged("", "", "");
        
    }

    const externalFilterChanged = useCallback((rfc, email, client) => {
        rfcFilter = rfc.toUpperCase();
        emailFilter = email;
        contactNameFilter = client;
        gridRef.current.api.onFilterChanged();
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        if (rfcFilter) {
            return rfcFilter !== ""
        } else if (emailFilter) {
            return emailFilter !== ""
        } else if (contactNameFilter) {
            return contactNameFilter !== ""
        } else {
            return false
        }
    }, []);

    const doesExternalFilterPass = useCallback(
        (node) => {
            if (node.data) {
                return (
                    node.data.rfc.includes(rfcFilter) &&
                    node.data.email.includes(emailFilter) &&
                    node.data.firstName.includes(contactNameFilter)
                )
            }
            return true;
        },
        []
    );

    const searchValues = () => {
        externalFilterChanged(rfcFilterValue, emailFilterValue, contactNameValue)
    }

    return (
        <div className='container'>
            <SubNavBarComponent title={'CONTACTOS'} />

            {/* -------------------------- TABLE ----------------------------- */}
            <div className='tableContainer'>
                <div style={{ width: '100%', display: 'flex', fontSize: '18px', fontFamily: 'Montserrat, sans-serif', color: '#094070',
                            fontWeight: '700' }}>
                    FILTROS DE BÚSQUEDA
                </div>
                <div className='filterContainer'>
                    <TextField
                        margin='dense'
                        label='RFC'
                        variant='outlined'
                        type='text'
                        value={rfcFilterValue}
                        onChange={(event) => { setRfcFilterValue(event.target.value) }}
                        style={{ marginRight: '10px', width: 'auto' }}
                        inputProps={{ style: { textTransform: "uppercase" }, pattern: '^[\wÀ-ÿ\u00f1\u00d1]+(\s[\wÀ-ÿ\u00f1\u00d1]+)*$', maxLength: 16 }}
                    />

                    <TextField
                        margin='dense'
                        label='Email'
                        variant='outlined'
                        type='text'
                        value={emailFilterValue}
                        onChange={(event) => { setEmailFilterValue(event.target.value) }}
                        style={{ marginRight: '10px', width: 'auto' }}
                        inputProps={{ pattern: '^[\wÀ-ÿ\u00f1\u00d1]+(\s[\wÀ-ÿ\u00f1\u00d1]+)*$' }}
                    />

                    <TextField
                        margin='dense'
                        label='Nombre'
                        variant='outlined'
                        type='text'
                        value={contactNameValue}
                        onChange={(event) => { setContactNameValue(event.target.value) }}
                        style={{ marginRight: '10px', width: 'auto' }}
                        inputProps={{ pattern: '^[\wÀ-ÿ\u00f1\u00d1]+(\s[\wÀ-ÿ\u00f1\u00d1]+)*$' }}
                    />

                    <button className='filterButtons' onClick={searchValues}>
                        BUSCAR
                    </button>

                    <button className='filterButtons' onClick={() => cleanFilters()}>
                        LIMPIAR FILTROS
                    </button>
                </div>

                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={{ height: 700 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact 
                        ref={gridRef}
                        rowData={contacts}
                        columnDefs={tableColumns}
                        columnTypes={columnTypes}
                        defaultColDef={defaultColDef}
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        paginationPageSizeSelector={paginationPageSizeSelector}
                        rowHeight={rowHeight}
                        autoSizeStrategy={autoSizeStrategy}
                        alwaysShowHorizontalScroll={showScroll}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                    />
                </div>
            </div>
        </div>
    )
}