import React from 'react'
import './styleNotificacionTable.scss';


export const NotificacionTableComponent = ({ typeListTable, type }) => {

    return (
        <>
            {type === "surtido" &&

                <div className="ATQAdministrator_Notificacion_ATQ_TableUbication-precontainer_table">
                    <div className="ATQAdministrator_Notificacion_ATQ_TableUbication-container_table">
                        <table className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-container_table">
                            <thead className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-thead_table">
                                <tr className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-row_table">
                                    <th className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-0">{'Material surtido'}</th>
                                </tr>
                            </thead>
                            <thead className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-thead_table">
                                <tr className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-row_table">
                                    <th className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-1">Ubicación</th>
                                    <th className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-2">Cantidad</th>
                                </tr>
                            </thead>

                            {
                                typeListTable &&
                                typeListTable.length > 0 &&
                                typeListTable.map((item) => (
                                    <tbody className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body_table">
                                        <tr className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body-row_table">
                                            <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_1">{item.Ubicacion && item.Ubicacion}</td>
                                            <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_2">{item.productQuantity && item.productQuantity}</td>
                                        </tr>
                                    </tbody>

                                ))

                            }
                        </table>
                    </div>
                </div>

            }

            {type === "no-surtido" &&

                <div className="ATQAdministrator_Notificacion_ATQ_TableUbication-precontainer_table">
                    <div className="ATQAdministrator_Notificacion_ATQ_TableUbication-container_table">
                        <table className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-container_table">
                            <thead className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-thead_table">
                                <tr className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-row_table">
                                    <th className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-0">{'Material sin surtir'}</th>
                                </tr>
                            </thead>
                            <thead className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-thead_table">
                                <tr className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-row_table">
                                    <th className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-1">Producto</th>
                                    <th className="ATQAdministrator_Notificacion_ATQ_TableUbication-table-title_table-2">Cantidad</th>
                                </tr>
                            </thead>

                            {
                                typeListTable &&
                                    typeListTable[0].length > 0 ?
                                    typeListTable[0].map((item) => (
                                        <tbody className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body_table">
                                            <tr className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body-row_table">
                                                <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_1">{item.product && item.product.productDescription}</td>
                                                <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_2">{item.systemLevels && item.systemLevels}</td>
                                            </tr>
                                        </tbody>

                                    ))

                                    :
                                    typeListTable &&
                                    typeListTable.length > 0 &&
                                    typeListTable.map((item) => (
                                        <tbody className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body_table">
                                            <tr className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body-row_table">
                                                <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_1">{item.product && item.product.productDescription}</td>
                                                <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_2">{item.systemLevels && item.systemLevels}</td>
                                            </tr>
                                        </tbody>

                                    ))

                            }

                            {/* <tbody className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body_table">
                                <tr className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body-row_table">
                                    <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_1">ADD PLUS 12</td>
                                    <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_2">2.0</td>
                                </tr>
                                <tr className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body-row_table">
                                    <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_1">ADD PLUS 12</td>
                                    <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_2">2.0</td>
                                </tr>
                                <tr className="ATQAdministrator_notificacion_ATQ_TableUbication-table-body-row_table">
                                    <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_1">ADD PLUS 12</td>
                                    <td className="ATQAdministrator_notificacion_ATQ_TableUbication-table-data_table_2">2.0</td>
                                </tr>

                            </tbody> */}
                        </table>
                    </div>
                </div>

            }


        </>



    )
}
