import React, { useState } from 'react';

export const ModalATQ = (props) => {

    const [valor,setValor] = useState(1);
    const handleSubmit = () => {
        props.toggle();
    }
    return (
        <div className="atq-product">
        {/* <div className="atq-product-c-list-items"> */}
        

        <div className= 'paperSchedule'>
            <label className='labelCounter'>Cantidad</label>
            <div className='productBox'>
                <span className='txt-cod'>CS 2253-12-13</span>
                <span className='txt-des'>Addplus -d 12 mm,Altura 13-18 mm, 0º.</span>
            </div>

            <div className='carousel'>
                <input 
                        onChange={(e) => {
                            if(e.target.value > 0){
                                setValor(e.target.value)
                            }else{
                                setValor(1)
                            }
                        }
                    }
                        autoFocus
                        type='number'
                        value={valor} 
                        className='counter' />

                <span className="focus"></span>
                <button className='continueButton' onClick={() => handleSubmit()}>
                        ACEPTAR
                </button>
            </div>
            
        </div>
                    
        
   {/* </div>*/}
    </div>
    )
}
