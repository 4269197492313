import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './sass/styles.scss';
import { RiDeleteBin6Line } from "react-icons/ri";
import { setManageObservationSucces } from '../../actions/scheduleSurgeryAction';
import PropTypes from 'prop-types';
import { AddObservationOrNoteModal } from './AddObservationOrNoteModal';
import { addSellerNotes, deleteWareHouseNote, getSearchList, getSearchListHouseSuccessAtq, setAppointmentsStoreHouse } from '../../actions/storeHouseAction';


export const PreAddOverviewComponent = ({ listElements, title = '', messageEmpty = '', messageButton = '', type }) => {

    const [openModalObservation, setOpenModalObservation] = useState(null);
    const [listObservationItems, setListObservationItems] = useState([]);
    const cxSelected = useSelector(state => state.storeHouse.appointmentItem);
    const startDate = '1900-01-01T01:01:57'
    const endDate = '2200-05-06T01:01:33'
    const notes = useSelector(state => state.storeHouse.listSellerNotes);
    const search = useSelector(state => state.storeHouse.listSearch)

    const dispatch = useDispatch();

  
    useEffect(() => {
        if (!listElements && listElements) {
        setListObservationItems([...listObservationItems]);
        }
    //    setListObservationItems([...listElements]);        
}, [])


    useEffect(() => {
            setListObservationItems([...listElements]);
        //    setListObservationItems([...listElements]);        
    }, [listElements])

    
    const handleToggleModal = () => {
        setOpenModalObservation(null);
    }


    useEffect(() => {

            dispatch(getSearchList(startDate, endDate, 15,cxSelected && cxSelected.id))
        
    }, [notes])

    useEffect(() => {
        if (search && search[0]) {
            setListObservationItems([...search[0].appointmentWarehouseNotes])
            dispatch(getSearchListHouseSuccessAtq([]))
        }
    }, [search])


    const handleDeleteItem = (observationId) => {
        const newListObservations = listObservationItems && listObservationItems.filter(el => el.id !== observationId.id);
        if (type === 'VENTAS') {
            dispatch(setManageObservationSucces(newListObservations));
        } else if (type === 'ALMACEN') {
            setListObservationItems([...newListObservations]);
            dispatch(deleteWareHouseNote(observationId.id));
        }
    }

    const handleAddWareHouseNote = (note) => {
        let newArray = [...listObservationItems];
        newArray.push(note);
        setListObservationItems([...newArray]);
    }

    return (
        <article className="schedulePreOrder-left-item">

            <div className='schedulePreOrder-left-item-items'>
                <h3 className='schedulePreOrder-left-item-item-title'>{title}</h3>
                <ol className='schedulePreOrder-left-item-list'>
                    {
                        listObservationItems &&
                        listObservationItems.length > 0 &&
                        listObservationItems.map((el, index) => (

                           [el].map((comment) => (

                                <li className='schedulePreOrder-left-item-list-item' key={comment.id}>
                                    <span>{`${index + 1}.- ${comment.description}`}</span>
                                    {

                                        <RiDeleteBin6Line className={'schedulePreOrder-left-item-list-item-img-delete'} onClick={() => handleDeleteItem(comment)} />
                                    }
                                </li>

                            ))

                        ))
                    }
                    {
                        (listObservationItems &&
                            listObservationItems.length == 0
                            ||
                            !listObservationItems) &&
                        <li className='schedulePreOrder-left-item-list-item'>
                            {messageEmpty}
                        </li>
                    }
                </ol>
            </div>
            <div className='schedulePreOrder-section-left-subTitle-container schedulePreOrder-section-left-subTitle-container-observation ' onClick={() => setOpenModalObservation('NEW_OBSERVATION')}>
                <p className='schedulePreOrder-left-subTitem-title'>{messageButton}</p>
            </div>
            {
                openModalObservation && type === 'VENTAS' &&
                <AddObservationOrNoteModal
                    toggle={handleToggleModal}
                    nameAndTypeModal={{ title: "Nueva Observación", type, titleModal: "Nueva Observación" }}
                    isOpen={!!openModalObservation}
                />
            }
            {
                openModalObservation && type === 'ALMACEN' &&
                <AddObservationOrNoteModal
                    toggle={handleToggleModal}
                    nameAndTypeModal={{ title: "NOTAS DE ALMACÉN", type, titleModal: 'NOTAS ALMACÉN' }}
                    isOpen={!!openModalObservation}
                    handleAddWareHouseNote={handleAddWareHouseNote}
                />
            }
        </article>
    )
}


PreAddOverviewComponent.propTypes = {
    listElements: PropTypes.any,
    title: PropTypes.any,
    messageEmpty: PropTypes.any,
    messageButton: PropTypes.any,
    type: PropTypes.any,
}

