import React from 'react';

export const QualityAnalisisModal = () => {

    const analisisModal = [
        {
            id: 1,
            codigo: 'CS 2253-12-13',
            descripcion: 'Addplus -d 12 mm, altura 13-18 mm, 0º.',
        },
        {
            id: 4,
            codigo: 'CS 2253-12-13',
            descripcion: 'Addplus -d 12 mm, altura 13-18 mm, 0º.',
        },
        {
            id: 2,
            codigo: 'CS 2253-12-13',
            descripcion: 'Addplus -d 12 mm, altura 13-18 mm, 0º.',
        },
    ];


    return (

        <div className='container_analisis_modal'>
            <table className='table_analisis'>
                <thead className='thead_analisi'>
                    <tr className='product_fila'>
                        <th className='product_material-title' >Descripción</th>
                        <th className='product_material-title'>ID Producto</th>
                        <th className='product_material-title'>Cantidad</th>
                    </tr>                    
                </thead>
                <tbody>
                {analisisModal.map((analisis) => (
                <tr>
                    <td className='analisis_body_modal'>{analisis.descripcion}</td>
                    <td className='analisis_body_modal'>{analisis.codigo}</td>
                    <td className='analisis_body_modal'>{analisis.id}</td>

                </tr>

                )
                )}

                </tbody>
            </table>
        </div>
    )
}
