import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ATQ_Modal_with_Img } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_with_Img';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHomeReceivingMaterialReportComponent } from '../../../components/StoreHouseComponents/StoreHomeReceivingMaterialReportComponent/StoreHomeReceivingMaterialReportComponent';
import './sass/styles.scss';

export const StoreHomeReceivingMaterialReportScreen = () => {
    const history = useHistory();
    const surgerySelected = useSelector((state) => state.atqAdministrador.surgeryChosed);
    const [openModal, setOpenModal] = useState(false);
    const toggle = () => {
        setOpenModal(false)
      };
      const closeModal = () => {
        setOpenModal(false);
    }

    const handleNavigate = () => {
        history.replace(`/StoreHouse/StoreHomeReceiving/recepcion`);
    }
    const [surtido,setSurtido] = useState(null);
    
    const handleAceptar = () => {
        let ban = true
        surtido.map(item => {
            if(item.locationBoxes){
                /* console.warn('item: ',item) */
                if(item.isReturned){
                    Object.keys(item.locationBoxes).map((box) => {
                        if(!item.locationBoxes[box].isReturned){
                            ban = false;
                        }
                    })
                }else{
                    Object.keys(item.locationBoxes).map((box) => {
                        if(item.locationBoxes[box].isReturned){
                            ban = false;
                        }

                    })
                }
            }
        })
        if(ban === true){
            handleNavigate()
        }else{
            setOpenModal(true);
        }
    }

    return (
        <div className="StoreHomeReceivingMaterialReportScreen-container">
            <SubNavBarComponent title={'RECEPCIÓN Y REVISIÓN DE MATERIAL'} historyPage={`/Reception`}/>
            <div className="StoreHomeReceivingMaterialReportScreen-table-container">
                <div className="StoreHomeReceivingMaterialReportScreen-title-container">
                    <button className="StoreHomeReceivingMaterialReportScreen-title">
                        CX# {surgerySelected && surgerySelected.id}
                    </button>
                </div>
                <StoreHomeReceivingMaterialReportComponent surtido={surtido} setSurtido={setSurtido}/>
                <div className="StoreHomeReceivingMaterialReportScreen-continue-button-container">
                    <button className="StoreHomeReceivingMaterialReportScreen-continue-button" onClick={ () => handleAceptar()}>
                        CONTINUAR
                    </button>
                </div>
            </div>
            {
            openModal == true &&
            <ATQ_Modal_with_Img
                    isOpen
                    toggle={closeModal}
                    type={'onlyText'}
                    text1={'POR FAVOR AGREGUE TODOS LOS DATOS NECESARIOS PARA EL SISTEMA'}/>
            }
        </div>
    )
}





