import React, { useEffect, useState } from 'react';
import { SideBarComponent } from '../sidebar/SideBarComponent';
import './sass/styles.sass';
import { useDispatch, useSelector } from 'react-redux';
import icoMenuAzul from '../../../../assets/img/512PX/Icono_Menu_Azul_512px.png';
import icoChatBlanco from '../../../../assets/img/512PX/Icono_Chat_Blanco_512px.png';
import icoNotificacionesBlanco from '../../../../assets/img/512PX/Icono_Notificaciones_Blanco_512px.png';
import icoAjustesBlanco from '../../../../assets/img/512PX/Icono_Ajustes_Blanco_512px.png';
import icoLogoutBlanco from '../../../../assets/img/512PX/Icono_CerrarSesion_Blanco_512px.png';
import icoTraumaService from '../../../../assets/img/512PX/Logo_TSI_512px.png';
import { initialSideActive } from '../../../../actions/sidebar';
import { NavLink, useHistory } from 'react-router-dom';
import { userLogout } from '../../../../actions/userActions';
import { resetToInitialStateQuote } from '../../../../actions/newQuoteAction';
import { resetToInitialState } from '../../../../actions/systemsAction';
import { ChatComponent } from '../../../ChatComponent/ChatComponent';
import { NotificationComponent } from '../../../NotificationComponent/NotificationComponent';
import { getMessagesToChatGroup, openMessageGeneral, openNotificacionGeneral, setStateNewNotification, suscrive_for_receive_not_success, unsuscribeForNotification } from '../../../../actions/notification-chatAction';
import { onMessageListener } from '../../../../firebaseInit';
import icono_menssages_active from '../../../../assets/img/256PX/Icono_Mensajes_Active.png';
import icono_notification_active from '../../../../assets/img/512PX/Icono_notificacion_Azul.png';
import { toast } from 'react-toastify';


export const NavbarComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenId = localStorage.getItem("idToken");
    const token = localStorage.getItem("Token");
    const notificationChat = useSelector((state) => state.notificationChat.suscription);
    const [openElement, setOpenElement] = useState(false);
    const stateNewNotification = useSelector((state) => state.notificationChat.stateNewNotification);

    const openMessagesGeneral = useSelector(state => state.notificationChat.openMessageGeneral);
    const openMessagesGeneralType = useSelector(state => state.notificationChat.openMessaageGeneralType);

    const openNotificationGeneral = useSelector(state => state.notificationChat.openNotificationGeneral);
    const openNotificationGeneralType = useSelector(state => state.notificationChat.openNotificationGeneralType);
    const statusChat = useSelector(state => state.notificationChat.setChatGroupSelected)
    const chat = useSelector(state => state.notificationChat.setChatGroupSelected)

    const user = useSelector(state => state.user.user.identity.userID)
    const userInformation = useSelector(state => state.user.user.identity)

    // console.log('message', openMessagesGeneral)
    // console.log('type', openMessagesGeneralType)

    const handleLogout = () => {
        if (tokenId && notificationChat && notificationChat.id) {
            dispatch(unsuscribeForNotification(notificationChat.id))
        }

        dispatch(userLogout());
        dispatch(suscrive_for_receive_not_success(null));
        dispatch(resetToInitialStateQuote());
        dispatch(resetToInitialState());
        history.replace('');
    }

    const handleChangeSideBarActive = () => {
        dispatch(initialSideActive());
    }

    //Pertenece a notificaciones
    const [notification, setNotification] = useState({ title: '', body: '', ChatGroucontent: '' });
    onMessageListener().then(payload => {

        dispatch(setStateNewNotification(true))

        let cleanPayload = payload.data.ContentData.replace(/\\+|\"/g, "");
        const userIdIndex = cleanPayload.indexOf("UserID");
        const userId = cleanPayload.substring(userIdIndex + 7, cleanPayload.indexOf(',', userIdIndex))

        let data = payload.data.ContentData.replace(new RegExp("}|{|\\\"", "g"), "").split(",").reduce((obj, keyValue) => {
            const [key, value] = keyValue.split(":");
            obj = {
                ...obj,
                [key]: value
            }
            return obj;
        }, {});

        if (user != userId) {

            switch (data.NotificationAction && data.NotificationAction) {

                case "1":
                    toast.info("Nuevo mensaje en " + data.Title, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    break;

                case "2":
                    toast.info(payload.data.title, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;

                case "3":
                    toast.info(payload.data.title, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    break;
            }


        }



        if (data.NotificationAction === "1" && data.ChatGroupId == chat.chatId) {
            if (statusChat && statusChat.status) {
                dispatch(getMessagesToChatGroup(chat.chatId, 0, 50))
            }

        }

        // console.log('Entra Aqui', payload)
        // console.log('data desde nav', data)
        // console.log('openChat', statusChat)

        //setShow(true);setIsNewNotification
        setNotification({ title: payload.data.title, body: payload.data.message, ChatGroucontent: data.ChatGroupId })
    }).catch(err => console.error('fallo: ', err));


    const handleMessagesOpen = () => {
        if (openNotificationGeneral) {
            dispatch(openNotificacionGeneral(!openNotificationGeneral, "NOTIFICATION"))
            dispatch(openMessageGeneral(!openMessagesGeneral, "CHAT"))


        }
        dispatch(openMessageGeneral(!openMessagesGeneral, "CHAT"))

    }

    const hadleNotificationOpen = () => {
        dispatch(setStateNewNotification(false))
        if (openMessagesGeneral) {
            dispatch(openMessageGeneral(!openMessagesGeneral, "CHAT"))
            dispatch(openNotificacionGeneral(!openNotificationGeneral, "NOTIFICATION"))

        } else {
            dispatch(openNotificacionGeneral(!openNotificationGeneral, "NOTIFICATION"))

        }
    }

    useEffect(() => {
        return () => {
            dispatch(openMessageGeneral(false, "CHAT"))
            dispatch(openNotificacionGeneral(false, "NOTIFICATION"))

        }
    }, [])



    // console.log('openElement', openElement)
    return (
        <nav className='c-navbar'>
            <div className='c-navbar-sideBar'>
                <button
                    onClick={handleChangeSideBarActive}
                    className='btn-sideBar sideBar-logo'
                >
                    <img className='sideBar-logo-img' src={icoMenuAzul} alt="" />
                </button>
            </div>

            <div className='c-navbar-c-icoLogo'>
                <NavLink to='/Inicio' >
                    <img className='c-navbar-icoLogo' src={icoTraumaService} alt="" />
                </NavLink>
            </div>

            <div className="c-navbar-tools">
                <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '12vh', color:'grey' }}>{`${userInformation.firstName || ''} ${userInformation.secondName || ''} ${userInformation.firstLastName || ''} ${userInformation.secondLastName || ''}`}</p>

                <button className='tools-logo tools-logo-Chat' onClick={() => handleMessagesOpen()}>
                    {openMessagesGeneral === true ?
                        <img className='tools-logo-img tools-logo-Chat-img' src={icono_menssages_active} alt="active" />
                        :
                        <img className='tools-logo-img tools-logo-Chat-img' src={icoChatBlanco} alt="" />
                    }
                </button>


                <button className='tools-logo tools-logo-Notificaciones' onClick={() => hadleNotificationOpen()}>
                    <div className={`${stateNewNotification ? 'bolita-roja' : 'hidden'}`} ></div>
                    {openNotificationGeneral === true ?
                        <img className='tools-logo-img tools-logo-Chat-img' src={icono_notification_active} alt="active" />
                        :
                        <img className='tools-logo-img tools-logo-Notificaciones-img' src={icoNotificacionesBlanco} alt="" />
                    }

                </button>


                <button className='tools-logo tools-logo-Ajustes'>
                    <img className='tools-logo-img tools-logo-Ajustes-img' src={icoAjustesBlanco} alt="" />
                </button>
                <button className='tools-logo tools-logo-Logout'
                    onClick={handleLogout}
                >
                    <img className='tools-logo-img tools-logo-Logout-img' src={icoLogoutBlanco} alt="" />
                </button>
            </div>

            {
                openMessagesGeneral === true && openMessagesGeneralType == 'CHAT' &&
                <ChatComponent />

            }


            {
                openNotificationGeneral === true && openNotificationGeneralType === 'NOTIFICATION' &&
                <NotificationComponent />
            }

            <SideBarComponent />

        </nav>
    )
}
