import shortid from "shortid";
import imgEncuestas from '../../../assets/img/126PX/IconoEncuesta.png';
import imgDisponibilidadAtq from '../../../assets/img/126PX/Icono_disponibilidadAtq.png';
import imgHistorialMaterial from '../../../assets/img/126PX/Icono_Historial_Material.png';
import imgRolGrupos from '../../../assets/img/126PX/Icono_rol_grupos.png';
import imgAgenda from '../../../assets/img/126PX/Icono_Agenda_512px.png';
import imgAsignarATQ from '../../../assets/img/126PX/Icono_AsignarATQ.png';

export const InfoButtonLeft = [

    { 
        uid: shortid.generate(),
        img: imgHistorialMaterial,
        name: 'Historial De Material',
        path: '/Inicio',
    },
    {
        uid: shortid.generate(),
        img: imgAsignarATQ,
        name: 'Asignar ATQ',
        path: '/Inicio',
    },
    {
        uid: shortid.generate(),
        img: imgDisponibilidadAtq,
        name: 'Disponibilidad de ATQ’S',
        path: '/Inicio',
        
    },
    {
        uid: shortid.generate(),
        img: imgRolGrupos,
        name: 'Rol de Guardias',
    },
    {
        uid: shortid.generate(),
        img: imgEncuestas,
        name: 'Transferir CX',
    },
    {
        uid: shortid.generate(),
        img: imgAgenda,
        name: 'Agenda',
    },
    
]
    

