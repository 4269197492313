import CryptoJS from 'crypto-js';
const secretKey = 'G2kMN2zmsNbtAcI3';

export function encryptData(data) {
    console.log(data)
    try {
        console.log(1)
        let cypher
        console.log(2)
        let k = CryptoJS.enc.Utf8.parse(secretKey)
        console.log(3)
        cypher = CryptoJS.AES.encrypt(data, k, { mode: CryptoJS.mode.ECB })
        console.log(4)
        cypher = CryptoJS.enc.Base64.stringify(cypher.ciphertext)
        console.log(5)
        cypher = cypher.replace(/\//g, "code");
        console.log(16)
        return cypher
    } catch (e) {
        console.log(e);
    }
}

export function dencryptData(data) {
    try {
        let decrypt = data.replace(/code/g, "/");
        let cypher
        let k = CryptoJS.enc.Utf8.parse(secretKey)
        cypher = CryptoJS.AES.decrypt(decrypt, k, { mode: CryptoJS.mode.ECB })
        cypher = CryptoJS.enc.Base64.stringify(cypher);
        var decodignPlainText = atob(cypher);
        var utf8 = decodeURIComponent(escape(decodignPlainText));
        return JSON.parse(utf8);
    } catch (e) {
        console.log("Error", e);
    }
}

