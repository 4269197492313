

export const LocationStatus = {
    1: {status: 'DISPONIBLE',   color: 'green'    },
    2: {status: 'INCOMPLETO',   color: 'grey'     },
    3: {status: 'RESERVADO',    color: 'yellow'   },
    4: {status: 'CIRUGÍA',      color: 'red'      },
    5: {status: 'INGRESO_ALM',  color: 'grey'     },
    6: {status: 'REVISION_ALM', color: 'black'    },
    7: {status: 'CUARENTENA',   color: 'grey'     },
}