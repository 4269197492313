import React, { useEffect, useState } from 'react';
import { StoreHouseListComponent } from '../StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';

import imgArrow from '../../assets/img/126PX/white_arrow_down.png';
import ico_con_Comentarios from '../../assets/img/126PX/icon_quality_conmentarios.png';
import './styleInfo.scss';
import shortid from 'shortid';
import { UbicacionComponent } from '../EncuestaComponent/UbicacionComponent/UbicacionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getQuotationSeller } from '../../actions/atqAdministradorAction';


export const QualityInfoEncuestasHeaderComponent = ({ type }) => {

    const response = useSelector(state => state.survey.setSurveyAppoimentSelected);
    const distpatch = useDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (response && response.surgeryAppointment.quotationId) {
            distpatch(getQuotationSeller(response.surgeryAppointment.quotationId))
        }
    }, [])



    const ListFields = [
        {
            uid: shortid.generate(),
            title: 'Fecha y Hora',
            value: response && response.surgeryAppointment.surgeryDate ? response.surgeryAppointment.surgeryDate : '',
            type: 'DATE'
        },
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: response && response.surgeryAppointment.medic &&
                ((response.surgeryAppointment.medic.name && response.surgeryAppointment.medic.name) + ' ' +
                    (response.surgeryAppointment.medic.secondName && response.surgeryAppointment.medic.secondName) + ' ' +
                    (response.surgeryAppointment.medic.firstLastname && response.surgeryAppointment.medic.firstLastname) + ' ' +
                    (response.surgeryAppointment.medic.secondLastname && response.surgeryAppointment.medic.secondLastname))
        },
        {
            uid: shortid.generate(),
            title: 'Hospital',
            value: response && response.surgeryAppointment.hospital && response.surgeryAppointment.hospital.name
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: response && response.surgeryAppointment.atq &&
                ((response.surgeryAppointment.atq.firstName && response.surgeryAppointment.atq.firstName) + ' ' +
                    (response.surgeryAppointment.atq.secondName && response.surgeryAppointment.atq.secondName) + ' ' +
                    (response.surgeryAppointment.atq.firstLastName && response.surgeryAppointment.atq.firstLastName) + ' ' +
                    (response.surgeryAppointment.atq.secondLastName && response.surgeryAppointment.atq.secondLastName))
        },
    ];

    const ListFields1 = [
        {
            uid: shortid.generate(),
            title: 'Sistema(s)',
            value: response && response.surgeryAppointment.productRequirements ? response.surgeryAppointment.productRequirements : [],
            type: 'DESCRIPTION'
        },
        {
            uid:shortid.generate(),
            title: "Tornillos/Niveles",
            value: response && response.surgeryAppointment.productRequirements ? response.surgeryAppointment.productRequirements : [],
            type:"Niveles"

        },
        {
            uid: shortid.generate(),
            title: 'Vendedor',
            value: 'NA sin bindeo',
        },
    ];

    return (
        <div className='precontainer_encue-info'>
            <div className='container-sencuestas-header'>
                <div className='header-container'>
                    <div>
                        <div>
                            <span className='title-header' onClick={() => setOpen(!open)}>{`CX #${response && response.surgeryAppointment.id}`}
                                <img src={imgArrow} style={open ? { transform: 'rotate(180deg)', marginLeft: '10px' } : { marginLeft: '10px' }} />
                            </span>

                            <img src={ico_con_Comentarios} className='img-header' />

                        </div>
                    </div>
                </div>
                {
                    open &&
                    <div className='body-container'>
                        <div className='container_encue-info'>
                            <div className='container-left-info'>

                                <StoreHouseListComponent
                                    typeView={{ title: `CX #15`, type: 'NONE', }}
                                    listFields={ListFields}
                                />

                                {/* {type === 'analisis' &&
                                    <div className='section-analisis-header-button'>
                                        <button className='type-analisis-button'>REPORTE DE CONSUMO</button>
                                    </div>
                                } */}

                            </div>


                        </div>
                        <div className={type == 'analisis' ? 'container-encue-info-analisis' : 'container_encue-info'}>
                            <div className='container-left-info'>

                                {
                                    type === 'analisis'
                                        ? <UbicacionComponent
                                            listUbicacion={ListFields1}
                                        />
                                        :
                                        <StoreHouseListComponent
                                            typeView={{ title: `CX #15`, type: 'NONE', }}
                                            listFields={ListFields1}
                                        />
                                }
                            </div>
                        </div>

                    </div>
                }


            </div>


        </div>
    );
};
