import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getLocationByLocationCodeStoreHose, getLocationByLocationCodeStoreHoseSuccess, searchProductsStoreHose, setAppointmentsStoreHouse } from '../../../../actions/storeHouseAction';
import { ObjectConverter, StringConverter } from '../../../shared/ObjectConverter';

export const InputComponent = ({value,productItemSelected,list,setSurtido,setOpenModal,type,principal}) => {
    const [inputValue, setInputValue] = useState(value ? value :'');
    const [itemSelected, setItemSelected] = useState('');
    const [locationObjectConverted, setLocationObjectConverted] = useState('');
    const locationItemToAdd = useSelector(state => state.storeHouse.locationItemToAdd);
    const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);
    const dispatch = useDispatch(); 

    useEffect(() => {
        return () => {
          dispatch(getLocationByLocationCodeStoreHoseSuccess(null))
        }
      }, [])


    /* useEffect(() => {
     
      console.warn('se ha modificacdo: ',list)
    }, [list])
     */
      
      useEffect(() => {
          if(locationItemToAdd && Object.keys(locationItemToAdd).length > 0 && locationItemToAdd !== null && itemSelected && Object.keys(itemSelected).length > 0){
              let prueba = [...list]
              let cambiado=null
              let ban =false;
              let ind = null;
        
        
            if(type === 'normal'){
                prueba.map((item,i) => {
                  if(item.id === itemSelected.id){
                    if(item.locastionInfo.id === locationItemToAdd.id){
                        ind = i;
                        ban = true;
                        cambiado = {
                            ...item,
                            isReturned:true,
                        }
                    }
                    if(ban === true){
                      list[ind] = cambiado         
                      prueba=[...list]
                      setSurtido(prueba)}else{
                        setOpenModal(true);
                      }
                      setLocationObjectConverted(null); 
                      setItemSelected(null)
                      dispatch(getLocationByLocationCodeStoreHoseSuccess(null)); 
                    }
                })    
            }if(type === 'secondary'){
                        prueba.map((item,i) => {
                          if(item.id === principal.id){
                             Object.keys(item.locationBoxes).map((box) => {
                              if(item.locationBoxes[box].id === itemSelected.id){
                                if(item.locationBoxes[box].description.ubicacion === locationItemToAdd.ubicacion){
                                    ind = i
                                    ban = true
                                      cambiado={
                                          ...principal,
                                          locationBoxes:{
                                            ...item.locationBoxes,
                                            [box]:{...itemSelected,
                                            isReturned:true
                                            }
                                          }
                                      }
                                  } 
                              }
                            })
                        }
                        })
                        if(ban === true){  
                          list[ind] = cambiado         
                           prueba=[...list]     
                          /* console.warn('cambiar prueba:',prueba) */
                          setSurtido(prueba)}else{
                            setOpenModal(true);
                          } 
                          setLocationObjectConverted(null); 
                          setItemSelected(null)
                          dispatch(getLocationByLocationCodeStoreHoseSuccess(null));
                }
              }
      }, [locationItemToAdd])
      

    const handleSubmitLocation = (e) => {
        e.preventDefault();
        // const exampleObject = '{"Warehouse”:”Cucharillas 2","NombeUbicacion":"Cucharillas 2","LinkSource":"http://linkparageneralelpdf.tsi.mx","NumberOfBox”:2,"TotalOfBoxes":5}';
        const locationObjectLocation = ObjectConverter(inputValue);
        const locationObjectProduct = StringConverter(inputValue);
        // const locationObject = ObjectConverter(exampleObject);
    
        if(locationObjectLocation.codigoUbicacion){
            let reg = locationObjectLocation.codigoUbicacion.replace(/['']+/g, '')
            setLocationObjectConverted(locationObjectLocation);
            dispatch(getLocationByLocationCodeStoreHose(reg));
        }
        if(locationObjectLocation.NombreUbicacion){
            setLocationObjectConverted(locationObjectLocation);
            dispatch(getLocationByLocationCodeStoreHose(locationObjectLocation.NombreUbicacion));
        }
        if(locationObjectProduct){
            dispatch( searchProductsStoreHose(locationObjectProduct, "productCode", true) );
        }
        setInputValue('');
    }

  return (
    <form action="" onSubmit={handleSubmitLocation} >
            <input type='text' className='StoreHouseInputComponent-body-data'
                onClick={()=> setItemSelected(productItemSelected)}
                value={ inputValue ?inputValue :''}
                onChange={({target}) => setInputValue(target.value)}
            />
        </form>
  )
}
