import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import CustomModal from '../../../components/shared/Modal';
import './Atq_Availability_Modal_asign_styles.scss';
import { getAppointmentsStoreHouseAtq, getAppointmentsStoreHouseSuccessAtq } from '../../../actions/storeHouseAction';
import {formatDate, formatOnlyFinalDate } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { reasigATQ, setATQChoosed, setSurgeryChoosed } from '../../../actions/atqAdministradorAction';
import { useHistory } from 'react-router-dom';
import { Error_Modal } from '../ATQAdministradorModals/Error_Modal';

export const Atq_Availability_Modal_asign = ({isOpen, toggle}) => {

    
    const modalBody = () => {
        const dispatch = useDispatch();
        const history = useHistory();
        const startDate = useSelector( (state) => state.atqAdministrador.DateChoseed);
        const cxList = useSelector( (state) => state.storeHouse.appointmentsListATQ);
        const searchBy = 'ATQ_REQUIRED_NOT_ASSIGNED'
        const [openModalError, setOpenModalError] = useState('');
        const [cxSelectedState,setCxSelectedState] = useState(null);
        const atqChooseed = useSelector(state => state.atqAdministrador.ATQChosed);
        const toggleModal = () => {
            setOpenModalSchedule(null);
        };

        useEffect(() => {
             dispatch(getAppointmentsStoreHouseAtq(startDate,formatOnlyFinalDate(startDate),searchBy)) 
             return () => {
                dispatch(getAppointmentsStoreHouseSuccessAtq(null)) 
              };
        }, []);


        

        const [ formValuesInput, handleInputChangeInputs] = useForm({
            CXSelected: ''
        });

        const { CXSelected } = formValuesInput;


        useEffect(() => {
            CXSelected &&
            setCxSelectedState(JSON.parse(CXSelected))
        }, [CXSelected]);
        

        const handleAtn = (e) => {
            e.preventDefault();
        }
        const handleNivagate = () => {

            if(CXSelected){
                dispatch(setSurgeryChoosed(JSON.parse(CXSelected)));
                history.replace(`/ATQAdministrator_Material_History/transfer_CX/asignarDispobilidad`);
            }
            else{
                setOpenModalError("errorModal" );
            }
        }
        const handleNivagateAdd = () => {

            if(CXSelected){
                dispatch (reasigATQ(cxSelectedState.id,atqChooseed.userID))
                dispatch(setSurgeryChoosed(JSON.parse(CXSelected)));
                history.replace(`/ATQ_Asignado_Message`);
            }
            else{
                setOpenModalError("errorModal" );
            }
        }

      

        return (
            <div className="container-Atq_Availability_Modal_asign-all">
                <h3 className="Atq_Availability_Modal_asign-title">Elige CX:</h3>
                <form className="container-Atq_Availability_Modal_asign-list-items" onSubmit={handleAtn}>
                    <div className="container-Atq_Availability_Modal_asign-items-scroll" >

                        <div className='container-Atq_Availability_Modal_asign'>
                            {
                                cxList &&
                                cxList.map((item) => (
                                    <div key={item.id} className="Atq_Availability_Modal_asign-c-item">
                                    <span className="Atq_Availability_Modal_asign-item-name">CX# {item.id}  Fecha: {formatDate(item.surgeryDate)}</span>
                                    <input className="Atq_Availability_Modal_asign-item-check" 
                                            type="radio"
                                            checked={ CXSelected && JSON.parse(CXSelected).id === item.id}
                                            value={JSON.stringify(item)}
                                            name='CXSelected'
                                            onChange={handleInputChangeInputs}
                                    />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </form>
                    <button className="Atq_Availability_Modal_asign-button" onClick={()=> handleNivagate()}>
                        VER CX SELECCIONADA
                    </button>
                    <button className="Atq_Availability_Modal_asign-button" onClick={()=> handleNivagateAdd()}>
                        ASIGNAR
                    </button>
                   {/*  <button className="Atq_Availability_Modal_asign-button" onClick={()=> toggle()}>
                        VER MAS CX
                    </button> */}
                {
                openModalError === "errorModal" &&
                <Error_Modal
                    isOpen
                    toggle={toggleModal}
                    setOpenModalError={setOpenModalError}
                    messageModal={'NO HAS SELECCIONADO CX'}
                    buttonName={'ACEPTAR'}
                />
                }
            </div>
            
        );
    };

    return (
        <CustomModal
                isOpen={isOpen}
                toggle={toggle}
                title="SUGERENCIA DE CX PARA ATQ"
                body={modalBody()}
        />
    );
}

Atq_Availability_Modal_asign.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    messageModal: PropTypes.any,
    lastOpenModal: PropTypes.any,
    setOpenModal: PropTypes.any,
    setOpenModalError: PropTypes.any,
};