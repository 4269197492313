import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from '../../../hooks/useForm';
import { addSurgeryAppointmentLogisticsNoteStoreHouse, addSurgeryAppointmentLogisticsNoteStoreHouseStore } from '../../../actions/storeHouseAction';
import shortid from 'shortid';



export const StoreHouseAssignSystemAddNoteFormModal = ({toggle,setDescription}) => {
    
    const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);
    const listNotesLogistics = useSelector(state => state.storeHouse.listNotesLogistics);
    const dispatch = useDispatch();

    const [formValues, handleInputChange] = useForm({
        newNoteStoreHouse: '',
    });
    
    const { newNoteStoreHouse } = formValues;


    const handleNewNoteStoreHouse = (e) => {
      e.preventDefault();
      if(listNotesLogistics && listNotesLogistics.length > 0){
        const newListSellerNotes = listNotesLogistics.slice();
        newListSellerNotes.push({ id: shortid.generate(), description: newNoteStoreHouse });
        dispatch(addSurgeryAppointmentLogisticsNoteStoreHouseStore( newListSellerNotes));
        appointmentItem&&dispatch(addSurgeryAppointmentLogisticsNoteStoreHouse({description: newNoteStoreHouse, SurgeryAppointmentId:appointmentItem.id}));
    } else if(!listNotesLogistics || listNotesLogistics.length === 0){
        dispatch(addSurgeryAppointmentLogisticsNoteStoreHouseStore([{ id: shortid.generate(), description: newNoteStoreHouse }]));
        appointmentItem&&dispatch(addSurgeryAppointmentLogisticsNoteStoreHouse({description: newNoteStoreHouse, SurgeryAppointmentId:appointmentItem.id}));
    }
      setDescription(newNoteStoreHouse);
      toggle();
    }

    return (
      
        <form className="c-MedicHospitalModalNewRequeriment" onSubmit={ handleNewNoteStoreHouse }>
          <div className="c-MedicHospitalModalNewRequeriment-c-item">
            <textarea
              className="c-MedicHospitalModalNewRequeriment-item-name"
              type="text"
              placeholder="Descripción de Observación"
              name="newNoteStoreHouse"
              value={newNoteStoreHouse}
              onChange={handleInputChange}
              autoComplete="off"
              required
              cols="40" rows="5">
              </textarea>
          </div>
          <button type="submit" className="c-MedicHospitalModalNewRequeriment-button" >
            CONTINUAR
          </button>
        </form>
    )
}

StoreHouseAssignSystemAddNoteFormModal.propTypes = {
  toggle: PropTypes.any,
}
