import React, { useEffect, useState } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { TableUbication_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Survey/TableUbication_Component';
import shortid from 'shortid';
import './styleDetalles.scss';
import icoAdd from '../../../assets/img/126PX/IcoAgregarCi.png';
import iconDel from '../../../assets/img/126PX/iconDeleteComp.png';
import { VendedorEncuestaComponent } from '../../../components/VendedorEncuesta/VendedorEncuestaComponent';
import { Survey_Medico_Component } from '../../../components/StoreHouseComponents/StoreHouseReiceivingEncuestasComponents/Survey_Medico_Component';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';
import { surveySales, surveySetCommetSelller } from '../../../actions/surveyAction';
import { ATQ_Individual_Compo } from '../../../components/ATQ/ATQ_ENCUESTA/ATQ_Individual_Compo';
import { NotificacionTableComponent } from '../../../components/NotificationComponent/NotificacionTableComponent';

export const DetalleEncuestasPage = () => {

    const surveyID = useSelector(state => state.survey.setSurveyAppoimentSelected.surgeryAppointment)
    const dispatch = useDispatch();
    const { Switch, id } = useParams();
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const [newProductsReq, setNewProductsReq] = useState(null);
    const history = useHistory();
    const [componentText, setComponentText] = useState(false);
    const surveySelected = useSelector(state => state.survey.setSurveyAppoimentSelected)
    const [newCommentSurvey, setNewCommentSurvey] = useState({
        Commentary: "",
    })

    const [valueSales, setValueSales] = useState({
        AssortmentSupplyCommentary: "",
        AssortmentSupplyRate: 0,
        DeliveryMaterialCommentary: "",
        DeliveryMaterialRate: 0,
    })

    //modal
    const [surtido, setSurtido] = useState(null);
    const [noSurtido, setNoSurtido] = useState(null);
    const [listado, setListado] = useState(null)



    //codigo perteneciente al modal(ubicacion y cantidad)
    useEffect(() => {

        if (surveyID && surveyID.id) {

            dispatch(getProductsToAppointmentStoreHose(surveyID.id))
            dispatch(getAppointmentLocationsStoreHose(surveyID.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [surveyID])


    useEffect(() => {

        if (AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
            AppointmentWithLocations.productRequirements.map(item => {
                if ((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)) {
                    arraySurtidos.push(item);
                } else {
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
            setListado([...arraySurtidos, ...arrayNoSurtidos])
        }
    }, [AppointmentWithLocations])




    /*    useEffect(() => {
           console.warn('surtido',surtido)
           console.warn('noSurtido',noSurtido)
       }, [surtido,noSurtido]) */


    useEffect(() => {
        if (AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0) {
            if (surveyID.productRequirements && surveyID.productRequirements.length > 0) {
                setNoSurtido([surveyID.productRequirements && surveyID.productRequirements]);
            }
        }

    }, [AppointmentLocations, AppointmentProducts])

    useEffect(() => {
        if (AppointmentLocations && surveyID && surveyID) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, surveyID && surveyID)
        }
    }, [AppointmentLocations])


    useEffect(() => {
        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
            {
                if (!AppointmentWithLocations || AppointmentWithLocations === null) {
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, surveyID && surveyID)));
                }
            }
        }

    }, [newProductsReq])

    //-------------------------------------------------------------------------------------------------------------------------------------------------------------------


    useEffect(() => {
        if (surveyID && surveyID.id) {
            dispatch(getProductsToAppointmentStoreHose(surveyID.id))
            dispatch(getAppointmentLocationsStoreHose(surveyID.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])

    useEffect(() => {
        if (AppointmentLocations) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, surveyID)
        }
    }, [, AppointmentLocations])

    useEffect(() => {

        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
            {
                if (!AppointmentWithLocations || AppointmentWithLocations === null) {
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, surveyID)));
                }
            }
        }

    }, [newProductsReq])

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: (surveyID && surveyID.medic) &&
                (surveyID.medic && surveyID.medic.name && surveyID.medic.name) + ' ' +
                (surveyID.medic && surveyID.medic.secondName && surveyID.medic.secondName) + ' ' +
                (surveyID.medic && surveyID.medic.firstLastname && surveyID.medic.firstLastname) + ' ' +
                (surveyID.medic && surveyID.medic.secondLastname && surveyID.medic.secondLastname),
        },
        {
            uid: shortid.generate(),
            title: 'Institucion',
            value: (surveyID && surveyID.hospital && surveyID.hospital.name),
        },
        {
            uid: shortid.generate(),
            title: 'Fecha y Hora',
            type: 'DATE-COMPLETE',
            value: (surveyID && surveyID.surgeryDate),
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: (surveyID && surveyID.atq ?
                `${surveyID.atq.firstName && surveyID.atq.firstName} ${surveyID.atq.secondName && surveyID.atq.secondName}
                 ${surveyID.atq.firstLastName && surveyID.atq.firstLastName} `
                : 'ATQ NO ASIGNADO'),
        },
    ];


    const handleSendData = async () => {
        const resultado = await dispatch(surveySales(valueSales, parseInt(id)))
        if (resultado === true) {
            history.push(`/Quality_Messages_Page/${id}`);
        }
    }

    const handleAceptar = async () => {
        if (newCommentSurvey.Commentary.length > 0) {
            const resultado = await dispatch(surveySetCommetSelller(newCommentSurvey, parseInt(surveySelected.salesSurvey.id)))
            if (resultado === true) {
                history.push('/Encuestas_Page_Ventas');

            }
        }
    }

    // console.log('AppointmentWithLocations', AppointmentWithLocations)
    // console.log('surtido', surtido)
    // console.log('noSurtido', noSurtido)
    // console.log('listado', listado)
    return (
        <div className='container-encuestas-detalles'>
            <SubNavBarComponent title={'ENCUESTA'} historyPage={'/Encuestas_Page_Ventas'} />
            <div className="container-encuestas-colums-detalles">
                <section className='container-sec-left' >
                    <StoreHouseListComponent
                        typeView={{ title: `CX #${id}`, type: 'STORE', }}
                        listFields={arrayListFields}
                    />

                    {surtido && surtido.length > 0 &&

                        <NotificacionTableComponent type={'surtido'} 
                              typeListTable ={surtido}
                        />
                    }


                    {noSurtido && noSurtido.length > 0 &&
                        <NotificacionTableComponent type={'no-surtido'} 
                            typeListTable ={noSurtido}
                        />

                    }


                    {/* <TableUbication_Component
                        listItemsBody={AppointmentWithLocations && AppointmentWithLocations.productRequirements && AppointmentWithLocations.productRequirements.length > 0 ? AppointmentWithLocations.productRequirements : null}
                    />  */}


                </section>
                <section className='container-sec-rigth'>
                    <div className='container-medico'>
                        <p className='text-medico-header' >Seguimiento para uso interno de TSI</p>

                        <Survey_Medico_Component
                            titulo={'Médico'}
                            tipo='medico-almacen'
                        />
                        <VendedorEncuestaComponent
                            title={'Vendedor'}
                            valueSales={valueSales}
                            setValueSales={setValueSales}
                            Switch={Switch}
                            pointsSur={surveySelected.salesSurvey && surveySelected.salesSurvey.assortmentSupplyRate ? parseInt(surveySelected.salesSurvey.assortmentSupplyRate) : ''}
                            pointsEnt={surveySelected.salesSurvey && surveySelected.salesSurvey.deliveryMaterialRate ? parseInt(surveySelected.salesSurvey.deliveryMaterialRate) : ''}
                            commentarySur={surveySelected.salesSurvey && surveySelected.salesSurvey.assortmentSupplyCommentary ? surveySelected.salesSurvey.assortmentSupplyCommentary : ''}
                            commentaryEnt={surveySelected.salesSurvey && surveySelected.salesSurvey.deliveryMaterialCommentary ? surveySelected.salesSurvey.deliveryMaterialCommentary : ''}
                        />
                        {
                            surveySelected.salesSurvey && surveySelected.salesSurvey.commentaries.length > 0 &&
                            surveySelected.salesSurvey.commentaries.map(item => (
                                <div key={shortid.generate} className='individual-container_New'>
                                    <ATQ_Individual_Compo
                                        titulo={'Vendedor - Comentario Agregado'}
                                        tipo={'Agregado'}
                                        comentario={item.description && item.description}

                                    />
                                </div>
                            ))
                        }
                        {
                            componentText === true &&
                            <div className='individual-container_New'>
                                <ATQ_Individual_Compo
                                    titulo={'Vendedor - Nuevos Comentarios'}
                                    tipo={'Nuevo'}
                                    setNewCommentSurvey={setNewCommentSurvey}
                                    newCommentSurvey={newCommentSurvey}
                                />
                            </div>
                        }

                    </div>
                    {
                        Switch === 'INACTIVE'
                            ? (<div className='container-buttons-sales'>
                                {
                                    componentText === true
                                        ? <img className='icoAdd-sales' src={iconDel} onClick={() => setComponentText(!componentText)} />
                                        : <img className='icoAdd-sales' src={icoAdd} onClick={() => setComponentText(!componentText)} />
                                }
                                <button className='btn-Aceptar2-sales' onClick={() => handleAceptar()}>
                                    <p className='btn-title'>ACEPTAR</p>
                                </button>
                            </div>)
                            : (<button className='btn-Aceptar21-sales' onClick={handleSendData}>
                                <p className='btn-title-sales'>GUARDAR</p>
                            </button>)
                    }
                </section>
            </div>
        </div>
    )
}
