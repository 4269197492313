import React from 'react';
import icoImg from '../../../../assets/img/256PX/Icon-check-circle.png';
import './style.scss';
import PropTypes from 'prop-types';

export const ATQ_Body_Modal_Img = ({toggle=null,text1=null,text2=null,type=null}) => {
   
    
      const handleClosed = () => {
          toggle();
      };


    return (
        <div className="c-container-modal">
        {
            type === 'normal' &&
            <div className="c-container-img">
                    <img className='imgIcon' src={icoImg}/>
            </div>
        }
            <span className='txt1'>
                    {text1}
            </span>
            { text2 &&
                <span className='txt2'>
                        {text2}
                </span>
            }
          <button className="c-button" onClick={handleClosed}>
            CONTINUAR
          </button>
       
    </div>
  );
    
};
ATQ_Body_Modal_Img.propTypes = {
    toggle: PropTypes.any,
  };
