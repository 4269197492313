import React from 'react';
import './ListItem1.scss';
import PropTypes from 'prop-types';

// ICONS

import blueArrow from '../../../assets/img/126PX/blue-arrow.png'
import whiteArrow from '../../../assets/img/126PX/white-arrow.png'
import { useSelector } from 'react-redux';


const ListItem1 = ({ item,id, selectedTabId, img = null, title = '', subtitle = '', subtitle2 = '', page = null, firstLastname = '', name = '',
    secondLastName = '', secondName = '', phone = '' }) => {
    const idSelect = useSelector(state => state.allUsers.selectedUserId);
    const rol = useSelector(state => state.allUsers.userRolSelected);
    const phoneUser = ['atq','adminAtq','storer']

    // const handleClick= () => {
    //   };

    // console.error('desdelist', selectedTabId)
    // console.error('id', id)

    /* console.warn("item: ",item); */
    /* console.warn("firstLastname: ",firstLastname);  */

    return (

        <div className={['profile-card-container', (id == idSelect) ? 'profile-blue-card-container' : ''].join(' ')}>
            <div className={"blue-line-content"}></div>
            {
                img &&
                <div className={['profile-card-left-bar', !img ? 'noImage' : ''].join(' ')}>
                    <div className={"image-circle"}>
                        <img src={img} alt={title} />
                    </div>
                </div>
            }
            <div className={['profile-card-right-bar', !img ? 'allwidthpart' : ''].join(' ')}>
                {
                    page &&
                    <span className={'title-left'}>{title}</span>

                }

                {
                    subtitle && <span className={'subtitle-leftlist'}>{subtitle + ' ' + firstLastname}</span>
                }
                {
                    name &&
                    <span className={'subtitle-leftlist'}>{(rol === 'atq' ? 'ATQ' : '') + ' ' + name + ' ' + secondName + ' ' + firstLastname + ' ' + secondLastName}</span>
                }

                {phone && phoneUser.includes(rol) &&
                    <span className={'subtitle2-leftlist'}>{phone}</span>
                }

                {

                    subtitle2 && <span className={'subtitle2-leftlist'}>{subtitle2}</span>
                }





            </div>

            <div className='container-img'>

                {
                    id != idSelect &&
                    <img className={"arrowIcon"} src={blueArrow} alt="" />
                }

                {
                    id == idSelect &&
                    <img className={"arrowIcon"} src={whiteArrow} alt="" />
                }
            </div>



        </div>

    )
};

export default ListItem1;


ListItem1.propTypes = {
    id: PropTypes.any,
    selectedTabId: PropTypes.any,
    img: PropTypes.any,
    title: PropTypes.any,
    subtitle: PropTypes.any,
    subtitle2: PropTypes.any,
}
