import React, { useEffect, useState } from 'react'
import { MessagesChatData } from './MessagesChatData';

import { GroupChat } from './GroupChat';

export const MessagesChatComponent = ({surgerieslist,setSurgeriesList,title,setTitle}) => {
    
    return (
        <>
            {
                surgerieslist  ?
                    <MessagesChatData
                    setSurgeriesList={setSurgeriesList}
                    surgerieslist={surgerieslist}
                    setTitle={setTitle} 
                    title={title}
                    />
                    :
                    <GroupChat
                    setSurgeriesList={setSurgeriesList}
                    surgerieslist={surgerieslist}
          
                    />
            }

        </>
    )
}
