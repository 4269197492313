import { SettingsInputHdmi } from '@material-ui/icons';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import shortid from 'shortid';
import { getUserById } from '../../../actions/allUsersAction';
import { getQuotationSeller, reasigATQ } from '../../../actions/atqAdministradorAction';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';
import { SubNavBarComponent } from '../../layouts/layout1/subnavbar/SubNavBarComponent';
import { PreRequerimentsComponent } from '../../PreRequerimentsComponent/PreRequerimentsComponent';
import { StoreHouseListComponent } from '../../StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { ATQAdministrator_Assign_ATQ_TableUbication_Component } from './ATQAdministrator_Assign_ATQ_TableUbication_Component';
import { ModalSelectionATQ } from './ModalSelectionATQ';
import './sass/styles.scss';

export const ATQAdministrator_Assign_ATQ = () => {


    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(null);
    const [infoSeller, setInfoSeller] = useState({});
    const surgerySelected = useSelector((state) => state.atqAdministrador.surgeryChosed);
    const sellerCreator = useSelector(state => state.allUsers.getUserById);
    const seller = useSelector(state => state.atqAdministrador)
    const atqChooseed = useSelector(state => state.atqAdministrador.ATQChosed);
    //--------------------------------------------------------------
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const [newProductsReq, setNewProductsReq] = useState(null);
    const [surtido, setSurtido] = useState(null);
    const [noSurtido, setNoSurtido] = useState(null);
    //-------------------------------------------------------------
    const history = useHistory();

    const { tipo } = useParams();

    //---------------------------------------------------------------------

    useEffect(() => {
        if (surgerySelected && surgerySelected.id) {
            dispatch(getProductsToAppointmentStoreHose(surgerySelected.id))
            dispatch(getAppointmentLocationsStoreHose(surgerySelected.id))
            dispatch(getQuotationSeller(surgerySelected.quotationId));
            dispatch(getUserById(surgerySelected.creatorUserId));
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])

    useEffect(() => {
        if (AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
            AppointmentWithLocations.productRequirements.map(item => {
                if ((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)) {
                    arraySurtidos.push(item);
                } else {
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
        }
    }, [AppointmentWithLocations])

    useEffect(() => {
        if (AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0) {
            if (surgerySelected.productRequirements && surgerySelected.productRequirements.length > 0) {
                setNoSurtido([...surgerySelected.productRequirements]);
            }
        }

    }, [AppointmentLocations, AppointmentProducts])

    useEffect(() => {
        if (AppointmentLocations) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, surgerySelected)
        }
    }, [, AppointmentLocations])


    useEffect(() => {

        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
            {
                if (!AppointmentWithLocations || AppointmentWithLocations === null) {
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, surgerySelected)));
                }
            }
        }

    }, [newProductsReq])

    //--------------------------------------------------------------------

    useEffect(() => {
        if (seller && seller.Seller) {
            setInfoSeller(seller.Seller);
            /* console.log(seller.Seller) */
        }
    }, [seller])



    const handleOpenModal = () => {
        setOpenModal('ModalSelectATQ')
    }
    const handleAsignar = (ubicacion) => {
        ubicacion === 'asignar' &&
            history.replace(`/ATQAdmin_Disponibilidad/asignar`);
        ubicacion === 'apoyo' &&
            history.replace(`/ATQAdmin_Disponibilidad/apoyo`);
        ubicacion === 'cambiar' &&
            history.replace(`/ATQAdmin_Disponibilidad/cambiar`);
    }



    const handleAceptar = (ubicacion) => {

        history.replace(`/ATQAdmin_Asignar_CX`);
    }


    const toggleModal = () => {
        setOpenModal(null);
    };

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Tipo de CX',
            value: 'Cervical',
        },
        {
            uid: shortid.generate(),
            title: 'Médico',
            value: surgerySelected.medic && (`${surgerySelected.medic.name && surgerySelected.medic.name} 
            ${surgerySelected.medic.secondName && surgerySelected.medic.secondName}
            ${surgerySelected.medic.firstLastname && surgerySelected.medic.firstLastname}
            ${surgerySelected.medic.secondLastname && surgerySelected.medic.secondLastname}`),
        },
        {
            uid: shortid.generate(),
            title: 'Institución',
            value: surgerySelected.hospital.name && surgerySelected.hospital.name,
        },
        {
            uid: shortid.generate(),
            title: 'Paciente',
            value: surgerySelected.patient && (`${surgerySelected.patient.firstName && surgerySelected.patient.firstName} 
            ${(surgerySelected.patient.secondName && surgerySelected.patient.secondName !== null) && surgerySelected.patient.secondName}
            ${surgerySelected.patient.firstLastName && surgerySelected.patient.firstLastName}
            ${surgerySelected.patient.secondLastName && surgerySelected.patient.secondLastName}`),
        },
        {
            uid: shortid.generate(),
            title: 'Fecha y hora de CX',
            type: 'DATE-COMPLETE',
            value: surgerySelected.surgeryDate && surgerySelected.surgeryDate,
        },
        {
            uid: shortid.generate(),
            title: 'Cliente',
            value: surgerySelected.client && (`${surgerySelected.client.clientName && surgerySelected.client.clientName}`),
        },
        {
            uid: shortid.generate(),
            title: 'Lista de Precios',
            value: surgerySelected.priceList && surgerySelected.priceList.description && surgerySelected.priceList.description,
        },
        {
            uid: shortid.generate(),
            title: 'Vendedor',
            value: sellerCreator
                ? (`${sellerCreator.firstName && sellerCreator.firstName}
                 ${sellerCreator.firstLastName && sellerCreator.firstLastName} 
                 ${sellerCreator.secondName && sellerCreator.secondName}
                 ${sellerCreator.secondLastName && sellerCreator.secondLastName}
                 `)
                :
                " - - -"

            // seller.Seller &&
            //  seller.Seller.creatorIdentity &&
            //   seller.Seller.creatorIdentity && 
            //   eller.Seller.creatorIdentity && 
            //   seller.Seller.creatorIdentity.firstName &&
            //    (`${seller.Seller.creatorIdentity.firstName} ${seller.Seller.creatorIdentity.firstLastName}`)  ,
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: surgerySelected.atq
                ? (`${surgerySelected.atq.firstName && surgerySelected.atq.firstName} ${surgerySelected.atq.secondName && surgerySelected.atq.secondName} ${surgerySelected.atq.firstLastName && surgerySelected.atq.firstLastName} ${surgerySelected.atq.secondLastName && surgerySelected.atq.secondLastName}
                ${'/'}
                ${surgerySelected.secondaryATQ ? (`${surgerySelected.secondaryATQ.firstName && surgerySelected.secondaryATQ.firstName} ${surgerySelected.secondaryATQ.secondName && surgerySelected.secondaryATQ.secondName}  ${surgerySelected.secondaryATQ.firstLastName && surgerySelected.secondaryATQ.firstLastName} ${surgerySelected.secondaryATQ.secondLastName && surgerySelected.secondaryATQ.secondLastName}`)
                        : ''}`
                )
                : surgerySelected.atqNeeded === false ? 'No requiere ATQ' : 'Sin Asignar ATQ',
        },


    ];

    const handleNivagateAdd = () => {
        dispatch(reasigATQ(surgerySelected.id, atqChooseed.userID))
        history.replace(`/ATQ_Asignado_Message`);
    }


    return (
        <div className={'atqAdministrator_Assign_ATQ_container'}>
            {
                (tipo && tipo === 'asignar' || tipo === 'apoyo') &&
                <SubNavBarComponent title={'ASIGNAR ATQ'} historyPage={'/ATQAdmin_Asignar_CX'} />
            }
            {
                tipo && tipo === 'transfer' &&
                <SubNavBarComponent title={'ASIGNAR ATQ'} historyPage={'/ATQAdministrator_Transferir_CX'} />
            }
            {
                tipo && tipo === 'asignarDispobilidad' &&
                <SubNavBarComponent title={'ASIGNAR ATQ'} historyPage={'/ATQAdministrator_atq_availability'} />
            }



            <div className='atqAdministrator_Assign_ATQ-section-container'>

                <section className='atqAdministrator_Assign_ATQ-section-left'>
                    <StoreHouseListComponent
                        typeView={{ type: 'STORE', title: `PQX #${surgerySelected.id}` }}
                        listFields={arrayListFields}
                    />
                    <PreRequerimentsComponent
                        requirementsInstitution={surgerySelected.hospital}
                        requirementsSales={surgerySelected.appointmentNotes}
                        requirementsMedic={surgerySelected.medic}
                        requirementsStore={[]}
                        listElements={[]}
                        type={'ATQ'}
                    />
                </section>

                <section className='atqAdministrator_Assign_ATQ-section-rigth'>
                    <ATQAdministrator_Assign_ATQ_TableUbication_Component
                        typeListTable={"surtido"}
                        info={surtido}
                    />
                    <ATQAdministrator_Assign_ATQ_TableUbication_Component
                        typeListTable={"noSurtido"}
                        info={noSurtido} />
                    {
                        (tipo && tipo === 'apoyo') &&
                        <>
                            <div className="container_buttons">
                                <button className="btn_apoyo" onClick={() => handleAsignar('apoyo')}>AGREGAR ATQ DE APOYO</button>
                                <button className="btn_cambiar" onClick={() => handleAsignar('cambiar')}>CAMBIAR ATQ</button>
                            </div>
                            <button className='atqAdministrator_Assign_ATQ-subTitle-container btn-Aceptar' onClick={handleAceptar}>
                                <p className='atqAdministrator_Assign_ATQ-subTitem-title'>ACEPTAR</p>
                            </button>
                        </>
                    }
                    {
                        (tipo && tipo === 'cambiar') &&
                        <div className="">
                            <button className="">CAMBIAR ATQ</button>
                        </div>
                    }


                    {
                        (tipo && tipo === 'transfer') &&
                        <button className='atqAdministrator_Assign_ATQ-subTitle-container-transfer' onClick={handleOpenModal}>
                            <p className='atqAdministrator_Assign_ATQ-subTitem-title-transfer'>TRANSFERIR</p>
                        </button>
                    }
                    {
                        (tipo && tipo === 'aceptar') &&
                        <button className='atqAdministrator_Assign_ATQ-subTitle-container' onClick={handleOpenModal}>
                            <p className='atqAdministrator_Assign_ATQ-subTitem-title'>ACEPTAR</p>
                        </button>
                    }
                    {
                        (tipo && tipo === 'asignar') &&
                        <button className='atqAdministrator_Assign_ATQ-subTitle-container-asignar' onClick={() => handleAsignar('asignar')}>
                            <p className='atqAdministrator_Assign_ATQ-subTitem-title-asignar'>ASIGNAR ATQ</p>
                        </button>
                    }
                    {
                        (tipo && tipo === 'asignarDispobilidad') &&
                        <button className='atqAdministrator_Assign_ATQ-subTitle-container-asignar' onClick={() => handleNivagateAdd()}>
                            <p className='atqAdministrator_Assign_ATQ-subTitem-title-asignar'>ASIGNAR ATQ</p>
                        </button>
                    }
                </section>
            </div>
            {
                openModal && openModal === "ModalSelectATQ" &&
                <ModalSelectionATQ
                    isOpen
                    toggle={toggleModal}
                    setOpenModalError={setOpenModal}
                />
            }
        </div>

    )
}
