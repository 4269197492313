import React from 'react';
import { useHistory } from 'react-router';
import iconOrderSuccess from '../../../assets/img/512PX/Icono_CXSurtida_512px.png';


export const ATQAdminViewMessageAsignar = () => {

    const history = useHistory();
    const handleFirtsPage = () => {
        history.replace(`/Inicio`);
      }

    return (
        <div className={"StoreHouseFinishedView_finish"}>
      <div className={"StoreHouseFinishedView_finish_general_c"}>
        <div className={"StoreHouseFinishedView-images"}>
          <img className="StoreHouseFinishedView-success-img" src={iconOrderSuccess} alt="" />
        </div>
    
              <p className={"StoreHouseFinishedView_finish-subtitle"}>{'Se ha asignado ha '} </p>
              <p className={"StoreHouseFinishedViewSucess-number"}>{ `CX #15`}</p>


        <div className={"order_buttons"}>
          <button
            className="StoreHouseFinishedView-white-button"
            onClick={ handleFirtsPage }
          >
            <span> ACEPTAR</span>
          </button>
        </div>
      </div>
    </div>
    )
}
