import React from 'react'
import CustomModal from '../../shared/Modal';
import { AddMembersComponent } from '../AddMembersModal/AddMembersComponent';
import { MembersComponent } from './MembersComponent';

export const Members_Group_Modal_Chat = (props) => {

    const modalBody = () => {
        return (

            <>
                {props.openModalAdd === 'openMembers' &&
                    <MembersComponent
                        toggle={props.toggle}
                        setOpenModal={props.setOpenModal}
                        setCxSelected={props.setCxSelected}
                        setOpenModalAdd={props.setOpenModalAdd}
                        openModalAdd={props.openModalAdd}

                    />

                }

            </>


        );
    };


    return (
        <CustomModal
            isOpen={props.isOpen}
            toggle={props.handleClose ? props.handleClose : props.toggle}
            body={modalBody()}
            title={"Miembros"}
        />
    );
}
