import React from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import 'rc-tooltip/assets/bootstrap.css';
import { useSelector } from "react-redux";
import SearchBar from "material-ui-search-bar";
import { RemoveRedEye } from "@material-ui/icons";
import { getRemissionsByClientAsync, getRemissionsByOrderAsync, getRemissionsByProductAsync } from "../../actions/userActions";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const Remission = () => {
  const { searchQuoteFinished } = useParams();
  const requestH = useSelector((state) => state.user.request);
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState('cliente');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [searchedValuesGeneral, setSearchedValuesGeneral] = useState("");

  useEffect(() => {
    getRemissionByOrderFunction()
    getRemissionsByClientFunction()
    getRemissionsByProductFunction()
  }, [])

  function filtrarRemisiones(jsonData) {
    return jsonData.filter(item => item.remissions !== "Sin remisiones");
  }

  function filtrarRemisionesByProduct(jsonData) {
    return jsonData.filter(item => item.remissions !== "Sin remisiones");
  }

  const getRemissionByOrderFunction = async () => {
    await getRemissionsByOrderAsync(0, requestH).then((res) => {
      const resultado = filtrarRemisiones(res.response);
      setRowsByPedido(resultado)
      setRowsByPedidoOriginal(resultado)
    })
  }

  const getRemissionsByClientFunction = async () => {
    await getRemissionsByClientAsync(0, requestH).then((res) => {
      const resultado = filtrarRemisiones(res.response);
      setRowsSolicitud(resultado)
      setRowsSolicitudOriginal(resultado)
    })
  }

  const getRemissionsByProductFunction = async () => {
    await getRemissionsByProductAsync(null, requestH).then((res) => {
      const resultado = filtrarRemisionesByProduct(res.response);
      setRowsProductos(resultado)
      setRowsProductosOriginal(resultado)
    })
  }

  /* Tabla By Pedido */
  const columnsByPedido = [
    { id: 'folio', label: 'Folio', minWidth: '10%', align: 'left' },
    { id: 'fecha', label: 'Fecha', minWidth: '5%', align: 'left' },
    { id: 'cliente', label: 'Cliente', minWidth: '20%', align: 'left' },
    { id: 'envios', label: 'Envios', minWidth: '55%', align: 'left' },
    { id: 'remision', label: 'Remision', minWidth: '5%', align: 'center' },
    { id: 'icon', label: '', minWidth: '5%', align: 'center' },
  ];

  const [rowsByPedido, setRowsByPedido] = useState([]);
  const [rowsByPedidoOriginal, setRowsByPedidoOriginal] = useState([]);
  const [rowsPerPageByPedido, setRowsPerPageByPedido] = useState(7);
  const [pageByPedido, setPageByPedido] = useState(0);

  const handleChangePageByPedido = (event, newPage) => {
    setPageByPedido(newPage);
  };

  const handleChangeRowsPerPageByPedido = (event) => {
    setRowsPerPageByPedido(+event.target.value);
    setPageByPedido(0);
  };

  /* Tabla solicitud */
  const columnSolicitud = [
    { id: 'folio', label: 'Folio', minWidth: '10%', align: 'left' },
    { id: 'fecha', label: 'Fecha', minWidth: '5%', align: 'left' },
    { id: 'cliente', label: 'Cliente', minWidth: '20%', align: 'left' },
    { id: 'envios', label: 'Envios', minWidth: '60%', align: 'left' },
    { id: 'remision', label: 'Remision', minWidth: '5%', align: 'center' },
  ];

  const columnsSolicitud = ['folio', 'fecha', 'cliente', 'envios', 'remision'];

  const [rowsSolicitud, setRowsSolicitud] = useState([]);
  const [rowsSolicitudOriginal, setRowsSolicitudOriginal] = useState([]);
  const [rowsPerPageSolicitud, setRowsPerPageSolicitud] = useState(7);
  const [pageSolicitud, setPageSolicitud] = useState(0);

  const handleChangePageSolicitud = (event, newPage) => {
    setPageSolicitud(newPage);
  };

  const handleChangeRowsPerPageSolicitud = (event) => {
    setRowsPerPageSolicitud(+event.target.value);
    setPageSolicitud(0);
  };

  /* Tabla Productos */
  const columnProductos = [
    { id: 'folio', label: 'Folio', minWidth: '10%', align: 'left' },
    { id: 'fecha', label: 'Fecha', minWidth: '5%', align: 'left' },
    { id: 'productos', label: 'Productos', minWidth: '40%', align: 'left' },
    { id: 'requerida', label: 'Requerida', minWidth: '5%', align: 'center' },
    { id: 'surtida', label: 'Surtida', minWidth: '5%', align: 'center' },
    { id: 'remision', label: 'Remision', minWidth: '5%', align: 'center' },
    { id: 'cliente', label: 'Cliente', minWidth: '25%', align: 'left' },
    { id: 'icon', label: '', minWidth: '5%', align: 'left' },
  ];

  const columnsProductos = ['folio', 'fecha', 'productos', 'requerida', 'surtida', 'remision', 'cliente', 'icon'];


  function createDataProductos(folio, fecha, productos, requerida, surtida, remision, cliente, icon) {
    return { folio, fecha, productos, requerida, surtida, remision, cliente, icon };
  }

  const rowsProductosData = [
    createDataProductos('202229560', '12/ene/2022', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '10', '10', '9 Remisiones', 'Aguas calientes rep. de Urugay 912...', 'icon'),
    createDataProductos('212229560', '12/ene/2022', 'Chalecos con mangas color azul ', '50', '50', '2 Remisiones', 'Aguas calientes rep. de Urugay 912...', 'icon'),
    createDataProductos('202229560', '12/ene/2022', 'Tornillos para rodilla', '1', '1', '1 Remision', 'Aguas calientes rep. de Urugay 912...', 'icon'),
  ];

  const [rowsProductos, setRowsProductos] = useState(rowsProductosData);
  const [rowsProductosOriginal, setRowsProductosOriginal] = useState(rowsProductosData);
  const [rowsPerPageProductos, setRowsPerPageProductos] = useState(7);
  const [pageProductos, setPageProductos] = useState(0);

  const handleChangePageProductos = (event, newPage) => {
    setPageProductos(newPage);
  };

  const handleChangeRowsPerPageProductos = (event) => {
    setRowsPerPageProductos(+event.target.value);
    setPageProductos(0);
  };


  function formatearFecha(fecha) {
    const fechaObjeto = new Date(fecha);
    const dia = fechaObjeto.getDate().toString().padStart(2, '0');
    const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan desde 0, por eso se suma 1
    const año = fechaObjeto.getFullYear().toString();

    return `${dia}/${mes}/${año}`;
  }

  function returnNumberOfShipment(cadena) {
    const numeros = cadena.split(',').map(item => parseInt(item.trim()));
    return numeros.length;
  }

  const requestSearch = (searchedVal) => {

    if (inputValue === 'solicitud') {
      const filteredArray = rowsByPedidoOriginal.filter((item) => {
        return item.billingName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          item.orderId.toString().includes(searchedVal.toLowerCase()) ||
          item.clientId.toString().toLowerCase().includes(searchedVal.toLowerCase())
      });
      setRowsByPedido(filteredArray)
    } else if (inputValue === 'cliente') {
      const filteredArray = rowsSolicitudOriginal.filter((item) => {
        return item.billingName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          item.orderId.toString().includes(searchedVal.toLowerCase()) ||
          item.clientId.toString().toLowerCase().includes(searchedVal.toLowerCase())
      });
      setRowsSolicitud(filteredArray)
    } else if (inputValue === 'productos') {
      const filteredArray = rowsProductosOriginal.filter((item) => {
        return item.productDescription.toLowerCase().includes(searchedVal.toLowerCase()) ||
          item.orderId.toString().includes(searchedVal.toLowerCase()) ||
          item.clientId.toString().toLowerCase().includes(searchedVal.toLowerCase()) ||
          item.productCode.toLowerCase().includes(searchedVal.toLowerCase()) ||
          item.billingName.toLowerCase().includes(searchedVal.toLowerCase())
      });
      setRowsProductos(filteredArray)
    }

  };

  const cancelSearch = () => {
    setSearchedValuesGeneral("")
    setRowsByPedido(rowsByPedidoOriginal)
    setRowsSolicitud(rowsSolicitudOriginal)
    setRowsProductos(rowsProductosOriginal)
  };


  return (
    <div style={{
      width: '100%',
      paddingTop: '16vh',
      height: '100%'
    }}>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={searchQuoteFinished && `/cotizaciones_finalizadas`} title={`Remision`} />
      <div >

        {/* TopBar */}
        <div style={{
          background: '#F0F0F0',
          display: 'flex'
        }}>
          <div style={{ width: '70%' }}>
            <div style={{ marginTop: '20px', marginLeft: '50px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Filtro</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '35%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                  <FormControl variant="outlined" className={classes.formControl} style={{
                    width: '100%',
                    height: '46px',
                    boxSizing: 'border-box',
                    background: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.5)',
                    borderRadius: '4px'
                  }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Mostrar por
                    </InputLabel>
                    <Select
                      style={{
                        height: '46px',
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      labelWidth={'Tipo de Generador'}
                      value={inputValue}
                      onChange={handleInputChange}
                    >
                      {/* <MenuItem value={'productos'}>Productos</MenuItem> */}
                      <MenuItem value={'cliente'}>Cliente</MenuItem>
                      <MenuItem value={'solicitud'}>Por Solicitud</MenuItem>
                      <MenuItem value={'productos'}>Producto</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '90%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px', width: '90%' }}>
                  <SearchBar
                    value={searchedValuesGeneral}
                    onChange={(searchVal) => { requestSearch(searchVal) }}
                    onCancelSearch={() => cancelSearch()}
                    placeholder={'Buscar'}
                    style={{
                      width: '100%',
                      height: '46px',
                      boxSizing: 'border-box',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px',
                    }}
                  />
                  {/*                   <TextField id="outlined-basic" label="Buscador General" variant="outlined" style={{ width: '100%' }} />
 */}                </div>
              </div>
            </div>
          </div>

        </div>

        {/* Tabla Solicitud */}
        {inputValue == 'solicitud' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Solicitud</p>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columnsByPedido.map((column) => {
                      const id = column.id
                      return (
                        <TableCell
                          key={column.id}
                          align={'left'}
                          width={column.minWidth}
                        >
                          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {/* {columnsByPedido.includes(id) && id != 'folio' && id != 'icon' && < ImportExportIcon style={{ color: '#484f59' }} />} */}
                            {column.label}
                            {/* {id == 'folio' && <ImportExportIcon style={{ color: '#484f59' }} />} */}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsByPedido && rowsByPedido.slice(pageByPedido * rowsPerPageByPedido, pageByPedido * rowsPerPageByPedido + rowsPerPageByPedido).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnsByPedido.map((column) => (
                          <TableCell key={column.id} align={column.align} width={column.minWidth}>
                            {column.id == 'tipo' && < ShoppingBasketIcon style={{ color: '#484f59' }} />}
                            {column.id == 'folio' &&
                              <NavLink to={{ pathname: `/remisionDetails`, state: { remission: row } }} >
                                <p style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>{row.orderId}</p>
                              </NavLink>
                            }
                            {column.id == 'fecha' && formatearFecha(row.insertDate)}
                            {column.id == 'cliente' && row.billingName}
                            {column.id == 'envios' && row.deliveryGuides}
                            {column.id == 'remision' && returnNumberOfShipment(row.remissions)}
                            {column.id == 'icon' && <GetAppIcon />}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 10, 25, 100]}
              component="div"
              count={rowsByPedido ? rowsByPedido.length : 0}
              rowsPerPage={rowsPerPageByPedido}
              page={pageByPedido}
              onChangePage={handleChangePageByPedido}
              onChangeRowsPerPage={handleChangeRowsPerPageByPedido}
            />
          </div>
        }

        {/* Tabla Cliente */}
        {inputValue == 'cliente' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Cliente</p>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columnSolicitud.map((column) => {
                      const id = column.id
                      return (
                        <TableCell
                          key={column.id}
                          align={'left'}
                          width={column.minWidth}
                        >
                          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {/* {columnsSolicitud.includes(id) && id != 'folio' && id != 'icon' && < ImportExportIcon style={{ color: '#484f59' }} />} */}
                            {column.label}
                            {/* {id == 'folio' && <ImportExportIcon style={{ color: '#484f59' }} />} */}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsSolicitud && rowsSolicitud.slice(pageSolicitud * rowsPerPageSolicitud, pageSolicitud * rowsPerPageSolicitud + rowsPerPageSolicitud).reverse().map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnSolicitud.map((column) => {
                          return (
                            <TableCell key={column.id} align={column.align} width={column.minWidth}>
                              {column.id == 'tipo' && < ShoppingBasketIcon style={{ color: '#484f59' }} />}
                              {column.id == 'folio' &&
                                <NavLink to={{ pathname: `/remisionDetails`, state: { remission: row } }} >
                                  <p style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>{row.orderId}</p>
                                </NavLink>
                              }
                              {column.id == 'fecha' && formatearFecha(row.insertDate)}
                              {column.id == 'cliente' && row.billingName}
                              {column.id == 'envios' && row.deliveryGuides}
                              {column.id == 'remision' && returnNumberOfShipment(row.remissions)}
                              {column.id == 'icon' && <GetAppIcon />}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 10, 25, 100]}
              component="div"
              count={rowsSolicitud ? rowsSolicitud.length : 0}
              rowsPerPage={rowsPerPageSolicitud}
              page={pageSolicitud}
              onChangePage={handleChangePageSolicitud}
              onChangeRowsPerPage={handleChangeRowsPerPageSolicitud}
            />
          </div>
        }

        {/* Tabla Productos */}
        {inputValue == 'productos' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Productos</p>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columnProductos.map((column) => {
                      const id = column.id
                      return (
                        <TableCell
                          key={column.id}
                          align={'left'}
                          width={column.minWidth}
                        >
                          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {/* {columnsProductos.includes(id) && id != 'folio' && id != 'icon' && < ImportExportIcon style={{ color: '#484f59' }} />} */}
                            {column.label}
                            {/* {id == 'folio' && <ImportExportIcon style={{ color: '#484f59' }} />} */}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsProductos && rowsProductos.slice(pageProductos * rowsPerPageProductos, pageProductos * rowsPerPageProductos + rowsPerPageProductos).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnProductos.map((column) => {
                          return (
                            <TableCell key={column.id} align={column.align} width={column.minWidth}>
                              {column.id == 'folio' &&
                                <NavLink to={{ pathname: `/remisionDetails`, state: { order: row } }} >
                                  <p style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>{row.orderId}</p>
                                </NavLink>
                              }
                              {column.id == 'fecha' && formatearFecha(row.insertDate)}
                              {column.id == 'productos' && row.productDescription}
                              {column.id == 'requerida' && row.quantity}
                              {column.id == 'surtida' && row.quantitySupplied}
                              {column.id == 'remision' && returnNumberOfShipment(row.remissions)}
                              {column.id == 'cliente' && row.billingName}
                              {column.id == 'icon'}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 10, 25, 100]}
              component="div"
              count={rowsProductos ? rowsProductos.length : 0}
              rowsPerPage={rowsPerPageProductos}
              page={pageProductos}
              onChangePage={handleChangePageProductos}
              onChangeRowsPerPage={handleChangeRowsPerPageProductos}
            />
          </div>
        }

      </div >
    </div >
  );
};
