import React from 'react';
import './styles.scss';
import atqAddMessage from '../../../assets/img/512PX/atqAddMessage.png';
import { useHistory, useParams } from 'react-router-dom';

export const ATQAdminMessageTransfered = () => {

  const {id} = useParams();
  const history = useHistory();

  const nextNavigation= () => {
    history.push('/ATQAdministrator_Transferir_CX');
  }

    return (
        <div className={"MessageTransfered_finish"}>
        <div className={"MessageTransfered_finish_general_c"}>
          <div className={"MessageTransfered-images"}>
            <img className="MessageTransfered-success-img" src={atqAddMessage} alt="" />
          </div>
                 <p className={"MessageTransfered-number"}>{ `CX #${id} `}</p>
                <p className={"MessageTransfered_finish-subtitle"}>{'TRANSFERENCIA REALIZADA'} </p>
          <div className={"MessageTransfered_order_buttons"}>
            <button onClick={() => nextNavigation()} className="MessageTransfered-white-button">
              <span className='txt-notification-tranfer-succes'> CONTINUAR</span>
            </button>
          </div>
        </div>
      </div>
    )
}
