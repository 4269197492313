import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { storeHouseStockModalData } from './storeHouseStockModalData';
import './sass/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AddNoteToAppoiment, getLocationsStoreHouseSuccess, setAppointmentsStoreHouse, setNewProductsAndSystems } from '../../../actions/storeHouseAction';
import { LocationStatus } from '../../shared/LocationStatus';
import shortid from 'shortid';

export const StoreHouseStockModalForm = ({ toggle, typeModal, itemToAddUbication, type = 'normal',addDeleteLocations}) => {

  const listLocationsState = useSelector(state => state.storeHouse.searchLocation);
  const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);

  //ESTE USE SELECTOR ES DONDE SE ENCUENTRA LOS NUEVOS PRODUCTOS QUE SE DEBEN AGREGAR
  const newProducts = useSelector (state => state.storeHouse.newProducts);

  const dispatch = useDispatch();
  const [nameRequeriment , setNameRequeriment] = useState();
  const [listItemsToLocationAvailable, setListItemsToLocationAvailable] = useState([]);


  const handleAddItem = (item) => {
      let newArray = [];
      if(listItemsToLocationAvailable.some( el => el.id === item.id )){
        newArray = listItemsToLocationAvailable.filter(el => el.id !== item.id);
      }else{
        newArray = listItemsToLocationAvailable.map(el => el);
        newArray.push(item);
      }
      setListItemsToLocationAvailable(newArray);
    }



    //ESTA FUNCION ES PARA REMPLAZAR EL VALOR QUE SE VA A RECUPERAR DE LA NOTA
    const handleInputChange = (e) => {
        /* console.warn(e.target.value); */
        setNameRequeriment(e.target.value)
    }

  useEffect(() => {

    return () => {
      dispatch(getLocationsStoreHouseSuccess(null));
    }
  }, [])


  useEffect(() => {
      if(itemToAddUbication && itemToAddUbication.notStockedNote && itemToAddUbication.notStockedNote.length > 0){
        handleAddItem({id: 'NO_DISPONIBLE', description:'NO_DISPONIBLE'})
        setNameRequeriment(itemToAddUbication.notStockedNote)
      }
    
  }, [])
  

  const handleSubmitForm = (e) => {
    e.preventDefault();

    if(type === 'down'){
        // debugger
        //listItemsToLocationAvailable ESTE ES EL QUE SE AGREGARA
        if(listItemsToLocationAvailable[0].id !== 'NO_DISPONIBLE'){
          //listItemsNew ESTE CONTIENE TODOS LOS PRODUCTOS RERQUERIDOS DESDE EL PRINCIPIO
                let listItemsNew = [...appointmentItem.productRequirements];
                if(listItemsToLocationAvailable && listItemsToLocationAvailable.length > 0){
                for(let i = 1 ; i < listItemsToLocationAvailable.length; i++ ){
                  listItemsNew.splice(
                        listItemsNew.findIndex(item=> item.id === itemToAddUbication.id) +1 , 0, 
                        {
                            id: shortid.generate(),
                            Ubicacion:listItemsToLocationAvailable[i].ubicacion,
                            product: {
                                productDescription: '-',
                                availableLocations: 'NOT'
                            },
                            locastionItem: listItemsToLocationAvailable[i].ubicacion,
                            locastionInfo: listItemsToLocationAvailable[i],
                            systemLevels: '-',
                            owner: {...itemToAddUbication}
                        });
                }
                //itemToAddUbication ESTE CONTIENE EL VALOR QUE TIENE LA CAJA ACTUALMENTE
                if( itemToAddUbication && listItemsToLocationAvailable && listItemsToLocationAvailable.length > 0 ){
                    listItemsNew.splice(
                    listItemsNew.findIndex(item=> item.id === itemToAddUbication.id), 1, 
                    {
                        ...itemToAddUbication,
                        locastionItem: listItemsToLocationAvailable[0].ubicacion,
                        locastionInfo: listItemsToLocationAvailable[0],
                    });
                }
              }
              let newAppointmentItem = {
                ...appointmentItem,
                productRequirements: listItemsNew,
              }
              //ESTE DISPATCH MODIFICA EL REDUX CON EL NUEVO PRODUCTO 
              //OJO EL OTRO SIGUE EXISTIENDO SOLO SE AGREGA EL NUEVO A ESTOS DOS VALORES
              //locastionItem: listItemsToLocationAvailable[0].ubicacion,
              //locastionInfo: listItemsToLocationAvailable[0],
              dispatch(setAppointmentsStoreHouse(newAppointmentItem))
            }
            else if (listItemsToLocationAvailable[0].id === 'NO_DISPONIBLE'){
              let listItemsNew = [...appointmentItem.productRequirements];
              listItemsNew.splice(
                listItemsNew.findIndex(item=> item.id === itemToAddUbication.id), 1, 
                {
                    ...itemToAddUbication,
                    notStockedNote: nameRequeriment,
                    
                });
                let newAppointmentItem = {
                  ...appointmentItem,
                  productRequirements: listItemsNew,
                }
                dispatch(setAppointmentsStoreHouse(newAppointmentItem))

                  dispatch(AddNoteToAppoiment({
                        Id : itemToAddUbication.id,
                        NotStockedNote : nameRequeriment
              }))

            }
            // debugger
            //AQUI EN ESTA FUNCION SE ENVIA EL PRODUCTO A ELIMINAR A SER EVALUADO.
            addDeleteLocations(itemToAddUbication)
            toggle();
}
//ESTA DECISION ES PARA SEPARAR QUE SE HAGAN MODIFICACIONES EN LA TABLA DE ARRIBA
    if(type === 'top'){

  
        let listNewTop = [...newProducts]; 
        if(listItemsToLocationAvailable && listItemsToLocationAvailable.length > 0){
          for(let i = 1 ; i < listItemsToLocationAvailable.length; i++ ){
            listNewTop.splice(
              listNewTop.findIndex(item=> item.id === itemToAddUbication.id) +1 , 0, 
                  {
                      id: shortid.generate(),
                      Ubicacion:listItemsToLocationAvailable[i].ubicacion,
                      product: {
                          productDescription: '-',
                          availableLocations: 'NOT'
                      },
                      locastionItem: listItemsToLocationAvailable[i].ubicacion,
                      locastionInfo: listItemsToLocationAvailable[i],
                      systemLevels: '-',
                      owner: {...itemToAddUbication}
                  });
          }
        }

        if( itemToAddUbication && listItemsToLocationAvailable && listItemsToLocationAvailable.length > 0 ){
          listNewTop.splice(
            listNewTop.findIndex(item=> item.id === itemToAddUbication.id), 1, 
          {
              ...itemToAddUbication,
              Ubicacion:listItemsToLocationAvailable[0].ubicacion,
              locastionItem: listItemsToLocationAvailable[0].ubicacion,
              locastionInfo: listItemsToLocationAvailable[0],
          });
      }
      dispatch(setNewProductsAndSystems(listNewTop))
    }

    //----------------------------------------------------------------------------
    toggle();
  }



  return (
    <div className="c-StoreHouseStockModalForm">
      <form className="c-StoreHouseStockModalForm-c-list-items" onSubmit={handleSubmitForm} >
        <div className={'c-StoreHouseStockModalForm-c-list-items-table-container' }>
            <div className="c-StoreHouseStockModalForm-c-list-items-table-precontainer">
            <table className="c-StoreHouseStockModalForm-c-list-items-table">
                <thead className="c-StoreHouseStockModalForm-item-container-titles">
                    <tr className="c-StoreHouseStockModalForm-item-container-titles-table-head-row">
                        <th className="c-StoreHouseStockModalForm-item-title">Deposito</th>
                        <th className="c-StoreHouseStockModalForm-item-title">Ubicación</th>
                        <th className="c-StoreHouseStockModalForm-item-title">Estatus</th>
                        <th className="c-StoreHouseStockModalForm-item-title">Nombre resp.</th>
                        {
                          typeModal !== 'STORE_SYSTEMS'&&
                          <th className="c-StoreHouseStockModalForm-item-title"></th>
                        }
                    </tr>
                </thead>
                <tbody className="c-StoreHouseStockModalForm-item-value-container">
                {
                  listLocationsState&&
                  listLocationsState.map(el => (
                    <tr className="c-StoreHouseStockModalForm-item-container-titles-table-body-row" key={el.id}>
                        <td className="c-StoreHouseStockModalForm-item-value"><p >{el.local}</p></td>
                        <td className="c-StoreHouseStockModalForm-item-value"><p >{el.ubicacion}</p></td>
                        <td className={`c-StoreHouseStockModalForm-item-value c-StoreHouseStockModalForm-item-value-${LocationStatus[el.status].color}`} ><p >{LocationStatus[el.status].status}</p></td>
                        <td className="c-StoreHouseStockModalForm-item-value"><p >{el.owner || '-'}</p></td>
                        {
                          typeModal !== 'STORE_SYSTEMS' &&
                          <td className="c-StoreHouseStockModalForm-item-value">
                            {
                              Number(el.status) === 1 &&
                              <input
                                  className="c-StoreHouseStockModalForm-item-check"
                                  type="checkbox"
                                  checked={listItemsToLocationAvailable.some(item=> item.id === el.id) }
                                  value={el.id}
                                  name={el.id}
                                  onChange={() => handleAddItem(el)}
                              />
                            }
                          </td>
                        }
                    </tr>

                  ))
                }
                {
                typeModal !== 'STORE_SYSTEMS' &&
                  <tr className="c-StoreHouseStockModalForm-item-container-titles-table-body-row">
                    {/* <td className="c-StoreHouseStockModalForm-item-value"><p >{el.deposito}</p></td> */}
                    <td className="c-StoreHouseStockModalForm-item-value "><p ></p></td>
                    <td className="c-StoreHouseStockModalForm-item-value"><p >NO DISPONIBLE</p></td>
                    <td className="c-StoreHouseStockModalForm-item-value"><p ></p></td>
                    <td className="c-StoreHouseStockModalForm-item-value"><p ></p></td>
                    {
                      typeModal !== 'STORE_SYSTEMS' &&
                      <td className="c-StoreHouseStockModalForm-item-value">
                          <input
                              className="c-StoreHouseStockModalForm-item-check"
                              type="checkbox"
                              checked={ listItemsToLocationAvailable.some(el=> el.id === 'NO_DISPONIBLE') }
                              value={{id: 'NO_DISPONIBLE', description: 'NO DISPONIBLE'}}
                              name={'NO_DISPONIBLE'}
                              onChange={() => handleAddItem({id: 'NO_DISPONIBLE', description:'NO_DISPONIBLE'})}
                          />
                      </td>
                    }
                  </tr>
                }
                </tbody>
            </table>
            </div>
            </div>
          {
            listItemsToLocationAvailable.some(el=> el.id === 'NO_DISPONIBLE') && typeModal !== 'STORE_SYSTEMS' &&
            <textarea
              className="c-StoreHouseStockModalForm-item-note"
              type="text"
              placeholder="Nota porque no se surtió..."
              name="nameRequeriment"
              value={nameRequeriment}
              onChange={(e) => handleInputChange(e)}
              autoComplete="off"
              cols="40" rows="5">
              </textarea>
          }
          {
            (!listLocationsState || listLocationsState && listLocationsState.length === 0 )&&
              <h3 className="c-StoreHouseStockModalForm-title-notItem">No se encontraron Ubicaciones disponibles</h3>
          }
        <button className="c-StoreHouseStockModalForm-button" type='submit'>
          {typeModal !== 'STORE_SYSTEMS' ? `Guardar` : `ACEPTAR`}
        </button>
      </form>
    </div>
  );
};

StoreHouseStockModalForm.propTypes = {
  toggle: PropTypes.any,
  typeModal: PropTypes.any,
  modalOrSystem: PropTypes.any,
  itemProduct: PropTypes.any,
  itemProductBundle: PropTypes.any,
  itemToAddUbication: PropTypes.any,
};
