import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';
import { setSurgeryChoosed } from '../../../actions/atqAdministradorAction';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getAppointmentsStoreHouse, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse, setNewProductsAndSystems, UpdateWarehouseAppointmentStatusStoreHose, UpdateWarehouseAppointmentStatusStoreHoseSuccess } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';
import './sass/styles.scss';


export const StoreHouseApproveOrderPreViewComponent = ({ status }) => {

    const [checkboxValue, setCheckboxValue] = useState(null);
    const [listApproveOrderPreView, setListApproveOrderPreView] = useState(false);
    const dispatch = useDispatch();
    const listApproveOrderPreViewStoreHose = useSelector(state => state.storeHouse.listApproveOrderPreViewStoreHose);
    const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);
    const history = useHistory();
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const [newProductsReq, setNewProductsReq] = useState(null);
    const producRequeriment = useSelector(state => state.storeHouse.appointmentItem)
    const productRequerimentMod = useSelector(state => state.atqAdministrador.surgeryChosed)
    const newProducts = useSelector(state => state.storeHouse.newProducts)
    const startDate = '1900-01-01T01:01:57'
    const endDate = '2200-05-06T01:01:33'

    const [arrayOriginal, setArrayOriginal] = useState(producRequeriment.productRequirements)

    useEffect(() => {
        if (newProducts && newProducts.length > 0) {
            setArrayOriginal([...arrayOriginal, ...newProducts])
        }

        if (status === 'complete') {

            dispatch(getProductsToAppointmentStoreHose(appointmentItem.id));
            dispatch(getAppointmentLocationsStoreHose(appointmentItem.id));
        }
        return () => {
            dispatch(getProductsToAppointmentStoreHoseSuccess(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null));
            dispatch(setNewProductsAndSystems([]));


        }

    }, [])



    useEffect(() => {
        if ((status === 'complete') && AppointmentLocations) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, appointmentItem)
        }
    }, [AppointmentLocations])


    useEffect(() => {

        if (status === 'complete') {
            if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
                dispatch(setSurgeryChoosed(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, appointmentItem)));
            }
        }
    }, [newProductsReq])


    useEffect(() => {
        if (appointmentItem && appointmentItem.warehouseCompleted) {
            setCheckboxValue('APROBADO')
        } else if (appointmentItem && !appointmentItem.warehouseCompleted) {
            setCheckboxValue('DESAPROBADO')
        }
    }, [appointmentItem])


    // useEffect(() => {
    //     debugger
    //     if(status !== 'complete'){
    //         if(checkboxValue==='APROBADO' && appointmentItem){
    //             dispatch(UpdateWarehouseAppointmentStatusStoreHose({
    //                 IsCompleted: true,
    //                 AppointmentId: appointmentItem.id
    //             }));
    //             dispatch(UpdateWarehouseAppointmentStatusStoreHoseSuccess(true))
    //         }
    //        else if(checkboxValue==='DESAPROBADO' && appointmentItem){
    //             dispatch(UpdateWarehouseAppointmentStatusStoreHose({
    //                 IsCompleted: false,
    //                 AppointmentId: appointmentItem.id
    //             }));
    //             dispatch(UpdateWarehouseAppointmentStatusStoreHoseSuccess(false))
    //         }
    // }
    // }, [checkboxValue])

    useEffect(() => {
        if (listApproveOrderPreViewStoreHose && !AppointmentProducts && !AppointmentLocations) {
            setListApproveOrderPreView(listApproveOrderPreViewStoreHose.filter(el => !el.owner))
        }
    }, [listApproveOrderPreViewStoreHose])

    const handleNavigateToViewQuote = () => {

        history.replace(`/StoreHouse/StoreHouseApproveOrder/OrderPreView/OrderPreView`);
    }

    const handleNavigateToFinish = () => {
        // if(status === 'complete'){

        if (checkboxValue === 'APROBADO' && appointmentItem) {
            dispatch(UpdateWarehouseAppointmentStatusStoreHose({
                IsCompleted: true,
                AppointmentId: appointmentItem.id
            }));
            dispatch(UpdateWarehouseAppointmentStatusStoreHoseSuccess(true))
        }
        else if (checkboxValue === 'DESAPROBADO' && appointmentItem) {
            dispatch(UpdateWarehouseAppointmentStatusStoreHose({
                IsCompleted: false,
                AppointmentId: appointmentItem.id
            }));
            dispatch(UpdateWarehouseAppointmentStatusStoreHoseSuccess(false))
        }

        history.replace(`/StoreHouseFinishedView`);
    }

    const converted = (objeto) => {
        let array = Object.values(objeto)
        return array
    }


    return (
        <>
            <div className="StoreHouseApproveOrderPreViewScreen-container-elements">
                <div className="StoreHouseApproveOrderPreViewScreen-container-title">
                    <h3 className="StoreHouseApproveOrderPreViewScreen-title">
                        PQX {status === 'complete' ? productRequerimentMod && productRequerimentMod.id : appointmentItem && appointmentItem.id}
                    </h3>
                </div>
                <div className="StoreHouseApproveOrderPreViewScreen-container-table">
                    <div className="StoreHouseApproveOrderPreViewScreen-precontainer-table">
                        <table className="StoreHouseApproveOrderPreViewScreen-table">
                            <thead className="StoreHouseApproveOrderPreViewScreen-table-head">
                                <tr className="StoreHouseApproveOrderPreViewScreen-table-tr">
                                    <th className="StoreHouseApproveOrderPreViewScreen-table-title">Producto/Ubicación</th>
                                    <th className="StoreHouseApproveOrderPreViewScreen-table-title">No. Cajas Registradas</th>
                                    <th className="StoreHouseApproveOrderPreViewScreen-table-title">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody className="StoreHouseApproveOrderPreViewScreen-table-body">
                                {

                                    (status === 'incomplete')

                                        ?
                                        newProducts && newProducts.length > 0 ?


                                            arrayOriginal && arrayOriginal.map(el => (
                                                <tr key={`${el.id}${shortid.generate()}`} className="StoreHouseApproveOrderPreViewScreen-table-tr" >
                                                    <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                        {el.product && el.product.productDescription ? `${el.product.productDescription}`
                                                            : el.product && el.product.description ? `${el.product.description}`
                                                                : el.product && el.product.productCode ? `${el.product.productCode}`
                                                                    : el.location && `${el.location.description}`}
                                                        /
                                                        {el.locastionItem ? (`${el.locastionItem} ${el.locationBoxes ? converted(el.locationBoxes).map(item => `${item.description.ubicacion}, `) : ''}`)
                                                            : el.locationBoxIDs ? el.location.ubicacion : el.notStockedNote && 'NOTA DE ALMACEN'}
                                                    </td>
                                                    <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                        {el.locastionScanned && el.locastionScanned.TotalOfBoxes}
                                                        {el.locastionInfo ? el.locastionInfo.numberOfBoxes : el.boxQuantity ? el.boxQuantity : el.locastionInfo ? el.locastionInfo.numberOfBoxes : el.notStockedNote ? el.notStockedNote : el.product ? el.product.numberOfBoxes : '-'}
                                                    </td>
                                                    <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                        {el.systemLevels ? el.systemLevels
                                                            : el.productQuantity ? el.productQuantity
                                                                : el.ProductQuantity ? el.ProductQuantity
                                                                    : el.locationLevels && el.locationLevels}
                                                    </td>
                                                </tr>
                                            ))

                                            :
                                            producRequeriment && producRequeriment.productRequirements.map(el => (
                                                <tr key={`${el.id}${shortid.generate()}`} className="StoreHouseApproveOrderPreViewScreen-table-tr" >
                                                    <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                        {el.product && el.product.productDescription ? `${el.product.productDescription}`
                                                            : el.product && el.product.description ? `${el.product.description}`
                                                                : el.product && el.product.productCode ? `${el.product.productCode}`
                                                                    : el.location && `${el.location.description}`}
                                                        /
                                                        {el.locastionItem ? (`${el.locastionItem} ${el.locationBoxes ? converted(el.locationBoxes).map(item => `${item.description.ubicacion}, `) : ''}`)
                                                            : el.locationBoxIDs ? el.location.ubicacion : el.notStockedNote && 'NOTA DE ALMACEN'}
                                                    </td>
                                                    <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                        {el.locastionScanned && el.locastionScanned.TotalOfBoxes}
                                                        {el.locastionInfo ? el.locastionInfo.numberOfBoxes : el.boxQuantity ? el.boxQuantity : el.locastionInfo ? el.locastionInfo.numberOfBoxes : el.notStockedNote ? el.notStockedNote : el.product ? el.product.numberOfBoxes : '-'}
                                                    </td>
                                                    <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                        {el.systemLevels ? el.systemLevels
                                                            : el.productQuantity ? el.productQuantity
                                                                : el.ProductQuantity ? el.ProductQuantity
                                                                    : el.locationLevels && el.locationLevels}
                                                    </td>
                                                </tr>
                                            ))




                                        :

                                        productRequerimentMod && productRequerimentMod.productRequirements.map(el => (
                                            <tr key={`${el.id}${shortid.generate()}`} className="StoreHouseApproveOrderPreViewScreen-table-tr" >
                                                <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                    {el.product ? el.product.productDescription : el.location && el.location.description}
                                                    /
                                                    {el.locastionItem ? (`${el.locastionItem} ${el.locationBoxes ? converted(el.locationBoxes).map(item => `${item.description.ubicacion}, `) : ''}`)
                                                        : el.locationBoxIDs ? el.location.ubicacion : el.notStockedNote && 'NOTA DE ALMACEN'}
                                                </td>
                                                <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                    {el.locastionScanned && el.locastionScanned.TotalOfBoxes}
                                                    {el.locastionInfo ? el.locastionInfo.numberOfBoxes : el.boxQuantity ? el.boxQuantity : el.locastionInfo ? el.locastionInfo.numberOfBoxes : el.notStockedNote ? el.notStockedNote : el.product ? el.product.numberOfBoxes : '-'}

                                                </td>
                                                <td className="StoreHouseApproveOrderPreViewScreen-body-data">
                                                    {el.systemLevels ? el.systemLevels
                                                        : el.productQuantity ? el.productQuantity
                                                            : el.ProductQuantity ? el.ProductQuantity
                                                                : el.locationLevels && el.locationLevels}
                                                </td>
                                            </tr>
                                        ))



                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="StoreHouseApproveOrderPreViewScreen-container-middle-buttons">
                    <button className="StoreHouseApproveOrderPreViewScreen-middle-buttons-pxq" onClick={handleNavigateToViewQuote}>
                        VER PQX
                    </button>
                    <label className="StoreHouseApproveOrderPreViewScreen-switch">
                        <input type="checkbox" onChange={() => checkboxValue === 'APROBADO'
                            ? setCheckboxValue('DESAPROBADO')
                            : (checkboxValue === 'DESAPROBADO' || !checkboxValue)
                            && setCheckboxValue('APROBADO')}
                            className={'StoreHouseApproveOrderPreViewScreen-switch-input'}
                        />
                        <span className={checkboxValue === 'APROBADO'
                            ? "StoreHouseApproveOrderPreViewScreen-slider StoreHouseApproveOrderPreViewScreen-slider-color-green StoreHouseApproveOrderPreViewScreen-round"
                            : 'StoreHouseApproveOrderPreViewScreen-slider StoreHouseApproveOrderPreViewScreen-slider-color-red StoreHouseApproveOrderPreViewScreen-round'}>
                            {checkboxValue === 'APROBADO' ? 'SURTIDO' : 'NO SURTIDO'}
                        </span>
                    </label>
                </div>
            </div>
            <div className="StoreHouseApproveOrderPreViewScreen-container-button-continue">
                <button className="StoreHouseApproveOrderPreViewScreen-button-continue" onClick={() => handleNavigateToFinish()} >
                    CONTINUAR
                </button>
            </div>

        </>
    )
}
