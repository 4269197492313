import React from 'react';
import icoSave from '../../../assets/img/512PX/ico_encuestas.png';
import './styleMensagge.scss';

export const ATQ_encuesta_men_save = ({ id = 32, page = 'coordinador', comentario = true }) => {

  //MENSAJE USADO POR COORDINADOR ATQ TAMBIEN

  const handleNavigate = () => {
    page === 'atq'

      ? ''//MNADAR AL INICIO DE ATQ
      : ''//MANDAR AL INICIO DE COORDINADOR ATQ
  }
  return (
    <div className={"Final_encuesta_finish"}>
      <div className={"Final_encuesta_finish_general_c"}>
        <div className={"Final_encuesta-images"}>
          <img className="Final_encuesta-success-img" src={icoSave} alt="" />
        </div>
        {
          page === 'atq'
            ? <p className={"Final_encuesta_finish-subtitle"}>{'se ha GUARDADO encuesta de:'} </p>
            : (comentario === false
              ? <p className={"Final_encuesta_finish-subtitle"}>{'se ha GUARDADO tu encuesta sobre la:'} </p>
              : <p className={"Final_encuesta_finish-subtitle"}>{'se ha GUARDADO comentario agregado en:'} </p>)
        }
        <p className={"Final_encuesta_finish-subtitle"}>{`CX#${id}`} </p>
        <div className={"Final_encuesta_order_buttons"}>
          {
            <button className="Final_encuesta-white-button" onClick={handleNavigate} >
              <span> ACEPTAR</span>
            </button>
          }
        </div>
      </div>
    </div>
  )
}
