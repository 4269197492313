import React, { useEffect, useState } from 'react';
import { TableUbication_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Survey/TableUbication_Component';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import './style_not.scss';
import shortid from 'shortid';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { answerAppoimentChangeAtq } from '../../../actions/atqAdministradorAction';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';

export const ATQ_Not_Transf_CX = ({type='material'}) => {
    const appoimentSearch = useSelector(state => state.storeHouse.appointmentsListATQ)
    const appimentSelected = useSelector(state => state.atqAdministrador.surgeryChosed)
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const [surtido,setSurtido] = useState(null);
    const [noSurtido, setNoSurtido] = useState(null);
    const [listField, setListField] = useState(null);
    const [newProductsReq, setNewProductsReq] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const letter = `${appimentSelected.requestedByUser.firstName && appimentSelected.requestedByUser.firstName}${appimentSelected.requestedByUser.secondName && appimentSelected.requestedByUser.secondName}${appimentSelected.requestedByUser.firstLastName && appimentSelected.requestedByUser.firstLastName}${appimentSelected.requestedByUser.secondLastName && appimentSelected.requestedByUser.secondLastName}  ${'te ha TRANSFERIDO CX#'} ${appimentSelected.surgeryId}`;
    const {Notification} = useParams();


    useEffect(() => {
        
        if(appimentSelected && appimentSelected.surgeryId) {
           
            dispatch(getProductsToAppointmentStoreHose(appimentSelected.surgeryId))
            dispatch(getAppointmentLocationsStoreHose(appimentSelected.surgeryId))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])


    useEffect(() => {
        if(AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
                AppointmentWithLocations.productRequirements.map(item => {
                if((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)){
                    arraySurtidos.push(item);
                }else{
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
        }
    }, [AppointmentWithLocations])




 /*    useEffect(() => {
        console.warn('surtido',surtido)
        console.warn('noSurtido',noSurtido)
    }, [surtido,noSurtido]) */
    

   useEffect(() => {
       if(AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0){
           if(appoimentSearch && appoimentSearch[0].productRequirements && appoimentSearch && appoimentSearch[0].productRequirements.length > 0){
                setNoSurtido([appoimentSearch[0].productRequirements]);
           }
       }
     
   }, [AppointmentLocations,AppointmentProducts])
   
    useEffect(() => {
        if(AppointmentLocations && appoimentSearch && appoimentSearch[0]){
            handleCompleteBeginLocations(AppointmentLocations,setNewProductsReq,appoimentSearch[0])
        }
    }, [AppointmentLocations])


    useEffect(() => {
        
            if( (!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)){   
                {  
                    if(!AppointmentWithLocations || AppointmentWithLocations === null){
                        dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts,newProductsReq,appoimentSearch[0])));
                    }
                }
            }
    
    }, [newProductsReq])



    const handleRechazar = () => {
        dispatch(answerAppoimentChangeAtq({
            Id : appimentSelected.id,
            SurgeryTransferStatus:3,
        }))
        history.replace("/ATQ_Inicio")
    }

    const handleAceptar = () => {

        if(Notification !== 'material'){
            dispatch(answerAppoimentChangeAtq({
                Id : appimentSelected.id,
                SurgeryTransferStatus: 2,
            }))
        }else{
        
        }
        history.replace("/ATQ_Inicio")
    }


useEffect(() => {
if(appoimentSearch){


    const listFieldsConst = [
         {
            uid: shortid.generate(),
            title: 'Institucion',
            value: appoimentSearch[0].hospital && appoimentSearch[0].hospital.name ,
        },
        {
            uid: shortid.generate(),
            title: 'Fecha de CX',
            type: 'DATE',
            value: appoimentSearch[0].surgeryDate && appoimentSearch[0].surgeryDate,
        },
        {
            uid: shortid.generate(),
            title: 'Hora de CX',
            type: 'HOUR',
            value: appoimentSearch[0].surgeryDate && appoimentSearch[0].surgeryDate,
        },
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: (appoimentSearch && appoimentSearch[0].medic)  ?(`${appoimentSearch[0].medic.name && appoimentSearch[0].medic.name} 
            ${appoimentSearch[0].medic.secondName && appoimentSearch[0].medic.secondName}
            ${appoimentSearch[0].medic.firstLastname && appoimentSearch[0].medic.firstLastname}
            ${appoimentSearch[0].medic.secondLastname && appoimentSearch[0].medic.secondLastname}`) :'--' ,
        } 
    ]; 
     setListField(listFieldsConst); 
}

  
}, [appoimentSearch]);


    const listTitle = [
        {
            id: shortid.generate(),
            title: "Material Surtido",
        },
        {
            id: shortid.generate(),
            title: "Cantidad",
        },
    ];
    const listTitle1 = [
        {
            id: shortid.generate(),
            title: "Material Sin Surtido",
        },
        {
            id: shortid.generate(),
            title: "Cantidad",
        },
    ];

   
    

  return (
  <div className='contaniner_trans_not'>
  {
    Notification !== 'material' 
        ? <SubNavBarComponent title={'TRANSFERENCIA DE CX'} searchActive={false} historyPage={'/ATQ_Inicio'} />
        : <SubNavBarComponent title={'NOTIFICACIONES'} searchActive={false} historyPage={'/ATQ_Inicio'} />
  }
        <div className='precontaniner_trans_not'>
            <div className='fisrt_container_transfer'>
                <section className='section_transf_left'>
                {
                    Notification !== 'material' 

               ? <StoreHouseListComponent
                        typeView={{ title: `CX #${appimentSelected.surgeryId}`, type: 'TRANSFER', }}
                    
                                listFields={ appoimentSearch && listField}
                        
                        letter={letter}
                    />
                :<StoreHouseListComponent
                        typeView={{ title: `CX #15`, type: 'STORE', }}
                        listFields={ListFields}
                    />
                }
                </section>
                <section className='section_transf_rigth'>
                <TableUbication_Component listTitles={listTitle} listItemsBody={surtido} type={'surtido'} />
                <TableUbication_Component listTitles={listTitle1} listItemsBody={noSurtido} type={'nosurtido'} />
                </section>
            </div>    
            <div className='second_container_transfer'>
            {
                Notification !== 'material' &&
                <button className='btn_trans_rechazar' onClick={() => handleRechazar()}>
                        RECHAZAR
                </button>
            }
                <button className='btn_trans_aceptar' onClick={() => handleAceptar()}>
                        ACEPTAR
                </button>
            </div>
        </div>
  </div>
  );
};
