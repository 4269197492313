import React, { useEffect } from 'react'
import './sass/styles.scss';
import share from '../../../assets/img/126PX/Ico_share.png';

import { ATQ_Modal_Share } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_Share';
import { ATQ_Modal_with_Img } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_with_Img';
import { StoreHomeReceivingMaterialReportIncomingRigthTableComponent } from './StoreHomeReceivingMaterialReportIncomingRigthTableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getChatGroup } from '../../../actions/notification-chatAction';

export const StoreHomeReceivingMaterialReportIncomingRigthInfoComponent = ({closeModal,
    updateMainObject,setOpenModal,openModal,setCxSelected,cxSelected}) => {

        const surgerySelected = useSelector(state => state.atqAdministrador.surgeryChosed);
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(getChatGroup(parseInt(surgerySelected.id),0,100,false));
        }, [])
        

    return (
        <div className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-component">
            <StoreHomeReceivingMaterialReportIncomingRigthTableComponent 
                setOpenModal ={setOpenModal}
                openModal = {openModal}
                closeModal = {closeModal}
                updateMainObject = {updateMainObject}
                setCxSelected = {setCxSelected}
                cxSelected = {cxSelected}
            />

            <div className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-buttons-component">
                <button className="StoreHomeReceivingMaterialReportIncomingRigthInfoComponent-button"
                onClick={() => setOpenModal('SHARE')}>
                <img  className='StoreHomeReceivingImgane' src={share} /> 
                </button>
            </div>

            {
                openModal === "SHARE" &&
                <ATQ_Modal_Share
                    isOpen
                    toggle={closeModal}
                    updateMainObject={updateMainObject}
                    setOpenModal={setOpenModal}
                    setCxSelected={setCxSelected}
                />
            }
            {
                openModal === "chat" &&
                <ATQ_Modal_with_Img
                    isOpen
                    toggle={closeModal}
                    type={'normal'}
                    text1={'Se ha enviado reporte de consumo al chat live.'}
                />
            }


        </div>
    )
}
