import React from 'react';
import './sass/styles.scss';
import PropTypes from 'prop-types';

export const StoreHomeAvailableMaterialToAddToggleButtons = ({typeList, setTypeList}) => {
    return (
        <div className="StoreHomeAvailableMaterialToAddToggleButtons_container_toggle">
            <nav className="StoreHomeAvailableMaterialToAddToggleButtons_toggle_container_buttons">
                <button className={typeList === 'LOCATIONS' ? 
                "StoreHomeAvailableMaterialToAddToggleButtons_toggle_button StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_left StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_active"
                    : " StoreHomeAvailableMaterialToAddToggleButtons_toggle_button StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_left"}
                    onClick={()=> setTypeList('LOCATIONS')}>
                    UBICACIONES
                </button>
                <button className=
                    { typeList === 'PRODUCTS' ? "StoreHomeAvailableMaterialToAddToggleButtons_toggle_button StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_rigth StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_active"
                        :" StoreHomeAvailableMaterialToAddToggleButtons_toggle_button StoreHomeAvailableMaterialToAddToggleButtons_toggle_button_left "}
                    onClick={()=> setTypeList('PRODUCTS')}>
                    PRODUCTOS
                </button>
            </nav>
        </div>
    )
}

StoreHomeAvailableMaterialToAddToggleButtons.propTypes = {
    typeList: PropTypes.any,
    setTypeList: PropTypes.any,
}