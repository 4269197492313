import React, { useState,useEffect} from 'react';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import shortid from 'shortid';
import { TableUbication_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Survey/TableUbication_Component';
import './styleAdd.scss';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddReportToAppoiment, UptadeReportToAppoiment } from '../../../actions/atqAdministradorAction';
import { QuillComponent } from '../../../components/ATQ/ATQ_REPORT_EXPERIENCE/QuillComponent';
import { formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';

export const ATQ_Add_Rep_Exp = () => {
    const surgerySelected = useSelector((state) => state.atqAdministrador.surgeryChosed);
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const appoimentSearch = useSelector(state => state.storeHouse.appointmentsListATQ)
    const [newProductsReq, setNewProductsReq] = useState(null);
    const [surtido,setSurtido] = useState(null);
    const [noSurtido, setNoSurtido] = useState(null);
    const [information, setInformation] = useState(surgerySelected.surgeryExperienceReport ? surgerySelected.surgeryExperienceReport.report : null);
    const user = useSelector(state => state.user.user.identity)
    const [coments, setComents] = useState('')

    useEffect(() => {
      if(surgerySelected &&  surgerySelected.surgeryExperienceReport && surgerySelected.surgeryExperienceReport.report && surgerySelected.surgeryExperienceReport.report){
          setComents(surgerySelected &&  surgerySelected.surgeryExperienceReport && surgerySelected.surgeryExperienceReport.report && surgerySelected.surgeryExperienceReport.report)
      }
    }, [])
    
    const history = useHistory();
    const dispatch = useDispatch();
    const handleAddReport = () => {
        if (coments) {
            dispatch(AddReportToAppoiment({
                SurgeryId: surgerySelected.id,
                Report: coments,
               
            }))
            history.replace('/ATQ_Inicio/exp_report/menssage')
        }
    }

    const handleUptadeReport = () => {
        if (coments  && user.roleID !== 9) {
            dispatch(UptadeReportToAppoiment({
                id: surgerySelected.surgeryExperienceReport.id,
                surgeryId: surgerySelected.id,
                report: coments,
               
            }))
            history.replace('/ATQ_Inicio/exp_report/menssage')
        }
        else{
            history.replace('/director/Reporte_de_experiencia')
        }
    
    }

    //----------------------------------------------------- DE AQUI EN ADELANTE ------------------------------------------------------------


    useEffect(() => {
        if(surgerySelected && surgerySelected.id) {
            dispatch(getProductsToAppointmentStoreHose(surgerySelected.id))
            dispatch(getAppointmentLocationsStoreHose(surgerySelected.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])


    useEffect(() => {
        if(AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
                AppointmentWithLocations.productRequirements.map(item => {
                if((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)){
                    arraySurtidos.push(item);
                }else{
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
        }
    }, [AppointmentWithLocations])

   useEffect(() => {
       if(AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0){
           if(appoimentSearch[0] && appoimentSearch[0].productRequirements && appoimentSearch[0] && appoimentSearch[0].productRequirements.length > 0){
                setNoSurtido([appoimentSearch[0].productRequirements]);
           }
       }
   }, [AppointmentLocations,AppointmentProducts])
   
    useEffect(() => {
        if(AppointmentLocations){
            handleCompleteBeginLocations(AppointmentLocations,setNewProductsReq,appoimentSearch[0])
        }
    }, [,AppointmentLocations])


    useEffect(() => {
        
            if( (!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)){   
                {  
                    if(!AppointmentWithLocations || AppointmentWithLocations === null){
                        dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts,newProductsReq,appoimentSearch[0])));
                    }
                }
            }
    
    }, [newProductsReq])


//----------------------------------------------------- DE AQUI EN ADELANTE ------------------------------------------------------------

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: surgerySelected.atq &&
                (`${surgerySelected.atq.firstName && surgerySelected.atq.firstName} ${surgerySelected.atq.secondName && surgerySelected.atq.secondName} ${surgerySelected.atq.firstLastName && surgerySelected.atq.firstLastName} ${surgerySelected.atq.secondLastName && surgerySelected.atq.secondLastName}`)
        },
        {
            uid: shortid.generate(),
            title: 'CX',
            value: `# ${surgerySelected.id}`,
        },
        {
            uid: shortid.generate(),
            title: 'Tipo de CX',
            value: 'Lumbar',
        },
        {
            uid: shortid.generate(),
            title: 'Fecha de última CX',
            type: 'DATE',
            value: surgerySelected.surgeryDate && surgerySelected.surgeryDate,
        },
        {
            uid: shortid.generate(),
            title: 'Hora de CX',
            value: surgerySelected.surgeryDate && (formatHourToPresent(surgerySelected.surgeryDate)),
        },
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: surgerySelected.medic && (`${surgerySelected.medic.name && surgerySelected.medic.name} 
            ${surgerySelected.medic.secondName && surgerySelected.medic.secondName}
            ${surgerySelected.medic.firstLastname && surgerySelected.medic.firstLastname}
            ${surgerySelected.medic.secondLastname && surgerySelected.medic.secondLastname}`),
        },
    ];
   
    return (
        <div className='container_experience'>
            {user.roleID === 9
            ?<SubNavBarComponent title={'REPORTE DE EXPERIENCIA'} historyPage={'/director/Reporte_de_experiencia'} />
            :<SubNavBarComponent title={'REPORTE DE EXPERIENCIA'} historyPage={'/ATQ_Inicio/Reporte_de_experiencia'} />
            }
            <div className='precontainer_experience'>
                <div className='first-section'>
                    <StoreHouseListComponent
                        typeView={{ type: 'REP' }}
                        listFields={arrayListFields}
                    />
                    <TableUbication_Component listItemsBody={surtido} type={'surtido'} />
                </div>
                <div className='second-section'>

                    <span className='text-report'>
                        Redacta tu reporte de experiencia:

                    </span>
                  <section className='section-editText-report'>

                  {/* { information === null
                            ?<textarea 
                                value = {nameRequeriment}
                                className='editor'
                                onChange={handleInputChange}
                                name="nameRequeriment"
                                />
                            :<textarea className='editor'
                                value = {information && information}
                                disabled
                            />
                        } */}

                    {information === null
                        ? <QuillComponent
                        className="editText-report"
                            setComents={setComents}
                            coments={coments}
                        />
                        : <QuillComponent
                        className="editText-report"
                            setComents={setComents}
                            coments={coments}
                            readOnly={user.roleID === 9 ?true :false}
                        />
                    } 

                  </section>
                   <section className='section-btn-report'>
                    {information !== null
                        ? (<button className='btn-continuar' onClick={() =>handleUptadeReport()}>
                            ACEPTAR
                        </button>)
                        : (<button className='btn-continuar' onClick={() => handleAddReport()}>
                            GUARDAR
                        </button>)
                    }

                    </section>
                </div>
            </div>
        </div>
    )
}
