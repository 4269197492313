import React from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { QuotesCompletedData, QuotesPendingData } from './QuotesData';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Container } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  Button,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions
} from "@material-ui/core";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const columns = [
  { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'cotizacionesGuardadas', label: 'COTIZACIONES GUARDADAS', minWidth: 100 },
  { id: 'cliente', label: 'CLIENTE', minWidth: 170 },
  { id: 'tipo', label: 'TIPO', minWidth: 170, },
  { id: 'subtotal', label: 'SUBTOTAL', minWidth: 170, },
  { id: 'iva', label: 'IVA', minWidth: 170, },
  { id: 'total', label: 'TOTAL', minWidth: 170 },
  { id: 'icon', label: 'Ver', minWidth: 20 }
];

const columnsModal = [
  { id: 'id', label: 'ID', minWidth: 200 },
  { id: 'producto', label: 'PRODUCTO', minWidth: 200 },
  { id: 'cantidad', label: 'CANTIDAD', minWidth: 200 },
  { id: 'precio', label: 'PRECIO', minWidth: 200 },
  { id: 'descuento', label: 'DESCUENTO', minWidth: 200 },
  { id: 'total', label: 'TOTAL', minWidth: 200 },
];

function createData(id, cotizacionesGuardadas, cliente, tipo, subtotal, iva, total, icon) {
  return { id, cotizacionesGuardadas, cliente, tipo, subtotal, iva, total, icon };
}

function createDataModal(id, producto, cantidad, precio, descuento, total) {
  return { id, producto, cantidad, precio, descuento, total };
}

const rowsPrincipal = [
  createData('CS 8201-08', 'Productos de cirugia', 'Productos de cirugia', 'Cirugias', '$2,500', '-', '$2,500', '-'),
  createData('CS 2600-6-45', 'Productas de paciente', 'Productas de paciente', 'Complementos y protección', '$1,000', '10%', '$900', '-'),
  createData('170001', 'Batas blancas', 'Batas blancas', 'Batas blacas', '$50', '-', '-', '-'),
];

const rows2 = [
  createDataModal('CS 8201-08', 'CERV-X CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '1', '1,500', '99999', '1,500'),
  createDataModal('CS 2600-6-45', 'COSMICMIA- TORNILLO D6.5 MM, LONGITUD 45MM', '50', '1,000', '10%', '900'),
  createDataModal('170001', 'CONTACT - TORNILLO DE FIJACION', '0', '50', '0', '0'),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const Quotes = () => {
  const { searchQuoteFinished } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rows, setRows] = useState(rowsPrincipal);
  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal.target.value)
    const filteredRows = rowsPrincipal.filter((row) => {
      return Object.keys(row).some((key) =>
        row[key].toLowerCase().includes((searchedVal.target.value).toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (

    <div className={"quote_finished_c"}>

      <SubNavBarComponent
        searchActive={false}
        typeSearch = {'none'}
        specificType={'listaPrecios'}
        historyPage={searchQuoteFinished && `/cotizaciones_finalizadas`}
        title={`Cotizacion`}
      />

      <div className={"quote_completed_general_c"} style={{ marginTop: 60 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
          <TextField
            id="outlined-basic"
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            label="Buscador General"
            variant="outlined"
            style={{ marginRight: 10, width: 300 }}
          />
          <NavLink className="btn" style={{ width: 220 }} to={`/cotizacionesNewInfo`}>
            <button className="btn" style={{ width: 220 }}>Nueva Cotizacion</button>
          </NavLink>
        </div>


        <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id == 'icon' ? <VisibilityIcon onClick={handleOpen} /> : column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle id="form-dialog-title">Detalles de la cotización</DialogTitle>
          <DialogContent style={{ alignSelf: 'center' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columnsModal.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows2.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnsModal.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell>Subtotal</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>750</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Impuesto</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>100</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>2,860</TableCell>
                </TableRow>
              </Table>
            </TableContainer>

          </DialogContent>
          <DialogActions style={{ marginTop: 100 }}>
            <button className="btn" style={{ width: 220 }}>Iniciar Cotización</button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
