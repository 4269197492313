import React, { useEffect, useState } from 'react';
import grayBorderImage from '../../../assets/img/126PX/boy-border-gray.png';

import PropTypes from 'prop-types';
import './style.scss';
import ListItem1 from '../../shared/Molecules/ListItem1';
import icoBuscarGris from '../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import { useDispatch, useSelector } from 'react-redux';
import { getMedics, getMedicsSucess, searchMedics } from '../../../actions/systemsAction';

export const Mess_medics_Req_Izq_Comp = ({selected,setSelected,setCurrentPage,list1,setList1}) => {
    const systems = useSelector( state => state.systems);
    const searchMedicStore = useSelector(state => state.systems.searchMedics);
    const getMedicsStore = systems.getMedics;

    const addRequerimentsListener = useSelector(state => state.systems.addMedicRequeriment);
    const [selectedTabId, setSelectedTabId] = useState(null);
    const [searchMedic, setSearchMedic] = useState("");
    const dispatch = useDispatch();
    const [currentPage, setPage] = useState(0);
       
    const [list, setList] = useState([]); 

    useEffect(() => {
        if(list1 === null){
          setList([])
          dispatch(getMedics(0));
          setSelected(null);
          setList1(0)
        }
      },[list1])
  
    useEffect(() => {
      if(list && list.length > 0){
      setList([...list,...getMedicsStore])
      }else if(getMedicsStore && getMedicsStore.length > 0){
        setList(getMedicsStore)
      }
    },[getMedicsStore])

    const handleScroll = (event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        
        if(scrollHeight-Math.round(scrollTop) === clientHeight){
            setPage(prev => prev + 1)
    
        }
    }

    useEffect(() => {
        dispatch(getMedics(currentPage));
        setSelected(null);
    }, [addRequerimentsListener])
    

    useEffect(() => {
      dispatch(getMedics(currentPage));
  }, [currentPage])

    useEffect(() => {
        setSelected(selectedTabId);
    }, [selectedTabId])

  useEffect(() => {
    return () => {
    dispatch(getMedicsSucess([]));
    }
}, [])

//ESTE ES EL METODO E BUSQUEDA
const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchMedics( searchMedic ));
  }

    

    const selectedTabIdContent = (item) => {
        setSelectedTabId(item.id)
        setSelected(item)
    }
    return (
        <div className={"MedicsHome-left-bar"} onScroll={handleScroll}>
            <div className={"c-medic-c-search"}>
            <form  className="tools-c-input-search" onSubmit={handleSearch} >
                <div className="c-tools-search-icobuscar">
                    <img src={icoBuscarGris} alt="" />
                </div>
                <input
                        type="text"
                        placeholder="Buscar..."
                        name="search"
                        onChange={({target}) => setSearchMedic(target.value)}
                        value={searchMedic}
                        className="tools-input-search"
                />
          </form>
            </div>
            <div className={'profile-list'}>
                {
                    list &&
            searchMedic.trim().length === 0&&
            list.map((item) => (

                        <div key = {item.id} onClick={() => setSelected(item)}>
                            {
                                <ListItem1 
                                    id={item.id} 
                                    userSeller={selected}
                                    key = {item.id}
                                    img={grayBorderImage} 
                                    subtitle={item.name}
                                    firstLastname={item.firstLastname || item.firstLastName}
                                    subtitle2 = {item.email}
                                    selectedTabId={selected && selected.id}
                                />
                            }
                        </div>  
                    ))
                }


                {
                    searchMedicStore &&
                    searchMedic.trim().length > 0 &&
                    searchMedicStore.map((item) => (

                        <div key = {item.id} onClick={() => selectedTabIdContent(item)}>
                            {
                                <ListItem1 
                                    id={item.id} 
                                    userSeller={selected}
                                    key = {item.id}
                                    img={grayBorderImage} 
                                    subtitle={item.name}
                                    firstLastname={item.firstLastname || item.firstLastName}
                                    subtitle2 = {item.email}
                                    selectedTabId={selectedTabId}
                                />
                            }
                        </div>  
                    ))
                }
            </div>

        </div>
    )
}
Mess_medics_Req_Izq_Comp.propTypes = {
    selected: PropTypes.any,
    setSelected: PropTypes.any,
}