import React from 'react';
import icoSave from '../../../assets/img/512PX/ico_comsum_report.png';
import './styleMensagge.scss';
export const ATQ_com_rep_men = ({id=32}) => {
    return (
        <div className={"Final_com_rep_finish"}>
        <div className={"Final_com_rep_finish_general_c"}>
          <div className={"Final_com_rep-images"}>
             <img className="Final_com_rep-success-img" src={icoSave} alt="" />
          </div>
                <p className={"Final_com_rep_finish-subtitle"}>{'se ha GUARDADO Reporte de Consumo de:'} </p>
                <p className={"Final_com_rep_finish-subtitle"}>{`CX#${id}`} </p>
          <div className={"Final_com_rep_order_buttons"}>
            <button className="Final_com_rep-white-button" >
              <span> ACEPTAR</span>
            </button>
          </div>
        </div>
      </div>
    )
}
