import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../components/shared/Atoms/Button';
import { useHistory, withRouter } from 'react-router-dom';
import './UserRightBar.scss';
import PropTypes from 'prop-types';
import UserProfileData from './UserProfileData';
import UsersTabBase from './UsersTabBase';
import bigBlueBorderImage from '../../../../../assets/img/512PX/Logo_TSI_512PX.jpg';
import { setSellerUser } from '../../../../../actions/allUsersAction';



const UserRightBar = ({ selectedSeller, setSelectedSeller }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [userRol, setUserRol] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const data = useSelector(state => state.allUsers);
    const userId = useSelector(state => state.user.user.identity);
    const styleHeader = ['atq', 'doctor', 'adminAtq', 'seller','storer']


    useEffect(() => {
        if (data.selectedUserInfo && data.selectedUserInfo.length > 0) {
            setUserInfo(data.selectedUserInfo[0])
        }
        if (data.userRolSelected) {
            setUserRol(data.userRolSelected)
        }
    }, [data])


    const renderSecondPartUserInstitutions = (item) => {
        return (

            <>
                <div className={'second-part-header'}>

                    <div className={"profile-content"}>
                        <span className={"requirements_title"}>Requerimientos</span>
                    </div>

                    <div className={"requirements_content"}>
                        {
                            item.requirements &&
                            item.requirements.map((i, index) => {
                                return (
                                    <p className='requirements-sesion-institucions'>{(index + 1) + '.'}{i.requirement}</p>
                                );
                            })
                        }
                    </div>




                </div>

                <div className='container-institutions-ubicacion'>
                    <button className='btn-institution'>ubicación</button>
                </div>

                <div className='line-institutions'></div>
            </>


        )
    }



    const renderHeader = (item) => {
        return (
            <>
                <div className={`${data.userRolSelected === "patients" ? '' : (styleHeader.includes(data.userRolSelected)) ? 'doctor-right-bar-header-left' : 'doctor-right-bar-header'}`}>
                    {data.userRolSelected && data.userRolSelected !== "patients" &&
                        <div className={'profile-image'}>
                            <div className={"profile-image-border"}>
                                <img src={bigBlueBorderImage} alt={"image"} />
                            </div>
                        </div>}
                    {
                        data.userRolSelected && data.userRolSelected === 'institutions' &&
                        <div className='text-description-institutions'>
                            <p className='text-title-institutions'>{item.name}</p>
                        </div>

                    }
                    <div className='info-hospital-institucions'>
                        <UserProfileData selectedSeller={selectedSeller} item={item} />
                    </div>
                </div>
                {
                    data.userRolSelected && data.userRolSelected === 'institutions' &&
                    renderSecondPartUserInstitutions(item)
                }
            </>
        );
    };

    const usersOnClick = () => {
        history.goBack();
    };

    const permisosVentorsView = () => {
        dispatch(setSellerUser(selectedSeller));
        setSelectedSeller(null);
        history.replace('/usuarios/vendedores/permisos');
    }

    return (
        <div className={'doctor-right-bar'}>
            <div className={'doctor-right-bar-buttons-container'}>
                {
                    userRol === 'seller' && selectedSeller && userId && (userId.roleID === -1 || userId.roleID === 4) &&
                    <Button color={"blue_light"} title={"PERMISOS"} onClick={permisosVentorsView} />
                }
                {
                    selectedSeller &&
                    <Button color={"blue_light"} title={"USUARIOS"} onClick={usersOnClick} />
                }
            </div>
            {

                <div>
                    {
                        selectedSeller &&
                        renderHeader(selectedSeller)
                    }

                    {/* 
                     {
                       selectedSeller &&
                        <>
                             <UserProfileData userData={userInfo}/> 
                              <PatientsRightBarInfo/>  
                        </>

                    } */}

                    {selectedSeller &&
                        <UsersTabBase selectedSeller={selectedSeller}
                            setSelectedSeller={setSelectedSeller} />


                    }
                </div>
            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}

UserRightBar.propTypes = {
    selectedSeller: PropTypes.any,
    setSelectedSeller: PropTypes.any,
}

export default withRouter(connect(mapStateToProps)(UserRightBar));
