import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { useDispatch } from 'react-redux';
import { getDriveAccess } from '../../actions/systemsAction';

import icoDisMat from '../../assets/img/512PX/Icono_DisponibilidadATQ_512.png';
import icoAgenda from '../../assets/img/512PX/Icono_Agenda_512.png';
import icoEncuesta from '../../assets/img/512PX/Icono_Encuestra_512.png';
/* import reportConsumo from '../../assets/img/512PX/report_consumo512.png'; */
import reporteExperiencia from '../../assets/img/512PX/report_experience512.png';
import reporteConsumo from '../../assets/img/512PX/IcoReportCom512.png';
import infoProd from '../../assets/img/512PX/inf_prod_512.png';
export const ATQ_Menu_Page = () => {


    const dispatch = useDispatch();


    return (
        <div className='principal-container'>
            <div className='post-container'>
                <div className='first-row'>
                
                    {/* <NavLink className='report-consum'
                        to={'/ATQ_Inicio/Rep_Con'}>
                        <img src={reportConsumo} alt="" className='img-reporte-consumo' />
                        <h3 className='report'> Reporte de consumo </h3>
                    </NavLink> */}

                    <button className='report-consum' disabled>
                        <img src={reporteConsumo} alt="" className='img-reporte-consumo disabled' />
                        <h3 className='report'>Reporte de consumo</h3>
                    </button> 


                    <NavLink className='report-experiencie'
                        to={'/ATQ_Inicio/Reporte_de_experiencia'}>
                        <h3 className='report-experience'> Reporte de experiencia </h3>
                        <img src={reporteExperiencia} alt="" className='img-reporte-experience' />
                    </NavLink>

                </div>
                <div className='second-row'>
                    <NavLink className='agenda'
                        to={'/agenda/agenda'}>
                        <img src={icoAgenda} alt="" className='img-agenda' />
                        <h3 className='agenda-text'> Agenda </h3>
                    </NavLink>
                    <NavLink className='encuesta'
                        to={'/ATQ_Inicio/Encuesta_List'}>
                        <img src={icoEncuesta} alt="" className='img-encuesta'/>
                        <h3 className='encuesta-text'> Encuestas </h3>
                </NavLink>  

                    {/* <button className='encuesta'>
                        <img src={icoEncuesta} alt="" className='img-encuesta' />
                        <h3 className='encuesta-text'>Encuestas</h3>
                    </button>  */}

                    {/* <NavLink className='informacion'
                to={'/ATQ_Inicio/informationProd'}>
                        <img src={infoProd} alt="" className='img-informacion'/>
                        <h3 className='informacion-text'> Informacion del producto </h3>
                </NavLink> */}

                    <button className='informacion' onClick={() => dispatch(getDriveAccess())} >
                        <img src={infoProd} alt="" className='img-informacion' />
                        <h3 className='informacion-text'> Información Productos TSI  </h3>
                    </button>

                </div>
            </div>
        </div>
    )
}
