import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import { Survey_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Survey/Survey_Component';
import { TableUbication_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Survey/TableUbication_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { ATQ_Individual_Compo } from '../../../components/ATQ/ATQ_ENCUESTA/ATQ_Individual_Compo';
import icoAdd from '../../../assets/img/126PX/IcoAgregarCi.png';
import './style.scss';
import { useHistory, useParams } from 'react-router-dom';
import iconDel from '../../../assets/img/126PX/iconDeleteComp.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getAppointmentsStoreHouseSuccessAtq, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';
import { surveyATQAdmin, surveySetCommetAdminAtq } from '../../../actions/surveyAction';



export const ATQAdministrator_TableUbication_Pages = () => {


    const [componentText, setComponentText] = useState(false);
    const dispatch = useDispatch();
    const surveyID = useSelector(state => state.survey.setSurveyAppoimentSelected.surgeryAppointment);
    const surveySele = useSelector(state => state.survey.setSurveyAppoimentSelected);
    const { Switch, id } = useParams();
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const [newProductsReq, setNewProductsReq] = useState(null);
    const history = useHistory();
    const [newCommentSurvey, setNewCommentSurvey] = useState({
        Commentary: "",
    })

    const handleSendData = async () => {
        const resultado = await dispatch(surveyATQAdmin(newCommentSurvey, parseInt(id)))
        if (resultado === true) {
            history.push(`/Quality_Messages_Page/${id}`);
        }
    }

    useEffect(() => {
        if (surveyID && surveyID.id) {
            dispatch(getProductsToAppointmentStoreHose(surveyID.id))
            dispatch(getAppointmentLocationsStoreHose(surveyID.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])

    useEffect(() => {
        if (AppointmentLocations) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, surveyID)
        }
    }, [, AppointmentLocations])

    useEffect(() => {

        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
            {
                if (!AppointmentWithLocations || AppointmentWithLocations === null) {
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, surveyID)));
                }
            }
        }
    }, [newProductsReq])


    useEffect(() => {
        return () => {
            dispatch(getAppointmentsStoreHouseSuccessAtq([]));
        }
    }, [])


    const coments_users = [
        {
            uid: shortid.generate(),
            title: 'Gerente de operaciones',
            description: 'Comentarios del gerente de operaciones…',
        },
        {
            uid: shortid.generate(),
            title: 'Comercial',
            description: surveySele.salesSurvey && surveySele.salesSurvey.Commentary  ?surveySele.salesSurvey.Commentary   :'Comentarios del vendedor...',
        },
        {
            uid: shortid.generate(),
            title: 'Calidad',
            description: 'Comentarios del gerente de calidad...',
        },
        {
            uid: shortid.generate(),
            title: 'Coordinador Almacén',
            description: surveySele && surveySele.warehouseSurvey ? surveySele.warehouseSurvey.coordinatorCommentary :  "Comentarios de almacén..."
        },
        {
            uid: shortid.generate(),
            title: 'Médico',
            description: 'Comentarios del médico...',
        },
    ]

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: (surveyID && surveyID.medic) &&
                (surveyID.medic && surveyID.medic.name && surveyID.medic.name) + ' ' +
                (surveyID.medic && surveyID.medic.secondName && surveyID.medic.secondName) + ' ' +
                (surveyID.medic && surveyID.medic.firstLastname && surveyID.medic.firstLastname) + ' ' +
                (surveyID.medic && surveyID.medic.secondLastname && surveyID.medic.secondLastname),
        },
        {
            uid: shortid.generate(),
            title: 'Institucion',
            value: (surveyID && surveyID.hospital && surveyID.hospital.name),
        },
        {
            uid: shortid.generate(),
            title: 'Fecha y Hora',
            type: 'DATE-COMPLETE',
            value: (surveyID && surveyID.surgeryDate),
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: (surveyID && surveyID.atq ?
                `${surveyID.atq.firstName && surveyID.atq.firstName} ${surveyID.atq.secondName && surveyID.atq.secondName}
                 ${surveyID.atq.firstLastName && surveyID.atq.firstLastName} `
                : 'ATQ NO ASIGNADO'),
        },
    ];
    const handleAceptar = async () => {
        if (newCommentSurvey.Commentary.length  > 0){
             const resultado = await dispatch(surveySetCommetAdminAtq(newCommentSurvey,parseInt(surveySele.adminATQSurvey.id)))
             if (resultado === true) {
                 history.push('/ATQAdministratorMenuSurvery');
             
             }
         }
     }
   

    return (
        <div className="container-atq">
            <SubNavBarComponent title={'ENCUESTA'} searchActive={false} historyPage={'/ATQAdministratorMenuSurvery'} />
            <div className="container-atq-colums">
                <section >
                    <StoreHouseListComponent
                        typeView={{ title: `CX #${id}`, type: 'STORE', }}
                        listFields={arrayListFields}
                    />
                    <TableUbication_Component
                        listItemsBody={AppointmentWithLocations && AppointmentWithLocations.productRequirements && AppointmentWithLocations.productRequirements.length > 0 ? AppointmentWithLocations.productRequirements : null}
                    />
                </section>
                <div className="precontainer-atq_survey">
                    <span className="survey_title">Seguimiento para uso externo de TSI</span>
                    <div className="container-atq_survey">
                        <Survey_Component
                            titulo={'ATQ'}
                            tipo={'atq'}
                        />
                        {coments_users &&
                            coments_users.map(item => (
                                <div className='individual-container-atq_New'>
                                    <ATQ_Individual_Compo
                                        titulo={item.title}
                                        tipo={'Agregado'}
                                        comentario={item.description}
                                        setNewCommentSurvey={setNewCommentSurvey}
                                        newCommentSurvey={newCommentSurvey}
                                    />
                                </div>
                            ))
                        }
                        <div className='individual-container-atq_New'>
                                <ATQ_Individual_Compo
                                    titulo={'Jefe de ATQ'}
                                    tipo={Switch === 'ACTIVE' ?'Nuevo' :'Agregado'}
                                    comentario={Switch === 'ACTIVE' ?'Agregar nuevo comentario' :surveySele.adminATQSurvey.commentary ?surveySele.adminATQSurvey.commentary :''}
                                    setNewCommentSurvey={setNewCommentSurvey}
                                    newCommentSurvey={newCommentSurvey}
                        />
                        </div>
                        {
                            surveySele.adminATQSurvey && surveySele.adminATQSurvey.commentaries.length > 0 &&
                            surveySele.adminATQSurvey.commentaries.map(item => (
                                <div key={shortid.generate} className='individual-container_New'>
                                    <ATQ_Individual_Compo
                                        titulo={'Comentarios Agregados'}
                                        tipo={'Agregado'}
                                        comentario={item.description && item.description}

                                    />
                                </div>
                            ))
                        }
                        {componentText === true &&
                            <div className='individual-container-atq_New'>
                                <ATQ_Individual_Compo
                                    titulo={'Agregar nuevo comentrio'}
                                    tipo={'Nuevo'}
                                    comentario={'Agregar nuevo comentario'}
                                    setNewCommentSurvey={setNewCommentSurvey}
                                    newCommentSurvey={newCommentSurvey}
                                />
                            </div>
                        }
                    </div>
                    {
                        Switch === 'ACTIVE'

                            ? <button className='btn-Aceptar21' onClick={() => handleSendData() }>
                                    <p className='btn-title'>GUARDAR</p>
                                </button>
                          
                            : componentText === true
                            ?<div className='container-atq-buttons-add'><img className='icoAdd' src={iconDel} onClick={() => setComponentText(!componentText)}/>
                                <button className='btn-Aceptar2-add' onClick={() => handleAceptar()}>
                                    <p className='btn-title'>ACEPTAR</p>
                                </button>
                             </div>

                            :<div className='container-atq-buttons-add'><img className='icoAdd' src={icoAdd} onClick={() => setComponentText(!componentText)}/>
                                <button className='btn-Aceptar2-add' onClick={() => handleAceptar()}>
                                    <p className='btn-title'>ACEPTAR</p>
                                </button>
                             </div>
                    }
                </div>
            </div>
        </div>
    )
}