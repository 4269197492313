import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import black_arrow_down from '../../../assets/img/126PX/black_arrow_down.png';
import './stylesButtons.scss';

export const ATQAdministrator_Mat_History_List_Info_Component = ({ listado }) => {

    // const lista = [
    // 'TETRIS 12',
    // 'TETRIS 14',
    // 'SET CUCH 13',
    // 'PHANTOM CERV 14',
    // 'DISTRACTOR 3',
    // 'OSMIUM 6',
    // 'TAVA 2',
    // 'MALETÍN LUZ 9',
    // 'INSTR. LUMBAR 12'
    // ];

    /* console.log('listado', listado) */

    const [surtidos, setSurtidos] = useState(null);
    const [entregados, setEntregados] = useState(null);
    const [recogidos,setRecogidos] = useState(null);

    const [selectedSurgeryItem, setSelectedSurgeryItem] = useState(null);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);

    const toggleOpenSurgeryItem = (item) => {
        setSelectedSurgeryItem(selectedSurgeryItem && selectedSurgeryItem.id == item.id ? null : item);
    };


    useEffect(() => {
      if(listado && listado.length > 0){
            let arraySurt = [];
            let arrayEntr = [];
            let arrayReco = [];
            const date = moment("0001-01-01T00:00:00")
            listado.map(item => {
                arraySurt.push(item);
                if(item.deliveryDate > date){
                    arrayEntr.push(item)
                }
                if(item.returnDate >date){
                    arrayReco.push(item);
                }
            })
            setSurtidos([...arraySurt]);
            setEntregados([...arrayEntr]);
            setRecogidos([...arrayReco]);
      }
    }, [listado])
    

    const detail = (arrayType) => {
        let lista = null;
        if(arrayType === "surtidos"){
            lista = surtidos;
        }else if(arrayType === "entregados"){
            lista = entregados
        }else{
            lista = recogidos
        }
        return (
            <div className="extra_info">
                <div className="content_info">
                    <div className="title-rigth">Lugar:</div>
                    <div className="title-left">Almacén</div>
                </div>
                <div className="content_info2">
                    <div className="title-rigth2">Ubicación(es):</div>
                    <div className="title-left2">
                        {
                            lista && lista.map(item => (
                                <span className="list_ubication">
                                    {item.product ? item.Ubicacion ? item.Ubicacion : item.product.productDescription : (item.location && item.location.ubicacion)}
                                </span>
                            ))
                        }
                    </div>
                </div>
                <div className="content_info3">
                    <div className="title-rigth3">Mensajero:</div>
                    <div className="title-left3">---</div>
                </div>
                <div className="content_info4">
                    <div className="title-rigth4">Motivo de recolección:</div>
                    <div className="title-left4">Programación CX</div>
                </div>
            </div>

        );
    }

    const arreglo = [{
        id: 1,
        title: 'Material listo para recogerse',
        date: '---',
        hour: '---',
        lista: "surtidos",
    },
    {
        id: 2,
        title: 'Material en proceso de entrega',
        date: '---',
        hour: '---',
        lista: "entregados",
    },
    {
        id: 3,
        title: 'Material recolectado',
        date: '---',
        hour: '---',
        lista: "recogidos",
    }];


    return (
        <div className="preconter_listButton">
            <ul className="content">
                {

                    arreglo.map(item => (
                        <li className="list" key={item.id}>

                            <button className="text_infoButton" onClick={() => toggleOpenSurgeryItem(item)}>

                                <span className="btn-green">
                                    {item.id}
                                </span>

                                <span className="info">
                                    {item.title + ' - ' + item.date + ' - ' + item.hour}
                                </span>
                                {
                                    selectedSurgeryItem && selectedSurgeryItem.id == item.id &&
                                    <img src={black_arrow_down} alt="arrow" className={'storeHouseStock_elements_img_arrowButton'} />
                                }
                                {
                                    (_.isNull(selectedSurgeryItem) || (!_.isNull(selectedSurgeryItem) && selectedSurgeryItem.id != item.id)) &&
                                    <img src={black_arrow} className={'storeHouseStock_elements_img_arrowButton'} />
                                }

                                {
                                    selectedSurgeryItem && selectedSurgeryItem.id == item.id &&
                                    detail(item.lista)
                                }

                            </button>
                        </li>
                    ))

                }
            </ul>
        </div>
    );
}
