import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import {  getAppointmentsStoreHouseAtqWithParams, getAppointmentsStoreHouseSuccessAtq } from '../../../actions/storeHouseAction';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { setSurgeryChoosed } from '../../../actions/atqAdministradorAction';

export const ATQAdministrator_Material_History_List_Component = () => {
    const history = useHistory();
    const distpatch = useDispatch();
    const listApoiment = useSelector(state => state.storeHouse.appointmentsListATQ);
    const searchList = useSelector(state => state.storeHouse.listSearch)
    const startDate = '1900-01-01T01:01:57'
    const endDate = '2200-05-06T01:01:33'
    const searchBy = 'LOGISTIC_INCOMPLETE'
    const searchByParam = '3'
    const offset=10;
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState([]); 
    const [search, setSearch] = useState([])

    useEffect(() => {
        if(searchList && searchList[0] && searchList[0].surgeryStatusId  >= 3 ){
            setSearch(searchList)
        }
        else if((searchList && searchList.length === 0 )|| (searchList && searchList.surgeryStatusId != 3) ){
            setSearch([]);
        }
    }, [searchList])
    


    const handleNivagate = (item) => {
        distpatch(setSurgeryChoosed(item));
        history.replace('/ATQAdministrator_Materials_History/Material_Info');
}

useEffect(() => {
    return () => {
        distpatch(getAppointmentsStoreHouseSuccessAtq(null));
    }
}, [])

useEffect(() => {
    if(currentPage === 0){
        distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy,null,currentPage,offset))   
    }else if(currentPage > 0 && listApoiment !== null) {
            distpatch(getAppointmentsStoreHouseAtqWithParams(startDate,endDate,searchBy,null,currentPage,offset))  
    } 
}, [currentPage])
    
//ESTE ES PARA USAR EL LIST 
useEffect(() => {
    if(list && list.length > 0 && listApoiment.length > 0){
    setList([...list,...listApoiment])
    }else if(listApoiment && listApoiment.length > 0){
      setList(listApoiment)
    }
  },[listApoiment])

   //scroll
   const handleScroll = (event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        if((scrollHeight-Math.round(scrollTop) - clientHeight) <= 1){
            setPage(prev => prev + 1)
        }

    }


    return (
        <div className="preconter_list_material" onScroll={handleScroll}>
        {
            search && 
            search.length > 0 

           ? search.map( item => (
            <div key={item.id} className="content_info" >
                <div className="text_info" onClick={() => handleNivagate(item)}>
                    <span className="info_date_numberPqx">
                        <p>
                          {'Fecha: '} {item.surgeryDate && formatDateToPresent(item.surgeryDate)}
                        </p>
                        <p>-</p>
                        <p>
                        {'Hora: '} {item.surgeryDate && formatHourToPresent(item.surgeryDate)}
                        </p>
                        <p>-</p>
                        <p>
                            {'PQX#'}{item.id}
                        </p>
                    </span>
                    <span className="info_date_numberPqx">
                        <p>
                            Origen: Almacen
                        </p>
                        <p>-</p>
                        <p>
                          {'Destino: '} {item.hospital && item.hospital.name}
                        </p>
                    </span>
                </div>
                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
            </div>
            ))



            :list &&
            list.map( item => (
            <div key={item.id} className="content_info" >
                <div className="text_info" onClick={() => handleNivagate(item)}>
                    <span className="info_date_numberPqx">
                        <p>
                          {'Fecha: '} {item.surgeryDate && formatDateToPresent(item.surgeryDate)}
                        </p>
                        <p>-</p>
                        <p>
                        {'Hora: '} {item.surgeryDate && formatHourToPresent(item.surgeryDate)}
                        </p>
                        <p>-</p>
                        <p>
                            {'PQX#'}{item.id}
                        </p>
                    </span>
                    <span className="info_date_numberPqx">
                        <p>
                            Origen: Almacen
                        </p>
                        <p>-</p>
                        <p>
                          {'Destino: '} {item.hospital && item.hospital.name}
                        </p>
                    </span>
                </div>
                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
            </div>
            ))
        }
        </div>
    )
}
