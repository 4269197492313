import React from 'react';
import './styleTable.scss';
import addImgButton from '../../../assets/img/512PX/Icono_Mas_512px.png';
import closeImgButton from '../../../assets/img/126PX/ico_delete.png';
import { SubdirectoryArrowLeftOutlined } from '@material-ui/icons';

export const ATQ_Con_Table = ({setOpenModal}) => {
    const data = [
        {
            cantidad: '4',
            id:'CS 1301-14T',
            descripcion:'Osmium - tornillo para hueso esponjoso, de 4 mm, fabricado de titanio, largo 14 mm.',
            precio:'$559.36',
            subtotal:'$2,237.43'
        },
        {
            cantidad: '1',
            id:'CS 2253-12-13',
            descripcion:'Addplus -d 12 mm, altura 13-18 mm, 0º.',
            precio:'$45,993.08',
            subtotal:'$45,993.08'
        },
        {
            cantidad: '4',
            id:'CS 1301-14T',
            descripcion:'Osmium - tornillo para hueso esponjoso, de 4 mm, fabricado de titanio, largo 14 mm.',
            precio:'$559.36',
            subtotal:'$2,237.43'
        },
        {
            cantidad: '1',
            id:'CS 2253-12-13',
            descripcion:'Addplus -d 12 mm, altura 13-18 mm, 0º.',
            precio:'$45,993.08',
            subtotal:'$45,993.08'
        },
        {
            cantidad: '4',
            id:'CS 1301-14T',
            descripcion:'Osmium - tornillo para hueso esponjoso, de 4 mm, fabricado de titanio, largo 14 mm.',
            precio:'$559.36',
            subtotal:'$2,237.43'
        },
        {
            cantidad: '1',
            id:'CS 2253-12-13',
            descripcion:'Addplus -d 12 mm, altura 13-18 mm, 0º.',
            precio:'$45,993.08',
            subtotal:'$45,993.08'
        },
        
    ];
     return (
        <div className="ConRepor-container-table">
            <div className="ConRepor-precontainer-table">
                <table className="ConRepor-table">
                    <thead className="ConRepor-table-head">
                        <tr className="ConRepor-table-tr">
                            <th className="ConRepor-table-title">Cantidad</th>
                            <th className="ConRepor-table-title">ID Producto</th>
                            <th className="ConRepor-table-title">Descripción</th>
                            <th className="ConRepor-table-title ">Precio</th>
                            <th className="ConRepor-table-title">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody className="ConRepor-table-body">
                    {
                        data.map( (el,index) => (
                            
                            <tr key={el.uid} className="ConRepor-table-tr" >
                                <td className="ConRepor-body-data" >
                                    {el.cantidad}
                                </td>
                                <td className="ConRepor-body-data">
                                    {el.id}
                                </td>
                                <td className="ConRepor-body-data">
                                    {el.descripcion}
                                </td>
                                <td className="ConRepor-body-data">
                                    {el.precio}
                                </td>
                                <td className="ConRepor-body-data">
                                    {el.subtotal}
                                </td>
                                <td className="ConRepor-body-data shtmriric-button">
                                    <button className="ConRepor-button"> 
                                        <img className='ConRepor-button-img' src={closeImgButton} alt="" />
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <button className='ConRepor-section-rigth-card-container-add-container'>
                    <img className='ConRepor-section-rigth-card-container-add' src={addImgButton} alt="" onClick={() =>setOpenModal('MODAL-LIST')} />
                </button>
            </div>
        </div>
    )
}
