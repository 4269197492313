import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupGuard, getHolidays, setModifyHolidays, getHolidaysSuccess } from '../../../actions/atqAdministradorAction';
import { addGuardGroupHolidaySuccess, deleteGuardGroupHolidaySuccess } from '../../../actions/guardGroupHolidayAction';
import { ATQ_Administrator_GuardRol_component } from '../../../components/ATQAdministrator/ATQ_Administrator_GuardRol_component/ATQ_Administrator_GuardRol_component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { formatDateToInsert, getNameDay, getYear, numberGetMonth } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { sortBy } from 'lodash';
import "./sass/styles.scss";
import { useHistory } from 'react-router-dom';

export const ATQ_Administrator_GuardRol_View = () => {

    const [itemSelected, setItemSelected] = useState("");
    const [listMonth, setListMonth] = useState(null);
    const [allMonsths, setAllMonths] = useState(null);
    const [guardGroupHoliday, setGuardGroupHoliday] = useState([]);

    const listTeamGroup = useSelector((state) => state.atqAdministrador.group);
    const listGuardDays = useSelector(state => state.atqAdministrador.holidays);
    const listGuardDaysModified = useSelector(state => state.atqAdministrador.holidaysWithGroup);
    const AddTeam = useSelector(state => state.guardGroupHoliday.lastAddGroupGuardHoliday);
    const DeleteTeam = useSelector(state => state.guardGroupHoliday.deleteTeam);
    const finisheProced = useSelector(state => state.atqAdministrador.finished);
    const reorderTeam = useSelector(state => state.guardGroupHoliday.reorderSpefic);
    const history = useHistory();
    const [date, setDate] = useState(null);
    let months = {};
    let keys = null;
    const dispatch = useDispatch();

    useEffect(() => {
        
        return () => {
            dispatch(setModifyHolidays(null));
            dispatch(getHolidaysSuccess(null));
        }
     }, [])

    useEffect(() => {  
        const date1 = new Date();
        setDate(getYear(date1))
     }, [ ])
    
    useEffect(() => {  
       listDay();
    }, [ finisheProced])

    useEffect(() => {  
        if(AddTeam) {
            dispatch(getGroupGuard());        
            dispatch(getHolidays(`${date}-01-01T00:00:04`, `${date}-12-30T12:00:04`));
        }
        if(AddTeam){
            dispatch(addGuardGroupHolidaySuccess(null));
        }
    }, [ AddTeam])

    useEffect(() => {  
        if(DeleteTeam ) {
            dispatch(getGroupGuard());        
            dispatch(getHolidays(`${date}-01-01T00:00:04`, `${date}-12-30T12:00:04`));
        }
        if(DeleteTeam){
            dispatch(deleteGuardGroupHolidaySuccess(null));
        }
    }, [ DeleteTeam])


    useEffect(() => {  
        if(reorderTeam ) {
            dispatch(getGroupGuard());        
            dispatch(getHolidays(`${date}-01-01T00:00:04`, `${date}-12-30T12:00:04`));
        }
        if(reorderTeam){
            dispatch(deleteGuardGroupHolidaySuccess(null));
        }
    }, [ reorderTeam])



    const recorridoLisTeamGroup =  () => {
        let holidays = JSON.parse(JSON.stringify(listGuardDays));
        listTeamGroup.map((group, indexGroup) => {
            group.assignedHolidays.forEach((days, index) => {
                holidays.map((d, i) => {
                     if (d.id === days.guardGroupHoliday.id) {
                        holidays[i]['group'] = {
                            group:  group.groupName,
                            idDelete: days.id,
                            groupId: group.id,
                            members: days.guardGroupMember.length > 0 ?days.guardGroupMember :sortBy(group.members, (member) => member.orderInGroup)
                        }
                    }
                });
            })
        });

        dispatch(setModifyHolidays(holidays));
       
    };


    useEffect(() => {
        if (listTeamGroup && listGuardDays) {
            recorridoLisTeamGroup();
        }
    }, [listTeamGroup])


    const meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ]

    const traducirDias = (day) => {
        switch (day) {
            case 'Monday':
                return 'Lunes';
            case 'Tuesday':
                return 'Martes';
            case 'Wednesday':
                return 'Miercoles';
            case 'Thursday':
                return 'Jueves';
            case 'Friday':
                return 'Viernes'
            case 'Saturday':
                return 'Sabado';
            case 'Sunday':
                return 'Domingo';
            default:
                return ''
        }
    }
    const traducirMonths = (month) => {
        switch (month) {
            case 'January':
                return 'Enero';
            case 'February':
                return 'Febrero';
            case 'March':
                return 'Marzo';
            case 'April':
                return 'Abril';
            case 'May':
                return 'Mayo'
            case 'June':
                return 'Junio';
            case 'July':
                return 'Julio';
            case 'August':
                return 'Agosto';
            case 'September':
                return 'Septiembre';
            case 'October':
                return 'Octubre';
            case 'November':
                return 'Noviembre';
            case 'December':
                return 'Diciembre';
            default:
                return month
        }
    }

    useEffect(() => {
        if(!listGuardDays && !listTeamGroup){
            dispatch(getGroupGuard());
            dispatch(getHolidays(`${date}-01-01T00:00:04`, `${date}-12-30T12:00:04`));
        }
    }, [date])

    const listDay = () => {
        
        meses.forEach((element, index) => {
            listGuardDaysModified &&
            listGuardDaysModified.map((item, indexDay) => {
                    if (traducirMonths(numberGetMonth(item.holidayDate)) === element) {
                        if (months[element]) {
                            months[element] = [
                                ...months[element],
                                {
                                    id: item.id,
                                    day: `${traducirDias(getNameDay(item.holidayDate))}`,
                                    date: `${formatDateToInsert(item.holidayDate)}`,
                                    groupId: item.group && item.group.groupId,
                                    members: item.group && item.group.members,
                                    idDelete: item.group && item.group.idDelete,
                                    groupName: item.group && item.group.group,
                                    indexDay,
                                }
                            ]
                        } else {
                            months[element] = [
                                {
                                    id: item.id,
                                    day: `${traducirDias(getNameDay(item.holidayDate))}`,
                                    date: `${formatDateToInsert(item.holidayDate)}`,
                                    groupId: item.group && item.group.groupId,
                                    members: item.group && item.group.members,
                                    idDelete: item.group && item.group.idDelete,
                                    groupName: item.group && item.group.group,
                                    indexDay,
                                }
                            ]
                        }
                    }
                });
        });
        keys = Object.keys(months);
        setListMonth(keys);
        setAllMonths(months);
    }

   /* ÇÇ */

    useEffect(() => {
        listDay();

    }, [listGuardDaysModified])


    const handleGroupSelected = (item) => {
        setItemSelected(item);
    }

    return (
        <div className=" ATQ_Administrator_GuardRol_View_container ">
            <SubNavBarComponent historyPage={"/ATQ_GuardRole_View"} searchActive={false} title={"ROL DE GUARDÍAS"} />
            <div className="ATQ_Administrator_GuardRol_View_items_container">
                <div className="ATQ_Administrator_GuardRol_View_buttons_team_container">
                    {
                        listTeamGroup &&
                        listTeamGroup.map((el) => (
                            <button className={el.id !== itemSelected.id ? "ATQ_Administrator_GuardRol_View_button_team" : "ATQ_Administrator_GuardRol_View_button_team ATQ_Administrator_GuardRol_View_button_team_active"}
                                key={el.id} onClick={() => handleGroupSelected(el)}>
                                {el.groupName}
                            </button>
                        ))
                    }
                </div>
                <div className="ATQ_Administrator_GuardRol_View_calendar_container">

                    {
                        listMonth &&
                        listMonth.map((m) => (

                            <ATQ_Administrator_GuardRol_component month={m} listDays={allMonsths[m]} guardTeam={itemSelected} setGuardGroupHoliday={setGuardGroupHoliday} />

                        ))
                    }
                </div>
                <div className="ATQ_Administrator_GuardRol_View_buttons_container">

                    <button className="ATQ_Administrator_GuardRol_View_button" onClick={() => history.replace("/ATQ_GuardRole_View/ATQ_GuardRole_CreateTeam_View")}>
                        EQUIPOS DE GUARDÍA
                    </button>
                    <button className="ATQ_Administrator_GuardRol_View_button" onClick={() => history.replace("/ATQ_GuardRole_View")}>
                        ACEPTAR
                    </button>
                </div>
            </div>
        </div>
    )
}

