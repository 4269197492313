import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles_MASXC.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';

export const ATQ_Disponibilidad_MasCX_List_Component = ({page}) => {
    const history = useHistory();
    const handleNivagate = () => {
        history.replace('/Inicio');
    };

    const listAsignar = [
        {
            date: '10/09/2021',
            hour: '11:00 hrs',
            idCX:'15'
        },
        {
            date: '11/09/2021',
            hour: '14:00 hrs',
            idCX:'16',
        },
        {
            date: '12/09/2021',
            hour: '18:00 hrs',
            idCX:'17',
        },
        {
            date: '13/09/2021',
            hour: '11:00 hrs',
            idCX:'18',
        },
        {
            date: '13/09/2021',
            hour: '14:00 hrs',
            idCX:'19',
        },
        {
            date: '14/09/2021',
            hour: '18:00 hrs',
            idCX:'20',
        },
    ];

    return (
        <div className="preconter_list_asignar_cx" onClick={handleNivagate}>
        {
            listAsignar.map( item => (
            <div className="content_info_asignar">
                <div className="text_info_asignar">
                    <span className="info_date_numberPqx_asignar">
                        { 'CX #' + item.idCX + ' - ' +item.date + ' - ' + item.hour }
                    </span>
                </div>
                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
            </div>
            ))
        }
        </div>
    )
}
