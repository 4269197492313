import React, { useState } from 'react';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { ListEncuestasVentasComponent } from '../../components/ListEncuestasComponent/ListEncuestasVentasComponent';
import './styleEncuestaVen.scss';
import { ComingSoonComponent } from '../ComingSoon/ComingSoonComponent';



export const EncuestaVentasPage = () => {

  const [activeSwitchVentas, setActiveSwitchVentas] = useState('ACTIVE');
  const [from,setFrom] = useState('dad');

  const handleChange  = (item) => {
    setFrom('dad')
      if(item === 'active'){
        setActiveSwitchVentas('ACTIVE')
      } else{
        setActiveSwitchVentas('INACTIVE')
      }
  }


  return (
    <>
      <ComingSoonComponent />

      {/*<div className='containerGeneral-encuesta-ventas'>

      <SubNavBarComponent title={'ENCUESTA'}
        searchActive={true}
        historyPage={'/Inicio'}
        typeSearch={'SurveyPending'}
        specificType={'id'} />


      <div className='body-encuestas-ventas'>

        <section className='btn-encuestas-ventas'>
          <button className={activeSwitchVentas === 'ACTIVE'
            ? 'btn-ventas btn-ventas-active'
            : 'btn-ventas'} onClick={() => handleChange('active')}>
            PENDIENTES
          </button>

          <button className={activeSwitchVentas === 'INACTIVE'
            ? 'btn-ventas btn-ventas-active'
            : 'btn-ventas'} onClick={() => handleChange('inactive')}>
            FINALIZADOS
          </button>

        </section>

        <section className='body-encuestas-list' >
          <ListEncuestasVentasComponent activeSwitchVentas={activeSwitchVentas} 
            setActiveSwitch={setActiveSwitchVentas}
            setFrom = {setFrom}
            from = {from}
          />
        </section>

      </div>

    </div>*/}
    </>
  )
}
