import React, { useEffect, useState } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import './styleEnc.scss';
import shortid from 'shortid';
import { ATQ_Table_Component } from '../../../components/ATQ/ATQ_ENCUESTA/ATQ_Table_Component';
import { ATQ_Individual_Compo } from '../../../components/ATQ/ATQ_ENCUESTA/ATQ_Individual_Compo';
import icoAdd from '../../../assets/img/126PX/IcoAgregarCi.png';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getAppointmentsStoreHouseSuccessAtq, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { surveyATQ, surveySetCommetATQ } from '../../../actions/surveyAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';
import { TableUbication_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Survey/TableUbication_Component';

export const ATQ_Enc_Ped_Page = () => {
    const [componentText, setComponentText] = useState(false);
    const [newCommentSurvey, setNewCommentSurvey] = useState({
        Commentary: "",
    })
    const [newCommentS, setNewCommentS] = useState(null);
    const surveySelected = useSelector(state => state.survey.setSurveyAppoimentSelected)
    const surveyID = useSelector(state => state.survey.setSurveyAppoimentSelected.surgeryAppointment)
    const atqSurvey = useSelector(state => state.survey.setSurveyAppoimentSelected.atqSurvey)
    const commentaries = useSelector(state => state.survey.setSurveyAppoimentSelected.atqSurvey)
    
    const dispatch = useDispatch();
    const { Switch, id } = useParams();
    const history = useHistory();


    const [valueSurvey, setValueSurvey] = useState({
        AllOk: true,
        AllOkCommentary: "",
        INSD6: false,
        INSD6Commentary: "",
    })

    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const [newProductsReq, setNewProductsReq] = useState(null);

    useEffect(() => {
        if(surveyID && surveyID.id) {
            dispatch(getProductsToAppointmentStoreHose(surveyID.id))
            dispatch(getAppointmentLocationsStoreHose(surveyID.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])

    useEffect(() => {
        if(AppointmentLocations){
            handleCompleteBeginLocations(AppointmentLocations,setNewProductsReq,surveyID)
        }
    }, [,AppointmentLocations])

    useEffect(() => {
        
            if( (!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)){   
                {  
                    if(!AppointmentWithLocations || AppointmentWithLocations === null){
                        dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts,newProductsReq,surveyID)));
                    }
                }
            }
    
    }, [newProductsReq])


    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: (surveyID  && surveyID.medic) &&
                (surveyID.medic && surveyID.medic.name && surveyID.medic.name) + ' ' +
                (surveyID.medic && surveyID.medic.secondName && surveyID.medic.secondName) + ' ' +
                (surveyID.medic && surveyID.medic.firstLastname && surveyID.medic.firstLastname) + ' ' +
                (surveyID.medic && surveyID.medic.secondLastname && surveyID.medic.secondLastname),
        },
        {
            uid: shortid.generate(),
            title: 'Institucion',
            value: (surveyID && surveyID.hospital && surveyID.hospital.name),
        },
        {
            uid: shortid.generate(),
            title: 'Fecha y Hora',
            type: 'DATE-COMPLETE',
            value: (surveyID && surveyID.surgeryDate),
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: ( surveyID && surveyID.atq ?
                `${surveyID.atq.firstName && surveyID.atq.firstName } ${surveyID.atq.secondName && surveyID.atq.secondName}
                 ${surveyID.atq.firstLastName && surveyID.atq.firstLastName} `
                : 'ATQ NO ASIGNADO'),
        },
    ];

    useEffect(() => {
        return () => {
            dispatch(getAppointmentsStoreHouseSuccessAtq([]));
        }
    }, [])

    const handleSendData = async () => {
        const resultado = await dispatch(surveyATQ(valueSurvey, parseInt(id)))
        if (resultado === true) {
            history.push(`/Quality_Messages_Page/${id}`);
        }
    }

    const handleAceptar = async () => {
       if (newCommentSurvey){
            const resultado = await dispatch(surveySetCommetATQ(newCommentSurvey,parseInt(atqSurvey.id)))
            if (resultado === true) {
                history.push('/ATQ_Inicio/Encuesta_List');
            
            }
        }
    }

    return (
        <div className='container_ATQ_Enc'>
            <SubNavBarComponent title={'ENCUESTA'} historyPage={'/ATQ_Inicio/Encuesta_List'} />
            <div className='precontainer_ATQ_Enc'>
                <section className='sect_ATQ_Left'>
                    <StoreHouseListComponent
                        typeView={{ type: 'STORE', title: `CX #${id}` }}
                        listFields={arrayListFields}
                    />
                      <TableUbication_Component 
                    listItemsBody={ AppointmentWithLocations && AppointmentWithLocations.productRequirements && AppointmentWithLocations.productRequirements.length > 0  ?AppointmentWithLocations.productRequirements :null }
                    />
                    <button className='btn-Aceptar'>
                        <p className='btn-title'>CONSUMO</p>
                    </button>
                </section>


                <section className='sect_ATQ_Rigth'>
                    <span className="survey_title_ATQ">Seguimiento para uso externo de TSI</span>
                    <div className='container-questions'>
                        <div className='individual-container'>

                            {
                                Switch === 'INACTIVE'
                                    ? (<ATQ_Individual_Compo
                                        titulo={'ATQ'}
                                        tipo={'atq'}
                                        setValueSurvey={setValueSurvey}
                                        valueSurvey={valueSurvey}
                                    />)
                                    : (<ATQ_Individual_Compo
                                        titulo={'ATQ'}
                                        tipo={'ATQAgregar'}
                                        setValueSurvey={setValueSurvey}
                                        valueSurvey={valueSurvey}
                                    />)
                            }
                        </div>

                         {
                            commentaries && commentaries.commentaries.length > 0 &&
                            commentaries.commentaries.map(item => (
                                <div key={shortid.generate} className='individual-container_New'>
                                    <ATQ_Individual_Compo
                                        titulo={'ATQ - Nuevos Comentarios'}
                                        tipo={'Agregado'}
                                        comentario={item.description && item.description}

                                    />
                                </div>
                            ))
                        } 

                        {
                            componentText === true &&
                            <div className='individual-container_New'>
                                <ATQ_Individual_Compo
                                    titulo={'ATQ - Nuevos Comentarios'}
                                    tipo={'Nuevo'}
                                    setNewCommentSurvey={setNewCommentSurvey}
                                    newCommentSurvey={newCommentSurvey}
                                />
                            </div>
                        }
                    </div>
                    {
                        Switch === 'INACTIVE'
                            ? (<div className='container-buttons'>
                                <img className='icoAdd' src={icoAdd} onClick={() => setComponentText(!componentText)} />
                                <button className='btn-Aceptar2' onClick={() => handleAceptar()} >
                                    <p className='btn-title'>ACEPTAR</p>
                                </button>
                            </div>)
                            : (<button className='btn-Aceptar21' onClick={handleSendData}>
                                <p className='btn-title'>GUARDAR</p>
                            </button>)
                    }
                </section>
            </div>
        </div>
    )
}
