import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentsStoreHouseAtq, getSearchListHouseSuccessAtq, setAppoiment } from '../../../actions/storeHouseAction';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';

export const ATQAdmin_EstatusCX_Componet = () => {
    const distpatch = useDispatch();
    const listApoiment = useSelector(state => state.storeHouse.appointmentsListATQ);
    const listSearch = useSelector(state => state.storeHouse.listSearch);
    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';
    const searchBy = 'ANY';
    const offset = 10;
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState([]); 
    const history = useHistory();


    useEffect(() => {
        distpatch(getAppointmentsStoreHouseAtq(startDate,endDate,searchBy,null,currentPage,offset));
    }, [currentPage])


    useEffect(() => {

        return () => {
            distpatch(getSearchListHouseSuccessAtq(null))  
        }
    }, [])
    


    //ESTE ES PARA USAR EL LIST 
    useEffect(() => {
        if(list && list.length > 0){
        setList([...list,...listApoiment])
        }else if(listApoiment && listApoiment.length > 0){
          setList(listApoiment)
        }
      },[listApoiment])


      //scroll
      const handleScroll = (event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        
        if(scrollHeight-Math.round(scrollTop) === clientHeight){
            setPage(prev => prev + 1)
    
        }
    }

    const handleNivagate = (item) => {
        distpatch(setAppoiment(item));
        history.replace(`/Atq_Admin_Estatus_Info`);
}

    return (
        <div className="preconter_list_statusCX" onScroll={handleScroll}>

        {
            !listSearch 
            ?(
                listApoiment &&
                list &&
                list.map( item => (
                <div className="content_info_statusCX" key={item.id} onClick={() => handleNivagate(item)}>
                    <div className="text_info_statusCX">
                        <span className="info_date_number_statusCX">
                            {'CX #' + item.id + ' - ' + 'Fecha: ' + formatDateToPresent(item.surgeryDate)+ ' - ' + 'Hora: ' +formatHourToPresent(item.surgeryDate)}
                        </span>
                        <span className="info_date_number_statusCX">
                            {item.hospital.name}
                        </span>
                    </div>
                    <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                </div>
                ))
            )
            :(
                listSearch &&
                listSearch.map( item => (
                <div className="content_info_statusCX" key={item.id} onClick={() => handleNivagate(item)}>
                    <div className="text_info_statusCX">
                        <span className="info_date_number_statusCX">
                            {'CX #' + item.id + ' - ' + 'Fecha: ' + formatDateToPresent(item.surgeryDate)+ ' - ' + 'Hora: ' +formatHourToPresent(item.surgeryDate)}
                        </span>
                        <span className="info_date_number_statusCX">
                            {item.hospital.name}
                        </span>
                    </div>
                    <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                </div>
                ))
            )
        }
        </div>
    )
}
