import React from 'react'
import imgAgregar from '../../../../assets/img/126PX/icon_add_almacen.png';

export const CheckListCom = ({setChecklist,checklist}) => {
  return (
    <div className='body-Acciones-checklist'>
        <div className='btn-accion'>
            <span className='title-btn' onClick={() => setChecklist(!checklist)}>CREAR CHECKLIST DE ACCIONES
                <img className='img-accion' src={imgAgregar}></img>
            </span>
        </div>
    </div>
  )
}
