import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { SubNavBarComponent } from '../../../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './styleMobileOrdBillData.scss';
import { getCatCfdi, getCatRegFiscal, searchByNameAsync, updateClient, updateClientBilling, addClient, updateClientSData, updateEnvioClientSData, updateBillingClientSData } from '../../../../../actions/userActions'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../../hooks/useForm';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, 
         InputAdornment, InputLabel, MenuItem, makeStyles, Radio, RadioGroup, Select, Snackbar, Tab, Tabs, TextField, IconButton, TextareaAutosize } from '@material-ui/core';
import { Alert, TabPanel } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import icoHistory from '../../../../../assets/img/126PX/Icono_atras_126px.png'
import cfiWhiteIcon from '../../../../../assets/img/126PX/cfiWhite.png'
import CloseIcon from '@material-ui/icons/Close';
import checkIcon from '../../../../../assets/img/126PX/Icono_Check_126px.png';

//****************************** */
import moment from 'moment/moment';
import { Autocomplete } from "@material-ui/lab";
//import cfdiCatalog from '../../../../../assets/catalogs/cfdi.json'
//import regimenCatalog from '../../../../../assets/catalogs/regimen.json'
import { asyncGetClientByRFC } from '../../../../../actions/userActions';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { string } from 'prop-types';

const useStyles = makeStyles({
    input: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    },
  });

export const MobileOrdBillData = (props) => {

    const previousRfc = props.history.location.state.rfc;
    /* type 1: requiresBill = true and billData exist 
      type 2: requiresBill = false and billData doesn't exist
    */
    const billType = props.history.location.state.bill; 
    const previousInfo = props.history.location.state.row;

    const loginData = useSelector((state) => state.user.user.identity)
    const requestH = useSelector((state) => state.user.request)
    const [alert, setAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorAlertText, setErrorAlertText] = useState('');

    /*************************************************************** */
    const [valueClientStockNew, setValueClientStockNew] = React.useState('new');
    const [orderNumberSale, setNumberOrderSale] = useState('');
    const [orderNumberSaleStatus, setNumberOrderSaleStatus] = useState(false);
    const [checked, setChecked] = React.useState(true);
    const [checkedClient, setCheckedClient] = React.useState(true);
    const [checkedShip, setCheckedShip] = React.useState(true);
    const classes = useStyles();
    const [valueCFDI, setValueCFDI] = React.useState('');
    const [inputValueCFDI, setInputValueCFDI] = React.useState('');
    const [valueRegimen, setValueRegimen] = React.useState('');
    const [inputValueRegimen, setInputValueRegimen] = React.useState('');
    const [clientSearchRFC, setClientSearchRFC] = useState('')
    const [selectedRFC, setSelectedRFC] = useState('none')
    const [rfcInfo, setRfcInfo] = useState('')
    const [rfcResults, setRfcResults] = useState()
    const [cfdiCatalog, setCfdiCatalog] = useState([]);
    const [regimenCatalog, setRegimenCatalog] = useState([]);
    const [openCfiDialog, setOpenCfiDialog] = useState(false);
    const [openRfcDialog, setOpenRfcDialog] = useState(false);
    const [rfcSearchResults, setRfcSearchResults] = useState([]);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      /*type 1: requiresBill = true and billData exist 
        type 2: requiresBill = false and billData doesn't exist 
      */
     console.log("PREVIOUS: ", previousInfo)
     getSATCats();

      if(billType == 1) {
        existingData(previousRfc);
      }
      else
      {
        newData()
      }
    }, [previousRfc, billType])

    async function getSATCats() {
      await getCatCfdi(requestH).then((res) => {
        if (res.sucess)
        {
          setCfdiCatalog(res.response)

          if (billType === 1) {
            setValueCFDI(previousInfo.billData.cfdiUse !== null ? res.response.find((val) => val.cucClave === previousInfo.billData.cfdiUse) : '')
          }
        }
        else console.log("ERROR: ", res.errorMessage)
       })

       getCatRegFiscal(requestH).then((res) => {
        if (res.sucess)
        {
          setRegimenCatalog(res.response)
          if (billType === 1) {
            setValueRegimen(previousInfo.billData.billingRaxRegime !== null ? res.response.find((val) => val.crfClave.includes(previousInfo.billData.billingRaxRegime)) : '')
          }
        }
        else console.log("ERROR: ", res.errorMessage)
       })
     }

    const handleChange = () => {
        setChecked(!checked);
    };

    const handleChangeStockNew = (event) => {
        setValueClientStockNew(event.target.value);
    };

    const [newClientData, setNewClientData] = useState({
        /*clientName: '',*/
        clientShortName: '',
        phone: '',
        email: '',
        comments: ''
      })

    const [newClientDataStatus, setNewClientDataStatus] = useState({
        /*clientName: false,*/
        phone: false,
        email: false,
        clientShortName: false,
        comments: false
    })

    const [facturationData, setFacturationData] = useState({
        razonSocial: '',
        rfc: '',
        domicilio: '',
        colonia: '',
        cp: '',
        estado: '',
        ciudad: '',
        /*cfdi: '',
        regimen: '',
        condicionPago: '' ,
        metodoPago: '',
        formaPago: '',
        numeroPago: '',*/
    })
    
    const [facturationDataStatus, setFacturationDataStatus] = useState({
        razonSocial: false,
        rfc: false,
        domicilio: false,
        colonia: false,
        cp: false,
        estado: false,
        ciudad: false,
        /*cfdi: false,
        regimen: false,
        condicionPago: false,
        metodoPago: false,
        formaPago: false,*/
    })

    const [facturationExistingData, setFacturationExistingData] = useState({
      cfdi: '',
      regimen: '',
      condicionPago: '', //billType === 1 ? (previousInfo.billData.paymentCondition !== null ? previousInfo.billData.paymentCondition : '') : '',
      metodoPago: '', //billType === 1 ? (previousInfo.billData.paymentCondition === 'Contado' ? 'Pago una solo exhibición (PUE)' : 'Parcialidades (PPD)') : '',
      formaPago: '', //billType === 1 ? (previousInfo.billData.paymentMethod !== null ? previousInfo.billData.paymentMethod : '' ) : '',
      numeroPago: '', //billType === 1 ? (previousInfo.billData.paymentNumber !== null ? previousInfo.billData.paymentNumber : '') : '',
    })

    const [facturationExistingDataStatus, setFacturationExistingDataStatus] = useState({
      cfdi: false,
      regimen: false,
      condicionPago: false,
      metodoPago: false,
      formaPago: false,
      rfcSelected: false,
      numeroPago: false
    })

    const [shipmentData, setShipmentData] = useState({
      deliveryDate: moment().format('YYYY-MM-DD'),
      reference: '',
      address: '',
      contactData: '',
      otherData: ''
    })

    const [shipmentDataStatus, setShipmentDataStatus] = useState({
      reference: false,
      address: false,
    })

    /*Form onChanges */
    const handleShipmentValues = (event) => {
      const newJson = { ...shipmentData }
      newJson[event.target.id] = event.target.value
      setShipmentData(newJson)
      checkShipFields();
    }

    const handleNewClientValues = (event) => {
        const newJson = { ...newClientData }
        newJson[event.target.id] = event.target.value
        setNewClientData(newJson)
        checkClientFields()
    }

    const handleBillDataValues = (event) => {
      const billJson = { ...facturationData };
      billJson[event.target.id] = event.target.value;
      setFacturationData(billJson);
      checkFields()
    }

    /*----------------------------------------------------------------------------------------------- */
    const handleInputValueCFDI = (value) => {
        setInputValueCFDI(value)
    }

    const handleInputValueRegimen = (value) => {
      setInputValueRegimen(value)
    }

    const handleSelectRFC = (value, set = true, array = rfcResults) => {
      setSelectedRFC(value);
      const found = array.filter((singleValues) => {
        return singleValues.id == value
      })
      if (set)
        setClientSearchRFC(found[0].rfc)
      setRfcInfo(found[0])
    };

    const handleChangeRFC = async (event) => {
      setClientSearchRFC(event.target.value);
      /*if (event.target.value) {
        await asyncGetClientByRFC(event.target.value, requestH).then((data) => {
          setRfcResults(data.response)
          if (data.response && data.response.length > 0) {
            handleSelectRFC(data.response[0].id, false, data.response)
          }
        })
      }*/
      checkFields()
    };

    const searchRfc = async () => {
      if (clientSearchRFC !== '')
      {
        await asyncGetClientByRFC(clientSearchRFC, requestH).then((data) => {
          setRfcSearchResults(data.response)
          openDialogRfcResults();
        })
      }
    }

    //fill the existing form if bill data exist
    const existingData = async (rfc) => {
      let rfcVal = (rfc !== null && rfc !== "undefined" && rfc !== "null") ? rfc : ''
      setClientSearchRFC(rfcVal);
      /*await asyncGetClientByRFC(rfc, requestH).then((data) => {
        setValueClientStockNew('stock');
        setRfcResults(data.response)
        if (data.response && data.response.length > 0) {
          handleChangeRFC({ target: { value: rfc } })
        }
      })*/

        //Client Data
        const clientJson = { ...newClientData }
        clientJson.clientShortName = (previousInfo.client && previousInfo.client.clientName && previousInfo.client.clientName !== "undefined" && previousInfo.client.clientName !== "null") ? 
                                      previousInfo.client.clientName : '';
        /*clientJson.clientName = previousInfo.client.clientName !== null ? previousInfo.client.clientName : '';*/
        clientJson.phone = (previousInfo.client.phone && previousInfo.client.phone !== "undefined" && previousInfo.client.phone !== "null" ) ? previousInfo.client.phone : '';
        clientJson.email = (previousInfo.client.email && previousInfo.client.email !== "undefined" && previousInfo.client.email !== "null") ? previousInfo.client.email : '';
        clientJson.comments = (previousInfo.remarks && previousInfo.remarks !== "undefined" && previousInfo.remarks !== "null") ? previousInfo.remarks : '';
        setNewClientData(clientJson);

        //Bill Data
        const billJson = { ...facturationData };
        billJson.razonSocial = (previousInfo.billData.billingName && previousInfo.billData.billingName !== "undefined" && previousInfo.billData.billingName !== "null") ? 
                                previousInfo.billData.billingName : '';
        billJson.rfc = (previousInfo.billData.billingRFC && previousInfo.billData.billingRFC !== "undefined" && previousInfo.billData.billingRFC !== "null") ? previousInfo.billData.billingRFC: '';
        billJson.domicilio = (previousInfo.client.address && previousInfo.client.address !== "undefined" && previousInfo.client.address !== "null") ? previousInfo.client.address: '';
        billJson.colonia = (previousInfo.client.neighborhood && previousInfo.client.neighborhood !== "undefined" && previousInfo.client.neighborhood !== "null") ? 
                            previousInfo.client.neighborhood: '';
        billJson.ciudad = (previousInfo.client.city && previousInfo.client.city !== "undefined" && previousInfo.client.city !== "null") ? previousInfo.client.city : '';
        billJson.estado = (previousInfo.client.state && previousInfo.client.state !== "undefined" && previousInfo.client.state !== "null") ? previousInfo.client.state : '';
        billJson.cp = (previousInfo.client.cp && previousInfo.client.cp !== "undefined" && previousInfo.client.cp !== "null") ? previousInfo.client.cp : '';
        setFacturationData(billJson);

        const existingBillJson = { ...facturationExistingData };
        existingBillJson.condicionPago = previousInfo.billData.paymentCondition !== null ? previousInfo.billData.paymentCondition : '';
        existingBillJson.metodoPago = previousInfo.billData.paymentCondition === 'Contado' ? 'Pago una solo exhibición (PUE)' : 'Parcialidades (PPD)';
        existingBillJson.formaPago = previousInfo.paymentMethod !== null ? previousInfo.paymentMethod : ''; //DD Forma de pago
        existingBillJson.numeroPago = previousInfo.billData.paymentNumber !== null ? previousInfo.billData.paymentNumber : '';
        setFacturationExistingData(existingBillJson);

        //Shipment Data
        const shipJson = { ...shipmentData };
        shipJson.address = (previousInfo.addressShipping && previousInfo.addressShipping !== 'null' && previousInfo.addressShipping !== 'undefined' ) ? 
                            previousInfo.addressShipping : '';
        shipJson.reference = (previousInfo.shippingInstruction && previousInfo.shippingInstruction !== 'null' && previousInfo.shippingInstruction !== 'undefined') ?
                              previousInfo.shippingInstruction : '';
        setShipmentData(shipJson);
    }

    const newData = () => {
      //Client Data
      const clientJson = { ...newClientData }
      clientJson.clientShortName = (previousInfo.client && previousInfo.client.clientName && previousInfo.client.clientName !== "undefined" && previousInfo.client.clientName !== "null") ? 
                                    previousInfo.client.clientName : '';
      /*clientJson.clientName = previousInfo.client.clientName !== null ? previousInfo.client.clientName : '';*/
      clientJson.phone = (previousInfo.client.phone && previousInfo.client.phone !== "undefined" && previousInfo.client.phone !== "null" ) ? previousInfo.client.phone : '';
      clientJson.email = (previousInfo.client.email && previousInfo.client.email !== "undefined" && previousInfo.client.email !== "null") ? previousInfo.client.email : '';
      clientJson.comments = (previousInfo.remarks && previousInfo.remarks !== "undefined" && previousInfo.remarks !== "null") ? previousInfo.remarks : '';
      setNewClientData(clientJson);

      const existingBillJson = { ...facturationExistingData };
      existingBillJson.formaPago = previousInfo.paymentMethod !== null ? previousInfo.paymentMethod : ''; //DD Forma de pago
      existingBillJson.numeroPago = previousInfo.paymentNumber !== null ? previousInfo.paymentNumber : '';
      setFacturationExistingData(existingBillJson);

      //Shipment Data
      const shipJson = { ...shipmentData };
      shipJson.address = (previousInfo.addressShipping && previousInfo.addressShipping !== 'null' && previousInfo.addressShipping !== 'undefined' ) ? 
                          previousInfo.addressShipping : '';
      shipJson.reference = (previousInfo.shippingInstruction && previousInfo.shippingInstruction !== 'null' && previousInfo.shippingInstruction !== 'undefined') ?
                            previousInfo.shippingInstruction : '';
      setShipmentData(shipJson);
    }

    const handleFacturacioExistingValues = (event) => {
      if (event.target.name == 'formaPago' || event.target.name == 'condicionPago') {
        const newJson = { ...facturationExistingData }
        console.log(event.target)
        if (event.target.name == 'condicionPago')
        {
          if(event.target.value == 'Contado')
            newJson['metodoPago'] = "Pago una solo exhibición (PUE)";
          else
            newJson['metodoPago'] = "Parcialidades (PPD)"
        }
        newJson[event.target.name] = event.target.value
        setFacturationExistingData(newJson)
      } else {
        const newJson = { ...facturationExistingData }
        newJson[event.target.name] = event.target.value
        setFacturationExistingData(newJson)
      }
      checkFields()
    }

    const validateEmail = (email) => {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (validRegex.test(email)) {
        return true;
      } else {
        return false;
      }
    };

    const checkFields = () => {
      const newJsonFacturation = { ...facturationDataStatus }
      const newJsonExistingData = { ...facturationExistingDataStatus }

      newJsonFacturation.razonSocial = !facturationData.razonSocial && facturationData.razonSocial.length == 0
      newJsonFacturation.rfc = !facturationData.rfc && facturationData.rfc.length == 0
      newJsonFacturation.domicilio = !facturationData.domicilio && facturationData.domicilio.length == 0
      newJsonFacturation.colonia = !facturationData.colonia && facturationData.colonia.length == 0
      newJsonFacturation.ciudad = !facturationData.ciudad && facturationData.ciudad.length == 0
      newJsonFacturation.estado = !facturationData.estado && facturationData.estado.length == 0
      newJsonFacturation.cp = !facturationData.cp && facturationData.cp.length == 0
      /*newJsonFacturation.cfdi = !valueCFDI
      newJsonFacturation.regimen = !valueRegimen && valueRegimen.length == 0
      newJsonFacturation.condicionPago = !facturationData.condicionPago && facturationData.condicionPago.length == 0
      newJsonFacturation.metodoPago = !facturationData.metodoPago && facturationData.metodoPago.length == 0
      newJsonFacturation.formaPago = !facturationData.formaPago && facturationData.formaPago.length == 0*/

      newJsonExistingData.cfdi = !valueCFDI
      newJsonExistingData.regimen = !valueRegimen && valueRegimen.length == 0
      newJsonExistingData.condicionPago = !facturationExistingData.condicionPago && facturationExistingData.condicionPago.length == 0
      newJsonExistingData.metodoPago = !facturationExistingData.metodoPago && facturationExistingData.metodoPago.length == 0
      newJsonExistingData.formaPago = !facturationExistingData.formaPago && facturationExistingData.formaPago.length == 0
      newJsonExistingData.numeroPago = !facturationExistingData.numeroPago && facturationExistingData.numeroPago.length == 0
      newJsonExistingData.rfcSelected = !rfcInfo

      setFacturationDataStatus(newJsonFacturation)
      setFacturationExistingDataStatus(newJsonExistingData)

    }

    const checkClientFields = () => {
      const newJsonClient = { ...newClientDataStatus }

      newJsonClient.clientShortName = !newClientData.clientShortName && newClientData.clientShortName.length == 0
      newJsonClient.phone = !newClientData.phone && newClientData.phone.length == 0
      newJsonClient.email = !validateEmail(newClientData.email) || (!newClientData.email && newClientData.email.length == 0 && !validateEmail(newClientData.email))
      newJsonClient.comments = !newClientData.comments && newClientData.comments.length == 0

      setNewClientDataStatus(newJsonClient)
    }

    const checkShipFields = () => {
      const newJsonShip = { ...shipmentDataStatus}

      newJsonShip.address = !shipmentData.address && shipmentData.address.length == 0
      newJsonShip.reference = !shipmentData.reference && shipmentData.reference.length == 0;

      setShipmentDataStatus(newJsonShip);

    }

    const openBillingPhoto = (base64URL) =>
    {
      /*var win = window.open();
      win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      */
     setOpenCfiDialog(true);
    }

    const handleCloseCfiDialog = () => {
      setOpenCfiDialog(false);
    }

    const openDialogRfcResults = () => { setOpenRfcDialog(true) }
    const handleCloseRfcDialog = () => { setOpenRfcDialog(false) }

    const saveClientData = async () => {
      if (newClientData.clientShortName && newClientData.phone && newClientData.email && newClientData.comments)
      {
        setCheckedClient(false)
        let body = {
          "saleId": previousInfo.id,
          "clientId": previousInfo.client.id,
          "clientName": newClientData.clientShortName,
          "phone": newClientData.phone,
          "email": newClientData.email,
          "remarks": newClientData.comments
        }
        await updateClientSData(body, requestH).then((data) => {
          if (data.sucess === true)
          {
            setOpenSuccessDialog(true)
          }
        })
      } else
      {
        console.log("FALTAN DATOS")
        checkClientFields();
        setErrorAlertText("DATOS DEL CLIENTE")
        setErrorAlert(true)
      }
    }

    const saveBillData2 = async () => {
      if (facturationData.razonSocial && facturationData.rfc && facturationData.domicilio && facturationData.colonia && facturationData.ciudad &&
        facturationData.estado && facturationData.cp && valueCFDI && valueRegimen && facturationExistingData.condicionPago &&
        facturationExistingData.metodoPago && facturationExistingData.formaPago)
      {
        let body = {
          "saleId": previousInfo.id,
          "clientId": previousInfo.client.id,
          "clientShortName": facturationData.razonSocial,
          "rfc": facturationData.rfc,
          "address": facturationData.domicilio,
          "neighborhood": facturationData.colonia,
          "city": facturationData.ciudad,
          "state": facturationData.estado,
          "cp": facturationData.cp,
          "billingUsoCfdi": valueCFDI.cucClave,
          "billingRaxRegime": valueRegimen.crfClave,
          "paymentCondition": facturationExistingData.condicionPago,
          "paymentMethod": facturationExistingData.metodoPago,
          "paymentPayForm": facturationExistingData.formaPago,
          "paymentNumber": facturationExistingData.numeroPago
        }
  
        await updateBillingClientSData(body, requestH).then((data) => {
          if (data.sucess === true)
            setOpenSuccessDialog(true)
        })
      } else {
        checkFields()
        setErrorAlertText("DATOS DE FACTURACIÓN")
        setErrorAlert(true)
      }
    }

    const saveShipData = async () => {
      if (shipmentData.address && shipmentData.reference)
      {
        let body = {
          "saleId": previousInfo.id,
          "clientId": previousInfo.client.id,
          "addressShipping": shipmentData.address,
          "shippingInstruction": shipmentData.reference
        }
  
        await updateEnvioClientSData(body, requestH).then((data) => {
          if (data.sucess === true)
            setOpenSuccessDialog(true)
        })
      } else {
        checkShipFields()
        setErrorAlertText("DATOS DE ENVIO")
        setErrorAlert(true)
      }
    }

    const closeAlert = (event) => {
        setAlert(false);
    }

    const closeErrorAlert = (event) => {
      setErrorAlert(false);
  }

    /* AG GRID RFC SEARCH RESULTS */
    const gridRef = useRef();
    const pagination = true;
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [5, 10, 15, 20, 25, 50, 100, 500, 1000];
    const rowHeight = 70;
    const showScroll = true;

    const tableColumns = [
      {
        field: 'rfc', headerName: 'RFC', suppressMovable: true, type: 'rfc', sortable: false, flex: 1, wrapText: true, autoHeight: true
      },
      {
        field: 'clientShortName', headerName: 'Nombre', type: 'shortName', suppressMovable: true, sortable: false, flex: 1, wrapText: true, autoHeight: true
      },
      {
        field: 'clientName', headerName: 'Razón social', type: 'client', suppressMovable: true, sortable: false, flex: 1, wrapText: true, autoHeight: true
      }
    ];

    const columnTypes = useMemo(() => {
      return {
        rfc: {
          valueFormatter: getRfc
        },
        client: {
          valueFormatter: getClientName
        },
        shortName: {
          valueFormatter: getClientShortName
        } 
      };
  }, []);

    const defaultColDef = useMemo(() => {
      return {
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerStyle: () => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignItems: "center"
          }),
          cellStyle: () => ({
              display: "flex",
              alignItems: "center",
          })
      };
  }, []);

  const autoSizeStrategy = {
      type: 'fitGridWidth',
      //type: 'fitCellContents',
      defaultMinWidth: 100,
  };

  function getRfc(data) {
    return data.data.rfc
  }

  function getClientName(data) {
    return data.data.clientName
  }

  function getClientShortName(data) {
    return data.data.clientShortName
  }

  const onSelectionChanged = () => {
    const selectedData = gridRef.current.api.getSelectedRows();
    console.log('Selection Changed', selectedData);

    //handleSelectRFC(data.response[0].id, false, data.response)
    handleSelectRFC(selectedData[0].id, false, selectedData)

    //Client Data
    /*const newJson = { ...newClientData }
    //newJson.clientShortName = selectedData[0].clientShortName;
    //newJson.clientName = selectedData[0].clientName; 
    newJson.phone = selectedData[0].phone;
    newJson.email = selectedData[0].email;
    setNewClientData(newJson)*/

    //Bill Data
    const billJson = { ...facturationData }
    billJson.razonSocial = selectedData[0].clientName;
    billJson.rfc = selectedData[0].rfc;
    billJson.domicilio = selectedData[0].address;
    billJson.colonia = selectedData[0].neighborhood;
    billJson.ciudad = selectedData[0].city;
    billJson.estado = selectedData[0].state;
    billJson.cp = selectedData[0].cp
    setFacturationData(billJson)

    const existingBillJson = { ...facturationExistingData };
    existingBillJson.condicionPago = selectedData[0].paymentCondition;
    setFacturationExistingData(existingBillJson);


    handleCloseRfcDialog();
    //console.log("RFC SELECCIONADO: ", selectedData[0].id)
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false)
}

    return (
        <div className='containerBillData'>
            <nav className='c-quote-navbar' style={{ opacity: 1 }}>
              <button className='quote-navbar-c-history'
                onClick={() => {
                    props.history.goBack()
                }}>
                <img className='quote-navbar-history' src={icoHistory} alt="" />
              </button>
              <h3 className='quote-navbar-title' >VENTA MÓVIL</h3>
          </nav>
            <div className='headerBill'>
                <div className='titleBill'>
                    <p>DATOS DE FACTURACIÓN</p>
                    <hr />
                </div>
                <div style={{width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                    <button className={ !previousInfo ? 'disableButton' : (previousInfo.billData.billingUrl === "" ? 'disableButton' : 'importBtn')} onClick={() => window.open(previousInfo.billData.billingUrl, "_blank")} >
                        <img src={cfiWhiteIcon} width={38} height={28}/>
                          VER CFI
                    </button>

                    <button className={ !previousInfo ? 'disableButton' : ((previousInfo.billData.billingPhotoCed === "" || previousInfo.billData.billingPhotoCed === "null" ) ? 'disableButton' : 'importBtn')} 
                      onClick={() => openBillingPhoto("data:image/png;charset=utf-8;base64, " + previousInfo.billData.billingPhotoCed)}>
                        <img src={cfiWhiteIcon} width={38} height={28}/>
                        VER IMÁGEN CFI
                    </button>
                  </div>
            </div>

            <div className='responseContainer'>
            <div style={{
                      width: '100%',
                      height: '50px',
                      background: '#E5E9F2',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                  }}>
                    <div style={{width: '40%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <p className='containerTitle2'>Buscar</p>
                    </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '20px' }}>
                <div style={{ width: '100%', borderRadius: '10px', background: '#FAFAFA'}}>
                  {/*<div style={{ background: '#FAFAFA', borderRadius: '10px' }}>*/}
                    <div style={{ margin: '20px 40px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <TextField id="outlined-basic"
                          label="Buscar por RFC/Razón Social"
                          variant="outlined"
                          style={{ width: '32%' }}
                          onChange={handleChangeRFC}
                          value={clientSearchRFC}
                        />

                        <button className='importBtn' onClick={searchRfc}>BUSCAR</button>
                    </div>
                  {/*</div>*/}
                </div>
              </div>

              <div style={{
                      width: '100%',
                      height: '50px',
                      background: '#E5E9F2',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                  }}>
                    <div style={{width: '40%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <p className='containerTitle2'>Datos del Cliente</p>
                    </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{
                  width: '100%',
                  borderRadius: '10px',
                  marginTop: 20,
                  marginLeft: 20
                }}>
                  <div style={{
                    background: '#FAFAFA',
                    borderRadius: '10px'
                  }}>
                    <div style={{ margin: '20px 40px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', rowGap: '20px' }}>
                      <TextField 
                        id="clientShortName" 
                        label="Nombre del cliente" 
                        variant="outlined" 
                        style={{ width: '33%' }}
                        onChange={handleNewClientValues}
                        value={newClientData.clientShortName} 
                        error={checkedClient ? newClientDataStatus.clientShortName : false}
                        />
                      {/*<TextField 
                        id="clientName" 
                        label="Razón social" 
                        variant="outlined" 
                        style={{ width: '33%' }} 
                        value={newClientData.clientName}
                        onChange={handleNewClientValues}
                        error={checkedClient ? newClientDataStatus.clientName : false} 
                        />*/}
                      <TextField 
                        id="phone" 
                        label="Número de Teléfono" 
                        variant="outlined" 
                        style={{ width: '33%' }} 
                        value={newClientData.phone}
                        onChange={handleNewClientValues} 
                        error={checkedClient ? newClientDataStatus.phone : false}
                        />
                      <TextField 
                        id="email" 
                        label="Correo Electrónico" 
                        variant="outlined" 
                        style={{ width: '33%' }} 
                        value={newClientData.email}
                        onChange={handleNewClientValues} 
                        error={checkedClient ? newClientDataStatus.email : false}
                        />
                      
                      <TextField
                        id='comments'
                        label="Observaciones"
                        variant='outlined'
                        multiline
                        rowsMax={4}
                        style={{width: '100%'}}
                        inputProps={{ maxLength: 150}}
                        value={newClientData.comments}
                        onChange={handleNewClientValues}
                        error={checkedClient ? newClientDataStatus.comments : false}
                         />
                    </div>

                    <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'end'}}>
                      <button className= 'importBtn' onClick={saveClientData}>
                        GUARDAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{
                      width: '100%',
                      height: '50px',
                      background: '#E5E9F2',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                  }}>
                    <div style={{width: '40%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <p className='containerTitle2'>Datos de Facturación:</p>
                    </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ width: '100%', borderRadius: '10px', marginTop: 20, marginLeft: 20 }}>
                  <div style={{ background: '#FAFAFA', borderRadius: '10px' }}>
                    {/*<div style={{ margin: '20px 40px', display: 'flex', justifyContent: 'space-between' }}>
                        <TextField id="outlined-basic"
                          label="Buscar por RFC/Razón Social"
                          variant="outlined"
                          style={{ width: '32%' }}
                          onChange={handleChangeRFC}
                          value={clientSearchRFC}
                          error={facturationExistingDataStatus.rfcSelected}
                        />
                    </div>*/}

                    <div style={{ margin: '20px 40px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField 
                        id="razonSocial" 
                        label={`Razón Social ${checked ? '*' : ''}`} 
                        variant="outlined" 
                        style={{ width: '49%' }} 
                        value={facturationData.razonSocial}
                        onChange={handleBillDataValues} 
                        error={checked ? facturationDataStatus.razonSocial : false}
                      /> {/* value={rfcInfo ? rfcInfo.clientName : ''} */}
                      <TextField 
                        id="rfc" 
                        label={`RFC ${checked ? '*' : ''}`} 
                        variant="outlined" 
                        style={{ width: '49%' }} 
                        value={facturationData.rfc}
                        onChange={handleBillDataValues} 
                        error={checked ? facturationDataStatus.rfc : false}
                      />
                    </div>
                    <div style={{ margin: '20px 40px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField 
                        id="domicilio" 
                        label={`Domicilio ${checked ? '*' : ''}`} 
                        variant="outlined" 
                        style={{ width: '100%' }} 
                        value={facturationData.domicilio}
                        onChange={handleBillDataValues}
                        error={checked ? facturationDataStatus.domicilio : false}
                      />
                    </div>

                    <div style={{ margin: '20px 40px', display: 'flex', justifyContent: 'space-between'}}>
                      <TextField
                          id="colonia"
                          label={`Colonia ${checked ? '*' : ''}`}
                          variant="outlined"
                          style={{ width: '24%' }}
                          value={facturationData.colonia}
                          onChange={handleBillDataValues}
                          error={checked ? facturationDataStatus.colonia : false}
                        />
                        <TextField
                          id="ciudad"
                          label={`Municipio ${checked ? '*' : ''}`}
                          variant="outlined"
                          style={{ width: '24%' }}
                          value={facturationData.ciudad}
                          onChange={handleBillDataValues}
                          error={checked ? facturationDataStatus.ciudad : false}
                        />
                        <TextField
                          id="estado"
                          label={`Estado ${checked ? '*' : ''}`}
                          variant="outlined"
                          style={{ width: '24%' }}
                          value={facturationData.estado}
                          onChange={handleBillDataValues}
                          error={checked ? facturationDataStatus.estado : false}
                        />
                        <TextField
                          id="cp"
                          label={`Codigo Postal ${checked ? '*' : ''}`}
                          variant="outlined"
                          style={{ width: '24%' }}
                          value={facturationData.cp}
                          onChange={handleBillDataValues}
                          type='number'
                          onWheel={event => event.currentTarget.blur()}
                          error={checked ? facturationDataStatus.cp : false}
                        />
                    </div>

                    <div style={{ margin: '20px 40px', display: 'flex', justifyContent: 'space-between' }}>
                      <Autocomplete
                        value={valueCFDI}
                        onChange={(event, newValue) => { setValueCFDI(newValue) }}
                        inputValue={inputValueCFDI}
                        onInputChange={(event, newInputValue) => { handleInputValueCFDI(newInputValue) }}
                        id="controllable-states-demo"
                        options={cfdiCatalog}
                        getOptionLabel={(option) => option ? `${option.cucName}` : `Uso de CFDI ${checked ? '*' : ''}`}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label={`CFDI ${checked ? '*' : ''}`} variant="outlined" error={checked ? facturationExistingDataStatus.cfdi : false} />}
                      />
                      <Autocomplete
                        value={valueRegimen}
                        onChange={(event, newValue) => { setValueRegimen(newValue) }}
                        inputValue={inputValueRegimen}
                        onInputChange={(event, newInputValue) => { handleInputValueRegimen(newInputValue) }}
                        id="controllable-states-demo"
                        options={regimenCatalog}
                        getOptionLabel={(option) => option ? `${option.crfClave}` : `Regimen fiscal ${checked ? '*' : ''}`}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label={`Regimen fiscal ${checked ? '*' : ''}`} variant="outlined" error={checked ? facturationExistingDataStatus.regimen : false} />}
                      />
                    </div>
                    <div style={{ margin: '20px 40px', display: 'flex', justifyContent: 'space-between' }}>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Condicion de pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="condicionPago"
                          label='condicionPago'
                          name='condicionPago'
                          id="condicionPago"
                          labelWidth={`Condicion de pago ${checked ? '*' : ''}`}
                          value={facturationExistingData.condicionPago}
                          onChange={handleFacturacioExistingValues}
                          defaultValue='none'
                          error={checked ? facturationExistingDataStatus.condicionPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Condicion de pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'30 Dias'}>30 Dias</MenuItem>
                          <MenuItem value={'60 Dias'}>60 Dias</MenuItem>
                          <MenuItem value={'90 Dias'}>90 Dias</MenuItem>
                          <MenuItem value={'120 Dias'}>120 Dias</MenuItem>
                          <MenuItem value={'Contado'}>Contado</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Método de pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="metodoPago"
                          label='metodoPago'
                          name='metodoPago'
                          id="metodoPago"
                          labelWidth={`Método de pago ${checked ? '*' : ''}`}
                          value={facturationExistingData.metodoPago}
                          onChange={handleFacturacioExistingValues}
                          defaultValue='none'
                          error={checked ? facturationExistingDataStatus.metodoPago : false}
                          disabled
                        >
                          {/*<MenuItem value="none">
                            {`Metodo de pago ${checked ? '*' : ''}`}
                          </MenuItem>*/}
                          <MenuItem value={'Pago una solo exhibición (PUE)'}>Pago una sola exhibición (PUE)</MenuItem>
                          <MenuItem value={'Parcialidades (PPD)'}>Parcialidades (PPD)</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Forma de Pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="formaPago"
                          label='formaPago'
                          name='formaPago'
                          id="formaPago"
                          labelWidth={`Forma de Pago ${checked ? '*' : ''}`}
                          value={facturationExistingData.formaPago}
                          onChange={handleFacturacioExistingValues}
                          defaultValue='none'
                          error={checked ? facturationExistingDataStatus.formaPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Forma de Pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'01-Efectivo'}>01-Efectivo</MenuItem>
                          <MenuItem value={'02-Cheque nominativo'}>02-Cheque nominativo</MenuItem>
                          <MenuItem value={'03-Transferencia electrónica de fondos'}>03-Transferencia electrónica de fondos</MenuItem>
                          <MenuItem value={'04-Tarjeta de crédito'}>04-Tarjeta de crédito</MenuItem>
                          <MenuItem value={'28-Tarjeta de débito'}>28-Tarjeta de débito</MenuItem>
                          <MenuItem value={'99-Por definir'}>99-Por definir</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ margin: '20px 40px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField 
                        id="numeroPago"
                        name='numeroPago' 
                        label="Número de recibo de pago" 
                        variant="outlined" 
                        style={{ width: '32%' }} 
                        value={facturationExistingData.numeroPago} 
                        onChange={handleFacturacioExistingValues}
                        error={checked ? facturationExistingDataStatus.numeroPago : false}
                         />
                    </div>
                    <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'end'}}>
                      <button className= { checked ? 'importBtn' : 'disableButton'} onClick={saveBillData2}>
                        GUARDAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{
                      width: '100%',
                      height: '50px',
                      background: '#E5E9F2',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                  }}>
                    <div style={{width: '40%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <p className='containerTitle2'>Datos de Envío:</p>
                    </div>
              </div>

              <div style={{ background: '#FAFAFA', borderRadius: '10px' }}>
                <div style={{ margin: '20px 40px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <TextField 
                    id="address" 
                    label="Direccion de Envio" 
                    variant="outlined" 
                    style={{ width: '100%' }} 
                    value={shipmentData.address} 
                    onChange={handleShipmentValues}
                    error={checkedShip ? shipmentDataStatus.address : false} />
                  <TextField 
                    id="reference" 
                    label="Instrucciones o referencias" 
                    variant="outlined" 
                    style={{ width: '100%' }} 
                    value={shipmentData.reference} 
                    onChange={handleShipmentValues}
                    error={checkedShip ? shipmentDataStatus.reference : false} />
                </div>
                <div style={{ margin: '20px 0', display: 'flex', justifyContent: 'end'}}>
                  <button className= { checked ? 'importBtn' : 'disableButton'} onClick={saveShipData}>
                    GUARDAR
                  </button>
                </div>
              </div>
            </div>

            

          {/*<div className="bottomBar-active1" style={{zIndex: '1'}}>
              <div className="div-one">
                <div className='div-two'>
                <button className= { checked ? 'importBtn' : 'disableButton'} onClick={saveBillData}>
                    GUARDAR
                </button>
                </div>
              </div>
            </div> */}

            {/* DIALOGO IMAGEN CFI */}
            <Dialog open={openCfiDialog} onClose={handleCloseCfiDialog} aria-labelledby='cfi-dialog-title' maxWidth='md'>
              <DialogTitle className='dialogTitleBg' id='cfi-dialog-title'>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  CFI
                  <IconButton aria-label='Cerrar' onClick={handleCloseCfiDialog}>
                    <CloseIcon style={{color: 'white'}} />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                {billType === 1 ?
                <img src={`data:image/png;charset=utf-8;base64, ${previousInfo.billData.billingPhotoCed}`} width={'100%'}/>
                : <p>No hay CFI que mostrar</p>
                }
              </DialogContent>
            </Dialog>

            {/* DIALOGO TABLA RFC */}
            <Dialog open={openRfcDialog} onClose={handleCloseRfcDialog} aria-labelledby='rfc-dialog-title' fullWidth={true} maxWidth='lg'>
              <DialogTitle className='dialogTitleBg' id='rfc-dialog-title'>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  Resultados
                  <IconButton aria-label='Cerrar' onClick={handleCloseRfcDialog}>
                    <CloseIcon style={{color: 'white'}} />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>Resultados de la búsqueda {clientSearchRFC}</DialogContentText>
                <p style={{ fontFamily: 'Poppins, sans-serif', marginBottom: '10px'}}>Seleccione la razón social</p>

                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={{ height: 700 }} // the grid will fill the size of the parent container
                >
                  <AgGridReact
                    ref={gridRef}
                    rowData={rfcSearchResults}
                    columnDefs={tableColumns}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    rowHeight={rowHeight}
                    autoSizeStrategy={autoSizeStrategy}
                    rowSelection={"single"}
                    onSelectionChanged={onSelectionChanged}
                  />
                </div>
              </DialogContent>
            </Dialog>

            {/*SUCCESS SUBMIT DIALOG */}
            <Dialog open={openSuccessDialog} onClose={handleCloseSuccessDialog} aria-labelledby='success-title' maxWidth='sm' fullWidth={true}>
              <DialogContent style={{padding: '20px', height: '250px'}}>
                <div className='successMainContainer'>
                  <div className='successSecContainer'>
                    <img src={checkIcon} className='successIcon' />
                    <div className='sombra'></div>
                    <p>Datos guardados correctamente</p>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <button className='importBtn' onClick={handleCloseSuccessDialog}>Cerrar</button>
              </DialogActions>
            </Dialog>
            {/* ALERTAS */}
            <Snackbar open={alert} autoHideDuration={3000} onClose={closeAlert} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
                <Alert severity='success' sx={{ width: '100%' }}>
                    Datos de facturación guardados
                </Alert>
            </Snackbar>

            <Snackbar open={errorAlert} autoHideDuration={5000} onClose={closeErrorAlert} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
              <Alert severity='error' sx={{ width: '100%'}}>
                Llene todos los <b>{errorAlertText}</b>
              </Alert>
            </Snackbar>
        </div>
    )
}
