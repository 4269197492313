import React, { useEffect, useState } from 'react';
import CausaSurveyComponent from '../../../components/EncuestaComponent/CausaComponent/CausaSurveyComponent';
import ComentsSurvey from '../../../components/EncuestaComponent/ComentsSurvey';
import { SNCComponent } from '../../../components/EncuestaComponent/SncComponent/SNCComponent';
import { PrimeraClasificacion } from '../../../components/EncuestaComponent/PrimeraClasificacion/PrimeraClasificacion';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { QualityInfoEncuestasHeaderComponent } from '../../../components/QualityManager/QualityInfoEncuestasHeaderComponent';
import { Survey_Medico_Component } from '../../../components/StoreHouseComponents/StoreHouseReiceivingEncuestasComponents/Survey_Medico_Component';
import './styleEncuestasInfo.scss';
import { SeguimientoMedicoComponent } from '../../../components/EncuestaComponent/SeguimientoMedicoComponent/SeguimientoMedicoComponent';
import { FechaCierreComponent } from '../../../components/EncuestaComponent/FechaCierreComponent/FechaCierreComponent';
import { EstatusComponent } from '../../../components/EncuestaComponent/EstatusComponent/EstatusComponent';
import { CalificacionComponent } from '../../../components/EncuestaComponent/Calificacion/CalificacionComponent';
import { AccionesComponent } from '../../../components/EncuestaComponent/AccionesComponent/AccionesComponent';
import { useHistory, useParams } from 'react-router-dom';
import { AddComentarioComponent } from '../../../components/EncuestaComponent/AddComentarioComponet/AddComentarioComponent';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import { convertMonth, formatDataBase, formatDate, getDay, getMonth, getYear } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { setCommentaryDirectory, setFirstClasification, setSurveySelected, surveyClasification, surveyDirectorManager, surveyIssueCause, surveyPending, surveyQualityManager, surveySetInformation, surveySetNewCommentaryQuality, surveySNC, surveyStatus } from '../../../actions/surveyAction';
import { ComentaryAdded } from '../../../components/EncuestaComponent/ComentarioGerenteCalidad/ComentaryAdded';


export const QualityManager_InfoEncuesta_Page = () => {
    const user = useSelector(state => state.user.user.identity);
    useEffect(() => {
      arrayDatabase();
    }, [])
    const {type} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [newCommentSurvey, setNewCommentSurvey] = useState({
        Commentary: "",
    })
    const responseSurvey = useSelector(state => state.survey.setSurveyAppoimentSelected);
    const [typeCalificacion, setTypeCalificacion] = useState(responseSurvey && responseSurvey.clasificationSurvey ?responseSurvey.clasificationSurvey.status  ?responseSurvey.clasificationSurvey.status  :null  :null);
    const [date, setDate] = useState(responseSurvey && responseSurvey.sugestedCloseDate && formatDate(responseSurvey.sugestedCloseDate));
    const [response, setResponse] = useState(
        {
            impuntualidad: responseSurvey && responseSurvey.sncSurvey ? responseSurvey.sncSurvey.unpunctuality ?responseSurvey.sncSurvey.unpunctuality :false :false,
            asesoria: responseSurvey && responseSurvey.sncSurvey ? responseSurvey.sncSurvey.inadequateTechnicalAdvice ?responseSurvey.sncSurvey.inadequateTechnicalAdvice :false  :false,
            entrega: responseSurvey && responseSurvey.sncSurvey ? responseSurvey.sncSurvey.untimelyDeliveryOfEquipment ? responseSurvey.sncSurvey.untimelyDeliveryOfEquipment  :false :false,
            material:responseSurvey && responseSurvey.sncSurvey ? responseSurvey.sncSurvey.incompleteMaterial ?responseSurvey.sncSurvey.incompleteMaterial :false :false,
            capacitacion: responseSurvey && responseSurvey.sncSurvey ? responseSurvey.sncSurvey.inadecuateRFCCaptation ?responseSurvey.sncSurvey.inadecuateRFCCaptation :false  :false,
        },
    )
    const [comCa,setComCa] = useState(responseSurvey && responseSurvey.qualityManagerSurvey ?responseSurvey.qualityManagerSurvey.medicFollowUpCommentary ?responseSurvey.qualityManagerSurvey.medicFollowUpCommentary  :responseSurvey.qualityManagerSurvey.directionCommentary ?responseSurvey.qualityManagerSurvey.directionCommentary :null :null);
    const [typePrimeraClasificacion, setTypePrimeraClasificacion] = useState(responseSurvey && responseSurvey.firstClasificationSurvey ?responseSurvey.firstClasificationSurvey.status  ?responseSurvey.firstClasificationSurvey.status  :null  :null);
    const [causas,setCausas] = useState(
        {
            desgaste:responseSurvey && responseSurvey.surveyIssueCause ? responseSurvey.surveyIssueCause.wearByUse ?responseSurvey.surveyIssueCause.wearByUse :false :false,
            material:responseSurvey && responseSurvey.surveyIssueCause ? responseSurvey.surveyIssueCause.incompleteMaterial ?responseSurvey.surveyIssueCause.incompleteMaterial :false :false,
            stock:responseSurvey && responseSurvey.surveyIssueCause ? responseSurvey.surveyIssueCause.lowStockLevel ?responseSurvey.surveyIssueCause.lowStockLevel :false :false,
            tecnica:responseSurvey && responseSurvey.surveyIssueCause ? responseSurvey.surveyIssueCause.badMedicTechnique ?responseSurvey.surveyIssueCause.badMedicTechnique :false :false,
            requisito:responseSurvey && responseSurvey.surveyIssueCause ? responseSurvey.surveyIssueCause.medicSpecialRequirement ?responseSurvey.surveyIssueCause.medicSpecialRequirement :false :false,
            otra:responseSurvey && responseSurvey.surveyIssueCause ? responseSurvey.surveyIssueCause.other ?responseSurvey.surveyIssueCause.other :false :false,
            comOtra:responseSurvey && responseSurvey.surveyIssueCause ? responseSurvey.surveyIssueCause.otherCommentary ?responseSurvey.surveyIssueCause.otherCommentary :'' :'',
        }
    )
    const [typeEstatus, setTypeEstatus] = useState(responseSurvey && responseSurvey.surveyStatus ?responseSurvey.surveyStatus.status  ?responseSurvey.surveyStatus.status  :null  :null);
    const [acciones,setAcciones] = useState(
        []
    )
    const [accData,setAccData] = useState(null);

    const handleCancel = () => {
        history.push('/Quality_Encuestas_Page')
    }
    const validationResp = () => {
        let keys = Object.keys(response)
        let ban = false
       for( let i=0; i<keys.length; i++){
           let position = keys[i];
           if(response[position] === true){
               ban = true
               return ban;
           }
       }
       return ban;
    }


    const validationCausa = () => {
       let keys = Object.keys(causas)
       let ban = false
       for( let i=0; i<keys.length; i++){
           let position = keys[i];
           if(causas[position] === true){
               ban = true
               return ban;
           }
       }
       return ban;
    }
    

    //REVISAR ESTA FUNCION ------------------------------------------------------------------------
    const arrayDatabase = () =>{
        let array = []
       // debugger
        responseSurvey  !== null &&
        responseSurvey &&
        Object.keys(responseSurvey).length > 0  &&
        responseSurvey.pendingSurveyActions && 
        responseSurvey.pendingSurveyActions.length > 0 &&
        responseSurvey.pendingSurveyActions.map((item) => {
            if(array.length > 0){

                array.push({
                    id:shortid.generate(),
                    usuario:{
                        name:`${item.responsible.firstName && item.responsible.firstName} ${item.responsible.secondName && item.responsible.secondName} ${item.responsible.firstLastName && item.responsible.firstLastName} ${item.responsible.secondLastName && item.responsible.secondLastName}`,
                        userID:item.responsible.userUD,
                    },
                    fechaVencimiento: `${getDay(item.expectedClosing)} de ${ convertMonth(getMonth(item.expectedClosing))} del ${getYear(item.expectedClosing)}`,
                    accion:item.description,
                    check:item.isCompleted
                })
            }else{
                //debugger
                array = [{
                    id:shortid.generate(),
                    usuario:{
                        name:`${item.responsible.firstName && item.responsible.firstName} ${item.responsible.secondName && item.responsible.secondName} ${item.responsible.firstLastName && item.responsible.firstLastName} ${item.responsible.secondLastName && item.responsible.secondLastName}`,
                        userID:item.user.userUD,
                    },
                    fechaVencimiento: `${getDay(item.expectedClosing)} de ${ convertMonth(getMonth(item.expectedClosing))} del ${getYear(item.expectedClosing)}`,
                    accion:item.description,
                    check:item.isCompleted
                }]
            }

        })  
        setAccData(array)
    }
    //REVISAR ESTA FUNCION ------------------------------------------------------------------------


    const handleSave = () => {
        if(user.roleID !== 9){
         if(typePrimeraClasificacion){
            dispatch(setFirstClasification(parseInt(typePrimeraClasificacion),parseInt(responseSurvey.surgeryAppointment.id)))
        } 
         if(typeCalificacion){
            dispatch(surveyClasification(parseInt(typeCalificacion),parseInt(responseSurvey.surgeryAppointment.id)))
        } 
         if(validationCausa()){
            dispatch(surveyIssueCause(causas,parseInt(responseSurvey.surgeryAppointment.id)))
        } 
         if(validationResp()){
            dispatch(surveySNC(response,parseInt(responseSurvey.surgeryAppointment.id)))
        } 
         if(date ){
            dispatch(surveySetInformation('',formatDataBase(date),parseInt(responseSurvey.surgeryAppointment.id)))
        }

        if(comCa ){
            dispatch(surveyQualityManager(comCa,parseInt(responseSurvey.surgeryAppointment.id)))
        } 

         if(typeEstatus && typeEstatus != 0){
            dispatch(surveyStatus(parseInt(typeEstatus),parseInt(responseSurvey.surgeryAppointment.id)))
        } 
        if(acciones && acciones.length > 0){
            acciones.map((item => {
                 dispatch(surveyPending({
                    SurveyId:responseSurvey.id,
                    Description:item.accion,
                    ExpectedClosing:formatDataBase(item.date),
                    Responsible:
                    {
                       UserID:item.usuario.userID
                    }
                })) 
            }))
            
        }
        history.push(`/Quality_Messages_Page/${responseSurvey.surgeryAppointment.id}`)
    }else{
        if(comCa ){

            handleDirector();
        } 
    }
    }

    
    const handleDirector = async () => {
        const result = await dispatch(surveyDirectorManager(comCa,parseInt(responseSurvey.surgeryAppointment.id)))
        if (result === true) {
            dispatch(setSurveySelected(null));
            history.push(`/Quality_Encuestas_Page`);
        }
    }
    const handleAceptar = () =>{
        if(user.roleID !== 9){
            if(typeEstatus && typeEstatus != 0){
                
                 dispatch(surveyStatus(parseInt(typeEstatus),parseInt(responseSurvey.surgeryAppointment.id)))
            } 
            if(newCommentSurvey.Commentary.length  > 0 && responseSurvey.qualityManagerSurvey){
                dispatch(surveySetNewCommentaryQuality(newCommentSurvey,parseInt(responseSurvey.qualityManagerSurvey.id)))
            }
         } else{
            if(newCommentSurvey.Commentary.length  > 0 && responseSurvey){
                dispatch(setCommentaryDirectory(newCommentSurvey,parseInt(responseSurvey.id)))
            } 
         }
        history.push('/Quality_Encuestas_Page') 
    }

    return (
        <div>
            <SubNavBarComponent className="navbar" title={'ENCUESTAS'} searchActive={false} historyPage={'/Quality_Encuestas_Page'} />
            <div className='container-header-encuesta'>
                <QualityInfoEncuestasHeaderComponent />
                <div className='body-page-infoEncuestas'>
                    {/* Agregar a izquierda dentro de este div */}
                    <div className='container-component container-left'>
                        <Survey_Medico_Component
                            titulo={'COMENTARIOS MÉDICO'}
                            tipo={'medico-gerente'}
                            resp={responseSurvey && responseSurvey.medicSurvey && responseSurvey.medicSurvey} />
                        <ComentsSurvey
                            title={'COMENTARIOS JEFE ATQ'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-mantenimiento'}
                            comments={responseSurvey &&  responseSurvey.adminATQSurvey && responseSurvey.adminATQSurvey.commentary ?responseSurvey.adminATQSurvey.commentary :'' }
                        />
                        <ComentsSurvey
                            title={'COMENTARIOS GERENTE DE OPERACIONES'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-mantenimiento'}
                            comments={responseSurvey &&  responseSurvey.cooSurvey && responseSurvey.cooSurvey.commentary ?responseSurvey.cooSurvey.commentary :'' }
                        />
                    </div>
                    {/* agregar a derecha dentro de este div */}
                    <div className='container-component container-right'>
                        <ComentsSurvey
                            title={'COMENTARIOS ATQ'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-atq'}
                        />
                        <ComentsSurvey
                            title={'COMENTARIOS ALMACEN'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-almacen'}
                        />
                        <ComentsSurvey
                            title={'COMENTARIOS VENTAS (COMERCIAL)'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-ventas'}
                        />
                        <ComentsSurvey
                            title={'COMENTARIOS MANTENIMIENTO'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-mantenimiento'}
                            comments={responseSurvey &&  responseSurvey.maintenanceSurvey && responseSurvey.maintenanceSurvey.commentary ?responseSurvey.maintenanceSurvey.commentary :'' }
                        />
                    </div>
                </div>
                {/* Aqui agregar en todo el nuevo div con las dos secciones - izquierda y derecha*/}
                <div className='body-page-infoEncuestas'>
                    {/* Agregar a izquierda dentro de este div */}
                    <div className='container-component '>
                        <div className='clasificacion-body'>
                        <PrimeraClasificacion
                        typePrimeraClasificacion = {typePrimeraClasificacion}
                        setTypePrimeraClasificacion = {setTypePrimeraClasificacion}
                        tipo={type} />
                        <CalificacionComponent 
                            typeCalificacion ={typeCalificacion}
                            setTypeCalificacion = {setTypeCalificacion}
                            tipo={type}
                        />
                        </div>  
                            <SNCComponent
                                response = {response}
                                setResponse = {setResponse}
                                type={type}
                            />
                            <SeguimientoMedicoComponent 
                                comCa={comCa}
                                setComCa={setComCa}
                                type={type}
                            />
                            {
                            responseSurvey &&  responseSurvey.qualityManagerSurvey && responseSurvey.qualityManagerSurvey.commentaries.length > 0 &&
                            responseSurvey.qualityManagerSurvey.commentaries.map(item => (
                                    <ComentaryAdded
                                        title={'GERENETE DE CALIDAD - COMENTARIO AGREGADO'}
                                        comentario={item.description && item.description}
                                    />
                            ))
                        } 
                    </div>
                    {/* agregar a derecha dentro de este div */}
                    <div className='container-component container-right'>
                        <CausaSurveyComponent
                        causas={causas} 
                        setCausas={setCausas}
                        type={type}
                        />
                        <AccionesComponent 
                            acciones = {acciones}
                            setAcciones = {setAcciones}
                            accData={accData}
                            setAccData={setAccData}
                            type={type}
                        />
                        <FechaCierreComponent 
                            date={date}
                            setDate={setDate}
                            type={type}
                        />
                        <EstatusComponent 
                            typeEstatus={typeEstatus}
                            setTypeEstatus={setTypeEstatus}
                            type={type}
                        />
                         {
                            responseSurvey &&  responseSurvey.surveyCommentaries && responseSurvey.surveyCommentaries.length > 0 &&
                            responseSurvey.surveyCommentaries.map(item => (
                                    <ComentaryAdded
                                        comentario={item.description && item.description}
                                        title={'DIRECTOR - COMENTARIO AGREGADO'}
                                    />
                            ))
                        } 
                        {
                            type === 'cerradas'&&
                            <AddComentarioComponent                                
                                setNewCommentSurvey={setNewCommentSurvey}
                                newCommentSurvey={newCommentSurvey}
                            />
                        }
                        {
                            type === 'cerradas'  
                        ?
                        <div className='cont-btn-footer'>
                        <button className='btnView-cancelar' onClick={() => handleCancel()}>CANCELAR</button>
                        <button className='btnView-guardar' onClick={() => handleAceptar()}>ACEPTAR</button>
                    </div>
                    :<div className='cont-btn-footer'>
                        <button className='btnView-cancelar' onClick={() => handleCancel()}>CANCELAR</button>
                        <button className='btnView-guardar' onClick={() => handleSave()}>GUARDAR</button>
                    </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
