import React, { useEffect, useState } from 'react'
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { StoreHouseListComponent } from '../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { TableUbication_Component } from '../../components/ATQAdministrator/ATQAdministrator_Survey/TableUbication_Component';
import shortid from 'shortid';
import './styleSurveyMantenimientoDetalles.scss';
import { useHistory, useParams } from 'react-router-dom';
import { ATQ_Individual_Compo } from '../../components/ATQ/ATQ_ENCUESTA/ATQ_Individual_Compo';
import { Survey_Component } from '../../components/ATQAdministrator/ATQAdministrator_Survey/Survey_Component';
import icoAdd from '../../assets/img/126PX/IcoAgregarCi.png';
import iconDel from '../../assets/img/126PX/iconDeleteComp.png';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../actions/storeHouseAction';
import { useDispatch, useSelector } from 'react-redux';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../hooks/useFunctionsStoreHouse';
import { surveyMaintenance, surveySetCommetMaintenance } from '../../actions/surveyAction';


export const SurveyMantenimientoDetallePage = () => {

    const [componentText, setComponentText] = useState(false);
    const [newCommentAdd, setNewCommentAdd] = useState(null)
    const surveyID = useSelector(state => state.survey.setSurveyAppoimentSelected.surgeryAppointment)
    const surveySelectected = useSelector(state => state.survey.setSurveyAppoimentSelected)
    const dispatch = useDispatch();
    const { type,id } = useParams();
    const [newCommentSurvey, setNewCommentSurvey] = useState({
        Commentary: "",
    })
    const history = useHistory();
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const [newProductsReq, setNewProductsReq] = useState(null);


    useEffect(() => {
        if(surveyID && surveyID.id) {
            dispatch(getProductsToAppointmentStoreHose(surveyID.id))
            dispatch(getAppointmentLocationsStoreHose(surveyID.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])

    useEffect(() => {
        if(AppointmentLocations){
            handleCompleteBeginLocations(AppointmentLocations,setNewProductsReq,surveyID)
        }
    }, [,AppointmentLocations])

    useEffect(() => {
        
            if( (!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)){   
                {  
                    if(!AppointmentWithLocations || AppointmentWithLocations === null){
                        dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts,newProductsReq,surveyID)));
                    }
                }
            }
    
    }, [newProductsReq])

     const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: (surveyID  && surveyID.medic) &&
                (surveyID.medic && surveyID.medic.name && surveyID.medic.name) + ' ' +
                (surveyID.medic && surveyID.medic.secondName && surveyID.medic.secondName) + ' ' +
                (surveyID.medic && surveyID.medic.firstLastname && surveyID.medic.firstLastname) + ' ' +
                (surveyID.medic && surveyID.medic.secondLastname && surveyID.medic.secondLastname),
        },
        {
            uid: shortid.generate(),
            title: 'Institucion',
            value: (surveyID && surveyID.hospital && surveyID.hospital.name),
        },
        {
            uid: shortid.generate(),
            title: 'Fecha y Hora',
            type: 'DATE-COMPLETE',
            value: (surveyID && surveyID.surgeryDate),
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: ( surveyID && surveyID.atq ?
                `${surveyID.atq.firstName && surveyID.atq.firstName } ${surveyID.atq.secondName && surveyID.atq.secondName}
                 ${surveyID.atq.firstLastName && surveyID.atq.firstLastName} `
                : 'ATQ NO ASIGNADO'),
        },
    ];


    const handleSendData = async () => {
        if(newCommentSurvey.Commentary.length > 0){
            const resultado = await dispatch(surveyMaintenance(newCommentSurvey, parseInt(id)))
            if (resultado === true) {
                history.push(`/Quality_Messages_Page/${id}`);
            }
       }
    }

    const handleAceptar = async () => {
        if (newCommentSurvey.Commentary.length  > 0){
             const resultado = await dispatch(surveySetCommetMaintenance(newCommentSurvey,parseInt(surveySelectected.maintenanceSurvey.id)))
             if (resultado === true) {
                 history.push('/Survey_Mantenimiento');
             
             }
         }
     }
    return (
        <div className='container-mantenimiento-detalles' >

            <SubNavBarComponent title={"ENCUESTA"} historyPage={"/Survey_Mantenimiento"} />
            <div className="container-mantenimiento-encuestas-colums-detalles">
                <section className='container-mantenimiento-sec-left' >
                    <StoreHouseListComponent
                        typeView={{ title: `CX #${id}`, type: 'STORE', }}
                        listFields={arrayListFields}
                    />
                    <TableUbication_Component 
                    listItemsBody={ AppointmentWithLocations && AppointmentWithLocations.productRequirements && AppointmentWithLocations.productRequirements.length > 0  ?AppointmentWithLocations.productRequirements :null }
                    />
                    <button className='btn-consumo-mantenimiento'>CONSUMO</button>
                </section>

                {type === "ACTIVE" &&
                    <section className='container-mantenimiento-sec-rigth'>
                        <div className='container-medico-mantenimiento'>
                            <p className='text-medico-header-mantenimiento' >Seguimiento para uso interno de TSI</p>
                            <ATQ_Individual_Compo
                                titulo={'Mantenimiento'}
                                tipo={'Nuevo'}
                                setNewCommentSurvey={setNewCommentSurvey}
                                newCommentSurvey={newCommentSurvey}
                            />
                            <button className='btn-detalles-encuesta-mantenimiento' onClick={() => handleSendData()} >GUARDAR</button>
                        </div>
                    </section>
                }

                {type === "INACTIVE" &&
                    <>
                        <section className='container-mantenimiento-sec-rigth'>
                            <div className='container-medico-mantenimiento'>
                                <p className='text-medico-header-mantenimiento' >Seguimiento para uso interno de TSI</p>
                                <Survey_Component
                                    titulo={"Mantenimiento"}
                                    tipo={'Mantenimiento'} 
                                    comentario={surveySelectected.maintenanceSurvey.commentary ?surveySelectected.maintenanceSurvey.commentary :''}
                                    />

                                {componentText == true &&
                                    <ATQ_Individual_Compo
                                        titulo={'Agregar nuevo comentario'}
                                        tipo={'Nuevo'}
                                        comentario={'Agregar nuevo comentario'}
                                        setNewCommentSurvey={setNewCommentSurvey}
                                        newComment={newCommentAdd}
                                    />
                                }
                                {
                            surveySelectected.maintenanceSurvey && surveySelectected.maintenanceSurvey.commentaries.length > 0 &&
                            surveySelectected.maintenanceSurvey.commentaries.map(item => (
                                <div key={shortid.generate} className='individual-container_New'>
                                    <ATQ_Individual_Compo
                                        titulo={'Comentarios Agregados'}
                                        tipo={'Agregado'}
                                        comentario={item.description && item.description}

                                    />
                                </div>
                            ))
                            }

                                {componentText === true

                                    ? <div className='container-atq-buttons-add'><img className='icoAdd' src={iconDel} onClick={() => setComponentText(!componentText)} />
                                    </div>
                                    : <div className='container-atq-buttons-add'><img className='icoAdd' src={icoAdd} onClick={() => setComponentText(!componentText)} />
                                    </div>
                                }
                            </div>  
                            <button className='btn-detalles-encuesta-mantenimiento' onClick={() => handleAceptar()}>ACEPTAR</button>
                        </section>
                    </>
                }
            </div>
        </div>
    )
}
