import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style-list.scss';

export const Atq_continue_list = () => {
    const apoiment = useSelector(state => state.storeHouse.appoitmentSelected);
    const nextSurgery = useSelector(state => state.storeHouse.nextAppointment);
    const [list, setList] = useState(null);


        useEffect(() => {
            if(nextSurgery && !list){
                setList(nextSurgery[0]);
            }
            else if(apoiment && !list){
                setList(apoiment);
            }
        }, [nextSurgery,apoiment]);
        

    const states = [
        {
            id: 1,
            name: 'Cirugía Programada'
        },
        {
            id: 2,
            name: 'Programación atendida'
        },
        {
            id: 3,
            name: 'Material listo para recogerse'
        },
        {
            id: 4,
            name: 'Material en proceso de entrega'
        },
        {
            id: 5,
            name: 'Material entregado'
        },
        {
            id: 6,
            name: 'Cirugia iniciada'
        },
        {
            id: 7,
            name: 'Cirugia finalizada'
        },
        {
            id: 8,
            name: 'Material retornado a almacén'
        },
        {
            id: 9,
            name: 'Material en proceso de revisión'
        },
        {
            id: 10,
            name: 'Cirugía facturada'
        },
        {
            id: 11,
            name: 'CANCELADA'
        }
    ]
    return (
        <div className="precontainer-list">
            <ul className="container-ul">
                <p>Estatus</p>
                {
                    
                    states.map( (item,index) => (
                    <ol className="container-ol">
                    {  
                        index < 10 &&
                            (<li className={`${ list && list.surgeryStatusId === 11 ?'container-li'  :(item.id === 10 ?'container-last' :'container-li')} ${list && list.surgeryStatusId === 11 ?''  :(list &&(item.id <= list.surgeryStatusId && 'active-li'))}`}>
                                <div className={`container-div process ${ list &&(list.surgeryStatusId === 11 ?'' :(item.id <= list.surgeryStatusId && 'active-div'))}`}>
                                    <span>
                                        {item.id}
                                    </span>
                                </div>
                                    {item.name}
                            </li>)
                    }
                    {
                        list &&
                        list.surgeryStatusId === 11 && index === 10 &&
                            (<li className={'container-last active-li'}>
                                <div className={'container-div process active-div'}>
                                    <span>
                                        {item.id}
                                    </span>
                                </div>
                                    {item.name}
                            </li>)
                            }

                        </ol>
                    
                      ))
                }    
            </ul>
        </div>
    )
}
