import React, { useEffect } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { ATQAdmin_EstatusCX_Componet } from '../../../components/ATQAdministrator/ATQ_Status_CX/ATQAdmin_EstatusCX_Componet';
import './styles.scss';
import { useDispatch } from 'react-redux';
import {  getAppointmentsStoreHouseSuccessAtq } from '../../../actions/storeHouseAction';
import { useParams } from 'react-router-dom';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';

export const ATQAdmin_EstatusCX_Page = () => {
    const distpatch = useDispatch();

    useEffect(() => {
        return () => {
            distpatch(getAppointmentsStoreHouseSuccessAtq(null))
        }
    }, [])

    return (
        <>
        <ComingSoonComponent />
        {/*{ 
            <SubNavBarComponent title={`ESTATUS CX`} searchActive={true} historyPage={`/Inicio`}  typeSearch= {'Status'}
            specificType = {'id'} />
        }
        <div className="container_cx">
            <ATQAdmin_EstatusCX_Componet />
        </div>*/}
    </>

    )
}
