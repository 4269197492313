import React from 'react'
import { storeHouseApproveStatusRigthData } from './storeHouseApproveStatusRigthData';
import './sass/styles.scss';


export const StoreHouseApproveStatusRigthListComponent = () => {

    return(
        <ul className={'StoreHouseApproveStatusRigthListComponent-list-ul'}>
            <div className={'StoreHouseApproveStatusRigthListComponent-list-li-body'}>
            <p className={"StoreHouseApproveStatusRigthListComponent-list-li-title"}>ESTATUS</p>
                <div className="StoreHouseApproveStatusRigthListComponent-container-list-ul">

                    <ol className={'StoreHouseApproveStatusRigthListComponent-list-ol'}>
                        {
                            storeHouseApproveStatusRigthData &&
                            storeHouseApproveStatusRigthData.map((statesItem, index) => {
                            return(
                                <li key = {statesItem.id} className={['textNonActualState'].join(' ')}><div className={"processNumber"}><span>{ index + 1 }</span></div>{ statesItem.name }</li>
                            );
                            })
                        }
                    </ol>

                </div>
            </div>
        </ul>
    );
}
