import moment from 'moment';
import React from 'react';

export const useFormatDateInitialAndFinalDay = (selectedDate,newFinalDayMonth) => {
  const startDay = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${'01'}T00:00:${('00')}`; /* + new Date().getUTCSeconds()).slice(-2) */
  const finalDay = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${newFinalDayMonth}T00:00:${('00')}`; /* + new Date().getUTCSeconds()).slice(-2) */

return {startDay,finalDay};
}
export const formatFinalDate = (selectedDate, selectTime) => {
  const date = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('DD')}T${selectTime}:${('00')}`;
  return {date};
}
export const formatDate = (selectedDate) => {
      const date = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('DD')}`;
  return date;
}
export const formatDateToInsert = (selectedDate) => {
  const date = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('DD')}`;
return date;
}
export const formatDateToPresent = (selectedDate) => {
  const date = `${moment(selectedDate).format('DD')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('YYYY')}`;
return date;
}
export const formatDateList = (selectedDate) => {
  const date = `${moment(selectedDate).format('DD')}/${moment(selectedDate).format('MM')}/${moment(selectedDate).format('YYYY')}`;
return date;
}
export const formatHourToPresent = (selectedDate) => {
  const date = `${moment(selectedDate).format('HH:mm')}`;
return date;
}

export const formatOnlyFinalDate = (selectedDate) => {
  const date = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('DD')}T${moment(selectedDate).format('HH:mm')}:${('59')}`;
return date
}

export const formatOnlyInicialDate = (selectedDate) => {
  const date = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('DD')}T${moment(selectedDate).format('HH:mm')}:${('00')}`;
return date
}

export const getMonth = (selectedDate) => {
  const month = `${moment(selectedDate).format('MM')}`;
  return month;
}
export const numberGetMonth = (selectedDate) => {
  const month = `${moment(selectedDate).format('MMMM')}`;
  return month;
}

export const getYear = (selectedDate) => {
  const year = `${moment(selectedDate).format('YYYY')}`;
  return year;
}
export const getNameDay = (selectedDate) => {
  const day = `${moment(selectedDate).format('dddd')}`;
  return day;
}
export const getDay = (selectedDate) => {
  const day = `${moment(selectedDate).format('DD')}`;
  return day;
}
 //---- Notificaciones 
export const formatOnlyNotificacion = (selectedDate) => {
  const date = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('DD')}T${moment(selectedDate).format('HH:mm')}`;
return date
}

export const formatDataBase = (selectedDate) => {
  const date = `${moment(selectedDate).format('YYYY')}-${moment(selectedDate).format('MM')}-${moment(selectedDate).format('DD')}T${moment(selectedDate).format('00:00:00')}`;
return date
}

export const formatDataBaseIndividual = (day,month,year) => {
  const date = `${year}-${moment().month(month).format('MM')}-${moment().day(day).format('MM')}T00:00:00`;
return date
}
export const convertMonth  = (month) => {
  /* console.warn('este es el mes: ',month) */
  switch (parseInt(month)) {
      case 1:
          return 'Enero'
      case 2:
          return 'Febrero'
      case 3:
          return 'Marzo'
      case 4:
          return 'Abril'
      case 5:
          return 'Junio'
      case 6:
          return 'Julio'     
      case 7:
          return 'Agosto'
      case 8:
          return 'Septiembre'
      case 9:
          return 'Octubre'
      case 10:
          return 'Noviembre'
      case 11:
          return 'Diciembre'     
      case 12:
          return ''
      default:
  }
}