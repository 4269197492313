import React, { useState } from 'react';
import icoBuscarGris from '../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import PropTypes from 'prop-types';
import { useForm } from '../../../hooks/useForm';

export const SearchCXModal = (props) => {
    const [searchInstitution, setSearchInstitution] = useState('');

    const handleSearch = (e) => {
        e.preventDefault();
        /* console.warn('si sirve el buscar',searchInstitution) */
      };
    const [formValuesInput, handleInputChangeInputs] = useForm({
        institution: '',
      });
    
      const { institution } = formValuesInput;
    
      const handleInstitution = (e) => {
        e.preventDefault();
        if(institution){
            props.setCxSelected(institution);
            props.setOpenModal('OPEN');
        }else{
            props.setLastOpenModal("INSTITUTION-MODAL");
            props.setMessageModal('¡No se ha seleccionado institución!');
            props.setOpenModal('errorModal');
        }
      };

      const cxList = [
          {
            id:1,
            codigo:'CS 2253-12-13',
            descripcion:'Addplus -d 12 mm, altura 13-18 mm, 0º.',
          },
          {
            id:2,
            codigo:'CS 1301-14T',
            descripcion:'Osmium - tornillo para hueso esponjoso, d 4 mm, fabricado de titanio, largo 14 mm.',
          },
          {
            id:3,
            codigo:'CS 2253-12-17',
            descripcion:'Addplus -d 12 mm, altura 17-26 mm, 6º.',
          },
          {
            id:4,
            codigo:'CS 2253-12-25',
            descripcion:'Addplus -d 12 mm, altura 25-41 mm, 6º.',
          },
          {
            id:5,
            codigo:'CS 2253-12-40',
            descripcion:'Addplus -d 12 mm, altura 40-65 mm, 6º.',
          },
          {
            id:6,
            codigo:'CS 1300-14T',
            descripcion:'Osmium - tornillo expansivo c/expansor, d 5mm, fabricado de titanio, largo 14 mm.',
          },
      ]




    return (
        <div className="c-institution-modal">
      <div className="c-institution-c-search">
        <form onSubmit={handleSearch} className="tools-c-input-search">
          <button className="c-tools-search-icobuscar">
            <img src={icoBuscarGris} alt="" />
          </button>
          <input
            type="text"
            placeholder="Buscar..."
            name="search"
            onChange={({ target }) => setSearchInstitution(target.value)}
            value={searchInstitution}
            className="tools-input-search"
            autoComplete="off"
          />
        </form>
      </div>

        <form
          className="c-institution-c-list-items"
          onSubmit={handleInstitution}
        >
          <div className="c-institution-c-list-items-scroll">
            {cxList &&
                cxList.map((item) => (
                <div key={item.id} className="c-institution-c-item">
                <div>
                  <p className="c-institution-item-cod">{item.codigo}</p>
                  <p className="c-institution-item-name">{item.descripcion}</p>
                </div>
                  <input
                    className="c-institution-item-check"
                    type="radio"
                    checked={ institution&&institution.id&& JSON.parse(institution).id === item.id}
                    value={JSON.stringify(item)}
                    name="institution"
                    onChange={handleInputChangeInputs}
                  />
                </div>
              ))}
          </div>
          <button className="c-institution-button" type="submit">
            CONTINUAR
          </button>
        </form>
    </div>
  );
    
};
SearchCXModal.propTypes = {
    toggle: PropTypes.any,
  };