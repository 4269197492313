import React, {useEffect, useRef, useState } from 'react'
import shortid from 'shortid';
import './styleAcciones.scss';
import imgDelete from '../../../assets/img/126PX/ico_delete.png';
import { useDispatch, useSelector } from 'react-redux';
import { cleanStateUserAprove, GetAllUsers } from '../../../actions/userActions';
import { CheckListCom } from './subComponents/CheckListCom';
import { ActionCom } from './subComponents/ActionCom';
import { AsignarRes } from './subComponents/AsignarRes';
import { DateComp } from './subComponents/DateComp';
import { updatePendigAction } from '../../../actions/surveyAction';
import { convertMonth, getDay, getMonth, getYear } from '../../../hooks/useFormatDateInitialAndFinalDay';



export const AccionesComponent = ({type=null,acciones,setAcciones,accData,setAccData}) => {

    const [checklist, setChecklist] = useState(false);
    const [añadirAccion, setAñadirAccion] = useState(false);
    const[copy,setCopy] = useState();
    const[copy1,setCopy1] = useState();
    const dispatch = useDispatch();
    const [asigar, setAsignar] = useState(false);
    const [fecha, setFecha] = useState(false);
    const [itemCh, setItemCh] = useState();
    const [index, setIndex] = useState();
    const [value, setValue] = useState();
    const [action,setAction] =  useState();
    const [responsable, setResponsable] = useState();
    const user = useSelector(state => state.user.allUsers)
    const render1 = useRef(0);
    const responseSurvey = useSelector(state => state.survey.setSurveyAppoimentSelected);
    const [date, setDate] = useState();
 
    useEffect(() => {
      dispatch(GetAllUsers(0,100))
    }, [])

    
   // pendingSurveyActions

    const handleChange = (event) => {
      setValue(event.target.value);
      const choosed = user.filter(item => item.email === event.target.value)
      setResponsable(choosed)
    };

   useEffect(() => {

     if(acciones === null){
     reaser()
     }else{
        setCopy([...acciones])
     }

   }, [acciones])

   useEffect(() => {
    if(render1.current === 1){
        if(accData === null){
        reaser1()
        }else{
        setCopy1([...accData])
        }
    }else{
    render1.current=1
}
  }, [accData])

    const handleDeleteAction = (item) => {
        const resto = acciones.filter(el => el.id !== item.id)
        setAcciones(resto)
   }

    const reaser = () => {
        let acciones2 = copy;
        acciones2[index] = {...acciones2[index],check:!itemCh.check} 
        setAcciones(acciones2)
    }

    const reaser1 = () => {
        let acciones2 = copy1;
        acciones2[index] = {...acciones2[index],check:!itemCh.check} 
        setAccData(acciones2)
         dispatch(updatePendigAction({
            Id:responseSurvey.pendingSurveyActions[index].id,
            IsCompleted:!itemCh.check,
            ExpectedClosing:responseSurvey.pendingSurveyActions[index].expectedClosing,
        })) 
    }
    const reasetear = (i,item) => {
            setIndex(i);
            setItemCh(item);
            setAcciones(null);
    }
    const reasetear1 = (i,item) => {
        setIndex(i);
        setItemCh(item);
        setAccData(null);
}
    const convert  = (month) => {
        /* console.warn('este es el mes: ',month) */
        switch (parseInt(month)) {
            case 1:
                return 'Enero'
            case 2:
                return 'Febrero'
            case 3:
                return 'Marzo'
            case 4:
                return 'Abril'
            case 5:
                return 'Mayo'
            case 6:
                return 'Junio'       
            case 7:
                return 'Julio'
            case 8:
                return 'Agosto'
            case 9:
                return 'Septiembre'
            case 10:
                return 'Octubre'
            case 11:
                return 'Noviembre'     
            case 12:
                return 'Diciembre'
            default:
                return ''
        }
    }


    const handleToAdd = (item) => {
        const dateCon = `${date.day} de ${convert(date.month)} del ${date.year}`
        if(responsable && action){
            asigar === true && setAsignar(false)
            fecha === true && setFecha(false)
            setChecklist(!checklist)
            setAñadirAccion(!añadirAccion)
            setResponsable(null)
            setAction(null)
            setAcciones([...acciones,{
                id:shortid.generate(),
                usuario:{
                    name:`${responsable[0].firstName && responsable[0].firstName} ${responsable[0].secondName  && responsable[0].secondName} ${responsable[0].firstLastName  && responsable[0].firstLastName} ${responsable[0].secondLastName  && responsable[0].secondLastName}`,
                    userID:responsable[0].userID,
                },
                fechaVencimiento:`${getDay(date)} de ${ convertMonth(getMonth(date))} del ${getYear(date)}`,
                accion:action,
                check:false,
                date:date
            }])
        }
    }
    return (
        <section className='container-component-comments-Acciones'>
            <div className='header-commentsAcciones'>
                <div className='header-titleAcciones'>
                    <p className='title-Acciones'>ACCIONES A TOMAR (CON RESPONSABLE)</p>
                </div>
                {añadirAccion === false && checklist === false &&
                <>
                    <section className='body-Acciones' >
                       { 
                        acciones &&
                        acciones.length > 0 &&
                        acciones.map( (item,i) => (
                        <>
                            <section className='accion-add-view'>
                                    <div className='left-add-view'>
                                        <input className='selec-view'
                                            type='checkbox'
                                            name='selectView'
                                            id="idView"
                                            disabled={(type !== 'seguimiento')  ? true:false}
                                            checked={acciones[i].check}
                                            onChange={() => reasetear(i,item)}
                                        >
                                        </input>
                                        <p className={`${type === 'analisis' ? 'text-view-analisis' : 'text-view'} ${acciones[i].check && 'decoration'}`}>{item.accion}</p>
                                        <p className={`text-center ${type === 'analisis' ? 'text-view-analisis' : 'text-view'} ${acciones[i].check && 'decoration'}` }>{item.usuario.name}</p>
                                        <p className={`text-center ${type === 'analisis' ? 'text-view-analisis' : 'text-view'} ${acciones[i].check && 'decoration'}` }>{item.fechaVencimiento}</p>
                                    </div>
                                    <div className='rigth-add-view' onClick={ () => handleDeleteAction(item)}>
                                        <img className='img-delete-view'  src={imgDelete} />
                                    </div>
                            </section>
                            </>
                            ))
                        }
                        { 
                        accData &&
                        accData.length > 0 &&
                        accData.map( (item,i) => (
                        <>
                            <section className='accion-add-view margin-b'>
                                    <div className='left-add-view'>
                                        <input className='selec-view'
                                            type='checkbox'
                                            name='selectView'
                                            id="idView"
                                            disabled={(type !== 'seguimiento') ? true:false}
                                            checked={accData[i].check}
                                            onChange={() => reasetear1(i,item)}
                                        >
                                        </input>
                                        <p className={`${type === 'analisis' ? 'text-view-analisis' : 'text-view'} ${accData[i].check && 'decoration'}`}>{item.accion}</p>
                                        <p className={`text-center ${type === 'analisis' ? 'text-view-analisis' : 'text-view'} ${accData[i].check && 'decoration'}` }>{item.usuario.name}</p>
                                        <p className={`text-center ${type === 'analisis' ? 'text-view-analisis' : 'text-view'} ${accData[i].check && 'decoration'}` }>{item.fechaVencimiento}</p>
                                    </div>
                                    <div className='rigth-add-view'>
                                        
                                    </div>
                            </section>
                            </>
                            ))
                        }
                    </section>
                    <section className='footer-btn-view'>
                            <button disabled={(type !== 'seguimiento')  ?true :false} onClick={() => setAñadirAccion(!añadirAccion)} className='btn-add'>AÑADIR</button>
                        </section>
                </>
                }
                
                {checklist === false && añadirAccion === true &&
                    <CheckListCom
                        checklist={checklist}
                        setChecklist={setChecklist}
                    />
                }
                {checklist === true && añadirAccion === true &&
                    <ActionCom
                        setAction={setAction}
                        handleToAdd={handleToAdd}
                        action={action}
                        setAsignar={setAsignar}
                        asigar={asigar}
                        setFecha={setFecha}
                        fecha={fecha}
                    />
                }
                {
                    asigar === true &&
                    <AsignarRes
                        setAsignar={setAsignar}
                        handleChange={handleChange}
                        asigar={asigar}
                        value={value}
                    />
                }
                {fecha === true &&
                    <DateComp
                        setDate={setDate}
                        setFecha={setFecha}
                        fecha={fecha}
                        date={date}
                    />
                }
            </div>
        </section>
    )
}
