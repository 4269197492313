
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetToInitialStateStoreHouse, updateLogisticAppointmentStatusStoreHose } from '../../../actions/storeHouseAction';
import iconOrderSuccess from '../../../assets/img/512PX/Icono_CXSurtida_512px.png';
import './../StoreHouseFinishedView/sass/styles.scss';



export const StoreHouseFinishedLogisticScreen = () => {

    const appointmentItem2 = useSelector(state => state.storeHouse.appointmentItem);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleFirtsPage = () => {
        history.replace(`/Inicio`);
      }

      useEffect(() => {
        appointmentItem2&&appointmentItem2.id &&
        dispatch(updateLogisticAppointmentStatusStoreHose({
          AppointmentId: appointmentItem2 ? appointmentItem2.id : appointmentItem.id,
          IsCompleted: true
        }))
        // /* if(!logistics && !appointmentStatus){
        //   dispatch(removeProductsFromAppointmentStoreHouse(appointmentItem.id));
        //   dispatch(removeLocationsFromAppointmentStoreHouse(appointmentItem.id));
        // } */
    
        return ()=>{
          dispatch(resetToInitialStateStoreHouse());
        }
      }, []);
    
  
// console.log("apoiment",appointmentItem2)


    return (
        <div className={"StoreHouseFinishedView_finish"}>
            <div className={"StoreHouseFinishedView_finish_general_c"}>
                <div className={"StoreHouseFinishedView-images"}>
                    <img className="StoreHouseFinishedView-success-img" src={iconOrderSuccess} alt="" />
                </div>

                <>
                    <p className={"StoreHouseFinishedView_finish-subtitle"}>{'Se ha guardado'} </p>
                </>   <p className={"StoreHouseFinishedViewSucess-number"}>{`PQX #${appointmentItem2 && appointmentItem2.id  ? appointmentItem2.id : 1}`}</p>

                <div className={"order_buttons"}>
                    <button
                        className="StoreHouseFinishedView-white-button"
                        onClick={handleFirtsPage}
                    >
                        <span> OK </span>
                    </button>
                </div>
            </div>
        </div>
    );
};