import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, getSearchList, getSearchSuccess } from '../../actions/allUsersAction';
import { getAppointmentsAtq, getSearchListSurgery, getSearchSurgerySuccess } from '../../actions/notification-chatAction';
import icoBuscarGris from '../../assets/img/126PX/Icono_Buscar_Gris_126px.png';

export const SearchChatComponent = ({ type }) => {

  const [searchUser, setSearchUser] = useState('');
  const dispatch = useDispatch();
  const startDate = '2019-01-22T00:00:00';
  const endDate = '2024-01-22T23:59:00';
  const offset = 15;
  const [currentPage, setPage] = useState(0);
  const selectedUserId = useSelector(state => state.allUsers.selectedUserId);


  useEffect(() => {
    
  
    return () => {
      dispatch(getSearchSuccess(null))
    }
  }, [])
  

  const handleSearch = (e) => {
    e.preventDefault();
    if (!type) {
      if (searchUser === '') {
        dispatch(getAppointmentsAtq(startDate, endDate, 14, null, currentPage, offset))
        dispatch(getSearchSurgerySuccess([]))
      } else {
        dispatch(getSearchListSurgery(startDate, endDate, 15, searchUser))
      }
      setSearchUser('');
    } else {
      if (searchUser === '') {
        dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId,currentPage,offset));
        dispatch(getSearchSuccess(null))
      } else {
        dispatch(getSearchList(startDate, endDate, 38,selectedUserId, searchUser))
        dispatch(getAppointmentsStoreHouseSuccess(null))
      }
      setSearchUser('');
    }
  };




  return (
    <form onSubmit={handleSearch} className="SearchChatComponent-tools-c-input-search">
      <button className="c-SearchChatComponent-tools-search-icobuscar" type='submit'>
        <img src={icoBuscarGris} alt="" />
      </button>
      <input
        type="text"
        placeholder={`${!type ? "Buscar... " : 'Buscar cirugias...'}`}
        name="search"
        onChange={({ target }) => setSearchUser(target.value)}
        value={searchUser}
        className="SearchChatComponent-tools-input-search"
        autoComplete="off"
      />
    </form>
  )
};


