
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addLocationsToAppointmentStoreHose, addProductsToAppointmentStoreHose, getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, getSearchList, getSearchListHouseSuccessAtq, removeLocationsFromAppointmentStoreHouse, removeLocationsFromAppointmentStoreHouseSuccess, removeProductsFromAppointmentStoreHouse, removeProductsFromAppointmentStoreHouseSuccess, requerimentsListToAproveState, setAppointmentsStoreHouse, setNewProductsAndSystems, storeHouseGetQuoteToShowInView, UpdateProductRequeriment, addSellerNotes, getAppointmentsStoreHouseRedux, getAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { ListElementsCXAbstractComponent } from '../../../components/ListElementsCXAbstractComponent/ListElementsCXAbstractComponent';
import { PreRequerimentsComponent } from '../../../components/PreRequerimentsComponent/PreRequerimentsComponent';
import { StoreHouseStockRigthFistTable } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockRigthFistTable';
import { StoreHouseStockRigthIconografia } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockRigthIconografia';
import { StoreHouseStockRigthTableItems } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockRigthTableItems';
import { ErrorModalStoreHouse } from './ErrorModal/ErrorModalStoreHouse';
import _ from 'lodash';
import './sass/styless.scss';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';
import { ATQ_Img_View } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Img_View';
import { getQuotationSeller, setSurgeryChoosed } from '../../../actions/atqAdministradorAction';
import { getUserById } from '../../../actions/allUsersAction';


export const StoreHouseApproveOrderScreen = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { OrderPreView } = useParams();
    const listSellerNotes = useSelector(state => state.storeHouse.listSellerNotes);
    const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);
    const seller1 = useSelector(state => state.allUsers.getUserById);
    const listTop = useSelector(state => state.storeHouse.newProducts);
    const [openModal, setOpenModal] = useState();
    const startDate = '1900-01-01T01:01:57'
    const endDate = '2200-05-06T01:01:33'
    const cxAgain = useSelector(state => state.storeHouse.listSearch)

    //ESTE USE SELECTOR ES DONDE SE ENCUENTRA LOS NUEVOS PRODUCTOS QUE SE DEBEN AGREGAR
    const newProducts = useSelector(state => state.storeHouse.newProducts || []);
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const quotationSeller = useSelector(state => state.storeHouse.QuoteToStoreView);
    const locationsDeleted = useSelector(state => state.storeHouse.removeLocationsFromAppointmentStoreHose);
    const produtsDeleted = useSelector(state => state.storeHouse.removeProductsFromAppointmentStoreHose);
    const requerimentsListToAproveItems = useSelector(state => state.storeHouse.requerimentsListToAprove);
    const [listSellerNotesState, setListSellerNotesState] = useState([]);
    const [newProductsReq, setNewProductsReq] = useState([]);
    const [requerimentsList, setRequerimentsList] = useState(null);
    const [medic, setMedic] = useState(null);
    const [patient, setPatient] = useState(null);
    const [priceList, setPriceList] = useState(null);
    const [displayList, setDisplayList] = useState(null)
    const [atq, setAtq] = useState(null);
    const [seller, setSeller] = useState(null);
    const [openModalError, setOpenModalError] = useState(null);
    const [deleteLocations, setDeleteLocations] = useState([]);
    const [addLocations, setAddLocations] = useState(null);




    //ESTA FUNCION SIRVER PARA CREAR EL ARREGLO DE LAS MODIFICACIONES QUE SE ESTAN HACIENDO Y PODER VER SI SE MODIFICO ALGUNA
    const addDeleteLocations = (idRemoveLocations) => {
        // debugger
        if (deleteLocations && deleteLocations.length > 0) {
            let searchArray = deleteLocations;
            let ban = searchArray.find(item => item.id === idRemoveLocations.id)
            if (!ban) {

                setDeleteLocations([...deleteLocations, { ...idRemoveLocations }])
            } else {
                let nuevo = searchArray.filter(item => item.id != idRemoveLocations.id)
                setDeleteLocations([...nuevo, { ...idRemoveLocations }])
            }
        } else {
            setDeleteLocations([{ ...idRemoveLocations }])
        }
    }

    useEffect(() => {
        dispatch(getAppointmentsStoreHouseRedux(startDate, endDate, 15, cxAgain && cxAgain[0] ? cxAgain[0].id : appointmentItem.id))
        return () => {
            dispatch(addSellerNotes([]))
            // dispatch(setAppointmentsStoreHouse([]))
        }
    }, [])





    useEffect(() => {
        // debugger

        // console.log(cxAgain[0])
        //AQUI ES CUANDO BUSCAS UNA CIRUGIA EN LA LISTA Y CUANDO TE REGRESAS
        if (cxAgain && cxAgain[0] && OrderPreView) {
            // debugger
            dispatch(setAppointmentsStoreHouse(cxAgain[0]))
            dispatch(getAppointmentLocationsStoreHose(cxAgain[0].id));
            dispatch(getProductsToAppointmentStoreHose(cxAgain[0].id));
        }
    }, [cxAgain])



    useEffect(() => {
        if (addLocations != null) {
            // debugger
            //ESTA FUNCION SIGUIENTE ES PARA AGREGAR O MODIFICAR LOS NUEVOS PRODUCTOS O LOCATIONS 
            //SE LE ENVIA EL 222 SOLO PARA DETERMINAR QUE ES UNA MODIFICACION Y ESO INDICA DENTRO DE LA FUNCION QUE ES UNA MODIFICACION
            handleAddProductsDown('222');
            handleNavigateOrderPreViewWithOut();
        }
    }, [addLocations])


    const addLocationsModified = () => {
        let arrayAdd = [];
        // debugger
        deleteLocations.map(item => {
            let add = appointmentItem.productRequirements.find(item2 => item2.id === item.id)
            // debugger
            if (add) {
                /* console.warn(add); */
                arrayAdd.push(add)
            }
        })
        setAddLocations([...arrayAdd])
    }

    const removeLocations = () => {
        // debugger
        if (deleteLocations) {
            deleteLocations.map(item => {
                // debugger
                if (item.allInformation || (OrderPreView && item.locastionInfo)) {
                    item.isSystem === true
                        ? dispatch(removeLocationsFromAppointmentStoreHouse(item.allInformation.id))
                        : dispatch(removeProductsFromAppointmentStoreHouse(item.allInformation.id))
                }
            })
        }
        addLocationsModified();
    }


    useEffect(() => {
        // debugger
        if ((produtsDeleted === true && produtsDeleted != 'si cambio' || locationsDeleted == true && locationsDeleted != 'si cambio') && !OrderPreView) {
            // debugger
            handleAddProductsDown();
        }
    }, [locationsDeleted])


    useEffect(() => {
        //Este apartado es cuando se elige por primera por primera vez la cirugia
        // debugger
        if (!OrderPreView) {
            dispatch(getAppointmentLocationsStoreHose(appointmentItem.id));
            dispatch(getProductsToAppointmentStoreHose(appointmentItem.id));

        }
        else {
            // debugger
            //REALIZA LA PETICION PARA LLENAR EL LISTSEARCH
            //dispatch(getSearchList(startDate, endDate, 15, appointmentItem.id))
        }
        dispatch(getUserById(appointmentItem.creatorUserId))
        return () => {
            // dispatch(setNewProductsAndSystems([]));
            dispatch(getAppointmentLocationsStoreHoseSuccess([]));
            dispatch(getProductsToAppointmentStoreHoseSuccess([]));
            dispatch(getSearchListHouseSuccessAtq([]));
            dispatch(removeLocationsFromAppointmentStoreHouseSuccess('si cambio'));
            dispatch(removeProductsFromAppointmentStoreHouseSuccess('si cambio'));
        }
    }, [])


    useEffect(() => {
        // debugger
        if (!_.isNil(AppointmentLocations) && AppointmentLocations.length > 0 && AppointmentLocations != null) {

            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, newProductsReq, appointmentItem)
        }
    }, [AppointmentLocations])



    useEffect(() => {

        // debugger
        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq && newProductsReq.length > 0 && newProductsReq != null)) {

            dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, appointmentItem, OrderPreView)));
        }
    }, [AppointmentProducts, newProductsReq])


    useEffect(() => {
        dispatch(requerimentsListToAproveState(requerimentsList))
    }, [requerimentsList])

    useEffect(() => {
        if (seller1) {
            const { firstName, secondName, firstLastName, secondLastName } = seller1;
            setSeller({ firstName, secondName, firstLastName, secondLastName });
        }
    }, [seller1])


    useEffect(() => {
        //debugger
        if (appointmentItem.medic) {
            const { name, secondName, firstLastname, secondLastname } = appointmentItem && appointmentItem.medic;
            setMedic({ name, secondName, firstLastname, secondLastname });
            setPriceList(appointmentItem.priceList)
            setDisplayList(appointmentItem.displayPricelist)
        }
    }, [appointmentItem])

    useEffect(() => {
        const { firstName = '', secondName, firstLastName, secondLastName } = appointmentItem && appointmentItem.patient;
        setPatient({ firstName, secondName, firstLastName, secondLastName });
    }, [medic])

    useEffect(() => {
        if (appointmentItem.atq) {
            const { firstName, secondName, firstLastName, secondLastName } = appointmentItem && appointmentItem.atq;
            setAtq({ firstName, secondName, firstLastName, secondLastName });
        }
    }, [patient])


    const toggleModal = () => {
        setOpenModalError(null);
    };


    //ESTA FUNCION ES PARA LOS PRODUCTOS DE LA TABLLILTA SUPERIOR
    const handleAddPoructsTop = (arraySystems, arrayProducts, arrayProRequUpdate) => {

        let arrayWithLocationBoxes = []; //ESTE ARREGLO ES PARA ALMACENAR TODAS LAS QUE TIENEN LOCATIONBOXES MAS DE UNA CAJA
        let arrayWithOutLocationBoxes = [];// ESTE ARREGLO ES PARA ALMACENAR TODOS LOS PRODUCTOS INDIVIDUALES
        // debugger
        newProducts &&
            newProducts.map(item => {
                // debugger
                if (!item.isProduct) {
                    let arrayBoxes = [];//ESTE ARREGLO ES PARA HACER EL ARREGLO DE STRING DE LAS UBICACIONES DE LAS CAJAS
                    arrayBoxes.push(item.locastionItem);//AQUI SE ALMACENA LA UBICACION POR DEFAULT QUE SE ESCOGIO
                    let objectConver2 = Object.values(item.locationBoxes ? item.locationBoxes : '');// AQUI SE HACE LA CONVERCION POR QUE LAS LOCATIONSBOXES SON OBJETOS

                    //AQUI EN EL SIGUIENTE MAP SE HACE EL PUSH DE CADA UBICACION DE LAS CAJAS EXTRAS QUE TIENE LA UBICACION ESCOGIDA
                    item.locationBoxes &&
                        objectConver2.map(item => {
                            arrayBoxes.push(item.description.ubicacion)
                        })
                    //AQUI LO SIGUIENTE QUE SE HACE ES METER ES METER EN UN ARREGLO TODAS LAS LOCATIONS QUE SE AGREGARAN PARA MANDAR TODO EL ARREGLO JUNTO EN EL DISPATCH
                    arrayWithLocationBoxes.push({
                        AppointmentId: appointmentItem.id,
                        Location:
                        {
                            Id: item.product.id
                        },
                        LocationBoxIDs: [...arrayBoxes],
                        LocationLevels: Number(item.systemLevels)
                    })
                    // debugger
                }
                else if (item.isProduct === true) {
                    // debugger
                    //AQUI EN ESTA DECISION ES DONDE SE HARA EL ARREGLO DE LOS PRODUCTOS INDIVIDUALES QUE SE AGREGARON EN ESE MOMENTO EN ALMACEN
                    arrayWithOutLocationBoxes.push(
                        {
                            AppointmentId: appointmentItem.id,
                            ProductQuantity: item.ProductQuantity,
                            Deposito: item.Deposito,
                            Lote: item.Lote,
                            Product:
                            {
                                Id: item.product.id
                            },
                            Ubicacion: item.Ubicacion
                        }
                    )

                }
                // debugger
            })

        let receiveArray = Object.values(arraySystems ? arraySystems : []);
        let receiveArrayProducts = Object.values(arrayProducts ? arrayProducts : []);
        let arrayComplete = [];
        let arrayCompleteProducts = [];


        if (arrayWithLocationBoxes && arrayWithLocationBoxes.length > 0) {
            //debugger
            let converToArray = Object.values(arrayWithLocationBoxes && arrayWithLocationBoxes);
            arrayComplete = [...receiveArray, ...converToArray];

        } else {
            arrayComplete = [...receiveArray]
        }

        if (arrayWithOutLocationBoxes && arrayWithOutLocationBoxes.length > 0) {
            let converToArray = Object.values(arrayWithOutLocationBoxes && arrayWithOutLocationBoxes)
            arrayCompleteProducts = [...receiveArrayProducts, ...converToArray]
        }
        // debugger
        if (arrayComplete && arrayComplete.length > 0) {
            dispatch(addLocationsToAppointmentStoreHose(arrayComplete))
        }
        if (arrayCompleteProducts && arrayCompleteProducts.length > 0) {
            dispatch(addProductsToAppointmentStoreHose(arrayWithOutLocationBoxes))
        }
        // debugger
        arrayProRequUpdate.map(item => {
            dispatch(UpdateProductRequeriment({
                ...item
            }))
        })
    }

    //ESTA FUNCION ES PARA AGREGAR LOS PRODUCTOS DE LA TABLITA INFERIOR
    const handleAddProductsDown = (variable = null) => {

        // debugger
        let arraySystems = []; //ESTE ARREGLO ES PARA LOS PRODUCTSREQUERIMENTS QUE SEAN SISTEMAS
        let arrayProducts = [];//ESTE ARREGLO ES PRA LOS PRODUCTSREQUERIMENTS QUE SEAN PRODUCTOS INDIVIDUALES
        let arrayProRequUpdate = [];
        let arrayComplete = variable === '222' ? addLocations : appointmentItem.productRequirements
        // debugger
        arrayComplete &&
            arrayComplete.map(item => {
                // debugger
                if (item.isSystem === true) {
                    // debugger
                    let arrayBoxes = [];//ESTE ARREGLO ES PARA HACER EL ARREGLO DE STRING DE LAS UBICACIONES DE LAS CAJAS
                    arrayBoxes.push(item.locastionItem);//AQUI SE ALMACENA LA UBICACION POR DEFAULT QUE SE ESCOGIO
                    let objectConver2 = Object.values(item.locationBoxes ? item.locationBoxes : '');// AQUI SE HACE LA CONVERCION POR QUE LAS LOCATIONSBOXES SON OBJETOS

                    //AQUI EN EL SIGUIENTE MAP SE HACE EL PUSH DE CADA UBICACION DE LAS CAJAS EXTRAS QUE TIENE LA UBICACION ESCOGIDA
                    //NOTA ESTO ES PARA LAS CAJAS PERO SE COMENTO


                    /* item.locationBoxes &&
                        objectConver2.map(item => {
                            arrayBoxes.push(item.description.ubicacion)
                        }) */

                    //AQUI LO SIGUIENTE QUE SE HACE ES METER ES METER EN UN ARREGLO TODAS LAS LOCATIONS QUE SE AGREGARAN PARA MANDAR TODO EL ARREGLO JUNTO EN EL DISPATCH
                    // debugger
                    if (item.locastionItem) {
                        //ESTA DECISION ES PARA VER SI TIENE LOCASTIONITEM SI NO ES QUE TIENE UNA NOTA DE NO SURTIDO YA QUE YA PASO LA VALIDACION

                        arrayProRequUpdate.push({
                            Id: item.id,
                            AppointmentProductOrRequirementId: item.locastionInfo.id
                        });

                        arraySystems.push({
                            AppointmentId: appointmentItem.id,
                            Location:
                            {
                                Id: item.locastionInfo.id
                            },
                            LocationBoxIDs: [...arrayBoxes],
                            LocationLevels: Number(item.systemLevels)

                        });
                    }
                    // debugger
                }
                else if (item.isSystem === false) {
                    // debugger
                    //AQUI EN ESTA DECISION ES DONDE SE HARA EL ARREGLO DE LOS PRODUCTOS INDIVIDUALES QUE YA VENIAN EN LOS PRODUCSREQUERIMENTS
                    arrayProducts.push(
                        {
                            AppointmentId: appointmentItem.id,
                            ProductQuantity: item.ProductQuantity,
                            Deposito: item.Deposito,
                            Lote: item.Lote,
                            Product:
                            {
                                Id: item.product.id
                            },
                            Ubicacion: item.Ubicacion
                        }
                    )

                }
                // debugger
            })
        // debugger
        handleAddPoructsTop(arraySystems, arrayProducts, arrayProRequUpdate);

    }

    const handleNavigateToViewQuote = () => {
        if (appointmentItem && appointmentItem.quotationId) {
            dispatch(storeHouseGetQuoteToShowInView(appointmentItem.quotationId))
            history.replace(`/StoreHouse/ReviewCotizacionFinalizada/${appointmentItem.quotationId}`);
        } else {
            setOpenModal("Generate-order")
        }
    }


    //ESTA FUNCION ES PARA AGREGAR LOS REQUERIMIENTOS CHEQUEADOS  QUE SE VAN A SURTIR
    const handleCheckRequeriments = (e) => {

        //ESTA DECISION SE TOMA PARA SABER SI EL STATE YA TIENE UN REQUERIEMTO COMO CHECKED
        //DE LO CONTRARIO ESTARIA PASANDO AL ELSE Y SE ESTARIA AGREGANDO AUTOMATICAMENTE
        if (requerimentsList && requerimentsList.length > 0) {
            //DE AQUI SE TOMA LA DECISION DE SI YA EXISTE DENTRO DEL STATE SE ELIMINE Y SI NO SE AGREGUE
            if (requerimentsList.some(el => el.requirement && el.requirement === JSON.parse(e.target.value).requirement)) {
                //SI YA EXISTE EL REQUERIMIENTO EN EL STATE SE ELIMINA Y SE HACE CON UN FILTER
                //YA QUE ESTE CREA OTRO ARRAY AVACE DE UNA CONDICION
                setRequerimentsList(requerimentsList.filter(el => el.requirement !== JSON.parse(e.target.value).requirement))
            } else {

                //SI NO EXISTE EL REQUERIMIENTO EN ESTE CASO SE PASA AL ELSE Y AQUI SE AGREGAN LOS QUE YA ESTABAN MAS EL NUEVO
                setRequerimentsList([...requerimentsList, JSON.parse(e.target.value)])
            }
        }

        else {
            setRequerimentsList([JSON.parse(e.target.value)])
        }
    }

    //ESTA FUNCION ES PARA SUMAR LOS REQUERIMIENTOS DE LA CIRUGIA QUE YA ESTAN CARGADOS
    const plusRequerimentAppoiment = () => {
        //AQUI ES PARA SUMAR LA CANTIDAD DE REQUIRIMIENTOS QUE YA TRAIA EL HOSPITAL
        let hospital = appointmentItem.hospital && appointmentItem.hospital.requirements && appointmentItem.hospital.requirements.length > 0 ? appointmentItem.hospital.requirements.length : 0;
        //ESTA VARIABLE ES PARA RESCATAR LA CANTIDAD DE REQUERIMIENTOS DE LOS MEDICOS
        let medicos = appointmentItem.medic && appointmentItem.medic.requirements && appointmentItem.medic.requirements.length > 0 ? appointmentItem.medic.requirements.length : 0;
        //ESTA VARIABLE ES PARA RESCATAR LA CANTIDAD DE NOTAS DE LOS VENDEDORES
        let notaVentas = appointmentItem.appointmentNotes && appointmentItem.appointmentNotes.length > 0 ? appointmentItem.appointmentNotes.length : 0;
        //ESTA ES VARIABLE ES PARA LA SUMATORIA DE LOS REQUERIMIENTOS QUE SE RESCATARON EN LAS VARIABLES PASADAS
        let plushRequeriments = hospital + medicos + notaVentas;
        return plushRequeriments
    }


    const handleClickContinuar = () => {
        let ban = true;
        // debugger

        if (requerimentsListToAproveItems && requerimentsListToAproveItems.length !== plusRequerimentAppoiment()
            || !requerimentsListToAproveItems && 0 !== plusRequerimentAppoiment()) {
            if (!OrderPreView) {
                //ESTA FUNCION LO QUE HACE ES REVISAR SI YA LE DISTE CHECK A TODO LOS REQUERIMIENTOS QUE APARECEN EN LA TABLA IZQUIERDA
                ban = false
                setOpenModalError({ error: 'errorModal', message: '!No se han confirmado los requerimientos!' })
            }
        } else if (appointmentItem.productRequirements.find(item => !item.locastionInfo && !item.notStockedNote)) {
            //ESTA FUNCION SIRVE PARA VER QUE  LOS PRODUCTSREQUERIMENTS TENGAN TODOS  UBICACIONES ASIGNADAS O EN SU CASO NOTA DE ALMACEN\\
            ban = false
            setOpenModalError({ error: 'errorModal', message: '!No se han asignado todas la ubicaciones!' })
        }

        else {

            // //ESTE  DECISION RECORREMOS LOS PRODUCTREQUERIMENTS PARA VER QUE UBICACION TIENE MAYOR QUE 1  CAJA Y ESO QUIERE DECIR QUEDEBE TENER MAS UBICACIONES
            //   appointmentItem.productRequirements.map( item => {
            //     if(item.locastionInfo && item.locastionInfo.numberOfBoxes > 1){
            //         // EN ESTA DECISION SI ESTE PRODUCTO TIENE MAS DE UNA CAJA SIGNIFICA QUE DEBE EXISTIR locationBoxes si o existe es por que no tiene las ubicaciones
            //         if(item.locationBoxes){
            //             // AQUI VERIFICAMOS SI DEBE TENER MAS DE 1 CAJA EXTRA SI TODAS ESTAN LLENAS
            //             let objectConver = Object.values(item.locationBoxes);
            //             if(objectConver.find(item => !item)){
            //                 ban = false
            //                 setOpenModalError({error:'errorModal', message:'!No se han asignado todas la ubicaciones!'})
            //             }
            //         }else{
            //             ban = false
            //             setOpenModalError({error:'errorModal', message:'!No se han asignado todas la ubicaciones!'})
            //         }
            //     }
            // }); 
            // // debugger
            //LisTop ES LA LISTA DE LOS PRODUCTOS QUE SE AGREGARON EN LA TABLA DE ARRIBA
            listTop &&
                listTop.map(item => {
                    if (item.product && item.product.numberOfBoxes > 1) {
                        if (item.locationBoxes) {
                            let objectConver2 = Object.values(item.locationBoxes);
                            if (objectConver2.some(item2 => _.isNil(item2))) {
                                ban = false
                                setOpenModalError({ error: 'errorModal', message: '!No se han asignado todas la ubicaciones!' })
                            }
                        }
                    }
                });
        }
        //LAS FUNCIONES SIGUIENTES ES PARA INGRESAR LOS DATOS A LA BASE DE DATOS
        //1.AGREGAR LOS PRIMEROS PRODUCTOS. DE LA TABLA DE LA PARTE SUPERIOR
        //1.AGREGAR LOS PRIMEROS PRODUCTOS. DE LA TABLA DE LA PARTE INFERIOR
        // debugger
        if (ban === true) {
            // debugger
            //AppointmentProducts Y AppointmentLocations ESTO SON LOS PRODUCTOS Y LOCATIONS QUE YA ESTAN EN LA BD
            if ((AppointmentProducts.length === 0)) {
                // debugger
                if (!OrderPreView) {
                    handleAddProductsDown();
                }

                handleNavigateOrderPreViewWithOut();
            } else
                if (AppointmentProducts || AppointmentLocations) {
                    // debugger
                    removeLocations();
                }
        }
    }



    const handleNavigateOrderPreViewWithOut = () => {

        history.replace('/StoreHouse/StoreHouseApproveOrderPreView/incomplete');

    }

    const closeModal = () => {
        if (openModal === 'Generate-order') {
            setOpenModal(null);
        }
    }

    return (
        <div className='storeHouseApproveOrderScreen-container'>
            <SubNavBarComponent title={'ALMACÉN'} historyPage={'/StoreHouse'} />
            <div className='storeHouseApproveOrderScreen-section-container'>
                <section className='storeHouseApproveOrderScreen-section-left'>
                    {
                        //ESTE COMPONENTE ES EL PRIMERO DEL LADO IZQUIERDO 
                    }
                    <ListElementsCXAbstractComponent
                        typeSurgeryList={''}
                        medicSchedule={medic}
                        patientSchedule={patient}
                        priceListSchedule={priceList}
                        InstitutionToSchedule={appointmentItem.hospital || ''}
                        HourToSchedule={appointmentItem.surgeryDate || ''}
                        ClientToSchedule={appointmentItem.client || ''}
                        ATQToSchedule={atq}
                        SellerToSchedule={seller}
                        displayList={displayList}
                        typeView={{ title: `PQX #${appointmentItem.id ? appointmentItem.id : ''}`, type: 'STORE' }}
                    />
                    {
                        //ESTE COMPONENTE ES EL SEGUNDO DEL LADO IZQUIERDO, ESTA AL FINAL
                    }
                    <PreRequerimentsComponent
                        handleCheck={handleCheckRequeriments}
                        requirementsInstitution={appointmentItem.hospital || []}
                        requirementsSales={appointmentItem.appointmentNotes || []}
                        requirementsMedic={appointmentItem.medic || []}
                        listElements={appointmentItem.appointmentWarehouseNotes || []}
                        listNameToAdd={'Notas de Almacén'}
                        messageEmpty={'No se encontraron Notas de Almacén'}
                        messageButton={'Agregar Nota de Almacén'}
                        type={'ALMACEN'}
                    />
                </section>

                <section className='storeHouseApproveOrderScreen-section-rigth'>

                    {
                        //ESTOS SON LOS  ICONOS, QUE APARECEN AL INICIO  DEL LADO DEREHO
                    }
                    <StoreHouseStockRigthIconografia />

                    {
                        //ESTA ES LA PRIMERA TABLITA DEL LADO DERECHO, LA CUAL SE LLENA SI AGREGAS UN PRODUTO O UBICACION EN ESTE MOMENTO
                    }
                    <StoreHouseStockRigthFistTable
                        listItems={newProducts}
                        typeComponent={'ListAddProsducts'}
                        addItem={appointmentItem && appointmentItem.warehouseCompleted}

                    />

                    {/*   {
                        OrderPreView&&
                        <StoreHouseStockRigthTableItems  
                            listItems={appointmentItem.productRequirements} 
                            typeComponent={'ListProsducts'} 
                            setListSystemScann={setListSystemScann} 
                        />
                    } */}
                    {
                        //ESTA ES LA SEGUNDA TABLITA DEL LADO DERECHO, LA CUAL SON LOS PRODUCTSREQUERIMENTS
                    }
                    {
                        <StoreHouseStockRigthTableItems
                            listItems={appointmentItem.productRequirements || []}
                            typeComponent={'ListProsducts'}
                            addDeleteLocations={addDeleteLocations}
                            validation={OrderPreView ? false : true}
                            addItem={appointmentItem && appointmentItem.warehouseCompleted}

                        />
                    }



                </section>

                <section className='storeHouseApproveOrderScreen-section-bottom-button-container'>
                    <div className='storeHouseApproveOrderScreen-section-bottom-button-container-position'>
                        <button className='storeHouseApproveOrderScreen-section-bottom-button-quote' onClick={handleNavigateToViewQuote} >
                            VER COTIZACIÓN
                        </button>
                        <button className='storeHouseApproveOrderScreen-section-bottom-button-continue' onClick={() => handleClickContinuar()}>
                            CONTINUAR
                        </button>
                    </div>
                </section>
            </div>
            {
                openModalError && openModalError.error === "errorModal" &&
                <ErrorModalStoreHouse
                    isOpen
                    toggle={toggleModal}
                    setOpenModalError={setOpenModalError}
                    messageModal={openModalError.message}
                />
            }

            {
                openModal === "Generate-order" &&
                <ATQ_Img_View
                    isOpen
                    toggle={closeModal}
                    type={"Generate-order"}
                    state={false}
                    textError={"NO EXISTE COTIZACIÓN"}
                />
            }
        </div>




    )
}
