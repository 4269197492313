import React, { useEffect, useState } from 'react';
import './styleStarQualification.scss';

export const StarQualificationComponent = (props) => {






    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);



    useEffect(() => {
        if (props && props.setValueSales) {
            props.setValueSales(rating);
            /* console.log('log', rating) */
        }
    }, [rating])


    return (

        <div className="star-rating">
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <button
                        type="button"
                        key={index}
                        className={index <= (hover || rating) ? "on" : "off"}
                        onClick={() => setRating(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        <span className="star">&#9733;</span>
                    </button>
                );
            })}
        </div>
    )
}
