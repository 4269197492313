import shortid from "shortid";


export const paymentConditionsData = [
    {
      id: shortid.generate(),
      name: '30 Días'
    },
    {
      id: shortid.generate(),
      name: '60 Días'
    },
    {
      id: shortid.generate(),
      name: '90 Días'
    },
    {
      id: shortid.generate(),
      name: '120 Días'
    },
    {
      id: shortid.generate(),
      name: 'Contado'
    },
]
