import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateFormat } from '../../shared/DateFormat';
import { StoreHouseApproveStatusEditFieldComponent } from './StoreHouseApproveStatusEditFieldComponent';
import iconEdit from '../../../assets/img/512PX/Icono_Editar_512px.png';
import iconPaloma from '../../../assets/img/126PX/Icon_paloma.png';
import { StoreHouseAssignSystemTableModal } from '../StoreHouseAssignSystemsComponents/StoreHouseAssignSystemTableModal';
import { formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import icon_arrowBefore from '../../../assets/img/126PX/icon_arrowBefore.png';
import icon_arrowLater from '../../../assets/img/126PX/icon_arrowLater.png';
import { type } from 'jquery';



export const StoreHouseListComponent = ({ typeView, listFields, letter }) => {

    const [oppenModal, setOppenModal] = useState();
    const [itemToAddLocation, setItemToAddLocation] = useState();

    const handleAddLocation = (item) => {
        setOppenModal('OPEN');
        setItemToAddLocation(item);
    }
    const toggle = () => {
        setOppenModal(null);
    }


    /* console.warn('listFields',listFields) */
    return (
        <div className="ListElementsCXAbstractComponent-left-item-overview">
            {
                (typeView && typeView.type === 'STORE' || typeView.type === 'PQX' || typeView.type === 'TRANSFER' || 
                typeView.type === "TRANSFERIR-CX" ) &&
                <div className={(typeView.type === 'STORE' || typeView.type === 'PQX' || typeView.type === 'TRANSFER' || typeView.type === "TRANSFERIR-CX" ) && 'ListElementsCXAbstractComponent-section-left-title-container ListElementsCXAbstractComponent-section-left-title-container-colorTitleStore'}>
                    <h4 className='ListElementsCXAbstractComponent-section-left-title' > {typeView.title} </h4>
                </div>
            }
            {

                typeView && typeView.type === 'PQX' &&
                <div className="second-letter">
                    <img src={iconPaloma} alt="" className="img_paloma" />
                    <span className="letter">Material Liberado</span>
                </div>
            }
            {

                typeView && typeView.type === 'TRANSFERIR-CX' &&
                <div className="second-letter-Trans">
                    <span className="letter-Trans">Jesus Garcia te ha transferido CX #5</span>
                </div>
            }
            {

                typeView && typeView.type === 'TRANSFER' &&
                <div className="second-letter3">
                    <span className="letter">{letter}</span>
                </div>
            }
            {

                typeView && typeView.type === 'CX' &&
                <div className="second-letter2">
                    <span className="letter">{typeView.title}</span>
                </div>
            }
            {
                listFields && listFields.map(el => (
                    <div key={el.uid} className="ListElementsCXAbstractComponent-left-overview-item-content">
                        {
                            (el.type != 'GREEN' || !el.type) &&

                            <h3 className='ListElementsCXAbstractComponent-left-overview-item-title'>{el.title}</h3>

                        }
                        {
                            (el.type === 'TEXT' || !el.type) &&
                            <p className='ListElementsCXAbstractComponent-left-overview-item-name'>
                                {el.value}
                            </p>
                        }
                        {
                            (el.type === 'GREEN') &&
                            <>
                                <h3 className='ListElementsCXAbstractComponent-left-overview-item-title_green'>{el.title}</h3>
                                <p className='ListElementsCXAbstractComponent-left-overview-item-name_green'>{el.value}</p>
                            </>
                        }
                        {
                            (el.type === 'DESCRIPTION') &&
                            <div className='container-description'>
                                {
                                    el.value &&
                                    el.value.map(item => (

                                        <p className='ListElementsCXAbstractComponent-left-overview-item-name'>{item.product.productDescription} </p>

                                    ))
                                }
                            </div>
                        }
                        {
                            (el.type === 'Niveles') &&
                            <div className='container-description'>
                                {
                                    el.value &&
                                    el.value.map(item => (
                                        <p className='ListElementsCXAbstractComponent-left-overview-item-name'> {item.systemLevels}</p>

                                    ))
                                }
                            </div>
                        }

                        {
                            (el.type === 'DATE-COMPLETE') &&
                            <p className='ListElementsCXAbstractComponent-left-overview-item-name'>
                                {`${el.value ? DateFormat(el.value) + ' - ' + formatHourToPresent(el.value) + ' hrs.' : '--'}`} 
                            </p>
                        }

                        {
                            (el.type === 'DATE') &&
                            <p className='ListElementsCXAbstractComponent-left-overview-item-name'>
                                {`${el.value ? DateFormat(el.value) : '--'}`}
                            </p>
                        }
                        {
                            (el.type === 'HOUR') &&
                            <p className='ListElementsCXAbstractComponent-left-overview-item-name'>
                                {formatHourToPresent(el.value) + ' hrs.'}
                            </p>
                        }
                        {
                            (el.type === 'STATE') &&
                            <div className={'ListElementsCXAbstractComponent-left-overview-item-component'}>
                                <p className='ListElementsCXAbstractComponent-left-overview-item-name'>
                                    {el.value}
                                </p>
                                <img src={iconEdit} alt="" className="ListElementsCXAbstractComponent-left-overview-item-img" onClick={() => handleAddLocation(el)} />
                            </div>
                        }
                        {
                            (el.type === 'TEXT-EDIT') &&
                            <StoreHouseApproveStatusEditFieldComponent itemElement={el} />
                        }
                        {
                            (el.type === 'ATQ-Before') &&
                            <div className='div_atq_before_red'>
                                <h3 className='ListElementsCXAbstractComponent-left-overview-item-title-atqBefore'>ATQ</h3>
                                <img className='img_atq_before_red' src={icon_arrowBefore} />
                                <p className='p_atq_before_red' >{el.value}</p>
                            </div>
                        }
                        {
                            (el.type === 'ATQ-Later') &&
                            <div className='div_atq_later_green'>
                                <h3 className='ListElementsCXAbstractComponent-left-overview-item-title-atqLater'>ATQ</h3>
                                <img className='img_atq_later_green' src={icon_arrowLater} />
                                <p className='p_atq_later_green' >{el.value}</p>
                            </div>


                        }
                    </div>
                ))
            }
            {
                oppenModal === 'OPEN' &&
                <StoreHouseAssignSystemTableModal
                    isOpen
                    toggle={toggle}
                    itemToChangeState={itemToAddLocation}
                />
            }
        </div>
    )
}

StoreHouseListComponent.propTypes = {
    typeView: PropTypes.any,
    listFields: PropTypes.any,
}
