import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../../../hooks/useForm';
import icoBuscarGris from '../../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import img_msj_group from '../../../../assets/img/512PX/Icono_GroupMsj_Azul.png'
import { useDispatch, useSelector } from 'react-redux';
import { addMemberToChatGroup, addMessagesToChatGroup, add_chat_group_message_success, add_member_from_chat_group_success, createChatGroup, create_chat_group_success, getChatGroup, get_chat_group_success, openMessageGeneral, search_group_surgery_to_modal, setChatGroupSelected } from '../../../../actions/notification-chatAction';
import { add } from 'lodash';

export const Search_Share = (props) => {
  const [searchInstitution, setSearchInstitution] = useState('');
  const [atqList, setATQList] = useState([]);
  const dispatch = useDispatch();
  const group = useSelector(state => state.notificationChat.getGroup);
  const search = useSelector(state => state.notificationChat.searchToGroupModal);
  const surgery = useSelector(state => state.storeHouse.appointmentItem);
  const newGroup = useSelector(state => state.notificationChat.createGroup);
  const members = useSelector(state => state.notificationChat.addMember);
  const addMessages = useSelector(state => state.notificationChat.addMessages);
  const userLogin = useSelector(state => state.user.user.identity);
  const openMessagesGeneral = useSelector(state => state.notificationChat.openMessageGeneral);
  const [groupChoseed,setGroupChoseed] = useState(null);

  const handleSearch = (e) => {
    e.preventDefault();
    let busqueda = group.filter(item => item.chatGroupName.toUpperCase() === searchInstitution.toUpperCase());
    dispatch(search_group_surgery_to_modal([...busqueda]));
  };

  const handleAddOptionalItem = (item) => {
    let newArray = [];

    if (atqList.some(el => el.id === item.id)) {
      newArray = atqList.filter(el => el.id !== item.id);
    } else {
      newArray = atqList.map(el => el);
      newArray.push(item);
    }
    setATQList(newArray);
  }

  /* useEffect(() => {
    debugger
      if(addMessages && addMessages.message && surgery && props.description){
        dispatch(openMessageGeneral(!openMessagesGeneral, "CHAT"))
      }
  }, [addMessages]) */
  

  const handleInstitution = async (e) => {
    e.preventDefault();
    if (atqList && atqList.length > 0) {
        if(atqList && surgery && props.description){
        let resultado = await dispatch(addMessagesToChatGroup(atqList[0].id, props.description, surgery.id, null))
            if(resultado === true){
              dispatch(openMessageGeneral(false, "CHAT"))
              dispatch(setChatGroupSelected(true, atqList[0].id, atqList[0].chatGroupName))
            }

        }
        
        props.setCxSelected(atqList);
        if (props.setOpenModal) {
          props.setOpenModal('chat');
        } else {
          props.toggle();
        }
      if(props.type === "chat"){
        props.toggle();
      }
   }
    else{
        if (atqList && atqList.length > 0) {

          props.setCxSelected(atqList);
          if (props.setOpenModal) {
            props.setOpenModal('chat');
          } else {
            props.toggle();
          }
      }
    }
};

useEffect(() => {
  if(group && group.length === 0){
    dispatch(createChatGroup(surgery.id,"Logistica " + surgery.id))
  }
}, [group])


useEffect(() => {
  if(newGroup && Object.keys(newGroup).length > 0){
      if(surgery && surgery.atq && surgery.atq.userID){
        dispatch(addMemberToChatGroup(newGroup.id,surgery.atq.userID))
      }
      if(surgery && surgery.secondaryATQ && surgery.secondaryATQ.userID){
        dispatch(addMemberToChatGroup(newGroup.id,surgery.secondaryATQ.userID))
      }
      if(surgery && surgery.creatorUserId ){
        dispatch(addMemberToChatGroup(newGroup.id,surgery.creatorUserId))
      }
      dispatch(getChatGroup(surgery.id, 0, 10, true));
      setGroupChoseed(newGroup);
  }
}, [newGroup])


 /*  useEffect(() => {
    if(members && members.chatMembers && members.chatMembers.length > 0 && groupChoseed && surgery && props.description){
      dispatch(addMessagesToChatGroup(groupChoseed.id, props.description, surgery.id, null))
    }
  }, [members])
   */





useEffect(() => {
  dispatch(getChatGroup(surgery.id, 0, 10, true));
  return () => {
    dispatch(get_chat_group_success(null));
    dispatch(add_member_from_chat_group_success(null));
    dispatch(create_chat_group_success(null));
    dispatch(add_chat_group_message_success(null));
  }
}, [])


return (
  <div className="c-share">
    <div className="c-share-c-search">
      <form onSubmit={handleSearch} className="share-c-input-search">
        <button className="c-share-search-icobuscar">
          <img src={icoBuscarGris} alt="" />
        </button>
        <input
          type="text"
          placeholder="Buscar..."
          name="search"
          onChange={({ target }) => setSearchInstitution(target.value)}
          value={searchInstitution}
          className="share-input-search"
          autoComplete="off"
        />
      </form>
    </div>
    <div className='txt-Enviar'>
      <span>
        Enviar al Grupo:
      </span>
    </div>

    <form
      className="c-share-c-list-items"
      onSubmit={handleInstitution}
    >
      <div className="c-share-c-list-items-scroll">
        {
          search && search.length > 0

          ?search.map((item) => (
            <div key={item.id} className="c-share-c-item">
              <img className='img-group-chat' src={img_msj_group}/>
              <div>
                <p className="c-share-item-name">{item.chatGroupName}</p>
              </div>
              <input
                className="c-share-item-check"
                type="checkbox"
                checked={atqList.some(el => el.id === item.id)}
                value={item.id}
                name={item.id}
                onChange={() => handleAddOptionalItem(item)}
                isChecked
              />
              </div>
              ))

          
          :group &&
          group.length > 0 &&
          group.map((item) => (
            <div key={item.id} className="c-share-c-item">
              <img className='img-group-chat' src={img_msj_group}/>
              <div>
                <p className="c-share-item-name">{item.chatGroupName}</p>
              </div>
              <input
                className="c-share-item-check"
                type="checkbox"
                checked={atqList.some(el => el.id === item.id)}
                value={item.id}
                name={item.id}
                onChange={() => handleAddOptionalItem(item)}
                isChecked
              />
            </div>
          ))}
      </div>
      <button className="c-share-button" type="submit">
        CONTINUAR
      </button>
    </form>
  </div>
);
    
};
Search_Share.propTypes = {
  toggle: PropTypes.any,
};