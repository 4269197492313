import React, { useRef,useState,useEffect } from 'react';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
// import { storeHomeAvailableMaterialToAddItemsListData } from './storeHomeAvailableMaterialToAddItemsListData';
import './sass/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsStoreHouse, getLocationsStoreHouseSuccess, getProductsStoreHouse, searchLocationsStoreHouseSuccess } from '../../../actions/storeHouseAction';
import PropTypes from 'prop-types';
import { ModalProductsComponents } from '../../shared/Modal/ModalProductsComponents';

export default function StoreHomeAvailableMaterialToAddItemsList({typeList,search}) {

    const [listLocations, setListLocations] = useState(null);
    const [productToModal, setProductToModal] = useState(null);
    const [list, setList] = useState();
    const [currentPage, setPage] = useState(0);
    const offset=10;
    const listLocationsState = useSelector(state => state.storeHouse.listLocations);
    const listProductsState = useSelector(state => state.storeHouse.listProducts);
    const dispatch = useDispatch();

    useEffect(() => {
            /* X */
        return () => {
            getLocationsStoreHouseSuccess(null);
        }
    }, []);

    useEffect(() => {
        if(currentPage > 0){
            setPage(0);
        }
        if(listLocations && listLocations.length > 0){
            setListLocations(null)
        }
        if(search && search.length > 0){
            dispatch(searchLocationsStoreHouseSuccess(null))
        }
    }, [typeList]);

   /*  useEffect(() => {
        if(typeList === 'LOCATIONS'){
            setListLocations(listLocationsState);
        }
        if(typeList === 'PRODUCTS'){
            setListLocations(listProductsState);
        }
    }, [typeList, listLocationsState ,listProductsState]); */


    const handleOpenModalToLocations = () => {
        openModalLocations.current.handleOpen();
    }
    const handleOpenModalToProducts = () => {
        openModalProducts.current.handleOpen();
    }

    useEffect(() => {
      
        if(typeList === 'LOCATIONS' && (!search || search.length === 0)){

            if(listLocations && listLocations.length > 0 && listLocationsState && listLocationsState.length > 0){
                setListLocations([...listLocations,...listLocationsState])
                }else if(listLocationsState && listLocationsState.length > 0){
                    setListLocations(listLocationsState)
                }
        }
        if(typeList === 'PRODUCTS'  && (!search || search.length === 0)){
            if(listLocations && listLocations.length > 0 && listProductsState && listProductsState.length > 0){
                setListLocations([...listLocations,...listProductsState])
                }else if(listProductsState && listProductsState.length > 0){
                    setListLocations(listProductsState)
                }
        }
    }, [listProductsState,listLocationsState])



    useEffect(() => {
           
        if(typeList === 'LOCATIONS' && (!search || search.length === 0)){
            dispatch( getLocationsStoreHouse(currentPage,offset) );
        }
        if(typeList === 'PRODUCTS' && (!search || search.length === 0)){
            dispatch( getProductsStoreHouse(currentPage,offset));
        }
    }, [currentPage])
    
    

     useEffect(() => {
       
        if(search && search.length > 0){
            setListLocations([...search])
        }
    }, [search]) 
    



    const openModalLocations = useRef();
    const openModalProducts = useRef();
    
    //ESTE ES EL HANDLESCROLL 
    const handleScroll = (event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        if((scrollHeight-Math.round(scrollTop) - clientHeight) <= 1){
            setPage(prev => prev + 1)
        }
    }


    return (
        <div className="StoreHomeAvailableMaterialToAddItemsList-container" onScroll={handleScroll}>
        {

             listLocations&&
                listLocations.map( item => (

                <div key={item.id} className="StoreHomeAvailableMaterialToAddItemsList_elements_info_container" onClick={() => setProductToModal(item)}>

                    <div className="StoreHomeAvailableMaterialToAddItemsList_elements_info_elements_container">

                        <span className="StoreHomeAvailableMaterialToAddItemsList_elements_info_ubicacion_container" onClick={typeList === 'LOCATIONS' && handleOpenModalToLocations || typeList === 'PRODUCTS' && handleOpenModalToProducts} >
                            <p className="StoreHomeAvailableMaterialToAddItemsList_elements_info_ubicacion_element">
                                {item.description || `${item.productCode} - ${item.productDescription}` }
                            </p>
                        </span>
                    </div>
                    <img src={black_arrow} alt="arrow" className={'StoreHomeAvailableMaterialToAddItemsList_elements_img_arrow'} onClick={typeList === 'LOCATIONS' && handleOpenModalToLocations || typeList === 'PRODUCTS' && handleOpenModalToProducts} />
                </div>
                ))
            }
        

            <ModalProductsComponents ref={openModalLocations} typeModal={'AddQuantityComponent'} productToModal={productToModal}/>
            <ModalProductsComponents ref={openModalProducts} typeModal={'IndividualProductsScanningComponent'} productToModal={productToModal}/>

        </div>
    )
}
StoreHomeAvailableMaterialToAddItemsList.propTypes = {
    typeList: PropTypes.any,
}
