import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import shortid from 'shortid'
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction'
import { ATQAdministrator_Assign_ATQ_TableUbication_Component } from '../../../components/ATQAdministrator/ATQAdmin_Tranferir_CX/ATQAdministrator_Assign_ATQ_TableUbication_Component'
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { DateFormat, handleGetHour } from '../../../components/shared/DateFormat'
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent'
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse'
import './styleMensajeroMaterial.scss';

export const MensajeroMaterial = () => {

    const setSurgerySelected = useSelector((state) => state.notificationChat.setSurgeryChatSelected);
    const nextSurgery = useSelector((state) => state.storeHouse.nextAppointment);
    const history = useHistory()
    const dispatch = useDispatch()


    //modal
    const [surtido, setSurtido] = useState(null);
    const [noSurtido, setNoSurtido] = useState(null);
    const [listado, setListado] = useState(null)
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const [newProductsReq, setNewProductsReq] = useState(null);

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Institucion',
            value: nextSurgery && nextSurgery[0].hospital.name,
        },
        {
            uid: shortid.generate(),
            title: 'Fecha de CX',
            value: DateFormat(nextSurgery ? nextSurgery[0].surgeryDate : ''),
        },
        {
            uid: shortid.generate(),
            title: 'Hora de CX',
            value: handleGetHour(nextSurgery ? nextSurgery[0].surgeryDate : ''),
        },
        {
            uid: shortid.generate(),
            title: 'Medico',
            value:  nextSurgery && (nextSurgery[0].medic.name && nextSurgery[0].medic.name) + ' ' + (nextSurgery[0].medic.secondName && nextSurgery[0].medic.secondName)
            + ' ' + (nextSurgery[0].medic.firstLastname && nextSurgery[0].medic.firstLastname) + ' ' + (nextSurgery[0].medic.secondLastname && nextSurgery[0].medic.secondLastname),
        },

    ];


    //codigo perteneciente al modal(ubicacion y cantidad)

    useEffect(() => {
      
        if (nextSurgery && nextSurgery[0].id) {
       
            dispatch(getProductsToAppointmentStoreHose(nextSurgery[0].id))
            dispatch(getAppointmentLocationsStoreHose(nextSurgery[0].id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [nextSurgery])


    useEffect(() => {
     
        if (AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
            AppointmentWithLocations.productRequirements.map(item => {
                if ((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)) {
                    arraySurtidos.push(item);
                } else {
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
            setListado([...arraySurtidos, ...arrayNoSurtidos])
        }
    }, [AppointmentWithLocations])




    /*    useEffect(() => {
           console.warn('surtido',surtido)
           console.warn('noSurtido',noSurtido)
       }, [surtido,noSurtido]) */


    useEffect(() => {
        if (AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0) {
            if (nextSurgery[0].productRequirements && nextSurgery[0].productRequirements.length > 0) {
                setNoSurtido([nextSurgery[0].productRequirements && nextSurgery[0].productRequirements]);
            }
        }

    }, [AppointmentLocations, AppointmentProducts])

    useEffect(() => {
        if (AppointmentLocations && nextSurgery && nextSurgery[0]) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, nextSurgery && nextSurgery[0])
        }
    }, [AppointmentLocations])


    useEffect(() => {
        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
            {
                if (!AppointmentWithLocations || AppointmentWithLocations === null) {
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, nextSurgery && nextSurgery[0])));
                }
            }
        }

    }, [newProductsReq])






 

    const handleContinue = () =>{
        history.push('/')
    }
    
   /*  console.log('surtido',surtido)
    console.log('noSurtido',noSurtido)
    console.log('listado',listado) */

  return (
    <div className='notificaciones_mensajero_materialCompleto_container'>

            <SubNavBarComponent title={"Notificaciones"} />

            <div className='notificaciones_mensajero_materialCompleto_subcontainer'>

                <section className='notificaciones_mensajero_materialCompleto_container_left'>
                    <StoreHouseListComponent
                        typeView={{ type: 'STORE', title: `PQX #${setSurgerySelected}` }}
                        listFields={arrayListFields}
                    />

                </section>

                <section className='notificaciones_mensajero_materialCompleto_container_rigth'>
                    <ATQAdministrator_Assign_ATQ_TableUbication_Component
                        typeListTable={"Sistemas"}
                        info={listado && listado} />

                    <div className="notificaciones_button_mensajero_materialCompleto_continuar_ATQ">
                        <button className="notificacion_btn_material_mensajero" onClick={handleContinue}>CONTINUAR</button>
                    </div>

                </section>


            </div>

        </div>
  )
}
