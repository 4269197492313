import React from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import SyncIcon from '@material-ui/icons/Sync';
import CropFreeIcon from '@material-ui/icons/CropFree';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Drawer,
  Button,
  Divider,
  withStyles,
  TablePagination,
  Checkbox
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { RemoveRedEye } from "@material-ui/icons";

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cfdiCatalog from '../../assets/catalogs/cfdi.json'
import regimenCatalog from '../../assets/catalogs/regimen.json'
import SearchBar from "material-ui-search-bar";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import { GetSupplyRequestAsync, setSupplyReceiveAsync } from "../../actions/userActions";


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const Reception = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const requestH = useSelector((state) => state.user.request);
  const loginData = useSelector((state) => state.user.user.identity)

  const [modalStateSave, setModalStateSave] = useState(false)

  /* Tabla Clientes */
  const columnsPedidos = [
    { id: 'oc', label: 'O.C', minWidth: 50 },
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'producto', label: 'PRODUCTO', minWidth: 100 },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: 30 },
    { id: 'recibido', label: 'RECIBIDO', minWidth: 30 },
    { id: 'pendiente', label: 'PENDIENTE', minWidth: 30 }
  ];

  function createDataPedidos(oc, id, producto, cantidad, recibido, pendiente) {
    return { oc, id, producto, cantidad, recibido, pendiente };
  }

  const rowsPedidos = [
    createDataPedidos('G-222222', 'CS 8201-08', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '1', '1', '0'),
    createDataPedidos('B-234567', 'CS 2600-6-45', 'COSMICMIA - TORNILLO D6.5 MM, LONGITUD 45 MM', '50', '15', '0'),
    createDataPedidos('G-654322', '006', 'ADD - D 12 MM, ALTURA 16-25 MM, 6°', '2', '2', '10'),
    createDataPedidos('G-987665', '007', 'DIANA - IMPLANTE 19/18MM, 30MM', '2', '0', '5'),
    createDataPedidos('G-222222', 'CS 8201-08', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '1', '0', '50'),
    createDataPedidos('G-0099888 ', 'CS 2600-6-45', 'COSMICMIA - TORNILLO D6.5 MM, LONGITUD 45 MM', '3', '3', '3'),

  ];

  const [rowsOrders, setRowsOrders] = useState(rowsPedidos);
  const [rowsOrdersOriginal, setRowsOrdersOriginal] = useState(rowsPedidos);
  const [rowsPerPageOrders, setRowsPerPageOrders] = useState(7);
  const [pageOrders, setPageOrders] = useState(0);

  const handleChangePageOrders = (event, newPage) => {
    setPageOrders(newPage);
  };

  const handleChangeRowsPerPageOrders = (event) => {
    setRowsPerPageOrders(+event.target.value);
    setPageOrders(0);
  };


  const [rowsReception, setrowsReception] = useState([]);
  const [rowReceptionSelected, setrowReceptionSelected] = useState();
  const [QuantityReceives, setQuantityReceives] = React.useState('')

  const GetSupplyRequest = async () => {
    await GetSupplyRequestAsync(requestH).then((resp) => {
      setrowsReception(resp.response)
      console.log("REGRESO DE WS -- GetSupplyRequestAsync", resp)
    })
  }

  useEffect(() => {
    GetSupplyRequest();
  }, [])

  const [quantityError, setQuantityError] = useState(false)

  const saveSupplyReceive = async () => {
    if (QuantityReceives) {
      if (QuantityReceives > rowReceptionSelected.quantitySupply) {
        setQuantityError(true)
      } else {
        setQuantityError(false)
        let data = {
          "id": 0,
          "supplyDetailId": rowReceptionSelected.supplyDetailID, //TOMARLO DEL GET
          "quantityReceives": parseInt(QuantityReceives),
          "userId": loginData.userID,
          "dateReceives": " " //SE MANDA VACÍO
        }

        console.log("DATA SET", data)

        await setSupplyReceiveAsync(data, requestH).then((resp) => {
          console.log(resp)
          GetSupplyRequest();
          setModalStateSave(false)
        })
      }
    }
  }

  return (
    <div style={{
      width: '100%',
      height: '100%'
    }}>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={`/remision`} title={`Detalle Pedido`} />
      <div >
        {/* TopBar */}
        <div style={{ display: 'flex', background: 'transparent', marginTop: '16vh' }}>{/*  background: '#f0f0f0', */}
          <div style={{ width: '70%', marginTop: '2rem', justifyContent: 'flex-start', display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', justifyContent: 'flex-start', width: '100%' }}>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Provedor</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>Martin Miranda Juarez</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '90%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px', width: '90%' }}>
                  <SearchBar
                    placeholder={'Buscar'}
                    style={{
                      width: '100%',
                      height: '46px',
                      boxSizing: 'border-box',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div style={{ maxHeight: '60vh', overflow: 'scroll', marginBottom: '8rem' }}>

          {/* Tabla Cliente */}
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columnsPedidos.map((column) => {
                      const id = column.id
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <div style={{ display: 'flex' }}>
                            {id == 'cantidad' && <ImportExportIcon style={{ color: '#484f59' }} />}
                            {column.label}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsReception.map((row) => {
                    return (
                      <TableRow hover style={{ cursor: 'pointer' }} role="checkbox" tabIndex={-1} key={row.code} onClick={() => { setModalStateSave(true); setrowReceptionSelected(row) }}>
                        {columnsPedidos.map((column) => {
                          const type = row.tipo
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}  >
                              {
                                column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                  column.id == 'oc' ? row.purchaseOrderId :
                                    column.id == 'id' ? row.productCode :
                                      column.id == 'producto' ? row.productDescription :
                                        column.id == 'cantidad' ? row.quantitySupply :
                                          column.id == 'recibido' ? row.recibido :
                                            column.id == 'pendiente' ? row.pendiente :
                                              <p>{value}</p>}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 10, 25, 100]}
              component="div"
              count={rowsOrders ? rowsOrders.length : 0}
              rowsPerPage={rowsPerPageOrders}
              page={pageOrders}
              onChangePage={handleChangePageOrders}
              onChangeRowsPerPage={handleChangeRowsPerPageOrders}
            />
          </div>

        </div>

        {/* Surtir Modal */}
        <Dialog
          open={modalStateSave}
          onClose={() => { setModalStateSave(false) }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          className="price-list-dialog"
        >
          <DialogTitle id="form-dialog-title" style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '20px',
            textAlign: 'start',
            color: '#000000'
          }}>Guardar</DialogTitle>
          <DialogContent className="price-list-dialog-content" style={{ display: 'flex', alignContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                <TextField id="outlined-basic" label="Cantidad" variant="outlined" style={{ width: '98%' }} value={QuantityReceives} onChange={(e) => setQuantityReceives(e.target.value)} />
              </div>
              {
                quantityError &&
                <p style={{ color: 'red', marginTop: 20 }}>Estas Ingresando una cantidad mayor a la requerida</p>
              }
            </div>
          </DialogContent>
          <DialogActions className="price-list-dialog-actions">
            <button className="btn" onClick={saveSupplyReceive}>Guardar</button>
          </DialogActions>
        </Dialog>

        {/* <Dialog
          open={modalStateSave}
          onClose={() => { setModalStateSave(false) }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          className="price-list-dialog"
        >
          <DialogTitle id="form-dialog-title" style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '20px',
            textAlign: 'start',
            color: '#000000'
          }}>Guardar</DialogTitle>
          <DialogContent className="price-list-dialog-content" style={{ display: 'flex', alignContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 300,
              fontSize: '20px',
              lineHeight: '23px',
              display: 'flex',
              alignItems: 'start',
              color: '#000000',
            }}>Nombre de usuario</div>
            <div style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 300,
              fontSize: '20px',
              lineHeight: '23px',
              display: 'flex',
              alignItems: 'start',
              color: '#000000',
            }}>Quien Captura</div>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                <TextField id="outlined-basic" label="Fecha" variant="outlined" style={{ width: '49%' }} />
                <TextField id="outlined-basic" label="Num documento" variant="outlined" style={{ width: '49%' }} />
              </div>
            </div>

          </DialogContent>
          <DialogActions className="price-list-dialog-actions">
            <button className="btn">Guardar</button>
          </DialogActions>
        </Dialog> */}

        <div style={{
          width: '100%',
          height: '10vh',
          position: 'fixed',
          bottom: '0',
          alignSelf: 'center',
          zIndex: 1
        }}>
          <div style={{
            background: '#e5e9f2',
            height: '6vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }} >
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>


            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'end' }}>
              {/* <div style={{
                marginTop: 10, marginRight: 80,
                background: 'linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%)',
                color: '#fff',
                minWidth: '20%',
                maxWidth: '30%',
                height: '40%',
                padding: '.5vw',
                cursor: 'pointer',
                border: 'none',
                borderRadius: '2vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }} onClick={() => { setModalStateSave(true) }}>
                <p style={{ fontFamily: 'arial', fontWeight: 'bold' }} onClick={saveSupplyReceive}>GUARDAR</p>
              </div> */}
            </div>

          </div>
        </div>
      </div >
    </div >
  );
};
