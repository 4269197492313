import React from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import SyncIcon from '@material-ui/icons/Sync';
import CropFreeIcon from '@material-ui/icons/CropFree';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TablePagination,
  Button,
  Checkbox
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';

import { GetRemissionsPendingAynsc, getShippingAsync } from "../../actions/userActions";

import { useState } from "react";
import { NavLink } from "react-router-dom";

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { getOrders } from "../../actions/userActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SearchBar from "material-ui-search-bar";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoomIcon from '@material-ui/icons/Room';

import { getOfficeParcel, updateShippingRemission } from "../../actions/allUsersAction";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const Shipment = (props) => {
  const classes = useStyles();
  const requestH = useSelector((state) => state.user.request);
  const [showBy, setShowBy] = React.useState('pendingShipment'); /* Mostrar Por Variable */
  const [searchBy, setSearchBy] = React.useState('destination'); /* Buscar Por Variable */
  const [officeParcel, setOfficeParcel] = useState([]); /* Paqueterías */
  const [selectedOfParcel, setSelectedOfParcel] = useState();
  const [SelectedLink, setSelectedLink] = useState();
  const [SelectedComment, setSelectedComment] = useState();

  const [value, setValue] = useState("");
  const [error, setError] = useState(false); //if inputs are empty
  const [error2, setError2] = useState(false); //for the select

  /* START Search Bar Section */
  const [searchedValuesGeneral, setSearchedValuesGeneral] = useState("");
  const requestSearch = (searchedVal) => {
  };
  const cancelSearch = () => {
  };
  /* END Search Bar Section */

  /* START PENDING SHIPMENTS */
  const columnPendingShipments = [
    { id: 'select', label: '', minWidth: 100 },
    { id: 'remision', label: 'REMISION', minWidth: 100 },
    { id: 'deliveryDate', label: 'INICIAL', minWidth: 100 },
    { id: 'pactada', label: 'PACTADA', minWidth: 170 },
    // { id: 'paqueteria', label: 'PAQUETERIA', minWidth: 170 },
    { id: 'contactId', label: 'DATOS CONTACTO', minWidth: 170 },
    { id: 'address', label: 'DOMICILIO DE ENTREGA', minWidth: 170 },
    { id: 'observaciones', label: 'OBSERVACIONES', minWidth: 170 },
  ];

  function createDataPendingShipments(select, remision, inicial, pactada, paqueteria, contacto, domicilio, observaciones) {
    return { select, remision, inicial, pactada, paqueteria, contacto, domicilio, observaciones };
  }

  const rowsPendingShipmentsData = [
    createDataPendingShipments('', '202229560', '12/SEP/2022', '19/SEP/2022', '-', 'Carlos Gonzales Coronel', 'Aguas calientes rep. de Urugay 912...', 'La Caja debe de llegar sin abotyones')
  ];

  const [rowsRemissionPeding, setRowsRemissionPeding] = useState([])
  const [rowsPeding, setPeding] = useState([])
  const [rowsPendingShipments, setRowsPendingShipments] = useState(rowsPendingShipmentsData);
  const [rowsPendingShipmentsOriginal, setRowsPendingShipmentsOriginal] = useState(rowsPendingShipmentsData);
  const [rowsPerPagePendingShipments, setRowsPerPagePendingShipments] = useState(7);
  const [pagePendingShipments, setPagePendingShipments] = useState(0);

  const handleChangePagePendingShipments = (event, newPage) => {
    setPagePendingShipments(newPage);
  };

  const handleChangeRowsPerPagePendingShipments = (event) => {
    setRowsPerPagePendingShipments(+event.target.value);
    setPagePendingShipments(0);
  };
  /* END PENDING SHIPMENTS */

  /* START Sent SHIPMENTS */
  const columnSentShipments = [
    { id: 'select', label: '', minWidth: 100 },
    { id: 'folio', label: 'FOLIO', minWidth: 100 },
    { id: 'remision', label: 'REMISION', minWidth: 100 },
    { id: 'salida', label: 'SALIDA ALMACEN', minWidth: 170 },
    { id: 'cliente', label: 'CLIENTE', minWidth: 170 },
    { id: 'destino', label: 'DESTINO', minWidth: 170 },
    // { id: 'envios', label: 'ENVIOS', minWidth: 170 },
    { id: 'paqueteria', label: 'PAQUETERIA', minWidth: 170 },
    { id: 'icon', label: '', minWidth: 30 },
  ];

  function createDataSentShipments(select, folio, remision, salida, cliente, destino, envios, paqueteria, icon) {
    return { select, folio, remision, salida, cliente, destino, envios, paqueteria, icon };
  }

  const rowsSentShipmentsData = [
    createDataSentShipments('', '202229560', '56789', '12/08/2022 12:54', 'Martina Colores', 'Aguas calientes rep. de Urugay 912...', 'Envio #1', 'Pendiente', true),
    createDataSentShipments('', '202229560', '98776', '12/08/2022 12:54', 'Martina Colores', 'Aguas calientes rep. de Urugay 912...', 'Envio #2', 'Paquete express', false),
    createDataSentShipments('', '202229560', '644678  ', '12/08/2022 12:54', 'Carmela Torres', '20 de Noviembre 153000 Ciudad de México', 'Envio #1', 'DHL', false)
  ];

  const [rowsSentShipments, setRowsSentShipments] = useState(rowsSentShipmentsData);
  const [rowsSentShipmentsOriginal, setRowsSentShipmentsOriginal] = useState(rowsSentShipmentsData);
  const [rowsPerPageSentShipments, setRowsPerPageSentShipments] = useState(7);
  const [pageSentShipments, setPageSentShipments] = useState(0);

  const handleChangePageSentShipments = (event, newPage) => {
    setPageSentShipments(newPage);
  };

  const handleChangeRowsPerPageSentShipments = (event) => {
    setRowsPerPageSentShipments(+event.target.value);
    setPageSentShipments(0);
  };

  const getRemissionPed = async () => {
    await GetRemissionsPendingAynsc(1, requestH).then((resp) => {
      setRowsRemissionPeding(resp.response)
      console.log("REGRESO DE WS", resp)
    })
  }

  const getShip = async () => {
    await getShippingAsync(requestH).then((resp) => {
      setPeding(resp.response)
      console.log("REGRESO DE SHIPPING", resp)
    })
  }

  useEffect(() => {
    getRemissionPed()
    getShip()
  }, []);

  const [arraySelected, setArraySelected] = useState([]);
  const [arraySelectedEnv, setArraySelectedEnv] = useState([]);

  const onClickSelect = (e, objectToInsert, type) => {
    console.log("MI EVENTO---", e.target.checked)
    console.log("MI OBJETO", objectToInsert)
    console.log("CHECK ???", type)
    const newArray = JSON.parse(JSON.stringify(arraySelected));

    if (e.target.checked) {
      newArray.push(objectToInsert);
    } else {
      newArray.splice(objectToInsert, 1)
    }

    setArraySelected(newArray);

  };

  const onClickSelectEnv = (e, objectToInsert, type) => {

    const newArray = JSON.parse(JSON.stringify(arraySelectedEnv));

    if (e.target.checked) {
      newArray.push(objectToInsert);
    } else {
      newArray.splice(objectToInsert, 1)
    }

    setArraySelectedEnv(newArray);

  };

  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [openerror, setOpenError] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // DIALOG DE EXITO
  const handleClickOpenConfirm = () => {
    setOpenconfirm(true);
    setTrackingModal(false);

  };

  const handleCloseconfirm = () => {
    setOpenconfirm(false);
    setTrackingModal(false);
    props.history.push({ pathname: '/' });
  };

  // DIALOG DE ERROR
  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };


  const onSaveRowsSelected = async () => {
    if (arraySelected.length > 0) {
      console.log("EJECUTANDO ARRAY DE ENVIOS PENDIENTES")
      console.log("ARREGLO QUE MANDARÉ-->", arraySelected)
      for (let x = 0; x < arraySelected.length; x++) {
        let count = 1
        let data = {
          Id: arraySelected[x].id,
          TotvsId: arraySelected[x].totvsId,
          UserId: arraySelected[x].userId,
          Status: arraySelected[x].status,
          RemissionDate: arraySelected[x].remissionDate,
          OrderId: arraySelected[x].orderId,
          DeliveryId: selectedOfParcel,
          DeliveryGuide: value,
          DeliveryURL: SelectedLink,
          DeliveryMessage: SelectedComment,
          orders: null,
          delivery: null
        };
        await updateShippingRemission(data, requestH).then(resp => {
          if (resp.sucess) {
            count++
            handleClickOpenConfirm()
          } else {
            handleClickError()
          }
          if (count == arraySelected.length) {
            handleClickOpenConfirm()
          }
        });
      }
      setArraySelected([])
    }
    if (arraySelectedEnv.length > 0) {
      console.log("EJECUTANDO ARRAY DE ENVIADOS")
      console.log("ARREGLO QUE MANDARÉ-->", arraySelectedEnv)
      for (let x = 0; x < arraySelectedEnv.length; x++) {
        let count = 1
        let data = {
          Id: arraySelectedEnv[x].id,
          TotvsId: arraySelectedEnv[x].totvsId,
          UserId: arraySelectedEnv[x].userId,
          Status: arraySelectedEnv[x].status,
          RemissionDate: arraySelectedEnv[x].remissionDate,
          OrderId: arraySelectedEnv[x].orderId,
          DeliveryId: selectedOfParcel,
          DeliveryGuide: value,
          DeliveryURL: SelectedLink,
          DeliveryMessage: SelectedComment,
          orders: null,
          delivery: null
        };
        await updateShippingRemission(data, requestH).then(resp => {
          if (resp.sucess) {
            count++
            handleClickOpenConfirm()
          } else {
            handleClickError()
          }
          if (count == arraySelected.length) {
            handleClickOpenConfirm()
          }
        });
      }
      setArraySelectedEnv([])
    }
  }



  /* END Sent SHIPMENTS */

  /* START DESTINATION SHIPMENTS */
  const columnDestinationShipments = [
    { id: 'select', label: '', minWidth: 100 },
    { id: 'remision', label: 'REMISION', minWidth: 100 },
    { id: 'pactada', label: 'PACTADA', minWidth: 100 },
    { id: 'paqueteria', label: 'Paqueteria Splash', minWidth: 170 },
    { id: 'contacto', label: 'DATOS CONTACTO', minWidth: 170 },
    { id: 'domicilio', label: 'DOMICILIO DE ENTREGA', minWidth: 170 },
    { id: 'observaciones', label: 'OBSERVACIONES', minWidth: 170 },
  ];

  function createDataDestinationShipments(select, remision, pactada, paqueteria, contacto, domicilio, observaciones) {
    return { select, remision, pactada, paqueteria, contacto, domicilio, observaciones };
  }

  const rowsDestinationShipmentsData = [
    createDataDestinationShipments('', '202229560', '12/SEP/2022', '19/SEP/2022', '-', '-', '', '')
  ];

  const [rowsDestinationShipments, setRowsDestinationShipments] = useState(rowsDestinationShipmentsData);
  const [rowsDestinationShipmentsOriginal, setRowsDestinationShipmentsOriginal] = useState(rowsDestinationShipmentsData);
  const [rowsPerPageDestinationShipments, setRowsPerPageDestinationShipments] = useState(7);
  const [pageDestinationShipments, setPageDestinationShipments] = useState(0);

  const handleChangePageDestinationShipments = (event, newPage) => {
    setPageDestinationShipments(newPage);
  };

  const handleChangeRowsPerPageDestinationShipments = (event) => {
    setRowsPerPageDestinationShipments(+event.target.value);
    setPageDestinationShipments(0);
  };
  /* END Destination SHIPMENTS */

  const [trackingModal, setTrackingModal] = useState(false);

  /* OFFICE PARCEL MENU */
  const getOfficesParcel = async () => {
    await getOfficeParcel(0, 20, requestH).then((resp) => {
      setOfficeParcel(resp.response)
      console.log("PAQUETERIAS : ", resp)
    })
  }

  useEffect(() => {
    getOfficesParcel()
  }, [])

  /*  */


  return (
    <div style={{
      width: '100%',
      paddingTop: '16vh',
      height: '100%'
    }}>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={`/`} title={`ENVIOS`} />
      <div >

        {/* TopBar */}
        <div style={{
          background: '#F0F0F0',
          display: 'flex'
        }}>
          <div style={{ width: '70%' }}>
            <div style={{ marginTop: '20px', marginLeft: '50px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Filtro</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '35%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                  <FormControl variant="outlined" className={classes.formControl} style={{
                    width: '100%',
                    height: '46px',
                    boxSizing: 'border-box',
                    background: '#FFFFFF',
                    borderRadius: '4px'
                  }}>
                    <InputLabel id="demo-simple-select-outlined-label">Mostrar por</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Mostrar por"
                      style={{ height: '46px' }}
                      value={showBy}
                      onChange={(e) => { setShowBy(e.target.value) }}
                    >
                      <MenuItem value={'destination'} disabled style={{ display: 'none' }}>Mostrar Por</MenuItem>
                      <MenuItem value={'origin'} disabled style={{ display: 'none' }}>Mostrar Por</MenuItem>
                      <MenuItem value={'pendingShipment'}>Envios Pendientes</MenuItem>
                      <MenuItem value={'sentShipments'}>Enviados</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* <div style={{ width: '35%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                  <FormControl variant="outlined" className={classes.formControl} style={{
                    width: '100%',
                    height: '46px',
                    boxSizing: 'border-box',
                    background: '#FFFFFF',
                    borderRadius: '4px'
                  }}>
                    <InputLabel id="demo-simple-select-outlined-label">Buscar Por</InputLabel>
                    <Select
                      style={{ height: '46px' }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Buscar Por"
                      value={showBy}
                      onChange={(e) => { setShowBy(e.target.value) }}
                    >
                      <MenuItem value={'pendingShipment'} disabled style={{ display: 'none' }}>Buscar Por</MenuItem>
                      <MenuItem value={'sentShipments'} disabled style={{ display: 'none' }}>Buscar Por</MenuItem>
                      <MenuItem value={'destination'} >Destinos</MenuItem>
                      <MenuItem value={'origin'}>Origen</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div> */}
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '90%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px', width: '90%' }}>
                  <SearchBar
                    value={searchedValuesGeneral}
                    onChange={(searchVal) => { requestSearch(searchVal) }}
                    onCancelSearch={() => cancelSearch()}
                    placeholder={'Buscar'}
                    style={{
                      width: '100%',
                      height: '46px',
                      boxSizing: 'border-box',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div style={{ maxHeight: '60vh', overflow: 'scroll', marginBottom: '8rem' }}>
          {/* Tabla Envios Pendientes */}
          {showBy == 'pendingShipment' &&
            <div style={{ marginTop: 50, margin: '40px auto 80px auto', width: '90%', position: 'relative' }}>

              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Envios Pendientes</p>
              <div>
                {rowsRemissionPeding && rowsRemissionPeding.map((row) => {
                  let ordenes = row.ordenes;
                  // console.log("ORDENES ------",ordenes)
                  return (<Accordion style={{ background: '#D9D9D9', borderRadius: '4px', marginBottom: '10px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', lineHeight: '17px', color: '#484F59' }}>Cliente</Typography>
                        <Typography style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 400, fontSize: '18px', lineHeight: '17px', color: '#000000' }}>{row.clientName}</Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ background: 'white' }}>
                      <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columnPendingShipments.map((column) => {
                                // console.log('column: ', column)
                                const id = column.id
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    <div style={{ display: 'flex' }}>
                                      {id == 'select'}
                                      {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                                      {column.label}
                                    </div>
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ordenes.map(subrow => {
                              let rem = subrow.remisiones
                              console.log(subrow.dinVariable)
                              return (
                                rem.map(remrow => {
                                  // console.log("REMISIONES: --->",remrow)
                                  return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={remrow.code}>
                                      {columnPendingShipments.map((column) => {
                                        const type = subrow.tipo
                                        const value = subrow[column.id];
                                        const valueC = subrow[column.id];
                                        // console.log(value)
                                        return (
                                          <TableCell key={column.id} align={column.align}>
                                            {
                                              column.id == 'select' ? <Checkbox checked={remrow.checked} onChange={e => { onClickSelect(e, remrow, remrow.checked) }} value={remrow} style={{ color: 'black' }} /> :
                                                column.id == 'remision' ? <NavLink to={{ pathname: `/shipmentDetails`, state: { remission: remrow } }} >
                                                  <p style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>{remrow.id}</p>
                                                </NavLink> :
                                                  column.id == 'deliveryDate' ? remrow.remissionDate.split('T')[0] :
                                                    column.id == 'pactada' ? subrow.deliveryDate.split('T')[0] :
                                                      // column.id == 'paqueteria' ? remrow.delivery.name:
                                                      column.id == 'contactId' ? subrow.dinVariable :
                                                        column.id == 'address' ? subrow.address :
                                                          column.id == 'observaciones' ? remrow.deliveryMessage :
                                                            value}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>)
                })}
              </div>
            </div>
          }

          {/* Tabla ENVIADOS */}
          {showBy == 'sentShipments' &&
            <div style={{ marginTop: 50, margin: '40px auto 80px auto', width: '90%', position: 'relative' }}>

              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Enviados</p>

              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnSentShipments.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select'}
                              {id != 'select' && id != 'remision' && id != 'icon' && <ImportExportIcon style={{ color: '#484f59' }} />}
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsPeding.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnSentShipments.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {
                                  column.id == 'select' ? '' :
                                    column.id == 'folio' ? <p>{row.orderId}</p> :
                                      column.id == 'remision' ? <p>{row.id}</p> :
                                        column.id == 'salida' ? <p>{row.remissionDate.split('T')[0]}</p> :
                                          column.id == 'cliente' ? <p>{row.orders.cliente.clientName}</p> :
                                            column.id == 'destino' ? <p>{row.orders.cliente.address}</p> :
                                              column.id == 'paqueteria' ? <p>{row.delivery.name || 'Pendiente'}</p> :
                                                column.id == 'icon' && value ? <RoomIcon style={{ color: '#484f59' }} onClick={() => { setTrackingModal(true) }} /> :
                                                  column.id == 'folio' ? <NavLink to={`/shipmentDetails`} style={{ color: 'black', fontWeight: 'bold' }}>{value}</NavLink> :
                                                    value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          }

          {/* Tabla Envios Pendientes */}
          {showBy == 'destination' &&
            <div style={{ marginTop: 50, margin: '40px auto 80px auto', width: '90%', position: 'relative' }}>

              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Envios (condicion) - Destino</p>

              <Accordion style={{ background: '#D9D9D9', borderRadius: '4px', marginBottom: '10px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', lineHeight: '17px', color: '#484F59' }}>Destino</Typography>
                    <Typography style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 400, fontSize: '18px', lineHeight: '17px', color: '#000000' }}>Aguas calientes rep. de Urugay 912...E</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ background: 'white' }}>
                  <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columnDestinationShipments.map((column) => {
                            console.log('column: ', column)
                            const id = column.id
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                <div style={{ display: 'flex' }}>
                                  {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                                  {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                                  {column.label}
                                </div>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsDestinationShipments && rowsDestinationShipments.slice(pageDestinationShipments * rowsPerPageDestinationShipments, pageDestinationShipments * rowsPerPageDestinationShipments + rowsPerPageDestinationShipments).map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                              {columnDestinationShipments.map((column) => {
                                const type = row.tipo
                                const value = row[column.id];
                                console.log(row)
                                console.log(column)
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

            </div>
          }

          {/* Tabla Envios Pendientes */}
          {showBy == 'origin' &&
            <div style={{ marginTop: 50, margin: '40px auto 80px auto', width: '90%', position: 'relative' }}>

              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Envio(condicion) / Origen</p>

              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnSentShipments.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                              {id != 'select' && id != 'remision' && id != 'icon' && <ImportExportIcon style={{ color: '#484f59' }} />}
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsSentShipments && rowsSentShipments.slice(pageSentShipments * rowsPerPageSentShipments, pageSentShipments * rowsPerPageSentShipments + rowsPerPageSentShipments).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnSentShipments.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                  column.id == 'paqueteria' ? <p style={{ color: value == 'Pendiente' ? 'red' : 'black' }}>{value}</p> :
                                    column.id == 'icon' && value ? <RoomIcon style={{ color: '#484f59' }} /> :
                                      column.id == 'folio' ? <NavLink to={`/shipmentDetails`} style={{ color: 'black', fontWeight: 'bold' }}>{value}</NavLink> :
                                        value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          }
        </div>


        <Dialog
          open={trackingModal}
          onClose={() => { setTrackingModal(false) }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          className="price-list-dialog"
        >
          <DialogTitle id="form-dialog-title" style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '20px',
            textAlign: 'start',
            color: '#000000'
          }}>Agregar Guia</DialogTitle>
          <DialogContent className="price-list-dialog-content" style={{ display: 'flex', alignContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 300,
              fontSize: '20px',
              lineHeight: '23px',
              display: 'flex',
              alignItems: 'start',
              color: '#000000',
            }}>Agrega los datos requeridos</div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                <TextField
                  id="outlined-required"
                  label="# Guia"
                  variant="outlined"
                  style={{ width: '49%' }}
                  required
                  onChange={(e) => { setValue(e.target.value), setError(false) }}
                  error={error} />
                <TextField id="outlined-basic" label="Link" onChange={(e) => { setSelectedLink(e.target.value) }} variant="outlined" style={{ width: '49%' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 10 }}>
                <FormControl variant="outlined" className={classes.formControl} style={{
                  width: '49%',
                  boxSizing: 'border-box',
                  background: '#FFFFFF',
                  borderRadius: '4px'
                }}>
                  <InputLabel id="labelParcels">Paquetería *</InputLabel>
                  <Select
                    labelId="labelParcels"
                    id="demo-simple-select-outlined"
                    label="Paquetería *"
                    value={selectedOfParcel}
                    onChange={(e) => { setSelectedOfParcel(e.target.value), setError2(false) }}
                    error={error2}
                  >
                    <MenuItem value={''} disabled> Paquetería *</MenuItem>
                    {officeParcel && officeParcel.map((e) => (
                      <MenuItem value={e.id} key={e.id}> {e.name} </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField id="outlined-basic" label="Comentario" onChange={(e) => { setSelectedComment(e.target.value) }} variant="outlined" style={{ width: '49%' }} />
              </div>
            </div>

          </DialogContent>
          <DialogActions className="price-list-dialog-actions">
            <button className="btn" onClick={() => { setTrackingModal(false) }}>Cancelar</button>
            <button className="btn" onClick={() => {
              (value == "" || !selectedOfParcel) ?
                (setError(true), setError2(true)) :
                (setError(false), setError2(false), onSaveRowsSelected())
            }}>Agregar</button>
          </DialogActions>
        </Dialog>
        {/* DIALOG DE QUE SE HIZO EL MOVIMIENTO - ÉXITO*/}
        <Dialog
          fullScreen={fullScreen}
          open={openconfirm}
          onClose={handleCloseconfirm}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Notificación de cambio de Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se han ejecutado los cambios con éxito.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseconfirm} color="primary" autoFocus>
              Cerrar y continuar
            </Button>
          </DialogActions>
        </Dialog>

        {/* DIALOG DE QUE SE HIZO EL MOVIMIENTO - ERROR*/}
        <Dialog
          fullScreen={fullScreen}
          open={openerror}
          onClose={handleCloseError}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Notificación de cambio de Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ha ocurrido un error, intentalo más tarde.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError} color="primary" autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Bottom Bar */}
        <div style={{
          width: '100%',
          height: '9vh',
          position: 'fixed',
          bottom: '0',
          alignSelf: 'center'
        }}>
          <div style={{
            background: 'linear-gradient(90deg, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 1) 94%)',
            height: '6vh',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }} >
            <div className="bottomBarBtn" onClick={() => { setTrackingModal(true) }}>
              REGISTRO SALIDA DE ALMACEN
            </div>


          </div>
        </div>

      </div >
    </div >
  );
};
