import React, { useEffect, useState } from 'react';
import { FactoryConfigurationSystemTab } from './FactoryConfigurationSystemTab';
import './sass/styles.sass';
import PropTypes from 'prop-types';
import { postAddShippingLocationToSystem } from '../../../../actions/systemsAction';
import { useDispatch } from 'react-redux';

export const FactoryConfiguratonSurgeryTab = (props) => {
    const dispatch = useDispatch();

    const { system, newConfig, configuration, setSystem } = props;

    const [activeTab, setActiveTab] = useState('SYSTEM');
    const [activeCard, setActiveCard] = useState('0')



    useEffect(() => {
        if (Object.values(configuration).length === 0) {
            setActiveTab('SYSTEM');
        }
    }, [configuration])

    useEffect(() => {
        setActiveCard(props.system.product.shippinglocation)
    }, [system])

    const handleChangeTab = (type) => {
        setActiveTab(type);
    }

    const AddShippingExcel = () => {
        dispatch(postAddShippingLocationToSystem(system.id, 1));
        setActiveCard(1)
    }

    const DeleteShippingCard = () => {
        dispatch(postAddShippingLocationToSystem(system.id, 0));
        setActiveCard(0)
    }
    return (
        <div className="container-factoryConfigurationSecundaryTabs" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                <h3 className='factoryConfigCard-info-sistem-title' style={{ fontSize: 25 }}>Selecciona el tipo de plantilla:</h3>
                <div className="factoryConfigurationSecundaryTabs-container-tabs" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '50%' }}>
                    <button className={activeCard == '0'
                        ? 'factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-sistem secundary-tab-active'
                        : 'factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-sistem'}
                        style={{ width: '45%' }} onClick={() => { DeleteShippingCard() }}>
                        PLANTILLA CARD
                    </button>
                    <button className={activeCard == '1'
                        ? "factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-config secundary-tab-active"
                        : "factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-config"}
                        style={{ width: '45%' }} onClick={() => { AddShippingExcel() }}>
                        PLANTILLA EXCEL
                    </button>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row', height: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '30%' }}>
                    <div className="factoryConfigurationSecundaryTabs-container-tabs" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                        <button className={activeTab === 'SYSTEM'
                            ? 'factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-sistem secundary-tab-active'
                            : 'factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-sistem'}
                            onClick={() => handleChangeTab('SYSTEM')}
                            style={{ width: '90%' }}>
                            SISTEMA PARA:
                        </button>
                        <button className={activeTab === 'CONFIG'
                            ? "factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-config secundary-tab-active"
                            : "factoryConfigurationSecundaryTabs-tab factoryConfigurationSecundaryTabs-config"}
                            onClick={() => handleChangeTab('CONFIG')} disabled={Object.values(configuration).length !== 0 ? false : true} style={{ width: '90%' }}>
                            CONFIGURACIÓN PARA:
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '70%' }}>
                    <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', marginTop: 150 }}>
                        {
                            activeTab === 'SYSTEM' &&
                            <div className="factoryConfigurationSecundaryTabs-tab-system">
                                <FactoryConfigurationSystemTab
                                    system={system}
                                    configuration={configuration}
                                    activeTab={activeTab}
                                    newConfig={newConfig}
                                    setNewConfig={props.setNewConfig}
                                    setSystem={setSystem}
                                />
                            </div>
                        }
                        {
                            activeTab === 'CONFIG' &&
                            <div className="factoryConfigurationSecundaryTabs-tab-system">
                                <FactoryConfigurationSystemTab
                                    system={system}
                                    configuration={configuration}
                                    activeTab={activeTab}
                                    setNewConfig={props.setNewConfig}
                                    setConfiguration={props.setConfiguration}
                                    newConfig={newConfig}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>



        </div>

    )
}

FactoryConfiguratonSurgeryTab.propTypes = {
    system: PropTypes.any,
    newConfig: PropTypes.any,
    configuration: PropTypes.any,
    setNewConfig: PropTypes.any,
    setConfiguration: PropTypes.any,
    setSystem: PropTypes.any,
};
