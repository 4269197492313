import {

    GET_SURVEY_BEGIN,
    GET_SURVEY_SUCCESS,
    GET_SURVEY_FAILURE,
    SURVEY_ATQ_BEGIN,
    SURVEY_ATQ_SUCCESS,
    SURVEY_ATQ_FAILURE,
    SURVEY_ADMIN_ATQ_BEGIN,
    SURVEY_ADMIN_ATQ_SUCCESS,
    SURVEY_ADMIN_ATQ_FAILURE,
    SURVEY_CLASIFICATION_BEGIN,
    SURVEY_CLASIFICATION_SUCCESS,
    SURVEY_CLASIFICATION_FAILURE,
    SURVEY_QUALITY_MANAGER_BEGIN,
    SURVEY_QUALITY_MANAGER_SUCCESS,
    SURVEY_QUALITY_MANAGER_FAILURE,
    SURVEY_FIRST_CLASIFICATION_BEGIN,
    SURVEY_FIRST_CLASIFICATION_SUCCESS,
    SURVEY_FIRST_CLASIFICATION_FAILURE,
    SURVEY_MAINTENANCE_BEGIN,
    SURVEY_MAINTENANCE_SUCCESS,
    SURVEY_MAINTENANCE_FAILURE,
    SURVEY_MEDIC_BEGIN,
    SURVEY_MEDIC_SUCCESS,
    SURVEY_MEDIC_FAILURE,
    SURVEY_SALES_BEGIN,
    SURVEY_SALES_SUCCESS,
    SURVEY_SALES_FAILURE,
    SURVEY_SNC_BEGIN,
    SURVEY_SNC_SUCCESS,
    SURVEY_SNC_FAILURE,
    SURVEY_ISSUE_CAUSE_BEGIN,
    SURVEY_ISSUE_CAUSE_SUCCESS,
    SURVEY_ISSUE_CAUSE_FAILURE,
    SURVEY_STATUS_BEGIN,
    SURVEY_STATUS_SUCCESS,
    SURVEY_STATUS_FAILURE,
    SURVEY_WAREHOUSE_BEGIN,
    SURVEY_WAREHOUSE_SUCCESS,
    SURVEY_WAREHOUSE_FAILURE,
    SURVEY_PENDING_SURVEY_BEGIN,
    SURVEY_PENDING_SURVEEY_SUCCESS,
    SURVEY_PENDING_SURVEEY_FAILURE,
    SURVEY_SET_SURVEY_INFORMATION_BEGIN,
    SURVEY_SET_SURVEY_INFORMATION_SUCCESS,
    SURVEY_SET_SURVEY_INFORMATION_FAILURE,
    SET_SURVEY_COMMENTARY_ATQ_BEGIN,
    SET_SURVEY_COMMENTARY_ATQ_SUCCESS,
    SET_SURVEY_COMMENTARY_ATQ_FAILURE,
    SET_SURVEY_COMMENTARY_SELLER_BEGIN,
    SET_SURVEY_COMMENTARY_SELLER_SUCCESS,
    SET_SURVEY_COMMENTARY_SELLER_FAILURE,
    SET_SURVEY_COMMENTARY_MAINTENANCE_BEGIN,
    SET_SURVEY_COMMENTARY_MAINTENANCE_SUCCESS,
    SET_SURVEY_COMMENTARY_MAINTENANCE_FAILURE,
    SET_SURVEY_COMMENTARY_ADMIN_ATQ_BEGIN,
    SET_SURVEY_COMMENTARY_ADMIN_ATQ_SUCCESS,
    SET_SURVEY_COMMENTARY_ADMIN_ATQ_FAILURE,
    SET_SURVEY_COMMENTARY_WAREHOUSE_BEGIN,
    SET_SURVEY_COMMENTARY_WAREHOUSE_SUCCESS,
    SET_SURVEY_COMMENTARY_WAREHOUSE_FAILURE,
    SET_SURVEY_SELECTED,
    GET_SURVEY_FROM_ID_BEGIN,
    GET_SURVEY_FROM_ID_SUCCESS,
    GET_SURVEY_FROM_ID_FAILURE,
    UPDATE_PENDING_ACTION_BEGIN,
    UPDATE_PENDING_ACTION_SUCCESS,
    UPDATE_PENDING_ACTION_FAILURE,
    SET_SURVEY_COMMENTARY_QUALITY_BEGIN,
    SET_SURVEY_COMMENTARY_QUALITY_SUCCESS,
    SET_SURVEY_COMMENTARY_QUALITY_FAILURE,
    SEARCH_SURVEY_TO_SURGERY_ID_BEGIN,
    SEARCH_SURVEY_TO_SURGERY_ID_SUCCESS,
    SEARCH_SURVEY_TO_SURGERY_ID_FAILURE,
    GET_SURVEY_TO_SEARCH_BEGIN,
    GET_SURVEY_TO_SEARCH_SUCCESS,
    GET_SURVEY_TO_SEARCH_FAILURE,
    SET_SURVEY_COMMENTARY_DIRECTORY_BEGIN,
    SET_SURVEY_COMMENTARY_DIRECTORY_SUCCESS,
    SET_SURVEY_COMMENTARY_DIRECTORY_FAILURE,                                                                    

} from '../constants/actionTypes';
import initialState from './initialState';

export function surveyReducer(state = initialState.survey, action) {
    switch (action.type) {

        case SET_SURVEY_COMMENTARY_DIRECTORY_BEGIN:
            return state;
        case SET_SURVEY_COMMENTARY_DIRECTORY_SUCCESS: {
            return {
                ...state,
                commentaryDirectory: action.payload.response
            }
        }
        case SET_SURVEY_COMMENTARY_DIRECTORY_FAILURE:
             return state;
        //------------------------------------------------

        case GET_SURVEY_TO_SEARCH_BEGIN:
            return state;
        case GET_SURVEY_TO_SEARCH_SUCCESS: {
            return {
                ...state,
                searchSurvey: action.payload.getSurveySearch
            }
        }
        case GET_SURVEY_TO_SEARCH_FAILURE:
             return state;
        //------------------------------------------------
        case SEARCH_SURVEY_TO_SURGERY_ID_BEGIN:
            return state;
        case SEARCH_SURVEY_TO_SURGERY_ID_SUCCESS: {
            return {
                ...state,
                search: action.payload.response
            }
        }
        case SEARCH_SURVEY_TO_SURGERY_ID_FAILURE:
             return state;
        //------------------------------------------------
        case SET_SURVEY_COMMENTARY_QUALITY_BEGIN:
            return state;
        case SET_SURVEY_COMMENTARY_QUALITY_SUCCESS: {
            return {
                ...state,
                newCommentaryQuantity: action.payload.response
            }
        }
        case SET_SURVEY_COMMENTARY_QUALITY_FAILURE:
             return state;
        //------------------------------------------------
        case UPDATE_PENDING_ACTION_BEGIN:
            return state;
        case UPDATE_PENDING_ACTION_SUCCESS: {
            return {
                ...state,
                updateAction: action.payload.response
            }
        }
        case UPDATE_PENDING_ACTION_FAILURE:
             return state;
        //------------------------------------------------
        case SET_SURVEY_COMMENTARY_WAREHOUSE_BEGIN:
            return state;
        case SET_SURVEY_COMMENTARY_WAREHOUSE_SUCCESS: {
            return {
                ...state,
                setCommentaryWareHouse: action.payload.response
            }
        }
        case SET_SURVEY_COMMENTARY_WAREHOUSE_FAILURE:
             return state;
        //------------------------------------------------
        case SET_SURVEY_COMMENTARY_ADMIN_ATQ_BEGIN:
            return state;
        case SET_SURVEY_COMMENTARY_ADMIN_ATQ_SUCCESS: {
            return {
                ...state,
                setCommentaryAdminATQ: action.payload.response
            }
        }
        case SET_SURVEY_COMMENTARY_ADMIN_ATQ_FAILURE:
             return state;
        //------------------------------------------------
        case SET_SURVEY_COMMENTARY_MAINTENANCE_BEGIN:
            return state;
        case SET_SURVEY_COMMENTARY_MAINTENANCE_SUCCESS: {
            return {
                ...state,
                setCommentaryMaintenance: action.payload.response
            }
        }
        case SET_SURVEY_COMMENTARY_MAINTENANCE_FAILURE:
             return state;
        //------------------------------------------------
        case SET_SURVEY_COMMENTARY_SELLER_BEGIN:
            return state;
        case SET_SURVEY_COMMENTARY_SELLER_SUCCESS: {
            return {
                ...state,
                setCommentarySeller: action.payload.response
            }
        }
        case SET_SURVEY_COMMENTARY_SELLER_FAILURE:
             return state;
        //------------------------------------------------
        
        case SET_SURVEY_COMMENTARY_ATQ_BEGIN:
            return state;
        case SET_SURVEY_COMMENTARY_ATQ_SUCCESS: {
            return {
                ...state,
                setCommentaryATQ: action.payload.response
            }
        }
        case SET_SURVEY_COMMENTARY_ATQ_FAILURE:
             return state;
        //------------------------------------------------
        case SET_SURVEY_SELECTED:{
            return{
                ...state,
                setSurveyAppoimentSelected: action.payload.response
            }
        }

        //-------------------------------------------------
        case SURVEY_SET_SURVEY_INFORMATION_BEGIN:
            return state;
        case SURVEY_SET_SURVEY_INFORMATION_SUCCESS: {
            return {
                ...state,
                setInformation: action.payload.response
            }
        }
        case SURVEY_SET_SURVEY_INFORMATION_FAILURE:
             return state;
        //-------------------------------------------------
        case SURVEY_PENDING_SURVEY_BEGIN:
            return state;
        case SURVEY_PENDING_SURVEEY_SUCCESS: {
            return {
                ...state,
                surveyPending: action.payload.response
            }
        }
        case SURVEY_PENDING_SURVEEY_FAILURE:
             return state;
        //-------------------------------------------------
        case SURVEY_WAREHOUSE_BEGIN:
            return state;
        case SURVEY_WAREHOUSE_SUCCESS: {
            return {
                ...state,
                surveyWareHouse: action.payload.response
            }
        }
        case SURVEY_WAREHOUSE_FAILURE:
             return state;
        //-------------------------------------------------
        case SURVEY_STATUS_BEGIN:
            return state;
        case SURVEY_STATUS_SUCCESS: {
            return {
                ...state,
                surveyStatus: action.payload.response
            }
        }
        case SURVEY_STATUS_FAILURE:
             return state;
        //-------------------------------------------------
        case SURVEY_ISSUE_CAUSE_BEGIN:
            return state;
        case SURVEY_ISSUE_CAUSE_SUCCESS: {
            return {
                ...state,
                surveyIssueCause: action.payload.response
            }
        }
        case SURVEY_ISSUE_CAUSE_FAILURE:
            return state;
        //-------------------------------------------------
        case SURVEY_SNC_BEGIN:
            return state;
        case SURVEY_SNC_SUCCESS: {
            return {
                ...state,
                surveySNC: action.payload.response
            }
        }
        case SURVEY_SNC_FAILURE:
            return state;
        //-------------------------------------------------
        case SURVEY_SALES_BEGIN:
            return state;
        case SURVEY_SALES_SUCCESS: {
            return {
                ...state,
                surveySales: action.payload.response
            }
        }
        case SURVEY_SALES_FAILURE:
            return state;
        //-------------------------------------------------
        case SURVEY_MEDIC_BEGIN:
            return state;
        case SURVEY_MEDIC_SUCCESS: {
            return {
                ...state,
                surveyMedic: action.payload.response
            }
        }
        case SURVEY_MEDIC_FAILURE:
            return state;
        //-------------------------------------------------
        case SURVEY_MAINTENANCE_BEGIN:
            return state;
        case SURVEY_MAINTENANCE_SUCCESS: {
            return {
                ...state,
                surveyMaintenance: action.payload.response
            }
        }
        case SURVEY_MAINTENANCE_FAILURE:
            return state;
        //-------------------------------------------------
        case SURVEY_FIRST_CLASIFICATION_BEGIN:
            return state;
        case SURVEY_FIRST_CLASIFICATION_SUCCESS: {
            return {
                ...state,
                firstClasification: action.payload.response
            }
        }
        case SURVEY_FIRST_CLASIFICATION_FAILURE:
            return state;
        //-------------------------------------------------
        case SURVEY_QUALITY_MANAGER_BEGIN:
            return state;
        case SURVEY_QUALITY_MANAGER_SUCCESS: {
            return {
                ...state,
                qualityManager: action.payload.response
            }
        }
        case SURVEY_QUALITY_MANAGER_FAILURE:
            return state;

        //-------------------------------------------------
        case SURVEY_CLASIFICATION_BEGIN:
            return state;
        case SURVEY_CLASIFICATION_SUCCESS: {
            return {
                ...state,
                clasification: action.payload.response
            }
        }
        case SURVEY_CLASIFICATION_FAILURE:
            return state;
        //-------------------------------------------------

        case GET_SURVEY_BEGIN:
            return state;
        case GET_SURVEY_SUCCESS: {
            return {
                ...state,
                getSurvey: action.payload.getSurvey
            }
        }
        case GET_SURVEY_FAILURE:
            return state;

        //SurveyATQ
        case SURVEY_ATQ_BEGIN:
            return state;
        case SURVEY_ATQ_SUCCESS: {
            return {
                ...state,
                surveyATQ: action.payload.surveyATQ
            }
        }
        case SURVEY_ATQ_FAILURE:
            return state;
        //SurveyAdminATQ
        case SURVEY_ADMIN_ATQ_BEGIN:
            return state;
        case SURVEY_ADMIN_ATQ_SUCCESS: {
            return {
                ...state,
                surveyATQAdmin: action.payload.surveyATQAdmin
            }
        }
        case SURVEY_ADMIN_ATQ_FAILURE:
            return state;

            
            case GET_SURVEY_FROM_ID_BEGIN:
                return state;
            case GET_SURVEY_FROM_ID_SUCCESS: {
                return {
                    ...state,
                    getSearchSurvey: action.payload.getSurveyId
                }
            }
            case GET_SURVEY_FROM_ID_FAILURE:
                return state;

        
        default:
            return state;
    }
}
