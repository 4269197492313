
import shortid from "shortid";
import imgNeon from '../../assets/img/512PX/Productos/Cervicales/Img_Neon_512px.png';
import imgAdd from '../../assets/img/512PX/Productos/Cervicales/Img_Add&AddPlus_512px.png';
import imgOsmium from '../../assets/img/512PX/Productos/Cervicales/Img_Osmium_512px.png';
import imgNubic from '../../assets/img/512PX/Productos/Cervicales/Img_Nubic_512px.png';
import imgPeek from '../../assets/img/512PX/Productos/Cervicales/Img_RabeaPeek_512px.png';
import imgTitanio from '../../assets/img/512PX/Productos/Cervicales/Img_RabeaTitanio_512px.png';

export const QuotesCompletedData = [
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgNeon,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgAdd,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgOsmium,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgNubic,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgPeek,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgTitanio,
    },
]

export const QuotesPendingData = [
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgOsmium,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgNubic,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgPeek,
    },
    {
        id: shortid.generate(),
        name: 'Neon-Add plus',
        date: 'dd/mm/aaaa',
        atn: 'Leonardo Oliva',
        paciente: 'Jefry Federico Flores',
        institucion: 'Hospital Real San José',
        img: imgTitanio,
    },
]