import React from 'react'
import CustomModal from '../../shared/Modal';
import { ATQ_Modal } from '../ATQ_PRODUCTS/Modal/ATQ_Modal';
import { ModalATQ } from './ModalATQ';
import PropTypes from 'prop-types';
import './styleModal.scss';
import ModalBlue from '../ModalBlue/ModalBlue';

export const Modal_Comp = (props) => {
    const modalBody = () => {
        return (
            <ModalATQ 
            toggle={props.toggle}
            page={props.page}
                                />
        );
    };

    return (
        <ModalBlue
                isOpen={props.isOpen}
                toggle={props.toggle} 
                title={props.name}
                body={modalBody()}
        />
    );
}
Modal_Comp.PropTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};


