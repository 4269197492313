import React, { useEffect } from 'react'
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import "./sass/styles.scss";
import groupATQ from "../../../assets/img/512PX/GroupATQ.png";
import groupRol from "../../../assets/img/512PX/GroupRol.png";
import groupDays from "../../../assets/img/512PX/GroupDays.png";
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {  getHolidaysSuccess, getTeamGroupSuccess } from '../../../actions/atqAdministradorAction';

export const ATQ_GuardRole_View = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleChangeView = (typeSelection) => {
        typeSelection === "CREATE_TEAM" &&
        history.replace("ATQ_GuardRole_View/ATQ_GuardRole_CreateTeam_View");
        typeSelection === "CREATE_GUARD_DAYS" &&
        history.replace("ATQ/ATQ_GuardDays_View");
        typeSelection === "ASSIGN_ROLE" &&
        history.replace("ATQ/ATQ_Administrator_GuardRol_View");
    }

    useEffect(() => {
    
        dispatch(getHolidaysSuccess(null))
        dispatch(getTeamGroupSuccess(null)); 
      
    }, [])
    

    return (
        <div className="ATQ_GuardRole_View-container">
            <SubNavBarComponent searchActive={false} historyPage={"/Inicio"} title={"ROL DE GUARDÍAS"}/>
            <div className="ATQ_GuardRole_View-items-menu-container">
                <div className="ATQ_GuardRole_View_container_item" onClick={() => handleChangeView("CREATE_TEAM")}>
                    <img src={groupATQ} alt="" className={"ATQ_GuardRole_View_item_img"} />
                    <h3 className={'ATQ_GuardRole_View_item_title'}>Crear / ver equipos de guardia</h3>
                </div>
                <div className="ATQ_GuardRole_View_container_item" onClick={() => handleChangeView("CREATE_GUARD_DAYS")} >
                    <h3 className={'ATQ_GuardRole_View_item_title'}>Seleccionar / Ver días de guardia</h3>
                    <img src={groupRol} alt="" className={"ATQ_GuardRole_View_item_img"} />
                </div>
                <div className="ATQ_GuardRole_View_container_item" onClick={() => handleChangeView("ASSIGN_ROLE")}>
                    <img src={groupDays} alt="" className={"ATQ_GuardRole_View_item_img"}  />
                    <h3 className={'ATQ_GuardRole_View_item_title'}>Asignar / Ver rol de guardias</h3>
                </div>
                <div className="ATQ_GuardRole_View_container_item">
                    <button className={"ATQ_GuardRole_View_container_item_button"} onClick={() => history.replace("ATQAdministrator_atq_availability")}>
                        DISPONIBILIDAD DE ATQ’S
                    </button>
                </div>
            </div>
        </div>
        
    )
}
