import React from 'react';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './styles.scss';

export const ATQ_Consum_Report_List_Com = ({activeSwitch}) => {
    const history = useHistory();
    const handleNivagate = (id) => {
        // if(activeSwitch === "INACTIVE"){
        //     history.replace('/ATQAdministrator_Materials_History/Material_Info');
        // }
        if( activeSwitch === "ACTIVE" ){
            history.replace(`/ATQ_Inicio/Rep_Con/Report/ACTIVO/${id}`);
        }
        if( activeSwitch === "INACTIVE" ){
            history.replace(`/ATQ_Inicio/Rep_Con/Report/INACTIVO/${id}`);
        }
    };

    const repExp = [
        {
            date: '10/09/2021',
            hour: '11:00 hrs',
            doctor: 'Dr. Daniel Ríos',
            hospital: 'Hospital San Javier',
            idCX:'15',
            
        },
        {
            date: '11/09/2021',
            hour: '14:00 hrs',
            doctor: 'Dr. Daniel Ríos',
            hospital: 'Hospital San Javier',
            idCX:'16',
        
        },
        {
            date: '12/09/2021',
            hour: '18:00 hrs',
            doctor: 'Dr. Jorge Blanco',
            hospital: 'Hospital Real San José',
            idCX:'17',
        
        },
        {
            date: '13/09/2021',
            hour: '11:00 hrs',
            doctor: 'Dr. Jorge Blanco',
            hospital: 'Hospital Real San José',
            idCX:'18',
            
        },
        {
            date: '13/09/2021',
            hour: '14:00 hrs',
            doctor: 'Dr. Jorge Blanco',
            hospital: 'Centro Médico de Occidente',
            idCX:'19',
        
        },
        {
            date: '14/09/2021',
            hour: '18:00 hrs',
            doctor: 'Dr. Jorge Blanco',
            hospital: 'Centro Médico de Occidente',
            idCX:'20',
        
        },
    ];
    return (
        <div className="preconter_list_rep_com" >
        {
            repExp.map( item => (
            <div className="content_info_rep_com" key={item.idCX} onClick={() => handleNivagate(item.idCX)}>
                <div className="text_info_rep_com">
                    <span className="info_date_com">
                        { item.date + ' - ' + item.hour + ' - ' + item.doctor}
                    </span>
                    <span className="info_date_com">
                        <p>
                        { item.hospital + ' - ' + 'CX#' + item.idCX}
                        </p>
                    </span>
                </div>
                    
                    <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                
            </div>
            ))
        }
        </div>
    )
}


ATQ_Consum_Report_List_Com.propTypes = {
    activeSwitch: PropTypes.any,

}

