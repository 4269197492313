import React, { useState } from 'react';
import { Add_Talk_Group_Modal_Chat } from '../../../ChatComponent/ModalChat/Add_Talk_Group_Modal_Chat';
import { ChatConversation } from '../../../ChatConversation/ChatConversation';
import { ModalConversation } from '../../../ChatConversation/ModalConversation';
import './sass/styles.sass';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

export const FooterComponent = () => {

    const [chatConvesation, setChatConversation] = useState(false)
    return (

        <>

            {/* <ModalConversation/> */}

            <div className='c-footer'>
                <h3 className='c-footer-copyright'> © Copyright TSI 2024 - Todos los derechos reservados TSI 2024 </h3>
            </div>

        </>

    )
}
