import React,{useState}from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../../../components/shared/Modal';
import './style_Analisis.scss';
import { QualityAnalisisModal } from '../../../components/QualityManager/QualityAnalisisModal';


export const Quality_Modal_Analisis = () => {
    
    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const modalBody = () => {
        return (
        <QualityAnalisisModal     
            toggle={toggle}
            isOpen={isOpen}
        />
    );
    };

    return (
        <CustomModal
                isOpen={isOpen}
                toggle={toggle} 
                body={modalBody()}
        />
    );
}

Quality_Modal_Analisis.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};