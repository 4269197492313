import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMainSurgeryTypeStoreHouse, getSystemByMainSurgencyTypeStoreHouse } from '../../../actions/storeHouseAction';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHomeAbailableSystemsItems } from '../../../components/StoreHouseComponents/StoreHomeAbailableSystemsComponents/StoreHomeAbailableSystemsItems';

export const ATQ_Products_Page = () => {
    const dispatch = useDispatch();

    const mainSurgeryTypes = useSelector( state => state.storeHouse.mainSurgeryTypes);
    const quoteProductsData = useSelector( state => state.storeHouse.systems);
    const [cervicales, setCervicales] = useState(null);
    const [lumbares, setLumbares] = useState(null);
    const [complementos, setComplementos] = useState(null);
    const [toraxico, setToraxico] = useState(null);

    const [existMainSurgeryTypes, setExistMainSurgeryTypes] = useState(null);

    useEffect(() => {
        if(mainSurgeryTypes) {
            setExistMainSurgeryTypes(mainSurgeryTypes);
        }
    }, [mainSurgeryTypes])

    useEffect( () => {
        if(mainSurgeryTypes && mainSurgeryTypes.length > 0) {
            mainSurgeryTypes.map(item => dispatch(getSystemByMainSurgencyTypeStoreHouse(item)));
            // dispatch(getProducts());
        } else if( !existMainSurgeryTypes ){
            dispatch(getMainSurgeryTypeStoreHouse());
            // dispatch(getProducts());
        }
    },[mainSurgeryTypes]);


    useEffect( () =>{
        //Cambiar 3 y 4 +  todos
        if(quoteProductsData && Object.keys(quoteProductsData).length >= 3 && Object.keys(quoteProductsData).length < 4) {
            // Pedimos el 5 Elemento
        } else if (quoteProductsData && Object.keys(quoteProductsData).length >= 4) {
            setCervicales(quoteProductsData['CERVICAL']);
            setLumbares(quoteProductsData['LUMBAR']);
            setComplementos(quoteProductsData['COMPLEMENTOS']);
            setToraxico(quoteProductsData['TORACICO']);
        }
    },[quoteProductsData]);


    return (
        <div className='animate__animated animate__fadeIn'>
            <SubNavBarComponent title={'INFORMACION DE PRODUCTOS'} historyPage={`/ATQ_Inicio`} searchActive={true} />

            <div className='c-StoreHomeAbailableSystemsScreen'>
                <div >
                    {
                        cervicales && cervicales.length > 0 &&
                            <StoreHomeAbailableSystemsItems 
                                mainSurgeryType={'CERVICAL'}
                                mainSurgeryTypeListItems={cervicales}
                                mainSurgeryTypeTitle={'Soluciones Cervicales'}
                                page={'ATQ'}
                            />
                    }

                    {
                        lumbares && lumbares.length > 0 &&
                        <StoreHomeAbailableSystemsItems 
                            mainSurgeryType={'LUMBAR'}
                            mainSurgeryTypeListItems={lumbares}
                            mainSurgeryTypeTitle={'Soluciones Lumbares '}
                            page={'ATQ'}
                        />
                    }
                    {
                        complementos && complementos.length > 0 &&
                        <StoreHomeAbailableSystemsItems 
                            mainSurgeryType={'COMPLEMENTOS'}
                            mainSurgeryTypeListItems={complementos}
                            mainSurgeryTypeTitle={'Torácicos'}
                            page={'ATQ'}
                        />
                    }
                    {
                        toraxico && toraxico.length > 0 &&
                        <StoreHomeAbailableSystemsItems 
                            mainSurgeryType={'TORACICO'}
                            mainSurgeryTypeListItems={toraxico}
                            mainSurgeryTypeTitle={'Complementos'}
                            page={'ATQ'}
                        />
                    }
                </div>
            </div>
        </div>
    )
}