import React from 'react';
import './sass/styles.sass';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, Checkbox, Snackbar, Button } from '@material-ui/core';
import { setBundleToVM, downloadQR } from '../../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { encryptData } from '../../../utils/encrypt.service';
//import QrCode2Icon from '@mui/icons-material/QrCode2';


export const FactoryConfigurationCardComponent = (props) => {
    const requestH = useSelector((state) => state.user.request);
    const [checkBundleVM, setCheckBundleVM] = React.useState(false);
    const [serviceMessage, setServiceMessage] = React.useState("")
    const [showAlert, setAlert] = React.useState(false);
    const [serviceErrorMessage, setServiceErrorMessage] = React.useState("")
    const [showErrorAlert, setErrorAlert] = React.useState(false);

    const handleChangeBundleVM = async (event)  => {
        console.log("CHECKBOX: ", event.target.checked)
         if(event.target.checked == true)
        {
            await setBundleToVM(requestH, props.system.product.id, 1).then((res) => {
                if (res.sucess)
                {
                    setServiceMessage("PAQUETE GUARDADO CON ÉXITO")
                    setAlert(true);
                } 
                else
                {
                    setServiceErrorMessage(res.errorMessage)
                    setErrorAlert(true);
                }
            })
        }
        else
        {
            await setBundleToVM(requestH, props.system.product.id, 0).then((res) => {
                if (res.sucess)
                {
                    setServiceMessage("PAQUETE ELIMINADO CON ÉXITO")
                    setAlert(true);
                } 
                else
                {
                    setServiceErrorMessage(res.errorMessage)
                    setErrorAlert(true);
                }
            })
        }
        
    }

    const getQr = async () => {
        const newData = encryptData(JSON.stringify({ "code": 201, "Id": props.configuration.id }))

        await downloadQR({ "palabra": newData }, requestH).then(resp => {
            if (resp.valido && resp.valido === 1) {
                setTimeout(() => {
                    window.open(resp.url, "_blank")
                }, 3000);
            }
            else
            {
                setServiceErrorMessage(res.errorMessage)
                setErrorAlert(true);
            }
        });
    }

    const closeAlert = (event) => {
        setAlert(false);
    }

    const closeErrorAlert = () => {
        setErrorAlert(false);
    }

    return (
        <div className="container-factoryConfigCard">
            <div className="factoryConfigCard-container-structure">
                <div className="factoryConfigCard-container-img">
                {
                    props.system.product &&
                        <img className='factoryConfigCard-img' src={props.system.product.productImage} alt=""/>
                }
                </div>
                <section className="factoryConfigCard-container-info">
                    {
                        props.configuration.configuration && props.configuration.configuration.name &&
                        <div style={{fontSize: '1.1w', display: 'flex', justifyContent: 'space-between', width: '90%'}}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={props.system.product.isForVM} onChange={handleChangeBundleVM}/>} label="Venta móvil"/>
                            </FormGroup>

                            <Button 
                                onClick={getQr}
                                style={{background: 'linear-gradient(270deg, rgb(9, 64, 112) 0%, rgba(55,167,185,1) 91%)', borderRadius: '50px', padding: '20px 20px',
                                        fontFamily: 'Roboto', fontSize: '20px', textTransform: 'uppercase', color: '#FFFFFF'}}>DESCARGAR QR</Button>
                        </div>
                    }
                    {
                        props.system.product &&
                            <div className="factoryConfigCard-info-container-sistem">
                                <h3 className='factoryConfigCard-info-sistem-title'>
                                    Sistema:
                                </h3> 
                                <span className='factoryConfigCard-info-sistem-info'>
                                    {props.system.product.productDescription}
                                </span>
                            </div>

                    }
                    {
                        props.configuration.configuration&&
                        props.configuration.configuration.name&&
                            <div className="factoryConfigCard-info-container-config">
                                <h3 className='factoryConfigCard-info-config-title'>
                                    Configuración:
                                </h3> 
                                <span className='factoryConfigCard-info-config-info'>
                                    {props.configuration.configuration.name}
                                </span>
                            </div>
                    }
                </section>
            </div>

            {/* SNACKBARS */}
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={closeAlert} anchorOrigin={{vertical: 'center', horizontal: 'right'}} >
                <Alert severity='success'>{serviceMessage}</Alert>
            </Snackbar>

            <Snackbar open={showErrorAlert} autoHideDuration={5000} onClose={closeErrorAlert} anchorOrigin={{vertical: 'center', horizontal: 'right'}} >
                <Alert severity='error'>{serviceErrorMessage}</Alert>
            </Snackbar>
        </div>
    )
}

FactoryConfigurationCardComponent.propTypes = {
    system:        PropTypes.any,
    configuration: PropTypes.any,
};
