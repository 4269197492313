import React from 'react'
import { NavLink } from 'react-router-dom';
import icoAgenda from '../../assets/img/512PX/Icono_Agenda_512.png';
import icoEncuesta from '../../assets/img/512PX/Icono_Encuestra_512.png';
import './styleScreenMantenimiento.scss';

export const HomeSreenMantenimientoPage = () => {


    return (

        <div className='principal-container-mantenimiento'>
            <div className='post-container-manteniiento'>
                <div className='primer-fila-mantenimiento'>


                    <NavLink className='agenda-manteminiemto'
                        to={'/agenda/agenda'}>
                        <img src={icoAgenda} alt="" className='img-agenda-mantenimiento' />
                        <h3 className='h3-agenda-mantenimiento'> Agenda </h3>
                    </NavLink>

                    {/* <button className='report-consum'>
                    <img src={icoEncuesta} alt="" className='img-reporte-consumo' />
                    <h3 className='report'>Reporte de consumo</h3>
                </button>  */}


                    <NavLink className='encuestas-mantenimiento'
                    to={'/Survey_Mantenimiento'}>
                    <img src={icoEncuesta} alt="" className='img-encuestas-mantenimiento' />
                    <h3 className='h3-encuestas-mantenimiento'>Encuestas</h3>
                </NavLink> 

                    {/* <button className='encuestas-mantenimiento'>
                        <img src={icoEncuesta} alt="" className='img-encuestas-mantenimiento' />
                        <h3 className='h3-encuestas-mantenimiento'>Encuestas</h3>
                    </button> */}

                </div>
            </div>
        </div>
    )
}
