import React from 'react'
import CustomModal from '../../../shared/Modal';
import { ATQ_Body_Modal_Img } from './ATQ_Body_Modal_Img';
import PropTypes from 'prop-types';

export const ATQ_Modal_with_Img = (props) => {
    const modalBody = () => {
        return (
        <ATQ_Body_Modal_Img    
            toggle={props.toggle}
            text1={props.text1} 
            text2={props.text2} 
            type={props.type}

        />
    );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle} 
                body={modalBody()}
        />
    );
}

ATQ_Modal_with_Img.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};

