import icoCervicales from '../../../assets/img/126PX/Icono_Cervical-Bco_126px.png';
import icoComplementos from '../../../assets/img/126PX/Icono_Complemento-Bco_126px.png';
import icoLumbares from '../../../assets/img/126PX/Icono_Lumbar-Bco_126px.png';
import imgToraxicoBl from '../../../assets/img/126PX/Icono_Toracica-Bco_126px.png';


export const propertiesMainSurgeryType = {
    CERVICAL: {
        img: icoCervicales,
    },
    LUMBAR: {
        img: icoComplementos,
    },
    COMPLEMENTOS: {
        img: icoLumbares,
    },
    TORACICO: {
        img: imgToraxicoBl,
    },
}