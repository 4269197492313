/* eslint-disable import/no-named-as-default */
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

// import NotFoundPage from "./components/NotFoundPage";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AppBar from './components/AppBar';
import { routes } from './Routers';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

import Login from './pages/LogIn/Login';
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { Signup } from "./pages/SignUp/SignUp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SurveyMedic } from "./pages/SurveyMedic/SurveyMedic";
import _ from "lodash";






class App extends React.Component {
  
  
  
  
  render() {
  
    // const isMyNotification = () => {
    //   if (this.props.sender && this.props.sender.userID === this.props.user && this.props.user.userID) {
    //     return true;
    //   }
    //     return false;
    // }

    // console.warn('sender', this.props.sender.userID)

    // console.warn('user', this.props.user.userID)

 

    // console.warn('type',isMyNotification())


    const CustomRoute = ({ exact, path = null, Component, layout = null, index, authenticatedAccess = false }) => {
      return (
        <Route key={index} exact={exact} path={`/${path}`} render={(routeProps) => {

          if (authenticatedAccess && !this.props.isAuthenticated) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: routeProps.location },
                }}
              />
            );
          }
          else if (layout) {
            return (
              <AppBar>
                <Component {...routeProps} />
              </AppBar>
            );
          } else {
            return (
              <Route key={index} exact={exact} path={`/${path}`} component={Component} />
            );
          }
        }} />
      );
    }


    return (
      <div>
      

          {/* {

            isMyNotification() &&
            ''
            
          } */}

          <ToastContainer />




        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path={`/password/:token`} component={ResetPassword} />
          <Route exact path={`/medico`} component={SurveyMedic} />
          {
            this.props.roleUserPermissions.identity &&
            routes(
              {
                permissions: this.props.roleUserPermissions.identity && this.props.roleUserPermissions.identity.permissions,
                role: this.props.roleUser.identity && this.props.roleUser.identity.roleID,
                isSideBarLinks: false
              }).map((r, index) => CustomRoute({ ...r, index }))
          }
          <Redirect to={'/'} />
          {/* <Route component={NotFoundPage} /> */}
        </Switch>

      </div>
    );
  }

}

App.propTypes = {
  isAuthenticated: PropTypes.any,
  roleUser: PropTypes.any,
  roleUserPermissions: PropTypes.any,

};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
    roleUser: state.user.user,
    roleUserPermissions: state.user.user,
    // sender:(state,'notificationChat.addMessages.sender',null),
    // user: (state, 'user.user.identity',null),
  };
}

export default withRouter(connect(mapStateToProps)(App));

//export default hot(module)(App); //

