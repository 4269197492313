import React, { useEffect, useRef, useState } from 'react';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { getSurvey, getSurveySuccess, setSurveySelected, surveySearchATQSuccess } from '../../../actions/surveyAction';

export const ATQ_List_Encuesta_Com = ({ activeSwitch,setActiveSwitch,setFrom,from}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const render = useRef(0);
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState(null);
    const survey = useSelector(state => state.survey.getSurvey);
    const listSearch = useSelector(state => state.survey.getSearchSurvey)
    const user = useSelector(state => state.user.user)

    const handleNivagate = (id, item) => {
        dispatch(setSurveySelected({ ...item }))
        if (activeSwitch === "ACTIVE") {
            history.replace(`/ATQ_Inicio/Encuesta/ACTIVE/${id}`);
        } if (activeSwitch !== "ACTIVE") {
            history.replace(`/ATQ_Inicio/Encuesta/INACTIVE/${id}`);
        }
    };

    useEffect(() => {
        return () => {
            dispatch(getSurveySuccess(null));
            dispatch(surveySearchATQSuccess(null));
        }
    }, [])


    useEffect(() => {
        if(currentPage !== null && from === 'dad'){
            if (currentPage === 0) {
                if (activeSwitch === "ACTIVE") {
                    dispatch(getSurvey(2, 5, currentPage, 10))
                }
                if (activeSwitch === "INACTIVE") {
                    dispatch(getSurvey(5, 5, currentPage, 10))
                }

            } else if (currentPage > 0 && survey !== null) {
                if (activeSwitch === "ACTIVE") {
                    dispatch(getSurvey(2, 5, currentPage, 10))
                } if (activeSwitch === "INACTIVE") {
                    dispatch(getSurvey(5, 5, currentPage, 10))
                }
            }
        }else{
            setPage(0);
        }
    }, [currentPage])



    // ESTE ES PARA USAR EL LIST 
    useEffect(() => {

        if (list && list.length > 0 && survey.length > 0) {
            setList([...list, ...survey])
        } else if (survey && survey.length > 0) {
            setList(survey)
        }

    }, [survey])




    useEffect(() => {

        if (render.current > 0) {
            setList(null);
            setPage(0);
            dispatch(getSurveySuccess(null));
            if(from === 'dad'){
                dispatch(surveySearchATQSuccess(null))
            }
            if(currentPage === 0){
                setPage(null)
            }
        } else {
            setList(null);
            render.current = 1;
        }
    }, [activeSwitch])



    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }


    useEffect(() => {
        if(listSearch && listSearch.length > 0){
            setFrom('child')
            let atq = listSearch[0].surgeryAppointment && listSearch[0].surgeryAppointment.atq && listSearch[0].surgeryAppointment.atq.atqCode ? listSearch[0].surgeryAppointment.atq.atqCode : "0"
            if(user.identity.atqCode === atq){
                if(listSearch[0].surveyStatus.status === 2){
                    setActiveSwitch('INACTIVE')
                }else{
                    setActiveSwitch('ACTIVE')
                }
            }else{
                dispatch(surveySearchATQSuccess([]))
            }
        }
    }, [listSearch])




    return (
        <div className="preconter_list_encuesta" onScroll={handleScroll} >


            {
                !listSearch ?
                    (survey &&
                        list && list.length > 0 &&
                        list.map(item => (
                            <div className="content_info_encuesta" key={item.surgeryAppointment.id} onClick={() => handleNivagate(item.surgeryAppointment.id, item)}>
                                <div className="text_info_encuesta" >
                                    <span className="info_date_encuesta">
                                        {item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) +
                                            ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' hrs' +
                                            ' - ' + ' Dr. ' + (item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                            (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                            (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                            (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)}
                                    </span>
                                    <span className="info_date_encuesta">
                                        <p>
                                            {(item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name) + ' - '
                                                + 'CX# ' + (item.surgeryAppointment.id && item.surgeryAppointment.id)}
                                        </p>
                                    </span>
                                </div>

                                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />

                            </div>
                        )))
                    :
                    (
                        listSearch &&
                        listSearch.map(item => (
                            <div className="content_info_encuesta" key={item.surgeryAppointment.id} onClick={() => handleNivagate(item.surgeryAppointment.id, item)}>
                                <div className="text_info_encuesta" >
                                    <span className="info_date_encuesta">
                                        {item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) +
                                            ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' hrs' +
                                            ' - ' + ' Dr. ' + (item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                            (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                            (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                            (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)}
                                    </span>
                                    <span className="info_date_encuesta">
                                        <p>
                                            {(item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name) + ' - '
                                                + 'CX# ' + (item.surgeryAppointment.id && item.surgeryAppointment.id)}
                                        </p>
                                    </span>
                                </div>

                                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />

                            </div>
                        ))

                    )

            }
        </div>
    )
}


ATQ_List_Encuesta_Com.propTypes = {
    activeSwitch: PropTypes.any,

}



