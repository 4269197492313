import React from 'react'
import { NavLink } from 'react-router-dom';
import './styleHomeDiretori.sass';
import icoListPrecio from '../../assets/img/512PX/ico_list_precios.png';
import icoAnalisisCX from '../../assets/img/512PX/ico_Analisis_Cx.png';
import icoDisMat from '../../assets/img/512PX/Icono_DisponibilidadATQ_512.png';
import icoRolGuardia from '../../assets/img/512PX/Icono_RolGuardia_512.png';
import icoEncuesta from '../../assets/img/512PX/Icono_Encuestra_512.png';
import reporteExperiencia from '../../assets/img/512PX/report_experience512.png';
import icoAgenda from '../../assets/img/512PX/Icono_Agenda_512.png';
import Icono_Estatus_CX from '../../assets/img/512PX/Icono_Estatus_CX.png';
import { useDispatch } from 'react-redux';
import { getDriveAccess } from '../../actions/systemsAction';

export const Home_Directorio_Page = () => {
    const dispatch = useDispatch();

    return (

        <div className="precontainer_atqHome">
            {/*  ESTA ES LA PRIMERA SECCION  */}
            <div className="container_mod_rigth animate__animated animate__fadeIn">
                <div className="container-sec section-1-atq">

                    <button className='atq-item-simpleform atq-item atq-historial'
                    onClick={() => dispatch(getDriveAccess())}
                    >
                        <img src={icoListPrecio} alt="" className='atq-historial-img' />
                        <h3 className='atq-item-title'>Lista de Precios</h3>

                    </button>


                    {/* <button className='atq-item-simpleform atq-item atq-historial'>
                    <img src={icoHistorialMaterial} alt="" className='atq-historial-img'/>
                    <h3 className='atq-item-title'> Historial de Material </h3>
                </button>  */}

                    {/* <NavLink className='atq-item-horizontalform atq-item atq-disponibilidad'
                        to={''}
                    >
                        <img src={icoDisMat} alt="" className='atq-disponibilidad-img' />
                        <h3 className='atq-item-title'> -__ </h3>

                    </NavLink> */}
                    <NavLink className='atq-item-horizontalform atq-item atq-disponibilidad'
                        to={'/Inicio'}
                    >
                        <img src={icoDisMat} alt="" className='atq-disponibilidad-img' />
                        <h3 className='atq-item-title'> -__ </h3>

                    </NavLink>
                    {/* <NavLink className='atq-item-horizontalform atq-item atq-asignar'
                        to={'/Inicio'}
                    >
                        <img src={icoAnalisisCX} alt="" className='atq-asignar-img' />
                        <h3 className='atq-item-title'>Analisis de CX</h3>

                    </NavLink> */}
                    <button className='atq-item-horizontalform atq-item atq-asignar disabled'
                        disabled
                    >
                        <img src={icoAnalisisCX} alt="" className='atq-asignar-img' />
                        <h3 className='atq-item-title'>Analisis de CX</h3>

                    </button>
                    <NavLink className='atq-item-simpleform atq-item atq-rol-guardias'
                        to={'/Inicio'}
                    >
                        <img src={icoRolGuardia} alt="" className='atq-rol-guardia-img' />
                        <h3 className='atq-item-title'>? </h3>

                    </NavLink>
                </div>

                {/* /* ESTA ES LA SEGUNDA SECCION */}



                {/* ESTA ES LA SEGUNDA SECCION  */}
                <div className="container-sec section-2-atq">




                    <NavLink className='atq-item-simpleform atq-item atq-encuesta'
                        to={'/Quality_Encuestas_Page'}
                    >
                        <img src={icoEncuesta} alt="" className='atq-encuesta-img' />
                        <h3 className='atq-item-title'> Encuestas </h3>

                    </NavLink>




                    {/* <button className='atq-item-simpleform atq-item atq-encuesta'>
                    <img src={icoEncuesta} alt="" className='atq-encuesta-img'/>
                    <h3 className='atq-item-title'> Encuestas </h3>
                </button>    */}

                    <NavLink className='atq-item-horizontalform atq-item atq-transferir'
                        to={'director/Reporte_de_experiencia'}
                    >
                        <img src={reporteExperiencia} alt="" className='atq-transferir-img' />
                        <h3 className='atq-item-title'>Reporte de Experiencia </h3>

                    </NavLink>
                    <NavLink className='atq-item-horizontalform atq-item atq-agenda'
                        to={'/agenda/agenda'}
                    >
                        <img src={icoAgenda} alt="" className='atq-agenda-img' />
                        <h3 className='atq-item-title'> Agenda </h3>

                    </NavLink>
                    <NavLink className='atq-item-simpleform atq-item atq-rol-guardias'
                        to={'/ATQAdmin_EstatusCX'}
                    >
                        <img src={Icono_Estatus_CX} alt="" className='atq-rol-guardia-img' />
                        <h3 className='atq-item-title'>Estatus CX</h3>

                    </NavLink>

                </div>
            </div>
        </div>
    )
}
