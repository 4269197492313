import React, { useEffect, useState } from 'react'
import './styleSeguimientoMedico.scss';

export const SeguimientoMedicoComponent = ({type,comCa,setComCa}) => {

    const [answered,setAnswered] = useState(false);
    useEffect(() => {
        if(comCa &&  comCa.length > 0){
            setAnswered(true)
        }
    }, [])
    
    return (
        <section className='container-component-comments-SeguimientoMedic'>
            <div className='header-commentsSeguimientoMedic'>
                <div className='header-titleSeguimientoMedic'>
                    <p className='title-componentSeguimientoMedic'>SEGUIMIENTO A MÉDICO</p>
                </div>
                <div className='header-subtitle-SeguimientoMedico'>
                    <p className='subtitle-componen-SeguimientoMedico'>Seguimiento para uso interno de TSI</p>
                </div>
            </div>
            <div className='body-SeguimientoMedico'>
                <div className='container-SeguimientoMedico'>
                    <textarea placeholder='Comentarios solo de Dirección y/o Calidad…' rows="5" cols="30" className='textArea-SeguimientoMedico' 
                    onChange = { (e) => setComCa(e.target.value)}
                    value={comCa ?comCa :''}
                    disabled ={answered  ?true :false}
                    />
                </div>
            </div>
        </section>
    )
}
