import React, { useState } from 'react'
import './styleInputSendChat.scss'
import icon_emojis from '../../../assets/img/126PX/Icon_emojis.png'
import { useDispatch, useSelector } from 'react-redux';
import { addMessagesToChatGroup, add_chat_group_message_success, getMessagesToChatGroup } from '../../../actions/notification-chatAction';
import { useEffect } from 'react';

export const InputSendChat = (props) => {

  const [messageSend, setMessageSend] = useState('');
  const chatGrupoId = useSelector(state => state.notificationChat.setChatGroupSelected.chatId);
  const surgeryId = useSelector(state => state.notificationChat.setSurgeryChatSelected)

  const dispatch = useDispatch();

  const sendMessage = async (e) => {
    e.preventDefault();
    const media = props.imgBase64 ? props.imgBase64 : null

    // para la img = 
    const success = await dispatch(addMessagesToChatGroup(chatGrupoId, messageSend, surgeryId, media))
    setMessageSend('')
    if (success) {
      dispatch(getMessagesToChatGroup(chatGrupoId, 0, 50))
      props.setImgBase64(null)

    }
  }





  return (
    <form onSubmit={sendMessage} className="form_input_chat_modal_send-add">

      {/* <button className="btn_form_chat_modal_send-add" >
        <img src={icon_emojis} className='img-emoji-chat-modal-add' />
      </button> */}

      <input
        type="text"
        size={20}
        placeholder="Escribe un mensaje..."
        value={messageSend}
        name='messageSend'
        onChange={({ target }) => setMessageSend(target.value)}
        autoComplete='off'
        className="input_form_chat_modal_send-add"
      />
      <button className='btn_chat_message_send' type='submit'></button>
    </form>
  )
}
