import React from 'react';
import './styles.scss';
import CambioAtqNoti from '../../../assets/img/512PX/CambioAtqNoti.png';
import CambioAtqRecha from '../../../assets/img/512PX/CambioAtqRecha.png';

export const Message_Final_Notification = ({back="aceptado"}) => {
    let mensaje = "";
    
    back === "aceptado"
    ? mensaje="ACEPTADO"
    : mensaje="RECHAZADO"


    return (
        <div className={"Final_Notification_finish"}>
        <div className={"Final_Notification_finish_general_c"}>
          <div className={"Final_Notification-images"}>
          {
          back === "aceptado"
                ? <img className="Final_Notification-success-img" src={CambioAtqNoti} alt="" />
                : <img className="Final_Notification-success-img" src={CambioAtqRecha} alt="" />
          }
            
          </div>
                <p className={"Final_Notification_finish-subtitle"}>{'CAMBIO DE ATQ'} </p>
                <p className={"Final_Notification_finish-subtitle"}>{` ${mensaje}`} </p>
                <p className={"Final_subtitle"}>{'(Se ha enviado notificación a los involucrados )'} </p>
                {/* <p className={"ATQAdmin-number"}>{ `CX #${idCX} `}</p> */}
          <div className={"Final_Notification_order_buttons"}>
            <button
              className="Final_Notification-white-button"
      
            >
              <span> Aceptar </span>
            </button>
          </div>
        </div>
      </div>
    )
}
