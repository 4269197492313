/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import sendIco from '../../../../assets/img/126PX/icoSend.png';
import bankIco from '../../../../assets/img/126px/icoBank.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CheckBox, Visibility } from '@material-ui/icons';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, Icon, IconButton, InputAdornment, InputLabel, MenuItem,
        Select, Snackbar, Tab, Tabs, TextField } from '@material-ui/core';
import './styleMobileOrdDetails.scss';
import { SubNavBarComponent } from '../../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useDispatch, useSelector } from 'react-redux';
import billIco from '../../../../assets/img/126PX/Icono_FacturacionMenu_126px.png';
import Dialog from '@material-ui/core/Dialog';
import { Alert, TabPanel } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import { useForm } from '../../../../hooks/useForm';
import delivered from '../../../../assets/img/126PX/deliverBlack_icon.png';
import { useHistory } from 'react-router-dom';
import { encryptData } from '../../../../utils/encrypt.service';
import { getCShippingCompany, getInventoryAsync, setBankReconciliationSale, setInvoiceSale, setShippingCompanySale, getSaleAsync } from '../../../../actions/userActions'
import { event } from 'jquery';
import userIcon from '../../../../assets/img/126PX/profile-white-i.png';
import CircularProgress from '@material-ui/core/CircularProgress';

export const MobileOrdDetailsPage = (props) => {

    const requestH = useSelector((state) => state.user.request);

    const previous = props.history.location.state.row
    const [openConcilDialog, setOpenConcilDialog] = useState(false);
    const [openClientlDialog, setOpenClientDialog] = useState(false);
    const [openBillDialog, setOpenBillDialog] = useState(false);
    const [openShipDialog, setOpenShipDialog] = useState(false);
    const [alertConcil, setAlertConcil] = useState(false);
    const [compValid, setCompValid] = useState(false);
    const [compValue, setCompValue] = useState("");
    const [invoiceValid, setInvoiceValid] = useState(false);
    const [invoiceValue, setInvoiceValue] = useState("");
    const [serieValid, setSerieValid] = useState(false);
    const [serieValue, setSerieValue] = useState("");
    const [deliveryValid, setDeliveryValid] = useState(false);
    const [shipCompValid, setShipCompValid] = useState(false);
    const [deliveryValue, setDeliveryValue] = useState("");
    const [tabsValue, setTabsValue] = useState(0);
    const [stateDrawerBill, setStateDrawerBill] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [displayOther, setDisplayOther] = useState(false);
    const [requiredOther, setRequiredOther] = useState(false);
    const [shippingCompany, setShippingCompany] = useState([]);
    const [otherValid, setOtherValid] = useState(false);
    const [otherShipValue, setOtherShipValue] = useState("")
    const [disabledInputs, setDisabledInputs] = useState(false);
    const [shipCompanyId, setShipCompanyId] = useState("");
    const history = useHistory();
    const [ticketData, setTicketData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [clientData, handleClientChange] = useForm({
        email: ticketData && ticketData.client && ticketData.client.email,
        clientName: ticketData && ticketData.client && ticketData.client.clientName,
        cellphone: ticketData && ticketData.client && ticketData.client.phone,
        paymentMeth: ticketData && ticketData.paymentMethod,
        paymentNum: ticketData && ticketData.paymentNumber
    });

    const {email, clientName, cellphone, paymentMeth, paymentNum} = clientData;

    console.log('PREVIOUS: ', previous)

    //var shipCompanyId = 0;

    const getSales = async () => {
        onBtShowLoading()
        await getSaleAsync(requestH, previous.id).then(resp => {
            if (resp.errorMessage === '')
            {
                console.log("DETALLE DEL TICKET: ", resp.response)
                setTicketData(resp.response)
                //facturado
                setInvoiceValue(resp.response.invoiceNumber ? resp.response.invoiceNumber : '')
                setSerieValue(resp.response.invoiceSerial ? resp.response.invoiceSerial : '')
                //enviado
                setDeliveryValue(resp.response.deliveryNumber ? resp.response.deliveryNumber : '')
                setShipCompanyId(resp.response.deliveryCscId === 0 ? '' : resp.response.deliveryCscId)
                //conciliado
                setCompValue(resp.response.bankReconciliation ? resp.response.bankReconciliation : '' )
                onBtHide();
            }
            else
            {
                console.log("ERROR: ", resp.errorMessage)
            }
            /*if (resp.response && resp.response.length > 0) {
                console.log("DETALLE DEL TICKET: ", resp.response)
                //setSales(resp.response)
                //onBtHide()
            }
            else {
                //onBtShowNoRows();
            }*/
        });
    }

    /* TABLE COLUMNS */
    const tableColumns = [
        { id: 'check', label: '', minWidth: '5%', align: 'center' },
        { id: 'id', label: 'ID', minWidth: '5%', align: 'center' },
        { id: 'prod', label: 'PRODUCTO', minWidth: '30%', align: 'center' },
        { id: 'color', label: 'COLOR', minWidth: '10%', align: 'center'},
        { id: 'lot', label: 'LOTE', minWidth: '10%', align: 'center'},
        { id: 'prodStock', label: 'CANTIDAD', minWidth: '5%', align: 'center' },
        { id: 'remarks', label: 'MARCADO', minWidth: '10%', align: 'center'},
        { id: 'prodPrice', label: 'PRECIO S/IMP', minWidth: '10%', align: 'center' },
        { id: 'prodDisc', label: 'DESCUENTO', minWidth: '10%', align: 'center' },
        { id: 'prodTotal', label: 'TOTAL', minWidth: '10%', align: 'center' },
        { id: 'eye', label: '', minWidth: '5%', align: 'center' }
    ]

    const condiciones = [
        {
            value: '30 Dias',
            label: '30 Dias'
        },
        {
            value: '60 Dias',
            label: '60 Dias'
        },
        {
            value: '90 Dias',
            label: '90 Dias'
        },
        {
            value: '120 Dias',
            label: '120 Dias'
        },
        {
            value: 'Contado',
            label: 'Contado'
        }
    ]

    const metodosPago = [
        {
            value: 'Pago una solo exhibición (PUE)',
            label: 'Pago una solo exhibición (PUE)'
        },
        {
            value: 'Parcialidades (PPD)',
            label: 'Parcialidades (PPD)'
        }
    ]

    const formasPago = [
        {
            value: '04-Tarjeta de crédito',
            label: '04-Tarjeta de crédito'
        },
        {
            value: '28-Tarjeta de débito',
            label: '28-Tarjeta de débito'
        },
        {
            value: '03-Transferencia electrónica de fondos',
            label: '03-Transferencia electrónica de fondos'
        },
        {
            value: '02-Cheque nominativo',
            label: '02-Cheque nominativo'
        },
        {
            value: '99-Por definir',
            label: '99-Por definir'
        },
        {
            value: '01-Efectivo',
            label: '01-Efectivo'
        }
    ]

    function formatearNumeroComoMoneda(numero) {
        numero = numero.toFixed(2);
        numero = numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const cantidadMoneda = `$ ${numero} MXN`;
        return cantidadMoneda;
    }

    /* DIALOGO CONCILIACIÓN */
    const handleOpenConcilDialog = () => {
        setOpenConcilDialog(true);
    }

    const handleCloseConcilDialog = () => {
        setOpenConcilDialog(false);
        setCompValid(false);
        setCompValue("");
    }

    /* DIALOGO INFO CLIENTE */
    const handleOpenClientDialog = () => {
        setOpenClientDialog(true);
    }

    const handleCloseClientDialog = () => {
        setOpenClientDialog(false);
    }

    /* DIALOGO ENVIO */
    const handleOpenShipDialog = async () => { 
        setOpenShipDialog(true);
        
        if (deliveryValue !== '' && (ticketData.deliveryCscId && ticketData.deliveryCscId !== '' || ticketData.deliveryCscId !== 0))
        {
            setDisabledInputs(true)
        }
        else setDisabledInputs(false)

        if (ticketData.deliveryCscId === 14)
        {
            setDisplayOther(true);
            setOtherShipValue(ticketData.deliveryOther)
        }

        await getCShippingCompany(requestH).then((res) => {
            if (res.sucess)
            {
                setShippingCompany(res.shippingCompanyLists)
                console.log(res.shippingCompanyLists)
            }
            else console.log("ERROR: ", res.errorMessage)

        })
    }

    const handleCloseShipDialog = () => { setOpenShipDialog(false); }

    /* DIALOGO FACTURADO */
    const handleOpenBillDialog = () => { 
        if (ticketData.invoiceNumber && ticketData.invoiceNumber !== '')
            setInvoiceValid(true);

        if (ticketData.invoiceSerial && ticketData.invoiceSerial !== '')
            setSerieValid(true);

        setOpenBillDialog(true); 
    }

    const handleCloseBillDialog = () => { 
        setOpenBillDialog(false); 
        setInvoiceValue(ticketData.invoiceNumber ? ticketData.invoiceNumber : '');
        setSerieValue(ticketData.invoiceSerial ? ticketData.invoiceSerial : '');
    }

    const toggleDrawerBillData = () => { setStateDrawerBill(!stateDrawerBill); }

    // --------------------------------- VALIDATIONS ---------------------------------
    const handleInvoiceValidation = (e) => {
        setInvoiceValue(e.target.value)
        const rex = new RegExp("^[A-z0-9]*$");
        const valid = rex.test(e.target.value)

        if(!valid || e.target.value.length < 1)
        {
            setInvoiceValid(false);
        }
        else
        {
            setInvoiceValid(true);
        }
    }

    const handleSerieValidation = (e) => {
        setSerieValue(e.target.value)
        const rex = new RegExp("^[A-z0-9]*$");
        const valid = rex.test(e.target.value)

        if(!valid || e.target.value.length < 1)
        {
            setSerieValid(false)
        }
        else { setSerieValid(true) }
    }

    const handleShipValidation = (e) => {
        setShipCompanyId(e.target.value)

        if (e.target.value == '')
        {
            setShipCompValid(false)
        }
        else 
        {
            setShipCompValid(true)
            if (e.target.value === 14)
            {
                setDisplayOther(true);
                setRequiredOther(true);
            }
            else
            {
                setDisplayOther(false);
                setRequiredOther(false);
                setOtherShipValue("");
                setOtherValid(false);
            }
        }
    }

    const handleOtherShipValidation = (e) => {
        setOtherShipValue(e.target.value);
        const rex = new RegExp("^[\\wÀ-ÿ\\u00f1\\u00d1]+(\\s[\\wÀ-ÿ\\u00f1\\u00d1]+)*$");
        const validRegex = rex.test(e.target.value)

        if(!validRegex)
            setOtherValid(false);
        else
            setOtherValid(true);
    }

    const handleDeliveryValidation = (e) => {
        setDeliveryValue(e.target.value)
        const rex = new RegExp("^[A-z0-9]*$");
        const valid = rex.test(e.target.value)

        if(!valid)
        {
            setDeliveryValid(false);
        }
        else
        {
            setDeliveryValid(true);
        }
    }

    //
    const handleCompPagoValidation = (e) => {
        setCompValue(e.target.value)
        const rex = new RegExp("^[A-z0-9]*$");
        const valid = rex.test(e.target.value)
        
        if (!valid || e.target.value.length < 6)
        {
            setCompValid(false)
        }
        else if (e.target.value.length >= 6 && !valid)
        {
            setCompValid(false)
        }
        else
        {
            setCompValid(true);
        }
    }

    const handleTabsChange = (event, newValue) => {
        setTabsValue(newValue);
    };

    const saveConcilData = async () => {

        await setBankReconciliationSale(requestH, ticketData.id, compValue).then((res) => {
            if (res.sucess)
            {
                handleCloseConcilDialog();
                setCompValue("");
                setCompValid(false);
                setAlertConcil(true);
            }
            else
            {
                console.log("ERROR: ", res.errorMessage);
            }
        })
        //setOpenConcilDialog(false);
        
    }

    const closeConcilAlert = (event) => {
        setAlertConcil(false);
    }

    function getSellerName(information) {
        console.log(information)
        return `${information.firstName || ''} ${information.firstLastName || ''} ${information.secondLastName || ''}`
    }
    
    function phoneFormat(phone) {
        return `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`;
    }

    function getTotal(total) {
        const totalFormat = total;

        return `${totalFormat.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} MXN`;
    }

    //
    const closeAlert = (event) => {
        setAlert(false);
    }

    const saveInvoiceNumber = async () => {

        await setInvoiceSale(requestH, ticketData.id, invoiceValue, serieValue).then((res) => {
            if ( res.sucess)
            {
                handleCloseBillDialog();
                setAlertMessage("Factura generada con éxito");
                setAlert(true);
            }
            else console.log("ERROR: ", res.errorMessage)
        })
    }

    const saveDeliveryNumber = async (e) => {

        if(shipCompanyId !== 14)
        {
            await setShippingCompanySale(requestH, ticketData.id, shipCompanyId, deliveryValue, '').then((res) => {
                if (res.sucess)
                {
                    handleCloseShipDialog();
                    setAlertMessage("Información de envío guardada exitosamente")
                    setAlert(true);
                }
                else
                {
                    console.log("ERROR: ", res.errorMessage)
                }
            })
        }
        else
        {
            await setShippingCompanySale(requestH, ticketData.id, shipCompanyId, deliveryValue, otherShipValue).then((res) => {
                if (res.sucess)
                {
                    handleCloseShipDialog();
                    setAlertMessage("Información de envío guardada exitosamente")
                    setAlert(true);
                }
                else
                {
                    console.log("ERROR: ", res.errorMessage)
                }
            })
        }

    }

    //función para implementar loading a la tabla
    const onBtShowLoading = () => {
        //gridRef.current.api.showLoadingOverlay();
        setLoading(true);
    }

    const onBtShowNoRows = () => {
        //gridRef.current.api.showNoRowsOverlay();
        setLoading(false)
    }

    const onBtHide = () => {
        //gridRef.current.api.hideOverlay();
        setLoading(false)
    }

    useEffect(() => {
        getSales()
    }, [])

    return (
        <div className='container'>
            <SubNavBarComponent title={'VENTA MÓVIL'} historyPage={`/Orders_VentaMovil`} />
            <div className='header'>
                <div className='title'>
                    <p>DETALLE DEL PEDIDO</p>
                    <hr />
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <button className={ ticketData.status === 2 ? 'disableButton': 'importBtn'} onClick={handleOpenConcilDialog} disabled={ticketData.status === 2}>
                        <img src={bankIco} width={24} height={24} />
                        CONCILIACIÓN
                    </button>
                    <button className={ ticketData.status === 2 ? 'disableButton': 'importBtn'} onClick={handleOpenShipDialog} disabled={ticketData.status === 2}>
                        <img src={delivered} width={28} height={28} style={{filter: 'invert(100)'}} />
                        ENVIADO
                    </button>
                    {/*<button className={!previous.requiresBill ? "disableButtonIcon" : "importBtn"} onClick={handleOpenBillDialog} disabled={!previous.requiresBill}> */}
                    <button className={ ticketData.status === 2 ? 'disableButton': 'importBtn'} onClick={handleOpenBillDialog} disabled={ticketData.status === 2}>
                        <img src={billIco} width={24} height={24} />
                        FACTURADO
                    </button>
                    {/*<button className='importBtn' onClick={() => { history.push({ pathname: `/OrdersDetails_Facturacion/${previous.billData.billingRFC}` }) }}>*/}
                    { ticketData.requiresBill && ticketData.requiresBill === true ? 
                        <button className='importBtn' onClick={() => { history.push({ pathname: '/OrdersDetails_Facturacion', state : {rfc: ticketData.billData.billingRFC, bill: 1, row: ticketData} })}} >
                            <img src={billIco} width={24} height={24} />
                            {/*DATOS DE FACTURACIÓN*/}
                            EDITAR DATOS
                        </button> 
                    : 
                        <button className='importBtn' onClick={() => { history.push({ pathname: '/OrdersDetails_Facturacion', state : {rfc: '', bill: 2, row: ticketData} })}} >
                            <img src={billIco} width={24} height={24} />
                            {/*DATOS DE FACTURACIÓN*/}
                            EDITAR DATOS
                        </button>
                    }
                    <button className='importBtn' onClick={toggleDrawerBillData}>
                        <img src={userIcon} width={24} height={24} />
                        DATOS DEL CLIENTE
                    </button>
                </div>
                
            </div>

            {loading ? 
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
                    <CircularProgress />
                </div> :
            <div>
                <div className='clientContainer'>
                    {/*<IconButton aria-label='edit' color='primary' onClick={handleOpenClientDialog} 
                        style={{position: 'absolute', top:'0', right: '0'}}>
                        <EditIcon />
                    </IconButton>*/}
                    <div style={{width: '25%'}}>
                        <p>PEDIDO #{ticketData.ticketNumber && ticketData.ticketNumber !== null ? ticketData.ticketNumber : ' - '}</p>
                        <p>CLIENTE TOTVS: <small>{ticketData.client && (ticketData.client.clientCode !== '' && ticketData.client.clientCode !== 'null') ? 
                        ticketData.client.clientCode : ' - '}</small> </p>
                    </div>
                    <div style={{width: '25%'}}>
                        <p>VENDEDOR: <small>{ticketData.seller && (ticketData.seller && ticketData.seller !== '') ? getSellerName(ticketData.seller) : ' - '}</small> </p>
                        <p>VALOR DE VENTA: <small>${ticketData && ticketData.total ? getTotal(ticketData.total) : ' - '}</small></p>
                    </div>

                    <div style={{width: '25%'}}>
                        <p>FORMA DE PAGO: <small>{(ticketData.paymentMethod && ticketData.paymentMethod !== '') ? ticketData.paymentMethod : ' - '}</small> </p>
                        <p>NO. RECIBO DE PAGO: <small>{ticketData.paymentNumber ? ticketData.paymentNumber : ' - '}</small></p>
                    </div>
                    <div style={{ width: '25%'}}>
                        <p style={{color: '#094070'}}>{ticketData.requiresBill ? 'REQUIERE FACTURA' : 'NO REQUIERE FACTURA'}</p>
                        <p>OBSERVACIONES: <small>{ticketData.remarks ? ticketData.remarks : ' - '}</small></p>
                        <p style={{color: '#d10a0a'}}><small>{ticketData.motivo ? 'Cancelado: '+ ticketData.motivo : ''}</small></p>
                    </div>
                </div>

                {/* -------------------------- TABLE ----------------------------- */}
                <div className='tableContainer'>
                    <TableContainer style={{ maxHeight: 600 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => {
                                        return (
                                            <TableCell key={column.id} align={column.align} width={column.minWidth}>
                                                <div style={{ display: 'flex', justifyContent: column.align }}>
                                                    {
                                                        column.id == 'check' ? <CheckBox></CheckBox> : column.label
                                                    }
                                                </div>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ticketData && ticketData.saleDetail && ticketData.saleDetail.length > 0 ? 
                                    ticketData.saleDetail.map((val) => {
                                        return (
                                            <TableRow key={val.id}>
                                                {tableColumns.map((col) => {
                                                    const value = val[col.id];

                                                    return (
                                                        <TableCell key={col.id} align={col.align}>
                                                            {
                                                                col.id === 'id' ? val.productCode:
                                                                col.id === 'size' ? val.size:
                                                                col.id === 'prod' ? val.productDescription :
                                                                col.id === 'color' ? val.color :
                                                                col.id === 'lot' ? val.lot :
                                                                col.id === 'prodStock' ? val.quantity :
                                                                col.id === 'remarks' ? ((val.comments !== '' && val.comments !== 'null') ? val.comments : '-') :
                                                                col.id === 'prodPrice' ? formatearNumeroComoMoneda(val.unitPrice) :
                                                                col.id === 'prodDisc' ? formatearNumeroComoMoneda(val.discount) :
                                                                col.id === 'prodTotal' ? formatearNumeroComoMoneda(val.total) : ''
                                                            }
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })
                                 : <td colSpan={10} style={{fontSize: '18px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', textAlign: 'center', paddingTop: '30px',
                                 color: '#000000DE'}}>
                                    <p> No hay datos... </p> </td>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div> }

            {/* ---------------------------------------------- DIALOGOS ---------------------------------------- */}

            {/* DIALOGO CONCILIACIÓN */}
            <Dialog open={openConcilDialog} onClose={handleCloseConcilDialog} aria-labelledby='concil-dialog-title' maxWidth='lg'>
                <DialogTitle id='concil-dialog-title'>CONCILIACIÓN</DialogTitle>
                <DialogContent>
                    <DialogContentText>Ingrese su <b>número de recibo TOTVS </b></DialogContentText>
                    <TextField
                        id='numCompPago'
                        margin='dense'
                        label='Número de recibo TOTVS'
                        variant='outlined'
                        required
                        fullWidth
                        onChange={(e) => handleCompPagoValidation(e)}
                        error={!compValid}
                        value={compValue}
                        inputProps={{ minLength: 6 }}
                        autoFocus
                        helperText={!compValid ? 'Dato inválido (debe tener mínimo 6 caracteres alfanuméricos)' : ''}/>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseConcilDialog}  className="buttons">
                        CANCELAR
                    </button>
                    <button className={!compValid ? "disableButton" : "buttons"} onClick={saveConcilData} disabled={!compValid}>
                        ACEPTAR
                    </button>
                </DialogActions>
            </Dialog>

            {/* DIALOGO DATOS DEL CLIENTE */}
            <Dialog open={openClientlDialog} onClose={handleCloseClientDialog} aria-labelledby='client-dialog-title' maxWidth='md'>
                <DialogTitle id='client-dialog-title'>DATOS GENERALES</DialogTitle>
                <DialogContent>
                    <DialogContentText>Llena los siguientes datos </DialogContentText>
                    <div>
                        <TextField
                            id='clientName'
                            name={'clientName'}
                            margin='dense'
                            label='Nombre del cliente'
                            variant='outlined'
                            type='text'
                            value={clientName}
                            onChange={handleClientChange}
                            style={{marginRight: '10px', width: 'auto'}}
                        />
                        <TextField
                            id='email'
                            name={'email'}
                            margin='dense'
                            label='Correo electrónico'
                            variant='outlined'
                            required
                            type='email'
                            value={email}
                            onChange={handleClientChange /*setClient(e.target.value) */}
                            style={{marginRight: '10px'}}
                        />
                        <TextField
                            id='cellphone'
                            name={'cellphone'}
                            margin='dense'
                            label='Número de teléfono'
                            variant='outlined'
                            value={cellphone}
                            onChange={handleClientChange}
                            inputProps={{ maxLength: 10 }}
                            style={{marginRight: '10px'}}
                        />
                        <TextField
                            id='formPago'
                            variant='outlined'
                            margin='dense'
                            required
                            select
                            label='Forma de pago'
                            defaultValue={paymentMeth}
                            style={{width: '26ch', marginRight: '10px'}}>
                                {formasPago.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </TextField>

                        <TextField
                            id='metodoPago'
                            variant='outlined'
                            margin='dense'
                            required
                            select
                            label='Método de pago'
                            style={{width: '26ch', marginRight: '10px'}}>
                                {metodosPago.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </TextField>

                        <TextField
                            id='condPago'
                            variant='outlined'
                            margin='dense'
                            required
                            select
                            label='Condición de pago'
                            style={{width: '26ch', marginRight: '10px'}}>
                                {condiciones.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </TextField>

                        <TextField
                            id='rePago'
                            margin='dense'
                            label='Número recibo de pago'
                            variant='outlined'
                            value={paymentNum}
                            onChange={handleClientChange}
                            style={{marginRight: '10px'}}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseClientDialog}  className="buttons">
                        CANCELAR
                    </button>
                    <button className="buttons">
                        ACEPTAR
                    </button>
                </DialogActions>
            </Dialog>

            {/* DIALOGO ENVIADO */}
            <Dialog open={openShipDialog} onClose={handleCloseShipDialog} aria-labelledby='ship-dialog-title' maxWidth='lg'>
                <DialogTitle id='ship-dialog-title'>ENVIADO</DialogTitle>
                <DialogContent>
                    <DialogContentText> ¿El pedido ya fue enviado? </DialogContentText>

                    <TextField
                        id="ship"
                        select
                        variant='outlined'
                        margin='dense'
                        required
                        fullWidth
                        label='Paquetería'
                        onChange={handleShipValidation}
                        error={!shipCompValid}
                        defaultValue={shipCompanyId}
                        disabled={disabledInputs}
                    >
                        {shippingCompany.map((paq) => (
                            <MenuItem key = {paq.cscId} value = {paq.cscId}>
                                {paq.cscName}
                            </MenuItem>
                        ))}
                    </TextField>
                    
                    { displayOther && 
                        <TextField
                            id="otherShip"
                            margin='dense'
                            label='Nombre de la paquetería'
                            variant='outlined'
                            fullWidth
                            required={requiredOther}
                            onChange={(e) => handleOtherShipValidation(e)}
                            error={!otherValid}
                            value={otherShipValue}
                            disabled={disabledInputs}
                        /> 
                    }

                    <TextField
                        id='deliveryNumber'
                        margin='dense'
                        label='No. guía de rastreo'
                        variant='outlined'
                        required
                        fullWidth
                        onChange={(e) => handleDeliveryValidation(e)}
                        error={!deliveryValid}
                        value={deliveryValue}
                        disabled={disabledInputs}
                        inputProps={{ maxLength: 30 }}
                    />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShipDialog}  className="buttons">
                        CANCELAR
                    </button>
                    <button className={(!deliveryValid || !shipCompValid) ? "disableButton" : "buttons"} onClick={saveDeliveryNumber} disabled={!deliveryValid}>
                        GUARDAR
                    </button>
                </DialogActions>
            </Dialog>

            {/* DIALOGO FACTURADO */}
            <Dialog open={openBillDialog} onClose={handleCloseBillDialog} aria-labelledby='bill-dialog-title' maxWidth='lg'>
                <DialogTitle id='bill-dialog-title'>FACTURADO</DialogTitle>
                <DialogContent>
                    <DialogContentText> ¿El pedido ya fue facturado? </DialogContentText>

                    <TextField
                        id='billNumber'
                        name={'billNumber'}
                        margin='dense'
                        label='No. de Factura'
                        variant='outlined'
                        required
                        fullWidth
                        onChange={(e) => handleInvoiceValidation(e)}
                        error={!invoiceValid}
                        value={invoiceValue}
                        autoFocus
                        inputProps={{ maxLength: 20 }}
                    />

                    <TextField
                        id='serieNumber'
                        name={'serieNumber'}
                        margin='dense'
                        label='No. de Serie'
                        variant='outlined'
                        required
                        fullWidth
                        onChange={(e) => handleSerieValidation(e)}
                        error={!serieValid}
                        value={serieValue}
                        inputProps={{ maxLength: 20 }}
                    />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseBillDialog} className="buttons">
                        CANCELAR
                    </button>
                    <button className={(!invoiceValid || !serieValid) ? "disableButton" : "buttons"} onClick={saveInvoiceNumber} disabled={!invoiceValid}>
                        GUARDAR
                    </button>
                </DialogActions>
            </Dialog>

            {/* DIALOGO DATOS DE FACTURACIÓN */}
            <Drawer anchor={'right'} open={stateDrawerBill} onClose={toggleDrawerBillData} PaperProps={{
                sx: {width: "20%"},
            }}>
                <div className='drawerContainer'>
                    <p className='drawerTitle'><span>DATOS DEL CLIENTE</span></p>
                    <div>
                        <p style={{marginBottom: '5px'}}>Cliente</p>
                        <small>{ticketData.client && (ticketData.client.clientName !== '' && ticketData.client.clientName !== 'null') ?
                         ticketData.client.clientName : '-'}</small>
                    </div>
                    <div>
                        <p style={{marginBottom: '5px'}}>Correo</p>
                        <small>{ticketData.client && (ticketData.client.email !== '' && ticketData.client.email !== 'null') ? 
                        ticketData.client.email : ' - '}</small>
                    </div>
                    <div style={{marginBottom: '20px'}}>
                        <p>Teléfono</p>
                        <small>{ticketData.client && (ticketData.client.phone !== '' && ticketData.client.phone !== 'null') ? 
                        phoneFormat(ticketData.client.phone) : ' - '}</small>
                    </div>

                    <p className='drawerTitle'><span>DATOS DE FACTURACIÓN</span></p>
                    <div>
                        <p>Razón social</p>
                        <small>{ticketData.billData && ticketData.billData.billingName ? ticketData.billData.billingName : '-'}</small>
                    </div>
                    <div>
                        <p>RFC</p>
                        <small>{ticketData.billData && ticketData.billData.billingRFC ? ticketData.billData.billingRFC : '-'}</small>
                    </div>
                    <div>
                        <p>Domicilio</p>
                        <small>{ticketData.billData && ticketData.billData.billingAddress ? ticketData.billData.billingAddress : '-'}</small>
                    </div>
                    <div>
                        <p>Código postal</p>
                        <small>{ticketData.billData && ticketData.billData.billingZIP ? ticketData.billData.billingZIP : '-'}</small>
                    </div>
                    <div>
                        <p>Régimen fiscal</p>
                        <small>{ticketData.billData && ticketData.billData.billingRaxRegime ? ticketData.billData.billingRaxRegime : '-'}</small>
                    </div>
                    <div>
                        <p>Uso de CFDI</p>
                        <small>{ticketData.billData && ticketData.billData.cfdiUse ? ticketData.billData.cfdiUse : '-'}</small>
                    </div>
                    <div>
                        <p>Condición de pago</p>
                        <small>{ticketData.billData && ticketData.billData.paymentCondition ? ticketData.billData.paymentCondition : '-'}</small>
                    </div>
                    <div>
                        <p>Método de pago</p>
                        <small>{ticketData.billData && ticketData.billData.paymentMethod ? ticketData.billData.paymentMethod : '-'}</small>
                    </div> 
                    <div>
                        <p>Forma de pago</p>
                        <small>{ticketData.billData && ticketData.billData.paymentTherms ? ticketData.billData.paymentTherms : '-'}</small>
                    </div>
                    <div style={{marginBottom: '20px'}}>
                        <p>Recibo de pago no.</p>
                        <small>{ticketData.billData && ticketData.billData.paymentNumber ? ticketData.billData.paymentNumber : '-'}</small>
                    </div>

                    <p className='drawerTitle'><span>DATOS DE ENVÍO</span></p>
                    <div>
                        <p>Dirección de envío</p>
                        <small>{ticketData.addressShipping && ticketData.addressShipping !== 'null' ? ticketData.addressShipping : '-'}</small>
                    </div>
                    <div>
                        <p>Instrucciones o referecias</p>
                        <small>{ticketData.shippingInstruction && ticketData.shippingInstruction !== 'null' ? ticketData.shippingInstruction : '-'}</small>
                    </div>
                    <div>
                        <p>Fecha de envio</p>
                        <small>{ticketData.deliveryDate && ticketData.deliveryDate !== 'null' ? ticketData.deliveryDate : '-'}</small>
                    </div>

                </div>
            </Drawer>

            {/* ALERTAS */}
            <Snackbar open={alertConcil} autoHideDuration={3000} onClose={closeConcilAlert} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
                <Alert severity='success' sx={{ width: '100%' }}>
                    Complemento de pago guardado
                </Alert>
            </Snackbar>

            <Snackbar open={alert} autoHideDuration={3000} onClose={closeAlert} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
                <Alert severity='success' sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

        </div>
    )
}
