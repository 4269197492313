import React from 'react';
import './styles.scss';
import img_encuesta from '../../../assets/img/512PX/ico_message_encuesta.png';
import { useHistory, useParams } from 'react-router-dom';



export const StoreHouseMessage_Encuesta_Page = () => {

  const history = useHistory();


  const handleNavigate = () => {
    history.push("/Inicio")

  }
  const {id} = useParams();
/*   console.log(id); */

  return (
    <div className='precontainer-almacen'>
      <div className='container_general_almace'>

        <div className='container-almacen'>
          <img className='img-encuesta-almacen' src={img_encuesta} />
        </div>

        <p className="title_encuesta_almacen">SE HA GUARDADO TU ENCUESTA</p>
        <p className={"numberCx_encuestas_almacen"}>{`CX #${id}`}</p>

        <div className='Cont_button_encuestas_almacen'>
          <button className='button-almacen' onClick={() => handleNavigate()}>
            <span>OK</span>
          </button>
        </div>

      </div>
    </div>


  )
};

