import React from 'react';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import shortid from 'shortid';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './style.scss';
import { Mess_Not_Com } from '../../../components/Messenger/Notifications/Mess_Not_Com';

export const Mess_Noti_Page = () => {

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Institucion',
            value: 'Hospital San Javier',
        },
        {
            uid: shortid.generate(),
            title: 'Fecha de CX',
            value: '11-09-2021',
        },
        {
            uid: shortid.generate(),
            title: 'Hora de CX',
            value: '11:00 Hrs',
        },
        {
            uid: shortid.generate(),
            title: 'Medico',
            value: 'Ricardo Lopez',
        },
    ];
    return (
        <div className='container-mess'>
        <SubNavBarComponent title={'NOTIFICACIONES'}  historyPage = {'/ATQAdmin_Asignar_CX'} />
            <div className='precontainer-mess'>
                <section className='section-left'>
                     <StoreHouseListComponent 
                        typeView={{type:'STORE', title: `PQX6`}}
                        listFields={arrayListFields} 
                    />
                </section>
                <section className='container-rigth'>
                    <Mess_Not_Com/>
                <button className='btn-Aceptar'>
                    <p className='btn-title'>ACEPTAR</p>
                </button>
                </section>
            </div>
        </div>
    )
}
