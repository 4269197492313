import React from 'react'
import CustomModal from '../../shared/Modal';
import { AddTalkGroup } from './AddTalkGroup';

export const Add_Talk_Group_Modal_Chat = (props) => {
    const modalBody = () => {
        return (
        <AddTalkGroup  
            toggle={props.toggle}
            setOpenModal={props.setOpenModal}
            setCxSelected  = {props.setCxSelected}
            setOpenModalAdd = {props.setOpenModalAdd}

        />
    );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.handleClose ? props.handleClose : props.toggle} 
                body={modalBody()}
                title={"Crear Chat"}
        />
    );
}