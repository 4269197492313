import React, {useState } from 'react'
import { useDispatch } from 'react-redux';
import { SearchUsers } from '../../../actions/userActions';
import icoBuscarGris from '../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import './style.scss';

export const SearchComponent = () => {
    const [searchUser, setSearchUser] = useState('');
    const dispatch = useDispatch();
    const searUser = () => {
      dispatch(SearchUsers(searchUser))
    }
   
    const handleSearch = (e) => {
      e.preventDefault();
        // console.log(searchUser)
        setSearchUser('');
    };

    return (
        <form onSubmit={handleSearch} className="SearchChatComponentResp">
          <button className="c-SearchChatComponent-ico" type='submit' onClick={() => searUser()}>
            <img src={icoBuscarGris} alt="" />
          </button>
          <input
            type="text"
            placeholder="Buscar..."
            name="search"
            onChange={({ target }) => setSearchUser(target.value)}
            value={searchUser}
            className="SearchChatComponent-tools-input"
            autoComplete="off"
          />
        </form>

        )
};

