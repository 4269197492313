
import React, { useEffect, useState } from 'react';

import { HomeScreen } from '../pages/home/HomeScreen';
import { useDispatch, useSelector } from 'react-redux';
import { setChatGroupSelected, suscribeForNotification } from '../actions/notification-chatAction';
import { getToken2 } from '../firebaseInit';
import { ATQ_Menu_Page } from '../pages/ATQ/ATQ_Menu_Page';
import { ATQAdmin_Home } from '../pages/ATQAdministrator/ATQ_Home/ATQAdmin_Home';
import { Messenger_Menu_Page } from '../pages/Messenger/Messenger_Menu_Page';
import { Quality_Manager_Page } from '../pages/QualityManager/Quality_Manager_Page';
import  {StoreHouseMenuScreen}  from '../pages/StoreHouseScreen/StoreHouseMenu/StoreHouseMenuScreen';
import { HomeSreenMantenimientoPage } from '../pages/SurveysMantenimiento/HomeSreenMantenimientoPage';
import { Home_Directorio_Page } from '../pages/Director/Home_Directorio_Page';

const HomePage = () => {

  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const notificationChat = useSelector((state) => state.notificationChat.suscription);
  const user = useSelector((state) => state.user.user.identity);
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    if(notificationChat){
      getToken2(setTokenFound)
    }
    return () => {
      dispatch(setChatGroupSelected(false, null, null))
    }
  }, [])
  

  useEffect(() => {
    if (isTokenFound) {
      setLocalStorage(isTokenFound);
    }else if(!isTokenFound && !token){
      getToken2(setTokenFound)
    }

  }, [isTokenFound])



  // Guardar token en localStorage
  const setLocalStorage = value => {
    try {
      window.localStorage.setItem("token", value)
    } catch (error) {
      //console.error(error);
    }
  }

  // Guardar idToken en localStorage
  const setLocalStorageID = id => {
    try {
      window.localStorage.setItem("idToken", id)
    } catch (error) {
      //console.error(error)
    }
  }

  // Guardar userID en localStorage
  const setLocalStorageUserID = (userID) => {
    try {
      window.localStorage.setItem("userID", userID)
    } catch (error) {
      //console.error(error)
    }
  }

  useEffect(() => {
    if (token && !notificationChat) {
      dispatch(suscribeForNotification(3, token));
    }
    if (notificationChat) {
      setLocalStorageID(notificationChat.id);
    }
  }, [token, notificationChat])

  useEffect(() => {
    const oldUser = window.localStorage.getItem("userID")
    if (user && user.userID && user.userID) {
      //console.log("olduserString",oldUser === JSON.stringify(user.userID))
      if (oldUser !== JSON.stringify(user.userID)) {
        window.localStorage.setItem("Newnotificacion", [])
      }
      setLocalStorageUserID(user.userID);
    }
  }, [user])


 
  const handleUserRole = { 
      '11': <ATQAdmin_Home/>,
       '5': <ATQ_Menu_Page/>,
       '1': <HomeScreen/>,
       '6': <HomeScreen/>,
       '9': <Home_Directorio_Page/>,
      '13': <StoreHouseMenuScreen/>,
      '12': <Messenger_Menu_Page/>,
      '16': <Quality_Manager_Page/>,
      '17':<HomeSreenMantenimientoPage/>,
  };
  return (
    <>
    {
      handleUserRole[user.roleID.toString()] 
      ? handleUserRole[user.roleID.toString()]
      :<HomeScreen/>
    }
    </>
  );
};

export default HomePage;
