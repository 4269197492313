import React from 'react'
import { useSelector } from 'react-redux';
import './styleSNC.scss';

export const SNCComponent = ({type=null,response,setResponse}) => {7
    
    const user = useSelector(state => state.user.user.identity);
    return (
        <section className='container-component-comments-SNC'>
            <div className='header-commentsSNC'>
                <div className='header-titleSNC'>
                    <p className='title-componentSNC'>TIPO DE SNC</p>
                </div>
            </div>

            <section className='body-SNC'>

                <div className='container-SNC'>
                    <div className='container-SNC-left'>

                        <div className='preco-row-SNC'>
                            <p className='text-header-SNC'>Incumplimiento Asistencia Técnica</p>
                        </div>
                        <div className='preco-row-SNC'>
                            <p className='text-SNC'>Impuntualidad</p>
                            <input className='input_check_SNC'
                                type="checkbox"
                                id="cbox2"
                                name="impuntualidad"
                                disabled={(type !== 'seguimiento') ?true :user.roleID === 9 ?true  :false}
                                checked={response.impuntualidad}
                                onChange={() => setResponse({...response,impuntualidad:!response.impuntualidad})}>
                                </input>
                        </div>
                        <div className='preco-row-SNC'>
                            <p className='text-SNC'>Inadecuada Asesoría Técnica</p>
                            <input className='input_check_SNC'
                                type="checkbox"
                                id="cbox2"
                                name="asesoria"
                                checked={response.asesoria}
                                disabled={(type !== 'seguimiento')  ?true :user.roleID === 9 ?true  :false}
                                onChange={() => setResponse({...response,asesoria:!response.asesoria})}>
                                </input>
                        </div>
                    </div>
                    <div className='container-SNC-rigth'>
                        <div className='preco-row-SNC'>
                            <p className='text-header-SNC'>Resultado Negativo</p>
                        </div>
                        <div className='preco-row-SNC'>
                            <p className='text-SNC'>Entrega Inoportuna de Equipos</p>
                            <input className='input_check_SNC'
                                type="checkbox"
                                id="cbox2"
                                name="entrega"
                                checked={response.entrega}
                                disabled={(type !== 'seguimiento')   ?true :user.roleID === 9 ?true  :false}
                                onChange={() => setResponse({...response,entrega:!response.entrega})}>
                                </input>
                        </div>
                        <div className='preco-row-SNC'>
                            <p className='text-SNC'>Material Incompleto</p>
                            <input className='input_check_SNC'
                                type="checkbox"
                                id="cbox2"
                                name="material"
                                checked={response.material}
                                disabled={(type !== 'seguimiento')   ?true :user.roleID === 9 ?true  :false}
                                onChange={() => setResponse({...response,material:!response.material})}>

                                </input>
                        </div>

                        <div className='preco-row-SNC'>
                            <p className='text-SNC'>Inadecuada Captación de los REC.</p>
                            <input className='input_check_SNC'
                                type="checkbox"
                                id="cbox2"
                                name="capacitacion"
                                disabled={(type !== 'seguimiento')  ?true :user.roleID === 9 ?true  :false}
                                checked={response.capacitacion}
                                onChange={() => setResponse({...response,capacitacion:!response.capacitacion})}>

                                </input>
                        </div>

                    </div>
                </div>
            </section>








        </section>

    )
}
