import React, { useState } from 'react'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';



export const QuillComponent = (props) => {

    return (


        <>
        
                <ReactQuill className="shadow-sm"
                    theme="snow"
                    readOnly={props.readOnly === true ?true :false}
                    style={{
                        height: 250,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    name={props.coments}
                    value={props.coments}

                    modules={{
                        toolbar: [
                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }], [{ size: [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'align': [] }],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link', "video", "image", "code-block"],
                            ['clean']
                        ],
                    }}
                    formats={[
                        'header', 'font', 'size',
                        'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
                        'list', 'bullet', 'indent', 'link', 'video', 'image', "code-block", "align"
                    ]}

                    onChange={(val) => {
                        props.setComents(val)

                    }}
                />

        </>
    )
}
