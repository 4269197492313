import React from 'react';
import './styleComentQuality.scss';

export const ComentQuality = () => {
    return (
        <section className='container-component-comments-ComentQuality'>
            <div className='header-commentsComentQuality'>
                <div className='header-titleComentQuality'>
                    <p className='title-componentComentQuality'>COMENTARIO GERENTE DE CALIDAD</p>
                </div>
                <div className='header-subtitle-ComentQuality'>
                    <p className='subtitle-componen-ComentQuality'>Seguimiento para uso interno de TSI</p>
                </div>
            </div>

            <div className='body-ComentQuality'>

                <div className='container-ComentQuality'>

                    <textarea placeholder='Comentarios solo de Dirección y/o Calidad…' rows="5" cols="30" className='textArea-ComentQuality'></textarea>

                </div>
            </div>


        </section>
    )
}
