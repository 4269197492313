import React from 'react';
import CambioAtqNoti from '../../../assets/img/512PX/CambioAtqNoti.png';
import CambioAtqRecha from '../../../assets/img/512PX/icon_not_rech.png';
import './styleMensagge.scss';


export const ATQ_not_mensage = ({back = 'rechazado'}) => {
    let mensaje = "";
    
    back === "aceptado"
    ? mensaje="TRANSFERENCIA ACEPTADA"
    : mensaje="TRANSFERENCIA RECHAZADA"


    return (
        <div className={"Final_Not_finish"}>
        <div className={"Final_Not_finish_general_c"}>
          <div className={"Final_Not-images"}>
          {
          back === "aceptado"
                ? <img className="Final_Not-success-img" src={CambioAtqNoti} alt="" />
                : <img className="Final_Not-success-img" src={CambioAtqRecha} alt="" />
          }
            
          </div>
                <p className={"Final_Not_finish-subtitle"}>{'CX#8'} </p>
                <p className={"Final_Not_finish-subtitle"}>{` ${mensaje}`} </p>
                {/* <p className={"Final_subtitle"}>{'(Se ha enviado notificación a los involucrados )'} </p> */}
                {/* <p className={"ATQAdmin-number"}>{ `CX #${idCX} `}</p> */}
          <div className={"Final_Not_order_buttons"}>
            <button
              className="Final_Not-white-button"
      
            >
              <span> NOTIFICAR A COORDINADOR </span>
            </button>
          </div>
        </div>
      </div>
    )
}
