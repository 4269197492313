import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import moment from 'moment';
import Calendar from 'react-calendar';
import './UserTabAgenda.scss';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, getSurgeriesInUser } from '../../../../../../actions/allUsersAction';

// ASSETS
import blueArrowIcon from '../../../../../../assets/img/126PX/blue_arrow_icon.png';
import blueDownArrowIcon from '../../../../../../assets/img/126PX/blue_arrow_down.png';
import search from '../../../../../../assets/img/126PX/Icono_Buscar_Gris_126px.png'
import { formatFinalDate, formatHourToPresent, formatOnlyFinalDate, formatOnlyInicialDate } from '../../../../../../hooks/useFormatDateInitialAndFinalDay';


const UserTabAgenda = () => {

    const dispatch = useDispatch();
    const [surgeries, setSurgeries] = useState(null)
    const [selectedDate, selectedDateOnChange] = useState(new Date());
    const [selectedFinalDate, selectedFinalDateOnChange] = useState();
    const [selectedSurgeryItem, setSelectedSurgeryItem] = useState(null);
    const data = useSelector(state => state.allUsers);
    const selectedUserId = useSelector(state => state.allUsers.selectedUserId);
    const surgery = useSelector(state => state.allUsers.appointmentsList)
    const rol = useSelector(state => state.allUsers.userRolSelected)
    const [openDetail, setOpenDetail] = useState(false);

    // useEffect(() => {
    //     dispatch(getSurgeriesInUser());
    // }, [])

    useEffect(() => {
        if (data.surgeriesInUser) {
            setSurgeries(data.surgeriesInUser)
        }
    }, [data])


    const toggleOpenSurgeryItem = (item) => {

        setSelectedSurgeryItem(selectedSurgeryItem && selectedSurgeryItem.id == item.id ? true : item);
        //setOpenDetail(!openDetail)
    };





    const renderLeftBar = () => {
        return (
            <div className={'doctor-tab-agenda-left-container'}>
                <span className={'bar-title'}>Calendario</span>
                <p className="dateText">Fecha inicial</p>
                <div className={"date_content mb-x3 calendar_date_content"}>
                    {/* <div className={"numericInputStyle"}>
                        <NumberInput title={'day'}/>
                    </div>
                    <div className={"numericInputStyle"}>
                        <NumberInput title={'month'}/>
                    </div>
                    <div className={"numericInputStyle"}>
                        <NumberInput title={'year'}/>
                    </div> */}
                    <Calendar
                        onChange={selectedDateOnChange}
                        value={selectedDate}
                    />
                </div>
                <p className="dateText">Fecha Final</p>
                <div className={"date_content calendar_date_content"}>
                    {/* <div className={"numericInputStyle"}>
                        <NumberInput title={'day'}/>
                    </div>
                    <div className={"numericInputStyle"}>
                        <NumberInput title={'month'}/>
                    </div>
                    <div className={"numericInputStyle"}>
                        <NumberInput title={'year'}/>
                    </div> */}
                    <Calendar
                        onChange={selectedFinalDateOnChange}
                        value={selectedFinalDate}
                    />
                </div>
            </div>
        );
    };

    const handleSurgery = () => {
        if (selectedDate && selectedFinalDate && rol === 'doctor') {
            dispatch(getAppointmentsStoreHouse(formatOnlyInicialDate(selectedDate), formatOnlyFinalDate(selectedFinalDate), 1, selectedUserId))
        } else if (selectedDate && selectedFinalDate && rol === 'atq') {
            dispatch(getAppointmentsStoreHouse(formatOnlyInicialDate(selectedDate), formatOnlyFinalDate(selectedFinalDate), 2, selectedUserId))
        } else if (selectedDate && selectedFinalDate && rol === 'adminAtq') {
            dispatch(getAppointmentsStoreHouse(formatOnlyInicialDate(selectedDate), formatOnlyFinalDate(selectedFinalDate), 0, selectedUserId))
        }else if(selectedDate && selectedFinalDate && rol === 'seller'){
            dispatch(getAppointmentsStoreHouse(formatOnlyInicialDate(selectedDate), formatOnlyFinalDate(selectedFinalDate), 9, selectedUserId))

        }
    }

    const renderRightBar = () => {
        return (
            <div className={'doctor-tab-agenda-right-container'}>
                <span className={'bar-title'}>Cirugías Programadas</span>
                <div className='searc-and-text-agenda'>
                    <button className='btn-search-agenda' onClick={() => handleSurgery()}>
                        <img className='img-search-icon' src={search} />
                    </button>
                    <span className={'bar-date-selected'}>{formatDate() + ' - ' + formatDateFinal()}</span>
                </div>
                <ul className={'doctor-tab-agenda-ul'}>
                    {
                        surgery &&
                        surgery.length > 0 ?
                        surgery.map(renderSurgeryItem)

                        : 
                        surgery && 
                        surgery.length <= 0 &&
                        <p className='txt-agenda-null'>Sin cirugias, en ese rango de fechas</p>
                    }
                </ul>
            </div>
        );
    }

    const renderSurgeryItem = (item, index) => {
        return (
            <li key={item.id} className={'doctor-tab-agenda-li'}>
                <button
                    className={'doctor-tab-agenda-li-header'}
                    onClick={() => toggleOpenSurgeryItem(item)}>
                    {'Cirugia #' + item.id + " - Hospital " + item.hospital.name + " - " + formatHourToPresent(item.surgeryDate) + " hrs"}

                    {
                        selectedSurgeryItem && selectedSurgeryItem.id == item.id &&
                        <img src={blueDownArrowIcon} alt={'FLECHA PARA CERRAR'} />
                    }

                    {
                        (_.isNull(selectedSurgeryItem) || (!_.isNull(selectedSurgeryItem) && selectedSurgeryItem.id != item.id)) &&
                        <img src={blueArrowIcon} alt={'FLECHA PARA ABRIR'} />
                    }
                </button>
                {
                    selectedSurgeryItem && selectedSurgeryItem.id == item.id &&
                    renderSugeryDetail(item, index)
                }
            </li>
        );
    };

    const renderSugeryDetail = (item, index) => {

        return (
            <div className={'doctor-tab-agednda-surgery-detail-body'}>
                <div className={'doctor-tab-agednda-surgery-detail-item'}>
                    <span className={'doctor-tab-agednda-surgery-detail-key'}>Paciente</span>
                    <span className={'doctor-tab-agednda-surgery-detail-value'}>{item.patient && item.patient.firstName + ' ' + item.patient.secondName + ' ' +
                        item.patient.firstLastName + ' ' + item.patient.secondLastName}</span>
                </div>
                <div className={'doctor-tab-agednda-surgery-detail-item'}>
                    <span className={'doctor-tab-agednda-surgery-detail-key'}>Sistema</span>
                    <span className={'doctor-tab-agednda-surgery-detail-value'}>{item.productRequirements ? item.productRequirements[0]  && item.productRequirements[0].product.productDescription : 'NA' }</span>
                </div>
                <div className={'doctor-tab-agednda-surgery-detail-item'}>
                    <span className={'doctor-tab-agednda-surgery-detail-key'}>Configuración </span>
                    <span className={'doctor-tab-agednda-surgery-detail-value'}>{item.productRequirements ? item.productRequirements[0] && item.productRequirements[0].systemLevels : 'NA' }</span>
                </div>
                <div className={'doctor-tab-agednda-surgery-detail-item'}>
                    <span className={'doctor-tab-agednda-surgery-detail-key'}>No. Producto</span>
                    <span className={'doctor-tab-agednda-surgery-detail-value'}>{item.productRequirements ? item.productRequirements[0] && item.productRequirements[0].product.productCode : 'NA'}</span>
                </div>
            </div>
        );
    }

    const formatDate = () => {
        return `${moment(selectedDate).format('DD')} de ${moment(selectedDate).format('MMMM')} del ${moment(selectedDate).format('YYYY')}`;
    }
    const formatDateFinal = () => {
        return `${moment(selectedFinalDate).format('DD')} de ${moment(selectedFinalDate).format('MMMM')} del ${moment(selectedFinalDate).format('YYYY')}`;
    }

    return (
        <div className={'doctor-tab-agenda-container'}>
            {
                renderLeftBar()
            }
            {
                renderRightBar()
            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default withRouter(connect(mapStateToProps)(UserTabAgenda));
