import React, { useEffect } from 'react';
import { useForm } from '../../../hooks/useForm';
import PropTypes from 'prop-types';
import { storeHouseAssignSystemTableModalData } from './storeHouseAssignSystemTableModalData';
import './sass/styles-modal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentLocationsStoreHose, setDestinationPlaceStoreHose, setPickupPlaceStoreHose, updateAppointmentLocationStoreHose, updateAppointmentProductStoreHose } from '../../../actions/storeHouseAction';

export const StoreHouseAssignSystemTableFormModal = ({ itemToChangeState, itemToAddLocation, toggle, result, setResult }) => {


    const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);
    const destinationPlaceStoreHose = useSelector(state => state.storeHouse.destinationPlaceStoreHose);
    const dispatch = useDispatch();


    const [formValuesInput, handleInputChangeInputs] = useForm({
        typeLocation: '',
        pickupPlace: '',
    });

    const { typeLocation, pickupPlace } = formValuesInput;


    const handleTypeLocation = (e) => {
        e.preventDefault();
        let pickupPlaceObject = {}
        if (typeLocation && JSON.parse(typeLocation).location === 'Otro:' ||
            typeLocation && JSON.parse(typeLocation).location === 'Aeropuerto' ||
            typeLocation && JSON.parse(typeLocation).location === 'Central Camionera') {
            if (pickupPlace && itemToAddLocation && itemToAddLocation.product) {
                pickupPlaceObject['Id'] = itemToAddLocation.id;
                pickupPlaceObject['ProductQuantity'] = itemToAddLocation.productQuantity;
                pickupPlaceObject['PickupPlace'] = pickupPlace && pickupPlace;
                pickupPlaceObject['DestinationPlace'] = destinationPlaceStoreHose && destinationPlaceStoreHose;
                setResult(dispatch(updateAppointmentProductStoreHose(pickupPlaceObject)));
                return toggle();
            } else if (pickupPlace && itemToAddLocation && itemToAddLocation.location) {
                pickupPlaceObject['Id'] = itemToAddLocation.id;
                pickupPlaceObject['LocationLevels'] = itemToAddLocation.locationLevels;
                pickupPlaceObject['PickupPlace'] = JSON.parse(typeLocation).location === 'Otro:' ? "Otro" + " - " + pickupPlace : JSON.parse(typeLocation).location === 'Aeropuerto' ? "Aeropuerto" + " - " + pickupPlace 
                : JSON.parse(typeLocation).location === 'Central Camionera' && "Central Camionera" + " - " + pickupPlace || pickupPlace && pickupPlace  ;
                pickupPlaceObject['DestinationPlace'] = destinationPlaceStoreHose && destinationPlaceStoreHose;
                setResult(dispatch(updateAppointmentLocationStoreHose(pickupPlaceObject)));
                dispatch(getAppointmentLocationsStoreHose(appointmentItem.id));

                return toggle();
            }
        }
        else if (typeLocation && itemToAddLocation && itemToAddLocation.product) {
            pickupPlaceObject['Id'] = itemToAddLocation.id;
            pickupPlaceObject['ProductQuantity'] = itemToAddLocation.productQuantity;
            pickupPlaceObject['PickupPlace'] = typeLocation && JSON.parse(typeLocation).location ? JSON.parse(typeLocation).location : JSON.parse(typeLocation).name;
            pickupPlaceObject['DestinationPlace'] = destinationPlaceStoreHose && destinationPlaceStoreHose;
            setResult(dispatch(updateAppointmentProductStoreHose(pickupPlaceObject)));
            return toggle();
        } else if (typeLocation && itemToAddLocation && itemToAddLocation.location) {
            pickupPlaceObject['Id'] = itemToAddLocation.id;
            pickupPlaceObject['LocationLevels'] = itemToAddLocation.locationLevels;
            pickupPlaceObject['PickupPlace'] = typeLocation && JSON.parse(typeLocation).location ? JSON.parse(typeLocation).location : JSON.parse(typeLocation).name;
            pickupPlaceObject['DestinationPlace'] = destinationPlaceStoreHose && destinationPlaceStoreHose;
            setResult(dispatch(updateAppointmentLocationStoreHose(pickupPlaceObject)));
            dispatch(getAppointmentLocationsStoreHose(appointmentItem.id));

            return toggle();
        }


    };

    const handleTypeLocationState = (e) => {
        e.preventDefault();

        if (typeLocation && JSON.parse(typeLocation).location === 'Otro:' ||
            typeLocation && JSON.parse(typeLocation).location === 'Aeropuerto' ||
            typeLocation && JSON.parse(typeLocation).location === 'Central Camionera') {
            if (itemToChangeState.typeModify === 'PickupPlace') {
                dispatch(setPickupPlaceStoreHose(pickupPlace));
            } else if (itemToChangeState.typeModify === 'DestinationPlace') {
                dispatch(setDestinationPlaceStoreHose(pickupPlace));
            }
        } else {
            if (itemToChangeState.typeModify === 'PickupPlace') {
                dispatch(setPickupPlaceStoreHose(typeLocation && JSON.parse(typeLocation).location ? JSON.parse(typeLocation).location : JSON.parse(typeLocation).name));
            } else if (itemToChangeState.typeModify === 'DestinationPlace') {
                dispatch(setDestinationPlaceStoreHose(typeLocation && JSON.parse(typeLocation).location ? JSON.parse(typeLocation).location : JSON.parse(typeLocation).name));
            }
        }

        toggle();
    }

    return (
        <div className="c-StoreHouseAssignSystemTableFormModal">
            <section className="c-StoreHouseAssignSystemTableFormModal-container-title">
                <h2 className="c-StoreHouseAssignSystemTableFormModal-title">{itemToChangeState && itemToChangeState.typeModify === 'DestinationPlace' ? `Lugar de Destino:` : `Lugar de Origen:`}</h2>
            </section>
            <form className="c-StoreHouseAssignSystemTableFormModal-c-list-items" onSubmit={!itemToChangeState ? handleTypeLocation : handleTypeLocationState} >

                {
                    storeHouseAssignSystemTableModalData &&
                    storeHouseAssignSystemTableModalData.map((item,index) => (
                        <>
                            <div key={item.uid} className="c-typeLocation-c-item">
                                <p className="c-typeLocation-item-name">{`${item.location}`}</p>
                                <input
                                key={item.uid} 
                                    className="c-typeLocation-item-check"
                                    type="radio"
                                    checked={typeLocation && typeLocation.uid && JSON.parse(typeLocation).id === item.id}
                                    value={JSON.stringify(item)}
                                    name="typeLocation"
                                    index={index}
                                    onChange={handleInputChangeInputs}
                                />
                            </div>
                        </>
                    ))
                }
                <div className="c-typeLocation-c-item">
                    <p className="c-typeLocation-item-name">{`${appointmentItem && appointmentItem.hospital && appointmentItem.hospital.name}`}</p>
                    <input
                        className="c-typeLocation-item-check"
                        type="radio"
                        checked={appointmentItem && appointmentItem.hospital && typeLocation && JSON.parse(typeLocation).name === appointmentItem.hospital.name}
                        value={appointmentItem && appointmentItem.hospital && JSON.stringify({ name: appointmentItem.hospital.name })}
                        name="typeLocation"
                        onChange={handleInputChangeInputs}
                    />
                </div>
                {
                    (typeLocation && JSON.parse(typeLocation).location === 'Otro:' ||
                        typeLocation && JSON.parse(typeLocation).location === 'Aeropuerto' ||
                        typeLocation && JSON.parse(typeLocation).location === 'Central Camionera') &&
                    <textarea
                        className="c-StoreHouseAssignSystemTableFormModal-item-note"
                        type="text"
                        placeholder="Agregar..."
                        name="pickupPlace"
                        value={pickupPlace}
                        onChange={handleInputChangeInputs}
                        autoComplete="off"
                        required
                        cols="40" rows="5">
                    </textarea>
                }
                <button className="c-StoreHouseAssignSystemTableFormModal-button" type="submit">
                    ACEPTAR
                </button>
            </form>
        </div>
    )
}

StoreHouseAssignSystemTableFormModal.propTypes = {
    toggle: PropTypes.any,
    itemToAddLocation: PropTypes.any,
    itemToChangeState: PropTypes.any,
}
