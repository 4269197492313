import React, { useState } from 'react'
import './styleSurveyMantenimiento.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { ListEncuestasComponent } from '../../components/ListEncuestasComponent/ListEncuestasComponent';

export const SurveyMantenimientoPage = () => {

  const [activeButtons, setActiveButtons] = useState("ACTIVE");
  const [from,setFrom] = useState('dad');

  const handleChange  = (item) => {
    setFrom('dad')
      if(item === 'active'){
        setActiveButtons('ACTIVE')
      } else{
        setActiveButtons('INACTIVE')
      }
  }

  return (

    
    <div className='containerGeneral-encuesta-mantenimiento'>

      <SubNavBarComponent title={'ENCUESTA'} searchActive={true}  historyPage={"/Inicio"} typeSearch={'SurveyPending'}
        specificType={'id'} />

      <div className='body-encuestas-mantenimiento'>

        <section className='btn-encuestas-mantenimiento'>
          <button className={activeButtons === 'ACTIVE'
            ? 'btn-mantenimiento btn-mantenimiento-active'
            : 'btn-mantenimiento'} onClick={() => handleChange('active')}>
            PENDIENTES
          </button>

          <button className={activeButtons === 'INACTIVE'
            ? 'btn-mantenimiento btn-mantenimiento-active'
            : 'btn-mantenimiento'} onClick={() => handleChange('inactive')}>
            FINALIZADOS
          </button>

        </section>

        <section className='body-encuestas-list-mantenimiento' >
          <ListEncuestasComponent 
          activeSwitchVentas={activeButtons} 
          setActiveSwitch={setActiveButtons}
            setFrom = {setFrom}
            from = {from}
          />
        </section>

      </div>

    </div>

  )
}





