import React from 'react';
import { Mess_Mod_Comp } from '../../../components/Messenger/Modal/Mess_Mod_Comp';
import CustomModal from '../../../components/shared/Modal';
import PropTypes from 'prop-types';
import './style.sass';

export const Messenger_Modal = (props) => {
    
    const modalBody = () => {
        return (
            <Mess_Mod_Comp     
            toggle={props.toggle}
            page={props.page}
            setTextRequirements={props.setTextRequirements}
            textRequirement = {props.textRequirement}
            selectedMedics = {props.selectedMedics}
            selectedHospitals = {props.selectedHospitals}
            setList1 = {props.setList1}
                                />
        );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle} 
                title="AGREGAR REQUERIMIENTO"
                body={modalBody()}
        />
    );
}
Messenger_Modal.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};