import shortid from "shortid";


export const storeHouseAssignSystemTableModalData = [
    {
        uid: shortid.generate(),
        location: 'Almacén'
    },
    {
        uid: shortid.generate(),
        location: 'Aeropuerto'
    },
    {
        uid: shortid.generate(),
        location: 'Central Camionera'
    },
    {
        uid: shortid.generate(),
        location: 'Otro:'
    },
]