import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TabsLine from '../../../../../components/shared/Molecules/TabsLine';

import UserTabProfile from './Tabs/UserTabProfile';
import UserTabPatient from './Tabs/UserTabPatient';
import UserTabProcess from './Tabs/UserTabProcess';
import UserTabAgenda from './Tabs/UserTabAgenda';
import UserTabChat from './Tabs/UserTabChat';

import profileImage from '../../../../../assets/img/126PX/profileI.png';
import pacientImage from '../../../../../assets/img/126PX/pacientI.png';
import processImage from '../../../../../assets/img/126PX/processI.png';
import agendaImage from '../../../../../assets/img/126PX/agendaI.png';
import chatImage from '../../../../../assets/img/126PX/chatI.png';

import profileWhiteImage from '../../../../../assets/img/126PX/profile-white-i.png';
import pacientWhiteImage from '../../../../../assets/img/126PX/pacient-white-i.png';
import processWhiteImage from '../../../../../assets/img/126PX/process-white-i.png';
import agendaWhiteImage from '../../../../../assets/img/126PX/agenda-white-i.png';
import chatWhiteImage from '../../../../../assets/img/126PX/chat-white-i.png';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, openSurgery, setOptionsIdSelected } from '../../../../../actions/allUsersAction';



const UsersTabBase = ({ selectedSeller, setSelectedSeller }) => {
    const [userRol, setUserRol] = useState(null)
    const data = useSelector(state => state.allUsers);
    const [tabsOptions, setTablsOptions] = useState(null)
    const dispatch = useDispatch();
    const startDate = '2019-01-22T00:00:00';
    const endDate = '2024-01-22T23:59:00';
    const offset = 15;
    const selectedUserId = useSelector(state => state.allUsers.selectedUserId);
    const rol = useSelector(state => state.allUsers.userRolSelected)
    const idOptions = useSelector(state => state.allUsers)
    const types = ['']
    const otherTypes = ['atq', 'seller', 'adminAtq', 'storer','doctor'];

    useEffect(() => {
        if (data.optionsSelected === 1 && rol === 'atq') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 15, selectedSeller.lastSurgeryId))
        }
    }, [selectedSeller])



    useEffect(() => {
        if (data.userRolSelected) {
            setUserRol(data.userRolSelected)
            if (types.includes(data.userRolSelected)) {
                setTablsOptions([
                    {
                        id: 1,
                        title: 'Perfil',
                        icon: undefined,
                        img: profileImage,
                        imgW: profileWhiteImage,
                    },
                    {
                        id: 2,
                        title: 'Paciente',
                        icon: undefined,
                        img: pacientImage,
                        imgW: pacientWhiteImage,
                    },
                    {
                        id: 3,
                        title: 'Procesos',
                        icon: undefined,
                        img: processImage,
                        imgW: processWhiteImage,
                    },
                    {
                        id: 4,
                        title: 'Agenda',
                        icon: undefined,
                        img: agendaImage,
                        imgW: agendaWhiteImage,
                    },
                    {
                        id: 5,
                        title: 'Chat',
                        icon: undefined,
                        img: chatImage,
                        imgW: chatWhiteImage,
                    },

                ])
            } else if (otherTypes.includes(data.userRolSelected)) {
                setTablsOptions([
                    {
                        id: 1,
                        title: 'Perfil',
                        icon: undefined,
                        img: profileImage,
                        imgW: profileWhiteImage,
                    },
                    {
                        id: 3,
                        title: 'Procesos',
                        icon: undefined,
                        img: processImage,
                        imgW: processWhiteImage,
                    },
                    {
                        id: 4,
                        title: 'Agenda',
                        icon: undefined,
                        img: agendaImage,
                        imgW: agendaWhiteImage,
                    },
                    {
                        id: 5,
                        title: 'Chat',
                        icon: undefined,
                        img: chatImage,
                        imgW: chatWhiteImage,
                    },

                ])
            }
        }
    }, [data])

    const setTabId = (id) => {
        dispatch(setOptionsIdSelected(id))
        if (id === 1 && rol === 'atq') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 15, selectedSeller.lastSurgeryId))
        }
        if (id === 5 && rol === 'doctor') {
            dispatch(openSurgery(true))
            //dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId,0,offset))
            dispatch(getAppointmentsStoreHouseSuccess(null))

        } else if (id === 5 && rol === 'atq') {
            dispatch(openSurgery(true))
            dispatch(getAppointmentsStoreHouseSuccess(null))
            //dispatch(getAppointmentsStoreHouse(startDate, endDate, 2, selectedUserId,0,10))

        } else if (id === 5 && rol === 'adminAtq') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 0, selectedUserId))

        } else if (id === 5 && rol === 'seller') {
            dispatch(openSurgery(true))
            //dispatch(getAppointmentsStoreHouse(startDate, endDate, 9, selectedUserId, 0, offset));
            dispatch(getAppointmentsStoreHouseSuccess(null))
           

        } 


    };

    return (
        <div>
            {
                tabsOptions &&
                <TabsLine
                    options={tabsOptions}
                    selectedTabId={data.optionsSelected}
                    setSelectedTabId={setTabId}
                />}
            <div>


                {
                    data.optionsSelected === 1 && <UserTabProfile
                        selectedSeller={selectedSeller}
                        setSelectedSeller={setSelectedSeller} />
                }
                {

                    data.optionsSelected == 2 && <UserTabPatient />
                }
                {

                    data.optionsSelected == 3 && <UserTabProcess />
                }
                {

                    data.optionsSelected == 4 && <UserTabAgenda />
                }
                {

                    data.optionsSelected == 5 && <UserTabChat />
                }
            </div>
        </div>
    )
}

export default UsersTabBase;
