import React, { useState } from 'react'
import { useForm } from '../../../hooks/useForm';
import modifyImgIcon from '../../../assets/img/126PX/Icon_edit.png';
import PropTypes from 'prop-types';
import './sass/styles.scss';

export const StoreHouseApproveStatusEditFieldComponent = ({itemElement}) => {


    const [ isEdit, setIsEdit ] = useState('NO-EDIT');

    const handleChangeItemToSubmit = (e) => {
        e.preventDefault();
        setIsEdit('NO-EDIT');
    }


    const [ formValues, handleInputChange ] = useForm({ 
        editText: ''
    });

    const { editText } = formValues;

    const handleChangeInfoToInput = () => {
        setIsEdit('EDIT');
        handleInputChange( {target: {name: 'editText', value: itemElement.value}});
    }

    return (
        <div className='StoreHouseApproveStatusEditFieldComponent-component'>
        {
            isEdit === 'NO-EDIT' &&
                <div className='StoreHouseApproveStatusEditFieldComponent-noEdit-component'>
                    <p className='StoreHouseApproveStatusEditFieldComponent-title'>
                        {itemElement.value}
                    </p>
                    <img className='StoreHouseApproveStatusEditFieldComponent-img' src={modifyImgIcon} alt="" onClick={handleChangeInfoToInput} />
                </div>
        }
        {
            isEdit === 'EDIT' &&
                <form className='StoreHouseApproveStatusEditFieldComponent-form' onSubmit={handleChangeItemToSubmit} >
                    <input 
                        className='StoreHouseApproveStatusEditFieldComponent-input'
                        type="text"
                        name='editText'
                        value={editText}
                        onChange={handleInputChange}
                    />
                    <button className='StoreHouseApproveStatusEditFieldComponent-button' type='submit' ></button>
                </form>
        }
    </div>
    )
}


StoreHouseApproveStatusEditFieldComponent.propTypes = {
    itemElement: PropTypes.any,
}