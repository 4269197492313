import React from 'react';
import './styles.scss';
import atqAddMessage from '../../../assets/img/512PX/atqAddMessage.png';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const AsignarAtqMessagePage = ({nombre="Omar Rodriguez", idCX=1}) => {
  
  const surgerySelected = useSelector( (state) => state.atqAdministrador.surgeryChosed);
  const atq = useSelector( (state) => state.atqAdministrador.ATQChosed);
  const history = useHistory();
  
  const handleNivagate = () => {
        history.replace(`/ATQAdmin_Inicio`);
}

    return (
      <div className={"AsignarAtq_finish"}>
        <div className={"AsignarAtq_finish_general_c"}>
          <div className={"AsignarAtq-images"}>
            <img className="AsignarAtq-success-img" src={atqAddMessage} alt="" />
          </div>
                <p className={"AsignarAtq_finish-subtitle"}>{`Se ha Asignado a ${atq.firstName && atq.firstName} ${atq. secondName && atq. secondName} ${atq. firstLastName && atq. firstLastName} ${atq. secondLastName && atq. secondLastName}`} </p>
                <p className={"AsignarAtq-number"}>{ `CX #${surgerySelected.id} `}</p>
          <div className={"AsignarAtq_order_buttons"}>
            <button className="AsignarAtq-white-button" onClick={() => handleNivagate()}>
              <span> Aceptar </span>
            </button>
          </div>
        </div>
      </div>

    )
}
