import React from 'react';
import './styles.scss';
import diasDeGuardia from '../../../assets/img/512PX/diasDeGuardia.png';
import rolDeGuardias from '../../../assets/img/512PX/rolDeGuardias.png';

export const ATQAdminFinalMessage = ({back="DiasDeGuardia"}) => {

    let mensaje = "";
    
        back === "RolDeGuardia"
        ? mensaje="ROL DE GUARDIAS GUARDADO"
        : mensaje="DIAS DE GUARDIA SELECCIONADOS"
    
    return (
        <div className={"ATQAdmin_finish"}>
        <div className={"ATQAdmin_finish_general_c"}>
          <div className={"ATQAdmin-images"}>
          {
          back === "RolDeGuardia"
                ? <img className="ATQAdmin-success-img" src={rolDeGuardias} alt="" />
                : <img className="ATQAdmin-success-img" src={diasDeGuardia} alt="" />
          }
            
          </div>
                <p className={"ATQAdmin_finish-subtitle"}>{` ${mensaje}`} </p>
                {/* <p className={"ATQAdmin-number"}>{ `CX #${idCX} `}</p> */}
          <div className={"ATQAdmin_order_buttons"}>
            <button
              className="ATQAdmin-white-button"
      
            >
              <span> Aceptar </span>
            </button>
          </div>
        </div>
      </div>
    )
}
