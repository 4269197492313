import React, { useRef, useState } from 'react';
import PropTypes from "prop-types";
import "./sass/styles.scss";
import addImg from "../../../assets/img/256PX/Icono_Agregar-Verde_256PX.png";
import { useDispatch, useSelector } from 'react-redux';
import icon_dele from "../../../assets/img/256PX/icon_dele.png";
import icon_edit from "../../../assets/img/256PX/icon_edit.png";
import { addGuardGroupHoliday, deleteGuardGroupHoliday } from '../../../actions/guardGroupHolidayAction';
import { ModalOrderSpecific } from '../ATQ_GuardRole_CreateTeam_Component/ModalOrderTeamInSpecific/ModalOrderSpecific';



export const ATQ_Administrator_GuardRol_component = ({ month, listDays, guardTeam, setGuardGroupHoliday, addGroupHoliday }) => {

    const [listElementsActive, setListElementsActive] = useState([]);
    const listTeamGroup = useSelector((state) => state.atqAdministrador.group);
    const [openModal, setOpenModal] = useState('');
    const [idDay, setIdDay] = useState();
    const [memberGroup, setMemberGroup] = useState();
    const ban = useRef(0);

    /* 
        console.warn('este es:',listDays); */
    const toggleModal = () => {
        /* ban.current = 0; */
        setOpenModal('');
    };
   /*  const changeBan = () => {
        ban.current = 1
    }; */

    // const holidays = useSelector(state => state.atqAdministrador.holidays);
    let byOrder = [];

    const dispatch = useDispatch();


    /* const orderMembers = (members) => {
        byOrder = members.slice(0);
        byOrder.sort(function (a, b) {
            return a.orderInGroup - b.orderInGroup;
        });
    } */

    //Funcion para abrir el modal de ordenamiento

    const handleOpenModalOrder = (item) => {
        setIdDay(item.idDelete);
        setMemberGroup(item.members);
        setOpenModal('ORDER-TEAM');

    }


    const handleAddTeam = (day) => {
        if (guardTeam) {
            const data = {
                GuardGroupId: guardTeam.id,
                GuardGroupHoliday: {
                    Id: day.id
                }
            }
            dispatch(addGuardGroupHoliday(data));
        }
    }

    const handleDeleteGuardGroupHoliday = (el) => {
        dispatch(deleteGuardGroupHoliday(el.idDelete));
    }


    return (
        <div className="ATQ_Administrator_GuardRol_component">
            <h3 className="ATQ_Administrator_GuardRol_title">{month}</h3>
            <div className="ATQ_Administrator_GuardRol_day_component">
                {
                    listDays &&
                    listDays.map((el) => (
                        <div className="ATQ_Administrator_GuardRol_day_item_component" key={el.id}>

                            <span className='txtNameGroup'>{el.groupName && el.groupName}</span>
                            
                            {
                                listElementsActive.every((item) => item.id !== el.id) &&
                                <div className={`${ el.groupId ? 'ATQ_Administrator_GuardRol_day_list_component' :'ATQ_Administrator_GuardRol_day_list_component-Add'}` }>
                                {/* `${ el.groupId ? 'ATQ_Administrator_GuardRol_day_list_component' :'ATQ_Administrator_GuardRol_day_list_component-Add'}` */}

                                    {el.groupId
                                        ? <ol className='li_group'>{
                                            (el.members.map(miembro => (

                                                <li className='group-item-name'>{miembro.user.firstName + ' '}
                                                    {miembro.user.firstLastName}
                                                </li>
                                            )
                                            ))
                                        }
                                        </ol>
                                        
                                        : <img src={addImg} alt="" className={"ATQ_Administrator_GuardRol_day_img"} onClick={() => handleAddTeam(el)} />
                                    }

                                  
                                </div>
                            }
                                {
                                    el.groupId &&
                                        <div className="ATQ_Administrator_GuardRol_day_team_icons_component-members">
                                            <img src={icon_dele} alt="" className={"ATQ_Administrator_GuardRol_day_team_icon-members"} onClick={() => handleDeleteGuardGroupHoliday(el)}/>
                                            <img src={icon_edit} alt="" className={"ATQ_Administrator_GuardRol_day_team_icon-members"} onClick={() => handleOpenModalOrder(el) } />
                                        </div>
                                    } 
                               

                            
                            <span className="ATQ_Administrator_GuardRol_text ATQ_Administrator_GuardRol_text_day">{el.day}</span>
                            <span className="ATQ_Administrator_GuardRol_text ATQ_Administrator_GuardRol_text_date">{el.date}</span>
                        </div>



                    ))
                }

            </div>
            {
                openModal === "ORDER-TEAM" &&
                <ModalOrderSpecific
                    isOpen={!!openModal}
                    toggle={toggleModal}
                    members={memberGroup}
                    idGroup={guardTeam.id}
                    idDay={idDay}

                />
            }

        </div>
    )


}


ATQ_Administrator_GuardRol_component.propTypes = {
    month: PropTypes.any,
    listDays: PropTypes.any,
    guardTeam: PropTypes.any,
}
