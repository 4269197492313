import React, { useEffect, useState } from 'react'
import { StarQualificationComponent } from '../StarQualification/StarQualificationComponent';
import StarsComponent from '../Stars/StarsComponent';
import './styleVendedorEncuestas.scss';
//import { Rating } from 'react-simple-star-rating'


export const VendedorEncuestaComponent = ({ title,valueSales ={
  AssortmentSupplyCommentary: "",
  AssortmentSupplyRate:0,
  DeliveryMaterialCommentary: "",
  DeliveryMaterialRate: 0,
},setValueSales,Switch,commentarySur,commentaryEnt,pointsSur,pointsEnt}) => {

  const [valueCX,setValueCX] = useState(null)
  const [valueMaterial,setValueMaterial] = useState(null)

  const{
    AssortmentSupplyCommentary,
    AssortmentSupplyRate,
    DeliveryMaterialCommentary,
    DeliveryMaterialRate,
    
  } = valueSales;

  useEffect(() => { 
    setValueSales({...valueSales, AssortmentSupplyRate: valueCX, DeliveryMaterialRate: valueMaterial })

  }, [valueCX,valueMaterial])



 /*  console.warn('este es el swtch:',Switch) */


  return (

    <div className='container-general-vendedor-survey'>

      <section className='header-title-vendedor-survey'>
        <p className='text-title-vendedor-survey'>{title}</p>
      </section>

      <section className='body-surtido-vendedor-survey'>
        <p className='subtext-title-vendedor-survey' >¿Cómo califica el surtido de CX?</p>
        {
          Switch === 'INACTIVE'
            ?<StarsComponent valoracion={pointsSur ?pointsSur :0} />
            :<StarQualificationComponent valueSales={valueCX} setValueSales={setValueCX}/>
        }
        <textarea className='textarea-vendedor-survey' 
        placeholder='Agregar comentario...'
        disabled =  {Switch === 'INACTIVE' ?true :false}
        value={commentarySur ?commentarySur  :AssortmentSupplyCommentary ?AssortmentSupplyCommentary :''}
        onChange={(e) => setValueSales({...valueSales,AssortmentSupplyCommentary: e.target.value})}>
        </textarea>
      </section>

      <section className='body-surtido-vendedor-survey'>
        <p className='subtext-title-vendedor-survey' >¿Cómo califica la entrega de material?</p>
        {
          Switch === 'INACTIVE'
            ?<StarsComponent valoracion={pointsEnt ?pointsEnt :0} />
            :<StarQualificationComponent valueSales={valueMaterial} setValueSales={setValueMaterial}/>
        }
        <textarea className='textarea-vendedor-survey' 
          placeholder='Agregar comentario...'
          disabled =  {Switch === 'INACTIVE' ?true :false}
          value={commentaryEnt ? commentaryEnt  :DeliveryMaterialCommentary ? DeliveryMaterialCommentary :''}
          onChange={(e) => setValueSales({...valueSales,DeliveryMaterialCommentary: e.target.value})}/>
      </section>


    </div>

  )
}
