import React, { useEffect, useState } from 'react';
import './styleModalConversation.scss';
import icon_status from '../../assets/img/126PX/icon-status-user.png';
import icon_perfil_user from '../../assets/img/126PX/icon-perfil-user.png';
import icon_minimize from '../../assets/img/126PX/icon-minimize-modal.png';
import icon_close from '../../assets/img/126PX/icon-close-modal.png';
import icon_users from '../../assets/img/126PX/icon-users-group.png';
import icon_send_img from '../../assets/img/126PX/Icon-feather-image.png'
import { MessageLeftComponent } from './MessageComponent/MessageLeftComponent';
import { MessageRigthComponent } from './MessageComponent/MessageRigthComponent';
import { useDispatch, useSelector } from 'react-redux';
import { addMessagesToChatGroup, getMessagesToChatGroup, get_chat_group_mesagges_success, openChatEmergent, setChatGroupSelected } from '../../actions/notification-chatAction';
import { Members_Group_Modal_Chat } from './MembersComponent/Members_Group_Modal_Chat';
import { InputSendChat } from './MessageComponent/InputSendChat';
import img_send_azul from '../../assets/img/126PX/insertar-icono-de-imagen.png'


export const ModalConversation = () => {
    const minimize = useSelector(state => state.notificationChat.openChat)
    const dispatch = useDispatch()
    const [openModalAdd, setOpenModalAdd] = useState(null)
    const [cxSelected, setCxSelected] = useState(null)
    const updateMainObject = () => { };

    const nameGroup = useSelector(state => state.notificationChat.setChatGroupSelected.nameChat)
    const chatId = useSelector(state => state.notificationChat.setChatGroupSelected.chatId)
    const messages = useSelector(state => state.notificationChat.getMessages)
    const user = useSelector(state => state.user.user.identity)
    const [messageRigth, setMessageRigth] = useState()
    const [imgBase64, setImgBase64] = useState(null)







    useEffect(() => {
        if (minimize === false) {
            ScrollToBottom();

        }
    })

    useEffect(() => {
        if (minimize === false) {
            dispatch(getMessagesToChatGroup(chatId, 0, 50))
        }
    }, [minimize])



    useEffect(() => {
        if (messages) {
            let msg = [...messages]
            const messagesReverse = msg.reverse();
            setMessageRigth(messagesReverse)

        }

    }, [messages])


    useEffect(() => {
        dispatch(getMessagesToChatGroup(chatId, 0, 50))

        if (minimize !== true && minimize !== false) {
            dispatch(openChatEmergent(false))
        }
        return () => {
            dispatch(get_chat_group_mesagges_success([]));
        }
    }, [chatId])

    const handleClose = () => {
        dispatch(setChatGroupSelected(false, chatId, nameGroup))
    }



    const handleMinimize = () => {
        dispatch(openChatEmergent(true))
    }

    const openChat = () => {
        dispatch(openChatEmergent(false))
    }
    const openChat1 = () => {

    }

    const closeModal = (item) => {
        if (openModalAdd === 'openMembers') {
            setOpenModalAdd(null);
        }
    }

    const toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                return resolve({
                    Base64Image: reader.result
                });
            }
            reader.onerror = error => reject(error);
        });

    };

    const handleSendImage = async (event) => {
        const imageFile = event.target.files;

        let arrImgBase64 = {};
        const arrImageFile = Object.entries(imageFile);
        const numberArrImageFile = arrImageFile.length;

        for (let i = 0; i < numberArrImageFile; i++) {
            const base64Image = await toBase64(arrImageFile[i][1
            ])
                .then(img => {
                    return img;
                })
            if (base64Image) {
                arrImgBase64 = { ...base64Image };
            }


        }


        if (arrImgBase64) {
            // const data = { hotelId: props.hotelId, media: arrImgBase64 };
            // const resultado = await dispatch(createPhotoHotel(data));
            // if(resultado === true) {
            const img = arrImgBase64.Base64Image.split(',')[1]
            setImgBase64(img)
            //     props.reloadHotel();
            // }
        }
    }

    const ScrollToBottom = () => {
        const scrollId = document.getElementById("scrollHeigth")
        scrollId.scrollTo(0, scrollId.scrollHeight);
    }


    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

        if (scrollHeight - Math.round(scrollTop) === clientHeight) {
            setPage(prev => prev + 1)
        }
    }

    return (
        <div className={`${minimize ? 'container-modal-chat-messages-condition ' : 'container-modal-chat-messages'}`}
            onscroll={handleScroll}>

            <div className={`${minimize ? 'header-modal-chat-msg-condition' : 'header-modal-chat-msg'}`}
                onClick={minimize ? () => openChat() : openChat1()}
            >
                <div className={`${minimize ? 'header-modal-chat-left-condition' : 'header-modal-chat-left'}`}>
                    <img src={icon_perfil_user} className={`${minimize ? 'img-modal-chat-header-user-condition' : 'img-modal-chat-header-user'}`} />
                    <p className={`${minimize ? 'title-modal-chat-header-condition' : 'title-modal-chat-header'}`}>{nameGroup && nameGroup}</p>
                    <img src={icon_status} className={`${minimize ? 'img-modal-chat-header-status-condition' : 'img-modal-chat-header-status'}`} />
                </div>

                {!minimize &&

                    <div className='header-modal-chat-rigth'>
                        <img src={icon_users} className='icon-modal-user' onClick={() => setOpenModalAdd('openMembers')} />
                        <img src={icon_minimize} className='icon-modal-minimeze' onClick={() => handleMinimize()} />
                        <img src={icon_close} className='icon-modal-close' onClick={() => handleClose()} />
                    </div>

                }
            </div>

            {!minimize &&
                <>
                    <div className='body-mensajes-chat-modal' id='scrollHeigth'>

                        {

                            messageRigth &&
                            messageRigth.length > 0 &&
                            messageRigth.map((item) => (
                                item.sender.userID === user.userID ?
                                    <MessageRigthComponent
                                        messages={item.message}
                                        userSend={item.sender.firstName}
                                        hour={item.createdAt}
                                        media={item.media ? item.media.mediaURL : null} />
                                    :
                                    <MessageLeftComponent
                                        messages={item.message}
                                        userSend={item.sender.firstName}
                                        hour={item.createdAt}
                                        media={item.media ? item.media.mediaURL : null}

                                    />
                            ))

                        }
                    </div>
                    <div className='footer-send-chat-modal'>
                        <InputSendChat
                            imgBase64={imgBase64}
                            setImgBase64={setImgBase64} />
                        <div className='container_input_send_image'>
                            <input className='input_file_chat' type='file' accept='image/*' onChange={(e) => handleSendImage(e)} />
                            <img src={`${imgBase64 != null ? img_send_azul : icon_send_img}`} className='add-image-chat-modal' onClick={ handleSendImage} />
                        </div>
                    </div>
                </>
            }

            {openModalAdd === 'openMembers' &&
                <Members_Group_Modal_Chat
                    isOpen
                    toggle={closeModal}
                    updateMainObject={updateMainObject}
                    setOpenModal={setOpenModalAdd}
                    setCxSelected={setCxSelected}
                    setOpenModalAdd={setOpenModalAdd}
                    openModalAdd={openModalAdd}

                />}

        </div>
    )
}
