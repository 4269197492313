import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChatGroupMembers, get_chat_group_members_success } from '../../../actions/notification-chatAction';
import './styleDataMembers.scss'


export const ListMembersData = () => {


    const members = useSelector(state => state.notificationChat.getChatGroupMembers);
    const dispatch = useDispatch();
    const chatId = useSelector(state => state.notificationChat.setChatGroupSelected.chatId)
    
    useEffect(() => {
        dispatch(getChatGroupMembers(chatId));
        return () => {
            dispatch(get_chat_group_members_success([]))
        }
    }, [])


    const TypeRol = (roleID) => {
        switch (roleID) {
            case 1:
                return 'Vendedor';
                break;
            case 2:
                return 'Medico';
                break;
            case 3:
                return 'Totvs';
                break;
            case 4:
                return 'Administrador';
                break;
            case 5:
                return 'ATQ';
                break;
            case 6:
                return 'Supervisor de zona';
                break;
            case 7:
                return 'Distribuidor';
                break;
            case 8:
                return 'Licitador';
                break;
            case 9:
                return 'Director';
                break;
            case 10:
                return 'Departamento Facturacion';
                break;
            case 11:
                return 'Administrador ATQ';
                break;
            case 12:
                return 'Mensajero';
                break;
            default:
        }
    }


    return (
        <div className='container-list-member-chat-modal'>
            {members &&
                members.length > 0 &&
                members.map((item) => (
                    <div className='card-modal-chat'>

                        <div className='name-memebers-list-modal-chat'>
                            <p className='title-style-members-modal'>{(item.user && item.user.firstName && item.user.firstName) + ' ' +
                                (item.user.secondName && item.user.secondName) + ' ' + (item.user.firstLastName && item.user.firstLastName) + ' ' +
                                ((item.user.secondLastName && item.user.secondLastName))}</p>
                        </div>

                        <div className='clasification-memebers-chat-modal'>
                            <p className='title-clasification-members-modal'>{TypeRol(item.user.roleID)}</p>
                        </div>

                    </div>
                ))
            }

        </div>
    )
}
