import React, { useState } from 'react';
import './styleMembersChat.scss';
import icon_add from '../../../assets/img/126PX/IcoAgregarCi.png';
import { ListMembersData } from './ListMembersData';
import { useDispatch, useSelector } from 'react-redux';
import { getChatGroupMembers, get_chat_group_members_success } from '../../../actions/notification-chatAction';
import { useEffect } from 'react';
import { Add_Members_Component_Modal } from '../AddMembersModal/Add_Members_Component_Modal';

export const MembersComponent = ({setOpenModalAdd,openModalAdd}) => {

  const dispatch = useDispatch();
  const chatId = useSelector(state => state.notificationChat.setChatGroupSelected.chatId)
  const [cxSelected, setCxSelected] = useState(null)
  const [openModalAddMembers, setOpenModalAddMembers] = useState(null)
  const updateMainObject = () => { };

  useEffect(() => {
    dispatch(getChatGroupMembers(chatId));
    return () => {
      dispatch(get_chat_group_members_success([]))
    }
  }, [])

  const closeModal = (item) => {
    if (openModalAddMembers === 'openListMembers') {
      setOpenModalAddMembers(null);
      
    }
  }

  const openModal = () => {
    setOpenModalAddMembers("openListMembers")
   
  }

  return (
    <div className='container-members-modal-chat'>

      <div className='subcontainer-memebers-modal-chat'>
        <ListMembersData />
      </div>

      <div className='container-footer-modal-members'>
        <img className='add-member-group-chat' src={icon_add} onClick={() => openModal()} />
      </div>


      {openModalAddMembers === 'openListMembers' &&
        <Add_Members_Component_Modal
          isOpen
          toggle={closeModal}
          updateMainObject={updateMainObject}
          setOpenModal={setOpenModalAddMembers}
          setCxSelected={setCxSelected}
          setOpenModalAddMembers={setOpenModalAddMembers}
          openModalAddMembers={openModalAddMembers}
          setOpenModalAdd={setOpenModalAdd}

        />}




    </div>
  )
}
