import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ATQ_List_Rep_Exp_Com } from '../../../components/ATQ/ATQ_REPORT_EXPERIENCE/ATQ_List_Rep_Exp_Com';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './style.scss';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';

export const ATQ_Rep_Exp_List_Page = () => {
    const history = useHistory;
    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');

   /*  const hanleClick = () => {

        history.replace(`/ATQ_Inicio/exp_report/Add`);
    } */

    return (
        <>
        <ComingSoonComponent />
        {/*<div className = "precontainer_page">

        {activeSwitch === "ACTIVE" &&
        <SubNavBarComponent 
        title={'REPORTE DE EXPERIENCIA'} 
        searchActive={true} 
        historyPage={'/Inicio'}
        typeSearch={"ExperienceIncomplete"}
        specificType={'id'} 
        />
        }

        {activeSwitch === "INACTIVE" &&
        <SubNavBarComponent 
        title={'REPORTE DE EXPERIENCIA'} 
        searchActive={true} 
        historyPage={'/Inicio'}
        typeSearch={"ExperienceComplete"}
        specificType={'id'} 
         />
        }


            <div className="container_page">
                <div className="container_buttons">
                     <nav className="toogle_container_buttons">
                            <button className={ activeSwitch === 'ACTIVE'
                               ? "normal_button-exp btn_left-exp btn_active-exp"
                               : "normal_button-exp btn_left-exp"} onClick ={() => setActiveSwitch('ACTIVE')}>
                                PENDIENTES
                            </button>
                            <button className={ activeSwitch === 'INACTIVE'
                               ? "normal_button-exp btn_rigth-exp btn_active-exp"
                               : "normal_button-exp btn_rigth-exp"} onClick ={() => setActiveSwitch('INACTIVE')}>
                                FINALIZADOS
                            </button>
                     </nav>
                </div>
                <div className="section_list">
                <ATQ_List_Rep_Exp_Com
                    activeSwitch = {activeSwitch}
                />
                </div>
                
            </div>
        </div>*/}
        </>
    )
}
