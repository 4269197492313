import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import deleteImg from '../../assets/img/512PX/Icono_Eliminar_512px.png'
import React, { useEffect,useState } from 'react'
import { getInstitutionsSucess, getMedicsSucess, RemoveHospitalRequirement, RemoveMedicRequirement } from '../../actions/systemsAction';
import { MedicHospitalModalAddRequerimentComponent } from './MedicHospitalModalAddRequerimentComponent';

export const MedicHospitalRequerimentsComponent = ({itemselected, setItemselected, typeView}) => {
    const {requirements} = itemselected;

    const dispatch = useDispatch();
    const institutions = useSelector(state => state.systems.getInstitutions);
    const Medics = useSelector(state => state.systems.getMedics);
    const [openModal, setOpenModal] = useState(null);
    const [listElements, setListElements] = useState(null);

    const toggleModal = () => {
        setOpenModal(null);
    };

    useEffect(() => {
        if(typeView === 'Hospital'){
            setListElements(
                {
                    Hospital: 'Hospital', 
                    function: getInstitutionsSucess, 
                    list: institutions, 
                    apiRequest: RemoveHospitalRequirement 
                });
        }else if(typeView === 'Medic'){
            setListElements(
                {
                    Medic: 'Medic', 
                    function: getMedicsSucess, 
                    list: Medics,
                    apiRequest: RemoveMedicRequirement
                });
        }

    }, [typeView, institutions, Medics])

    const handleDeleteItemCardClick = (element) => {
        if(  itemselected && itemselected.requirements.length > 0 &&
             itemselected.requirements.filter( item =>  item.id === element.id).length !== 0){

                setItemselected({...itemselected,
                    requirements: itemselected.requirements.filter(el => el.id !== element.id)
            })
            if(listElements[typeView]){
                dispatch(listElements.function( listElements.list.map(item => {
                    if(item.id === itemselected.id){
                        return item = {...itemselected,
                            requirements: itemselected.requirements.filter(el => el.id !== element.id)
                        }
                    }
                    return item;
                })));
            }

            dispatch(listElements.apiRequest(element.id));
        }
        
    }
    

    return (
        <section className="container_medic_hospital_list_component medic_hospital_list_component_rigth">
            <div className="medic_hospital_list_component_container_title">
                <h3 className="medic_hospital_list_component_title"> Requerimientos </h3>
            </div>
            <div className="medic_hospital_list_component_container_cards medic_hospital_list_component_cards_rigth">
                {
                    requirements && requirements.length > 0 &&
                        requirements.map(el => (
                            <div className={ 'medic_hospital_list_component_container_card medic_hospital_list_component_container_card_requeriment'}
                                key={el.id}
                            >
                                <div className="medic_hospital_list_component_card_container_info medic_hospital_list_component_card_container_info_requeriments">
                                    <span className="medic_hospital_list_component_card_info_text">{el.requirement}</span>
                                    <div className="medic_hospital_list_component_card_container_info_img" onClick={() => handleDeleteItemCardClick(el)}>
                                        <img src={deleteImg} alt="" className="medic_hospital_list_component_card_info_img" />
                                    </div>
                                </div>

                            </div>
                        ))
                }
            </div>
            <div className="medic_hospital_list_component_cards_rigth_container_button">
                <button className={'medic_hospital_list_component_cards_rigth_button'} onClick={ () => setOpenModal("NewRequeriment") } >
                    Agregar
                </button>

            </div>

            {
                openModal === "NewRequeriment" &&
                <MedicHospitalModalAddRequerimentComponent
                    isOpen
                    toggle={toggleModal}
                    itemselected={itemselected}
                    setItemselected={setItemselected}
                    typeView={typeView}

                />
            }
        </section>
                    
    )
}


MedicHospitalRequerimentsComponent.propTypes = {
    itemselected: PropTypes.any,
    setItemselected: PropTypes.any,
    typeView: PropTypes.any,
}