import React from 'react'
import { useHistory } from 'react-router-dom';
import { ReportExperienceListCom } from '../../../components/Director/ReportExperience/ReportExperienceListCom';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './style.scss';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';
export const ReportExperienceList = () => {
    const history = useHistory();

    return (
        <>
        <ComingSoonComponent/>
        {/*<div className = "precontainer_page_directory">
        <SubNavBarComponent 
        title={'REPORTE DE EXPERIENCIA'} 
        searchActive={true} 
        historyPage={'/Inicio'}
        typeSearch={"GlobalExperienceComplete"}
        specificType={'id'} 
         />
        
            <div className="container_page_directory">
                <div className="section_list_directory">
                <ReportExperienceListCom/>
                </div>
                
            </div>
        </div>*/}
        </>
    )
  
}
