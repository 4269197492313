import React from 'react';
import './sass/styles.scss';
import PropTypes from 'prop-types';

export const ATQAdministrator_Assign_ATQ_TableUbication_Component = ({typeListTable,info}) => {

    /* console.warn('listado de informacion:',info) */



    let letter = typeListTable === 'surtido' ?'Material Surtido' : typeListTable === 'Sistemas' ? 'Sistemas' :'Material No Surtido' 
    return(
        <>
        
            <div className="ATQAdministrator_Assign_ATQ_TableUbication-precontainer">
                <div className="ATQAdministrator_Assign_ATQ_TableUbication-container">
                    <table className="ATQAdministrator_Assign_ATQ_TableUbication-table-container">
                        <thead className="ATQAdministrator_Assign_ATQ_TableUbication-table-thead">
                            <tr className="ATQAdministrator_Assign_ATQ_TableUbication-table-row">
                                <th className="ATQAdministrator_Assign_ATQ_TableUbication-table-title">{letter}</th>
                                <th className="ATQAdministrator_Assign_ATQ_TableUbication-table-title">Cantidad</th>
                                <th className="ATQAdministrator_Assign_ATQ_TableUbication-table-title">Ubicación</th>
                            </tr>
                        </thead>

                        
                        
                        <tbody className={`${( typeListTable === 'Sistemas' ? "ATQAdministrator_Assign_ATQ_TableUbication-table-body-sistemas" : "ATQAdministrator_Assign_ATQ_TableUbication-table-body")}`}>

                            {
                                info &&
                                info.length > 0 
                                ?info.map( item => (
                                    <>
                                        <tr className="ATQAdministrator_Assign_ATQ_TableUbication-table-body-row">
                                                <td className="ATQAdministrator_Assign_ATQ_TableUbication-table-data_description">{item.product  ?item.product.productDescription :item.location.description ?item.location.description :'-'}</td>
                                                <td className="ATQAdministrator_Assign_ATQ_TableUbication-table-data_quantity">{item.productQuantity ?item.productQuantity :item.locationLevels ?item.locationLevels :'1'}</td>
                                                <td className="ATQAdministrator_Assign_ATQ_TableUbication-table-data_ubicacion">{item.locastionInfo ?item.locastionInfo.ubicacion ?item.locastionInfo.ubicacion :item.ubicacion  :item.notStockedNote ? item.notStockedNote : 'No surtido'}</td>
                                            </tr>
                                    {
                                        item.locationBoxIDs &&
                                            item.locationBoxIDs.map((item2,i) => (
                                                i > 0 &&
                                                <tr className='ATQAdministrator_Assign_ATQ_TableUbication-table-body-row-extra'>
                                                    <td className="ATQAdministrator_Assign_ATQ_TableUbication-extra">{item2}</td>
                                                </tr>
                                            ))
                                        } 
                                    </>
                                ))
                                :
                                <tr className="ATQAdministrator_Assign_ATQ_TableUbication-table-body-row">
                                        <td className="ATQAdministrator_Assign_ATQ_TableUbication-table-data_description">-</td>
                                        <td className="ATQAdministrator_Assign_ATQ_TableUbication-table-data_quantity">-</td>
                                        <td className="ATQAdministrator_Assign_ATQ_TableUbication-table-data_ubicacion">-</td>
                                </tr>
       
                            }
                            </tbody>
                  </table>

             </div>
        </div>
        </>
        );
    }

ATQAdministrator_Assign_ATQ_TableUbication_Component.propTypes = {
    typeListTable: PropTypes.any,
}