import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from "react-router-dom";
import Button from '../../../../../../components/shared/Atoms/Button';
import CustomModal from '../../../../../../components/shared/Modal';
import './UserTabPatient.scss';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, getMedicsListSuccess, getPatientsInUser } from '../../../../../../actions/allUsersAction';

// ASSETS
import userIcon from '../../../../../../assets/img/126PX/Iconawesome-user-circle.png';
import pdfIcon from '../../../../../../assets/img/126PX/Iconmetro-file-pdf.png';
import _ from 'lodash';
import { formatDateToPresent } from '../../../../../../hooks/useFormatDateInitialAndFinalDay';
import { getViewPdf } from '../../../../../../actions/systemsAction';

const UserTabPatient = () => {
    const dispatch = useDispatch();
    // const [ patients, setPatients ] = useState(null)
    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';
    const selectedUserId = useSelector(state => state.allUsers.selectedUserId);
    const patients = useSelector(state => state.allUsers.appointmentsList)
    const [currentPage, setPage] = useState(0);
    const offset = 10;
    const [listPatients, setListPatients] = useState([])

    const history = useHistory()

    useEffect(() => {
        if (currentPage != 0) {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId, currentPage, offset));
        }

    }, [currentPage])


    useEffect(() => {
        dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId, 0, offset));
        setPage(0)
        setListPatients([])
        return () => {
            dispatch(getAppointmentsStoreHouseSuccess(null))
        }
    }, [selectedUserId])


    //console.log('listPatients',listPatients)



    useEffect(() => {
        if (patients && patients.length > 0) {
            setListPatients([...listPatients, ...patients])
        }
    }, [patients])

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }







    // const data = useSelector(state => state.allUsers);
    // useEffect(() => {
    //     if (data.patientsInUser) {
    //         setPatients(data.patientsInUser)
    //     }
    // }, [data])

    const [openModal, setOpenModal] = useState(false);
    const [patientSelected, setPatientSelected] = useState(null);


    const surgeriesOnClick = (item) => {
        setPatientSelected(item);
        dispatch(getMedicsListSuccess(null))
        history.push(`/usuarios/pacientes/${item.patient.id}`)


    };

    const handleDownloadPDF = (id) => {
        if (id === 0) {
            return null
        } else {
            dispatch(getViewPdf(id))
        }

    }


    // const modalBody = () => {
    //     return (<h1>{patientSelected.name}</h1>);
    // };

    const renderPatientItem = (item, index) => {
        return (

            item.patient &&
            <div className={'doctor-tab-patient-container'} >
                <div className={'doctor-tab-patient-bloque-1'}>
                    <img src={userIcon} alt={'IMG'} />
                </div>
                <div className={'doctor-tab-patient-bloque-2'}>
                    <span className={"blue_dark_text"}>{item.patient.firstName + ' ' + item.patient.firstLastName}</span>
                    <span className={"blue_light_text"}>Cotización</span>
                    {
                        item.quotationId === 0 ?
                            <img />
                            :
                            <img className={"pdfIcon"} src={pdfIcon} alt={'img'} onClick={() => handleDownloadPDF(item.quotationId)} />
                    }
                </div>
                <div className={'doctor-tab-patient-bloque-3'}>
                    <div>
                        <span className={"gray_dark_text"}>Sistema: </span>
                        <span className={"gray_light_text"}>{`${'Neon'}`}</span>
                    </div>
                    <div>
                        <span className={"gray_dark_text"}>Fecha de CX: </span>
                        <span className={"gray_light_text"}>{formatDateToPresent(item.surgeryDate)}</span>
                    </div>
                    <div>
                        <span className={"gray_dark_text"}>Hospital: </span>
                        <span className={"gray_light_text"}>{`${item.hospital.name}`}</span>
                    </div>
                </div>
                <div className={'doctor-tab-patient-bloque-4'}>
                    <Button color={"blue_dark"} title={"VER PERFIL"} onClick={() => surgeriesOnClick(item)} />
                </div>
            </div>
        );
    };

    return (
        <div className={"padding-left-sb"} onScroll={handleScroll}>
            {
                listPatients &&
                listPatients.length > 0 &&
                <>
                    {
                        listPatients.map(renderPatientItem)
                    }
                    {/* {
                        openModal &&
                        <CustomModal // Esto se puede ser otro componente
                            title={'CIRUGÍAS PROGRAMADAS'}
                            isOpen={openModal}
                            toggle={toggleModal}
                            body={modalBody()}
                        />
                    } */}
                </>
            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default withRouter(connect(mapStateToProps)(UserTabPatient));
