import React, { useEffect, useState } from 'react';
import { SearchChatComponent } from './SearchChatComponent';
import './sass/styles.scss';
import { MessagesChatComponent } from './MessagesChatComponent';
import icon_back from '../../assets/img/126PX/Icono_atras_126px.png';
import icon_add from '../../assets/img/126PX/IcoAgregarCi.png';
import { Add_Talk_Group_Modal_Chat } from './ModalChat/Add_Talk_Group_Modal_Chat';
import { useDispatch } from 'react-redux';
import { getSearchSurgerySuccess } from '../../actions/notification-chatAction';
import { SearchChatGroupComponent } from './SearchChatGroupComponent';


export const ChatComponent = () => {

    const [title, setTitle] = useState(true);
    const [surgerieslist, setSurgeriesList] = useState(true)
    const [openModalAdd, setOpenModalAdd] = useState(null)
    const [cxSelected, setCxSelected] = useState(null)
    const dispatch = useDispatch()
    const updateMainObject = () => { };

    useEffect(() => {

        return () => {
            dispatch(getSearchSurgerySuccess(null))
        }
    }, [])

    const backward = () => {
        setTitle(!title)
        setSurgeriesList(!surgerieslist)
    }

    const closeModal = (item) => {
        if (openModalAdd === 'open') {
            setOpenModalAdd(null);
        }
    }

    return (
        <div className="ChatComponent-container">
            <div className="ChatComponent-subContainer">

                <div className="ChatComponent-header-container">

                    <div className="ChatComponent-header-info-container">
                    </div>

                    <div className="ChatComponent-header-search">


                        {title === false ?
                            <>

                                <img src={icon_back} alt="" className='img-header-back-chat' onClick={() => backward()} />

                                <SearchChatGroupComponent />

                                <img src={icon_add} alt="" className='img-header-add-chat' onClick={() => setOpenModalAdd('open')} />

                                {
                                    openModalAdd === 'open' &&
                                    <Add_Talk_Group_Modal_Chat
                                        isOpen
                                        toggle={closeModal}
                                        updateMainObject={updateMainObject}
                                        setOpenModal={setOpenModalAdd}
                                        setCxSelected={setCxSelected}
                                        setOpenModalAdd={setOpenModalAdd}

                                    />
                                }
                            </>

                            :
                            <>
                                <SearchChatComponent />
                            </>

                        }

                    </div>

                    <p className='ChatComponent-header-info-message'>{title ? 'Seleccione una cirugia:' : 'Seleccione un grupo'}</p>

                </div>

                <div className="ChatComponent-messages-info-container">
                    <MessagesChatComponent
                        setTitle={setTitle}
                        title={title}
                        surgerieslist={surgerieslist}
                        setSurgeriesList={setSurgeriesList}
                    />
                </div>

            </div>
        </div>
    )
}
