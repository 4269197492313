import React from 'react';
import imgAsignar from '../../../../assets/img/126PX/icon_asignar_encuestas.png';
import imgFecha from '../../../../assets/img/126PX/icon_fecha_encuestas.png';

export const ActionCom = ({action,setAction,handleToAdd,setAsignar,setFecha,asigar,fecha}) => {
  return (
    <div className='body-Acciones-New'>
    <div className='header-accion-add'>
        <input
            placeholder='Agregue una acción...'
            value={action}
            onChange={(e) => setAction(e.target.value)}
            className='input-accion-add'/>
    </div>
    <div className='body-accion-add'>
        <button onClick={() => handleToAdd()} className='btn-add'>GUARDAR</button>
        <div className='body-accion-asignar' onClick={() => setAsignar(!asigar)}>
            <img className='img-asignar' src={imgAsignar}></img>
            <p className='text-asignar'>Asignar</p>
        </div>
        <div className='body-accion-fecha' onClick={() => setFecha(!fecha)} >
            <img className='img-fecha' src={imgFecha}></img>
            <p className='text-fecha'>Fecha de vencimiento</p>
        </div>
    </div>
</div>
  )
}
