import React from 'react'
import { formatHourToPresent} from '../../../hooks/useFormatDateInitialAndFinalDay';
import './styleMessageComponent.scss';
export const MessageLeftComponent = ({messages,userSend,hour,media}) => {



  return (

    <div className='container-menssage-received-component'>
      <p className='user-name-chat-conversation-received'>{userSend && userSend}</p>
        <div className='subcontainer-messagge-received-component'>
            <p className='contents-msg-title'>{messages && messages}</p>
            {
                media && 
                <img className='img-send-chat-received' src={media}></img>
              }
            <p className='contents-msg-hour-left'>{formatHourToPresent(hour && hour)}</p>
        </div>
    </div>
 
  )
}
