import React, { useState } from 'react';
import { ATQ_Consum_Report_List_Com } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Consum_Report_List_Com';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './styles.scss';
export const ATQ_Consum_Report_List_Page = () => {
    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');
    return (
        <div className = "precontainer_page">
        <SubNavBarComponent title={'REPORTE DE CONSUMO'} searchActive={true} historyPage={'/ATQ_Inicio'} />
            <div className="container_page">
                <div className="container_buttons">
                     <nav className="toogle_container_buttons">
                            <button className={ activeSwitch === 'ACTIVE'
                               ? "normal_button btn_left btn_active"
                               : "normal_button btn_left"} onClick ={() => setActiveSwitch('ACTIVE')}>
                                PENDIENTES
                            </button>
                            <button className={ activeSwitch === 'INACTIVE'
                               ? "normal_button btn_rigth btn_active"
                               : "normal_button btn_rigth"} onClick ={() => setActiveSwitch('INACTIVE')}>
                                FINALIZADOS
                            </button>
                     </nav>
                </div>
                <div className="section_list">
                <ATQ_Consum_Report_List_Com
                    activeSwitch = {activeSwitch}
                />
                </div>
                
            </div>
        </div>
    )
}
