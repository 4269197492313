import React, { useEffect, useState } from "react";
import './sass/styles.scss';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from "moment";

export const ScheduleNew = () => {
  const [dates, setDates] = useState();
  const [anterior, setAnterior] = useState()
  const [despues, setDespues] = useState()

  useEffect(() => {
    setDates(getDaysForCalendar(new Date()))
  }, [])

  useEffect(() => {
    console.log(dates)
  }, [dates])

  const nextDates = () => {
    setDates(getDaysForCalendar(moment(dates.test2).format()))
  };

  const previousDates = () => {
    setDates(getDaysForCalendar(moment(dates.test1).subtract(3, 'days').format()))
  };


  function getWeek(dataRecived) {
    let fromDate = dataRecived ? new Date(dataRecived) : new Date();
    let sunday = new Date(
      fromDate.setDate(fromDate.getDate() - fromDate.getDay())
    );
    let result = [];

    for (let index = 0; index < 7; index++) {
      result.push(new Date(sunday));
      sunday.setDate(sunday.getDate() + 1);
    }

    return result;
  }

  function getDaysForCalendar(dateRecived) {
    let result = getWeek(dateRecived);
    let getIn = false;
    let counter = 0;
    let innerLastElement = null
    let innerFirstElement = null

    let daysToCome = result.filter((date) => {
      if (new Date(dateRecived).getDate() == date.getDate()) {
        getIn = true;
      }
      if (getIn) {
        if (counter < 4) {
          counter++;
          return date;
        }
      }
    });

    //console.log('daysToCome:', daysToCome)
    const innerArray = structuredClone(daysToCome);
    innerLastElement = innerArray[innerArray.length - 1];
    //console.log('innerLastElement: ', innerLastElement)
    innerFirstElement = innerArray[0];
    const newDateLastElement = moment(innerLastElement).add(1, 'd');
    let InnerResult = getWeek(newDateLastElement);
    //console.log('newDateLastElement', newDateLastElement)
    InnerResult.filter((date) => {
      if (daysToCome.length < 4) {
        daysToCome.push(date);
      }
    });

    let days = daysToCome.map((date) => {
      let day = date.getDate();
      let weekday = date
        .toLocaleString("default", { weekday: "long" })
        .toUpperCase();

      return weekday + " " + day;
    });

    var fechaAnterior = moment(daysToCome[0]).subtract(1, 'days').format();
    var fechaDespues = moment(daysToCome[daysToCome.length - 1]).add(1, 'days').format();


    return {
      prettyDates: days,
      date: daysToCome,
      test1: fechaAnterior,
      test2: fechaDespues
    };
  }


  function Card(data) {
    return (
      <div style={{ border: '1px solid lightGrey', boxShadow: ' 0px 0px 3px 1px rgba(112,112,112,0.5)', marginBottom: '15px', width: '90%', alignSelf: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '10px' }}>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <div style={{ width: '20%' }}>
              {
                data.data.icon == 'urgent' ? <EmojiObjectsIcon fontSize="large" style={{ color: '#9f1717' }} /> :
                  data.data.icon == 'warning' ? <WarningIcon fontSize="large" style={{ color: '#eb5400' }} /> :
                    data.data.icon == 'detail' ? <ErrorIcon fontSize="large" style={{ color: '#d68000' }} /> :
                      data.data.icon == 'success' ? <CheckCircleIcon fontSize="large" style={{ color: '#689f38' }} /> :
                        <></>
              }
            </div>
            <div style={{ width: '60%' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>{data.data.time}</p>
            </div>
          </div>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'center', justifyContent: 'center' }}>
            <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{data.data.place}</p>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', flexDirection: 'column', marginLeft: '30px' }}>
            <div style={{ width: '20%' }}>
              <p style={{ fontFamily: 'arial', fontSize: '17px' }}>Tecnico</p>
            </div>
            <div style={{ width: '60%' }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>{data.data.technician}</p>
            </div>
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', flexDirection: 'column', marginLeft: '30px' }}>
            <div style={{ width: '20%' }}>
              <p style={{ fontFamily: 'arial', fontSize: '17px' }}>Medico</p>
            </div>
            <div style={{ width: '60%' }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>{data.data.doctor}</p>
            </div>
          </div>
          {
            data.data.programmingId &&
            <div style={{ width: '50%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', flexDirection: 'column', marginLeft: '30px' }}>
              <div style={{ width: '20%' }}>
                <p style={{ fontFamily: 'arial', fontSize: '17px' }}>#Programación</p>
              </div>
              <div style={{ width: '60%' }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>{data.data.programmingId}</p>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }

  function Technician(data) {
    return (
      <div style={{ border: '1px solid lightGrey', boxShadow: ' 0px 0px 3px 1px rgba(112,112,112,0.5)', marginBottom: '15px', width: '90%', alignSelf: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
          <div style={{ width: '90%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', flexDirection: 'column', marginLeft: '30px' }}>
            <div style={{ width: '100%' }}>
              <p style={{ fontFamily: 'arial', fontSize: '17px' }}>{data.data.technician}</p>
            </div>
            <div style={{ width: '100%' }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>{data.data.profession}</p>
            </div>
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', marginLeft: '30px' }}>
            <MoreVertIcon fontSize="large" style={{ color: 'dark' }} />
          </div>
        </div>
      </div>
    );
  }

  return (

    <div className="container">
      <div style={{ display: 'flex', marginTop: '-80px', marginLeft: '40px', marginRight: '40px' }}>
        <div className="Schedulecolumn" style={{ width: '80%' }}>
          <div className="cardSchedule" style={{ background: '#0b4674' }}>
            <div className="card-contentSchedule">
              <h2>CIRUGIAS DEL DIA</h2>
              <h2>4</h2>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: '20px', marginTop: '20px' }}>
            <div style={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>21 de Agosto 2021</p>
            </div>
            <div style={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}> 6:30 PM</p>
            </div>
          </div>
          <Card data={{ icon: 'urgent', time: '10:31 AM', place: 'Hospital de la Luz', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez' }} />
          <Card data={{ icon: 'warning', time: '11:30 AM', place: 'Hospital los Angeles', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez' }} />
          <Card data={{ icon: 'detail', time: '3:00 PM', place: 'Hospital de la Mujer', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez' }} />
          <Card data={{ icon: 'success', time: '8:00 PM', place: 'Hospital de la Luz', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez' }} />

        </div>
        <div className="Schedulecolumn" style={{ width: '80%' }}>
          <div className="cardSchedule" style={{ background: '#4c7fb6' }}>
            <div className="card-contentSchedule">
              <h2>CIRUGIAS DEL DIA</h2>
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: '20px', marginTop: '20px' }}>
            <div style={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
              <button onClick={previousDates}>Antes</button>
            </div>
            <div style={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
              <button onClick={nextDates}>Despues</button>
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: '50px', justifyContent: 'space-between' }}>
            <div style={{ width: '23%', justifyContent: 'center', display: 'flex', background: '#648348', borderRadius: '10px', height: '200%', alignItems: 'center', flexDirection: 'column' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[0][0] + dates.prettyDates[0][1] : ''}</p>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[0][dates.prettyDates[0].length - 2] + dates.prettyDates[0][dates.prettyDates[0].length - 1] : ''}</p>
            </div>
            <div style={{ width: '23%', justifyContent: 'center', display: 'flex', background: '#648348', borderRadius: '10px', height: '200%', alignItems: 'center', flexDirection: 'column' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[1][0] + dates.prettyDates[1][1] : ''}</p>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[1][dates.prettyDates[1].length - 2] + dates.prettyDates[1][dates.prettyDates[1].length - 1] : ''}</p>
            </div>
            <div style={{ width: '23%', justifyContent: 'center', display: 'flex', background: '#648348', borderRadius: '10px', height: '200%', alignItems: 'center', flexDirection: 'column' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[2][0] + dates.prettyDates[2][1] : ''}</p>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[2][dates.prettyDates[2].length - 2] + dates.prettyDates[2][dates.prettyDates[2].length - 1] : ''}</p>
            </div>
            <div style={{ width: '23%', justifyContent: 'center', display: 'flex', background: '#648348', borderRadius: '10px', height: '200%', alignItems: 'center', flexDirection: 'column' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[3][0] + dates.prettyDates[3][1] : ''}</p>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold', color: 'white' }}>{dates ? dates.prettyDates[3][dates.prettyDates[3].length - 2] + dates.prettyDates[3][dates.prettyDates[3].length - 1] : ''}</p>
            </div>
          </div>

          <Card data={{ icon: 'urgent', time: '4:00 PM', place: 'Hospital de la Luz', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez', programmingId: '123456' }} />
          <Card data={{ icon: 'urgent', time: '2:00 PM', place: 'Hospital los Angeles', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez', programmingId: '123456' }} />
          <Card data={{ icon: 'warning', time: '12:00 PM', place: 'Hospital de la Mujer', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez', programmingId: '123456' }} />
          <Card data={{ icon: 'success', time: '10:00 AM', place: 'Hospital de la Luz', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez', programmingId: '123456' }} />
          <Card data={{ icon: 'success', time: '8:00 AM', place: 'Hospital de la Luz', technician: 'Mauricio Cortez', doctor: 'Mauricio Cortez', programmingId: '123456' }} />

        </div>
        <div className="Schedulecolumn" style={{ width: '80%' }}>
          <div className="cardSchedule" style={{ background: '#37a7b9' }}>
            <div className="card-contentSchedule">
              <h2>TECNICOS DISPOIBLES</h2>
              <h2>4</h2>
            </div>
          </div>
          <Technician data={{ technician: 'Tecnico Mauricio Cortez', profession: 'Cirujano de cadera' }} />
          <Technician data={{ technician: 'Mauricio Cortez', profession: 'Cirujano de cadera' }} />
          <Technician data={{ technician: 'Tecnico Mauricio Cortez', profession: 'Cirujano de cadera' }} />
        </div>
      </div>
    </div>
  );
};
