import React from 'react';

export const QualityEncuestasModal = () => {
    return (
        <div className='container_encuesta_modal'>
            <div className='container-encuesta-tittle'>
                <span className='modal_encuesta_texto'>SE HA NOTIFICADO DE NUEVO COMENTARIO A ENCUESTA</span>
            </div>
            <button className='modal_encuesta_button'>OK</button>
        </div>

    )

};
