export const routesFilt = {
    doctor: 'Medic/GetMedics',
    adminAtq: 'Atn/GetAtnList',
    atq: 'Atn/GetAtnList',
    institutions: 'Hospital/GetHospitals',
    patients: 'Patient/GetPatients',
    delivery: 'Sellers/GetSellers',
    seller: 'Sellers/GetSellers',
    storer: 'Sellers/GetSellers'
}
export const responsesFilt = {
    doctor: 'medics',
    adminAtq: 'response',
    atq: 'response',
    institutions: 'hospitals',
    patients: 'response',
    delivery: 'sellers',
    seller: 'sellers',
    storer: 'sellers'
}
export const rolsTypes = {
    doctor: 'Médico',
    adminAtq: 'Admin. ATQ',
    atq: 'ATQ',
    institutions: 'Instituciones',
    patients: 'Paciente',
    delivery: 'Repartidor',
    seller: 'Vendedor',
    storer: 'Almacenista'
}

export const SearchTypes = {
    doctor: 'médico',
    adminAtq: 'admin. ATQ',
    atq: 'ATQ',
    institutions: 'instituciones',
    patients: 'paciente',
    delivery: 'repartidor',
    seller: 'vendedor',
    storer: 'almacenista'
}
export const rolTypeNav = {
    doctor: 'Médicos',
    adminAtq: 'Coordinador ATQ',
    atq: 'ATQ',
    institutions: 'Instituciones',
    patients: 'Pacientes',
    delivery: 'Repartidor',
    seller: 'Vendedor',
    storer: 'Almacenista'
}
export const userTypes = {
        doctor: 'Médico',
        adminAtq: 'Admin. ATQ',
        atq: 'ATQ',
        seller: 'Vendedor',
        storer: 'Almacén',
        delivery: 'Repartidor',
};
