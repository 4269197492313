import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.sass';
import icoHistorialMaterial from '../../../assets/img/512PX/Icono_HistorialMaterial_512.png';
import icoAsignarATQ from '../../../assets/img/512PX/Icono_AsignarATQ_512.png';
import icoDisMat from '../../../assets/img/512PX/Icono_DisponibilidadATQ_512.png';
import icoRolGuardia from '../../../assets/img/512PX/Icono_RolGuardia_512.png';
import icoEncuesta from '../../../assets/img/512PX/Icono_Encuestra_512.png';
import icoTransferirCX from '../../../assets/img/512PX/Icono_TransCX_512.png';
import icoAgenda from '../../../assets/img/512PX/Icono_Agenda_512.png';
import Icono_Estatus_CX from '../../../assets/img/512PX/Icono_Estatus_CX.png';

export const ATQAdmin_Home = () => {
    return (
        <div className="precontainer_atqHome">
            {/*  ESTA ES LA PRIMERA SECCION  */}
            <div className="container_mod_rigth animate__animated animate__fadeIn">
                <div className="container-sec section-1-atq">

                    <NavLink className='atq-item-simpleform atq-item atq-historial'
                        to={'/ATQAdministrator_Material_History'}
                    >
                        <img src={icoHistorialMaterial} alt="" className='atq-historial-img' />
                        <h3 className='atq-item-title'> Historial de Material </h3>

                    </NavLink>

                    
                        {/* <button className='atq-item-simpleform atq-item atq-historial disabled' disabled>
                            <img src={icoHistorialMaterial} alt="" className='atq-historial-img'/>
                            <h3 className='atq-item-title'> Historial de Material </h3>
                        </button>   */}

                    <NavLink className='atq-item-horizontalform atq-item atq-disponibilidad'
                        to={'/ATQAdministrator_atq_availability'}
                    >
                        <img src={icoDisMat} alt="" className='atq-disponibilidad-img' />
                        <h3 className='atq-item-title'> Estatus de ATQ </h3>

                    </NavLink>
                    <NavLink className='atq-item-horizontalform atq-item atq-asignar'
                        to={'/ATQAdmin_Asignar_CX'}
                    >
                        <img src={icoAsignarATQ} alt="" className='atq-asignar-img' />
                        <h3 className='atq-item-title'> Asignar ATQ </h3>

                    </NavLink>
                    <NavLink className='atq-item-simpleform atq-item atq-rol-guardias'
                        to={'/ATQ_GuardRole_View'}
                    >
                        <img src={icoRolGuardia} alt="" className='atq-rol-guardia-img' />
                        <h3 className='atq-item-title'> Rol de Guardias </h3>

                    </NavLink>
                </div>

                {/* /* ESTA ES LA SEGUNDA SECCION */}



                {/* ESTA ES LA SEGUNDA SECCION  */}
                <div className="container-sec section-2-atq">




                    <NavLink className='atq-item-simpleform atq-item atq-encuesta'
                        to={'/ATQAdministratorMenuSurvery'}
                    >
                        <img src={icoEncuesta} alt="" className='atq-encuesta-img' />
                        <h3 className='atq-item-title'> Encuestas </h3>

                    </NavLink>




                    {/* <button className='atq-item-simpleform atq-item atq-encuesta'>
                            <img src={icoEncuesta} alt="" className='atq-encuesta-img'/>
                            <h3 className='atq-item-title'> Encuestas </h3>
                        </button>    */}

                    <NavLink className='atq-item-horizontalform atq-item atq-transferir'
                        to={'/ATQAdministrator_Transferir_CX'}
                    >
                        <img src={icoTransferirCX} alt="" className='atq-transferir-img' />
                        <h3 className='atq-item-title'> Transferir CX </h3>

                    </NavLink>
                    <NavLink className='atq-item-horizontalform atq-item atq-agenda'
                        to={'/agenda/agenda'}
                    >
                        <img src={icoAgenda} alt="" className='atq-agenda-img' />
                        <h3 className='atq-item-title'> Agenda </h3>

                    </NavLink>
                    <NavLink className='atq-item-simpleform atq-item atq-rol-guardias'
                        to={'/ATQAdmin_EstatusCX'}
                    >
                        <img src={Icono_Estatus_CX} alt="" className='atq-rol-guardia-img' />
                        <h3 className='atq-item-title'>Estatus CX</h3>

                    </NavLink>

                </div>
            </div>
        </div>
    )
}
