import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TablePagination,
  TextField
} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import CropFreeIcon from '@material-ui/icons/CropFree';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import InfoIcon from '@material-ui/icons/Info';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SyncIcon from '@material-ui/icons/Sync';
import { Alert, Autocomplete } from "@material-ui/lab";
import React from "react";
import { useParams } from "react-router";
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './sass/styles.scss';

import { useState } from "react";
import { NavLink } from "react-router-dom";

import SearchBar from "material-ui-search-bar";
import moment from "moment";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrdersProductAsync, getLocationsAsync,
  getOrderClientDataAsync, getOrderDetailsAsync, getOrders,
  getRemissionDetailAsync, getRemissionsByOrderAsync,
  getWarehouseAsync
} from "../../actions/userActions";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const OrderList = (props) => {
  const requestH = useSelector((state) => state.user.request);

  const { searchQuoteFinished } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.user.getOrders)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [searched, setSearched] = useState("");
  const [inputValue, setInputValue] = React.useState('pedidos');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders(0))
  }, []);

  useEffect(() => {
    setRowsOrders(orders)
    setRowsOrdersOriginal(orders)

    getProductsByOrder(orders)
  }, [orders])

  const getProductsByOrder = async (orders) => {
    /* for (const singleOrder of orders) {
      await getOrderDetailsAsync(singleOrder.id, requestH).then((data) => {
        console.log('DATA RESPONSE: ', data)
      })
    } */
    getClientInformation(orders)

  }

  /* Tabla Cotización */
  const columnsCotizacion = [
    { id: 'tipo', label: 'TIPO', minWidth: 100 },
    { id: 'folio', label: 'FOLIO', minWidth: 100 },
    { id: 'inicial', label: 'FECHA', minWidth: 100 },
    { id: 'pactada', label: 'PACTADA', minWidth: 170 },
    { id: 'productos', label: 'PRODUCTOS', minWidth: 170 },
    { id: 'requerida', label: 'CANT.REQ', minWidth: 170, },
    { id: 'stock', label: 'STOCK', minWidth: 170, },
    { id: 'scan', label: 'SCAN', minWidth: 170 },
    { id: 'vendedor', label: 'VENDEDOR', minWidth: 20 }
  ];

  const columnsQuote = ['folio', 'inicial', 'pactada', 'productos', 'requerida'];


  function createDataCotizacion(tipo, folio, inicial, pactada, productos, requerida, stock, scan, vendedor) {
    return { tipo, folio, inicial, pactada, productos, requerida, stock, scan, vendedor };
  }

  const rowsCotizacion = [
    createDataCotizacion('ShoppingBasketIcon', '202229560', '19/SEP/2022', '19/SEP/2022', 'CERV-c-CONVEX, Altura 8MM, ANCHO 14mm, Longitud 12MM', '10', '10', 'SCAN', 'Maria Montes'),
    createDataCotizacion('FolderIcon', '322229560', '22/SEP/2022', '22/SEP/2022', 'Chalecos con mangas color azul', '50', '100', 'SCAN', 'Carlos Torres'),
    createDataCotizacion('DescriptionIcon', '232229560', '25/SEP/2022', '25/SEP/2022', 'Tornillos para rodilla', '1', '0', 'SCAN', 'Eugenia Martinez'),
    createDataCotizacion('SyncIcon', '202229560', '19/SEP/2022', '19/SEP/2022', 'Kit para suturas', '15', '50', 'SCAN', 'Carlos Torres'),
  ];

  const [rowsQuote, setRowsCotizacion] = useState(rowsCotizacion);


  /* Tabla Cotización */
  const columnsEscanear = [
    { id: 'codigo', label: 'Codigo', minWidth: 100 },
    { id: 'cantidad', label: 'Cantidad', minWidth: 100 },
    { id: 'lote', label: 'Lote', minWidth: 100 },
    { id: 'pedimento', label: 'Pedimento', minWidth: 170 },
    { id: 'almacen', label: 'Almacen', minWidth: 170 },
    { id: 'ubicacion', label: 'Ubicacion', minWidth: 170, },
    { id: 'borrar', label: '', minWidth: 170, },
  ];

  function createDataEscanear(codigo, cantidad, lote, pedimento, almacen, ubicacion, borrar) {
    return { codigo, cantidad, lote, pedimento, almacen, ubicacion, borrar };
  }

  // const rowsEscanear = [
  //   createDataEscanear('5901234123457', 2, '00321', 'Ninguno', 'Bodega 1', '00321'),
  //   createDataEscanear('5901234123555', 5, '00321', 'Ninguno', 'Bodega 1', '00321'),
  // ];

  // const [rowsScan, setRowsScan] = useState(rowsEscanear);

  /* Tabla Pedidos */
  const columnsPedidos = [
    { id: 'tipo', label: 'TIPO', minWidth: '5%', align: 'center', background: 'red' },
    { id: 'folio', label: 'FOLIO', minWidth: '10%', align: 'center', background: 'green' },
    { id: 'inicial', label: 'FECHA PEDIDO', minWidth: '10%', align: 'center', background: 'blue' },
    { id: 'pactada', label: 'REQUERIDA', minWidth: '10%', align: 'center', background: 'yellow' },
    { id: 'cliente', label: 'CLIENTE', minWidth: '40%', align: 'left', background: 'orange' },
    { id: 'proceso', label: 'STATUS', minWidth: '15%', align: 'center', background: 'purple' },
    { id: 'status', label: 'PROCESO', minWidth: '5%', align: 'center', background: 'pink' },
    { id: 'envios', label: '#ENVIOS', minWidth: '5%', align: 'center', background: 'grey' },
  ];

  const columnsOrders = ['folio', 'inicial', 'pactada', 'cliente', 'proceso', 'status', 'envios'];


  function createDataPedidos(tipo, folio, inicial, pactada, cliente, proceso, status, envios) {
    return { tipo, folio, inicial, pactada, cliente, proceso, status, envios };
  }

  const rowsPedidos = [
    createDataPedidos('ShoppingBasketIcon', '202229560', '19/SEP/2022', '19/SEP/2022', 'INSTITUTO MEXICANO DEL SEGURO SOCIAL HOSPITAL REGIONAL', 'PARCIALMENTE SURTIDO', '10%', '2',),
    createDataPedidos('FolderIcon', '322229560', '22/SEP/2022', '22/SEP/2022', 'INSTITUTO MEXICANO DEL SEGURO SOCIAL HOSPITAL REGIONAL', 'SURTIDO', '100%', '1'),
    createDataPedidos('DescriptionIcon', '232229560', '25/SEP/2022', '25/SEP/2022', 'INSTITUTO MEXICANO DEL SEGURO SOCIAL HOSPITAL REGIONAL', 'NUEVO', '0%', '0'),
    createDataPedidos('SyncIcon', '202229560', '19/SEP/2022', '19/SEP/2022', 'INSTITUTO MEXICANO DEL SEGURO SOCIAL HOSPITAL REGIONAL', 'PARCIALMENTE SURTIDO', '50%', '5'),
  ];

  const [rowsOrders, setRowsOrders] = useState(rowsPedidos);
  const [rowsOrdersOriginal, setRowsOrdersOriginal] = useState(rowsPedidos);
  const [rowsPerPageOrders, setRowsPerPageOrders] = useState(7);
  const [pageOrders, setPageOrders] = useState(0);

  useEffect(() => {
    getProductsByOrder(orders)
  }, [pageOrders, rowsPerPageOrders])

  const handleChangePageOrders = (event, newPage) => {
    setClientsTable([])
    setPageOrders(newPage);
    setLoading(true)
    /* getProductsByOrder(orders)
    getClientInformation(orders)
    getProductForOrder(orders)
    getRemissionOfOrders(orders) */
  };

  const handleChangeRowsPerPageOrders = (event) => {
    setClientsTable([])
    setRowsPerPageOrders(+event.target.value);
    setPageOrders(0);
    setLoading(true)
    /* getProductsByOrder(rowsOrdersOriginal) */
    /* getClientInformation(orders)
    getProductForOrder(orders)
    getRemissionOfOrders(orders) */
  };

  /* ToolTip */
  const [tooltipV, setToolTipV] = useState(false);

  function setToolTipVisible() {
    setToolTipV(!tooltipV)
  }

  /* Modal Scan */
  const [openModalScan, setOpenModalScan] = React.useState(false);

  const handleOpenModalScan = () => {
    setOpenModalScan(true);
  };

  const handleCloseModalScan = () => {
    setOpenModalScan(false);
  }

  /* Modal Manual Scan */
  const [openModalManualScan, setOpenModalManualScan] = React.useState(false);

  const handleOpenModalManualScan = () => {
    setOpenModalManualScan(true);
  };

  const handleCloseModalManualScan = () => {
    setOpenModalManualScan(false);
  }

  const [searchedValuesGeneral, setSearchedValuesGeneral] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredArray = rowsOrdersOriginal.filter((item) => {
      return item.orderedBy_Name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        item.id.toString().includes(searchedVal.toLowerCase()) ||
        item.clientId.toLowerCase().includes(searchedVal.toLowerCase())
    });
    setRowsOrders(filteredArray)
  };

  const cancelSearch = () => {
    setSearchedValuesGeneral("")
    setRowsOrders(rowsOrdersOriginal)
  };

  const [clientsTable, setClientsTable] = useState([])

  const getClientInformation = async (orders) => {
    const ordersCuts = orders.slice(pageOrders * rowsPerPageOrders, pageOrders * rowsPerPageOrders + rowsPerPageOrders)
    let clients = []
    for (const element of ordersCuts) {
      await getOrderClientDataAsync(element.id, requestH).then((data) => {
        clients.push(data.response)
      })
    }
    setClientsTable(clients)
    getProductForOrder(orders, clients)
  }

  const [productsInformation, setProductInformation] = useState([])
  const getProductForOrder = async (orders, clients) => {
    const ordersCuts = orders.slice(pageOrders * rowsPerPageOrders, pageOrders * rowsPerPageOrders + rowsPerPageOrders)
    let ordersInformation = []
    for (const element of ordersCuts) {
      await getOrderDetailsAsync(element.id, requestH).then((data) => {
        ordersInformation.push(data.response)
      })
    }
    setProductInformation(ordersInformation)
    getRemissionOfOrders(orders, clients, ordersInformation)
  }

  function returnRemissionsArray(cadena) {
    const numeros = cadena.split(',').map(item => parseInt(item.trim()));
    return numeros;
  }

  function formatArray(arr) {
    return arr.map((item) => {
      const details = item.details.flat(); // Flatten the nested arrays
      const shipmentsQuantity = details.length;

      let generalQuantity = 0;
      details.forEach((detail) => {
        generalQuantity += detail.quantity;
      });

      const informationStatus = getInfoOfAll(item.order_id)

      return {
        ...item,
        details,
        shipmentsQuantity,
        generalQuantity,
        informationStatus
      };
    });
  }

  const [remissionInformation, setRemissionInformation] = useState([])

  const getRemissionOfOrders = async (orders, clients, orderInformation1) => {
    const ordersCuts = orders.slice(pageOrders * rowsPerPageOrders, pageOrders * rowsPerPageOrders + rowsPerPageOrders)
    let ordersRemissions = []
    for (const element of ordersCuts) {
      await getRemissionsByOrderAsync(element.id, requestH).then(async (data) => {
        if (data.response && data.response.length > 0 && data.response[0].remissions) {
          const remissionData = data.response[0]
          const arrayRemissions = returnRemissionsArray(data.response[0].remissions)
          if (arrayRemissions && arrayRemissions.length > 0) {
            const allRemissionsDetail = []
            for (const singleRemission of arrayRemissions) {
              await getRemissionDetailAsync(singleRemission, requestH).then((dataDetail) => {
                allRemissionsDetail.push(dataDetail.response)
              })
            }
            remissionData.details = allRemissionsDetail
            remissionData.orderId = element.id
            ordersRemissions.push(remissionData)
          }
        }
      })
    }

    setRemissionInformation(formatArray(ordersRemissions))
    setLoading(false)
  }

  function getQuantityByOrderId(orderId, quantityType) {
    const order = remissionInformation.find((item) => item.orderId === orderId);

    if (!order) {
      return null;
    }
    if (quantityType === "generalQuantity") {
      return order.generalQuantity;
    } else if (quantityType === "shipmentsQuantity") {
      return order.shipmentsQuantity;
    } else {
      return null;
    }
  }

  function getClientInfoById(id) {
    const client = clientsTable.find(item => item.id === id);
    if (client) {
      return {
        clientName: client.clientName,
        clientCode: client.clientCode
      };
    }
    return null;
  }

  function transformArray() {
    const outputArray = [];
    const orderMap = {};

    // Recorre el array de entrada y agrupa las cantidades por orderId
    for (const orderItems of productsInformation) {
      for (const item of orderItems) {
        const orderId = item.orderId;
        const quantity = item.quantity;
        if (orderMap[orderId]) {
          orderMap[orderId] += quantity;
        } else {
          orderMap[orderId] = quantity;
        }
      }
    }

    // Crea el nuevo array con el formato deseado
    for (const orderId in orderMap) {
      const generalQuantity = orderMap[orderId];
      outputArray.push({
        orderId: parseInt(orderId),
        generalQuantity,
      });
    }

    return outputArray;
  }

  function getOrderById(orderId) {
    let generalQuantity = 0;
    for (const orderItems of productsInformation) {
      for (const item of orderItems) {
        if (item.orderId == orderId) {
          generalQuantity += item.quantity;
        }
      }
    }
    return {
      orderId: orderId,
      generalQuantity: generalQuantity,
    };
  }

  function getInfoOfAll(orderId) {
    const { generalQuantity } = getOrderById(orderId)
    const remissionQuantity = getQuantityByOrderId(orderId, "generalQuantity")

    if (remissionQuantity >= generalQuantity) {
      return 2
    } else if (remissionQuantity < generalQuantity && remissionQuantity > 0) {
      return 1
    } else {
      0
    }

  }

  function getObjectById(array, id) {
    return array.find(item => item.orderId === id);
  }

  // const classes = useStyles();
  const [productCode, setProductCode] = useState('')
  const [lote, setLote] = useState('')
  const [pedimento, setPedimento] = useState('')

  const [inputValueAlmacen, setInputValueAlmacen] = React.useState('');
  const [almacenList, setAlmacenList] = useState([])
  const [locationList, setLocationList] = useState([])

  const handleInputValueAlmacen = (value) => {
    setInputValueAlmacen(value)
  }

  useEffect(() => {
    getWarehouse()
    getLocations()
  }, [])

  const getWarehouse = async () => {
    await getWarehouseAsync(requestH).then((data) => {
      setAlmacenList(data.response)
    })
  }

  const getLocations = async () => {
    await getLocationsAsync(requestH).then((data) => {
      setLocationList(data.response)
    }).catch((e) => { console.log(e) })
  }


  function productCodeExistInPreRemissionCart(productCode) {
    const temporalCart = structuredClone(rowsScan)
    for (let i = 0; i < rowsScan.length; i++) {
      const producto = temporalCart[i];

      if (producto.product.productCode == productCode) {
        return { status: true, id: i };
      }
    }
    return { status: false, id: null };
  }


  function productCodeExist(productCode) {
    const temporalArray = structuredClone(orderInformation);
    for (let i = 0; i < temporalArray.length; i++) {
      const producto = temporalArray[i];

      let string1 = producto.product.productCode.replace(/\s/g, "");
      let string2 = productCode.replace(/\s/g, "");

      string1 = string1.replace('-', '')
      string2 = string2.replace('-', '')

      if (producto.product.productCode == productCode) {
        return producto;
      }
    }
    return false;
  }

  const [arrayProducts, setarrayProducts] = useState([]);

  const getProducts = async () => {
    await GetOrdersProductAsync(0, requestH).then((resp) => {
      setarrayProducts(resp.response)
    })
  }

  useEffect(() => {
    getProducts()
  }, [])

  const [valueAlmacen, setValueAlmacen] = React.useState('');
  const handleAddAutomatic = (scanLine, almacen, localizacion) => {
    if (scanLine)
      setScanLineCode(scanLine.target.value)
    else if (almacen)
      handleInputValueAlmacen(almacen)
    else if (localizacion)
      handleInputValueLocation(localizacion)
  }

  const [rowsScan, setRowsScan] = useState([]);
  const [scanLineCode, setScanLineCode] = useState('')

  useEffect(() => {
    handleAddToArrayAtomatic()
  }, [scanLineCode, valueAlmacen, valueLocation])

  const addToPreRemissionAutomatic = (productCode, automaticLote, AutomaticPediment) => {
    const valueToAdd = productCodeExist(productCode)
    if (valueToAdd != false && valueToAdd != null) {
      const temporalCart = structuredClone(rowsScan)
      const preRemissionCart = productCodeExistInPreRemissionCart(valueToAdd.product.productCode)

      if (preRemissionCart.status) {
        if (temporalCart[preRemissionCart.id].quantity < valueToAdd.quantity)
          temporalCart[preRemissionCart.id].quantity = temporalCart[preRemissionCart.id].quantity + 1
      } else {
        if (lote && pedimento && valueAlmacen && valueLocation) {
          valueToAdd.lote = automaticLote
          valueToAdd.pedimento = AutomaticPediment
          valueToAdd.quantity = 1
          valueToAdd.locationStorage = valueAlmacen
          valueToAdd.location = valueLocation
          temporalCart.push(valueToAdd)
        }
      }

      setRowsScan(temporalCart)
    }
  }

  const handleAddToArrayAtomatic = () => {
    if (scanLineCode && valueAlmacen && valueLocation) {
      const cadenaProcesada = procesarCadena(scanLineCode)
      if (cadenaProcesada != 'La cadena no cumple con el formato requerido') {
        setScanLineCode('')

        const pedimentoProcesado = esFechaValida(cadenaProcesada[2])
        if (pedimentoProcesado) {
          addToPreRemissionAutomatic(cadenaProcesada[0], cadenaProcesada[1])
        } else {
          addToPreRemissionAutomatic(cadenaProcesada[0], cadenaProcesada[1], cadenaProcesada[2])
        }
      }
    }
  }

  const [valueLocation, setValueLocation] = React.useState('');
  const [inputValueLocation, setInputValueLocation] = React.useState('');

  const handleInputValueLocation = (value) => {
    setInputValueLocation(value)
  }

  const addRemission = async () => {
    if (rowsScan && rowsScan.length > 0) {
      const dataToSendRemission = {
        "totvsId": '1',
        "userId": loginData.userID,
        "orderId": previousOrder.order.id
      }

      await addRemissionAsync(dataToSendRemission, requestH).then((data) => {
        rowsScan.map(async (singleElement) => {
          const dataToSendRemissionDetail = {
            "id": 0,
            "remissionId": data.response.id,
            "orderDetailId": singleElement.id,
            "quantity": singleElement.quantity,
            "code": singleElement.product.productCode,
            "lot": singleElement.lote,
            "pedimento": singleElement.pedimento,
            "warehouseId": singleElement.locationStorage.id,
            "locationId": singleElement.location.id,
            "status": 0
          }

          await addRemissionDetailAsync(dataToSendRemissionDetail, requestH).then((dataDetail) => {
          })
        })
      })

      dispatch(getOrderDetails(previousOrder.order.id))
      setOpenToasty(true)
      setRowsScan([])
      handleCloseModalManualScan()
    }
  }

  const [openToasty, setOpenToasty] = React.useState(false);

  const handleClickToasty = () => {
    setOpenToasty(true);

  };

  const handleCloseToasty = () => {
    setOpenToasty(false);
  };

  const [loading, setLoading] = useState(true)


  return (
    <div style={{
      width: '100%',
      paddingTop: '16vh',
      height: '100%'
    }}>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={searchQuoteFinished && `/cotizaciones_finalizadas`} title={`PEDIDOS`} />
      <div >

        {/* TopBar */}
        <div style={{
          background: '#F0F0F0',
          display: 'flex'
        }}>
          <div style={{ width: '70%' }}>
            <div style={{ marginTop: '20px', marginLeft: '50px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Filtro</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '35%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                  <FormControl variant="outlined" className={classes.formControl} style={{
                    width: '100%',
                    height: '46px',
                    boxSizing: 'border-box',
                    background: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.5)',
                    borderRadius: '4px'
                  }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Mostrar por
                    </InputLabel>
                    <Select
                      style={{
                        height: '46px',
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      labelWidth={'Tipo de Generador'}
                      value={inputValue}
                      onChange={handleInputChange}
                    >
                      <MenuItem value={'productos'}>Productos</MenuItem>
                      <MenuItem value={'pedidos'}>Pedidos</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '90%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px', width: '90%' }}>
                  <SearchBar
                    value={searchedValuesGeneral}
                    onChange={(searchVal) => { requestSearch(searchVal) }}
                    onCancelSearch={() => cancelSearch()}
                    placeholder={'Buscar'}
                    style={{
                      width: '100%',
                      height: '46px',
                      boxSizing: 'border-box',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px',
                    }}
                  />
                  {/*                   <TextField id="outlined-basic" label="Buscador General" variant="outlined" style={{ width: '100%' }} />
 */}                </div>
              </div>
            </div>
          </div>

        </div>

        <Snackbar
          open={openToasty}
          onClose={handleCloseToasty}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          severity="error"
        >
          <Alert severity="error">
            {`El Product Code que intentas agregar no existe en el pedido`}
          </Alert>
        </Snackbar>

        {loading &&
          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: 100 }}>
            <CircularProgress color="secondary" />
          </div>
        }

        {!loading &&
          <>
            {/* Tabla Productos */}
            {inputValue == 'productos' &&
              <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
                <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                  <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Productos</p>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columnsCotizacion.map((column) => {
                          const id = column.id
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              <div style={{ display: 'flex' }}>
                                {id == 'tipo' &&
                                  <>
                                    <Tooltip
                                      visible={tooltipV}
                                      placement="top"
                                      animation="zoom"
                                      onVisibleChange={setToolTipVisible}
                                      trigger="click"
                                      overlay={
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><ShoppingBasketIcon style={{ marginRight: 4 }} />Pedido de Venta</div>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><FolderIcon style={{ marginRight: 4 }} />Reporte de Consumo</div>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><DescriptionIcon style={{ marginRight: 4 }} />Prestamo</div>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><SyncIcon style={{ marginRight: 4 }} />Consigna</div>
                                        </div>
                                      }
                                    >
                                      <InfoIcon onClick={setToolTipVisible} style={{ color: '#484f59' }} />
                                    </Tooltip>
                                  </>
                                }
                                {/*                             {columnsQuote.includes(id) && <ImportExportIcon style={{ color: '#484f59' }} />}
*/}                            {column.label}
                                {/*                             {id == 'vendedor' && <ImportExportIcon style={{ color: '#484f59' }} />}
*/}                          </div>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arrayProducts.map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columnsCotizacion.map((column) => {
                              const type = row.tipo
                              const value = row[column.id];
                              const informationStatus = getInfoOfAll(row.id)
                              const objectInformation = getObjectById(remissionInformation, row.id)
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id == 'tipo' ? < ShoppingBasketIcon style={{ color: '#484f59' }} /> : ''}
                                  {/* {column.id == 'tipo' && type == 'FolderIcon' && < FolderIcon style={{ color: '#484f59' }} />}
                      {column.id == 'tipo' && type == 'DescriptionIcon' && < DescriptionIcon style={{ color: '#484f59' }} />}
                      {column.id == 'tipo' && type == 'SyncIcon' && < SyncIcon style={{ color: '#484f59' }} />} */}

                                  {column.id == 'folio' &&
                                    <NavLink to={{ pathname: `/orderDetailsNew`, state: { order: row, remissionInformation: objectInformation, informationStatus } }} style={{ color: 'black' }}>
                                      <p style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>{row.id}</p>
                                    </NavLink>
                                  }
                                  {column.id == 'inicial' && moment(row.dinVariable2).format("DD/MMMM/YYYY")}
                                  {column.id == 'pactada' && <p style={{ color: value == '19/SEP/2022' ? 'red' : 'black' }}>{moment(row.deliveryDate).format("DD/MMMM/YYYY")}</p>}
                                  {column.id == 'productos' && row.dinVariable}
                                  {column.id == 'requerida' && <p style={{ fontWeight: 'bold' }}>{row.dinNumeric}</p>}
                                  {column.id == 'stock' && <p style={{ color: value < 11 ? 'red' : 'black', fontWeight: 'bold' }}>0</p>}
                                  {column.id == 'vendedor' && row.sellerName}

                                  {column.id == 'scan' && <CropFreeIcon onClick={handleOpenModalScan} />}

                                  {/* {column.id == 'icon' ? <VisibilityIcon onClick={handleOpen} /> : column.format && typeof value === 'number' ? column.format(value) : value} */}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Dialog
                  open={openModalScan}
                  onClose={handleCloseModalScan}
                  aria-labelledby="form-dialog-title"
                  fullWidth={true}
                  maxWidth='xl'
                >
                  <DialogTitle id="form-dialog-title"><div style={{ display: 'flex', marginBottom: 40 }}>
                    <p style={{ marginRight: 30, fontWeight: 'bold' }}>Escanear</p>
                  </div></DialogTitle>
                  <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'column', marginRight: '100px', fontFamily: 'arial' }}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        id="outlined-basic"
                        label="Línea de captura de escaneo"
                        variant="outlined"
                        style={{ width: '100%' }}
                        defaultValue={scanLineCode}
                        value={scanLineCode}
                        onChange={(e) => { handleAddAutomatic(e, null, null) }}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                      <Autocomplete
                        value={valueAlmacen}
                        onChange={(event, newValue) => { setValueAlmacen(newValue) }}
                        inputValue={inputValueAlmacen}
                        onInputChange={(event, newInputValue) => { handleAddAutomatic(null, newInputValue, null) }}
                        id="controllable-states-demo"
                        options={almacenList}
                        getOptionLabel={(option) => option ? `${option.name}` : 'Almacen'}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label="Almacen" variant="outlined" />}
                      />
                      <Autocomplete
                        value={valueLocation}
                        onChange={(event, newValue) => { setValueLocation(newValue) }}
                        inputValue={inputValueLocation}
                        onInputChange={(event, newInputValue) => { handleAddAutomatic(null, null, newInputValue) }}
                        id="controllable-states-demo"
                        options={locationList}
                        getOptionLabel={(option) => option ? `${option.name}` : 'Ubicación'}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />}
                      />
                    </div>
                  </div>
                  <DialogContent style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'row', marginRight: '100px', fontFamily: 'arial' }}>
                    <div style={{ width: '50%' }}>
                      {/* <button className="btn" style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }} onClick={() => {
            setOpenModalManualScan(true)
            setOpenModalScan(false)
          }}>CAPTURA MANUAL</button> */}
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
                      <button className="btn" onClick={addRemission} style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }}>GENERAR REMISIÓN</button>
                    </div>
                  </DialogContent>
                  <DialogActions style={{ marginTop: 100 }}>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openModalManualScan}
                  onClose={handleCloseModalManualScan}
                  aria-labelledby="form-dialog-title"
                  fullWidth={true}
                  maxWidth='xl'
                >
                  <DialogTitle id="form-dialog-title"><div style={{ display: 'flex', marginBottom: 40 }}>
                    <p style={{ marginRight: 30, fontWeight: 'bold' }}>Captura Manual</p>
                  </div></DialogTitle>
                  <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'column', marginRight: '100px', fontFamily: 'arial' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="outlined-basic" label="Línea de captura de escaneo" variant="outlined" style={{ width: '32%' }} />
                      <TextField id="outlined-basic" label="Lote" variant="outlined" style={{ width: '32%' }} />
                      <TextField id="outlined-basic" label="Pedimento" variant="outlined" style={{ width: '32%' }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                      <TextField id="outlined-basic" label="Almacen" variant="outlined" style={{ width: '32%' }} />
                      <TextField id="outlined-basic" label="Ubicación" variant="outlined" style={{ width: '32%' }} />
                      <div style={{ width: '32%' }}>
                        <button className="btn" style={{ width: 220, fontFamily: 'arial', fontWeight: 'bold' }}>AGREGAR</button>
                      </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                      <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columnsEscanear.map((column) => {
                                const id = column.id
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    <div style={{ display: 'flex' }}>
                                      {column.label}
                                    </div>
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rowsScan.map((row) => {
                              return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                  {columnsEscanear.map((column) => {
                                    const value = row[column.id];
                                    return (
                                      <TableCell key={column.id} align={column.align}>
                                        {column.id == 'borrar' && < DeleteIcon style={{ color: 'red' }} />}
                                        {column.id == 'cantidad' && <TextField id="outlined-basic" variant="outlined" value={value} />}
                                        {column.id != 'cantidad' && value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                  <DialogContent style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'row', marginRight: '100px', fontFamily: 'arial' }}>
                    <div style={{ width: '50%' }}>
                      <button className="btn" style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }} onClick={() => {
                        setOpenModalManualScan(false)
                        setOpenModalScan(true)
                      }}>CAPTURA AUTOMATICA</button>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
                      <button className="btn" style={{ width: 250, fontFamily: 'arial', fontWeight: 'bold' }} onClick={addRemission}>ENVIAR A REMISIÓN</button>
                    </div>
                  </DialogContent>
                  <DialogActions style={{ marginTop: 100 }}>
                  </DialogActions>
                </Dialog>
              </div>
            }

            {/* Tabla Pedidos */}
            {inputValue == 'pedidos' && clientsTable && clientsTable.length > 0 &&
              <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
                <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                  <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Pedidos</p>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        {columnsPedidos.map((column) => {
                          const id = column.id
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              width={column.minWidth}
                            >
                              <div style={{ display: 'flex', justifyContent: column.align }}>
                                {id == 'tipo' &&
                                  <>
                                    <Tooltip
                                      visible={tooltipV}
                                      placement="top"
                                      animation="zoom"
                                      onVisibleChange={setToolTipVisible}
                                      trigger="click"
                                      overlay={
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><ShoppingBasketIcon style={{ marginRight: 4 }} />Pedido de Venta</div>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><FolderIcon style={{ marginRight: 4 }} />Reporte de Consumo</div>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><DescriptionIcon style={{ marginRight: 4 }} />Prestamo</div>
                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}><SyncIcon style={{ marginRight: 4 }} />Consigna</div>
                                        </div>
                                      }
                                    >
                                      <InfoIcon onClick={setToolTipVisible} style={{ color: '#484f59' }} />
                                    </Tooltip>
                                  </>
                                }
                                {/* {columnsOrders.includes(id) && <ImportExportIcon style={{ color: '#484f59' }} />} */}
                                {column.label}
                                {/* {id == 'vendedor' && <ImportExportIcon style={{ color: '#484f59' }} />} */}
                              </div>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsOrders && rowsOrders.slice(pageOrders * rowsPerPageOrders, pageOrders * rowsPerPageOrders + rowsPerPageOrders).map((row, index) => {
                        const clientInformation = clientsTable[index]
                        const informationStatus = getInfoOfAll(row.id)
                        const objectInformation = getObjectById(remissionInformation, row.id)
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columnsPedidos.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align} width={column.minWidth}>
                                  {column.id == 'tipo' && < ShoppingBasketIcon style={{ color: '#484f59' }} />}
                                  {column.id == 'folio' &&
                                    <NavLink to={{ pathname: `/orderDetailsNew`, state: { order: row, remissionInformation: objectInformation, informationStatus } }} >
                                      <p style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>{row.id}</p>
                                    </NavLink>
                                  }
                                  {column.id == 'inicial' && moment(row.deliveryDate).format("DD/MMMM/YYYY")}
                                  {column.id == 'pactada' && <p style={{ color: value == '19/SEP/2022' ? 'red' : 'black' }}>{moment(row.deliveryDate).format("DD/MMMM/YYYY")}</p>}
                                  {column.id == 'cliente' && clientInformation ? `${clientInformation.clientCode ? `${clientInformation.clientCode} - ` : ''} ${clientInformation.clientName}` : ''}
                                  {column.id == 'proceso' && <p style={{
                                    color: informationStatus == 1 ? '#0b4674' : informationStatus == 2 ? '#5d7e40' : '#b14343',
                                    fontWeight: 'bold'
                                  }}>{informationStatus == 2 ? 'Surtido' : informationStatus == 0 ? 'Sin proceso' : informationStatus == 1 ? 'Parcialmente Surtido' : 'Sin proceso'}</p>}
                                  {column.id == 'status' && <p style={{
                                    color: value == '10%' ? '#ec6010' : value == '100%' ? '#5d7e40' : value == '0%' ? '#c70808' : '#ec6010',
                                    fontWeight: 'bold'
                                  }}>{row.dinVariable || '0'}%</p>}
                                  {column.id == 'envios' && <p>{row.dinNumeric || 0}</p>}
                                  {column.id == 'scan' && <CropFreeIcon />}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[7, 10]}
                  component="div"
                  count={rowsOrders ? rowsOrders.length : 0}
                  rowsPerPage={rowsPerPageOrders}
                  page={pageOrders}
                  onChangePage={handleChangePageOrders}
                  onChangeRowsPerPage={handleChangeRowsPerPageOrders}
                />
              </div>
            }
          </>
        }



      </div >
    </div >
  );
};
