import React from 'react'
import './style.scss';


export const ComentaryAdded = ({comentario,title}) => {
  return (
    <section className='container-component-comments-SeguimientoQuality'>
            <div className='header-commentsSeguimientoQuality'>
                <div className='header-titleSeguimientoQuality'>
                <p className='title-componentSeguimientoQuality'>{title}</p>
                </div>
            </div>
            <div className='body-SeguimientoQualityo'>
                <div className='container-SeguimientoQualityo'>
                    <textarea placeholder='Comentarios solo de Dirección y/o Calidad…' rows="5" cols="30" className='textArea-SeguimientoQualityo' 
                    value={comentario}
                    disabled
                    />
                </div>
            </div>
        </section>
  )
}
