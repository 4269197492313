import React from 'react';
import { useSelector } from 'react-redux';
import './styleCalificacion.scss';

export const CalificacionComponent = ({ type,typeCalificacion,setTypeCalificacion,tipo}) => {

    const valueCalificacion = '5=Servicio Exitoso';
    const user = useSelector(state => state.user.user.identity);

   /*  console.warn(typeCalificacion); */

    return (
        <section className='container-component-comments-Calificacion'>
            <div className='header-commentsCalificacion'>
                <div className='header-titleCalificacion'>
                    <p className='title-Calificacion'>CALIFICACIÓN *</p>
                </div>
            </div>

            <div className='body_Calificacion'>

                {
                    type === 'analisis'
                        ? <select className='select-Calificacion-analisis'
                            name="calificacion"
                            disabled
                        >
                            <option value={0}>{valueCalificacion && valueCalificacion}</option>

                        </select>
                        : <select className='select-Calificacion'
                            name="calificacion"
                            value={typeCalificacion}
                            onChange={(e) => setTypeCalificacion(e.target.value)}
                            disabled={(tipo !== 'seguimiento') ?true :user.roleID === 9 ?true  :false}
                        >
                            <option disabled selected>Seleccionar</option>
                            <option value={5}>5=Servicio Exitoso</option>
                            <option value={4}>4=Inconv.Inadvertidos / Servicio Exitoso </option>
                            <option value={3}>3=Inconv./ Servicio Exitoso</option>
                            <option value={2}>2=Inconv./ CX Retrasada </option>
                            <option value={1}>1=Inconv./ CX Cancelada</option>
                        </select>
                }

            </div>


        </section>
    )

}



