
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentsStoreHouseAtqWithParams, getAppointmentsStoreHouseSuccessAtq, getSearchListHouseSuccessAtq } from '../../../actions/storeHouseAction';
import { formatDateToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { setSurgeryChoosed } from '../../../actions/atqAdministradorAction';

export const StoreHouseRigthComponentReception = ({ activeSwitch }) => {
    const history = useHistory();
    const distpatch = useDispatch();
    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState(null);
    const searchBy2 = 'BY_SURGERY_STATUS';
    const searchBy = 'ANY';
    const offset = 10;
    const render = useRef(0);
    const listApoiment = useSelector(state => state.storeHouse.appointmentsListATQ);
    const listSearch = useSelector(state => state.storeHouse.listSearch);

    const handleNivagateToApproveOrder = (item) => {
        distpatch(setSurgeryChoosed(item));
        history.replace('/StoreHouse/StoreHomeReceivingMaterialReportScreen');
    }


    useEffect(() => {
        return () => {
            distpatch(getSearchListHouseSuccessAtq(null))
        }
    }, [])

    //AQUI ES PARA CUANDO CAMBIA DE BOTON
    useEffect(() => {
        if (render.current > 0) {
            setList(null);
            setPage(0);
            distpatch(getAppointmentsStoreHouseSuccessAtq([]));
            distpatch(getSearchListHouseSuccessAtq(null))

            if (currentPage === 0) {
                if (activeSwitch === "ACTIVE") {
                    distpatch(getAppointmentsStoreHouseAtqWithParams(startDate, endDate, searchBy, currentPage, offset))
                }
                if (activeSwitch === "INACTIVE") {
                    distpatch(getAppointmentsStoreHouseAtqWithParams(startDate, endDate, searchBy2, 10, currentPage, offset))
                }
            }
        } else {
            setList(null);
            render.current = 1;
        }
    }, [activeSwitch])


    useEffect(() => {

        if (currentPage === 0) {
            if (activeSwitch === "ACTIVE") {
                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate, endDate, searchBy, null, currentPage, offset))
            }
            if (activeSwitch === "INACTIVE") {
                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate, endDate, searchBy2, 10, currentPage, offset))
            }
        } else if (currentPage > 0 && listApoiment !== null) {
            if (activeSwitch === "ACTIVE") {
                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate, endDate, searchBy, null, currentPage, offset))
            }
            if (activeSwitch === "INACTIVE") {
                distpatch(getAppointmentsStoreHouseAtqWithParams(startDate, endDate, searchBy2, 10, currentPage, offset))
            }
        }
    }, [currentPage])

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }

    }
    //ESTE ES PARA USAR EL LIST 
    useEffect(() => {
        if (list && list.length > 0 && listApoiment.length > 0) {
            setList([...list, ...listApoiment])
        } else if (listApoiment && listApoiment.length > 0) {
            setList(listApoiment)
        }
    }, [listApoiment])


    return (
        <div className="storeHouseStock_elements_container_info" onScroll={handleScroll}>
            {

                (listSearch &&
                    listSearch.length > 0)

                    ? (
                        listSearch.map(item => (
                            <div key={item.id} className="storeHouseStock_elements_info_container" onClick={() => handleNivagateToApproveOrder(item)}>
                                <div className="storeHouseStock_elements_info_elements_container" >
                                    <span className="storeHouseStock_elements_info_date_container">
                                        <h3 className="storeHouseStock_elements_info_date_title">
                                            Fecha:
                                        </h3>
                                        <p className="storeHouseStock_elements_info_date">
                                            {item.surgeryDate && (formatDateToPresent(item.surgeryDate))}
                                        </p>
                                    </span>
                                    <span className="storeHouseStock_elements_info_ubicacion_container">
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {item.hospital && item.hospital.name}  -
                                        </p>
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {item.medic && ((item.medic.name && item.medic.name) + ' ' +
                                                (item.medic.secondName && item.medic.secondName) + ' ' +
                                                (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                                (item.medic.secondLastname && item.medic.secondLastname))}  -
                                        </p>
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {'PQX#' + item.id}
                                        </p>
                                    </span>
                                </div>
                                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                            </div>
                        ))
                    )
                    : (
                        list &&
                        list.length > 0 &&
                        list.map(item => (

                            <div className='content-div-list-generate' >
                                <div key={item.id} className="storeHouseStock_elements_info_container" onClick={() => handleNivagateToApproveOrder(item)}>
                                    <div className="storeHouseStock_elements_info_elements_container" >
                                        <span className="storeHouseStock_elements_info_date_container">
                                            <h3 className="storeHouseStock_elements_info_date_title">
                                                Fecha:
                                            </h3>
                                            <p className="storeHouseStock_elements_info_date">
                                                {item.surgeryDate && (formatDateToPresent(item.surgeryDate))}
                                            </p>
                                        </span>
                                        <span className="storeHouseStock_elements_info_ubicacion_container">
                                            <p className="storeHouseStock_elements_info_ubicacion_element">
                                                {item.hospital && item.hospital.name}  -
                                            </p>
                                            <p className="storeHouseStock_elements_info_ubicacion_element">
                                                {item.medic && ((item.medic.name && item.medic.name) + ' ' +
                                                    (item.medic.secondName && item.medic.secondName) + ' ' +
                                                    (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                                    (item.medic.secondLastname && item.medic.secondLastname))}  -
                                            </p>
                                            <p className="storeHouseStock_elements_info_ubicacion_element">
                                                {'PQX#' + item.id}
                                            </p>
                                        </span>
                                    </div>
                                    <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                                </div>
                            </div>
                        ))

                    )
            }
        </div>
    )
}

StoreHouseRigthComponentReception.propTypes = {
    activeSwitch: PropTypes.any,
}
