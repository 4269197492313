import React, { useState } from 'react';
// import { storeHouseAssignSystemsTableData } from './storeHouseAssignSystemsTableData';
import './sass/styles.scss';
import { StoreHouseAssignSystemTableModal } from './StoreHouseAssignSystemTableModal';
import PropTypes from 'prop-types';


export const StoreHouseAssignSystemsTableComponent = ({ arrayItemFromAppointment,result,setResult}) => {

    const [oppenModal, setOppenModal] = useState();
    const [itemToAddLocation, setItemToAddLocation] = useState();



    const handleAddLocation = (item) => {
        setOppenModal('OPEN');
        setItemToAddLocation(item);
    }
    const toggle = () => {
        setOppenModal(null);
    }

    return (
        <div className="StoreHouseAssignSystemsTableComponent-container-table">
            <div className="StoreHouseAssignSystemsTableComponent-precontainer-table">
                <table className="StoreHouseAssignSystemsTableComponent-table">
                    <thead className="StoreHouseAssignSystemsTableComponent-table-head">
                        <tr className="StoreHouseAssignSystemsTableComponent-table-tr">
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">Origen</th>
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">Ubicación</th>
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">No. Cajas Registradas</th>
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">Cantidad</th>
                        </tr>
                    </thead>


                    <tbody className="StoreHouseAssignSystemsTableComponent-table-body">


                        {
                            arrayItemFromAppointment && arrayItemFromAppointment.map(el => (
                                <tr key={el.id} className="StoreHouseAssignSystemsTableComponent-table-tr" >
                                    <td className="StoreHouseAssignSystemsTableComponent-body-data">
                                        {el.pickupPlace && el.pickupPlace}
                                    </td>
                                    <td className="StoreHouseAssignSystemsTableComponent-body-data StoreHouseAssignSystemsTableComponent-body-select" onClick={() => handleAddLocation(el)} >
                                        {/* {el.ubicacion}  */}
                                        {el.location && el.location.ubicacion}
                                        {el.ubicacion && el.ubicacion}
                                    </td>
                                    <td className="StoreHouseAssignSystemsTableComponent-body-data">
                                        {/* //{el.n_cajas_registradas}  */}
                                        {el.boxQuantity ?el.boxQuantity :'NA'}
                                    </td>
                                    <td className="StoreHouseAssignSystemsTableComponent-body-data">
                                        {el.cantidad} 
                                        {el.locationLevels && el.locationLevels}
                                        {el.productQuantity && el.productQuantity}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {
                oppenModal === 'OPEN' &&
                <StoreHouseAssignSystemTableModal
                    isOpen
                    toggle={toggle}
                    itemToAddLocation={itemToAddLocation}
                    result={result}
                    setResult={setResult}
                />
            }
        </div>
    )
}


StoreHouseAssignSystemsTableComponent.propTypes = {
    arrayItemFromAppointment: PropTypes.any,
    listItem: PropTypes.any,
    listItemsBody: PropTypes.any,

}