import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextShow from '../../../../../components/shared/Atoms/TextShow';

//const userSelectedId = useSelector(state => state.data.selectedUserId);
//const userList = useSelector(state => state.allUsers.listUsersByRoleId);

/* useEffect(() =>{
    if(userSelectedId){
        setUserSelected(userList.find((userId) => userId.userID === userSelectedId));
     }
  },[userSelectedId])
 */



const UserProfileData = ({ selectedSeller, item }) => {

    const [userSelected, setUserSelected] = useState(null);
    const [openReport, setOpenReport] = useState(false);

    // useEffect(() => {
      
    //   return () => {
    //     setOpenReport(!openReport)
    //   }
    // }, [])
    

    const userSelectedId = useSelector(state => state.allUsers.selectedUserId);
    const userList = useSelector(state => state.allUsers.listUsersByRoleId);

    //  useEffect(() =>{
    //     if(userSelectedId){
    //         setUserSelected(userList.find((userId) => userId.userID === userSelectedId));
    //      }
    //   },[userSelectedId])
    //   console.log('aqui',userSelected); 


    const [userRol, setUserRol] = useState(null)
    const data = useSelector(state => state.allUsers);


    useEffect(() => {
        if (data.userRolSelected) {
            setUserRol(data.userRolSelected)
        }
    }, [data])
    const doctorsInfo = () => {


        return (
            <div className='container-perfil-user'>

                <span className={"bigBlueText"}>{(selectedSeller && selectedSeller.name) + ' ' + (selectedSeller.secondName) + ' ' + (selectedSeller.firstLastname)
                    + ' ' + (selectedSeller.secondLastname)}</span>
                <span className={"subtitleProfile"}>{selectedSeller && selectedSeller.email}</span>

            </div>
        )
    }

    const atqInfo = () => {


        return (
            <div className='container-perfil-user'>

                <span className={"bigBlueText"}>{(selectedSeller && selectedSeller.firstName) + ' ' + (selectedSeller.secondName) + ' ' + (selectedSeller.firstLastName)
                    + ' ' + (selectedSeller.secondLastName)}</span>
                <span className={"subtitleProfile"}>{selectedSeller && selectedSeller.phone}</span>
                <span className={"subtitleProfile"}>{selectedSeller && selectedSeller.email}</span>

            </div>
        )
    }


    const patientsInfo = () => {
        return (
            <>
                <div className={'patients-right-bar-header'}>
                    <div className={'profile-data'}>
                        <span className={"titleProfile"}>Nombre</span>
                    </div>
                    <div className={'profile-data'}>
                        <span className={"titleProfile"}>Características</span>
                    </div>
                </div>
                <div className={'patien-profile-data'}>
                    <p className='name-patients-rigth'>{item.firstName + ' ' + (item.secondName) + ' ' + (item.firstLastName) + ' ' + (item.secondLastName)}</p>
                    <div className={"profile-data-content"}>
                        <div className={"profile-data-two-columns"}>
                            <span className={"subtitleProfileDark"}>Tipo de CX</span>
                            <span className={"subtitleProfile"}>Cirugía toracica</span>
                        </div>
                        <div className={"profile-data-two-columns"}>
                            <span className={"subtitleProfileDark"}>Sistema</span>
                            <span className={"subtitleProfile"}>Neon</span>
                        </div>
                        <div className={"profile-data-two-columns"}>
                            <span className={"subtitleProfileDark"}>Ultima CX</span>
                            <span className={"subtitleProfile"}>En caso de que haya tenido alguna otra CX</span>
                        </div>
                    </div>
                </div>

                <div className='container-patients-consumo'>
                    <button onClick={() => setOpenReport(!openReport)} className={`${openReport ? 'btn-consumo-patients-active' : 'btn-consumo-patients-inactive'}`}>REPORTE DE CONSUMO</button>
                </div>

                <div className='line-border'></div>


                {openReport &&

                    <div className='container-general-patient'>
                        <div className='container-report-patient'>

                            <TextShow title={'Medico'} description={"- - -"} />

                            <TextShow title={'Hospital'} description={"- - -"} />

                            <TextShow title={'Fecha'} description={"- - -"} />

                        </div>

                        <div className='container-report-patient-diferent'>
                            <TextShow title={'Sistema'} description={"- - -"} />

                            <TextShow title={'Configuracion'} description={"- - -"} />

                        </div>

                        <div className='container-report-patient'>
                            <TextShow title={'Descripción'} description={"- - -"} />

                            <TextShow title={'Cantidad'} description={"- - -"} />
                            <TextShow title={'No. de producto'} description={"- - -"} />

                        </div>



                    </div>

                }




            </>
        );
    }
    const institutionsInfo = () => {
        return (
            <>
                <div className={"profile-data-element"}>
                    <div className={"profile-content"}>
                        {

                            <span className={"bigBlueText"}></span>
                        }
                    </div>
                    <div className={"direction_content"}>
                        <p className='direccion-p-institutions'>Calle: - - -</p>
                        <p className='direccion-p-institutions'>Colonia:  - - -</p>
                        <p className='direccion-p-institutions'>Ciudad: {item.city && item.city}</p>
                        <p className='direccion-p-institutions'>Telefono:  - - - </p>
                    </div>
                </div>
            </>
        )
    }
    const otherUsersInfo = () => {
        return (
            <>
                {
                    <span className={"bigBlueText"}>{userSelected && userSelected.firstName && userSelected.firstName} {userSelected && userSelected.secondName}
                        {userSelected && userSelected.firstLastName} {userSelected && userSelected.secondLastName} </span>
                }
                {
                    <span className={"subtitleProfile"}>{userSelected && userSelected.phone}</span>
                }
                {
                    <span className={"subtitleProfile"}>{userSelected && userSelected.email} </span>
                }
            </>
        )
    }
    return (
        <div className={'profile-data'}>
            {
                userRol && userRol == 'doctor' &&
                doctorsInfo()
            }
            {
                userRol && userRol == 'atq' &&
                atqInfo()
            }
            {
                userRol && userRol == 'adminAtq' &&
                atqInfo()
            }
            {
                userRol && userRol == 'storer' &&
                atqInfo()
            }
            {
                userRol && userRol == 'seller' &&
                atqInfo()
            }

            {
                userRol && userRol == 'patients' &&
                patientsInfo()
            }
            {
                data.userRolSelected && data.userRolSelected == 'institutions' &&
                institutionsInfo()
            }


            {
                userRol && (userRol != '' && userRol != null && userRol != 'doctor' && userRol != 'patients' && userRol != 'institutions') &&
                otherUsersInfo()
            }
        </div>
    )
}

export default UserProfileData;
