import React from 'react';
import { useDispatch } from 'react-redux';
import { createTeam } from '../../../actions/atqAdministradorAction';
import Icon_Modify from "../../../assets/img/256PX/Icon_Modify.png";
import './style.scss';

export const ATQViewTeamComponent = ({nameGroup,members, setOpenModal,setPage,idGroup,setIdGroup}) => {

    const dispatch = useDispatch();
   /*  const [listAtq, setListAtq] = useState(null); */
    let byOrder = null;
    const orderMembers = () => {
        byOrder = members.slice(0);
        byOrder.sort(function(a,b) {
            return a.orderInGroup - b.orderInGroup;
        });
        
    }
    orderMembers();
    //setListAtq(orderMembers);
    

    
    const handleGroupSelected = (atqList) =>{
        if(members && members.length > 0){
            
            dispatch(createTeam({teamList:{atqList}}));
        }
        else{
            dispatch(createTeam({teamList:{}}));
        }
            setPage("modify");
            setIdGroup(idGroup);
            setOpenModal("CREATE-TEAM");
    } 
    
    return (
        <div className="container_team">
            <span className ="name_team">
                {nameGroup}
            </span>
            <ol className="list">
            {
                byOrder &&
                byOrder.map((item) => (
                    <li>
                        {item.user.firstName + " " + item.user.firstLastName + " " +item.user.secondLastName}
                    </li>
                ))
            }
            </ol>
            <button className="btn_modify_team" onClick={() => handleGroupSelected(members)}>
                <span>Modificar</span>
                <img src={Icon_Modify} alt=""  />
            </button>
        </div>
    )
}
