import {

    SET_USER_ROL_SELECTED_BEGIN,
    SET_USER_ROL_SELECTED_SUCCESS,
    SET_USER_ROL_SELECTED_FAILURE,
    GET_USERS_MEDICS_LIST_BEGIN,
    GET_USERS_MEDICS_LIST_SUCCESS,
    GET_USERS_MEDICS_LIST_FAILURE,
    SET_SELECTED_USER_BEGIN,
    SET_SELECTED_USER_SUCCESS,
    SET_SELECTED_USER_FAILURE,
    GET_SELECTED_USER_BEGIN,
    GET_SELECTED_USER_SUCCESS,
    GET_SELECTED_USER_FAILURE,
    GET_PATIENTS_IN_USER_BEGIN,
    GET_PATIENTS_IN_SELECTED_USER_SUCCESS,
    GET_PATIENTS_IN_SELECTED_USER_FAILURE,
    GET_PROCESSES_IN_USER_BEGIN,
    GET_PROCESSES_IN_SELECTED_USER_SUCCESS,
    GET_PROCESSES_IN_SELECTED_USER_FAILURE,
    GET_STATES_IN_PROCESSES_BEGIN,
    GET_STATES_IN_PROCESSES_SUCCESS,
    GET_STATES_IN_PROCESSES_FAILURE,
    GET_SURGERIES_IN_USER_BEGIN,
    GET_SURGERIES_IN_USER_SUCCESS,
    GET_SURGERIES_IN_USER_FAILURE,
    GET_USER_ROLS_BEGIN,
    GET_USER_ROLS_SUCCESS,
    GET_USER_ROLS_FAILURE,
    GET_USERS_CHAT_BEGIN,
    GET_USERS_CHAT_SUCCESS,
    GET_USERS_CHAT_FAILURE,
    GET_CHAT_FROM_USER_BEGIN,
    GET_CHAT_FROM_USER_SUCCESS,
    GET_CHAT_FROM_USER_FAILURE,
    SET_USER_CHAT_SELECTED_BEGIN,
    SET_USER_CHAT_SELECTED_SUCCESS,
    SET_USER_CHAT_SELECTED_FAILURE,
    GET_SELLERS_BEGIN,
    GET_SELLERS_SUCCESS,
    GET_SELLERS_FAILURE,
    SET_SELLER_USER,
    GET_USER_SPECIFIC_PRODUCT_GROUPS_BEGIN,
    GET_USER_SPECIFIC_PRODUCT_GROUPS_SUCCESS,
    GET_USER_SPECIFIC_PRODUCT_GROUPS_FAILURE,
    GET_PRODUCT_GROUPS_BEGIN,
    GET_PRODUCT_GROUPS_SUCCESS,
    GET_PRODUCT_GROUPS_FAILURE,
    ADD_USER_SPECIFIC_GROUP_BEGIN,
    ADD_USER_SPECIFIC_GROUP_SUCCESS,
    ADD_USER_SPECIFIC_GROUP_FAILURE,
    REMOVE_USER_SPECIFIC_PRODUCT_GROUP_BEGIN,
    REMOVE_USER_SPECIFIC_PRODUCT_GROUP_SUCCESS,
    REMOVE_USER_SPECIFIC_PRODUCT_GROUP_FAILURE,
    GET_SPECIFIC_BUNDLES_BEGIN,
    GET_SPECIFIC_BUNDLES_SUCCESS,
    GET_SPECIFIC_BUNDLES_FAILURE,
    ADD_USER_SPECIFIC_BUNDLE_BEGIN,
    ADD_USER_SPECIFIC_BUNDLE_SUCCESS,
    ADD_USER_SPECIFIC_BUNDLE_FAILURE,
    DELETE_SPECIFIC_BUNDLE_BEGIN,
    DELETE_SPECIFIC_BUNDLE_SUCCESS,
    DELETE_SPECIFIC_BUNDLE_FAILURE,
    GET_USER_SPECIFIC_PRICELISTS_BEGIN,
    GET_USER_SPECIFIC_PRICELISTS_SUCCESS,
    GET_USER_SPECIFIC_PRICELISTS_FAILURE,
    ADD_USER_SPECIFIC_PRICELIST_BEGIN,
    ADD_USER_SPECIFIC_PRICELIST_SUCCESS,
    ADD_USER_SPECIFIC_PRICELIST_FAILURE,
    REMOVE_USER_SPECIFIC_PRICELIST_BEGIN,
    REMOVE_USER_SPECIFIC_PRICELIST_SUCCESS,
    REMOVE_USER_SPECIFIC_PRICELIST_FAILURE,
    GET_USERS_BY_ROLE_ID_BEGIN,
    GET_USERS_BY_ROLE_ID_SUCCESS,
    GET_USERS_BY_ROLE_ID_FAILURE,


    GET_USERS_BY_ROLE_ID_ATQ_BEGIN,
    GET_USERS_BY_ROLE_ID_ATQ_SUCCESS,
    GET_USERS_BY_ROLE_ID_ATQ_FAILURE,

    GET_USERS_BY_ROLE_ID_ADMIN_BEGIN,
    GET_USERS_BY_ROLE_ID_ADMIN_SUCCESS,
    GET_USERS_BY_ROLE_ID_ADMIN_FAILURE,

    GET_USERS_BY_ROLE_ID_STORER_BEGIN,
    GET_USERS_BY_ROLE_ID_STORER_SUCCESS,
    GET_USERS_BY_ROLE_ID_STORER_FAILURE,



    SEARCH_PRODUCT_GROUPS_BEGIN,
    SEARCH_PRODUCT_GROUPS_SUCCESS,
    SEARCH_PRODUCT_GROUPS_FAILURE,
    OPTION_SELECTED_BEGIN,
    OPTION_SELECTED_SUCCESS,
    OPTION_SELECTED_FAILURE,
    GET_CHAT_GROUP_SESIONS_BEGIN,
    GET_CHAT_GROUP_SESIONS_SUCCESS,
    GET_CHAT_GROUP_SESIONS_FAILURE,
    SET_SELECTED_SURGERY_BEGIN,
    SET_SELECTED_SURGERY_SUCCESS,
    GET_OPEN_SURGERY,
    GET_CHAT_MEMBERS_BEGIN,
    GET_CHAT_MEMBERS_SUCCESS,
    GET_CHAT_MEMBERS_FAILURE,
    SET_SELECTED_CHAT_BEGIN,
    SET_SELECTED_CHAT_SUCCESS,
    GET_APPOINTMENTS_BEGIN,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_CHAT_MESSAGES_BEGIN,
    GET_CHAT_MESSAGES_SUCCESS,
    GET_CHAT_MESSAGES_FAILURE,
    GET_INSTITUTIONS_SESIONS_BEGIN,
    GET_INSTITUTIONS_SESIONS_SUCCESS,
    GET_INSTITUTIONS_SESIONS_FAILURE,
    GET_PATIENT_SESIONS_BEGIN,
    GET_PATIENT_SESIONS_SUCCESS,
    GET_PATIENT_SESIONS_FAILURE,
    GET_SEARCH_BEGING,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_FAILURE,
    SEARCH_USER_FAILURE,
    SEARCH_USER_BEGIN,
    SEARCH_USER_SUCCESS,
    SEARCH_INSTITUTION_BEGIN,
    SEARCH_INSTITUTION_SUCCESS,
    SEARCH_INSTITUTION_FAILURE,
    SEARCH_PATIENT_SESIONS_BEGIN,
    SEARCH_PATIENT_SESIONS_SUCCESS,
    SEARCH_PATIENT_SESIONS_FAILURE,
    SEARCH_GROUP_SURGERY_SESIONS ,
    PATIENT_PARAMS_SESIONS_BEGIN,
    PATIENT_PARAMS_SESIONS_SUCCESS,
    PATIENT_PARAMS_SESIONS_FAILURE,
    SEARCH_ATQ_BEGIN,
    SEARCH_ATQ_SUCCESS,
    SEARCH_ATQ_FAILURE,
    GET_USER_BY_USER_ID_BEGIN,
    GET_USER_BY_USER_ID_SUCCESS,
    GET_USER_BY_USER_ID_FAILURE,
    GET_OFFICE_PARCEL,
    GET_OFFICE_PARCELS_SUCCESS,
    GET_OFFICE_PARCELS_FAILURE,
} from '../constants/actionTypes';

import request from '../utils/request';

// SET USER ROL SELECTED

export const getUserByIdBegin = () => ({
    type: GET_USER_BY_USER_ID_BEGIN,
});

export const getUserByIdSuccess = (user) => ({
    type: GET_USER_BY_USER_ID_SUCCESS,
    payload: {
        user
    }
});

export const getUserByIdFailure = (sellerUser) => ({
    type: GET_USER_BY_USER_ID_FAILURE,
    payload: {
        sellerUser
    }
});


export const setUserRolSelectedBegin = () => ({
    type: SET_USER_ROL_SELECTED_BEGIN,
});

export const setUserRolSelectedSuccess = (userRolSelected) => ({
    type: SET_USER_ROL_SELECTED_SUCCESS,
    payload: {
        userRolSelected
    }
});

export const setSellerUser = (sellerUser) => ({
    type: SET_SELLER_USER,
    payload: {
        sellerUser
    }
});

export const setUserRolSelectedFailure = () => ({
    type: SET_USER_ROL_SELECTED_FAILURE,
});

// GET MEDICS

export const getMedicsListBegin = () => ({
    type: GET_USERS_MEDICS_LIST_BEGIN,
});

export const getMedicsListSuccess = (getMedicList) => ({
    type: GET_USERS_MEDICS_LIST_SUCCESS,
    payload: {
        getMedicList
    }
});

export const getMedicsListFailure = () => ({
    type: GET_USERS_MEDICS_LIST_FAILURE,
});

// SET SELECTED USER ID

export const setUserIdBegin = () => ({
    type: SET_SELECTED_USER_BEGIN,
});

export const setUserIdSuccess = (id) => ({
    type: SET_SELECTED_USER_SUCCESS,
    payload: {
        id
    }
});



export const setSurgeryIdBegin = () => ({
    type: SET_SELECTED_SURGERY_BEGIN,
});

export const setSurgeryIdSuccess = (id) => ({
    type: SET_SELECTED_SURGERY_SUCCESS,
    payload: {
        id
    }
});


export const setChatIdBegin = () => ({
    type: SET_SELECTED_CHAT_BEGIN,
});

export const setChatIdSuccess = (id) => ({
    type: SET_SELECTED_CHAT_SUCCESS,
    payload: {
        id
    }
});


export const optionIdFailure = () => ({
    type: OPTION_SELECTED_FAILURE,
});


export const optionIdBegin = () => ({
    type: OPTION_SELECTED_BEGIN,
});

export const optionIdSuccess = (id) => ({
    type: OPTION_SELECTED_SUCCESS,
    payload: {
        id
    }
});

export const setUserIdFailure = () => ({
    type: SET_SELECTED_USER_FAILURE,
});
// GET SELECTED USER ID

export const getUserIdBegin = () => ({
    type: GET_SELECTED_USER_BEGIN,
});

export const getUserIdSuccess = (user) => ({
    type: GET_SELECTED_USER_SUCCESS,
    payload: {
        user
    }
});

export const getUserIdFailure = () => ({
    type: GET_SELECTED_USER_FAILURE,
});

// GET PATIENTS IN USER

export const getPatientsInUserBegin = () => ({
    type: GET_PATIENTS_IN_USER_BEGIN,
});

export const getPatientsInUserSuccess = (patients) => ({
    type: GET_PATIENTS_IN_SELECTED_USER_SUCCESS,
    payload: {
        patients
    }
});

export const getPatientsInUserFailure = () => ({
    type: GET_PATIENTS_IN_SELECTED_USER_FAILURE,
});

// GET PROCESSES IN USER

export const getProcessesInUserBegin = () => ({
    type: GET_PROCESSES_IN_USER_BEGIN,
});

export const getProcessesInUserSuccess = (processes) => ({
    type: GET_PROCESSES_IN_SELECTED_USER_SUCCESS,
    payload: {
        processes
    }
});

export const getProcessesInUserFailure = () => ({
    type: GET_PROCESSES_IN_SELECTED_USER_FAILURE,
});

// GET STATES IN PROCESSES

export const getStatesInProcessesBegin = () => ({
    type: GET_STATES_IN_PROCESSES_BEGIN,
});

export const getStatesInProcessesSuccess = (states) => ({
    type: GET_STATES_IN_PROCESSES_SUCCESS,
    payload: {
        states
    }
});

export const getStatesInProcessesFailure = () => ({
    type: GET_STATES_IN_PROCESSES_FAILURE,
});

// GET SURGERIES IN USER

export const getSurgeriesBegin = () => ({
    type: GET_SURGERIES_IN_USER_BEGIN,
});

export const getSurgeriesSuccess = (surgeries) => ({
    type: GET_SURGERIES_IN_USER_SUCCESS,
    payload: {
        surgeries
    }
});

export const getSurgeriesFailure = () => ({
    type: GET_SURGERIES_IN_USER_FAILURE,
});

// GET USER ROLS

export const getUserRolsBegin = () => ({
    type: GET_USER_ROLS_BEGIN,
});

export const getUserRolsSuccess = (rols) => ({
    type: GET_USER_ROLS_SUCCESS,
    payload: {
        rols
    }
});

export const getUserRolsFailure = () => ({
    type: GET_USER_ROLS_FAILURE,
});

// GET USERS CHAT

export const getUsersChatBegin = () => ({
    type: GET_USERS_CHAT_BEGIN,
});

export const getUsersChatSuccess = (users) => ({
    type: GET_USERS_CHAT_SUCCESS,
    payload: {
        users
    }
});

export const getUsersChatFailure = () => ({
    type: GET_USERS_CHAT_FAILURE,
});

// GET CHATS FROM USER

export const getChatsFromUserBegin = () => ({
    type: GET_CHAT_FROM_USER_BEGIN,
});

export const getChatsFromUserSuccess = (chats) => ({
    type: GET_CHAT_FROM_USER_SUCCESS,
    payload: {
        chats
    }
});

export const getChatsFromUserFailure = () => ({
    type: GET_CHAT_FROM_USER_FAILURE,
});

// SET USER CHAT SELECTED

export const setUserChatSelectedBegin = () => ({
    type: SET_USER_CHAT_SELECTED_BEGIN,
});

export const setUserChatSelectedSuccess = (id) => ({
    type: SET_USER_CHAT_SELECTED_SUCCESS,
    payload: {
        id
    }
});

export const setUserChatSelectedFailure = () => ({
    type: SET_USER_CHAT_SELECTED_FAILURE,
});

// GET SELLERS

export const getSellersBegin = () => ({
    type: GET_SELLERS_BEGIN,
});

export const getSellersSuccess = (listSellers) => ({
    type: GET_SELLERS_SUCCESS,
    payload: {
        listSellers
    }
});

export const getSellersFailure = () => ({
    type: GET_SELLERS_FAILURE,
});


// GET SELLERS

export const getUserSpecificProductGroupsBegin = () => ({
    type: GET_USER_SPECIFIC_PRODUCT_GROUPS_BEGIN,
});

export const getUserSpecificProductGroupsSuccess = (listSpecificProductUser) => ({
    type: GET_USER_SPECIFIC_PRODUCT_GROUPS_SUCCESS,
    payload: {
        listSpecificProductUser
    }
});

export const getUserSpecificProductGroupsFailure = () => ({
    type: GET_USER_SPECIFIC_PRODUCT_GROUPS_FAILURE,
});


// GET SELLERS

export const addUserSpecificGroupBegin = () => ({
    type: ADD_USER_SPECIFIC_GROUP_BEGIN,
});

export const addUserSpecificGroupSuccess = (addedUserSpecificProductGroup) => ({
    type: ADD_USER_SPECIFIC_GROUP_SUCCESS,
    payload: {
        addedUserSpecificProductGroup
    }
});

export const addUserSpecificGroupFailure = () => ({
    type: ADD_USER_SPECIFIC_GROUP_FAILURE,
});


// GET SELLERS

export const getProductGroupsBegin = () => ({
    type: GET_PRODUCT_GROUPS_BEGIN,
});

export const getProductGroupsSuccess = (listProductGoups) => ({
    type: GET_PRODUCT_GROUPS_SUCCESS,
    payload: {
        listProductGoups
    }
});

export const getProductGroupsFailure = () => ({
    type: GET_PRODUCT_GROUPS_FAILURE,
});



// GET SELLERS

export const removeUserSpecificProductGroupBegin = () => ({
    type: REMOVE_USER_SPECIFIC_PRODUCT_GROUP_BEGIN,
});

export const removeUserSpecificProductGroupSuccess = (deleteUserSpecificProductGroup) => ({
    type: REMOVE_USER_SPECIFIC_PRODUCT_GROUP_SUCCESS,
    payload: {
        deleteUserSpecificProductGroup
    }
});

export const removeUserSpecificProductGroupFailure = () => ({
    type: REMOVE_USER_SPECIFIC_PRODUCT_GROUP_FAILURE,
});

// GET SELLERS

export const getSpecificBundlesBegin = () => ({
    type: GET_SPECIFIC_BUNDLES_BEGIN,
});

export const getSpecificBundlesSuccess = (listBundlesGoups) => ({
    type: GET_SPECIFIC_BUNDLES_SUCCESS,
    payload: {
        listBundlesGoups
    }
});

export const getSpecificBundlesFailure = () => ({
    type: GET_SPECIFIC_BUNDLES_FAILURE,
});

// GET SELLERS

export const addUserSpecificBundleBegin = () => ({
    type: ADD_USER_SPECIFIC_BUNDLE_BEGIN,
});

export const addUserSpecificBundleSuccess = (addedUserSpecificBundleGroup) => ({
    type: ADD_USER_SPECIFIC_BUNDLE_SUCCESS,
    payload: {
        addedUserSpecificBundleGroup
    }
});

export const addUserSpecificBundleFailure = () => ({
    type: ADD_USER_SPECIFIC_BUNDLE_FAILURE,
});

// GET SELLERS

export const deleteSpecificBundleBegin = () => ({
    type: DELETE_SPECIFIC_BUNDLE_BEGIN,
});

export const deleteSpecificBundleSuccess = (deleteUserSpecificBundleGroup) => ({
    type: DELETE_SPECIFIC_BUNDLE_SUCCESS,
    payload: {
        deleteUserSpecificBundleGroup
    }
});

export const deleteSpecificBundleFailure = () => ({
    type: DELETE_SPECIFIC_BUNDLE_FAILURE,
});

// GET SELLERS

export const getUserSpecificPricelistsBegin = () => ({
    type: GET_USER_SPECIFIC_PRICELISTS_BEGIN,
});

export const getUserSpecificPricelistsSuccess = (listSpecificPricelists) => ({
    type: GET_USER_SPECIFIC_PRICELISTS_SUCCESS,
    payload: {
        listSpecificPricelists
    }
});

export const getUserSpecificPricelistsFailure = () => ({
    type: GET_USER_SPECIFIC_PRICELISTS_FAILURE,
});

// GET SELLERS

export const addUserSpecificPricelistBegin = () => ({
    type: ADD_USER_SPECIFIC_PRICELIST_BEGIN,
});

export const addUserSpecificPricelistSuccess = (addedUserSpecificPricelist) => ({
    type: ADD_USER_SPECIFIC_PRICELIST_SUCCESS,
    payload: {
        addedUserSpecificPricelist
    }
});

export const addUserSpecificPricelistFailure = () => ({
    type: ADD_USER_SPECIFIC_PRICELIST_FAILURE,
});

// GET SELLERS

export const removeUserSpecificPricelistBegin = () => ({
    type: REMOVE_USER_SPECIFIC_PRICELIST_BEGIN,
});

export const removeUserSpecificPricelistSuccess = (removeUserSpecificPricelist) => ({
    type: REMOVE_USER_SPECIFIC_PRICELIST_SUCCESS,
    payload: {
        removeUserSpecificPricelist
    }
});

export const removeUserSpecificPricelistFailure = () => ({
    type: REMOVE_USER_SPECIFIC_PRICELIST_FAILURE,
});

// GET SELLERS

export const getUsersByRoleIdBegin = () => ({
    type: GET_USERS_BY_ROLE_ID_BEGIN,
});

export const getUsersByRoleIdSuccess = (listUsersByRoleId) => ({
    type: GET_USERS_BY_ROLE_ID_SUCCESS,
    payload: {
        listUsersByRoleId
    }
});

export const getUsersByRoleIdFailure = () => ({
    type: GET_USERS_BY_ROLE_ID_FAILURE,
});


// GET ATQ

export const getUsersByRoleIdATQBegin = () => ({
    type: GET_USERS_BY_ROLE_ID_ATQ_BEGIN,
});

export const getUsersByRoleIdATQSuccess = (listUsersByRoleId) => ({
    type: GET_USERS_BY_ROLE_ID_ATQ_SUCCESS,
    payload: {
        listUsersByRoleId
    }
});

export const getUsersByRoleIdATQFailure = () => ({
    type: GET_USERS_BY_ROLE_ID_ATQ_FAILURE,
});


//GET AdminATQ

export const getUsersByRoleIdAdminBegin = () => ({
    type: GET_USERS_BY_ROLE_ID_ADMIN_BEGIN,
});

export const getUsersByRoleIdAdminSuccess = (listUsersByRoleId) => ({
    type: GET_USERS_BY_ROLE_ID_ADMIN_SUCCESS,
    payload: {
        listUsersByRoleId
    }
});

export const getUsersByRoleIdAdminFailure = () => ({
    type: GET_USERS_BY_ROLE_ID_ADMIN_FAILURE,
});


//GET Storer
export const getUsersByRoleIdStorerBegin = () => ({
    type: GET_USERS_BY_ROLE_ID_STORER_BEGIN,
});

export const getUsersByRoleIdStorerSuccess = (listUsersByRoleId) => ({
    type: GET_USERS_BY_ROLE_ID_STORER_SUCCESS,
    payload: {
        listUsersByRoleId
    }
});

export const getUsersByRoleIdStorerFailure = () => ({
    type: GET_USERS_BY_ROLE_ID_STORER_FAILURE,
});





// GET SELLERS

export const searchProductGroupsBegin = () => ({
    type: SEARCH_PRODUCT_GROUPS_BEGIN,
});

export const searchProductGroupsSuccess = (listProductGroupsSearch) => ({
    type: SEARCH_PRODUCT_GROUPS_SUCCESS,
    payload: {
        listProductGroupsSearch
    }
});

export const searchProductGroupsFailure = () => ({
    type: SEARCH_PRODUCT_GROUPS_FAILURE,
});

export const getAppointmentsStoreHouseBegin = () => ({
    type: GET_APPOINTMENTS_BEGIN
});
export const getAppointmentsStoreHouseSuccess = (appointments) => ({
    type: GET_APPOINTMENTS_SUCCESS,
    payload: {
        appointments
    }
});
export const getAppointmentsStoreHouseFailure = () => ({
    type: GET_APPOINTMENTS_FAILURE
});

export const get_chat_group_begin = () => ({
    type: GET_CHAT_GROUP_SESIONS_BEGIN,
});
export const get_chat_group_success = (response) => ({
    type: GET_CHAT_GROUP_SESIONS_SUCCESS,
    payload: {
        response
    }
});
export const get_chat_group_failure = () => ({
    type: GET_CHAT_GROUP_SESIONS_FAILURE,
});


export const get_chat_members_begin = () => ({
    type: GET_CHAT_MEMBERS_BEGIN,
});
export const get_chat_members_success = (members) => ({
    type: GET_CHAT_MEMBERS_SUCCESS,
    payload: {
        members
    }
});
export const get_chat_members_failure = () => ({
    type: GET_CHAT_MEMBERS_FAILURE,
});


export const get_chat_mesagges_begin = () => ({
    type: GET_CHAT_MESSAGES_BEGIN,
});
export const get_chat_mesagges_success = (response) => ({
    type: GET_CHAT_MESSAGES_SUCCESS,
    payload: {
        response
    }
});
export const get_chat_mesagges_failure = () => ({
    type: GET_CHAT_MESSAGES_FAILURE,
});


export const getInstitutionsBegin = () => ({
    type: GET_INSTITUTIONS_SESIONS_BEGIN
});

export const getInstitutionsSucess = (institutions) => ({
    type: GET_INSTITUTIONS_SESIONS_SUCCESS,
    payload: {
        institutions
    },
});

export const getInstitutionsFailure = (err) => ({
    type: GET_INSTITUTIONS_SESIONS_FAILURE,
    error: err
});

//---------Get Patients
export const getPatientsBegin = () => ({
    type: GET_PATIENT_SESIONS_BEGIN
});

export const getPatientsSuccess = (patient) => ({
    type: GET_PATIENT_SESIONS_SUCCESS,
    payload: {
        patient
    },
});

export const getPatientsFailure = (err) => ({
    type: GET_PATIENT_SESIONS_FAILURE,
    error: err
});

//SEARCH USER
export const getSearchBegin = () => ({
    type: GET_SEARCH_BEGING,
});
export const getSearchSuccess = (response) => ({
    type: GET_SEARCH_SUCCESS,
    payload: {
        response
    }
});
export const getSearchFailure = () => ({
    type: GET_SEARCH_FAILURE,
});


//Search medic

export const searchUserFailure = () => ({
    type: SEARCH_USER_FAILURE,
});

export const searchUserBegin = () => ({
    type: SEARCH_USER_BEGIN,
});

export const searchUserSuccess = (searchUser) => ({
    type: SEARCH_USER_SUCCESS,
    payload: {
        searchUser
    }
});

//Search Hospital

export const searchHospitalBegin = () => ({
    type: SEARCH_INSTITUTION_BEGIN,
});

export const searchHospitalSuccess = (searchHospital) => ({
    type: SEARCH_INSTITUTION_SUCCESS,
    payload: {
        searchHospital
    }
});

export const searchHospitalFailure = () => ({
    type: SEARCH_INSTITUTION_FAILURE,
});

//Search ATQ

export const searchATQBegin = () => ({
    type: SEARCH_ATQ_BEGIN,
});

export const searchATQSuccess = (searchATQ) => ({
    type: SEARCH_ATQ_SUCCESS,
    payload: {
        searchATQ
    }
});

export const searchATQFailure = () => ({
    type: SEARCH_ATQ_FAILURE,
}); 

//Search Patientes

export const searchPatientsBegin = () => ({
    type: SEARCH_PATIENT_SESIONS_BEGIN,
});

export const searchPatientsSuccess = (searchPatient) => ({
    type: SEARCH_PATIENT_SESIONS_SUCCESS,
    payload: {
        searchPatient
    }
});

export const patientParamsFailure = () => ({
    type: PATIENT_PARAMS_SESIONS_FAILURE,
});



export const searchGroupSurgerySesions = (item) => ({
    type: SEARCH_GROUP_SURGERY_SESIONS ,
    payload: {
        item
    }
});

// GET OFFICE PARCEL
export const getOfficeParcels = () => ({
    type: GET_OFFICE_PARCEL,
});

export const getOfficeParcelsSuccess = (listOfficeParcels) => ({
    type: GET_OFFICE_PARCELS_SUCCESS,
    payload: {
        listOfficeParcels
    }
});

export const getOfficeParcelsFailure = () => ({
    type: GET_OFFICE_PARCELS_FAILURE,
});


//Members de chat
export function getChatMembers(id) {
    return (dispatch, getState) => {
        dispatch(get_chat_members_begin());
        return request(
            {
                partialUrl: `/API/Chat/GetChatGroupMembers?Id=${id}`,
                state: getState().user.request,
                method: 'GET',
                body: {
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(get_chat_members_success(data.response));
            })
            .catch(err => {
                return dispatch(get_chat_members_failure(err));
            })
    };
}


//CON ESTA FUNCION TU OBTENER TODOS LOS MENSAJES DEL CHAT
export function getMessagesToChatGroup(ChatGroupId, currentPage, offset = 50) {
    return (dispatch, getState) => {
        dispatch(get_chat_mesagges_begin());
        return request(
            {
                partialUrl: `/API/Chat/GetChatGroupMessages?chatGroupId=${ChatGroupId}&currentPage=${currentPage}& = 50=${offset}`,
                state: getState().user.request,
                method: 'GET',
                body: {
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(get_chat_mesagges_success(data.response));
            })
            .catch(err => {
                return dispatch(get_chat_mesagges_failure(err));
            })
    };
}



//currentPage = 0, offset = 10S
export const getAppointmentsStoreHouse = (startDate, endDate, SearchBy = null, searchByParam = null, currentPage = null, offset = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(getAppointmentsStoreHouseBegin());
            const fetchrequest = await request({
                partialUrl: `/API/Appointment/GetAppointments?startDate=${startDate}&endDate=${endDate}${SearchBy ? `&SearchBy=${SearchBy}` : ''}${searchByParam ? `&searchByParam=${searchByParam}` : ''}${currentPage ? `&currentPage=${currentPage}` : ' '}${offset ? `&offset=${offset}` : ' '}`,
                method: 'GET',
                state: getState().user.request,
                body: {}
            })
            const response = await fetchrequest.json();
            const data = await response.response;
            return dispatch(getAppointmentsStoreHouseSuccess(data));

        } catch (err) {
            return dispatch(getAppointmentsStoreHouseFailure(err))

        }
    }
}

export function getChatGroup(surgeryId, currentPage = 0, offset = 10, onlyUserChats = true) {
    return (dispatch, getState) => {
        dispatch(get_chat_group_begin());
        return request(
            {
                partialUrl: `/API/Chat/GetChatGroup?surgeryId=${surgeryId}&currentPage=${currentPage}&offset=${offset}&onlyUserChats=${onlyUserChats}`,
                state: getState().user.request,
                method: 'GET',
                body: {
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(get_chat_group_success(data.response));

            })
            .catch(err => {
                return dispatch(get_chat_group_failure(err));
            })
    };
}




export const openSurgery = (isOpenSurgey) => ({
    type: GET_OPEN_SURGERY,
    payload: {
        isOpenSurgey,
    }
});



// SET USER ROL SELECTED

export function setUserRolSelected(userRolSelected = null) {

    return function (dispatch) {
        dispatch(setUserRolSelectedBegin());
        dispatch(setUserRolSelectedSuccess(userRolSelected));
    }
}


export function getUsersByRoleIdStorer({ roleId = 13 }) {
    return (dispatch, getState) => {
        dispatch(getUsersByRoleIdStorerBegin());
        return request(
            {
                partialUrl: `/API/Account/GetUsersByRole?request=${roleId}`,
                method: 'GET',
                state: getState().user.request,
                body: {},
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(getUsersByRoleIdStorerSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getUsersByRoleIdStorerFailure(err));
            })
    };
}

export function getUsersByRoleATQId({ roleId = 5 }) {
    return (dispatch, getState) => {
        dispatch(getUsersByRoleIdATQBegin());
        return request(
            {
                partialUrl: `/API/Account/GetUsersByRole?request=${roleId}`,
                method: 'GET',
                state: getState().user.request,
                body: {},
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(getUsersByRoleIdATQSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getUsersByRoleIdATQFailure(err));
            })
    };
}

export function getUsersByRoleId({ roleId = 5 }) {
    return (dispatch, getState) => {
        dispatch(getUsersByRoleIdBegin());
        return request(
            {
                partialUrl: `/API/Account/GetUsersByRole?request=${roleId}`,
                method: 'GET',
                state: getState().user.request,
                body: {},
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(getUsersByRoleIdSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getUsersByRoleIdFailure(err));
            })
    };
}

export function getUsersByRoleIdAdmin({ roleId = 11 }) {
    return (dispatch, getState) => {
        dispatch(getUsersByRoleIdAdminBegin());
        return request(
            {
                partialUrl: `/API/Account/GetUsersByRole?request=${roleId}`,
                method: 'GET',
                state: getState().user.request,
                body: {},
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(getUsersByRoleIdAdminSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getUsersByRoleIdAdminFailure(err));
            })
    };
}

export function searchProductGroups({ keyword }) {
    return (dispatch, getState) => {
        dispatch(searchProductGroupsBegin());
        return request(
            {
                partialUrl: `/API/Products/SearchProductGroups?key=${keyword}`,
                method: 'GET',
                state: getState().user.request,
                body: {},
            }
        )
            .then(response => response.json())
            .then(data => {
                dispatch(searchProductGroupsSuccess(data.response));
            })
            .catch(err => {
                return dispatch(searchProductGroupsFailure(err));
            })
    };
}

// GET MEDICS

export function getMedicsList(currentPage = null, offset = null, rol = null) {
    return function (dispatch, getState) {
        dispatch(getMedicsListBegin());
        return request({
            partialUrl: `/API/${rol}?currentPage=${currentPage}&offset=${offset}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                if (rol == 'institutions') { // ESTE IF Y LO DE ADENTRO SE DEBEN DE QUITAR CUANDO SE TENGAN LOS ENDPOINTS, SOLO SON DATOS DE PRUEBA
                    data = {
                        ...data,
                        requirements: [
                            {
                                id: 1,
                                description: '1. Presentar cédula de identidad.'
                            },
                            {
                                id: 2,
                                description: '2. Firmar documento de autorización y responsabilidades que autoriza al hospital y a sus médicos a proporcionarle diagnósticos, tratamiento y rehabilitación.'
                            },
                            {
                                id: 3,
                                description: '3. Firmar el reglamento y rehabilitación.'
                            }
                        ]

                    };
                }
                dispatch(getMedicsListSuccess(data));
            })
            .catch(err => {
                return dispatch(getMedicsListFailure(err));
            });
    }
}

//GET INSTITUITONS
export function getInstitutions(currentPage = 0, offset = 100) {
    return function (dispatch, getState) {
        dispatch(getInstitutionsBegin());
        return request({
            partialUrl: `/API/Hospital/GetHospitals?currentPage=${currentPage}&offset=${offset}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getInstitutionsSucess(data.response));
            })
            .catch(err => {
                return dispatch(getInstitutionsFailure(err))
            });
    }
}

export function searchHospital(key) {

    return function (dispatch, getState) {
        dispatch(searchHospitalBegin());
        return request({
            partialUrl: `/API/Hospital/SearchHospitals?key=${key}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(searchHospitalSuccess(data.response));
            })
            .catch(err => {
                return dispatch(searchHospitalFailure(err));
            });
    }
}

export function searchPatients(key) {

    return function (dispatch, getState) {
        dispatch(searchPatientsBegin());
        return request({
            partialUrl: `/API/Patient/SearchPatients?key=${key}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(searchPatientsSuccess(data.response));
            })
            .catch(err => {
                return dispatch(searchPatientsFailure(err));
            });
    }
}

export function getPatientsParams(id) {

    return function (dispatch, getState) {
        dispatch(getPatientsBegin());
        return request({
            partialUrl: `/API/Patient/GetPatient?id=${id}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getPatientsSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getPatientsFailure(err));
            });
    }
}


export function getPatients(currentPage = 0, offset = 10) {

    return function (dispatch, getState) {
        dispatch(getPatientsBegin());
        return request({
            partialUrl: `/API/Patient/GetPatients?currentPage=${currentPage}&offset=${offset}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getPatientsSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getPatientsFailure(err));
            });
    }
}

// GET VENDEDOR

export function getSellers(currentPage = 0, offset = 10) {
    return function (dispatch, getState) {
        dispatch(getSellersBegin());
        return request({
            partialUrl: `/API/Sellers/GetSellers?currentPage=${currentPage}&offset=${offset}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getSellersSuccess(data.sellers));
            })
            .catch(err => {
                return dispatch(getSellersFailure(err));
            });
    }
}


export const getSearchList = (startDate, endDate, SearchBy = null, searchByParam = null,  searchByParam2 = null, currentPage = null, offset = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(getSearchBegin());
            const fetchrequest = await request({
                partialUrl: `/API/Appointment/GetAppointments?startDate=${startDate}&endDate=${endDate}${SearchBy ? `&SearchBy=${SearchBy}` : ' '}${searchByParam ? `&searchByParam=${searchByParam}` : ''}${searchByParam2 ? `&searchByParam=${searchByParam2}` : ''}${currentPage ? `&currentPage=${currentPage}` : ' '}${offset ? `&offset=${offset}` : ' '}`,
                method: 'GET',
                state: getState().user.request,
                body: {}
            })
            const response = await fetchrequest.json();
            const data = await response.response;
            return dispatch(getSearchSuccess(data));

        } catch (err) {
            return dispatch(getSearchFailure(err))

        }
    }
}


export const getSearchATQ = (rol = null ,keyword = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch(searchATQBegin());
            const fetchrequest = await request({
                partialUrl: `/API/Account/GetUsersByRole?request=${rol}&key=${keyword}`,
                method: 'GET',
                state: getState().user.request,
                body: {}
            })
            const response = await fetchrequest.json();
            const data = await response.response;
            return dispatch(searchATQSuccess(data));

        } catch (err) {
            return dispatch(searchATQFailure(err))

        }
    }
}



// GET MEDICS

export function getProductGroups() {
    return function (dispatch, getState) {
        dispatch(getProductGroupsBegin());
        return request({
            partialUrl: `/API/Products/GetProductGroups`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getProductGroupsSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getProductGroupsFailure(err));
            });
    }
}

// 

// getUserSpecificProductGroups

export function getUserSpecificProductGroups(userId) {
    return function (dispatch, getState) {
        dispatch(getUserSpecificProductGroupsBegin());
        return request({
            partialUrl: `/API/Products/GetUserSpecificProductGroups?userId=${userId}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getUserSpecificProductGroupsSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getUserSpecificProductGroupsFailure(err));
            });
    }
}

export function addUserSpecificGroup(addItem) {
    return function (dispatch, getState) {
        dispatch(addUserSpecificGroupBegin());
        return request({
            partialUrl: `/API/Products/AddUserSpecificGroup`,
            method: 'POST',
            state: getState().user.request,
            body: {
                request: [...addItem]
            }
        })
            .then(response => response.json())
            .then(data => {
                dispatch(addUserSpecificGroupSuccess(data.response));
            })
            .catch(err => {
                return dispatch(addUserSpecificGroupFailure(err));
            });
    }
}

// GET MEDICS

export function removeUserSpecificProductGroup(deleteItems) {
    return function (dispatch, getState) {
        dispatch(removeUserSpecificProductGroupBegin());
        return request({
            partialUrl: `/API/Products/RemoveUserSpecificProductGroup`,
            method: 'POST',
            state: getState().user.request,
            body: {
                request: [...deleteItems]
            }
        })
            .then(response => response.json())
            .then(data => {
                dispatch(removeUserSpecificProductGroupSuccess(true));
            })
            .catch(err => {
                return dispatch(removeUserSpecificProductGroupFailure(err));
            });
    }
}


// getUserSpecificProductGroups

export function getSpecificBundles(userId, mainSurgeryType = 0, includeAllSurgeryTypes = false) {
    return function (dispatch, getState) {
        dispatch(getSpecificBundlesBegin());
        return request({
            partialUrl: `/API/Bundle/GetSpecificBundles?userId=${userId}&mainSurgeryType=${mainSurgeryType}&includeAllSurgeryTypes=${includeAllSurgeryTypes}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getSpecificBundlesSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getSpecificBundlesFailure(err));
            });
    }
}

export function addUserSpecificBundle(addItem) {
    return function (dispatch, getState) {
        dispatch(addUserSpecificBundleBegin());
        return request({
            partialUrl: `/API/Bundle/AddUserSpecificBundle`,
            method: 'POST',
            state: getState().user.request,
            body: {
                request: [...addItem]
            }
        })
            .then(response => response.json())
            .then(data => {
                dispatch(addUserSpecificBundleSuccess(data.response));
            })
            .catch(err => {
                return dispatch(addUserSpecificBundleFailure(err));
            });
    }
}

// GET MEDICS

export function deleteSpecificBundle(deleteItem) {
    return function (dispatch, getState) {
        dispatch(deleteSpecificBundleBegin());
        return request({
            partialUrl: `/API/Bundle/DeleteSpecificBundle`,
            method: 'POST',
            state: getState().user.request,
            body: {
                request: [...deleteItem]
            }
        })
            .then(response => response.json())
            .then(data => {
                dispatch(deleteSpecificBundleSuccess(true));
            })
            .catch(err => {
                return dispatch(deleteSpecificBundleFailure(err));
            });
    }
}


// getUserSpecificProductGroups

export function getUserSpecificPricelists(userId) {
    return function (dispatch, getState) {
        dispatch(getUserSpecificPricelistsBegin());
        return request({
            partialUrl: `/API/PriceList/GetUserSpecificPricelists?userId=${userId}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getUserSpecificPricelistsSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getUserSpecificPricelistsFailure(err));
            });
    }
}

export function addUserSpecificPricelist(addItem) {
    return function (dispatch, getState) {
        dispatch(addUserSpecificPricelistBegin());
        return request({
            partialUrl: `/API/PriceList/AddUserSpecificPricelist`,
            method: 'POST',
            state: getState().user.request,
            body: {
                request: [...addItem]
            }
        })
            .then(response => response.json())
            .then(data => {
                dispatch(addUserSpecificPricelistSuccess(data.response));
            })
            .catch(err => {
                return dispatch(addUserSpecificPricelistFailure(err));
            });
    }
}

// GET MEDICS

export function removeUserSpecificPricelist(deleteItem) {
    return function (dispatch, getState) {
        dispatch(removeUserSpecificPricelistBegin());
        return request({
            partialUrl: `/API/PriceList/RemoveUserSpecificPricelist`,
            method: 'POST',
            state: getState().user.request,
            body: { request: deleteItem }
        })
            .then(response => response.json())
            .then(data => {
                dispatch(removeUserSpecificPricelistSuccess(true));
            })
            .catch(err => {
                return dispatch(removeUserSpecificPricelistFailure(err));
            });
    }
}

// SET SELECTED USER ID

export function setUserIdSelected(id = null) {

    return function (dispatch) {
        dispatch(setUserIdBegin());
        dispatch(setUserIdSuccess(id));
    }
}

export function setSugeryIdSelected(id = null) {

    return function (dispatch) {
        dispatch(setSurgeryIdBegin());
        dispatch(setSurgeryIdSuccess(id));
    }
}

export function setChatIdSelected(id = null) {

    return function (dispatch) {
        dispatch(setChatIdBegin());
        dispatch(setChatIdSuccess(id));
    }
}


export function setOptionsIdSelected(id = null) {

    return function (dispatch) {
        dispatch(optionIdBegin());
        dispatch(optionIdSuccess(id));
    }
}



export function searchMedics(key) {

    return function (dispatch, getState) {
        dispatch(searchUserBegin());
        return request({
            partialUrl: `/API/Medic/SearchMedics?key=${key}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(searchUserSuccess(data.response));
            })
            .catch(err => {
                return dispatch(searchUserFailure(err));
            });
    }
}


// GET SELECTED USER ID

export function getUserIdSelected(id = null, dataRol) {
    return function (dispatch, getState) {
        dispatch(getUserIdBegin());
        const user = getState().allUsers.getMedicsList[dataRol].filter(user => user.id == id)
        dispatch(getUserIdSuccess(user));
    }
}

// GET PATIENTS IN USER

export function getPatientsInUser() {

    return function (dispatch, getState) {
        dispatch(getPatientsInUserBegin());
        const userId = getState().allUsers.selectedUserId;
        // AQUI VA LA PETICION DONDE SE TRAE LOS PATIENTS DEL USUARIO CON EL ID userID
        // const patients = [
        //     {
        //         id: 1,
        //         name: 'Jorge blanco',
        //         system: 'Neon',
        //         cx_date: '15/02/2021',
        //         hospital: 'San Javier',
        //     },
        //     {
        //         id: 2,
        //         name: 'Jorge blanco',
        //         system: 'Neon',
        //         cx_date: '15/02/2021',
        //         hospital: 'San Javier',
        //     },
        //     {
        //         id: 3,
        //         system: 'Neon',
        //         cx_date: '15/02/2021',
        //         hospital: 'San Javier',
        //     },
        // ];
        dispatch(getPatientsInUserSuccess());
    }
}

// GET STATES IN PROCESSES

export function getStatesInProcesses() {

    return function (dispatch, getState) {
        dispatch(getStatesInProcessesBegin());
        const userId = getState().allUsers.selectedUserId;
        // AQUI VA LA PETICION DONDE SE TRAE LOS STATES DE LOS PROCESOS DEL USUARIO CON UN ROL ESPECIFICO
        const states = [
            {
                id: 1,
                name: 'Cirugia programada'
            },
            {
                id: 2,
                name: 'Programación atendida'
            },
            {
                id: 3,
                name: 'Material listo para recogerse'
            },
            {
                id: 4,
                name: 'Material en proceso de entrega'
            },
            {
                id: 5,
                name: 'Material entregado'
            },
            {
                id: 6,
                name: 'Cirugia iniciada'
            },
            {
                id: 7,
                name: 'Cirugia finalizada'
            },
            {
                id: 8,
                name: 'Material retornado a almacén'
            },
            {
                id: 9,
                name: 'Material en proceso de revisión'
            },
            {
                id: 10,
                name: 'Cirugia facturada'
            }

        ]
        dispatch(getStatesInProcessesSuccess(states));
    }
}


// GET USER ROLS

export function getUserRols() {

    return function (dispatch, getState) {
        dispatch(getUserRolsBegin());
        // AQUI VA LA PETICION DONDE SE TRAE LOS ROLES

        const rols = [
            {
                id: 1,
                name: 'doctor',
            },
            {
                id: 2,
                name: 'adminAtq',
            },
            {
                id: 3,
                name: 'atq',
            },
            {
                id: 4,
                name: 'seller',
            },
            {
                id: 5,
                name: 'storer',
            },
            {
                id: 6,
                name: 'delivery',
            },
        ];
        dispatch(getUserRolsSuccess(rols));
    }
}

// SET USER CHAT SELECTED

export function setUserChatSelected(id = null) {

    return function (dispatch) {
        dispatch(setUserChatSelectedBegin());
        dispatch(setUserChatSelectedSuccess(id));
    }
}


export function getUserById(userId) {

    return function (dispatch, getState) {
        dispatch(getUserByIdBegin());
        return request({
            partialUrl: `/API/Account/GetUser?UserId=${userId}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
            .then(response => response.json())
            .then(data => {
                dispatch(getUserByIdSuccess(data.response));
            })
            .catch(err => {
                return dispatch(getUserByIdFailure(err));
            });
    }
}

export function getOfficeParcel(currentPage = 0, offset = 50, headers) {
    return request({
        partialUrl: `/API/Delivery/GetDeliverys?currentPage=${currentPage}&offset=${offset}`,
        method: 'GET',
        state: headers,
    })
    .then(response => response.json())
    .then(data => {
        return data;
        //dispatch(getOfficeParcelsSuccess(data.deliverys));
    })
    .catch(err => {
        return err
        //return dispatch(getOfficeParcelsFailure(err));
    });
}

export function updateShippingRemission(body, headers) {
    return request({
        partialUrl: `/API/Remission/updateShippingRemission`,
        method: 'POST',
        state: headers,
        body
    })
    .then(response => response.json())
    .then(data => {
        return data
    })
    .catch(err => {
        return err
    });
}