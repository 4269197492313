import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setSurgeryChoosed } from '../../../actions/atqAdministradorAction';
import { getAppointmentsStoreHouseAtq, getAppointmentsStoreHouseSuccessAtq, getSearchListHouseSuccessAtq } from '../../../actions/storeHouseAction';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import './style_list.scss';

export const Asignar_List_Component = (props) => {

    const distpatch = useDispatch();
    const listApoiment = useSelector(state => state.storeHouse.appointmentsListATQ);
    const listSearch = useSelector(state => state.storeHouse.listSearch);


    const startDate = '1900-01-01T01:01:57'
    const endDate = '2200-05-06T01:01:33'
    const searchBy = 'ATQ_REQUIRED_NOT_ASSIGNED'
    const searchBy1 = 'ATQ_NOT_REQUIRED_OR_ASSIGNED'
    const searchByParam = '0'
    const offset = 10;
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState([]);
    const history = useHistory();
    const render = useRef(0);

    const handleNivagate = (item) => {
        distpatch(setSurgeryChoosed(item));
        props.switch && props.switch === 'ACTIVE' &&
            history.replace(`/ATQAdministrator_Material_History/transfer_CX/asignar`);
        props.switch && props.switch === 'INACTIVE' &&
            history.replace(`/ATQAdministrator_Material_History/transfer_CX/apoyo`);
    };

    useEffect(() => {

        return () => {
            distpatch(getSearchListHouseSuccessAtq(null))  
        }
    }, [])


    
    useEffect(() => {
        if (render.current > 0) {
            setList(null);
            setPage(0);
            distpatch(getAppointmentsStoreHouseSuccessAtq([]));
            distpatch(getSearchListHouseSuccessAtq(null));
            

            if (currentPage === 0) {
                props.switch === 'ACTIVE'
                    ? distpatch(getAppointmentsStoreHouseAtq(startDate, endDate, searchBy, null, currentPage, offset))
                    : distpatch(getAppointmentsStoreHouseAtq(startDate, endDate, searchBy1, null, currentPage, offset))
            }
        } else {
            setList(null);
            render.current = 1;
        }
    }, [props.switch])



    useEffect(() => {
        props.switch === 'ACTIVE'
            ? distpatch(getAppointmentsStoreHouseAtq(startDate, endDate, searchBy, null, currentPage, offset))
            : distpatch(getAppointmentsStoreHouseAtq(startDate, endDate, searchBy1, null, currentPage, offset))
    }, [currentPage])



    //ESTE ES PARA USAR EL LIST 
    useEffect(() => {
        if (!_.isNil(list) && !_.isNil(listApoiment)) {
            setList([...list, ...listApoiment])
        } else if (listApoiment && listApoiment.length > 0) {
            setList(listApoiment)
        }
    }, [listApoiment])


    //scroll
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        /* console.warn(scrollHeight-Math.round(scrollTop) ,'-',clientHeight) */
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)

        }
    }
    //console.warn('listSearc',listSearch)
    //     useEffect(() => {
    //       if(listApoiment){
    // console.log('list',list)
    //       }
    //     }, [list])

    //     console.log('listAp',listApoiment)
    return (
        <div className="preconter_list_asignar" onScroll={handleScroll} >


            { 
            !listSearch 
            
            ?(listApoiment && 
            list !== null  &&
            list.map( item => (
            <div className="content_info_asignar" onClick={() => handleNivagate(item)}>
                <div className="text_info_asignar">
                    <span className="info_date_numberPqx_asignar">
                        {'CX #' + item.id + ' - ' + 'Fecha: ' + formatDateToPresent(item.surgeryDate) + ' - ' + 'Hora: ' + formatHourToPresent(item.surgeryDate)}
                    </span>
                    <span className="info_date_numberPqx_asignar">
                        {item.hospital.name}
                    </span>
                </div>
                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
            </div>
            )))

            :
          
            (listSearch.length > 0 &&
            listSearch.map( item => (

                <div className="content_info_asignar" onClick={() => handleNivagate(item)}>
                <div className="text_info_asignar">
                    <span className="info_date_numberPqx_asignar">
                        {'CX #' + item.id + ' - ' + 'Fecha: ' + formatDateToPresent(item.surgeryDate) + ' - ' + 'Hora: ' + formatHourToPresent(item.surgeryDate)}
                    </span>
                    <span className="info_date_numberPqx_asignar">
                        {item.hospital.name}
                    </span>
                </div>
                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
            </div>

            )))
        }



        </div>
    )
}
