import React from 'react';
import { ATQAdministrator_Material_History_List_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Material_History/ATQAdministrator_Material_History_List_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './styleMath.scss';

export const ATQAdministrator_Material_History_List_Page = () => {
    
    return (
        <div className="container_Material">
        <SubNavBarComponent title={'HISTORIAL DE MATERIAL'} searchActive={true} historyPage={'/ATQAdmin_Inicio'} typeSearch={'Status'}/>
        <ATQAdministrator_Material_History_List_Component />
    </div>
    )
}
