import React, { useState } from 'react';
import { ATQ_List_Encuesta_Com } from '../../../components/ATQ/ATQ_ENCUESTA/ATQ_List_Encuesta_Com';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './styles.scss';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';

export const ATQ_List_Encuesta_Page = () => {

    const [activeSwitch, setActiveSwitch] = useState('ACTIVE');
    const [from,setFrom] = useState('dad');

  const handleChange  = (item) => {
    setFrom('dad')
      if(item === 'active'){
        setActiveSwitch('ACTIVE')
      } else{
        setActiveSwitch('INACTIVE')
      }
  }

    return (
        <>
        <ComingSoonComponent/>
        {/*<div className="precontainer_page_encuesta">

            <SubNavBarComponent title={'ENCUESTA'}
                searchActive={true}
                historyPage={'/Inicio'}
                typeSearch={'SurveyPending'} 
                specificType={'id'} />



            <div className="container_page_encuesta">
                <div className="container_buttons_encuesta">
                    <nav className="toogle_container_buttons_encuesta">
                        <button className={activeSwitch === 'ACTIVE'
                            ? "normal_button_encuesta btn_left_encuesta btn_active_encuesta"
                            : "normal_button_encuesta btn_left_encuesta"} onClick={() => handleChange('active')}>
                            PENDIENTES
                        </button>
                        <button className={activeSwitch === 'INACTIVE'
                            ? "normal_button_encuesta btn_rigth_encuesta btn_active_encuesta"
                            : "normal_button_encuesta btn_rigth_encuesta"} onClick={() => handleChange('inactive')}>
                            FINALIZADOS
                        </button>
                    </nav>
                </div>
                <div className="section_list_encuesta">
                    <ATQ_List_Encuesta_Com
                        activeSwitch={activeSwitch}
                        setActiveSwitch={setActiveSwitch}
                        setFrom = {setFrom}
                        from = {from}
                    />
                </div>

            </div>
        </div>*/}
        </>
    )
}

