import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import  {ATQ_Modal}  from './Modal/ATQ_Modal';
import CustomModal from '../../shared/Modal';

export const ATQ_modal_Producst = (props) => {

    const modalBody = () => {
        return (
            <ATQ_Modal     
            toggle={props.toggle}
            page={props.page}
                                />
        );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle} 
                title={props.name}
                body={modalBody()}
        />
    );
}

ATQ_modal_Producst.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};
