import React from 'react';
import { ATQ_Disponibilidad_MasCX_List_Component } from '../../../components/ATQAdministrator/ATQ_CX_ASIGNAR_ATQ/ATQ_Disponibilidad_MasCX_List_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './style_MASCX.scss';

export const ATQ_Disponibilidad_MasCX_Page = () => {
    return (
        <div>
            
            <div className="container">
                <SubNavBarComponent title={'ASIGNAR ATQ'} searchActive={true} />
                <div className='ListElementsCXAbstractComponent-section-left-title-container ListElementsCXAbstractComponent-section-left-title-container-colorTitlePQX'>
                        <h4 className='ListElementsCXAbstractComponent-section-left-title' > CX SIN ASIGNAR ATQ </h4>
                    </div>
                <ATQ_Disponibilidad_MasCX_List_Component
                    page = {'asignar'}
                />
            </div>
        </div>
    )
}
