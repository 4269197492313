import React, {useState} from 'react';
import { IoCaretForwardCircleSharp } from "react-icons/io5";
import PropTypes from 'prop-types';
import { propertiesMainSurgeryType } from './storeHomeAbailableSystemsData';
import { StoreHouseStockModal } from '../StoreHouseStockModal/StoreHouseStockModal';
import { searchLocationsStoreHouse } from '../../../actions/storeHouseAction';
import { useDispatch } from 'react-redux';
import './sass/styles.sass';
import { ATQ_description_component } from '../../ATQ/ATQ_PRODUCTS/ATQ_description_component';

export const StoreHomeAbailableSystemsItems = ({mainSurgeryType, mainSurgeryTypeListItems, mainSurgeryTypeTitle, page='almacen'}) => {

    const [openModal, setOpenModal] = useState(null);
    const dispatch = useDispatch();
    

    const handleOpenListAbailable = (systemToGetLocation) => {
        dispatch(searchLocationsStoreHouse(systemToGetLocation.productCode));
        setOpenModal('OPEN');
    }

    const closeModal = () => {
        setOpenModal(null);
    }


    return (
        <div className="StoreHomeAbailableSystemsItems-c-section StoreHomeAbailableSystemsItems-c-cervicales">
            <header className="StoreHomeAbailableSystemsItems-c-section-c-title StoreHomeAbailableSystemsItems-c-cervicales-c-title">
                <img className="StoreHomeAbailableSystemsItems-c-section-img" src={propertiesMainSurgeryType[mainSurgeryType].img} alt="" />
                <h2 className="StoreHomeAbailableSystemsItems-c-section-title">{mainSurgeryTypeTitle}</h2>
            </header>

            <section className={'c-items StoreHomeAbailableSystemsItems-c-section-c-items StoreHomeAbailableSystemsItems-c-cervicales-c-items'}>
            {   
                mainSurgeryTypeListItems.map((item) => {
                    return (
                        <div className="StoreHomeAbailableSystemsItems-c-item" key={item.id}>
                            <img className="StoreHomeAbailableSystemsItems-c-item-img" src={item.productImage} alt="" />
                            {
                                mainSurgeryType &&
                                    page === 'almacen' &&
                                    <button className="StoreHomeAbailableSystemsItems-c-item-button StoreHomeAbailableSystemsItems-c-item-button-Childs " onClick={ () => handleOpenListAbailable(item) } >
                                        <div className="StoreHomeAbailableSystemsItems-item-button-name-container">
                                            <span className="StoreHomeAbailableSystemsItems-item-button-name"> {item.productDescription} </span>
                                            <span className="StoreHomeAbailableSystemsItems-item-button-secondname"> {`Disponible ${item.availableLocations}`} </span>    
                                        </div>
                                        <span className="StoreHomeAbailableSystemsItems-item-button-ico">
                                            {" "}
                                            <IoCaretForwardCircleSharp />{" "}
                                        </span>
                                    </button>
                            }
                            {
                                    page === 'ATQ' &&
                                    <ATQ_description_component
                                        item = {item}
                                    />
                            }

                        </div>
                    );
            })}
            </section>

            {
                openModal === 'OPEN' &&
                <StoreHouseStockModal 
                    isOpen
                    toggle={closeModal}
                    typeModal={'STORE_SYSTEMS'}
                />
            }
        </div>
    )
}

StoreHomeAbailableSystemsItems.propTypes = {
    mainSurgeryType: PropTypes.any,
    mainSurgeryTypeListItems: PropTypes.any,
    mainSurgeryTypeTitle: PropTypes.any,
}


