import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import SearchInput from '../../../../../../components/shared/Atoms/SearchInput';
import Button from '../../../../../../components/shared/Atoms/Button';
import CustomModal from '../../../../../../components/shared/Modal';
import './UserTabProcess.scss';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, getProcessesInUser, getSearchSuccess, getStatesInProcesses, getStatesInProcessesSuccess } from '../../../../../../actions/allUsersAction';

// ASSETS
import blueArrowIcon from '../../../../../../assets/img/126PX/blue_arrow_icon.png';
import blueDownArrowIcon from '../../../../../../assets/img/126PX/blue_arrow_down.png';
import { formatDateToPresent } from '../../../../../../hooks/useFormatDateInitialAndFinalDay';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../../../../hooks/useFunctionsStoreHouse';



const UserTabProcess = () => {

    const dispatch = useDispatch();
    const [ordenDetailSelected, setOrdenDetailSelected] = useState(null);
    const [processSelected, setProcessSelected] = useState(null);
    const [states, setStates] = useState(null)
    const data = useSelector(state => state.allUsers);
    const [selectedOrderItem, setSelectedOrderItem] = useState(null);
    const selectedUserId = useSelector(state => state.allUsers.selectedUserId);
    const process = useSelector(state => state.allUsers.appointmentsList)
    const searchProcess = useSelector(state => state.allUsers.searchList)
    const [currentPage, setPage] = useState(0);
    const offset = 10;
    const [listProcess, setListProcess] = useState([])
    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';

    //Modal 
    const [surtido, setSurtido] = useState(null);
    const [noSurtido, setNoSurtido] = useState(null);
    const [listado, setListado] = useState(null)
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const [newProductsReq, setNewProductsReq] = useState(null);

    useEffect(() => {
        if (process && process.length > 0) {
            setListProcess([...listProcess, ...process])
        }
    }, [process])

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    //codigo perteneciente al modal(ubicacion y cantidad)

    useEffect(() => {
        if (selectedOrderItem && selectedOrderItem.id) {
            dispatch(getProductsToAppointmentStoreHose(selectedOrderItem.id))
            dispatch(getAppointmentLocationsStoreHose(selectedOrderItem.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [selectedOrderItem])


    useEffect(() => {
        if (AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
            AppointmentWithLocations.productRequirements.map(item => {
                if ((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)) {
                    arraySurtidos.push(item);
                } else {
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
            setListado([...arraySurtidos, ...arrayNoSurtidos])
        }
    }, [AppointmentWithLocations])




    /*    useEffect(() => {
           console.warn('surtido',surtido)
           console.warn('noSurtido',noSurtido)
       }, [surtido,noSurtido]) */


    useEffect(() => {
        if (AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0) {
            if (selectedOrderItem.productRequirements && selectedOrderItem.productRequirements.length > 0) {
                setNoSurtido([selectedOrderItem.productRequirements && selectedOrderItem.productRequirements]);
            }
        }

    }, [AppointmentLocations, AppointmentProducts])

    useEffect(() => {
        if (AppointmentLocations && selectedOrderItem && selectedOrderItem) {
            handleCompleteBeginLocations(AppointmentLocations, setNewProductsReq, selectedOrderItem)
        }
    }, [AppointmentLocations])


    useEffect(() => {
        if ((!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)) {
            {
                if (!AppointmentWithLocations || AppointmentWithLocations === null) {
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts, newProductsReq, selectedOrderItem)));
                }
            }
        }

    }, [newProductsReq])


    //-------------------------------------------------------------------------------

    // useEffect(() => {
    //     return () => {
    //         setListProcess(null)
    //     }
    // }, [optionSelected])



    useEffect(() => {
        dispatch(getStatesInProcesses());
        if (data.userRolSelected === 'doctor' && currentPage != 0) {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId, currentPage, offset));
        } else if (data.userRolSelected === 'atq' && currentPage != 0) {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 2, selectedUserId, currentPage, offset));
        } else if (data.userRolSelected === 'adminAtq' && currentPage != 0) {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 0, selectedUserId, currentPage, offset));
        } else if (data.userRolSelected === "seller" && currentPage != 0) {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 9, selectedUserId, currentPage, offset));

        }

    }, [currentPage])

    useEffect(() => {
        if (data.userRolSelected === 'doctor') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 1, selectedUserId, 0, offset));
        } else if (data.userRolSelected === 'atq') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 2, selectedUserId, 0, offset));
        } else if (data.userRolSelected === 'seller') {
            dispatch(getAppointmentsStoreHouse(startDate, endDate, 9, selectedUserId, 0, offset));
        }
        setPage(0)
        setListProcess([])
        setSelectedOrderItem(null)

        return () => {
            dispatch(getAppointmentsStoreHouseSuccess(null))
            dispatch(getSearchSuccess(null))
        }
    }, [selectedUserId])



    useEffect(() => {
        if (data.processesStates) {
            setStates(data.processesStates)
        }
    }, [data])

    useEffect(() => {
        return () => {
            dispatch(getStatesInProcessesSuccess(null))
        }
    }, [])


    const toggleOpenProcessItem = (item) => {
        setSelectedOrderItem(selectedOrderItem && selectedOrderItem.id == item.id ? true : item);
    };

    const renderOrderItem = (item, index) => {
        return (
            <li key={item.id} className={'doctor-tab-process-li'}>
                <button
                    className={'doctor-tab-process-li-header'}
                    onClick={() => toggleOpenProcessItem(item)}
                >
                    {'Cirugía #' + '' + item.id + " - Hospital " + item.hospital.name + " - " + formatDateToPresent(item.surgeryDate)}
                    {
                        selectedOrderItem && selectedOrderItem.id == item.id &&
                        <img src={blueDownArrowIcon} alt={'FLECHA PARA CERRAR'} />
                    }
                    {
                        (_.isNull(selectedOrderItem) || (!_.isNull(selectedOrderItem) && selectedOrderItem.id != item.id)) &&
                        <img src={blueArrowIcon} className={"blueArrowListIcon"} alt={'FLECHA PARA ABRIR'} />
                    }
                </button>
                {
                    selectedOrderItem && selectedOrderItem.id == item.id &&
                    renderOrderDetail(item, index)
                }
            </li>
        );
    }

    const showOrderOnClick = (orden = null) => {
        setProcessSelected(ordenDetailSelected ? null : orden);
    };

    const modalBody = () => {
        return (
            <div className={"modal-order"}>
                <div className={"table-order-header"}>
                    <div className={"header-element"}>
                        <p>Item</p>
                    </div>
                    <div className={"header-element"}>
                        <p>Material utilizado</p>
                    </div>
                    <div className={"header-element"}>
                        <p>Ubicación</p>
                    </div>
                    <div className={"header-element"}>
                        <p>Cantidad</p>
                    </div>
                </div>
                {

                    surtido.length == 0 &&
                        noSurtido.length == 0 ?

                        processSelected.productRequirements.length > 0 ?
                            processSelected.productRequirements.map((item, i) => {
                                return (
                                    <div className={"table-order-body"}>
                                        <div className={'body-element'}>
                                            <p>{i + 1}</p>
                                        </div>
                                        <div className={'body-element material-element'}>
                                            {
                                                <p>{item.product.productDescription}</p>
                                            }
                                        </div>
                                        <div className={'body-element'}>
                                            <p>{'- - -'}</p>
                                        </div>
                                        <div className={'body-element'}>
                                            <p>{item.productQuantity}</p>
                                        </div>
                                    </div>
                                );


                            })

                            :
                            <p className='text-dontMaterial'>Sin Material Requerido</p>

                        :
                        listado.length > 0 &&
                        listado.map((item,i) => {
                            return (
                                <div className={"table-order-body"}>
                                    <div className={'body-element'}>
                                        <p>{i + 1}</p>
                                    </div>
                                    <div className={'body-element material-element'}>
                                        {
                                            <p>{item.product  ? item.product.productDescription :(item.location && item.location.description)}</p>
                                        }
                                    </div>
                                    <div className={'body-element'}>
                                        <p>{item.product ? item.Ubicacion ? item.Ubicacion: '- - -' : (item.location && item.location.ubicacion)}</p>
                                    </div>
                                    <div className={'body-element'}>
                                        <p>{item.productQuantity ?item.productQuantity :item.locationLevels ?item.locationLevels :'1'}</p>
                                    </div>
                                </div>
                            );


                        })

                }

            </div>
        );
    };

    const renderOrderDetail = (item, index) => {
        return (
            <div className={'doctor-tab-process-li-body'}>
                <ol className={'doctor-tab-process-ol'}>
                    {
                        states &&
                        states.map((statesItem, index) => {
                            return (
                                <li key={''} className={['textNonActualState', statesItem.id == item.surgeryStatusId ? 'textActualState' : ''].join(' ')}>
                                    <div className={"processNumber"}><span>{index + 1}</span></div>{statesItem.name}
                                </li>

                            );
                        })
                    }
                </ol>
                <div className={'doctor-tab-process-li-button'}>
                    <Button color={"blue_dark"} title={"DESCRIPCIÓN DEL PEDIDO"} onClick={() => showOrderOnClick(item)} />
                </div>
            </div>
        );
    };




    return (
        <div className={"tab_content_base"}>
            <div className={"search_input_content"}>
                <SearchInput />
            </div>
            <ul className={'doctor-tab-process-ul'} onScroll={handleScroll}>
                {
                    searchProcess &&
                        searchProcess.length > 0 ?

                        searchProcess.map(renderOrderItem)
                        :

                        listProcess &&
                        listProcess.length > 0 &&
                        listProcess.map(renderOrderItem)
                }
            </ul>
            {
                processSelected &&
                <CustomModal // Esto se puede ser otro componente
                    title={'Cirugía #' + '' + processSelected.id + " - " + formatDateToPresent(processSelected.surgeryDate)}
                    isOpen={processSelected}
                    toggle={showOrderOnClick}
                    body={modalBody()}
                />
            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default withRouter(connect(mapStateToProps)(UserTabProcess));
