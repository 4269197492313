
import { ChevronLeft } from "@material-ui/icons";
import { setAppointmentsStoreHouse } from "../actions/storeHouseAction";
import shortid from 'shortid';


//ESTA FUNCION ES PARA CUANDO HAY INFORMACION EN LA BASE DE DATOS DESDE EL INICIO CUANDO ABRES LA  CXZ
export const handleCompleteBeginProducts = (AppointmentProducts, newProductsReq, appointmentItem,OrderPreView) => {

    // ESTO QUE SIGUE ES POR SI HAY PRODUCTOS INDIVIDUALES

    let newArray = JSON.parse(JSON.stringify(newProductsReq))

    if ((OrderPreView) || (!OrderPreView && !appointmentItem.warehouseCompleted)){
        for (let i = 0; i < appointmentItem.productRequirements.length; i++) {
            if (appointmentItem && appointmentItem.productRequirements[i] &&
                appointmentItem.productRequirements[i].notStockedNote &&
                appointmentItem.productRequirements[i].notStockedNote.length > 0) {
                newArray.push(appointmentItem.productRequirements[i])
            }
        }
        console.log("entrooooooooooooooooooo")
    }

    console.log("newArray", newArray)

    AppointmentProducts &&
        AppointmentProducts.length > 0 &&
        AppointmentProducts.map(item => {
            newArray.push({
                ...item,
                producto: true,
                isSystem: false,
                locastionInfo: item.product
            })
        })
    let completeApoimentArray = {
        ...appointmentItem,
        productRequirements: newArray,
    }
    return completeApoimentArray

    //sirve para lo mismo que lo comentado arriba solo que el otro retonra un onjetc y este no
    // let newArray = false;

    // if ( AppointmentProducts && AppointmentProducts.length > 0) {
    //     newArray = AppointmentProducts.map(item => {
    //         return ({
    //             ...item,
    //             producto: true,
    //             isSystem:false,
    //             locastionInfo:item.product
    //         })
    //     });
    // }

    // let  completeApoimentArray = {
    //     ...appointmentItem,
    //     productRequirements: !newArray ? newProductsReq : newArray ,
    // }
    // return completeApoimentArray
}



//ESTA FUNCION ES PARA CUANDO YA TENGO INFORMACION EN LA BASE DE DATOS DESDE EL INICIO
export const handleCompleteBeginLocations = (AppointmentLocations, setNewProductsReq, appointmentItem) => {
    let newArray = []
    let cont = 0;
    appointmentItem &&
        appointmentItem.productRequirements &&
        appointmentItem.productRequirements.map(item => {
            if (item.isSystem === true) {
                if (item.appointmentProductOrRequirementId) {
                    cont = cont + 1;
                    if ((!_.isNil(AppointmentLocations))) {
                        let encontro = AppointmentLocations.find(item2 => item.appointmentProductOrRequirementId === item2.location.id)
                        if (encontro) {
                            if (encontro.locationBoxIDs.length > 1) {
                                console.warn("item", item)
                                console.warn("encontro", encontro)
                                newArray.push({
                                    ...item,
                                    id: item.id,
                                    Ubicacion: encontro.location.ubicacion,
                                    locastionItem: encontro.location.ubicacion,
                                    locationBoxIDs: encontro.locationBoxIDs,
                                    boxQuantity: encontro.boxQuantity,
                                    locationLevels: encontro.locationLevels,
                                    productQuantity: encontro.locationLevels,
                                    locastionInfo: encontro.location,
                                    locationBoxes: { ...handleCreateLocationBox(encontro.locationBoxIDs) },
                                    allInformation: { ...encontro },
                                })
                            } else {
                                console.warn("item 1", item)
                                console.warn("encontro 1", encontro)
                                newArray.push({
                                    ...item,
                                    id: item.id,
                                    Ubicacion: encontro.location.ubicacion,
                                    locastionItem: encontro.location.ubicacion,
                                    boxQuantity: encontro.boxQuantity,
                                    locationBoxIDs: encontro.locationBoxIDs,
                                    locationLevels: encontro.locationLevels,
                                    productQuantity: encontro.locationLevels,
                                    locastionInfo: encontro.location,
                                    allInformation: { ...encontro },

                                })
                            }
                        } else {
                            console.warn("first 1")
                            newArray.push({
                                ...item
                            })
                        }
                    }
                } else {
                    console.warn("first 2")
                    newArray.push({
                        ...item
                    })
                }
            } else {
                console.warn("first 3",)
                newArray.push({
                    ...item
                })
            }

        })
    //ESTO QUE SIGUE ES POR LAS LOCATIONS QUE SE AGREGARON SOLAS QUE NO PERTENECEN A UN PRODUCT REQUERIMENT
    AppointmentLocations &&
        AppointmentLocations.length > 0 &&
        AppointmentLocations.map((item, i) => {
            if (i >= cont) {
                if (item.locationBoxIDs.length > 1) {
                    console.warn("aquiiiiii")
                    newArray.push({
                        ...item,
                        producto: true,
                        locationBoxes: { ...handleCreateLocationBox(item.locationBoxIDs) },
                        locastionInfo: item.location,
                    })
                } else {
                    console.log("aquiiiiii 222222")
                    newArray.push({
                        ...item,
                        producto: true,
                        locastionInfo: item.location,
                    })
                }
            }
        })
    setNewProductsReq(newArray);
}


//ESTA FUNCION SIRVE PARA GENERAR EL ARREGLO DE LOCATIONBOXES DENTRO DEL PRODUCT REQUERIMENT
const handleCreateLocationBox = (locationBox) => {
    let NewArray = [];
    locationBox.map((item, i) => {
        i

        if (i > 0) {
            NewArray.push({
                id: shortid.generate(),
                description: {
                    ubicacion: item
                },
            })
        }
    })
    return NewArray;
}