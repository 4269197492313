import React from 'react';
import { useParams } from 'react-router';
import { Atq_disponible_List_Component } from '../../../components/ATQAdministrator/ATQ_CX_ASIGNAR_ATQ/Atq_disponible_List_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './style.scss';

export const ATQAdmin_ATQ_Disponible_Page = () => {
    const { page } = useParams();
    return (
        <div>
            <div className="container">
            {
                page && page === 'asignar' &&
                <SubNavBarComponent title={'ASIGNAR gallo'} searchActive={true} historyPage={'/ATQAdministrator_Material_History/transfer_CX/asignar'}/>
            }  
            {
                (page && page === 'apoyo' || page === 'cambiar') &&
                <SubNavBarComponent title={'ASIGNAR perrico'} searchActive={true} historyPage={'/ATQAdministrator_Material_History/transfer_CX/apoyo'}/>
            }
                <div className='ListElementsCXAbstractComponent-section-left-title-container ListElementsCXAbstractComponent-section-left-title-container-colorTitlePQX'>
                        <h4 className='ListElementsCXAbstractComponent-section-left-title' > ATQ’S - DISPONIBLES </h4>
                    </div>
                <Atq_disponible_List_Component
                    page = {page}
                />
            </div>
        </div>
    )
}
