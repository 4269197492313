import { forEach } from 'lodash';
import React, { useEffect, useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { addHolidays, addHolidaysSuccess, getHolidays, getHolidaysSuccess, getTeamGroupSuccess, removeHolidays, removeHolidaysSuccess } from '../../../actions/atqAdministradorAction';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { CalendarBase } from '../../../components/shared/Atoms/CalendarBase';
import { formatDate, formatDateToInsert, getMonth, useFormatDateInitialAndFinalDay } from '../../../hooks/useFormatDateInitialAndFinalDay';
import "./sass/styles.scss";

export const ATQ_GuardDays_View = () => {

    const [selectedDate, selectedDateOnChange] =  useState(new Date());
    const [newFinalDayMonth, setNewFinalDayMonth] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const data = useRef(0);
    const numberRef = useRef(0);
    const month = useRef(getMonth(selectedDate));
    const listGuardDays = useSelector(state => state.atqAdministrador.holidays);
    const newHoliday = useSelector(state => state.atqAdministrador.newHoliday);
    const deletedHoliday = useSelector(state => state.atqAdministrador.deletedHoliday);
    const handleNavigate = (typeNavigate) => {

        dispatch(getHolidaysSuccess(null));
        dispatch(getTeamGroupSuccess(null)); 
        typeNavigate === "GUARD_TEAM" &&
            history.replace("/ATQ_GuardRole_View/ATQ_GuardRole_CreateTeam_View");
        
        typeNavigate === "HOME_ATQ" &&
            history.replace("/ATQ_GuardRole_View");

    }
    
    useEffect(() => {
        let ban= false;
       /*  console.warn('---------',newFinalDayMonth); */
        let {startDay,finalDay} = useFormatDateInitialAndFinalDay(selectedDate,newFinalDayMonth);
        
        if(newFinalDayMonth && !listGuardDays){
            dispatch(getHolidays(startDay,finalDay));
        }


        if(data.current === 0){
            data.current = 1
        }
        data.current++;
        const currentDay = formatDate(selectedDate);//FECHA QUE SE SELECCIONNO
        let montCompare = getMonth(selectedDate);
        //para recetear el data para que al cambiar de mes no ejecute la primera seleccion
        if(month.current != montCompare){
            data.current = 3;
            dispatch(getHolidays(startDay,finalDay));
            month.current = getMonth(selectedDate);
        }
        //------------------------------------------------------------

        //ESTA DECISION ES POR QUE AL INICIAR EL CALENDARIO ENTRA 3 VECES POR PRIMERA VEZ
        if(data.current > 3){
            listGuardDays.forEach((element,index) => {     
                if (formatDate(element.holidayDate) === currentDay){
                    dispatch(removeHolidays(element.id));
                    ban = true;
                }
            });
            if(ban === false){
                dispatch(addHolidays(formatDateToInsert(selectedDate)));
            }
        }
        month.current = getMonth(selectedDate);
        
    }, [selectedDate])


    useEffect(() => {
        let {startDay,finalDay} = useFormatDateInitialAndFinalDay(selectedDate,newFinalDayMonth);
        if(numberRef.current === 0 && newHoliday){
            numberRef.current = 1;
        }
        if(numberRef.current > 0 && newHoliday && (startDay && finalDay)){
            dispatch(addHolidaysSuccess(null));
            dispatch(getHolidays(startDay,finalDay));
        }
    }, [newHoliday])

    useEffect(() => {
        let {startDay,finalDay} = useFormatDateInitialAndFinalDay(selectedDate,newFinalDayMonth);
        if(numberRef.current === 0 && deletedHoliday){
            numberRef.current = 1;
        }
        if(numberRef.current > 0 && deletedHoliday && (startDay && finalDay)){
            dispatch(removeHolidaysSuccess(null));
            dispatch(getHolidays(startDay,finalDay));
        }
    }, [deletedHoliday])

    return (
        <div className="ATQ_GuardDays_View_container">
            <SubNavBarComponent searchActive={false} historyPage={"/ATQ_GuardRole_View"}  title={"ROL DE GUARDÍAS"}/>
            <div className="ATQ_GuardDays_View_Items_container">

                <div className="ATQ_GuardDays_View_Calendar_container">
                    <CalendarBase
                        selectedDate={selectedDate}
                        selectedDateOnChange={selectedDateOnChange}
                        setNewFinalDayMonth={setNewFinalDayMonth}
                        typeCalendar={"ATQ"}
                        page={'roldeGuardia'}
                    />
                </div>
                <div className="ATQ_GuardDays_View_Buttons_container">
                    <button className="ATQ_GuardDays_View_Button" onClick={() => handleNavigate("GUARD_TEAM") }>EQUIPOS DE GUARDÍA</button>
                    <button className="ATQ_GuardDays_View_Button" onClick={() => handleNavigate("HOME_ATQ") }>ACEPTAR</button>
                </div>
            </div>
        </div>
    )
}
