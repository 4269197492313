import React, { useState } from 'react'
import { Survery_List_Component } from '../../../components/ATQAdministrator/ATQAdministrator_Survey/Survery_List_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import { StoreHouseStockLeftButtons } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockLeftButtons';
import './styleMenu.scss';

export const ATQAdministrator_Finally_Survery = () => {
    const [activeSwitch, setActiveSwitch] = useState('ACTIVE');
    const [from,setFrom] = useState('dad');

  const handleChange  = (item) => {
    setFrom('dad')
      if(item === 'active'){
        setActiveSwitch('ACTIVE')
      } else{
        setActiveSwitch('INACTIVE')
      }
  }

    return (
        <div className="container_menu_survery">
            <SubNavBarComponent title={'ENCUESTA'} 
            searchActive={true} 
            historyPage={'/ATQAdmin_Inicio'}
            typeSearch={'SurveyPending'}
            specificType={'id'} />

            <section className="menu_survery_container">
                <div className="menu_survery_container_toggle">
                    <nav className="menu_survery_container_buttons">
                        <button className={activeSwitch === 'ACTIVE'
                            ? "normal_button btn_left btn_active"
                            : "normal_button btn_left"} onClick={() => handleChange('active')}>
                            PENDIENTES
                        </button>
                        <button className={activeSwitch === 'INACTIVE'
                            ? "normal_button btn_rigth btn_active"
                            : "normal_button btn_rigth"} onClick={() => handleChange('inactive')}>
                            FINALIZADOS
                        </button>
                    </nav>
                </div>

                <div className="menu_survery_container_elements">

                    <Survery_List_Component
                        switch1={activeSwitch}
                        setActiveSwitch={setActiveSwitch}
                        setFrom = {setFrom}
                        from = {from}
                    />
                </div>
            </section>

        </div>
    )
}
