import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import arrowBlackUp from "../../../../assets/img/126PX/arrow-black-up.png";
import arrowBlackDown from "../../../../assets/img/126PX/arrow-black-down.png";
import { overrideGuardGroupOrder } from '../../../../actions/guardGroupHolidayAction';

export const ModalOrderBody = ({toggle,members,idGroup,idDay}) => {
    const dispatch = useDispatch();   
    const [list,setList ]=  useState(members);
  
    const countTeamMember = useRef(0);
    const [finishRender,setFinishRender] = useState(null);
    
    
    useEffect(() => {
        orderMembers(list)
    }, [list])

   /*  useEffect(() => {
        if(ban.current === 0 && (page === 'modify' || page === 'modifyOnDay')){
            console.warn('entro entro')
            dispatch(getGroupGuardById(idGroup));
            changeBan();
        }
    }, []) */
 

    let byOrder = null;
    const orderMembers = (members) => {
        byOrder = members.slice(0);
        byOrder.sort(function(a,b) {
            return a.orderInGroup - b.orderInGroup;
        });
        
    }
    

        //REORDENAMIENTO DE LOS ATQ
        const reorder = (listValidate,list,reorderAtq) =>{
            
                    //PARA VALIDAR QUE SI NO EXISTE NINGUNA DE LAS ANTERIORRES SOLO EL ORDEN LO HAGA
                   if(listValidate.length === 0 && reorderAtq.length > 0 ){
                        dispatch(principalorderMembers({
                                Id: idGroup,
                                Members:
                                list.map( (member,index) => ({
                                    OrderInGroup: index+1,
                                    User:
                                    {
                                        UserID: member.userID,
                                    },
                            }))
                        })); 
                            
                    }
         }
        

        //FINALIZAR MODIFICACION: ELIMINAR, AGREGAR, REORDENAR
        

        const handleCreateTeam  = () => {
           dispatch(overrideGuardGroupOrder({
            GuardGroupId : members[0].guardGroupId,
            Id : idDay,
            GuardGroupMember:
            list.map( (member,index) => ({
                OrderInGroup: index+1,
                User:
                {
                    UserID: member.user.userID,
                },
                }))
           }))
           toggle();

        }


    const handleSubir = (item,index) => {
            if (index != 0) {
                const arrayaux = {...list}
                for(let x=0; x<list.length; x++){
                    if(item.user.userID === list[x].user.userID){
                        const aux = list[x-1]
                        arrayaux[x-1] = item
                        arrayaux[x] = aux
                    }
                    setList(Object.values(arrayaux))
                }
            }

    }
    const handleBajar = (item,index) => {
        if (index != list.length-1) {
            const arrayaux = {...list}
            for(let x=0; x<list.length; x++){
                if(item.user.userID === list[x].user.userID){
                    const aux = list[x+1]
                    arrayaux[x+1] = item
                    arrayaux[x] = aux
                }
                setList(Object.values(arrayaux))
            }
        }
    }
        const handleAtn = (e) => {
            e.preventDefault();
        }

        return (
            <div className="container-ModalOrderTeamATQ-all">
                <form className="container-ModalOrderTeamATQ-list-items" onSubmit={handleAtn}>
                    <div className="container-ModalOrderTeamATQ-items-scroll">

                        <div className='container-ModalOrderTeamATQ'>
                            {
                                list &&
                                list.map((item, index) => (
                                    <div key={item.user.userID} className="ModalOrderTeamATQ-c-item">
                                        <div className={
                                            (index + 1) % 2 === 0 ? "ModalOrderTeamATQ-c-item-numbers-position ModalOrderTeamATQ-c-item-numbers-position-first"
                                            : "ModalOrderTeamATQ-c-item-numbers-position ModalOrderTeamATQ-c-item-numbers-position-second"
                        
                                            }>
                                            <h3 className="ModalOrderTeamATQ-c-item-numbers-position-number">{index + 1}</h3>
                                        </div>
                                        <span className="ModalOrderTeamATQ-item-name">{item.user.firstName + " " + item.user.secondName + " " + item.user.firstLastName + " " + item.user.secondLastName }</span>
                                        <img src={arrowBlackUp} alt="" className="ModalOrderTeamATQ-item-arrow" onClick={() => handleSubir(item,index)}/>
                                        <img src={arrowBlackDown} alt="" className="ModalOrderTeamATQ-item-arrow" onClick={() =>handleBajar(item,index)}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <button className="ModalOrderTeamATQ-button" onClick={handleCreateTeam}>
                        ACEPTAR
                    </button>
                </form>
            </div>
        );
}
