import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import icoAgenda from '../../assets/img/512PX/Icono_Agenda_512.png';
import medicos from '../../assets/img/512PX/Icoreq_med.png';
import hospital from '../../assets/img/512PX/IcoReq_Hos.png';

export const Messenger_Menu_Page = () => {
    

    return (
        <div className='container-mantenimiento'>
            <div className='precontainer-mantenimiento'>
                <div className='section-left'>
                <NavLink className='agenda'
                to={'/agenda/agenda'}>
                            <img src={icoAgenda} alt="" className='img-agenda'/>
                            <h3 className='agenda-text'> Agenda </h3>
                </NavLink>
                </div>
                <div className='section-rigth'>
                <NavLink className='reque_medicos'
                     to={'Messenger_Menu/medics_requ'}>
                            <img src={medicos} alt="" className='img-medicos'/>
                            <h3 className='text-med'> Requerimientos de Medicos </h3>
                    </NavLink>
                    <NavLink className='reque_hospitales'
                    to={'Messenger_Menu/hospitals_requ'}>
                    <img src={hospital} alt="" className='img-hospitales'/>
                            <h3 className='text-hosp'> Requerimientos de Hospitales </h3>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
