import React from 'react';
import './style.scss';

export const TableUbication_Component = ({listTitles=null ,type=null ,listItemsBody=null}) => {
  
    return(
       <>
           { type === null
               ?<div className="precontainer_table">
                <div className="container_table">
                    <table className="table-container">
                        <thead className="table-thead">
                            <tr className="table-row">
                                <th className="table-title-1">Ubicación</th>
                                <th className="table-title-2">Cantidad</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                        {
                        listItemsBody&&
                        listItemsBody.length > 0
                        ?listItemsBody.map((item) => (
                        <tr className="table-body-row">
                            <td className="table-data-1">{item.product  ?item.product.productDescription :(item.location && item.location.description)}</td>
                            <td className="table-data-2">{item.productQuantity ?item.productQuantity :item.locationLevels ?item.locationLevels :'1'}</td>
                        </tr>
                        ))
                        :<tr className="table-body-row">
                            <td className="table-data-1">-</td>
                            <td className="table-data-2">-</td>
                        </tr>
                    }
                        </tbody>
                    </table>
                </div>
            </div>
        
        : <div className="precontainer_table">
            <div className="container_table">
                <table className="table-container">
                    <thead className="table-thead">
                
                            <tr className="table-row">
                                <th className="table-title-1">{ type === 'surtido' ?'Material Surtido' :'Material Sin Surtir'}</th>
                                <th className="table-title-2">Cantidad</th>
                            </tr>
            
                    </thead>
                    <tbody className="table-body">
                    {
                        listItemsBody&&
                        listItemsBody.length > 0
                        ?listItemsBody.map((item) => (
                        <tr className="table-body-row">
                            <td className="table-data-1">{item.product  ?item.product.productDescription :(item.location && item.location.description)}</td>
                            <td className="table-data-2">{item.productQuantity ?item.productQuantity :item.locationLevels ?item.locationLevels :'1'}</td>
                        </tr>
                        ))
                        :<tr className="table-body-row">
                            <td className="table-data-1">-</td>
                            <td className="table-data-2">-</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
           }
    </>

        
        );
}