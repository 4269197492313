/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import excelLogo from '../../assets/img/512PX/excelLogo.png';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './sass/styles.scss';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { addClient, addClientBilling, addContact, addOrderDetails, addOrders, asyncAddOrderConfig, asyncAddOrderDetails, asyncGetClientByRFC, createPatient, getClientByRFC, getContact, setShoppingCartRedux } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetInstitutions, asyncGetMedics, asyncGetPatients, getClients, getColors, getInstitutions, getMedics, getPatients, getSizes, searchPatient } from "../../actions/systemsAction";
import { getPatientsParams, getSellers } from "../../actions/allUsersAction";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment/moment";
import { asynGetProductPrices, postNewDirectQuote, postNewQuote } from "../../actions/newQuoteAction";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { NavLink } from "react-router-dom";
import icoHistory from '../../assets/img/126PX/Icono_atras_126px.png'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { element } from "prop-types";
import NumberFormat from "react-number-format";
import CircularProgress from '@material-ui/core/CircularProgress';

//import cfdiCatalog from '../../assets/catalogs/cfdi.json'
//import regimenCatalog from '../../assets/catalogs/regimen.json'
import VisibilityIcon from '@material-ui/icons/Visibility';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
});

export const OrderDetail = (props) => {

  const classes = useStyles();

  function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [openToasty, setOpenToasty] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const [finish, setFinish] = useState(false)

  const handleClickToasty = () => {
    setLoading(false)
    setTransition(() => TransitionRight);
    setOpenToasty(true);
    dispatch(setShoppingCartRedux([]))
    setFinish(true)
  };

  const handleCloseToasty = () => {
    setOpenToasty(false);
  };

  /* const patientList = useSelector((state) => state.systems.getPatients);
  const medicsList = useSelector((state) => state.systems.getMedics)
  const hospitalsList = useSelector((state) => state.systems.getInstitutions) */

  const [patientList, setPatientList] = useState([])
  const [contactList, setContactList] = useState([])
  const [medicsList, setMedicsList] = useState([])
  const [hospitalsList, setHospitalsList] = useState([])
  const [colorList, setColorList] = useState([])
  const [sizeList, setSizeList] = useState([])
  const [typeVest, setTypeVest] = useState(['Marcado', "Bordado"])
  const [valueVestToAdd, setValueVestToAdd] = useState(1)
  const vestRef = useRef()

  const loginData = useSelector((state) => state.user.user.identity)
  const requestH = useSelector((state) => state.user.request);

  useEffect(() => {
    async function fetchData() {
      await getPatientsFunction()
      await getMedicsFunction()
      await getInstitutionsFunction()
      await getContactList()
      await getColorsFunction()
      await getSizesFunction()
      calculateTotal()
      calculateTotalOfAll(props.location.state.shoppingCart)
    }
    fetchData()
  }, []);

  const getPatientsFunction = async () => {
    await asyncGetPatients(0, 10000, requestH).then((data) => {
      const newDataPatient = data.response
      newDataPatient.unshift({ id: 'new', firstName: 'Agregar Paciente', secondName: '', firstLastName: '', secondLastName: '' })
      setPatientList(newDataPatient)
    })
  }

  const getContactList = async () => {
    await asyncGetPatients(0, 10000, requestH).then((data) => {
      setContactList(data.response)
    })
  }

  const getMedicsFunction = async () => {
    await asyncGetMedics(0, 10000, requestH).then((data) => {
      setMedicsList(data.response)
    })
  }

  const getInstitutionsFunction = async () => {
    await asyncGetInstitutions(0, 10000, requestH).then((data) => {
      setHospitalsList(data.response)
    })
  }

  const getColorsFunction = async () => {
    await getColors(requestH).then((data) => {
      setColorList(data.response)
    })
  }

  const getSizesFunction = async () => {
    await getSizes(requestH).then((data) => {
      setSizeList(data.response)
    })
  }


  const [selectedRFC, setSelectedRFC] = useState('none')
  const [rfcInfo, setRfcInfo] = useState('')

  const [shoppingCart, setShoppingCart] = useState(props.location.state.shoppingCart)
  const dispatch = useDispatch();

  const addToShoppingCart = (newItem, type, index) => {
    const copyCart = [...shoppingCart];

    copyCart.forEach((cartItem, innerIndex) => {
      if (cartItem.id === newItem.id && type === cartItem.type) {
        if (type === 'producto') {
          copyCart[innerIndex].quantity++
        } else {
          copyCart[innerIndex].open = true;
          if (type === 'paquete') {
            copyCart[innerIndex].information.configuration.items[index].productQuantity++;
          } else {
            copyCart[innerIndex].information.quotationItems[index].productQuantity++;
          }
        }
      }
    });

    setShoppingCart(copyCart);
    calculateTotalOfAll(copyCart);
  }

  const removeFromShoppingCart = (newItem, type, index) => {
    const copyCart = [...shoppingCart]

    copyCart.map((cartItem, innerIndex) => {
      if (cartItem.id == newItem.id && type == cartItem.type) {
        if (type == 'producto') {
          if (copyCart[innerIndex].quantity > 1) {
            copyCart[innerIndex].quantity = copyCart[innerIndex].quantity - 1
          } else {
            copyCart.indexOf(innerIndex);
            copyCart.splice(innerIndex, 1);
          }
        }
        else {
          copyCart[innerIndex].open = true;
          if (type == 'paquete') {
            if (copyCart[innerIndex].information.configuration.items[index].productQuantity > 1) {
              copyCart[innerIndex].information.configuration.items[index].productQuantity = copyCart[innerIndex].information.configuration.items[index].productQuantity - 1
            } else {
              copyCart[innerIndex].information.configuration.items.indexOf(index);
              copyCart[innerIndex].information.configuration.items.splice(index, 1);
            }
            if (copyCart[innerIndex].information.configuration.items.length == 0) {
              copyCart.indexOf(innerIndex);
              copyCart.splice(innerIndex, 1)
            }
          }
          else {
            if (copyCart[innerIndex].information.quotationItems[index].productQuantity > 1) {
              copyCart[innerIndex].information.quotationItems[index].productQuantity = copyCart[innerIndex].information.quotationItems[index].productQuantity - 1
            } else {
              copyCart[innerIndex].information.quotationItems.indexOf(index);
              copyCart[innerIndex].information.quotationItems.splice(index, 1);
            }
            if (copyCart[innerIndex].information.quotationItems.length == 0) {
              copyCart.indexOf(innerIndex);
              copyCart.splice(innerIndex, 1)
            }
          }
        }
      }
    })
    setShoppingCart(copyCart)
    calculateTotalOfAll(copyCart)
  }

  const [priceInputValue, setPriceInputValue] = useState(props.location.state.priceInputValue);
  const [priceList, setPriceList] = useState(props.location.state.priceList)

  const { searchQuoteFinished } = useParams();

  const handlePriceInputChange = (event) => {
    setPriceInputValue(event.target.value);
    changePricesOfAll(event.target.value)
  };

  const [totalPrices, setTotalPrices] = useState({
    subtotal: 0,
    impuesto: 0,
    total: 0
  })

  const changePricesOfAll = async (priceId) => {
    const newShoppingCart = [...shoppingCart]
    const itemsCode = []

    shoppingCart.map((item) => {
      if (item.type == 'producto') {
        itemsCode.push({ "productCode": item.information.productCode })
      } else if (item.type == 'cotizacion') {
        item.information.quotationItems.map((quoteItems) => {
          itemsCode.push({ "productCode": quoteItems.product.productCode })
        })
      } else if (item.type == 'paquete') {
        item.information.configuration.items.map((paqueteItem) => {
          itemsCode.push({ "productCode": paqueteItem.product.productCode })
        })
      }
    })

    const paquetesProductsWithPrice = await asynGetProductPrices({ "PriceListId": priceId, "Products": itemsCode }, requestH).then((data) => {
      return (data.response)
    })

    shoppingCart.map((item, index) => {
      if (item.type == 'producto') {
        const productInfo = paquetesProductsWithPrice.find(individualProduct => individualProduct.productCode == item.information.productCode)
        if (productInfo) {
          if (newShoppingCart[index].selected) {
            newShoppingCart[index].information.prices = productInfo
            newShoppingCart[index].productPrice = productInfo.priceForSale
          }
        }
      } else if (item.type == 'cotizacion') {
        item.information.quotationItems.map((quoteItems, indexCot) => {
          const productInfo = paquetesProductsWithPrice.find(individualProduct => individualProduct.productCode == quoteItems.product.productCode)
          if (productInfo) {
            if (newShoppingCart[index].information.quotationItems[indexCot].selected)
              newShoppingCart[index].information.quotationItems[indexCot].productPrice = productInfo.priceForSale
          }
        })
      } else if (item.type == 'paquete') {
        item.information.configuration.items.map((paqueteItem, indexPaq) => {
          const productInfo = paquetesProductsWithPrice.find(individualProduct => individualProduct.productCode == paqueteItem.product.productCode)
          if (productInfo) {
            if (newShoppingCart[index].information.configuration.items[indexPaq].selected) {
              newShoppingCart[index].information.configuration.items[indexPaq].prices = productInfo
              newShoppingCart[index].information.configuration.items[indexPaq].productPrice = productInfo.priceForSale
            }
          }
        })
      }
    })
    calculateTotalOfAll(newShoppingCart)

    setShoppingCart(newShoppingCart)
  }

  const calculateTotalOfAll = async (copyCart) => {
    const newShoppingCart = [...copyCart]

    shoppingCart.map((item, index) => {
      if (item.type == 'producto') {
        const subtotal = (item.productPrice * item.quantity)
        const descuentoT = subtotal * parseFloat(item.discountPercentage / 100)
        const total = subtotal - descuentoT
        newShoppingCart[index].total = total
      } else if (item.type == 'cotizacion') {
        item.information.quotationItems.map((quoteItems, indexCot) => {
          const subtotal = (quoteItems.productPrice * quoteItems.productQuantity)
          const descuentoT = subtotal * parseFloat(`.${quoteItems.discountPercentage}`)
          const total = subtotal - descuentoT
          newShoppingCart[index].information.quotationItems[indexCot].total = total
        })
      } else if (item.type == 'paquete') {
        item.information.configuration.items.map((paqueteItem, indexPaq) => {
          const subtotal = (paqueteItem.productPrice * paqueteItem.productQuantity)
          const descuentoT = subtotal * parseFloat(`.${paqueteItem.discountPercentage}`)
          const total = subtotal - descuentoT
          newShoppingCart[index].information.configuration.items[indexPaq].total = total
        })
      }
    })

    setShoppingCart(newShoppingCart)
  }

  const changePricesOfQuote = async (priceId) => {
    const newShoppingCart = [...shoppingCart]
    const itemsCode = []

    shoppingCart.map((item) => {
      if (item.type == 'cotizacion') {
        item.information.quotationItems.map((quoteItems) => {
          itemsCode.push({ "productCode": quoteItems.product.productCode })
        })
      }
    })

    const paquetesProductsWithPrice = await asynGetProductPrices({ "PriceListId": priceId, "Products": itemsCode }, requestH).then((data) => {
      return (data.response)
    })

    shoppingCart.map((item, index) => {
      if (item.type == 'cotizacion') {
        item.information.quotationItems.map((quoteItems, indexCot) => {
          const productInfo = paquetesProductsWithPrice.find(individualProduct => individualProduct.productCode == quoteItems.product.productCode)
          if (productInfo) {
            newShoppingCart[index].information.quotationItems[indexCot].productPrice = productInfo.priceForSale
          }
        })
      }
    })

    setShoppingCart(newShoppingCart)
  }

  useEffect(() => {
    calculateTotal()
  }, [shoppingCart])

  const calculateTotal = () => {
    let subtotal = 0
    let impuesto = 0
    let total = 0

    shoppingCart.map((item) => {
      if (item.type == 'producto') {
        const subtotalP = (item.productPrice * item.quantity)
        const descuentoP = subtotalP * parseFloat(item.discountPercentage / 100)
        const totalP = subtotalP - descuentoP
        subtotal = (subtotal + totalP)
      } else if (item.type == 'cotizacion') {
        item.information.quotationItems.map((quoteItems) => {
          const subtotalC = (quoteItems.productPrice * quoteItems.productQuantity)
          const descuentoC = subtotalC * parseFloat(`.${quoteItems.discountPercentage}`)
          const totalC = subtotalC - descuentoC
          subtotal = (subtotal + totalC)
        })
      } else if (item.type == 'paquete') {
        item.information.configuration.items.map((paqueteItem) => {
          const subtotalP = (paqueteItem.productPrice * paqueteItem.productQuantity)
          const descuentoP = subtotalP * parseFloat(`.${paqueteItem.discountPercentage}`)
          const totalP = subtotalP - descuentoP
          subtotal = (subtotal + totalP)
        })
      }
    })

    impuesto = subtotal * .16

    total = subtotal + impuesto

    setTotalPrices({
      subtotal: subtotal,
      impuesto: impuesto,
      total: total
    })

  }

  const handleSelectRFC = (value, set = true) => {
    setSelectedRFC(value);
    const found = rfcResults.filter((singleValues) => {
      return singleValues.id == value
    })

    if (set)
      setClientSearchRFC(found[0].rfc)


    setRfcInfo(found[0])
  };

  const changeIndividualValuesEdit = (event, type, valueStored) => {
    if (type == 'quantity') {
      setCustomQuantity(event.target.value)
    } else if (type == 'price') {
      setCustomPrice(event)
    } else if (type == 'disccount') {
      setCustomDisccount(event)
    }
  }

  const changeQuantityManual = (newItem, type, index) => {
    const newShoppingCart = [...shoppingCart]
    shoppingCart.map((item, index) => {
      if (item.type == 'producto') {
        if (index == generalIndex) {
          newShoppingCart[index].quantity = customQuantity
          newShoppingCart[index].productPrice = customPrice
          newShoppingCart[index].discountPercentage = customDisccount
        }
      } else if (item.type == 'cotizacion') {
        item.information.quotationItems.map((quoteItems, indexCot) => {
          if (index == generalIndex && indexCot == innerIndex) {
            newShoppingCart[index].information.quotationItems[indexCot].productQuantity = customQuantity
            newShoppingCart[index].information.quotationItems[indexCot].productPrice = customPrice
            newShoppingCart[index].information.quotationItems[indexCot].discountPercentage = customDisccount
          }
        })
      } else if (item.type == 'paquete') {
        item.information.configuration.items.map((paqueteItem, indexPaq) => {
          if (index == generalIndex && indexPaq == innerIndex) {
            newShoppingCart[index].information.configuration.items[indexPaq].productQuantity = customQuantity
            newShoppingCart[index].information.configuration.items[indexPaq].productPrice = customPrice
            newShoppingCart[index].information.configuration.items[indexPaq].discountPercentage = customDisccount
          }
        })
      }
    })

    setShoppingCart(newShoppingCart)
    calculateTotal()
    calculateTotalOfAll(newShoppingCart)
    setValue(1)
    setEditDrawer(false)
  }

  const setPriceToAllItem = async (priceId) => {
    setLoaderOpen(true)
    const copyProducts = [...products]

    const preProducts = products && products.map((individualProduct) => {
      return { "productCode": individualProduct.productCode }
    })

    const productsWithPrice = await asynGetProductPrices({ "PriceListId": priceId, "Products": preProducts }, requestH).then((data) => {
      return (data.response)
    })

    const allMerge = productsWithPrice.map((insideProductWithPrice) => {
      const productInfo = copyProducts.find(individualProduct => individualProduct.productCode == insideProductWithPrice.productCode)
      productInfo.prices = insideProductWithPrice
      return productInfo
    })

    setRowsProducts(allMerge)
    setLoaderOpen(false)
  }

  function getSteps() {
    return [{ step: 0, label: 'Lista Productos' }, { step: 1, label: 'Datos del cliente' }, { step: 2, label: 'Confirma' }];
  }

  const [clientSearchRFC, setClientSearchRFC] = useState('')

  const [facturationData, setFacturationData] = useState({
    razonSocial: '',
    rfc: '',
    domicilio: '',
    cp: '',
    cfdi: '',
    regimen: '',
    condicionPago: '',
    metodoPago: '',
    formaPago: '',
    numeroPago: '',
  })

  const [facturationDataStatus, setFacturationDataStatus] = useState({
    razonSocial: false,
    rfc: false,
    domicilio: false,
    cp: false,
    cfdi: false,
    regimen: false,
    condicionPago: false,
    metodoPago: false,
    formaPago: false,
  })

  const handleFacturacionValues = (event) => {
    if (event.target.name == 'formaPago' || event.target.name == 'condicionPago' || event.target.name == 'metodoPago') {
      const newJson = { ...facturationData }
      newJson[event.target.name] = event.target.value
      setFacturationData(newJson)
    } else {
      const newJson = { ...facturationData }
      newJson[event.target.id] = event.target.value
      setFacturationData(newJson)
    }
    checkFields()
  }

  const [facturationExistingData, setFacturationExistingData] = useState({
    cfdi: '',
    regimen: '',
    condicionPago: '',
    metodoPago: '',
    formaPago: '',
    numeroPago: '',
  })

  const [facturationExistingDataStatus, setFacturationExistingDataStatus] = useState({
    cfdi: false,
    regimen: false,
    condicionPago: false,
    metodoPago: false,
    formaPago: false,
    rfcSelected: false
  })

  const handleFacturacioExistingValues = (event) => {
    if (event.target.name == 'formaPago' || event.target.name == 'condicionPago' || event.target.name == 'metodoPago') {
      const newJson = { ...facturationExistingData }
      console.log(event.target)
      newJson[event.target.name] = event.target.value
      setFacturationExistingData(newJson)
    } else {
      const newJson = { ...facturationExistingData }
      newJson[event.target.id] = event.target.value
      setFacturationExistingData(newJson)
    }
    checkFields()
  }

  const [newClientData, setNewClientData] = useState({
    firstName: '',
    secondName: '',
    lastName: '',
    secondLastName: '',
    phone: '',
    email: '',
    city: '',
    state: '',
    comments: ''
  })

  const [newClientDataStatus, setNewClientDataStatus] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false
  })

  const handleNewClientValues = (event) => {
    const newJson = { ...newClientData }
    newJson[event.target.id] = event.target.value
    setNewClientData(newJson)
    checkFields()
  }

  const [shipmentData, setShipmentData] = useState({
    deliveryDate: moment().format('YYYY-MM-DD'),
    reference: '',
    address: '',
    contactData: '',
    otherData: ''
  })

  const handleShipmentValues = (event) => {
    const newJson = { ...shipmentData }
    newJson[event.target.id] = event.target.value
    setShipmentData(newJson)
  }

  const [otherData, setOtherData] = useState({
    name: '',
    observations: '',
  })

  const [otherDataStatus, setOtherDataStatus] = useState({
    name: false
  })

  const handleOtherValues = (event) => {
    const newJson = { ...otherData }
    newJson[event.target.id] = event.target.value
    setOtherData(newJson)
    checkFields()
  }

  const [cirugiaData, setCirugiaData] = useState({
    patientIdentity: '',
    patientFirstName: '',
    patientSecondName: '',
    patientFirstLastName: '',
    patientSecondLastName: '',
    surgeryDate: moment().format('YYYY-MM-DD'),
    surgeryHour: moment().format('HH:mm'),
  })

  const handleCirugiaValues = (event) => {
    const newJson = { ...cirugiaData }
    newJson[event.target.id] = event.target.value
    setCirugiaData(newJson)
  }

  const addedContact = useSelector((state) => state.user.contactAdded);

  const handleAllUpdates = () => {
    setLoading(false)

    if (valueClientStockNew == 'new') {
      dispatch(addContact({
        "firstName": newClientData.firstName,
        "secondName": newClientData.secondName,
        "firstLastName": newClientData.lastName,
        "secondLastName": newClientData.secondLastName,
        "phone": newClientData.phone,
        "email": newClientData.email,
        "rfc": facturationData.rfc,
        "city": newClientData.city,
        "state": newClientData.state,
        "comentario": newClientData.comments,
        "isActive": true,
        "isDeleted": false,
      }))
    } else {
      dispatch(addClientBilling({
        "clientId": rfcInfo.id,
        "billingName": rfcInfo.clientName,
        "billingRFC": rfcInfo.rfc,
        "billingAddress": rfcInfo.address,
        "billingZIP": rfcInfo.cp,
        "billingRaxRegime": valueRegimen.code
      }))
    }
  }

  useEffect(() => {
    if (checked && orderNumberSale)
      handleAddClient()
    if (!checked && otherData.name) {
      handleAddBilling()
    }
  }, [addedContact])

  const addedBilling = useSelector((state) => state.user.clientBillingAdded);

  const handleAddBilling = () => {
    if (valueClientStockNew == 'new') {
      if ((checked && addedContact && facturationData && facturationData.razonSocial) || (addedContact)) {
        dispatch(addClientBilling({
          "id": 0,
          "clientId": addedClient && addedClient.id,
          "contactId": addedContact && addedContact.id,
          "billingName": facturationData.razonSocial,
          "billingRFC": facturationData.rfc,
          "billingAddress": facturationData.domicilio,
          "billingZIP": facturationData.cp,
          "billingRaxRegime": valueRegimen.code,
          "isDeleted": false
        }))
      }
    } else {
      if ((checked && facturationData && facturationData.razonSocial) || (!checked)) {
        dispatch(addClientBilling({
          "id": 0,
          "clientId": rfcInfo.id,
          "contactId": 0,
          "billingName": rfcInfo.clientName,
          "billingRFC": rfcInfo.rfc,
          "billingAddress": rfcInfo.address,
          "billingZIP": rfcInfo.cp,
          "billingRaxRegime": valueRegimen.code,
          "isDeleted": false
        }))
      }
    }
  }

  useEffect(() => {
    if ((checked && orderNumberSale) || (!checked && otherData.name))
      if (valueClientStockNew == 'new') {
        if (cirugiaData.patientFirstName)
          handleAddPatient()
        else handleAddQuote()
      } else {
        if (cirugiaData.patientFirstName)
          handleAddPatient()
        else handleAddQuote()
      }
  }, [addedBilling])

  const addedClient = useSelector((state) => state.user.clientAdded);

  const handleAddClient = () => {
    if ((checked && facturationData && facturationData.razonSocial && loginData && newClientData && priceInputValue) || (checked && loginData && newClientData && priceInputValue)) {
      dispatch(addClient({
        "id": 0,
        "clientCode": "",
        "clientBusiness": "01",
        "clientName": facturationData.razonSocial,
        "clientCommercialRole": "F",
        "clientShortName": facturationData.razonSocial,
        "sellerID": loginData.sellerCode || '0',
        "paymentCondition": facturationData.condicionPago,
        "paymentMethod": facturationData.metodoPago,
        "address": facturationData.domicilio,
        "clientType": 1,
        "state": newClientData.state,
        "city": newClientData.city,
        "phone": newClientData.phone,
        "rfc": facturationData.rfc,
        "email": newClientData.email,
        "billingAccount": '106001',
        "salesGroup": "",
        "priceList": JSON.stringify(priceInputValue),
        "costCenter": "",
        "cp": facturationData.cp,
        "neighborhood": '',
        "isActive": true,
        "isDeleted": false,
      }))
    } else {
      handleAddQuote()
    }
  }

  useEffect(() => {
    if ((checked && orderNumberSale) || (!checked && otherData.name)) {
      if (valueClientStockNew == 'new') {
        handleAddBilling()
      } else {
        handleAddQuote()
      }
    }
  }, [addedClient])

  const addedPatient = useSelector((state) => state.user.createdPatient)

  const handleAddPatient = () => {
    dispatch(createPatient({
      "id": 0,
      "firstName": cirugiaData.patientFirstName || '',
      "secondName": cirugiaData.patientSecondName || '',
      "firstLastName": cirugiaData.patientFirstLastName || '',
      "secondLastName": cirugiaData.patientSecondLastName || '',
      "isActive": true,
      "isDeleted": false
    }))
  }

  useEffect(() => {
    if ((checked && orderNumberSale) || (!checked && otherData.name))
      if (valueClientStockNew == 'new')
        handleAddClient()
      else
        handleAddQuote()
  }, [addedPatient])

  const addedQuote = useSelector((state) => state.newQuote.createdQuote)

  const handleAddQuote = () => {
    if ((addedClient && loginData && addedBilling) || loginData && addedBilling) {
      const items = []
      shoppingCart.filter((item) => {
        if (item.type == 'producto') {
          items.push({
            "id": item.id,
            "quotationId": 0,
            "product": item.information,
            "productQuantity": item.quantity,
            "discountPercentage": parseInt(item.discountPercentage),
            "productPrice": item.productPrice
          })
        } else if (item.type == 'cotizacion') {
          item.information.quotationItems.map((quoteItems) => {
            items.push({
              "id": quoteItems.id,
              "quotationId": 0,
              "product": quoteItems.product,
              "productQuantity": quoteItems.productQuantity,
              "discountPercentage": parseInt(quoteItems.discountPercentage),
              "productPrice": quoteItems.productPrice
            })
          })
        } else if (item.type == 'paquete') {
          item.information.configuration.items.map((paqueteItem) => {
            items.push({
              "id": paqueteItem.id,
              "quotationId": 0,
              "product": paqueteItem.product,
              "productQuantity": paqueteItem.productQuantity,
              "discountPercentage": parseInt(paqueteItem.discountPercentage),
              "productPrice": paqueteItem.productPrice
            })
          })
        }
      })

      const newQuote = {
        "id": 0,
        "PriceList": getObjectById(priceList, priceInputValue),
        "QuotationName": otherData.name,
        "CurrentDate": moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        "Client": addedClient || null,
        "Medic": valueMedic || null,
        "Atn": null,
        "patient": addedPatient || valuePatient || null,
        "Hospital": valueHospital || null,
        "DeliveryDate": moment(shipmentData.deliveryDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        "QuotationExpirationDate": moment(shipmentData.deliveryDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        "Email": newClientData.email || rfcInfo.email,
        "Phone": newClientData.phone || rfcInfo.phone,
        "QuotationItems": items,
        "SpecificDescriptionQuotationItems": items,
        "displayItems": items,
        "PaymentMethod": facturationExistingData.formaPago || facturationData.formaPago,
        "PaymentCondition": facturationExistingData.condicionPago || facturationData.condicionPago,
        "DeliveryDateString": moment(shipmentData.deliveryDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),

        "finalPrice": parseFloat(totalPrices.total),
        "realPrice": parseFloat(totalPrices.subtotal),
        "subTotal": parseFloat(totalPrices.subtotal),
        "ivaQuantity": parseFloat(totalPrices.impuesto),

        "City": "",
        "State": "",
        "institutionName": null,

        "notes": otherData.observations,
        "isFinished": true,
        "creatorUserId": loginData.userID,
        "creatorIdentity": loginData

      }

      dispatch(postNewDirectQuote(newQuote))
    }
  }

  useEffect(() => {
    if (checked) {
      handleAddOrder()
    }
    else {
      handleClickToasty()
    }
  }, [addedQuote])

  const addedOrder = useSelector((state) => state.user.ordersAdded)

  const handleAddOrder = () => {
    if (valueClientStockNew == 'new') {
      if (addedClient && loginData && addedBilling && valueCFDI && orderNumberSale && checked) {
        dispatch(addOrders({
          "id": 0,
          "clientId": JSON.stringify(addedClient.id),
          "priceListId": JSON.stringify(priceInputValue),
          "sellerId": JSON.stringify(loginData.userID) || '0',
          "billingId": addedBilling.id,

          "store": "",
          "orderType": "",
          "saleType": "",
          "filial": "",
          "atqId": "",
          "secondaryATQ": "",

          "orderNumberSale": orderNumberSale,
          "cfdi": valueCFDI.code,
          "paymentCondition": facturationData.condicionPago,
          "paymentMethod": facturationData.metodoPago,
          "paymentForm": facturationData.formaPago,
          "paymentReceipt": facturationData.numeroPago,
          "deliveryDate": moment(shipmentData.deliveryDate).format(),
          "referencesAddress": shipmentData.reference,
          "address": shipmentData.address,

          "hospitalId": valueHospital ? JSON.stringify(valueHospital.id) : '0',
          "patientId": valuePatient ? JSON.stringify(valuePatient.id) : '0',
          "medic": valueMedic ? JSON.stringify(valueMedic.id) : '0',
          "surgeryDate": cirugiaData && cirugiaData.surgeryDate && cirugiaData.surgeryHour ? moment(`${cirugiaData.surgeryDate} ${cirugiaData.surgeryHour}`).format() : '2023-03-10T16:16:47.942Z',
          "surgeryHour": cirugiaData && cirugiaData.surgeryHour ? cirugiaData.surgeryHour : '9:00',

          "orderedBy_Name": otherData.name,
          "warehouseMessage": otherData.observations,
          "isActive": true,
          "isDeleted": false

        }))
      }
    } else {
      if (loginData && addedBilling && valueCFDI && orderNumberSale && checked) {
        dispatch(addOrders({
          "id": 0,
          "clientId": JSON.stringify(rfcInfo.id),
          "priceListId": JSON.stringify(priceInputValue),
          "sellerId": JSON.stringify(loginData.userID) || '0',
          "billingId": addedBilling.id,
          "store": "",
          "orderType": "",
          "saleType": "",
          "filial": "",
          "atqId": "",
          "secondaryATQ": "",
          "orderNumberSale": orderNumberSale,
          "cfdi": valueCFDI.code,
          "paymentCondition": facturationExistingData.condicionPago,
          "paymentMethod": facturationExistingData.metodoPago,
          "paymentForm": facturationExistingData.formaPago,
          "paymentReceipt": facturationExistingData.numeroPago,
          "deliveryDate": moment(shipmentData.deliveryDate).format(),
          "referencesAddress": shipmentData.reference,
          "address": shipmentData.address,
          "hospitalId": valueHospital ? JSON.stringify(valueHospital.id) : '0',
          "patientId": valuePatient ? valuePatient.id == 'new' ? JSON.stringify(addedPatient.id) : JSON.stringify(valuePatient.id) : '0',
          "medic": valueMedic ? JSON.stringify(valueMedic.id) : '0',
          "surgeryDate": cirugiaData && cirugiaData.surgeryDate && cirugiaData.surgeryHour ? moment(`${cirugiaData.surgeryDate} ${cirugiaData.surgeryHour}`).format() : '2023-03-10T16:16:47.942Z',
          "surgeryHour": cirugiaData && cirugiaData.surgeryHour ? cirugiaData.surgeryHour : '9:00',
          "orderedBy_Name": otherData.name,
          "warehouseMessage": otherData.observations,
          "isActive": true,
          "isDeleted": false
        }))
      }
    }
  }

  useEffect(() => {
    if (addedOrder && addedOrder.id && addedBilling) {
      handleAddOrderDetails()
    }
  }, [addedOrder])

  function findIdByName(arr, name) {
    // Loop through the array
    for (let i = 0; i < arr.length; i++) {
      // If the name matches, return the id
      if (arr[i].name === name) {
        return arr[i].id;
      }
    }
    // If the name wasn't found, return null
    return null;
  }

  const handleAddOrderDetails = () => {
    if (loginData && addedBilling && orderNumberSale && addedOrder && addedOrder.id && checked) {
      const items = []
      shoppingCart.filter((item) => {
        if (item.type == 'producto') {
          if (item.vestConfigurations && item.vestConfigurations.length > 0) {
            item.vestConfigurations.map(async (singleVest) => {
              return await asyncAddOrderConfig({
                "idOrder": addedOrder.id,
                "productCode": item.information.productCode,
                "idColor": findIdByName(colorList, singleVest.color) || 0,
                "colorName": singleVest.color || '',
                "idSize": findIdByName(sizeList, singleVest.size) || 0,
                "sizeName": singleVest.size || '',
                "idType": singleVest.type == 'Bordado' ? 2 : 1,
                "productEmbroidery": singleVest.datos
              }, requestH).then((data) => {
                return data.response
              })
            })
          }
          items.push({
            "id": 0,
            "product": {
              "id": item.information.id,
              "productCode": item.information.productCode,
              "productDescription": item.information.productDescription,
              "productType": item.information.productType,
              "manufacturerName": item.information.manufacturerName,
              "productObservation": item.information.productObservation,
              "productModel": item.information.productModel,
              "productBrand": item.information.productBrand,
              "productSubmodel": item.information.productSubmodel,
              "satProdServ": item.information.satProdServ,
              "productImage": item.information.productImage,
              "groupID": item.information.groupID,
              "identificationNumber": item.information.identificationNumber,
              "shouldSendToApp": item.information.shouldSendToApp,
              "isBundle": item.information.isBundle,
              "shortDescription": item.information.shortDescription,
              "isActive": true,
              "isDeleted": false,
              "availableLocations": item.information.availableLocations,
              "ctrsize": item.information.ctrsize,
              "ctrcolor": item.information.ctrcolor,
              "shippinglocation": item.information.shippinglocation
            },
            "quantitySupplied": 0,
            "quantityPending": item.quantity,
            "quantity": item.quantity,
            "rowOrderItem": 0,
            "location": "",
            "locationStorage": "",
            "productBatch": "",
            "isActive": true,
            "orderId": addedOrder.id,
            "isDeleted": false,
            "productCost": parseFloat(item.productPrice),
            "productDiscount": parseInt(item.discountPercentage)
          })
        } else if (item.type == 'cotizacion') {
          item.information.quotationItems.map((quoteItems) => {
            items.push({
              "id": 0,
              "product": {
                "id": quoteItems.product.id,
                "productCode": quoteItems.product.productCode,
                "productDescription": quoteItems.product.productDescription,
                "productType": quoteItems.product.productType,
                "manufacturerName": quoteItems.product.manufacturerName,
                "productObservation": quoteItems.product.productObservation,
                "productModel": quoteItems.product.productModel,
                "productBrand": quoteItems.product.productBrand,
                "productSubmodel": quoteItems.product.productSubmodel,
                "satProdServ": quoteItems.product.satProdServ,
                "productImage": quoteItems.product.productImage,
                "groupID": quoteItems.product.groupID,
                "identificationNumber": quoteItems.product.identificationNumber,
                "shouldSendToApp": quoteItems.product.shouldSendToApp,
                "isBundle": quoteItems.product.isBundle,
                "shortDescription": quoteItems.product.shortDescription || '',
                "isActive": true,
                "isDeleted": false,
                "availableLocations": quoteItems.product.availableLocations,
                "ctrsize": quoteItems.product.ctrsize,
                "ctrcolor": quoteItems.product.ctrcolor,
                "shippinglocation": quoteItems.product.shippinglocation
              },
              "quantitySupplied": 0,
              "quantityPending": quoteItems.productQuantity,
              "quantity": quoteItems.productQuantity,
              "rowOrderItem": 0,
              "location": "",
              "locationStorage": "",
              "productBatch": "",
              "isActive": true,
              "orderId": addedOrder.id,
              "isDeleted": false,
              "productCost": parseFloat(quoteItems.productPrice),
              "productDiscount": parseInt(quoteItems.discountPercentage)
            })
          })
        } else if (item.type == 'paquete') {
          item.information.configuration.items.map((paqueteItem) => {
            items.push({
              "id": 0,
              "product": {
                "id": paqueteItem.product.id,
                "productCode": paqueteItem.product.productCode,
                "productDescription": paqueteItem.product.productDescription,
                "productType": paqueteItem.product.productType,
                "manufacturerName": paqueteItem.product.manufacturerName,
                "productObservation": paqueteItem.product.productObservation,
                "productModel": paqueteItem.product.productModel,
                "productBrand": paqueteItem.product.productBrand,
                "productSubmodel": paqueteItem.product.productSubmodel,
                "satProdServ": paqueteItem.product.satProdServ,
                "productImage": paqueteItem.product.productImage,
                "groupID": paqueteItem.product.groupID,
                "identificationNumber": paqueteItem.product.identificationNumber,
                "shouldSendToApp": paqueteItem.product.shouldSendToApp,
                "isBundle": paqueteItem.product.isBundle,
                "shortDescription": paqueteItem.product.shortDescription || '',
                "isActive": true,
                "isDeleted": false,
                "availableLocations": paqueteItem.product.availableLocations,
                "ctrsize": paqueteItem.product.ctrsize,
                "ctrcolor": paqueteItem.product.ctrcolor,
                "shippinglocation": paqueteItem.product.shippinglocation
              },
              "quantitySupplied": 0,
              "quantityPending": paqueteItem.productQuantity,
              "quantity": paqueteItem.productQuantity,
              "rowOrderItem": 0,
              "location": "",
              "locationStorage": "",
              "productBatch": "",
              "isActive": true,
              "orderId": addedOrder.id,
              "isDeleted": false,
              "productCost": paqueteItem.productPrice,
              "productDiscount": parseInt(paqueteItem.discountPercentage)
            })
          })
        }
      })

      items.map(async (singleItem) => {
        return await asyncAddOrderDetails(singleItem, requestH).then((data) => {
          return data.response
        })
      })
      setItemsDetails(items)
    }
  }


  const [itemsDetails, setItemsDetails] = useState([])
  const [orderNumberSale, setNumberOrderSale] = useState('')
  const [orderNumberSaleStatus, setNumberOrderSaleStatus] = useState(false)

  const [rfcResults, setRfcResults] = useState()
  const [cfdiCatalog, setCfdiCatalog] = useState([]);
  const [regimenCatalog, setRegimenCatalog] = useState([]);

  function getObjectById(array, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return array[i];
      }
    }
    return null; // Return null if no matching object is found
  }

  useEffect(() => {
    if (itemsDetails && itemsDetails.length > 0) {
      getSATCats();
      handleClickToasty()
    }
  }, [itemsDetails])

  async function getSATCats() {
    await getCatCfdi(requestH).then((res) => {
      if (res.sucess)
      {
        console.log("CAT CFDI: ", res.response)
        setCfdiCatalog(res.response)
      }
      else console.log("ERROR: ", res.errorMessage)
     })

     getCatRegFiscal(requestH).then((res) => {
      if (res.sucess)
      {
        setRegimenCatalog(res.response)
      }
      else console.log("ERROR: ", res.errorMessage)
     })
   }

  const handleChangeRFC = async (event) => {
    setClientSearchRFC(event.target.value);
    if (event.target.value) {
      await asyncGetClientByRFC(event.target.value, requestH).then((data) => {
        setRfcResults(data.response)
        if (data.response && data.response.length > 0) {
          handleSelectRFC(data.response[0].id, false)
        }
      })
    }
    checkFields()
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const allVestConfigurated = () => {
    const validation = shoppingCart.map((shoppingCartElement) => {
      if (shoppingCartElement.type == 'producto') {
        if (shoppingCartElement.information.ctrcolor > 0 || shoppingCartElement.information.ctrsize > 0) {
          if (shoppingCartElement.vestConfigurations) {
            return shoppingCartElement.vestConfigurations.length == shoppingCartElement.quantity
          } else {
            return false
          }
        } else {
          return true
        }
      } else if (shoppingCartElement.type == 'paquete' || shoppingCartElement.type == 'cotizacion') {
        const validationPaquete = shoppingCartElement.information.configuration && shoppingCartElement.information.configuration.items.map((paqueteItem) => {
          if (paqueteItem.product.ctrcolor > 0 || paqueteItem.product.ctrsize > 0) {
            if (paqueteItem.vestConfigurations) {
              return paqueteItem.vestConfigurations.length == paqueteItem.productQuantity
            } else {
              return false
            }
          } else {
            return true
          }
        })
        if (validationPaquete)
          return !validationPaquete.includes(false)
        else
          return true
      }
    })

    if (validation.includes(false)) {
      console.log('Entro')
      setOpenErrorVest(true)
    }

    return !validation.includes(false)

  }

  const handleNext = () => {
    if (activeStep == 1) {
      checkFields()
      if (valueClientStockNew == 'new') {
        if (checked && newClientData.firstName &&
          newClientData.lastName &&
          newClientData.phone &&
          newClientData.email &&
          facturationData.razonSocial &&
          facturationData.rfc &&
          facturationData.domicilio &&
          facturationData.cp &&
          valueCFDI &&
          valueRegimen &&
          facturationData.condicionPago &&
          facturationData.formaPago &&
          otherData.name &&
          orderNumberSale) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (!checked &&
          newClientData.firstName &&
          newClientData.lastName &&
          newClientData.phone &&
          newClientData.email &&
          otherData.name) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      else if (valueClientStockNew == 'stock') {
        if (
          checked &&
          rfcInfo.rfc &&
          valueCFDI &&
          facturationExistingData.condicionPago &&
          facturationExistingData.formaPago &&
          valueRegimen &&
          otherData.name &&
          orderNumberSale
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (!checked &&
          rfcInfo.rfc &&
          otherData.name) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } else {
      if (allVestConfigurated())
        if (!(itemsDetails && itemsDetails.length > 0)) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }
  };

  const validateEmail = (email) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (validRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const checkFields = () => {
    const newJsonClient = { ...newClientDataStatus }
    const newJsonFacturation = { ...facturationDataStatus }
    const newJsonOtherData = { ...otherDataStatus }
    const newJsonExistingData = { ...facturationExistingDataStatus }

    console.log(!validateEmail(newClientData.email))
    console.log(!newClientData.email)
    console.log(newClientData.email.length == 0)

    newJsonClient.firstName = !newClientData.firstName && newClientData.firstName.length == 0
    newJsonClient.lastName = !newClientData.lastName && newClientData.lastName.length == 0
    newJsonClient.phone = !newClientData.phone && newClientData.phone.length == 0
    newJsonClient.email = !validateEmail(newClientData.email) || (!newClientData.email && newClientData.email.length == 0 && !validateEmail(newClientData.email))

    newJsonFacturation.razonSocial = !facturationData.razonSocial && facturationData.razonSocial.length == 0
    newJsonFacturation.rfc = !facturationData.rfc && facturationData.rfc.length == 0
    newJsonFacturation.domicilio = !facturationData.domicilio && facturationData.domicilio.length == 0
    newJsonFacturation.cp = !facturationData.cp && facturationData.cp.length == 0
    newJsonFacturation.cfdi = !valueCFDI && valueCFDI.length == 0
    newJsonFacturation.regimen = !valueRegimen && valueRegimen.length == 0
    newJsonFacturation.condicionPago = !facturationData.condicionPago && facturationData.condicionPago.length == 0
    newJsonFacturation.metodoPago = !facturationData.metodoPago && facturationData.metodoPago.length == 0
    newJsonFacturation.formaPago = !facturationData.formaPago && facturationData.formaPago.length == 0

    newJsonOtherData.name = !otherData.name && otherData.name.length == 0

    newJsonExistingData.cfdi = !valueCFDI && valueCFDI.length == 0
    newJsonExistingData.regimen = !valueRegimen && valueRegimen.length == 0
    newJsonExistingData.condicionPago = !facturationExistingData.condicionPago && facturationExistingData.condicionPago.length == 0
    newJsonExistingData.metodoPago = !facturationExistingData.metodoPago && facturationExistingData.metodoPago.length == 0
    newJsonExistingData.formaPago = !facturationExistingData.formaPago && facturationExistingData.formaPago.length == 0
    newJsonExistingData.rfcSelected = !rfcInfo

    setNumberOrderSaleStatus(!orderNumberSale && orderNumberSale.length == 0)
    setNewClientDataStatus(newJsonClient)
    setFacturationDataStatus(newJsonFacturation)
    setOtherDataStatus(newJsonOtherData)
    setFacturationExistingDataStatus(newJsonExistingData)
  }

  function createData(id, producto, cantidad, precio, descuento, total, icon) {
    return { id, producto, cantidad, precio, descuento, total, icon };
  }

  const rowsPrincipal = [
    createData('CS 8201-09', 'Chaleco manga corta', '5', '$2,500', '-', '$2,500', 'edit'),
    createData('CS 8201-09', 'Chaleco manga larga', '1', '$2,500', '-', '$2,500', 'edit'),
    createData('custom', 'Cotizacion guardada - Hospital los angeles', ' ', ' ', ' ', ' ', 'simpleEdit'),
    createData('CS 8201-08', 'CERV-X-CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '1', '$2,500', '-', '$2,500', 'simpleEdit'),
    createData('CS 2600-6-45', 'COSMICMIA-TORNILLO D6.5MM, LONGITUD 45MM', '50', '$1,000', '-10%', '$900', 'simpleEdit'),
    createData('170001', 'CHALECO', '1', '$50', '-', '-', 'simpleEdit'),
    createData('custom2', 'Paquete #359', ' ', ' ', ' ', '15,000', 'simpleEdit'),
    createData('006', 'ADD-D 12MM, ALTURA 15-25 MM, 6°', '2', '$7,500', '-', '-', 'simpleEdit'),
    createData('007', 'DIANA - IMPLANTE 19/18MM, 30MM', '2', '$7,500', '-', '-', 'simpleEdit'),
  ];

  const columns = [
    { id: 'select', label: ' ', width: '5%', align: 'start', background: 'red' },
    { id: 'id', label: 'ID', width: '10%', align: 'center', background: 'green' },
    { id: 'producto', label: 'PRODUCTO', width: '40%', align: 'center', background: 'blue' },
    { id: 'cantidad', label: 'CANTIDAD', width: '10%', align: 'center', background: 'yellow' },
    { id: 'precio', label: 'PRECIO', width: '10%', align: 'center', background: 'orange' },
    { id: 'descuento', label: 'DESCUENTO', width: '10%', align: 'center', background: 'purple' },
    { id: 'total', label: 'TOTAL', width: '10%', align: 'center', background: 'grey' },
    { id: 'icon', label: ' ', width: '5%', align: 'center', background: 'pink' }
  ];

  const [rows, setRows] = useState(rowsPrincipal);

  const handleStep = (step) => () => {
    if (activeStep > step) {
      setActiveStep(step);
    }
  };

  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked(!checked);
  };

  const [checkedPedido, setCheckedPedido] = React.useState(true);

  const handleChangePedido = () => {
    setChecked(!checked);
  };

  // Anchor Right Edit
  const [anchorElRight, setAnchorElRight] = React.useState(null);

  const handleClickEditRight = (event) => {
    setAnchorElRight(event.currentTarget);
  };

  const handleCloseEditRight = () => {
    setAnchorElRight(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [priceListModal, setPriceListModal] = useState(false);

  const [selectedCotizacion, setSelectedCotizacion] = useState()
  const [priceInputValueCotizacion, setPriceInputValueCotizacion] = useState();

  const handlePriceInputChangeCotizacion = (event) => {
    setPriceInputValueCotizacion(event.target.value);
  };


  const handleOpenPriceList = (row) => {
    setPriceInputValueCotizacion(row.information.priceList.id)
    setSelectedCotizacion(row)
    setPriceListModal(true);
  };

  const handleClosePriceList = () => {
    setPriceList(false);
  };

  const [deleteSelected, setDeleteSelected] = React.useState(false);

  const handleOpenDeleteSelected = () => {
    setDeleteSelected(true);
  };

  const handleCloseDeleteSelected = () => {
    setDeleteSelected(false);
  };

  const [editDrawer, setEditDrawer] = React.useState(false);
  const [editDrawerView, setEditDrawerView] = React.useState(false);



  const handleCloseEditDrawer = () => {
    setEditDrawer(false);
  };

  const handleCloseEditDrawerView = () => {
    setEditDrawerView(false);
  };
  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const [openDisc, setOpenDisc] = React.useState(false);

  const handleOpenDisc = () => {
    setOpenDisc(true);
  };

  const handleCloseDisc = () => {
    setOpenDisc(false);
  };

  function eliminarElementoDelArray(originalArray, objetoFiltro) {
    return originalArray.filter((elemento) => {
      return (
        elemento.color !== objetoFiltro.color ||
        elemento.size !== objetoFiltro.size ||
        elemento.type !== objetoFiltro.type ||
        elemento.datos !== objetoFiltro.datos
      );
    });
  }

  const handleRemoveVest = (vest) => {
    const newArray = { ...editActiveRow }

    const newArray2 = eliminarElementoDelArray(newArray.vestConfigurations, vest)

    editActiveRow.vestConfigurations = newArray2

    setInputValueColor('')
    setValue(0)
  }

  const [openErrorVest, setOpenErrorVest] = useState(false)


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    const [vestValue, setVestValue] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [openError, setOpenError] = useState(false);
    const [requireData, setRequireData] = useState(false);

    console.log(editActiveRow)

    const addToConfiguration = () => {
      if (!editActiveRow.vestConfigurations) {
        editActiveRow.vestConfigurations = []
      }

      if (editActiveRow && editActiveRow.quantity && editActiveRow.vestConfigurations.length < editActiveRow.quantity) {
        if ((editActiveRow.information.ctrcolor > 0 && inputValueColor != 'Color') && (editActiveRow.information.ctrsize > 0 && inputValueSize != 'Talla')) {
          if (!requireData && vestValue.length <= 0) {
            setOpenConfirmDialog(true)
          } else {
            addVestToArray()
          }
        } else if ((editActiveRow.information.ctrcolor > 0 && inputValueColor != 'Color') && (editActiveRow.information.ctrsize < 1)) {
          if (!requireData && vestValue.length <= 0) {
            setOpenConfirmDialog(true)
          } else {
            addVestToArray()
          }
        } else if ((editActiveRow.information.ctrcolor < 1) && (editActiveRow.information.ctrsize > 0 && inputValueSize != 'Talla')) {
          if (!requireData && vestValue.length <= 0) {
            setOpenConfirmDialog(true)
          } else {
            addVestToArray()
          }
        } else {
          setOpenError(true)
        }
      } else if (editActiveRow && editActiveRow.productQuantity && editActiveRow.vestConfigurations.length < editActiveRow.productQuantity) {
        if ((editActiveRow.product.ctrcolor > 0 && inputValueColor != 'Color') && (editActiveRow.product.ctrsize > 0 && inputValueSize != 'Talla')) {
          if (!requireData && vestValue.length <= 0) {
            setOpenConfirmDialog(true)
          } else {
            addVestToArrayProduct()
          }
        } else if ((editActiveRow.product.ctrcolor > 0 && inputValueColor != 'Color') && (editActiveRow.product.ctrsize < 1)) {
          if (!requireData && vestValue.length <= 0) {
            setOpenConfirmDialog(true)
          } else {
            addVestToArrayProduct()
          }
        } else if ((editActiveRow.product.ctrcolor < 1) && (editActiveRow.product.ctrsize > 0 && inputValueSize != 'Talla')) {
          if (!requireData && vestValue.length <= 0) {
            setOpenConfirmDialog(true)
          } else {
            addVestToArrayProduct()
          }
        } else {
          setOpenError(true)
        }
      }
    }

    const addVestToArray = () => {
      if (!editActiveRow.vestConfigurations) {
        editActiveRow.vestConfigurations = []
      }

      if (valueVestToAdd < 1 || valueVestToAdd > editActiveRow.quantity) {
        editActiveRow.vestConfigurations.push({
          "color": editActiveRow.information.ctrcolor && inputValueColor,
          'size': editActiveRow.information.ctrsize && inputValueSize,
          "type": inputValueTypeVest,
          "datos": vestValue,
          "colorStitch": colorStitch
        })
      } else {
        for (let i = 0; i < valueVestToAdd; i++) {
          editActiveRow.vestConfigurations.push({
            "color": editActiveRow.information.ctrcolor && inputValueColor,
            'size': editActiveRow.information.ctrsize && inputValueSize,
            "type": inputValueTypeVest,
            "datos": vestValue,
            "colorStitch": colorStitch
          })
        }
      }

      setValue(1)
    }

    const addVestToArrayProduct = () => {
      if (!editActiveRow.vestConfigurations) {
        editActiveRow.vestConfigurations = []
      }

      editActiveRow.vestConfigurations.push({
        "color": editActiveRow.product.ctrcolor && inputValueColor,
        'size': editActiveRow.product.ctrsize && inputValueSize,
        "type": inputValueTypeVest,
        "datos": vestValue,
        "colorStitch": colorStitch
      })

      setValue(1)
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >

        <p style={{ fontFamily: 'Arial', textAlign: 'center', marginTop: '20px' }}>Chalecos por Configurar: {(editActiveRow && editActiveRow.quantity ? editActiveRow.quantity : editActiveRow.productQuantity) - (editActiveRow.vestConfigurations ? editActiveRow.vestConfigurations.length : 0)}</p>

        <Dialog
          open={openConfirmDialog}
          onClose={() => { setOpenConfirmDialog(true) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Chaleco sin Datos"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vas a agregar un chaleco sin datos para el Bordado/Marcado
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setOpenConfirmDialog(false) }} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => { editActiveRow.quantity ? addVestToArray() : addVestToArrayProduct() }} color="primary" autoFocus>
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={openError} autoHideDuration={6000} onClose={() => { setOpenError(false) }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={() => { setOpenError(false) }} severity="error">
            Ingresa los Campos Requeridos
          </Alert>
        </Snackbar>

        {value === index && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton>
                <RemoveIcon style={{
                  fontFamily: 'Arial',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '30px',
                  color: '#000000',
                  background: '#D9D9D9',
                  borderRadius: '50%'
                }} onClick={() => {
                  if (valueVestToAdd > 1) {
                    const newValue = valueVestToAdd - 1
                    setValueVestToAdd(parseInt(newValue))
                  }
                }} />
              </IconButton>
              <input
                ref={vestRef}
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '20px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: '#000000'
                }}
                minLength={1}
                defaultValue={valueVestToAdd}
                max={(editActiveRow && editActiveRow.quantity) - (editActiveRow.vestConfigurations ? editActiveRow.vestConfigurations.length : 0)}
                onBlur={(e) => {
                  if (e.target.value <= (editActiveRow && editActiveRow.quantity) - (editActiveRow.vestConfigurations ? editActiveRow.vestConfigurations.length : 0)) {
                    setValueVestToAdd(parseInt(e.target.value))
                  }
                  else {
                    setValueVestToAdd(parseInt((editActiveRow && editActiveRow.quantity) - (editActiveRow.vestConfigurations ? editActiveRow.vestConfigurations.length : 0)))
                  }
                }}
                type="number"
                name="name"
              /><IconButton>
                <AddIcon style={{
                  fontFamily: 'Arial',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '30px',
                  color: '#000000',
                  background: '#D9D9D9',
                  borderRadius: '50%'
                }} onClick={(e) => {
                  if (valueVestToAdd < (editActiveRow && editActiveRow.quantity) - (editActiveRow.vestConfigurations ? editActiveRow.vestConfigurations.length : 0)) {
                    const newValue = valueVestToAdd + 1
                    setValueVestToAdd(parseInt(newValue))
                  }
                }} />
              </IconButton>
            </div>
            {
              ((editActiveRow.information && editActiveRow.information.ctrcolor > 0) || (editActiveRow.product && editActiveRow.product.ctrcolor > 0)) &&
              <div style={{ marginTop: '20px', display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                <Autocomplete
                  value={valueColor}
                  onChange={(event, newValue) => { setValueColor(newValue) }}
                  inputValue={inputValueColor}
                  onInputChange={(event, newInputValue) => { handleInputValueColor(newInputValue) }}
                  id="controllable-states-demo"
                  options={colorList}
                  getOptionLabel={(option) => option ? `${option.name}` : 'Color'}
                  style={{ width: '100%' }}
                  renderOption={(option) => {
                    console.log(option)
                    return (
                      <React.Fragment>
                        {option.url &&
                          <img src={`https://asistenteapi.tsi.mx:8080/images/${option.url}`} alt="Logo" style={{ width: '100px', height: '50px' }} />
                        }
                        <p style={{ marginLeft: '10px' }}>{option.name}</p>
                        <p style={{ marginLeft: '10px' }}>{`(${option.code})`}</p>

                      </React.Fragment>
                    )
                  }}
                  renderInput={(params) => <TextField {...params} label="Color" variant="outlined" />}
                />
              </div>
            }

            {
              ((editActiveRow.information && editActiveRow.information.ctrsize > 0) || (editActiveRow.product && editActiveRow.product.ctrsize > 0)) &&
              <div style={{ marginTop: '20px', display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                <Autocomplete
                  value={valueSize}
                  onChange={(event, newValue) => { setValueSize(newValue) }}
                  inputValue={inputValueSize}
                  onInputChange={(event, newInputValue) => { handleInputValueSize(newInputValue) }}
                  id="controllable-states-demo"
                  options={sizeList}
                  getOptionLabel={(option) => option ? `${option.name}` : 'Talla'}
                  style={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} label="Talla" variant="outlined" />}
                />
              </div>
            }
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <p style={{ fontFamily: 'Arial' }}>No Requiere Bordado</p>
              <Switch
                checked={requireData}
                onChange={() => { setRequireData(!requireData) }}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            {!requireData &&
              <>
                <div style={{ marginTop: '20px', display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Autocomplete
                    value={valueTypeVest}
                    onChange={(event, newValue) => { setValueTypeVest(newValue) }}
                    inputValue={inputValueTypeVest}
                    onInputChange={(event, newInputValue) => { handleInputValueTypeVest(newInputValue) }}
                    id="controllable-states-demo"
                    options={typeVest}
                    getOptionLabel={(option) => option ? `${option}` : 'Bordado / marcado'}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label="Bordado / marcado" variant="outlined" />}
                  />
                </div>
                <TextField
                  id="outlined-basic"
                  label="Datos *" variant="outlined" style={{ width: '100%', marginTop: 20 }} defaultValue={vestValue} onChange={(e) => { setVestValue(e.target.value) }} />
                <p style={{ fontFamily: 'arial', fontSize: '12px', marginTop: 10, alignSelf: 'start' }}>Max. 31 caracteres</p>
                <TextField
                  id="outlined-basic"
                  label="Color de Hilo"
                  variant="outlined"
                  style={{ width: '100%', marginTop: 20 }}
                  defaultValue={colorStitch}
                  onBlur={(e) => { setColorStitch(e.target.value) }}
                />

              </>
            }
            <button className="btn" style={{ width: 220, alignSelf: 'end', marginTop: 20 }} onClick={addToConfiguration}>Agregar</button>
          </div>
        )}
      </div>
    );
  }

  function TabPanel2(props) {
    const { children, value, index, ...other } = props;

    const groupedArray = agruparPorCamposIguales(editActiveRow.vestConfigurations || [])

    function agruparPorCamposIguales(arr) {
      const grupos = {};

      arr.forEach((item) => {
        const { color, size, type, datos } = item;
        const clave = `${color}-${size}-${type}-${datos}`;

        if (grupos[clave]) {
          grupos[clave].quantity++;
        } else {
          grupos[clave] = { ...item, quantity: 1 };
        }
      });

      return Object.values(grupos);
    }

    function getColorByName(name) {
      return colorList.find(color => color.name === name);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontFamily: 'Arial', textAlign: 'center', marginTop: '20px' }}>Chalecos Configurados: {editActiveRow.vestConfigurations && editActiveRow.vestConfigurations.length}</p>
            {editActiveRow.vestConfigurations && groupedArray.map((vest, index) => {
              const colorSelected = getColorByName(vest.color)
              return (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', background: '#e5e9f2', borderRadius: 10, marginTop: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 10, width: '80%' }}>
                    {
                      vest.color ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>COLOR: {(vest.color).toUpperCase()} - {(colorSelected.code)}</p> : null
                    }
                    {
                      vest.size ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>TALLA: {(vest.size).toUpperCase()}</p> : null
                    }
                    {
                      vest.datos ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>{(vest.type).toUpperCase()}: {vest.datos}</p> : null
                    }
                    {
                      vest.quantity ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>CANTIDAD: {vest.quantity}</p> : null
                    }
                    {
                      vest.colorStitch ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>COLOR BORDADO: {vest.colorStitch}</p> : null
                    }
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <DeleteIcon style={{ color: 'red' }} onClick={() => handleRemoveVest(vest)} />
                  </div>

                </div>
              )
            })}

            {/*  <div style={{ display: 'flex', flexDirection: 'column' }}>
              <button className="btn" style={{ width: 220, alignSelf: 'center', marginTop: 50 }}>Actualizar</button>
            </div> */}


          </div>
        )}
      </div>
    );
  }

  function TabPanel2View(props) {
    const { children, value, index, ...other } = props;

    const groupedArray = agruparPorCamposIguales(editActiveRow.vestConfigurations || [])

    function agruparPorCamposIguales(arr) {
      const grupos = {};

      arr.forEach((item) => {
        const { color, size, type, datos } = item;
        const clave = `${color}-${size}-${type}-${datos}`;

        if (grupos[clave]) {
          grupos[clave].quantity++;
        } else {
          grupos[clave] = { ...item, quantity: 1 };
        }
      });

      return Object.values(grupos);
    }

    function getColorByName(name) {
      return colorList.find(color => color.name === name);
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontFamily: 'Arial', textAlign: 'center', marginTop: '20px' }}>Chalecos Configurados: {editActiveRow.vestConfigurations && editActiveRow.vestConfigurations.length}</p>
            {editActiveRow.vestConfigurations && groupedArray.map((vest, index) => {
              const colorSelected = getColorByName(vest.color)
              console.log(vest)
              return (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', background: '#e5e9f2', borderRadius: 10, marginTop: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 10, width: '80%' }}>
                    {
                      vest.color ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>COLOR: {(vest.color).toUpperCase()} - {(colorSelected.code)}</p> : null
                    }
                    {
                      vest.size ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>TALLA: {(vest.size).toUpperCase()}</p> : null
                    }
                    {
                      vest.datos ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>{(vest.type).toUpperCase()}: {vest.datos}</p> : null
                    }
                    {
                      vest.quantity ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>CANTIDAD: {vest.quantity}</p> : null
                    }
                    {
                      vest.colorStitch ? <p style={{ fontFamily: 'arial', fontSize: '13px', marginTop: 10, alignSelf: 'start' }}>COLOR BORDADO: {vest.colorStitch}</p> : null
                    }
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    );
  }

  const [simpleEditDrawer, setSimpleEditDrawer] = React.useState(false);

  const [editActiveRow, setEditActiveRow] = useState()
  const [generalIndex, setGeneralIndex] = useState()
  const [innerIndex, setInnerIndex] = useState()

  const handleOpenSimpleEditDrawer = (row, general, inner) => {
    setGeneralIndex(general)
    setInnerIndex(inner)

    setEditActiveRow(row)
    setCustomPrice(row.productPrice)
    setCustomQuantity(row.productQuantity || row.quantity)
    setCustomDisccount(row.discountPercentage)
    setSimpleEditDrawer(true);
  };

  const handleOpenEditDrawer = (row, general, inner) => {
    setGeneralIndex(general)
    setInnerIndex(inner)

    setEditActiveRow(row)
    setCustomPrice(row.productPrice)
    setCustomQuantity(row.productQuantity || row.quantity)
    setCustomDisccount(row.discountPercentage)
    setEditDrawer(true);
  };

  const handleOpenEditDrawerView = (row, general, inner) => {
    setGeneralIndex(general)
    setInnerIndex(inner)

    setEditActiveRow(row)
    setCustomPrice(row.productPrice)
    setCustomQuantity(row.productQuantity || row.quantity)
    setCustomDisccount(row.discountPercentage)
    setEditDrawerView(true);
  };

  const handleCloseSimpleEditDrawer = () => {
    setSimpleEditDrawer(false);
  };

  const names = [
    'Excel',
    'PDF'
  ];

  const [personName, setPersonName] = React.useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeChip = (event) => {
    setPersonName(event.target.value);
  };

  const [valueClientStockNew, setValueClientStockNew] = React.useState('new');

  const handleChangeStockNew = (event) => {
    setValueClientStockNew(event.target.value);
  };

  const handleSelectfromTable = (event, type, indexGeneral, indexInter) => {
    const newShoppingCart = [...shoppingCart]

    switch (type) {
      case 'producto':
        newShoppingCart[indexGeneral].selected = event.target.checked
        break;
      case 'cotizacion':
        newShoppingCart[indexGeneral].information.quotationItems[indexInter].selected = event.target.checked
        break;
      case 'paquete':
        newShoppingCart[indexGeneral].information.configuration.items[indexInter].selected = event.target.checked
        break;
      case 'paqueteGeneral':
        const { information: { configuration: { items } } } = newShoppingCart[indexGeneral]
        newShoppingCart[indexGeneral].selected = event.target.checked
        newShoppingCart[indexGeneral].information.configuration.items = items.map((item) => ({ ...item, selected: event.target.checked }))
        break;
      case 'cotizacionGeneral':
        const { information: { quotationItems } } = newShoppingCart[indexGeneral]
        newShoppingCart[indexGeneral].selected = event.target.checked
        newShoppingCart[indexGeneral].information.quotationItems = quotationItems.map((item) => ({ ...item, selected: event.target.checked }))
        break;
      default:
        break;
    }

    setShoppingCart(newShoppingCart)
  }

  /* SECCION ROWS COLLAPSE */

  function RowCotizacion(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickInner = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    let totalPrice = 0

    row.information.quotationItems.forEach(element => totalPrice = totalPrice + parseFloat(element.total));
    {/* <MoreVertIcon onClick={handleClickInner} /> */ }
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {columns.map((column) => {
            return (
              <TableCell key={column.id} align={column.align} style={{ background: '#f9f9f9', width: column.width }}>
                {
                  column.id == 'select' ? <Checkbox checked={row.selected} style={{ color: 'black' }} onChange={(event) => { handleSelectfromTable(event, 'cotizacionGeneral', index) }} /> :
                    column.id == 'producto' ? <text style={{
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#484F59'
                    }}>{`Cotización guardada - ${row.information.institutionName}`}</text> :
                      column.id == 'icon' ?
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                          <IconButton onClick={() => {
                            setOpen(!open)
                            const newShoppingCart = [...shoppingCart]
                            newShoppingCart[index].open = !newShoppingCart[index].open
                            setShoppingCart(newShoppingCart)
                          }}>
                            {shoppingCart[index].open ?
                              <KeyboardArrowUpIcon style={{ color: 'black', borderRadius: 20 }} />
                              :
                              <KeyboardArrowDownIcon style={{ color: 'black', borderRadius: 20 }} />
                            }
                          </IconButton>
                        </div> :
                        column.id == 'total' ? formatMoney(parseFloat(totalPrice)) :
                          ' '
                }
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell colSpan={9}>
            <Collapse in={row.open || open} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  {row.information.quotationItems.map((historyRow, indexRow) => {
                    return (
                      <TableRow key={historyRow.date}>
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} style={{ width: column.width }} align={(column.id == 'total' || column.id == 'icon') ? 'right' : 'center'}>
                              {column.id == 'select' ? <Checkbox checked={historyRow.selected} onChange={(event) => { handleSelectfromTable(event, 'cotizacion', index, indexRow) }} style={{ color: 'black', justifyContent: 'center' }} /> :
                                column.id == 'id' ? historyRow.product.productCode :
                                  column.id == 'producto' ? historyRow.product.productDescription :
                                    column.id == 'cantidad' ?
                                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                        <IconButton onClick={() => { addToShoppingCart(row, 'cotizacion', indexRow) }}>
                                          <AddIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} />
                                        </IconButton>
                                        <div style={{
                                          fontFamily: 'Roboto',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: '20px',
                                          color: '#000000'
                                        }}>{historyRow.productQuantity}</div>
                                        <IconButton onClick={() => { removeFromShoppingCart(row, 'cotizacion', indexRow) }}>
                                          {
                                            row.quantity == 0 ?
                                              <DeleteForeverIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} /> :
                                              <RemoveIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} />
                                          }
                                        </IconButton>
                                      </div> :
                                      column.id == 'precio' ? (formatMoney(parseFloat(historyRow.productPrice)) || '0') :
                                        column.id == 'descuento' ? (`${historyRow.discountPercentage}%` || '0%') :
                                          column.id == 'total' ? formatMoney(parseFloat(historyRow.total)) || formatMoney(0) :
                                            column.id == 'icon' ?
                                              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                {
                                                  historyRow.product.ctrcolor > 0 || historyRow.product.ctrsize > 0 ?
                                                    <IconButton>
                                                      <EditIcon onClick={() => { handleOpenEditDrawer(historyRow, index, indexRow) }} />
                                                    </IconButton> :
                                                    <IconButton>
                                                      <EditIcon onClick={() => { handleOpenSimpleEditDrawer(historyRow, index, indexRow) }} />
                                                    </IconButton>
                                                }
                                              </div> :
                                              ' '
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            handleOpenPriceList(row)
            setAnchorEl(null)
          }}>
            <MenuItem>
              <Typography variant="inherit">MODIFICAR POR LISTA DE PRECIOS</Typography>
            </MenuItem>
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
            <MenuItem>
              <Typography variant="inherit">MODIFICAR MANUALMENTE</Typography>
            </MenuItem>
          </MenuItem> */}
        </Menu>
      </React.Fragment >
    );
  }

  function RowProductos(props) {
    const { row, index } = props;
    const classes = useRowStyles();
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {columns.map((column) => {
            return (
              <TableCell key={column.id} align={column.align}>
                {column.id == 'select' ? <Checkbox checked={row.selected} onChange={(event) => { handleSelectfromTable(event, 'producto', index) }} style={{ color: 'black' }} /> :
                  column.id == 'id' ? row.information.productCode :
                    column.id == 'producto' ? row.information.productDescription :
                      column.id == 'cantidad' ?
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                          <IconButton onClick={() => { addToShoppingCart(row, 'producto') }}>
                            <AddIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} />
                          </IconButton>
                          <div style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#000000'
                          }}>{row.quantity}</div>
                          <IconButton onClick={() => { removeFromShoppingCart(row, 'producto') }}>
                            {
                              row.quantity == 0 ?
                                <DeleteForeverIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} /> :
                                <RemoveIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} />
                            }
                          </IconButton>
                        </div> :
                        column.id == 'precio' ? (formatMoney(parseFloat(row.productPrice)) || formatMoney(0)) :
                          column.id == 'descuento' ? (`${row.discountPercentage}%` || '0%') :
                            column.id == 'total' ? formatMoney(parseFloat(row.total)) || '0' :
                              column.id == 'icon' ?
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                  {
                                    row.information.ctrcolor > 0 || row.information.ctrsize > 0 ?
                                      <IconButton>
                                        <EditIcon onClick={() => { handleOpenEditDrawer(row, index) }} />
                                      </IconButton> :
                                      <IconButton>
                                        <EditIcon onClick={() => { handleOpenSimpleEditDrawer(row, index) }} />
                                      </IconButton>
                                  }
                                </div> :
                                ' '
                }
              </TableCell>
            );
          })}
        </TableRow>
      </React.Fragment>
    );
  }

  function RowPaquetes(props) {
    const { row, index } = props;
    const [openInnerPaquetes, setOpenInnerPaquetes] = React.useState(false);
    const classes = useRowStyles();
    let totalPrice = 0
    row.information.configuration.items.forEach(element => totalPrice = totalPrice + parseFloat(element.total));

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {columns.map((column) => {
            return (
              <TableCell key={column.id} align={'center'} style={{ background: '#f9f9f9' }}>
                {
                  column.id == 'select' ? <Checkbox checked={row.selected} style={{ color: 'black' }} onChange={(event) => { handleSelectfromTable(event, 'paqueteGeneral', index) }} /> :
                    column.id == 'producto' ? <text style={{
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#484F59'
                    }}>
                      {`Paquete ${row.bundleInformation.productDescription} - ${row.information.configuration.name}`}
                    </text> :
                      column.id == 'icon' ?
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                          <IconButton onClick={() => {
                            setOpenInnerPaquetes(!openInnerPaquetes)
                            const newShoppingCart = [...shoppingCart]
                            newShoppingCart[index].open = !newShoppingCart[index].open
                            setShoppingCart(newShoppingCart)
                          }} >
                            {shoppingCart[index].open ?
                              <KeyboardArrowUpIcon style={{ color: 'black', borderRadius: 20 }} />
                              :
                              <KeyboardArrowDownIcon style={{ color: 'black', borderRadius: 20 }} />
                            }
                          </IconButton>
                        </div> :
                        column.id == 'total' ? formatMoney(parseFloat(totalPrice)) :
                          ' '
                }
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell colSpan={8} >
            <Collapse in={row.open || openInnerPaquetes} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.information.configuration.items.map((historyRow, rowIndex) => {
                    return (
                      <TableRow key={historyRow.date}>
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} align={(column.id == 'total' || column.id == 'icon') ? 'right' : 'center'} style={{ background: '#f9f9f9', width: column.width }}>
                              {column.id == 'select' ? <Checkbox checked={historyRow.selected} onChange={(event) => { handleSelectfromTable(event, 'paquete', index, rowIndex) }} style={{ color: 'black' }} /> :
                                column.id == 'id' ? historyRow.product.productCode :
                                  column.id == 'producto' ? historyRow.product.productDescription :
                                    column.id == 'cantidad' ?
                                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                        <IconButton onClick={() => { addToShoppingCart(row, 'paquete', rowIndex) }}>
                                          <AddIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} />
                                        </IconButton>
                                        <div style={{
                                          fontFamily: 'Roboto',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: '20px',
                                          lineHeight: '24px',
                                          color: '#000000'
                                        }}>{historyRow.productQuantity}</div>
                                        <IconButton onClick={() => { removeFromShoppingCart(row, 'paquete', rowIndex) }}>
                                          {
                                            historyRow.productQuantity == 0 ?
                                              <DeleteForeverIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} /> :
                                              <RemoveIcon style={{ color: 'black', background: '#d9d9d9', borderRadius: 20 }} />
                                          }
                                        </IconButton>
                                      </div> :
                                      column.id == 'precio' ? (formatMoney(parseFloat(historyRow.productPrice)) || formatMoney(0)) :
                                        column.id == 'descuento' ? (`${historyRow.discountPercentage}%` || '0%') :
                                          column.id == 'total' ? formatMoney(parseFloat(historyRow.total)) || formatMoney(0) :
                                            column.id == 'icon' ?
                                              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                                {
                                                  historyRow.product.ctrcolor > 0 || historyRow.product.ctrsize > 0 ?
                                                    <IconButton>
                                                      <EditIcon onClick={() => { handleOpenEditDrawer(historyRow, index, rowIndex) }} />
                                                    </IconButton> :
                                                    <IconButton>
                                                      <EditIcon onClick={() => { handleOpenSimpleEditDrawer(historyRow, index, rowIndex) }} />
                                                    </IconButton>
                                                }
                                              </div> :
                                              ' '
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  /* SECCION ROWS COLLAPSE Final Table */

  function RowCotizacionNoEdit(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
      setAnchorEl(null);
    };

    let totalPrice = 0

    row.information.quotationItems.forEach(element => totalPrice = totalPrice + parseFloat(element.total));
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {columns.map((column) => {
            return (
              <TableCell key={column.id} align={column.align} style={{ background: '#f9f9f9', width: column.width }}>
                {
                  column.id == 'producto' ? <text style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>{`Cotización guardada - ${row.information.institutionName}`}</text> :
                    column.id == 'icon' ?
                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <IconButton onClick={() => {
                          setOpen(!open)
                          const newShoppingCart = [...shoppingCart]
                          newShoppingCart[index].open = !newShoppingCart[index].open
                          setShoppingCart(newShoppingCart)
                        }}>
                          {shoppingCart[index].open ?
                            <KeyboardArrowUpIcon style={{ color: 'black', borderRadius: 20 }} />
                            :
                            <KeyboardArrowDownIcon style={{ color: 'black', borderRadius: 20 }} />
                          }
                        </IconButton>
                      </div> :
                      column.id == 'total' ? formatMoney(parseFloat(totalPrice)) :
                        ' '
                }
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell colSpan={9}>
            <Collapse in={row.open || open} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  {row.information.quotationItems.map((historyRow, indexRow) => {
                    return (
                      <TableRow key={historyRow.date}>
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} style={{ width: column.width }} align={(column.id == 'total' || column.id == 'icon') ? 'right' : 'center'}>
                              {
                                column.id == 'id' ? historyRow.product.productCode :
                                  column.id == 'producto' ? historyRow.product.productDescription :
                                    column.id == 'cantidad' ?
                                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                        <div style={{
                                          fontFamily: 'Roboto',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: '20px',
                                          color: '#000000'
                                        }}>{historyRow.productQuantity}</div>
                                      </div> :
                                      column.id == 'precio' ? (formatMoney(parseFloat(historyRow.productPrice)) || '0') :
                                        column.id == 'descuento' ? (`${historyRow.discountPercentage}%` || '0%') :
                                          column.id == 'total' ? formatMoney(parseFloat(historyRow.total)) || formatMoney(0) :
                                            column.id == 'icon' ?
                                              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                {
                                                  (historyRow.product.ctrcolor > 0 || historyRow.product.ctrsize > 0) &&
                                                  <IconButton>
                                                    <VisibilityIcon onClick={() => { handleOpenEditDrawerView(historyRow, index, indexRow) }} />
                                                  </IconButton>
                                                }
                                              </div> :
                                              ' '
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            handleOpenPriceList(row)
            setAnchorEl(null)
          }}>
            <MenuItem>
              <Typography variant="inherit">MODIFICAR POR LISTA DE PRECIOS</Typography>
            </MenuItem>
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
            <MenuItem>
              <Typography variant="inherit">MODIFICAR MANUALMENTE</Typography>
            </MenuItem>
          </MenuItem> */}
        </Menu>
      </React.Fragment >
    );
  }

  function RowProductosNoEdit(props) {
    const { row, index } = props;
    const classes = useRowStyles();
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {columns.map((column) => {
            return (
              <TableCell key={column.id} align={column.align}>
                {
                  column.id == 'id' ? row.information.productCode :
                    column.id == 'producto' ? row.information.productDescription :
                      column.id == 'cantidad' ?
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                          <div style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#000000'
                          }}>{row.quantity}</div>
                        </div> :
                        column.id == 'precio' ? (formatMoney(parseFloat(row.productPrice)) || formatMoney(0)) :
                          column.id == 'descuento' ? (`${row.discountPercentage}%` || '0%') :
                            column.id == 'total' ? formatMoney(parseFloat(row.total)) || '0' :
                              column.id == 'icon' ?
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                  {
                                    (row.information.ctrcolor > 0 || row.information.ctrsize > 0) &&
                                    <IconButton>
                                      <VisibilityIcon onClick={() => { handleOpenEditDrawerView(row, index) }} />
                                    </IconButton>
                                  }
                                </div> :
                                ' '
                }
              </TableCell>
            );
          })}
        </TableRow>
      </React.Fragment>
    );
  }

  function RowPaquetesNoEdit(props) {
    const { row, index } = props;
    const [openInnerPaquetes, setOpenInnerPaquetes] = React.useState(false);
    const classes = useRowStyles();
    let totalPrice = 0
    row.information.configuration.items.forEach(element => totalPrice = totalPrice + parseFloat(element.total));

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {columns.map((column) => {
            return (
              <TableCell key={column.id} align={'center'} style={{ background: '#f9f9f9' }}>
                {
                  column.id == 'producto' ? <text style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>
                    {`Paquete ${row.bundleInformation.productDescription} - ${row.information.configuration.name}`}
                  </text> :
                    column.id == 'icon' ?
                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <IconButton onClick={() => {
                          setOpenInnerPaquetes(!openInnerPaquetes)
                          const newShoppingCart = [...shoppingCart]
                          newShoppingCart[index].open = !newShoppingCart[index].open
                          setShoppingCart(newShoppingCart)
                        }} >
                          {shoppingCart[index].open ?
                            <KeyboardArrowUpIcon style={{ color: 'black', borderRadius: 20 }} />
                            :
                            <KeyboardArrowDownIcon style={{ color: 'black', borderRadius: 20 }} />
                          }
                        </IconButton>
                      </div> :
                      column.id == 'total' ? formatMoney(parseFloat(totalPrice)) :
                        ' '
                }
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell colSpan={8} >
            <Collapse in={row.open || openInnerPaquetes} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.information.configuration.items.map((historyRow, rowIndex) => {
                    return (
                      <TableRow key={historyRow.date}>
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} align={(column.id == 'total' || column.id == 'icon') ? 'right' : 'center'} style={{ background: '#f9f9f9', width: column.width }}>
                              {
                                column.id == 'id' ? historyRow.product.productCode :
                                  column.id == 'producto' ? historyRow.product.productDescription :
                                    column.id == 'cantidad' ?
                                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                        <div style={{
                                          fontFamily: 'Roboto',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: '20px',
                                          lineHeight: '24px',
                                          color: '#000000'
                                        }}>{historyRow.productQuantity}</div>
                                      </div> :
                                      column.id == 'precio' ? (formatMoney(parseFloat(historyRow.productPrice)) || formatMoney(0)) :
                                        column.id == 'descuento' ? (`${historyRow.discountPercentage}%` || '0%') :
                                          column.id == 'total' ? formatMoney(parseFloat(historyRow.total)) || formatMoney(0) :
                                            column.id == 'icon' ?
                                              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                                {
                                                  (historyRow.product.ctrcolor > 0 || historyRow.product.ctrsize > 0) &&
                                                  <IconButton>
                                                    <VisibilityIcon onClick={() => { handleOpenEditDrawerView(historyRow, index, rowIndex) }} />
                                                  </IconButton>
                                                }
                                              </div> :
                                              ' '
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  /* SELECT GENERAL */
  const [allSelected, setAllSelected] = useState(false);

  function selectAll() {
    setAllSelected(!allSelected)

    shoppingCart.forEach((item) => {
      item.selected = !allSelected;

      if (item.type === 'cotizacion') {
        item.information.quotationItems.forEach((quoteItem) => {
          quoteItem.selected = !allSelected;
        });
      } else if (item.type === 'paquete') {
        item.information.configuration.items.forEach((paqueteItem) => {
          paqueteItem.selected = !allSelected;
        });
      }
    });

    setShoppingCart([...shoppingCart]);
  }

  function formatMoney(number) {
    return number ? number.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) : 0;
  }

  const [valueColor, setValueColor] = React.useState('');
  const [inputValueColor, setInputValueColor] = React.useState('');

  const handleInputValueColor = (value) => {
    setInputValueColor(value)
  }

  const [valueSize, setValueSize] = React.useState('');
  const [colorStitch, setColorStitch] = React.useState('');

  const [inputValueSize, setInputValueSize] = React.useState('');

  const handleInputValueSize = (value) => {
    setInputValueSize(value)
  }

  const [valueTypeVest, setValueTypeVest] = React.useState('');
  const [inputValueTypeVest, setInputValueTypeVest] = React.useState('');

  const handleInputValueTypeVest = (value) => {
    setInputValueTypeVest(value)
  }

  const [valueRegimen, setValueRegimen] = React.useState('');
  const [inputValueRegimen, setInputValueRegimen] = React.useState('');

  const handleInputValueRegimen = (value) => {
    setInputValueRegimen(value)
  }

  const [valueCFDI, setValueCFDI] = React.useState('');
  const [inputValueCFDI, setInputValueCFDI] = React.useState('');

  const handleInputValueCFDI = (value) => {
    setInputValueCFDI(value)
  }

  const [valueContact, setValueContact] = React.useState('');
  const [inputValueContact, setInputValueContact] = React.useState('');

  const handleInputValueContact = (value) => {
    setInputValueContact(value)
  }

  const [valueHospital, setValueHospital] = React.useState('');
  const [inputValueHospital, setInputValueHospital] = React.useState('');

  const handleInputValueHospital = (value) => {
    setInputValueHospital(value)
  }

  const [valuePatient, setValuePatient] = React.useState('');
  const [inputValuePatient, setInputValuePatient] = React.useState('');

  const handleInputValuePatient = (value) => {
    setInputValuePatient(value)
  }

  const [valueMedic, setValueMedic] = React.useState('');
  const [inputValueMedic, setInputValueMedic] = React.useState('');

  const handleInputValueMedic = (value) => {
    setInputValueMedic(value)
  }

  const [loading, setLoading] = React.useState(false);

  const [customPrice, setCustomPrice] = useState()
  const [customQuantity, setCustomQuantity] = useState()
  const [customDisccount, setCustomDisccount] = useState()
  const [selectCustomDisscount, setSelectCustomDisscount] = useState(5)

  //Function to add discount to selected items

  const handleDiscountOfSelected = () => {
    shoppingCart.forEach((item, index) => {
      if (item.type === 'producto' && item.selected) shoppingCart[index].discountPercentage = selectCustomDisscount || 0
      if (item.type === 'cotizacion') {
        item.information.quotationItems.forEach((quoteItems, indexCot) => {
          if (quoteItems.selected) shoppingCart[index].information.quotationItems[indexCot].discountPercentage = selectCustomDisscount || 0
        });
      }
      if (item.type === 'paquete') {
        item.information.configuration.items.forEach((paqueteItem, indexPaq) => {
          if (paqueteItem.selected) shoppingCart[index].information.configuration.items[indexPaq].discountPercentage = selectCustomDisscount || 0
        });
      }
    });

    calculateTotal();
    calculateTotalOfAll(shoppingCart);
    handleCloseDisc();
  };

  //Function to felete items from the Shopping Cart

  const deleteSelectedItems = (shoppingCart) => {
    const newShoppingCart = shoppingCart.map((item) => {
      if (item.type === 'producto' && item.selected) return null;
      else if (item.type === 'cotizacion') {
        const quotationItems = item.information.quotationItems.filter((quoteItem) => !quoteItem.selected);
        return quotationItems.every((item) => item === null)
          ? null
          : { ...item, information: { ...item.information, quotationItems } };
      } else if (item.type === 'paquete') {
        const packageItems = item.information.configuration.items.filter((packageItem) => !packageItem.selected);
        return packageItems.every((item) => item === null)
          ? null
          : { ...item, information: { ...item.information, configuration: { ...item.information.configuration, items: packageItems } } };
      }
      return item;
    });
    return newShoppingCart.filter(Boolean);
  };

  const handleDeleteSelected = () => {
    const filteredCart = deleteSelectedItems(shoppingCart);
    setShoppingCart(filteredCart);
    handleCloseDeleteSelected();
  };

  const isLetters = (str) => /^[A-Za-z]*$/.test(str);

  return (
    <div className={"quote_finished_c"}>

      <nav className='c-quote-navbar' style={{ opacity: 1 }}>
        <button className='quote-navbar-c-history'
          onClick={() => {
            { activeStep == 0 && props.history.push({ pathname: '/generateProductList', state: { shoppingCart } }) }
            { activeStep == 1 && setActiveStep(0) }
            { activeStep == 2 && setActiveStep(1) }
          }}>
          <img className='quote-navbar-history' src={icoHistory} alt="" />
        </button>
      </nav>

      <div className={"quote_completed_general_c"} style={{ marginTop: 40, paddingBottom: '200px' }}>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((singleStep, index) => (
              <Step key={singleStep.label} >
                <StepLabel onClick={handleStep(index)} >{singleStep.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <Snackbar open={openErrorVest} autoHideDuration={6000} onClose={() => { setOpenErrorVest(false) }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={() => { setOpenErrorVest(false) }} severity="error">
            Todos los chalecos no estan configurados
          </Alert>
        </Snackbar>

        {activeStep == 0 &&
          <div>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        width={column.width}
                        style={{ background: '#F8F9FA' }}
                      >
                        {column.id == 'icon' ? <MoreVertIcon onClick={handleClickEditRight} /> :
                          column.id == 'select' ? <Checkbox onChange={selectAll} style={{ color: 'black' }} /> :
                            column.label
                        }
                      </TableCell>
                    ))}

                  </TableRow>
                </TableHead>

                <TableBody>
                  {shoppingCart && shoppingCart.map((row, index) => {
                    if (row.type == 'cotizacion') {
                      return <RowCotizacion key={row.name} row={row} index={index} />
                    } else if (row.type == 'paquete') {
                      return <RowPaquetes key={row.name} row={row} index={index} />
                    } else {
                      return <RowProductos key={row.name} row={row} index={index} />
                    }
                  })}

                </TableBody>

                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={1}>Subtotal</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell align="center">{totalPrices ? formatMoney(parseFloat(totalPrices.subtotal)) : '0'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}>Impuesto</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell align="center">{totalPrices ? formatMoney(parseFloat(totalPrices.impuesto)) : '0'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}>Total</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell align="center">{totalPrices ? formatMoney(totalPrices.total) : '0'}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </div>
        }

        {activeStep == 1 &&
          <div style={{ width: '100%' }}>
            <div style={{
              width: '100%',
              height: '121px',
              background: '#E5E9F2',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'row',
            }}>

              <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{
                  fontFamily: 'Arial',
                  fontStyle: 'normal',
                  fontWeight: '800',
                  fontSize: '24px',
                  lineHeight: '20px',
                  color: '#000000',
                  marginLeft: 20
                }}>Datos del Cliente</p>
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#000000',
                  marginTop: 16,
                  marginLeft: 20
                }}>Ingresa los siguientes datos para seguir con el proceso</p>
              </div>
              <div style={{ width: '50%', display: 'flex' }}>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <text style={{
                      fontFamily: 'Arial',
                      fontStyle: 'normal',
                      fontWeight: 300,
                      fontSize: '24px',
                      lineHeight: '29px',
                      color: '#000000',
                      marginTop: 29
                    }}>¿Tu cliente es?</text>
                    <FormControl component="fieldset" >
                      <RadioGroup aria-label="gender" name="userStatus" value={valueClientStockNew} onChange={handleChangeStockNew} style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel value="new" control={<Radio style={{ color: valueClientStockNew == 'new' ? '#689f38' : '#bdbdbd' }} />} label="Nuevo" />
                        <FormControlLabel value="stock" control={<Radio style={{ color: valueClientStockNew == 'stock' ? '#689f38' : '#bdbdbd' }} />} label="Existente" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div style={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '16px',
                    lineHeight: '19px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                    color: '#000000',
                    marginRight: 20
                  }}>{`Fecha: ${moment().format('DD/MM/YYYY')}`}</p>
                  <TextField
                    id="orderNumberSale"
                    label={`Numero de orden de compra cliente ${checked ? '*' : ''}`}
                    variant="outlined"
                    style={{
                      width: '80%',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px',
                      marginRight: 20,
                      marginTop: 10,
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      fontSize: '16px',
                      lineHeight: '19px',
                      color: '#9F1717'
                    }}
                    type="number"
                    value={orderNumberSale}
                    onChange={(event) => {
                      setNumberOrderSale(event.target.value)
                      checkFields()
                    }}
                    error={checked ? orderNumberSaleStatus : false}
                    className={classes.input}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div style={{
                width: '100%',
                borderRadius: '10px',
                marginTop: 20,
                marginLeft: 20
              }}>

                {valueClientStockNew == 'new' &&
                  <div>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Nuevo cliente:</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="firstName" label="Primer nombre *" variant="outlined" style={{ width: '24%' }} defaultValue={newClientData.firstName} onChange={handleNewClientValues} error={newClientDataStatus.firstName} />
                      <TextField id="secondName" label="Segundo nombre" variant="outlined" style={{ width: '24%' }} defaultValue={newClientData.secondName} onChange={handleNewClientValues} />
                      <TextField id="lastName" label="Apellido paterno *" variant="outlined" style={{ width: '24%' }} defaultValue={newClientData.lastName} onChange={handleNewClientValues} error={newClientDataStatus.lastName} />
                      <TextField id="secondLastName" label="Apellido Materno" variant="outlined" style={{ width: '24%' }} defaultValue={newClientData.secondLastName} onChange={handleNewClientValues} />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField
                        id="phone"
                        label="Celular *"
                        className={classes.input}
                        variant="outlined"
                        style={{ width: '24%' }}
                        defaultValue={newClientData.phone}
                        onChange={handleNewClientValues}
                        type="number"
                        inputProps={{ maxLength: 10 }}
                        error={newClientDataStatus.phone}
                      />
                      <TextField
                        id="email"
                        label="Email *"
                        variant="outlined"
                        style={{ width: '24%' }}
                        defaultValue={newClientData.email}
                        onChange={handleNewClientValues}
                        type={'email'}
                        error={newClientDataStatus.email}
                      />
                      <TextField id="city" label="Ciudad" variant="outlined" style={{ width: '24%' }} defaultValue={newClientData.city} onChange={handleNewClientValues} />
                      <TextField id="state" label="Estado" variant="outlined" style={{ width: '24%' }} defaultValue={newClientData.state} onChange={handleNewClientValues} />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="comments" label="Comentario" variant="outlined" style={{ width: '99%' }} defaultValue={newClientData.comments} onChange={handleNewClientValues} />
                    </div>
                  </div>
                }

                {
                  valueClientStockNew == 'stock' &&
                  <div style={{
                    background: '#FAFAFA',
                    borderRadius: '10px'
                  }}>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{
                        fontFamily: 'Arial',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        fontSize: '20px',
                        lineHeight: '23px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000000'
                      }}>Ingresa el RFC o Razón Social para seleccionar tu cliente</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="outlined-basic"
                        label="RFC/Razón Social"
                        variant="outlined"
                        style={{ width: '32%' }}
                        onChange={handleChangeRFC}
                        value={clientSearchRFC}
                        error={facturationExistingDataStatus.rfcSelected}
                      />

                      <FormControl variant="outlined" style={{
                        width: '32%'
                        /* width: '329px',
                        height: '46px',
                        boxSizing: 'border-box',
                        background: '#FFFFFF',
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        borderRadius: '4px',
                        marginLeft: 80 */
                      }}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          labelWidth={'Razón Social *'}
                          value={selectedRFC}
                          onChange={(event) => { handleSelectRFC(event.target.value) }}
                          defaultValue='none'
                        /* style={{
                          height: '46px',
                        }} */
                        >
                          <MenuItem value="none" disabled>Razon Social *</MenuItem>
                          {
                            rfcResults && rfcResults.map((individualRFC) => (
                              <MenuItem value={individualRFC.id} key={individualRFC.id}>{individualRFC.clientName}</MenuItem>
                            ))
                          }

                        </Select>
                      </FormControl>

                      <TextField disabled id="outlined-basic" label="Número de Teléfono" variant="outlined" style={{ width: '32%' }} value={rfcInfo ? rfcInfo.phone : ''} type='number' />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField disabled id="outlined-basic" label="Correo Electrónico" variant="outlined" style={{ width: '32%' }} value={rfcInfo ? rfcInfo.email : ''} />
                    </div>
                  </div>
                }


                {/* Datos de Facturación */}
                {valueClientStockNew == 'stock' &&
                  <div style={{
                    background: '#FAFAFA',
                    borderRadius: '10px'
                  }}>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Facturación:</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="outlined-basic" label={`Razón Social ${checked ? '*' : ''}`} variant="outlined" style={{ width: '49%' }} value={rfcInfo ? rfcInfo.clientName : ''} disabled />
                      <TextField id="outlined-basic" label={`RFC ${checked ? '*' : ''}`} variant="outlined" style={{ width: '49%' }} value={rfcInfo ? rfcInfo.rfc : ''} disabled />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="outlined-basic" label={`Domicilio ${checked ? '*' : ''}`} variant="outlined" style={{ width: '79%' }} value={rfcInfo ? rfcInfo.address : ''} disabled />
                      <TextField
                        id="outlined-basic"
                        label={`Codigo Postal ${checked ? '*' : ''}`}
                        variant="outlined"
                        style={{ width: '19%' }}
                        value={rfcInfo ? rfcInfo.cp : ''}
                        disabled
                        type='number'
                        onWheel={event => event.currentTarget.blur()}
                      />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <Autocomplete
                        value={valueCFDI}
                        onChange={(event, newValue) => { setValueCFDI(newValue) }}
                        inputValue={inputValueCFDI}
                        onInputChange={(event, newInputValue) => { handleInputValueCFDI(newInputValue) }}
                        id="controllable-states-demo"
                        options={cfdiCatalog}
                        getOptionLabel={(option) => option ? `${option.cucName}` : `Uso de CFDI ${checked ? '*' : ''}`}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label={`CFDI ${checked ? '*' : ''}`} variant="outlined" error={checked ? facturationExistingDataStatus.cfdi : false} />}
                      />
                      <Autocomplete
                        value={valueRegimen}
                        onChange={(event, newValue) => { setValueRegimen(newValue) }}
                        inputValue={inputValueRegimen}
                        onInputChange={(event, newInputValue) => { handleInputValueRegimen(newInputValue) }}
                        id="controllable-states-demo"
                        options={regimenCatalog}
                        getOptionLabel={(option) => option ? `${option.crfName}` : `Regimen fiscal ${checked ? '*' : ''}`}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label={`Regimen fiscal ${checked ? '*' : ''}`} variant="outlined" error={checked ? facturationExistingDataStatus.regimen : false} />}
                      />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Condicion de pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="condicionPago"
                          label='condicionPago'
                          name='condicionPago'
                          id="condicionPago"
                          labelWidth={`Condicion de pago ${checked ? '*' : ''}`}
                          value={facturationExistingData.condicionPago}
                          onChange={handleFacturacioExistingValues}
                          defaultValue='none'
                          error={checked ? facturationExistingDataStatus.condicionPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Condicion de pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'30 Dias'}>30 Dias</MenuItem>
                          <MenuItem value={'60 Dias'}>60 Dias</MenuItem>
                          <MenuItem value={'90 Dias'}>90 Dias</MenuItem>
                          <MenuItem value={'120 Dias'}>120 Dias</MenuItem>
                          <MenuItem value={'Contado'}>Contado</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Metodo de pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="metodoPago"
                          label='metodoPago'
                          name='metodoPago'
                          id="metodoPago"
                          labelWidth={`Metodo de pago ${checked ? '*' : ''}`}
                          value={facturationExistingData.metodoPago}
                          onChange={handleFacturacioExistingValues}
                          defaultValue='none'
                          error={checked ? facturationExistingDataStatus.metodoPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Metodo de pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'Pago una solo exhibición (PUE)'}>Pago una sola exhibición (PUE)</MenuItem>
                          <MenuItem value={'Parcialidades (PPD)'}>Parcialidades (PPD)</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Forma de Pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="formaPago"
                          label='formaPago'
                          name='formaPago'
                          id="formaPago"
                          labelWidth={`Forma de Pago ${checked ? '*' : ''}`}
                          value={facturationExistingData.formaPago}
                          onChange={handleFacturacioExistingValues}
                          defaultValue='none'
                          error={checked ? facturationExistingDataStatus.formaPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Forma de Pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'Tarjeta'}>Tarjeta de crédito/débito</MenuItem>
                          <MenuItem value={'Transferencia'}>Transferencia</MenuItem>
                          <MenuItem value={'Cheque'}>Cheque</MenuItem>
                          <MenuItem value={'Deposito'}>Depósito</MenuItem>
                          <MenuItem value={'Efectivo'}>Efectivo</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="numeroPago" label="Número de recibo de pago" variant="outlined" style={{ width: '32%' }} defaultValue={facturationExistingData.numeroPago} onChange={handleFacturacioExistingValues} />
                    </div>
                  </div>
                }

                {valueClientStockNew == 'new' &&
                  <div style={{
                    background: '#FAFAFA',
                    borderRadius: '10px'
                  }}>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Facturación:</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField label={`Razón Social ${checked ? '*' : ''}`} id="razonSocial" variant="outlined" style={{ width: '49%' }} defaultValue={facturationData.razonSocial} onChange={handleFacturacionValues} error={checked ? facturationDataStatus.razonSocial : false} />
                      <TextField label={`RFC ${checked ? '*' : ''}`} id='rfc' variant="outlined" style={{ width: '49%' }} defaultValue={facturationData.rfc} onChange={handleFacturacionValues} error={checked ? facturationDataStatus.rfc : false} />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField label={`Domicilio ${checked ? '*' : ''}`} id='domicilio' variant="outlined" style={{ width: '79%' }} defaultValue={facturationData.domicilio} onChange={handleFacturacionValues} error={checked ? facturationDataStatus.domicilio : false} />
                      <TextField
                        label={`Codigo Postal ${checked ? '*' : ''}`}
                        id='cp'
                        variant="outlined"
                        style={{ width: '19%' }}
                        defaultValue={facturationData.cp}
                        onChange={handleFacturacionValues}
                        type='number'
                        error={checked ? facturationDataStatus.cp : false}
                        className={classes.input}
                      />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <Autocomplete
                        value={valueCFDI}
                        onChange={(event, newValue) => { setValueCFDI(newValue) }}
                        inputValue={inputValueCFDI}
                        onInputChange={(event, newInputValue) => { handleInputValueCFDI(newInputValue) }}
                        id="controllable-states-demo"
                        options={cfdiCatalog}
                        getOptionLabel={(option) => option ? `${option.cucName}` : `Uso de CFDI ${checked ? '*' : ''}`}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label={`CFDI ${checked ? '*' : ''}`} variant="outlined" error={checked ? facturationDataStatus.cfdi : false} />}
                      />
                      <Autocomplete
                        value={valueRegimen}
                        onChange={(event, newValue) => { setValueRegimen(newValue) }}
                        inputValue={inputValueRegimen}
                        onInputChange={(event, newInputValue) => { handleInputValueRegimen(newInputValue) }}
                        id="controllable-states-demo"
                        options={regimenCatalog}
                        getOptionLabel={(option) => option ? `${option.crfName}` : `Regimen fiscal ${checked ? '*' : ''}`}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label={`Regimen fiscal ${checked ? '*' : ''}`} variant="outlined" error={checked ? facturationExistingDataStatus.regimen : false} />}
                      />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Condicion de pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="condicionPago"
                          label='condicionPago'
                          name='condicionPago'
                          id="condicionPago"
                          labelWidth={`Condicion de pago ${checked ? '*' : ''}`}
                          value={facturationData.condicionPago}
                          onChange={handleFacturacionValues}
                          defaultValue='none'
                          error={checked ? facturationDataStatus.condicionPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Condicion de pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'30'}>30 Dias</MenuItem>
                          <MenuItem value={'60'}>60 Dias</MenuItem>
                          <MenuItem value={'90'}>90 Dias</MenuItem>
                          <MenuItem value={'120'}>120 Dias</MenuItem>
                          <MenuItem value={'contado'}>Contado</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Metodo de pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="metodoPago"
                          label='metodoPago'
                          name='metodoPago'
                          id="metodoPago"
                          labelWidth={'Metodo de pago'}
                          value={facturationData.metodoPago}
                          onChange={handleFacturacionValues}
                          defaultValue='none'
                          error={checked ? facturationDataStatus.metodoPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Metodo de pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'Pago una solo exhibición (PUE)'}>Pago una sola exhibición (PUE)</MenuItem>
                          <MenuItem value={'Parcialidades (PPD)'}>Parcialidades (PPD)</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ width: '32%' }}>
                        <InputLabel id="demo-simple-select-filled-label">{`Forma de Pago ${checked ? '*' : ''}`}</InputLabel>
                        <Select
                          labelId="formaPago"
                          label='formaPago'
                          name='formaPago'
                          id="formaPago"
                          labelWidth={'Forma de Pago'}
                          value={facturationData.formaPago}
                          onChange={handleFacturacionValues}
                          defaultValue='none'
                          error={checked ? facturationDataStatus.formaPago : false}
                        >
                          <MenuItem value="none" disabled>
                            {`Forma de Pago ${checked ? '*' : ''}`}
                          </MenuItem>
                          <MenuItem value={'tarjeta'}>Tarjeta de crédito/débito</MenuItem>
                          <MenuItem value={'transferencia'}>Transferencia</MenuItem>
                          <MenuItem value={'Cheque'}>Cheque</MenuItem>
                          <MenuItem value={'deposito'}>Depósito</MenuItem>
                          <MenuItem value={'efectivo'}>Efectivo</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField
                        label="Número de recibo de pago"
                        id='numeroPago'
                        variant="outlined"
                        style={{ width: '32%' }}
                        defaultValue={facturationData.numeroPago}
                        onChange={handleFacturacionValues}
                        type="number"
                        className={classes.input}
                      />
                    </div>
                  </div>
                }

                {/* Datos de Envio con cliente existente */}
                {valueClientStockNew == 'stock' &&
                  <div style={{
                    background: '#FAFAFA',
                    borderRadius: '10px'
                  }}>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Envio:</p>
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1">
                          <FormControlLabel value="female" control={<Radio />} label="Recoge en Sitio" />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField
                        id="deliveryDate"
                        label="Fecha de Entrega Requerida"
                        type="date"
                        variant="outlined"
                        style={{ width: '29%' }}
                        defaultValue={shipmentData.deliveryDate}
                        onChange={handleShipmentValues}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField id="reference" label="Instrucciones o referencias" variant="outlined" style={{ width: '69%' }} defaultValue={shipmentData.reference} onChange={handleShipmentValues} />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="address" label="Direccion de Envio" variant="outlined" style={{ width: '100%' }} defaultValue={shipmentData.address} onChange={handleShipmentValues} />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '12', fontWeight: 'bold' }}>Datos del contacto:</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <Autocomplete
                        value={valueContact}
                        onChange={(event, newValue) => { setValueContact(newValue) }}
                        inputValue={inputValueContact}
                        onInputChange={(event, newInputValue) => { handleInputValueContact(newInputValue) }}
                        id="controllable-states-demo"
                        options={contactList}
                        getOptionLabel={(option) => option ? `${option.firstName} ${option.firstLastName}` : 'Contacto'}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} label="Controllable" variant="outlined" />}
                      />
                    </div>
                  </div>
                }

                {/* Datos de Envio sin cliente */}
                {valueClientStockNew == 'new' &&
                  <div style={{
                    background: '#FAFAFA',
                    borderRadius: '10px'
                  }}>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Envio:</p>
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1">
                          <FormControlLabel value="female" control={<Radio />} label="Recoge en Sitio" />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField
                        id="deliveryDate"
                        label="Fecha de Entrega Requerida"
                        type="date"
                        variant="outlined"
                        style={{ width: '29%' }}
                        defaultValue={shipmentData.deliveryDate}
                        onChange={handleShipmentValues}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField id="reference" label="Instrucciones o referencias" variant="outlined" style={{ width: '69%' }} defaultValue={shipmentData.reference} onChange={handleShipmentValues} />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField id="address" label="Direccion de Envio" variant="outlined" style={{ width: '100%' }} defaultValue={shipmentData.address} onChange={handleShipmentValues} />
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '12', fontWeight: 'bold' }}>Datos del contacto:</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <Autocomplete
                        value={valueContact}
                        onChange={(event, newValue) => { setValueContact(newValue) }}
                        inputValue={inputValueContact}
                        onInputChange={(event, newInputValue) => { handleInputValueContact(newInputValue) }}
                        id="controllable-states-demo"
                        options={patientList}
                        getOptionLabel={(option) => option ? `${option.firstName} ${option.firstLastName}` : 'Contacto'}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} label="Controllable" variant="outlined" />}
                      />
                    </div>
                  </div>
                }

                {/* Datos de la Cirugia */}
                {/* valueClientStockNew == 'stock' && */
                  <div>
                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de la Cirugia:</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <Autocomplete
                        value={valueHospital}
                        onChange={(event, newValue) => { setValueHospital(newValue) }}
                        inputValue={inputValueHospital}
                        onInputChange={(event, newInputValue) => { handleInputValueHospital(newInputValue) }}
                        id="controllable-states-demo"
                        options={hospitalsList}
                        getOptionLabel={(option) => option ? `${option.name} | ${option.city}, ${option.state}` : 'Hospital'}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label="Hospital" variant="outlined" />}
                      />
                      <Autocomplete
                        value={valuePatient}
                        onChange={(event, newValue) => { setValuePatient(newValue) }}
                        inputValue={inputValuePatient}
                        onInputChange={(event, newInputValue) => { handleInputValuePatient(newInputValue) }}
                        id="controllable-states-demo"
                        options={patientList}
                        getOptionLabel={(option) => option ? `${option.firstName} ${option.secondName} ${option.firstLastName} ${option.secondLastName}` : 'Paciente'}
                        style={{ width: '49%' }}
                        renderInput={(params) => <TextField {...params} label="Paciente" variant="outlined" />}
                      />
                    </div>
                    {valuePatient && valuePatient.id == 'new' &&
                      <>
                        <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                          <p style={{ fontFamily: 'arial', fontSize: '12', fontWeight: 'bold' }}>Agregar Paciente</p>
                        </div>
                        <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'space-between' }}>
                          <TextField
                            id="patientFirstName"
                            label="Primer Nombre"
                            variant="outlined"
                            style={{ width: '24%' }}
                            defaultValue={cirugiaData.patientFirstName}
                            onChange={handleCirugiaValues}
                          />
                          <TextField
                            id="patientSecondName"
                            label="Segundo Nombre"
                            variant="outlined"
                            style={{ width: '24%' }}
                            defaultValue={cirugiaData.patientSecondName}
                            onChange={handleCirugiaValues}
                          />
                          <TextField
                            id="patientFirstLastName"
                            label="Primer Apellido"
                            variant="outlined"
                            style={{ width: '24%' }}
                            defaultValue={cirugiaData.patientFirstLastName}
                            onChange={handleCirugiaValues}
                          />
                          <TextField
                            id="patientSecondLastName"
                            label="Segundo Apellido"
                            variant="outlined"
                            style={{ width: '24%' }}
                            defaultValue={cirugiaData.patientSecondLastName}
                            onChange={handleCirugiaValues}
                          />
                        </div>
                      </>
                    }
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <Autocomplete
                        value={valueMedic}
                        onChange={(event, newValue) => { setValueMedic(newValue) }}
                        inputValue={inputValueMedic}
                        onInputChange={(event, newInputValue) => { handleInputValueMedic(newInputValue) }}
                        id="controllable-states-demo"
                        options={medicsList}
                        getOptionLabel={(option) => option ? `${option.name} ${option.secondName} ${option.firstLastname} ${option.secondLastname} | ${option.city}, ${option.state}` : 'Médico'}
                        style={{ width: '32%' }}
                        renderInput={(params) => <TextField {...params} label="Médico" variant="outlined" />}
                      />
                    </div>

                    <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p style={{ fontFamily: 'arial', fontSize: '12', fontWeight: 'bold' }}>Fecha y hora de CX</p>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                      <TextField
                        id="surgeryDate"
                        label="Fecha"
                        type="date"
                        variant="outlined"
                        style={{ width: '49%' }}
                        defaultValue={cirugiaData.surgeryDate}
                        onChange={handleCirugiaValues}
                      />
                      <TextField
                        id="surgeryHour"
                        label="Hora"
                        type="time"
                        variant="outlined"
                        style={{ width: '49%' }}
                        defaultValue={cirugiaData.surgeryHour}
                        onChange={handleCirugiaValues}
                      />
                    </div>
                  </div>
                }

                {/* Otros Datos */}
                <div>
                  <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                    <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Otros datos</p>
                  </div>
                  <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                    <TextField id="name" label={`Nombre ${checked ? `del pedido` : `de la cotización`} *`} variant="outlined" style={{ width: '32%' }} defaultValue={otherData.name} onChange={handleOtherValues} error={otherDataStatus.name} />
                  </div>
                  <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                    <TextField id="observations" label="Observaciones" variant="outlined" style={{ width: '100%' }} defaultValue={otherData.observations} onChange={handleOtherValues} />
                  </div>
                </div>

              </div>
            </div>

          </div>
        }

        {activeStep == 2 &&
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Snackbar
              open={openToasty}
              onClose={handleCloseToasty}
              /* TransitionComponent={transition} */
              message="I love snacks"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              severity="success"
            >
              <Alert severity="success">
                {`SE A AGREGADO TU SOLICITUD ${addedOrder ? addedOrder.id : ''} A ${checked ? 'PEDIDOS' : 'COTIZACIÓN'}`}
              </Alert>
            </Snackbar>
            <div style={{ display: 'flex', flexDirection: 'column', background: '#f0f0f0', width: '25%', marginRight: '20px', maxHeight: '60vh', overflow: 'scroll' }}>
              <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos del Cliente</p>
              </div>
              {
                (facturationData.razonSocial || rfcInfo.clientName || newClientData.firstName) &&
                <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                  <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Cliente</p>
                  <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? `${newClientData.firstName} ${newClientData.secondName} ${newClientData.lastName} ${newClientData.secondLastName}` : rfcInfo.clientName}</p>
                </div>
              }


              {
                (newClientData.email || rfcInfo.email) &&
                <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                  <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Correo</p>
                  <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? newClientData.email : rfcInfo.email}</p>
                </div>
              }

              <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Contacto</p>
              </div>

              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Nombre</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{
                  valueContact ?
                    `${valueContact.firstName || ''} ${valueContact.secondName || ''} ${valueContact.firstLastName || ''} ${valueContact.secondLastName || ''}` :
                    rfcInfo ?
                      `${rfcInfo.clientName}` :
                      newClientData ?
                        `${newClientData.firstName || ''} ${newClientData.secondName || ''} ${newClientData.lastName || ''} ${newClientData.secondLastName || ''}` :
                        ''
                }</p>
              </div>

              <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Facturación</p>
              </div>

              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Razón Social</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.razonSocial : rfcInfo.clientName}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>RFC</p>

                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.rfc : rfcInfo.rfc}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Domicilio</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.domicilio : rfcInfo.address}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Codigo Postal</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.cp : rfcInfo.cp}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Uso de CFDI</p>
                {console.log('FACTURATION DATA: ', facturationExistingData)}
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueCFDI.value || ''}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Regimen Fiscal</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueRegimen.value || ''}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Condicion de Pago</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.condicionPago.toUpperCase() : facturationExistingData.condicionPago.toUpperCase()} {valueClientStockNew == 'new' ? (facturationData.condicionPago == 'contado' ? '' : 'DIAS') : (facturationExistingData.condicionPago == 'contado' ? '' : 'DIAS')}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Metodo de Pago</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.metodoPago : facturationExistingData.metodoPago}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Forma de Pago</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.formaPago : facturationExistingData.formaPago}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Numero de Recibo de Pago</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? facturationData.numeroPago : facturationExistingData.numeroPago}</p>
              </div>

              <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Envio</p>
              </div>

              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Fecha de Entrega Requerida</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? shipmentData.deliveryDate : shipmentData.deliveryDate}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Instrucciones o referecias</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? shipmentData.reference : shipmentData.reference}</p>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Direccion de Envio</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{valueClientStockNew == 'new' ? shipmentData.address : shipmentData.address}</p>
              </div>

              <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Obervaciones</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{otherData ? otherData.observations : ''}</p>
              </div>

            </div>
            <div style={{ width: '75%' }}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ background: '#F8F9FA' }}
                        >
                          {column.id == 'icon' ? '' :
                            column.id == 'select' ? '' :
                              column.label
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {shoppingCart && shoppingCart.map((row, index) => {
                      if (row.type == 'cotizacion') {
                        return <RowCotizacionNoEdit key={row.name} row={row} index={index} />
                      } else if (row.type == 'paquete') {
                        return <RowPaquetesNoEdit key={row.name} row={row} index={index} />
                      } else {
                        return <RowProductosNoEdit key={row.name} row={row} index={index} />
                      }
                    })}

                  </TableBody>

                  <TableRow>
                    <TableCell rowSpan={3} />
                    <TableCell rowSpan={3} />
                    <TableCell rowSpan={3} />
                    <TableCell>Subtotal</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{totalPrices ? formatMoney(parseFloat(totalPrices.subtotal)) : '0'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Impuesto</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{totalPrices ? formatMoney(parseFloat(totalPrices.impuesto)) : '0'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{totalPrices ? formatMoney(parseFloat(totalPrices.total)) : '0'}</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </div>
          </div>
        }

      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorElRight}
        keepMounted
        open={Boolean(anchorElRight)}
        onClose={handleCloseEditRight}
      >
        <MenuItem onClick={handleOpenDeleteSelected}>
          <MenuItem>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">ELIMINAR SELECCIONADO</Typography>
          </MenuItem>
        </MenuItem>
        <MenuItem onClick={handleOpenDisc}>
          <MenuItem>
            <ListItemIcon>
              <LocalOfferIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">AGREGAR DESCUENTO</Typography>
          </MenuItem>
        </MenuItem>
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
      >
        <MenuItem onClick={handleClose2}>ELIMINAR SELECCIONADO</MenuItem>
        <MenuItem onClick={handleClose2}>AGREGAR DESCUENTO</MenuItem>
      </Menu>

      {
        activeStep == 0 &&
        <div style={{
          background: '#E5E9F2',
          display: 'flex',
          width: '100%',
          height: '82px',
          position: 'fixed',
          bottom: 40,
          justifyContent: 'space-between',
          zIndex: 10
        }}>
          <div style={{ width: '50%', display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }} >
              {/* <button style={{
                width: '175px',
                height: '46px',
                background: '#7EC573',
                borderRadius: '50px',
                marginLeft: 32,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '19px',
                textTransform: 'uppercase',
                color: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}>
                <img src={excelLogo} width={20} height={20} />
                <text>Importar</text>
              </button> */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: 30 }} >
              <FormControl variant="outlined" style={{
                width: '329px',
                height: '46px',
                boxSizing: 'border-box',
                background: '#FFFFFF',
                border: '1px solid rgba(0, 0, 0, 0.5)',
                borderRadius: '4px',
                marginLeft: 80
              }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  labelWidth={'Tipo de busqueda'}
                  MenuProps={{
                    style: { maxHeight: 300 },
                    id: "id-menu",
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  value={priceInputValue}
                  onChange={handlePriceInputChange}
                  defaultValue='none'
                  style={{
                    height: '46px',
                  }}
                >
                  <MenuItem value="none" disabled>Lista de Precios</MenuItem>
                  {
                    priceList && priceList.map((individualPrice) => (
                      <MenuItem value={individualPrice.id} key={individualPrice.id}>{individualPrice.description}</MenuItem>
                    ))
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 33 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  style={{ color: '#074e9b' }}
                />
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '20px',
                  lineHeight: '23px',
                  textAlign: 'center',
                  color: '#000000'
                }}>Generar Pedido</p>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 73 }}>
              <button style={{
                width: '239px',
                height: '46px',
                background: 'linear-gradient(90deg, #37A7B9 0%, #094070 100%)',
                borderRadius: '50px',
                fontFamily: 'Arial',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '19px',
                textTransform: 'uppercase',
                color: '#FFFFFF'
              }} onClick={handleNext}>
                Siguiente
              </button>
            </div>
          </div>
        </div>
      }

      {
        activeStep == 1 &&
        <div className="bottomBar-active1">
          <div className="div-one">
            <div className="div-two">
              <button className="buttonBottomBar1" onClick={handleNext}>
                Siguiente
              </button>
            </div>
          </div>
        </div>
      }

      {
        activeStep == 2 &&
        <div className="bottomBar-active2">
          <div className="div-first">
            <div className="div-second">
              {finish ?
                <NavLink to={{ pathname: `/` }}><Button
                  variant="contained"
                  className="navLink-two"
                  color="primary"
                  disabled={loading}
                  onClick={handleAllUpdates}
                >
                  Terminar
                </Button></NavLink>
                :
                <div className={classes.wrapper}>
                  {!loading &&
                    <Button
                      variant="contained"
                      className="navLink-two"
                      color="primary"
                      disabled={loading}
                      onClick={handleAllUpdates}
                    >
                      {`${checked ? 'Generar Pedido' : 'Generar Cotización'}`}
                    </Button>}
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              }
            </div>
          </div>
        </div>
      }
      {/*       <button className="navLink-two" onClick={handleAllUpdates}>{`${checked ? 'Generar Pedido' : 'Generar Cotización'}`}</button>
 */}
      {/* Modificar Lista de Precio */}
      <Dialog
        open={priceListModal}
        onClose={handleClosePriceList}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth='sm'
        className="price-list-dialog"
      >
        <DialogTitle id="form-dialog-title">Modificar lista de precios</DialogTitle>
        <DialogContent className="price-list-dialog-content">
          <div>Modifica los precios de tu cotizacion guardada conforme a la lista de precios</div>
          <FormControl variant="outlined" className="price-list-form-control">
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              labelWidth={'Tipo de busqueda'}
              value={priceInputValueCotizacion}
              onChange={handlePriceInputChangeCotizacion}
              defaultValue='none'
              className="price-list-select"
            >
              <MenuItem value="none" disabled>Lista de Precios</MenuItem>
              {
                priceList && priceList.map((individualPrice) => (
                  <MenuItem value={individualPrice.id} key={individualPrice.id}>{individualPrice.description}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="price-list-dialog-actions">
          <button
            className="btn"
            onClick={() => {
              changePricesOfQuote(priceInputValue)
              setPriceListModal(false)
            }}>Modificar</button>
        </DialogActions>
      </Dialog>

      {/* Vest Edit */}
      <Drawer
        anchor={'right'}
        open={editDrawer}
        onClose={handleCloseEditDrawer}
        PaperProps={{ style: { width: '20%' } }}
        className="drawer-edit"
      >
        <div className="drawer-content">
          <p className="drawer-product-code">{editActiveRow && (editActiveRow.information ? editActiveRow.information.productCode : editActiveRow.product.productCode)}</p>
          <p className="drawer-product-description">{editActiveRow && (editActiveRow.information ? editActiveRow.information.productDescription : editActiveRow.product.productDescription)}</p>
          <p className="drawer-product-price">{editActiveRow ? formatMoney(parseFloat(editActiveRow.productPrice)) : ''}</p>
          <div className="drawer-input-container">
            <TextField
              id="outlined-basic"
              label="Cantidad productos"
              variant="outlined"
              className="drawer-input"
              value={customQuantity}
              type="number"
              onChange={(event) => changeIndividualValuesEdit(event, 'quantity')}
            />
          </div>
          <div className="drawer-input-container">
            <NumberFormat
              className="drawer-input-number"
              value={customPrice}
              onValueChange={(values) => { changeIndividualValuesEdit(values.value, 'price') }}
              thousandSeparator
              isNumericString
              prefix="$"
            />
          </div>
          <div className="drawer-input-container">
            <NumberFormat
              className="drawer-input-number"
              value={customDisccount}
              onValueChange={(values) => { changeIndividualValuesEdit(values.value, 'disccount') }}
              suffix="%"
            />
            {/* <TextField
              id="outlined-basic"
              variant="outlined"
              className="drawer-input"
              value={customDisccount}
              type="number"
              onChange={(event) => changeIndividualValuesEdit(event, 'disccount')}
            /> */}
          </div>
          <div className="drawer-tabs-container">
            <Tabs
              value={value}
              onChange={(e, newValue) => handleChangeTabs(e, newValue)}
              aria-label="simple tabs example"
            >
              <Tab label="Configurable" />
              <Tab label="Configurados" />
            </Tabs>

            <TabPanel value={value} index={0} />
            <TabPanel2 value={value} index={1} />
          </div>
          <div className="drawer-update-button-container">
            <button className="btn btnEditVest" onClick={() => { changeQuantityManual() }}>Actualizar</button>
          </div>
        </div>
      </Drawer>

      {/* Vest View Edit */}
      <Drawer
        anchor={'right'}
        open={editDrawerView}
        onClose={handleCloseEditDrawerView}
        PaperProps={{ style: { width: '20%' } }}
        className="drawer-edit"
      >
        <div className="drawer-content">
          <p className="drawer-product-code">{editActiveRow && (editActiveRow.information ? editActiveRow.information.productCode : editActiveRow.product.productCode)}</p>
          <p className="drawer-product-description">{editActiveRow && (editActiveRow.information ? editActiveRow.information.productDescription : editActiveRow.product.productDescription)}</p>
          <p className="drawer-product-price">{editActiveRow ? formatMoney(parseFloat(editActiveRow.productPrice)) : ''}</p>
          <div className="drawer-input-container">
            <TextField
              id="outlined-basic"
              label="Cantidad productos"
              variant="outlined"
              className="drawer-input"
              value={customQuantity}
              type="number"
              onChange={(event) => changeIndividualValuesEdit(event, 'quantity')}
              disabled
            />
          </div>
          <div className="drawer-input-container">
            <NumberFormat
              className="drawer-input-number"
              value={customPrice}
              onValueChange={(values) => { changeIndividualValuesEdit(values.value, 'price') }}
              thousandSeparator
              isNumericString
              prefix="$"
              disabled
            />
          </div>
          <div className="drawer-input-container">
            <NumberFormat
              className="drawer-input-number"
              value={customDisccount}
              onValueChange={(values) => { changeIndividualValuesEdit(values.value, 'disccount') }}
              suffix="%"
              disabled
            />
          </div>
          <div className="drawer-tabs-container">
            <TabPanel2View value={value} index={1} />
          </div>
        </div>
      </Drawer>

      {/* Simple Edit */}
      <Drawer
        anchor={'right'}
        open={simpleEditDrawer}
        onClose={handleCloseSimpleEditDrawer}
        PaperProps={{ style: { width: '20%' } }}
        className="drawer-simple-edit"
      >
        <div className="drawer-content">
          <p className="drawer-product-code">{editActiveRow && (editActiveRow.information ? editActiveRow.information.productCode : editActiveRow.product.productCode)}</p>
          <p className="drawer-product-description">{editActiveRow && (editActiveRow.information ? editActiveRow.information.productDescription : editActiveRow.product.productDescription)}</p>
          <p className="drawer-product-price">{editActiveRow ? formatMoney(parseFloat(editActiveRow.productPrice)) : ''}</p>

          <div className="drawer-input-container">
            <TextField
              id="outlined-basic"
              label="Cantidad productos"
              variant="outlined"
              className="drawer-input"
              value={customQuantity}
              type="number"
              onChange={(event) => changeIndividualValuesEdit(event, 'quantity')}
            />
          </div>

          <div className="drawer-input-container">
            <NumberFormat
              className='drawer-input-number'
              value={customPrice}
              onValueChange={(values) => { changeIndividualValuesEdit(values.value, 'price') }}
              thousandSeparator
              isNumericString
              prefix="$"
            />
          </div>

          <div className="drawer-input-container">
            <NumberFormat
              className="drawer-input-number"
              value={customDisccount}
              onValueChange={(values) => { changeIndividualValuesEdit(values.value, 'disccount') }}
              suffix="%"
            />
          </div>
          <div className="drawer-update-button-container">
            <button className="btn btnSimpleEdit" onClick={() => { changeQuantityManual() }}>Actualizar</button>
          </div>
        </div>
      </Drawer>

      {/* Eliminar Seleccionado */}
      <Dialog
        open={deleteSelected}
        onClose={handleCloseDeleteSelected}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth='sm'
        class="dialog-delete-selected"
      >
        <DialogTitle id="form-dialog-title" className="dialog-title">Eliminar Seleccionado</DialogTitle>
        <DialogContent>
          <DialogTitle id="form-dialog-title" className="dialog-description">¿Esta seguro de eliminar los productos seleccionados de la cotización?</DialogTitle>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button className="btnDelete btnDeleteDialog" onClick={handleDeleteSelected}>ELIMINAR</button>
        </DialogActions>
      </Dialog>

      {/* Agregar Descuento */}
      <Dialog
        open={openDisc}
        onClose={handleCloseDisc}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth='sm'
        className="dialog-add-disscount"
      >
        <DialogTitle id="form-dialog-title" className="dialog-title">Agregar Descuento</DialogTitle>
        <DialogContent className="dialog-content">
          <div className="dialog-description">Agrega el descuento para el o los productos seleccionados.</div>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="position" name="position" defaultValue="top" value={selectCustomDisscount} onChange={(event) => { setSelectCustomDisscount(parseInt(event.target.value)) }}>
              <FormControlLabel value={5} control={<Radio style={{ color: selectCustomDisscount == 5 ? '#689F38' : '#BDBDBD' }} />} label="5%" />
              <FormControlLabel value={10} control={<Radio style={{ color: selectCustomDisscount == 10 ? '#689F38' : '#BDBDBD' }} />} label="10%" />
              <FormControlLabel value={15} control={<Radio style={{ color: selectCustomDisscount == 15 ? '#689F38' : '#BDBDBD' }} />} label="15%" />
            </RadioGroup>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Descuento"
            variant="outlined"
            type="number"
            value={selectCustomDisscount}
            InputProps={{ inputProps: { max: 100, min: 10 } }}
            onChange={(e) => {
              var value = parseInt(e.target.value, 10);
              if (value > 99) value = 99;
              if (value < 0) value = 0;
              setSelectCustomDisscount(value)
            }}
            className="discount-input"
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button className="btn" onClick={handleDiscountOfSelected}>AGREGAR</button>
        </DialogActions>
      </Dialog>

    </div >
  );
};
