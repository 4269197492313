import React from 'react';
import { useSelector } from 'react-redux';
import StarsComponent from '../Stars/StarsComponent';
import './styleComentSurvey.scss';


const ComentsSurvey = ({ title, subtitle,comments, type }) => {

    const response = useSelector(state => state.survey.setSurveyAppoimentSelected);

    return (
        <div className='container-component-comments-survey'>
            <div className='header-comments'>
                <div className='header-title'>
                    <p className='title-component'>{title}</p>
                </div>
                <div className='header-subtitle'>
                    <p className='subtitle-component'>{subtitle}</p>
                </div>
            </div>
            {
                type === 'comentarios-atq' &&
                <div className='body-coments'>
                    <div className='container-row'>
                        <div className='preco-row'>
                            <p className='text'>¿Todo bien en CX?</p>
                        </div>
                        <div className='preco-row'>
                            <p className='text center-h'>{response && response.atqSurvey && response.atqSurvey.allOk === true  ?'Si' :'No'}</p>
                        </div>
                        <div className='preco-row'>
                            <p className='text'>{response && response.atqSurvey && response.atqSurvey.allOkCommentary ?response.atqSurvey.allOkCommentary :''}</p>
                        </div>


                    </div>
                    <div className='container-row'>
                        <div className='preco-row'>
                            <p className='text'>Desinfección según INS-D6. Lavado de instrumental efectuado.</p>
                        </div>
                        <div className='preco-row'>
                            <p className='text center-h center-v'>{response && response.atqSurvey  && response.atqSurvey.insD6 === true  ?'Si' :'No'}</p>
                        </div>
                        <div className='preco-row'>
                            <p className='text'>{ response && response.atqSurvey  && response.atqSurvey.insD6Commentary ?response.atqSurvey.insD6Commentary :'' }</p>
                        </div>

                    </div>

                </div>
            }

            {
                type === 'comentarios-almacen' &&
                <div className='body-coments'>
                    <div className='container-row'>
                        <div className='preco-row-almacen'>
                            <p className='text'>Entrada almacén</p>
                        </div>
                        <div className='preco-row-almacen'>
                            <p className='text'>{ response && response.warehouseSurvey && response.warehouseSurvey.checkInCommentary ?response.warehouseSurvey.checkInCommentary :''}</p>
                        </div>
                    </div>

                    <div className='container-row'>
                        <div className='preco-row-almacen'>
                            <p className='text'>Salida almacén</p>
                        </div>
                        <div className='preco-row-almacen'>
                            <p className='text center-h center-v'>{ response && response.warehouseSurvey && response.warehouseSurvey.checkOutCommentary ?response.warehouseSurvey.checkOutCommentary :''}</p>
                        </div>
                    </div>

                    <div className='container-row'>
                        <div className='preco-row-almacen'>
                            <p className='text'>Coordinador almacén</p>
                        </div>
                        <div className='preco-row-almacen'>
                            <p className='text center-h center-v'>{ response && response.warehouseSurvey && response.warehouseSurvey.coordinatorCommentary ?response.warehouseSurvey.coordinatorCommentary :''}</p>
                        </div>
                    </div>

                </div>
            }
            {
                type === 'comentarios-ventas' &&
                <div className='body-coments-ventas'>
                    <div className='container-row-ventas'>
                        <div className='preco-row-ventas'>
                            <p className='text-ventas'>¿Cómo califica el surtido de CX?</p>
                        </div>
                        <div className='preco-row-ventas'>
                            <StarsComponent valoracion={ response && response.salesSurvey && response.salesSurvey.assortmentSupplyRate ?parseInt(response.salesSurvey.assortmentSupplyRate) :0} />
                        </div>
                        <div className='preco-row-ventas'>
                            <p className='text-ventas'>{response &&  response.salesSurvey && response.salesSurvey.assortmentSupplyCommentary ?response.salesSurvey.assortmentSupplyCommentary :''}</p>
                        </div>


                    </div>
                    <div className='container-row-ventas'>
                        <div className='preco-row-ventas'>
                            <p className='text-ventas'>¿Cómo califica la entrega de material?</p>
                        </div>
                        <div className='preco-row-ventas'>
                            <StarsComponent valoracion={response && response.salesSurvey && response.salesSurvey.deliveryMaterialRate ?parseInt(response.salesSurvey.deliveryMaterialRate) :0} />
                        </div>
                        <div className='preco-row-ventas'>
                            <p className='text-ventas'>{response && response.salesSurvey && response.salesSurvey.deliveryMaterialCommentary ?response.salesSurvey.deliveryMaterialCommentary :''}</p>
                        </div>

                    </div>


                </div>
            }

            {
                type === 'comentarios-mantenimiento' &&
                <div className='body-coments'>
                    <div className='preco-row-mantenimiento'>
                        <p className='text'>{comments}</p>
                    </div>

                </div>
            }
        </div>

    )

};

export default ComentsSurvey;
