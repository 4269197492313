import React from "react";
import './sass/styles.scss';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import excelLogo from '../../assets/img/512PX/excelLogo.png';
import SendIcon from '@material-ui/icons/Send';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ToastContainer, toast } from 'react-toastify';
import {
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";
import { useState } from "react";
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const columnsModal = [
  { id: 'id', label: 'ID', minWidth: 200 },
  { id: 'producto', label: 'PRODUCTO', minWidth: 200 },
  { id: 'total', label: 'TOTAL', minWidth: 200 },
  { id: 'icon', label: 'Ver', minWidth: 20 }
];

function createDataModal(id, producto, precio, icon) {
  return { id, producto, precio };
}

const notify = () => toast("Agregado con exito!");

const rows2 = [
  createDataModal('CS 8201-08', 'CERV-X CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '1,500', '-'),
  createDataModal('CS 2600-6-45', 'COSMICMIA- TORNILLO D6.5 MM, LONGITUD 45MM', '1,000', '-'),
  createDataModal('170001', 'CONTACT - TORNILLO DE FIJACION', '50', '-'),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const QuotesProducts = () => {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [openDisc, setOpenDisc] = React.useState(false);

  const handleOpenDisc = () => {
    setOpenDisc(true);
  };

  const handleCloseDisc = () => {
    setOpenDisc(false);
  };

  const [openQuote, setOpenQuote] = React.useState(false);

  const handleOpenQuote = () => {
    setOpenQuote(true);
  };

  const handleCloseQuote = () => {
    setOpenQuote(false);
  };

  const [openDownQuote, setOpenDownQuote] = React.useState(false);

  const handleOpenDownQuote = () => {
    setOpenDownQuote(true);
  };

  const handleCloseDownQuote = () => {
    setOpenDownQuote(false);
  };

  const [openSaveQuote, setOpenSaveQuote] = React.useState(false);

  const handleOpenSaveQuote = () => {
    setOpenSaveQuote(true);
  };

  const handleCloseSaveQuote = () => {
    setOpenSaveQuote(false);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const productDetail = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DialogTitle id="form-dialog-title">Chaleco tipo doctor | ID 001</DialogTitle>
      <DialogContent style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <DialogTitle id="form-dialog-title">32 disponibles</DialogTitle>
        <DialogTitle id="form-dialog-title">$100.00</DialogTitle>
        <FormControl component="fieldset">
          <TextField id="outlined-basic" label="Color" variant="outlined" style={{ marginTop: 10 }} />
          <TextField id="outlined-basic" label="Talla" variant="outlined" style={{ marginTop: 10 }} />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
        <button className="btn" style={{ width: 220 }}>Actualizar</button>
      </DialogActions>
    </div>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (

    <div>
      <div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 20
        }}>
          <div style={{ width: '50%' }}>
            <div className="title" onClick={handleOpenDelete}>

            </div>
          </div>
          <div style={{ width: '50%', justifyContent: 'flex-end', display: 'flex' }}>
            <FormControl variant="outlined" style={{ width: '30%', marginRight: 10 }}>
              <InputLabel id="demo-simple-select-outlined-label">Paquetes</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Age"
              >
                <MenuItem value="">
                  <em>Paquetes</em>
                </MenuItem>
                <MenuItem value={10}>Paquete Febrero</MenuItem>
                <MenuItem value={20}>Paquete Cirugia</MenuItem>
                <MenuItem value={30}>Paquete chaleco</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: '30%' }}>
              <InputLabel id="demo-simple-select-outlined-label">Mis Paquetes</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Age"
              >
                <MenuItem value="">
                  <em>Paquetes</em>
                </MenuItem>
                <MenuItem value={10}>Paquete Atrevete</MenuItem>
                <MenuItem value={20}>Paquete Descuento</MenuItem>
                <MenuItem value={30}>Paquete 2x1</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <TableContainer className={classes.container} style={{ maxHeight: 600, marginBottom: 100 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnsModal.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.code}>
                    {columnsModal.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {
                            column.id == 'cantidad' ?
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}>
                                <IconButton onClick={value + 1}>
                                  <RemoveIcon />
                                </IconButton>
                                <div>{value}</div>
                                <IconButton>
                                  <AddIcon />
                                </IconButton>
                              </div>
                              :
                              column.id == 'box' ?
                                <Checkbox /> :
                                column.id == 'icon' ?
                                  <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  }}>
                                    <VisibilityIcon onClick={toggleDrawer('right', true)} style={{ marginLeft: 10 }} />
                                    <MoreVertIcon onClick={handleClick} />
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={handleClose}
                                    >
                                      <MenuItem onClick={handleClose}>Agregar paquete existente</MenuItem>
                                      <MenuItem onClick={handleClose}>Agregar a cotización</MenuItem>
                                      <MenuItem onClick={handleClose}>Agregar nuevo paquete</MenuItem>
                                    </Menu>
                                  </div> :
                                  column.format && typeof value === 'number' ?
                                    column.format(value) :
                                    column.id == 'producto' ?
                                      <Tooltip title={value}>
                                        <div>{value}</div>
                                      </Tooltip> : value
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer className={classes.container} style={{ maxHeight: 600, marginBottom: 100 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnsModal.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.code}>
                    {columnsModal.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {
                            column.id == 'cantidad' ?
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}>
                                <IconButton>
                                  <RemoveIcon />
                                </IconButton>
                                <div>{value}</div>
                                <IconButton>
                                  <AddIcon />
                                </IconButton>
                              </div>
                              :
                              column.id == 'box' ?
                                <Checkbox /> :
                                column.id == 'icon' ?
                                  <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  }}>
                                    <VisibilityIcon onClick={toggleDrawer('right', true)} style={{ marginLeft: 10 }} />
                                    <MoreVertIcon onClick={handleClick} />
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={handleClose}
                                    >
                                      <MenuItem onClick={handleClose}>Agregar paquete existente</MenuItem>
                                      <MenuItem onClick={handleClose}>Agregar a cotización</MenuItem>
                                      <MenuItem onClick={handleClose}>Agregar nuevo paquete</MenuItem>
                                    </Menu>
                                  </div> :
                                  column.format && typeof value === 'number' ?
                                    column.format(value) :
                                    column.id == 'producto' ?
                                      <Tooltip title={value}>
                                        <div>{value}</div>
                                      </Tooltip> : value
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Eliminar Seleccionado */}
        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle id="form-dialog-title">Eliminar Seleccionado</DialogTitle>
          <DialogContent style={{ alignSelf: 'center' }}>
            <DialogTitle id="form-dialog-title">¿Esta seguro de eliminar los productos seleccionados de la cotización?</DialogTitle>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btnDelete" style={{ width: 220 }}>Eliminar</button>
          </DialogActions>
        </Dialog>

        {/* Agregar Descuento */}
        <Dialog
          open={openDisc}
          onClose={handleCloseDisc}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle id="form-dialog-title">Agregar Descuento</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <div>Agrega el descuento para el o los productos seleccionados</div>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel value="5" control={<Radio color="primary" />} label="5%" />
                <FormControlLabel value="10" control={<Radio color="primary" />} label="10%" />
                <FormControlLabel value="15" control={<Radio color="primary" />} label="15%" />
              </RadioGroup>
              <TextField id="outlined-basic" label="Descuento" variant="outlined" />
            </FormControl>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btn" style={{ width: 220 }}>Agregar</button>
          </DialogActions>
        </Dialog>

        {/* Enviar Cotizacion*/}
        <Dialog
          open={openQuote}
          onClose={handleCloseQuote}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          style={{ height: '150%' }}
        >
          <DialogTitle id="form-dialog-title">Actualizar paquete</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <DialogTitle id="form-dialog-title">¿Esta seguro de actualizar el paquete ?</DialogTitle>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btn" style={{ width: 220 }}>Actualizar</button>
          </DialogActions>
        </Dialog>

        {/* Descargar Cotizacion*/}
        <Dialog
          open={openDownQuote}
          onClose={handleCloseDownQuote}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          style={{ height: '120%' }}
        >
          <DialogTitle id="form-dialog-title">Descargar Cotizacion</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: '500px'
          }}>
            <DialogTitle id="form-dialog-title">Ingresa los datos necesarios</DialogTitle>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <FormControl style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10
              }}>
                <TextField id="outlined-basic" label="Titulo del archivo * " variant="outlined" style={{ marginTop: 10 }} />
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">ATN * </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Institución</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Forma de pago</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Tiempo de entrega</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="outlined-basic" label="Correo Electronico * " variant="outlined" style={{ marginTop: 10 }} />
              </FormControl>

              <FormControl style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 10
              }}>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Fecha * </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Paciente * </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Ciudad y estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Condiciones de pago</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 10 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Validez de la oferta</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="outlined-basic" label="Numero de telefono * " variant="outlined" style={{ marginTop: 10 }} />
              </FormControl>

            </div>
          </DialogContent>
          <DialogActions style={{ alignContent: 'right', alignItems: 'right', alignSelf: 'right' }}>
            <button className="btn" style={{ width: 220 }}>Descargar PDF</button>
            <button className="btn" style={{ width: 220 }}>Descargar Excel</button>
          </DialogActions>
        </Dialog>

        {/* Guardar Cotizacion*/}
        <Dialog
          open={openSaveQuote}
          onClose={handleCloseSaveQuote}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='sm'
          style={{ height: '160%' }}
        >
          <DialogTitle id="form-dialog-title">Guardar paquete</DialogTitle>
          <DialogContent style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <div>Ingresa el nombre del nuevo paquete</div>
            <FormControl component="fieldset">
              <TextField id="outlined-basic" label="Nombre del borrador" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} />
            </FormControl>
          </DialogContent>
          <DialogActions style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <button className="btn" style={{ width: 220 }}>Guardar</button>
          </DialogActions>
        </Dialog>

        <React.Fragment key={'right'}>
          <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
            {productDetail('right')}
          </Drawer>
        </React.Fragment>

        <ToastContainer />
      </div>
      <nav className='c-quote-navbarBottom' >
        <button className="btnDeletePackages" onClick={handleOpenDelete} style={{ marginTop: 10, marginLeft: 20 }}>
          Eliminar Seleccionados
        </button>

        <button className="btnBottomPackages" style={{ marginTop: 10, marginLeft: 30 }} onClick={handleOpenSaveQuote}>
          GUARDAR PAQUETE
        </button>

        <button className="btnBottomPackages" style={{ marginTop: 10, marginLeft: '40%' }} onClick={handleOpenQuote}>
          ACTUALIZAR PAQUETE
        </button>

        <button className="btnBottomPackages" style={{ marginTop: 10, marginLeft: 30 }} onClick={notify}>
          ACTUALIZAR PAQUETE
        </button>


      </nav>
    </div >
  );
};
