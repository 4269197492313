/* eslint-disable react/prop-types */
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, Slide, Snackbar, TextField, Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RemoveIcon from '@material-ui/icons/Remove';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";
import { asynGetProductPrices, getProductPrices } from "../../actions/newQuoteAction";
import { getPriceListToSchedule } from "../../actions/scheduleSurgeryAction";
import { getFinishedAllQoute, getProducts, getSystems, asyncGetSystemBundle, getFinishedQoute, getColors } from "../../actions/systemsAction";
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './sass/styles.scss';
import AutosizeInput from "react-input-autosize";
import MuiAlert from '@material-ui/lab/Alert';
import * as XLSX from "xlsx";
import excelLogo from '../../assets/img/512PX/excelLogo.png';
import { setShoppingCartRedux } from "../../actions/userActions";
import icoHistory from '../../assets/img/126PX/Icono_atras_126px.png'

export const ProductList = (props) => {
  /* General Zone */
  const dispatch = useDispatch();

  const products = useSelector(state => state.systems.products);
  const quotesFinished = useSelector((state) => state.systems.quotesFinished);
  const systemsTemplate = useSelector((state) => state.systems.systemsTemplate)
  const bundles = useSelector((state) => state.systems.bundles)
  const requestH = useSelector((state) => state.user.request);
  const priceList = useSelector((state) => state.scheduleSurgery.getListPricesToSchedule);
  const shoppingCartStore = useSelector((state) => state.user.shoppingCart)
  const [colorList, setColorList] = useState([])

  function findColorNameByCode(codeToFind, colorArray) {
    for (let i = 0; i < colorArray.length; i++) {
      console.log(codeToFind)
      console.log(colorArray[i].code)
      if (parseInt(colorArray[i].code) === parseInt(codeToFind)) {
        return colorArray[i].name;
      }
    }
    return null; // Retorna null si no se encuentra el code en el array
  }

  const getColorsFunction = async () => {
    await getColors(requestH).then((data) => {
      setColorList(data.response)
    })
  }

  useEffect(() => {
    async function fetchData() {
      dispatch(getPriceListToSchedule(0, 1000));
      dispatch(getProducts(0, 10000, true));
      dispatch(getFinishedQoute(0, 1000, true));
      /* dispatch(getProductPrices(dataToSendPrice,requestH)) */
      dispatch(getSystems());
      getColorsFunction()
    }
    fetchData()
  }, []);

  useEffect(() => {
    if (shoppingCartStore) {
      setShoppingCart(shoppingCartStore)
    }
  }, [shoppingCartStore])

  useEffect(() => {
    if (props.location.state.shoppingCart) {
      async function fetchData() {
        dispatch(getPriceListToSchedule(0, 1000));
        dispatch(getProducts(0, 1000, true));
        dispatch(getFinishedQoute(0, 1000, true));
        dispatch(getSystems());
      }
      fetchData()
      setInputValue('cotizacionesGuardadas')
      setShoppingCart(props.location.state.shoppingCart)
    }
  }, [props.location.state.shoppingCart]);


  const isAllLoaded = () => {
    if (products && quotesFinished && systemsTemplate && requestH && priceList) setLoaderOpen(false)
  }

  useEffect(() => {
    if (priceList && priceList.length > 0) setPriceInputValue(priceList[0].id)
    isAllLoaded()
  }, [priceList])

  useEffect(() => {
    setRowsProducts(products)
    setOriginalRowsProducts(products)
    isAllLoaded()
  }, [products])

  useEffect(() => {
    setRowsCotizacion(quotesFinished)
    setOriginalQuote(quotesFinished)
    isAllLoaded()
  }, [quotesFinished])

  useEffect(() => {
    getAllSystems()
    isAllLoaded()
  }, [systemsTemplate])

  useEffect(() => {
    if (products && priceList) setPriceToAllItemProducts(priceList[0].id)
  }, [products, allPaquetesSystem, priceList])

  /* useEffect(() => {
  }, [originalRowsPaquetes, priceList]) */

  function formatMoney(number) {
    if (number) { return number.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) }
    else { return 0 }
  }

  /* SubNavBarComponent */
  const { searchQuoteFinished } = useParams();

  /* Top Bar */
  const [searchedValuesGeneral, setSearchedValuesGeneral] = useState("");
  const [inputValue, setInputValue] = useState(props.location.state.state || '');
  const [inputValueType, setInputValueType] = useState(props.location.state.type || '');
  const [loaderOpen, setLoaderOpen] = useState(true);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handlePriceInputChange = (event) => {
    setPriceInputValue(event.target.value);
    setPriceToAllItemProducts(event.target.value)
    setPriceToAllItemPaquetes(event.target.value)
  };

  const requestSearch = (searchedVal) => {
    if (inputValue == 'productos') {
      requestSearchProducts(searchedVal)
    } else if (inputValue == 'paquetes') {
      requestSearchPaquetes(searchedVal)
    } else if (inputValue == 'cotizacionesGuardadas') {
      requestSearchQuote(searchedVal)
    }
  };

  const cancelSearch = () => {
    setSearchedValuesGeneral('')
    if (inputValue == 'productos') {
      cancelSearchProducts()
    } else if (inputValue == 'paquetes') {
      cancelSearchPaquetes()
    } else if (inputValue == 'cotizacionesGuardadas') {
      cancelSearchQuote()
    }
  };

  /* Price List */
  const [priceInputValue, setPriceInputValue] = useState('none');

  const setPriceToAllItemProducts = async (priceId) => {
    setLoaderOpen(true)

    /* PRODUCTS */
    const copyProducts = [...products]

    const preProducts = products && products.map((individualProduct) => {
      return { "productCode": individualProduct.productCode }
    })

    const productsWithPrice = await asynGetProductPrices({ "PriceListId": priceId, "Products": preProducts }, requestH).then((data, index) => {
      return (data.response)
    })

    const allMerge = copyProducts.filter((innerProduct) => {
      const productInfo = productsWithPrice.find(individualProduct => individualProduct.productCode == innerProduct.productCode)
      innerProduct.prices = productInfo

      if (!innerProduct.isBundle)
        return innerProduct
    })

    setRowsProducts(allMerge)
    setLoaderOpen(false)

  }

  const setPriceToAllItemPaquetes = async (priceId, systems) => {
    setLoaderOpen(true)
    /* PAQUETES */
    let copyPaquetes

    const allSystems = [...allPaquetesSystem]
    const preSystems = [...systems]
    const arrayWhitCodes = [];

    if (allSystems && allSystems.length > 0) copyPaquetes = [...allSystems]
    else if (preSystems && preSystems.length > 0) copyPaquetes = [...preSystems]

    copyPaquetes.map((systemsIndividual) => {
      if (systemsIndividual.bundles && systemsIndividual.bundles.length > 0) {
        systemsIndividual.bundles.map((individualBundle) => {
          individualBundle.configuration.items.map((item) => {
            arrayWhitCodes.push({ "productCode": item.product.productCode })
          })
        })
      }
    })

    const paquetesProductsWithPrice = await asynGetProductPrices({ "PriceListId": priceId, "Products": arrayWhitCodes }, requestH).then((data) => {
      return (data.response)
    })

    copyPaquetes.map((systemsIndividual, index1) => {
      if (systemsIndividual.bundles && systemsIndividual.bundles.length > 0) {
        systemsIndividual.bundles.map((individualBundle, index2) => {
          individualBundle.configuration.items.map((item, index3) => {
            const productInfo = paquetesProductsWithPrice.find(individualProduct => individualProduct.productCode == copyPaquetes[index1].bundles[index2].configuration.items[index3].product.productCode)

            copyPaquetes[index1].bundles[index2].configuration.items[index3].discountPercentage = 0
            copyPaquetes[index1].bundles[index2].configuration.items[index3].productPrice = productInfo ? productInfo.priceForSale : 0
            copyPaquetes[index1].bundles[index2].configuration.items[index3].prices = productInfo

          })
        })
      }
    })
    setAllPaquetesSystem(copyPaquetes)
    setLoaderOpen(false)
  }

  function findArray(jsonObject, id) { return jsonObject.filter(function (jsonObject) { return (jsonObject['id'] == id); })[0]; }

  /* Tabla Cotización */
  const [rowsQuote, setRowsCotizacion] = useState([]);
  const [originalQuote, setOriginalQuote] = useState([]);
  const [pageQuote, setPageQuote] = useState(0);
  const [rowsPerPageQuote, setRowsPerPageQuote] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState()

  const [totalQuotePrice, setTotalQuotePrice] = useState(0)

  const columnsCotizacion = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'deliveryDate', label: 'FECHA', minWidth: 100 },
    { id: 'quotationName', label: 'NOMBRE', minWidth: 100 },
    { id: 'cliente', label: 'CLIENTE', minWidth: 170 },
    { id: 'realPrice', label: 'SUBTOTAL', minWidth: 170, },
    { id: 'iva', label: 'IVA', minWidth: 170, },
    { id: 'finalPrice', label: 'TOTAL', minWidth: 170 },
    { id: 'icon', label: 'Ver', minWidth: 20 }
  ];

  const getAllSystems = async () => {
    const allSystemsReady = await Promise.all(
      systemsTemplate && systemsTemplate.map(async individualSystem => {
        const newSystem = individualSystem
        const bundles = await asyncGetSystemBundle(individualSystem.id, 0, 100, requestH).then((data) => {
          return (data.response)
        })
        if (bundles && bundles.length > 0) {
          newSystem.bundles = bundles
        }
        return newSystem
      })
    )
    setAllPaquetesSystem(allSystemsReady)
    setOriginalRowsPaquetes(allSystemsReady)
    setPriceToAllItemPaquetes(priceList[0].id, allSystemsReady)
    isAllLoaded()
  }

  const handleChangePageQuote = (event, newPage) => {
    setPageQuote(newPage);
  };

  const handleChangeRowsPerPageQuote = (event) => {
    setRowsPerPageQuote(+event.target.value);
    setPageQuote(0);
  };

  const handleOpen = (row) => {
    setSelectedRow(row)
    createDataModal(row.quotationItems)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const requestSearchQuote = (searchedVal) => {
    const filteredRows = originalQuote.filter((row) => {
      return row.quotationName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (row && row.client && row.client.clientName.toLowerCase().includes(searchedVal.toLowerCase()))
    });
    setRowsCotizacion(filteredRows);
  };

  const cancelSearchQuote = () => {
    const filteredRows = originalRowsProducts.filter((row) => {
      return row.productDescription.toLowerCase().includes('') || row.productCode.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRowsCotizacion(filteredRows);
  };

  function getRealPrice() {
    let price = 0
    rowsModal && rowsModal.map((row) => {
      price = price + parseFloat(((row.productPrice * row.productQuantity) - ((row.productPrice * row.productQuantity) * (row.discountPercentage / 100))))
    })
    return formatMoney(price)
  }

  function getTaxNumber() {
    let price = 0
    rowsModal && rowsModal.map((row) => {
      price = price + parseFloat(((row.productPrice * row.productQuantity) - ((row.productPrice * row.productQuantity) * (row.discountPercentage / 100))))
    })
    return (formatMoney(price * (.16)))
  }

  function getTotalFinal() {
    let price = 0
    rowsModal && rowsModal.map((row) => {
      price = price + parseFloat(((row.productPrice * row.productQuantity) - ((row.productPrice * row.productQuantity) * (row.discountPercentage / 100))))
    })
    let tax = (price * (.16))

    console.log(tax)

    return (formatMoney(price + tax))
  }

  /* Tabla Paquetes */
  const [allPaquetesSystem, setAllPaquetesSystem] = useState([])
  const [originalRowsPaquetes, setOriginalRowsPaquetes] = useState();

  const findQuantityofPackage = (type, id) => {
    let cantidad
    shoppingCart && shoppingCart.map((cartItem, index) => {
      if (cartItem.type == type && cartItem.id == id) cantidad = index
    })
    return cantidad
  }

  function RowPaquete(props) {
    const { row, systemsIndividual } = props;
    const [openPaquete, setOpenPaquete] = useState(false);
    let indexOriginal = findQuantityofPackage('paquete', row.id)
    return (
      <Fragment key={row.id}>
        <TableRow style={{ background: '#F9F9F9' }}>
          <TableCell key={'label'} align={' '} style={{ minWidth: 300 }}>
            <text style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              color: '#484F59'
            }}>
              {row.configuration.name}
            </text>
          </TableCell>

          <TableCell key={'precio'} align={' '} style={{ minWidth: 50 }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <IconButton onClick={() => addToShoppingCart(row, 'paquete', systemsIndividual.product)}>
                <AddIcon style={{
                  fontFamily: 'Arial',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '30px',
                  color: '#000000',
                  background: '#D9D9D9',
                  borderRadius: '50%'
                }} />
              </IconButton>
              <text style={{
                fontFamily: 'Arial',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '24px',
                textDecorationLine: 'underline',
                color: '#000000'
              }}>{(shoppingCart && shoppingCart[indexOriginal] && shoppingCart[indexOriginal].quantity) ? shoppingCart[indexOriginal].quantity : 0}</text>
              <IconButton onClick={() => { removeFromShoppingCart(row, 'paquete') }}>
                <RemoveIcon style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '30px',
                  color: '#000000',
                  background: '#D9D9D9',
                  borderRadius: '50%'
                }} />
              </IconButton>
            </div>
          </TableCell>


          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => {
              setOpenPaquete(!openPaquete)
            }}>
              {openPaquete ? <KeyboardArrowUpIcon style={{ color: '#303030', fontSize: '30px' }} /> : <KeyboardArrowDownIcon style={{ color: '#303030', fontSize: '30px' }} />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openPaquete} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align={'left'} width={'10%'}>ID</TableCell>
                      <TableCell align={'left'} width={'70%'}>Producto</TableCell>
                      <TableCell align={'right'} width={'10%'}>Precio</TableCell>
                      <TableCell align={'center'} width={'10%'}>Cantidad</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row && row.configuration.items.map((historyRow) => {
                      return (
                        <TableRow key={historyRow.date}>
                          <TableCell component="th" scope="row" style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#484F59'
                          }} align={'left'} width={'10%'}>
                            {historyRow.product.productCode}
                          </TableCell>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#484F59'
                          }} align={'left'} width={'70%'}>{historyRow.product.productDescription || ''}</TableCell>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#484F59'
                          }} align={'right'} width={'10%'}>{formatMoney(historyRow.productPrice) || '-'}</TableCell>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#484F59'
                          }} align={'center'} width={'10%'}>
                            {historyRow.productQuantity}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment >
    );
  }

  const requestSearchPaquetes = (searchedVal) => {
    const newArray = []
    originalRowsPaquetes.filter((row) => {
      const newRow = { ...row };
      newRow.bundles = {}
      if (row.bundles) {
        const rowBundle = row.bundles.filter((innerBundle) => {
          return innerBundle.configuration.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.product.productDescription.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row.product.productCode.toLowerCase().includes(searchedVal.toLowerCase());
        })
        newRow.bundles = rowBundle
        newArray.push(newRow)
      }
    });

    setAllPaquetesSystem(newArray);
  };

  const cancelSearchPaquetes = () => {
    const newArray = []
    originalRowsPaquetes.filter((row) => {
      const newRow = { ...row };
      newRow.bundles = {}
      if (row.bundles) {
        const rowBundle = row.bundles.filter((innerBundle) => {
          return innerBundle.configuration.name.toLowerCase().includes('') ||
            row.product.productDescription.toLowerCase().includes('') ||
            row.product.productCode.toLowerCase().includes('');
        })
        newRow.bundles = rowBundle
        newArray.push(newRow)
      }
    });

    setAllPaquetesSystem(newArray);
  };


  /* Tabla Productos */
  const columnsProductos = [
    { id: 'id', label: 'ID', minWidth: 170 },
    { id: 'productDescription', label: 'PRODUCTO', minWidth: 100 },
    { id: 'precio', label: 'PRECIO', minWidth: 170 },
    { id: 'icons', label: ' ', minWidth: 170, },
  ];

  const [rowsProducts, setRowsProducts] = useState([]);
  const [originalRowsProducts, setOriginalRowsProducts] = useState([]);
  const [pageProducts, setPageProducts] = useState(0);
  const [rowsPerPageProducts, setRowsPerPageProducts] = useState(7);

  const handleChangePageProducts = (event, newPage) => {
    setPageProducts(newPage);
  };

  const handleChangeRowsPerPageProducts = (event) => {
    setRowsPerPageProducts(+event.target.value);
    setPageProducts(0);
  };

  const requestSearchProducts = (searchedVal) => {
    const filteredRows = originalRowsProducts.filter((row) => {
      if (!row.isBundle)
        return row.productDescription.toLowerCase().includes(searchedVal.toLowerCase()) || row.productCode.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRowsProducts(filteredRows);
  };

  const cancelSearchProducts = () => {
    const filteredRows = originalRowsProducts.filter((row) => {
      if (!row.isBundle)
        return row.productDescription.toLowerCase().includes('') || row.productCode.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRowsProducts(filteredRows);
  };

  const handleQuantityIndividual = (e, row, product, bundle) => {
    if (e.target.value > 0) {
      addToShoppingCartValue(row, product, bundle, e.target.value)
    } else if (e.target.value <= 0) {
      removeFromShoppingCartValue(row, product, bundle)
    }
  }

  function RowProductos(props) {
    const { row } = props;
    let indexOriginal = findQuantityofPackage('producto', row.id)

    return (
      <Fragment>
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
          {columnsProductos && columnsProductos.map((column) => {
            const value = row[column.id];
            return (
              <TableCell key={column.id} align={column.align}>
                {column.id == 'icons' ?
                  <div>
                    <IconButton onClick={() => addToShoppingCart(row, 'producto')}>
                      <AddIcon style={{
                        fontFamily: 'Arial',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '30px',
                        color: '#000000',
                        background: '#D9D9D9',
                        borderRadius: '50%'
                      }} />
                    </IconButton>
                    <input
                      style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '20px',
                        lineHeight: '24px',
                        textAlign: 'center',
                        color: '#000000',
                        borderColor: 'transparent',
                        maxWidth: '15%'
                      }}
                      type="text"
                      name="name"
                      defaultValue={(shoppingCart && shoppingCart[indexOriginal] && shoppingCart[indexOriginal].quantity) ? shoppingCart[indexOriginal].quantity : 0}
                      onBlur={(e) => { handleQuantityIndividual(e, row, 'producto') }}
                    /><IconButton onClick={() => { removeFromShoppingCart(row, 'producto') }}>
                      <RemoveIcon style={{
                        fontFamily: 'Arial',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '30px',
                        color: '#000000',
                        background: '#D9D9D9',
                        borderRadius: '50%'
                      }} />
                    </IconButton>
                  </div>
                  : column.id == 'id' ? row.productCode
                    : column.id == 'precio' ? <text style={{
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '17px',
                      textAlign: 'right',
                      color: '#484F59'
                    }}>{(row && row.prices && row.prices.priceForSale) ? formatMoney(row.prices.priceForSale) : ''}</text> : column.format && typeof value === 'number' ? column.format(value) : value}
              </TableCell>
            );
          })}
        </TableRow>
      </Fragment >
    );
  }

  /* Tabla Modal */
  const columnsModal = [
    { id: 'id', label: 'CODIGO PROD', minWidth: 200 },
    { id: 'productDescription', label: 'PRODUCTO', minWidth: 200 },
    { id: 'productQuantity', label: 'CANTIDAD', minWidth: 200 },
    { id: 'productPrice', label: 'PRECIO', minWidth: 200 },
    { id: 'discountPercentage', label: 'DESCUENTO', minWidth: 200 },
    { id: 'total', label: 'TOTAL', minWidth: 200 },
  ];

  function createDataModal(rowsModalInner) {
    const map1 = rowsModalInner.map(item => {
      return {
        id: item.id,
        productDescription: item.product.productDescription,
        productQuantity: item.productQuantity,
        productPrice: item.productPrice,
        discountPercentage: item.discountPercentage,
        total: 0,
        productCode: item.product.productCode
      }
    });

    setRowsModal(map1);
  }

  const [rowsModal, setRowsModal] = useState([])

  /* CARRITO DE COMPRAS */

  const [shoppingCart, setShoppingCart] = useState([])

  const addToShoppingCart = (newItem, type, bundleInformation, quantity = 1) => {
    const copyCart = [...shoppingCart]
    const exists = copyCart.map((cartItem, index) => {
      if (cartItem.id == newItem.id && type == cartItem.type) {
        copyCart[index].quantity = copyCart[index].quantity + parseInt(quantity)
        return true
      } else return false
    })

    if (!exists.includes(true)) {
      console.log('Hago Push de: ', newItem)
      copyCart.push({
        information: newItem,
        type,
        id: newItem.id,
        quantity: parseInt(quantity),
        selected: false,
        isVest: false,
        productPrice: newItem && newItem.prices ? newItem.prices.priceForSale : 0,
        discountPercentage: 0,
        prices: newItem ? newItem.prices : 0,
        bundleInformation: bundleInformation
      })
    }
    setShoppingCart(copyCart)
    console.log(copyCart)
  }

  const addToShoppingCartValue = (newItem, type, bundleInformation, quantity = 1) => {
    const copyCart = [...shoppingCart]
    const exists = copyCart.map((cartItem, index) => {
      if (cartItem.id == newItem.id && type == cartItem.type) {
        copyCart[index].quantity = parseInt(quantity)
        return true
      } else return false
    })

    if (!exists.includes(true)) {
      copyCart.push({
        information: newItem,
        type,
        id: newItem.id,
        quantity: parseInt(quantity),
        selected: false,
        isVest: false,
        productPrice: newItem && newItem.prices ? newItem.prices.priceForSale : 0,
        discountPercentage: 0,
        prices: newItem ? newItem.prices : 0,
        bundleInformation: bundleInformation
      })
    }

    setShoppingCart(copyCart)
  }

  const removeFromShoppingCart = (newItem, type) => {
    const copyCart = [...shoppingCart]
    copyCart.map((cartItem, index) => {
      if (cartItem.id == newItem.id && type == cartItem.type) {
        if (copyCart[index].quantity > 1) copyCart[index].quantity = copyCart[index].quantity - 1
        else {
          copyCart.indexOf(index);
          copyCart.splice(index, 1);
        }
      }
    })
    setShoppingCart(copyCart)
  }

  const removeFromShoppingCartValue = (newItem, type) => {
    const copyCart = [...shoppingCart]
    copyCart.map((cartItem, index) => {
      if (cartItem.id == newItem.id && type == cartItem.type) {
        copyCart.indexOf(index);
        copyCart.splice(index, 1);
      }
    })
    setShoppingCart(copyCart)
  }

  const generateOrder = (newItem, type) => {
    const copyCart = [...shoppingCart]
    const exists = copyCart.map((cartItem, index) => {
      if (cartItem.id == newItem.id && type == cartItem.type) {
        copyCart[index].quantity = copyCart[index].quantity + 1
        return true
      } else return false
    })

    if (!exists.includes(true)) {
      copyCart.push({
        information: newItem,
        type,
        id: newItem.id,
        quantity: 1,
        selected: false,
        isVest: false,
        productPrice: newItem && newItem.prices ? newItem.prices.priceForSale : 0,
        discountPercentage: 0,
        prices: newItem ? newItem.prices : 0
      })
    }

    setShoppingCart(copyCart)

    props.history.push({
      pathname: '/orderDetails',
      state: { shoppingCart: copyCart, priceList, priceInputValue }
    });
  }

  function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [openToasty, setOpenToasty] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);

  const handleClickToasty = () => {
    setTransition(() => TransitionRight);
    setOpenToasty(true);
  };

  const handleCloseToasty = () => {
    setOpenToasty(false);
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        searchProductFromJSON(json)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  const searchProductFromJSON = (ProductList) => {
    const type = 'producto'
    const copyCart = [...shoppingCart]

    ProductList.map((individualProduct, index) => {
      products.map((product) => {
        if (product.productCode == individualProduct.codigo) {

          const exists = copyCart.map((cartItem, index) => {
            if (
              cartItem.id == product.id &&
              type == cartItem.type &&
              cartItem.discountPercentage == individualProduct.descuento &&
              cartItem.productPrice == individualProduct.precio
            ) {
              copyCart[index].quantity = copyCart[index].quantity + parseInt(individualProduct.cantidad)
              return true
            } else return false
          })

          const vestConfigurations = []
          if (individualProduct.color || individualProduct.talla) {
            for (let i = 0; i < individualProduct.cantidad; i++) {
              console.log(individualProduct)
              vestConfigurations.push(
                {
                  color: (findColorNameByCode(individualProduct.color, colorList) || ''),
                  size: (individualProduct && individualProduct.talla && individualProduct.talla.toUpperCase() || ''),
                  type: (individualProduct && individualProduct.tipo && individualProduct.tipo.toUpperCase() || ''),
                  datos: (individualProduct && individualProduct.dato && individualProduct.dato.toUpperCase() || ''),
                  colorStitch: (individualProduct && individualProduct.Colordato && individualProduct.Colordato.toUpperCase() || '')
                }
              )
            }
          }

          if (!exists.includes(true)) {
            copyCart.push({
              information: product,
              type,
              id: product.id,
              quantity: parseInt(individualProduct.cantidad),
              selected: false,
              isVest: false,
              productPrice: parseInt(individualProduct.precio),
              discountPercentage: parseInt(individualProduct.descuento),
              prices: product ? product.prices : 0,
              vestConfigurations
            })
          }
        }
      })
    })

    setShoppingCart(copyCart)
  }

  const refExcel = useRef()
  const handleClickExcel = (e) => {
    refExcel.current.click()
  }

  const handleClickTest = () => {
    dispatch(setShoppingCartRedux(shoppingCart))
  }

  return (
    <div style={{
      width: '100%',
      paddingTop: '16vh',
      height: '100%'
    }}>
      <Backdrop open={loaderOpen} style={{ zIndex: 10000, color: '#074e9b' }} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <nav className='c-quote-navbar' style={{ opacity: 1 }}>
        <button className='quote-navbar-c-history'
          onClick={() => {
            handleClickTest()
            if (props.location.state && props.location.state.state && props.location.state.state == "customPaquete")
              props.history.goBack()
            else
              props.history.push({ pathname: '/Inicio', state: { shoppingCart } })
          }}>
          <img className='quote-navbar-history' src={icoHistory} alt="" />
        </button>
        <h3 className='quote-navbar-title' >GENERADOR DE LISTA DE PRODUCTOS</h3>

      </nav>

      {/* TopBar */}
      <div style={{
        background: '#F0F0F0',
        display: 'flex'
      }}>
        <div style={{ width: '60%' }}>
          {/* <div style={{ marginTop: '20px', marginLeft: '50px' }}>
            <p style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '25px',
              lineHeight: '29px',
              display: 'flex',
              alignItems: 'center',
              color: '#000000'
            }}>¿Que deseas hacer?</p>
          </div>
          <div style={{ marginTop: '20px', marginLeft: '50px', marginBottom: '20px' }}>
            <FormControl variant="outlined" style={{
              width: '35%',
              height: '46px',
              boxSizing: 'border-box',
              background: '#FFFFFF',
              border: '1px solid rgba(0, 0, 0, 0.5)',
              borderRadius: '4px'
            }}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                labelWidth={'Tipo de Generador'}
                defaultValue='none'
                style={{
                  height: '46px',
                }}
              >
                <MenuItem value="none" disabled>
                  Tipo de generador
                </MenuItem>
                <MenuItem value={'1'}>Cotizacion</MenuItem>
                <MenuItem value={'2'}>Paquete</MenuItem>
                <MenuItem value={'3'}>Pedido</MenuItem>

              </Select>
            </FormControl>

          </div> */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '40%', justifyContent: 'flex-end' }}>
          <div style={{ marginTop: '20px' }}>
            <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>¿Qué desea Buscar?</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '45%' }}>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <FormControl variant="outlined" style={{
                  width: '100%',
                  height: '46px',
                  boxSizing: 'border-box',
                  background: '#FFFFFF',
                  border: '1px solid rgba(0, 0, 0, 0.5)',
                  borderRadius: '4px'
                }}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    labelWidth={'Tipo de busqueda'}
                    value={inputValue}
                    onChange={handleInputChange}
                    defaultValue='none'
                    style={{
                      height: '46px',
                    }}
                  >
                    <MenuItem value="none" disabled>
                      Tipo de busqueda
                    </MenuItem>
                    <MenuItem value={'cotizacionesGuardadas'}>Cotizaciones guardadas</MenuItem>
                    <MenuItem value={'paquetes'}>Paquetes guardados</MenuItem>
                    <MenuItem value={'productos'}>Productos</MenuItem>

                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={{ width: '45%' }}>
              <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                <SearchBar
                  value={searchedValuesGeneral}
                  onChange={(searchVal) => { requestSearch(searchVal) }}
                  onCancelSearch={() => cancelSearch()}
                  placeholder={'Buscar'}
                  style={{
                    width: '100%',
                    height: '46px',
                    boxSizing: 'border-box',
                    background: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.5)',
                    borderRadius: '4px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ maxHeight: '60vh', overflow: 'scroll', marginBottom: '8rem' }}>
        {/* Tabla Cotización */}
        {inputValue == 'cotizacionesGuardadas' &&
          <div style={{ margin: '10px auto 0px auto', width: '90%', height: '30%' }}>
            <Snackbar
              open={openToasty}
              onClose={handleCloseToasty}
              /* TransitionComponent={transition} */
              message="I love snacks"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              severity="success"
            >
              <Alert severity="success">
                SE A AGREGADO LA COTIZACION AL CARRITO
              </Alert>
            </Snackbar>
            <TableContainer style={{ maxHeight: '55vh' }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Cotización</p>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columnsCotizacion.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsQuote && rowsQuote.slice(pageQuote * rowsPerPageQuote, pageQuote * rowsPerPageQuote + rowsPerPageQuote).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columnsCotizacion.map((column) => {
                          const value = row[column.id];
                          const cliente = row.client
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {
                                column.id == 'id' ? value :
                                  column.id == 'deliveryDate' ? value :
                                    column.id == 'quotationName' ? value :
                                      column.id == 'cliente' ? row.client ? cliente.clientName : 'Sin Cliente' :
                                        column.id == 'realPrice' ? formatMoney(parseFloat(value)) :
                                          column.id == 'iva' ? value ? value : '16 %' :
                                            column.id == 'finalPrice' ? formatMoney(parseFloat(value)) :
                                              column.id == 'icon' ? <VisibilityIcon onClick={() => { handleOpen(row) }} /> :
                                                'pending'
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rowsQuote ? rowsQuote.length : 0}
              rowsPerPage={rowsPerPageQuote}
              page={pageQuote}
              onChangePage={handleChangePageQuote}
              onChangeRowsPerPage={handleChangeRowsPerPageQuote}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `${to}`}`
              }
              labelRowsPerPage={'Columnas por página'}
              style={{ height: '10%' }}
            />

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              maxWidth='xl'
            >
              <DialogTitle id="form-dialog-title" style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 800,
                fontSize: '24px',
                lineHeight: '20px',
                color: '#000000',
                marginTop: 20,
                marginLeft: 13,
                marginBottom: 18
              }}>Detalles de la cotización</DialogTitle>
              <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', justifyContent: 'space-between', marginRight: '100px', fontFamily: 'arial' }}>
                <div style={{ marginRight: '20px' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>Nombre del Paciente:</p>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '17px',
                    color: '#000'
                  }}>{
                      selectedRow && selectedRow.patient ?
                        `${selectedRow.patient.firstName || ''} ${selectedRow.patient.firstLastName || ''}` :
                        '---'
                    }</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>ATN:</p>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '17px',
                    color: '#000'
                  }}>{selectedRow ? (selectedRow.atn ? `${selectedRow.atn.firstName || ''} ${selectedRow.atn.secondName || ''} ${selectedRow.atn.firstLastname || ''} ${selectedRow.atn.secondLastname || ''}` : 'Sin ATN') : 'Sin ATN'}</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>Institución:</p>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '17px',
                    color: '#000'
                  }}>{selectedRow ? selectedRow.institutionName || 'Sin Institución' : 'Sin Institución'}</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>Ciudad y estado:</p>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '17px',
                    color: '#000'
                  }}>{selectedRow ? `${selectedRow.city || ''}, ${selectedRow.state || ''}` : ''}</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>Correo electronico:</p>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '17px',
                    color: '#000'
                  }}>{selectedRow ? selectedRow.email || 'Sin Email' : 'Sin Email'}</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>Fecha</p>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '17px',
                    color: '#000'
                  }}>{selectedRow ? selectedRow.deliveryDate || 'Sin Fecha' : 'Sin Fecha'}</p>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '17px',
                    color: '#484F59'
                  }}>{'Lista de Precio (ID | Nombre)'}</p>
                  <p style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#000'
                  }}>{(selectedRow && selectedRow.priceList) ? `${selectedRow.priceList.id ? selectedRow.priceList.id : ''} | ${selectedRow.priceList.description ? selectedRow.priceList.description : ''}` || 'Sin lista de precios' : 'Sin lista de precios'}</p>
                </div>
              </div>
              <DialogContent style={{ alignSelf: 'center' }}>
                <TableContainer style={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        {columnsModal && columnsModal.map((column, index) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ background: '#F8F9FA' }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsModal && rowsModal.map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columnsModal.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={'left'}>
                                  {
                                    column.id == 'id' ? row.productCode :
                                      column.id == 'productDescription' ? value :
                                        column.id == 'productQuantity' ? <text style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 400, fontSize: '20px', lineHeight: '24px', color: '#000000' }}>{value}</text> :
                                          column.id == 'productPrice' ? formatMoney(value) :
                                            column.id == 'discountPercentage' ? `${value} % ` :
                                              column.id == 'total' ? formatMoney(parseFloat(((row.productPrice * row.productQuantity) - ((row.productPrice * row.productQuantity) * (row.discountPercentage / 100))).toFixed(2))) :
                                                'null'
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {
                      selectedRow &&
                      <>
                        <TableRow>
                          <TableCell rowSpan={selectedRow.finalPrice < selectedRow.realPrice ? 4 : 3} />
                          <TableCell rowSpan={selectedRow.finalPrice < selectedRow.realPrice ? 4 : 3} />
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#484F59'
                          }}>Subtotal</TableCell>
                          <TableCell>{' - '}</TableCell>
                          <TableCell>{' - '}</TableCell>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#000000'
                          }}>{getRealPrice()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#484F59'
                          }}>Impuesto</TableCell>
                          <TableCell>{' - '}</TableCell>
                          <TableCell>{' - '}</TableCell>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#000000'
                          }}>{getTaxNumber()}</TableCell>
                        </TableRow>
                        {/* {
                          selectedRow.finalPrice < selectedRow.realPrice &&
                          <TableRow>
                            <TableCell style={{
                              fontFamily: 'Roboto',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              fontSize: '14px',
                              lineHeight: '17px',
                              color: '#484F59'
                            }}>Descuento</TableCell>
                            <TableCell>{' - '}</TableCell>
                            <TableCell>{' - '}</TableCell>
                            <TableCell style={{
                              fontFamily: 'Roboto',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              fontSize: '14px',
                              lineHeight: '17px',
                              color: '#000000'
                            }}>{formatMoney((selectedRow.realPrice + (selectedRow.realPrice * .16)) - selectedRow.finalPrice)}</TableCell>
                          </TableRow>
                        } */}
                        <TableRow>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#484F59'
                          }}>Total</TableCell>
                          <TableCell>{' - '}</TableCell>
                          <TableCell>{' - '}</TableCell>
                          <TableCell style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#000000'
                          }}>{getTotalFinal()}</TableCell>
                        </TableRow>
                      </>
                    }
                  </Table>
                </TableContainer>

              </DialogContent>
              <DialogActions style={{ marginTop: 100 }}>
                <button style={{
                  width: '239px',
                  height: '46px',
                  background: 'linear-gradient(90deg, #37A7B9 0%, #094070 100%)',
                  borderRadius: '50px',
                  marginRight: 29,
                  marginBottom: 33,
                  fontFamily: 'Arial',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                  texTransform: 'uppercase',
                  color: '#FFFFFF'
                }} onClick={() => { generateOrder(selectedRow, 'cotizacion') }}>GENERAR PEDIDO</button>

                <button style={{
                  width: '239px',
                  height: '46px',
                  background: 'linear-gradient(90deg, #37A7B9 0%, #094070 100%)',
                  borderRadius: '50px',
                  marginRight: 29,
                  marginBottom: 33,
                  fontFamily: 'Arial',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                  texTransform: 'uppercase',
                  color: '#FFFFFF'
                }}
                  onClick={() => {
                    addToShoppingCart(selectedRow, 'cotizacion')
                    handleClickToasty()
                    handleClose()
                  }}
                >AGREGAR AL CARRITO</button>
              </DialogActions>
            </Dialog>
          </div>
        }

        {/* Tabla Paquetes */}
        {
          inputValue == 'paquetes' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Paquetes</p>
            <TableContainer>
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                </TableHead>
                {allPaquetesSystem.map((systemsIndividual) => {
                  if (systemsIndividual.bundles && systemsIndividual.bundles.length > 0) {
                    return (
                      <>
                        <Typography style={{
                          marginTop: 40,
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '18px',
                          lineHeight: '21px',
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000'

                        }}>{systemsIndividual.product.productDescription}</Typography>
                        <TableBody>
                          {systemsIndividual.bundles.map((row) => (
                            <RowPaquete key={row.name} row={row} systemsIndividual={systemsIndividual} />
                          ))}
                        </TableBody>
                      </>
                    )
                  }
                })}
              </Table>
            </TableContainer>

          </div>
        }

        {
          inputValue == 'customPaquete' &&
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
            <p style={{
              marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline'
            }}>{`Paquetes ${inputValueType}`}</p>
            <TableContainer>
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                </TableHead>
                {allPaquetesSystem.map((systemsIndividual) => {
                  if (systemsIndividual.bundles && systemsIndividual.bundles.length > 0 && systemsIndividual.product.productCode == inputValueType) {
                    return (
                      <>
                        <Typography style={{
                          marginTop: 40,
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          fontSize: '18px',
                          lineHeight: '21px',
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000'

                        }}>{systemsIndividual.product.productDescription}</Typography>
                        <TableBody>
                          {systemsIndividual.bundles.map((row) => (
                            <RowPaquete key={row.name} row={row} systemsIndividual={systemsIndividual} />
                          ))}
                        </TableBody>
                      </>
                    )
                  }
                })}
              </Table>
            </TableContainer>

          </div>
        }

        {/* Tabla Productos */}
        {
          inputValue == 'productos' &&
          <div style={{ margin: '30px auto 40px auto', width: '90%', position: 'relative' }}>
            <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Productos</p>

            <TableContainer style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columnsProductos.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ background: '#F8F9FA' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsProducts && rowsProducts.slice(pageProducts * rowsPerPageProducts, pageProducts * rowsPerPageProducts + rowsPerPageProducts).map((row) => {
                    if (!row.idBundle) {
                      return (
                        <RowProductos key={row.name} row={row} />
                      )
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 10, 25, 100]}
              component="div"
              count={rowsProducts ? rowsProducts.length : 0}
              rowsPerPage={rowsPerPageProducts}
              page={pageProducts}
              onChangePage={handleChangePageProducts}
              onChangeRowsPerPage={handleChangeRowsPerPageProducts}
            />
          </div>
        }
      </div >

      {/* Bottom Bar */}
      < div style={{
        background: '#E5E9F2',
        display: 'flex',
        width: '100%',
        height: '82px',
        position: 'fixed',
        bottom: 40,
        justifyContent: 'space-between'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }} >
          <button style={{
            width: '175px',
            height: '46px',
            background: '#7EC573',
            borderRadius: '50px',
            marginLeft: 32,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }} onClick={handleClickExcel}>
            <img src={excelLogo} width={20} height={20} />
            <input
              ref={refExcel}
              type="file"
              hidden
              onChange={readUploadFile}
            />
            <text>Importar</text>
          </button>
        </div>
        {
          (inputValue == 'productos' || inputValue == 'paquetes') &&
          <div style={{ width: '50%' }}>

            <FormControl variant="outlined" style={{
              width: '35%',
              height: '46px',
              boxSizing: 'border-box',
              background: '#FFFFFF',
              border: '1px solid rgba(0, 0, 0, 0.5)',
              borderRadius: '4px',
              marginTop: 15,
              marginLeft: 80
            }}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                labelWidth={'Tipo de busqueda'}
                MenuProps={{
                  style: { maxHeight: 700 },
                  id: "demo-simple-select-outlined",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                value={priceInputValue}
                onChange={handlePriceInputChange}
                defaultValue='none'
                style={{
                  height: '46px',
                }}
              >
                <MenuItem value="none" disabled>Lista de Precios</MenuItem>
                {
                  priceList && priceList.map((individualPrice) => (
                    <MenuItem value={individualPrice.id} key={individualPrice.id}>{individualPrice.description}</MenuItem>
                  ))
                }

              </Select>
            </FormControl>

          </div>
        }
        <div style={{ justifyContent: 'flex-end', width: (inputValue == 'productos' || inputValue == 'paquetes') ? '50%' : '100%', display: 'flex' }}>
          <Popup
            trigger={<button style={{
              width: '298px',
              height: '50px',
              background: shoppingCart && shoppingCart.length > 0 ? '#0B4573' : '#484F59',
              borderRadius: '10px',
              marginTop: 15,
              marginRight: 80,
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <text style={{
                  marginLeft: 24,
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#FFFFFF',
                }}>{shoppingCart ? shoppingCart.length : 0} Productos Agregados</text>
                <VisibilityIcon style={{ color: '#FFFFFF', marginRight: 17 }} />
              </div>
            </button>}
            position="top center"
            closeOnDocumentClick
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px',
              width: '329px',
              background: '#FFF',
              filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
            }}>

              <div style={{ maxHeight: '40vh', overflow: 'scroll', marginTop: 20, width: '100%' }}>
                {shoppingCart && shoppingCart.map((item) => {
                  if (item.type == 'cotizacion' || item.type == 'paquete') {
                    return (
                      <div style={{
                        display: 'flex',
                        width: '100%'
                      }}>

                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', margin: 10, marginLeft: 16 }}>
                          <text style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#484F59',
                          }}>{item.type == 'cotizacion' ? 'Cotización Guardada' : 'Paquete Guardado'}</text>
                          <text style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#484F59',
                          }}>{item.information.quotationName || `${(item && item.bundleInformation && item.bundleInformation.productDescription) ? item.bundleInformation.productDescription : ''} - ${(item && item.information && item.information.configuration && item.information.configuration.name) ? item.information.configuration.name : ''}`}</text>
                        </div>

                      </div>
                    )
                  } else {
                    return (
                      <div style={{
                        display: 'flex',
                        width: '100%'
                      }}>
                        <div style={{ display: 'flex', width: '10%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 10 }}>
                          <text style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#000000',
                          }}>{item.quantity}</text>
                          <text style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#000000',
                          }}>pza</text>
                        </div>

                        <div style={{ display: 'flex', width: '90%', flexDirection: 'column', justifyContent: 'center', margin: 10 }}>
                          <text style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#484F59',
                          }}>{`ID - ${item.information.productCode}`}</text>
                          <text style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '17px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#484F59',
                          }}>{item.information.productDescription}</text>
                        </div>

                      </div>
                    )
                  }
                })}
              </div>

              <button style={{
                width: '239px',
                height: '46px',
                background: 'linear-gradient(90deg, #37A7B9 0%, #094070 100%)',
                borderRadius: '50px',
                marginTop: 14,
                marginBottom: 10,
                fontFamily: 'Arial',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '19px',
                textTransform: 'uppercase',
                color: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }} onClick={() => {
                handleClickTest()
                props.history.push({ pathname: '/orderDetails', state: { shoppingCart, priceList, priceInputValue } })
              }}>
                Siguiente
              </button>
            </div>
          </Popup>
        </div>
      </div >
    </div >
  );
};

