import {
    GET_SURVEY_BEGIN,
    GET_SURVEY_SUCCESS,
    GET_SURVEY_FAILURE,
    GET_SURVEY_TO_SEARCH_BEGIN,
    GET_SURVEY_TO_SEARCH_SUCCESS,
    GET_SURVEY_TO_SEARCH_FAILURE,
    SURVEY_ATQ_BEGIN,
    SURVEY_ATQ_SUCCESS,
    SURVEY_ATQ_FAILURE,
    SURVEY_ADMIN_ATQ_BEGIN,
    SURVEY_ADMIN_ATQ_SUCCESS,
    SURVEY_ADMIN_ATQ_FAILURE,
    SURVEY_CLASIFICATION_BEGIN,
    SURVEY_CLASIFICATION_SUCCESS,
    SURVEY_CLASIFICATION_FAILURE,
    SURVEY_QUALITY_MANAGER_BEGIN,
    SURVEY_QUALITY_MANAGER_SUCCESS,
    SURVEY_QUALITY_MANAGER_FAILURE,
    SURVEY_FIRST_CLASIFICATION_BEGIN,
    SURVEY_FIRST_CLASIFICATION_SUCCESS,
    SURVEY_FIRST_CLASIFICATION_FAILURE,
    SURVEY_MAINTENANCE_BEGIN,
    SURVEY_MAINTENANCE_SUCCESS,
    SURVEY_MAINTENANCE_FAILURE,
    SURVEY_MEDIC_BEGIN,
    SURVEY_MEDIC_SUCCESS,
    SURVEY_MEDIC_FAILURE,
    SURVEY_SALES_BEGIN,
    SURVEY_SALES_SUCCESS,
    SURVEY_SALES_FAILURE,
    SURVEY_SNC_BEGIN,
    SURVEY_SNC_SUCCESS,
    SURVEY_SNC_FAILURE,
    SURVEY_ISSUE_CAUSE_BEGIN,
    SURVEY_ISSUE_CAUSE_SUCCESS,
    SURVEY_ISSUE_CAUSE_FAILURE,
    SURVEY_STATUS_BEGIN,
    SURVEY_STATUS_SUCCESS,
    SURVEY_STATUS_FAILURE,
    SURVEY_WAREHOUSE_BEGIN,
    SURVEY_WAREHOUSE_SUCCESS,
    SURVEY_WAREHOUSE_FAILURE,
    SURVEY_PENDING_SURVEY_BEGIN,
    SURVEY_PENDING_SURVEEY_SUCCESS,
    SURVEY_PENDING_SURVEEY_FAILURE,
    SURVEY_SET_SURVEY_INFORMATION_BEGIN,
    SURVEY_SET_SURVEY_INFORMATION_SUCCESS,
    SURVEY_SET_SURVEY_INFORMATION_FAILURE,
    SET_SURVEY_COMMENTARY_ATQ_BEGIN,
    SET_SURVEY_COMMENTARY_ATQ_SUCCESS,
    SET_SURVEY_COMMENTARY_ATQ_FAILURE,
    SET_SURVEY_COMMENTARY_SELLER_BEGIN,
    SET_SURVEY_COMMENTARY_SELLER_SUCCESS,
    SET_SURVEY_COMMENTARY_SELLER_FAILURE,
    SET_SURVEY_COMMENTARY_MAINTENANCE_BEGIN,
    SET_SURVEY_COMMENTARY_MAINTENANCE_SUCCESS,
    SET_SURVEY_COMMENTARY_MAINTENANCE_FAILURE,
    SET_SURVEY_COMMENTARY_ADMIN_ATQ_BEGIN,
    SET_SURVEY_COMMENTARY_ADMIN_ATQ_SUCCESS,
    SET_SURVEY_COMMENTARY_ADMIN_ATQ_FAILURE,
    SET_SURVEY_COMMENTARY_WAREHOUSE_BEGIN,
    SET_SURVEY_COMMENTARY_WAREHOUSE_SUCCESS,
    SET_SURVEY_COMMENTARY_WAREHOUSE_FAILURE,
    SET_SURVEY_SELECTED,
    GET_SURVEY_FROM_ID_BEGIN ,                                         
    GET_SURVEY_FROM_ID_SUCCESS,
    GET_SURVEY_FROM_ID_FAILURE,
    UPDATE_PENDING_ACTION_BEGIN,
    UPDATE_PENDING_ACTION_SUCCESS,
    UPDATE_PENDING_ACTION_FAILURE,
    SET_SURVEY_COMMENTARY_QUALITY_BEGIN,
    SET_SURVEY_COMMENTARY_QUALITY_SUCCESS,
    SET_SURVEY_COMMENTARY_QUALITY_FAILURE,
    SEARCH_SURVEY_TO_SURGERY_ID_BEGIN,
    SEARCH_SURVEY_TO_SURGERY_ID_SUCCESS,
    SEARCH_SURVEY_TO_SURGERY_ID_FAILURE,
    SET_SURVEY_COMMENTARY_DIRECTORY_BEGIN,
    SET_SURVEY_COMMENTARY_DIRECTORY_SUCCESS,
    SET_SURVEY_COMMENTARY_DIRECTORY_FAILURE,

} from '../constants/actionTypes';

import request from '../utils/request';

//GET SURVEY

export const setCommentaryDirectoryBegin= () => ({
    type: SET_SURVEY_COMMENTARY_DIRECTORY_BEGIN,
});

export const setCommentaryDirectorySuccess = (response) => ({
    type: SET_SURVEY_COMMENTARY_DIRECTORY_SUCCESS,
    payload: {
        response
    }
});

export const setCommentaryDirectoryFailure = () => ({
    type: SET_SURVEY_COMMENTARY_DIRECTORY_FAILURE,
});
//-----------------------------------------------------
export const getSurveyToSearchBegin= () => ({
    type: GET_SURVEY_TO_SEARCH_BEGIN,
});

export const getSurveyToSearchSuccess = (getSurveySearch) => ({
    type: GET_SURVEY_TO_SEARCH_SUCCESS,
    payload: {
        getSurveySearch
    }
});

export const getSurveyToSearchFailure = () => ({
    type: GET_SURVEY_TO_SEARCH_FAILURE,
});
//-----------------------------------------------------
export const searchSurveyBySurgeryBegin= () => ({
    type: SEARCH_SURVEY_TO_SURGERY_ID_BEGIN,
});
export const searchSurveyBySurgerySuccess = (response) => ({
    type: SEARCH_SURVEY_TO_SURGERY_ID_SUCCESS,
    payload: {
        response
    }
});
export const searchSurveyBySurgeryFailure = () => ({
    type: SEARCH_SURVEY_TO_SURGERY_ID_FAILURE,
});

//----------------------------------------------------------------
export const setCommentaryQualityBegin= () => ({
    type: SET_SURVEY_COMMENTARY_QUALITY_BEGIN,
});
export const setCommentaryQualitySuccess = (response) => ({
    type: SET_SURVEY_COMMENTARY_QUALITY_SUCCESS,
    payload: {
        response
    }
});
export const setCommentaryQualityFailure = () => ({
    type: SET_SURVEY_COMMENTARY_QUALITY_FAILURE,
});

//----------------------------------------------------------------

export const updatePendingActionBegin= () => ({
    type: UPDATE_PENDING_ACTION_BEGIN,
});
export const updatePendingActionSuccess = (response) => ({
    type: UPDATE_PENDING_ACTION_SUCCESS,
    payload: {
        response
    }
});
export const updatePendingActionFailure = () => ({
    type: UPDATE_PENDING_ACTION_FAILURE,
});

//----------------------------------------------------------------
export const setCommentaryWareHouseBegin= () => ({
    type: SET_SURVEY_COMMENTARY_WAREHOUSE_BEGIN,
});
export const setCommentaryWareHouseSuccess = (response) => ({
    type: SET_SURVEY_COMMENTARY_WAREHOUSE_SUCCESS,
    payload: {
        response
    }
});
export const setCommentaryWareHouseFailure = () => ({
    type: SET_SURVEY_COMMENTARY_WAREHOUSE_FAILURE,
});

//----------------------------------------------------------------
export const setCommentaryAdminATQBegin= () => ({
    type: SET_SURVEY_COMMENTARY_ADMIN_ATQ_BEGIN,
});
export const setCommentaryAdminATQSuccess = (response) => ({
    type: SET_SURVEY_COMMENTARY_ADMIN_ATQ_SUCCESS,
    payload: {
        response
    }
});
export const setCommentaryAdminATQFailure = () => ({
    type: SET_SURVEY_COMMENTARY_ADMIN_ATQ_FAILURE,
});

//----------------------------------------------------------------
export const setCommentaryMaintenanceBegin= () => ({
    type: SET_SURVEY_COMMENTARY_MAINTENANCE_BEGIN,
});
export const setCommentaryMaintenanceSuccess = (response) => ({
    type: SET_SURVEY_COMMENTARY_MAINTENANCE_SUCCESS,
    payload: {
        response
    }
});
export const setCommentaryMaintenanceFailure = () => ({
    type: SET_SURVEY_COMMENTARY_MAINTENANCE_FAILURE,
});

//----------------------------------------------------------------
export const setCommentarySellerBegin= () => ({
    type: SET_SURVEY_COMMENTARY_SELLER_BEGIN,
});
export const setCommentarySellerSuccess = (response) => ({
    type: SET_SURVEY_COMMENTARY_SELLER_SUCCESS,
    payload: {
        response
    }
});
export const setCommentarySellerFailure = () => ({
    type: SET_SURVEY_COMMENTARY_SELLER_FAILURE,
});

//------------------------------------------
export const setCommentaryATQBegin= () => ({
    type: SET_SURVEY_COMMENTARY_ATQ_BEGIN,
});
export const setCommentaryATQSuccess = (response) => ({
    type: SET_SURVEY_COMMENTARY_ATQ_SUCCESS,
    payload: {
        response
    }
});
export const setCommentaryATQFailure = () => ({
    type: SET_SURVEY_COMMENTARY_ATQ_FAILURE,
});

//------------------------
export const setSurveySelected = (response) => ({
    type: SET_SURVEY_SELECTED,
    payload: {
        response
    }
});

export const surveySetInformationBegin= () => ({
    type: SURVEY_SET_SURVEY_INFORMATION_BEGIN,
});
export const surveySetInformationSuccess = (response) => ({
    type: SURVEY_SET_SURVEY_INFORMATION_SUCCESS,
    payload: {
        response
    }
});
export const surveySetInformationFailure = () => ({
    type: SURVEY_SET_SURVEY_INFORMATION_FAILURE,
});
//servey pending
export const surveyPendingBegin= () => ({
    type: SURVEY_PENDING_SURVEY_BEGIN,
});
export const surveyPendingSuccess = (response) => ({
    type: SURVEY_PENDING_SURVEEY_SUCCESS,
    payload: {
        response
    }
});
export const surveyPendingFailure = () => ({
    type: SURVEY_PENDING_SURVEEY_FAILURE,
});

//SURVEY WHAREHOUSE
export const surveyWareHouseBegin= () => ({
    type: SURVEY_WAREHOUSE_BEGIN,
});

export const surveyWareHouseSuccess = (response) => ({
    type: SURVEY_WAREHOUSE_SUCCESS,
    payload: {
        response
    }
});

export const surveyWareHouseFailure = () => ({
    type: SURVEY_WAREHOUSE_FAILURE,
});

//survey status
export const surveyStatusBegin = () => ({
    type: SURVEY_STATUS_BEGIN,
});

export const surveyStatusSuccess = (response) => ({
    type: SURVEY_STATUS_SUCCESS,
    payload: {
        response
    }
});

export const surveyStatusFailure = () => ({
    type: SURVEY_STATUS_FAILURE,
});

//SURVEY ISSUE CAUSE
export const surveyCauseBegin= () => ({
    type: SURVEY_ISSUE_CAUSE_BEGIN,
});

export const surveyCauseSuccess = (response) => ({
    type: SURVEY_ISSUE_CAUSE_SUCCESS,
    payload: {
        response
    }
});

export const surveyCauseFailure = () => ({
    type: SURVEY_ISSUE_CAUSE_FAILURE,
});

//SNC SURVEY
export const surveySNCBegin = () => ({
    type: SURVEY_SNC_BEGIN,
});

export const surveySNCSuccess = (response) => ({
    type: SURVEY_SNC_SUCCESS,
    payload: {
        response
    }
});

export const surveySNCFailure = () => ({
    type: SURVEY_SNC_FAILURE,
});
//SURVEY SALES
export const surveySalesBegin= () => ({
    type: SURVEY_SALES_BEGIN,
});

export const surveySalesSuccess = (response) => ({
    type: SURVEY_SALES_SUCCESS,
    payload: {
        response
    }
});

export const surveySalesFailure = () => ({
    type: SURVEY_SALES_FAILURE,
});

//MEDICO
export const surveyMedicBegin= () => ({
    type: SURVEY_MEDIC_BEGIN,
});

export const surveyMedicSuccess = (response) => ({
    type: SURVEY_MEDIC_SUCCESS,
    payload: {
        response
    }
});

export const surveyMedicFailure = () => ({
    type: SURVEY_MEDIC_FAILURE,
});

//SURVEY MAINTENANCE
export const surveyMaintenanceBegin= () => ({
    type: SURVEY_MAINTENANCE_BEGIN,
});

export const surveyMaintenanceSuccess = (response) => ({
    type: SURVEY_MAINTENANCE_SUCCESS,
    payload: {
        response
    }
});

export const surveyMaintenanceFailure = () => ({
    type: SURVEY_MAINTENANCE_FAILURE,
});

//FIRST CLASIFICATION
export const firstClasificationBegin= () => ({
    type: SURVEY_FIRST_CLASIFICATION_BEGIN,
});

export const firstClasificationSuccess = (response) => ({
    type: SURVEY_FIRST_CLASIFICATION_SUCCESS,
    payload: {
        response
    }
});

export const firstClasificationFailure = () => ({
    type: SURVEY_FIRST_CLASIFICATION_FAILURE,
});

//QUALITY MANAGER SURVEY
export const surQualityManBegin= () => ({
    type: SURVEY_QUALITY_MANAGER_BEGIN,
});

export const surQualityManSuccess = (response) => ({
    type: SURVEY_QUALITY_MANAGER_SUCCESS,
    payload: {
        response
    }
});

export const surQualityManFailure = () => ({
    type: SURVEY_QUALITY_MANAGER_FAILURE,
});

//SURVEY CLASIFICATION

export const surveyClasificationBegin= () => ({
    type: SURVEY_CLASIFICATION_BEGIN,
});

export const surveyClasificationSuccess = (response) => ({
    type: SURVEY_CLASIFICATION_SUCCESS,
    payload: {
        response
    }
});

export const surveyClasificationFailure = () => ({
    type: SURVEY_CLASIFICATION_FAILURE,
});


//GET SURVEY

export const getSurveyBegin= () => ({
    type: GET_SURVEY_BEGIN,
});

export const getSurveySuccess = (getSurvey) => ({
    type: GET_SURVEY_SUCCESS,
    payload: {
        getSurvey
    }
});

export const getSurveyFailure = () => ({
    type: GET_SURVEY_FAILURE,
});

//ATQAdminSurvey

export const surveyATQAdminBegin= () => ({
    type: SURVEY_ADMIN_ATQ_BEGIN ,
});

export const surveyATQAdminSuccess = (surveyATQAdmin) => ({
    type: SURVEY_ADMIN_ATQ_SUCCESS,
    payload: {
        surveyATQAdmin
    }
});

export const surveyATQAdminFailure = () => ({
    type: SURVEY_ADMIN_ATQ_FAILURE,
});

//ATQSurvey
export const surveyATQBegin= () => ({
    type: SURVEY_ATQ_BEGIN ,
});

export const surveyATQSuccess = (surveyATQ) => ({
    type: SURVEY_ATQ_SUCCESS,
    payload: {
        surveyATQ
    }
});

export const surveyATQFailure = () => ({
    type: SURVEY_ATQ_FAILURE,
});

//Search survey
export const surveySearchATQBegin = () => ({
    type: GET_SURVEY_FROM_ID_BEGIN ,
});

export const surveySearchATQSuccess = (getSurveyId) => ({
    type: GET_SURVEY_FROM_ID_SUCCESS,
    payload: {
        getSurveyId
    }
});

export const surveySearchATQFailure = () => ({
    type: GET_SURVEY_FROM_ID_FAILURE,
});


export function setCommentaryDirectory(data,SurveyId) {
    return function (dispatch, getState) {
        dispatch(setCommentaryDirectoryBegin());
        return request({
            partialUrl: `/API/Survey/SurveyCommentary`,
            method: 'POST',
            state: getState().user.request,
            body: {
                SurveyId:SurveyId,
                Description:data.Commentary,
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setCommentaryDirectorySuccess(data.response));
        })
        .catch(err => {
            return dispatch(setCommentaryDirectoryFailure(err));
        });
    }
}

export function getSurveyToSearch( surveyStatus=0, surveyUserrole=0 ,surveyDate='', currentPage=0, offset=1000  ) {
    return function (dispatch, getState) {
        dispatch(getSurveyToSearchBegin());
        return request({
            partialUrl: `/API/Survey/GetSurvey?surveyStatus=${surveyStatus}&surveyUserrole=${surveyUserrole}&surveyDate=${surveyDate}&currentPage=${currentPage}&offset=${offset}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(getSurveyToSearchSuccess(data.response));
        })
        .catch(err => {
            return dispatch(getSurveyToSearchFailure(err));
        });
    }
}

export function searchSurveyBySurgery(id) {
    return function (dispatch, getState) {
         dispatch(searchSurveyBySurgeryBegin());
        return request({
            partialUrl: `/API/Survey/GetSurveyFromSurgeryId?Id=${id}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(searchSurveyBySurgerySuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(searchSurveyBySurgeryFailure(err));
        }); 
    }
}

export function surveySetNewCommentaryQuality(data,QualityManagerSurveyId) {
    return function (dispatch, getState) {
         dispatch(setCommentaryQualityBegin());
        return request({
            partialUrl: `/API/Survey/QualityManagerSurveyCommentary`,
            method: 'POST',
            state: getState().user.request,
            body: {
                QualityManagerSurveyId:QualityManagerSurveyId,
                Description:data.Commentary,
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setCommentaryQualitySuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(setCommentaryQualityFailure(err));
        }); 
    }
}

export function updatePendigAction(data) {
    return function (dispatch, getState) {
         dispatch(updatePendingActionBegin());
        return request({
            partialUrl: `/API/Survey/UpdatePendingSurveyAction`,
            method: 'PUT',
            state: getState().user.request,
            body: {...data}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(updatePendingActionSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(updatePendingActionFailure(err));
        }); 
    }
}


export function surveySetCommetMaintenance(data,MaintenanceSurveyId) {
    return function (dispatch, getState) {
         dispatch(setCommentaryMaintenanceBegin());
        return request({
            partialUrl: `/API/Survey/MaintenanceSurveyCommentary`,
            method: 'POST',
            state: getState().user.request,
            body: {
                MaintenanceSurveyId:MaintenanceSurveyId,
                Description:data.Commentary,
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setCommentaryMaintenanceSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(setCommentaryMaintenanceFailure(err));
        }); 
    }
}

export function surveySetCommetWareHouse(data,WarehouseSurveyId) {
    return function (dispatch, getState) {
         dispatch(setCommentaryWareHouseBegin());
        return request({
            partialUrl: `/API/Survey/WarehouseSurveyCommentary`,
            method: 'POST',
            state: getState().user.request,
            body: {
                WarehouseSurveyId:WarehouseSurveyId,
                Description:data.Commentary,
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setCommentaryWareHouseSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(setCommentaryWareHouseFailure(err));
        }); 
    }
}

export function surveySetCommetAdminAtq(data,AdminATQSurveyId) {
    return function (dispatch, getState) {
         dispatch(setCommentaryAdminATQBegin());
        return request({
            partialUrl: `/API/Survey/AdminATQSurveyCommentary`,
            method: 'POST',
            state: getState().user.request,
            body: {
                AdminATQSurveyId:AdminATQSurveyId,
                Description:data.Commentary,
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setCommentaryAdminATQSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(setCommentaryAdminATQFailure(err));
        }); 
    }
}

export function surveySetCommetSelller(data,SalesSurveyId) {
    return function (dispatch, getState) {
         dispatch(setCommentarySellerBegin());
        return request({
            partialUrl: `/API/Survey/SalesSurveyCommentary`,
            method: 'POST',
            state: getState().user.request,
            body: {
                SalesSurveyId:SalesSurveyId,
                Description:data.Commentary,
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setCommentarySellerSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(setCommentarySellerFailure(err));
        }); 
    }
}

export function surveySetCommetATQ(data,ATQSurveyId) {
    /* console.warn('aqui es: ',data.Commentary,ATQSurveyId) */
    return function (dispatch, getState) {
         dispatch(setCommentaryATQBegin());
        return request({
            partialUrl: `/API/Survey/ATQSurveyCommentary`,
            method: 'POST',
            state: getState().user.request,
            body: {
                ATQSurveyId:ATQSurveyId,
                Description:data.Commentary,
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(setCommentaryATQSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(setCommentaryATQFailure(err));
        }); 
    }
}

export function surveySetInformation(commentary,date,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveySetInformationBegin());
        return request({
            partialUrl: `/API/Survey/SetSurveyInformation`,
            method: 'PUT',
            state: getState().user.request,
            body: {
                Survey:{
                    Commentary: '',
                    SugestedCloseDate: date,
                    ClosedAt:date,
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveySetInformationSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveySetInformationFailure(err));
        });
    }
}

export function surveyPending(data) {
    return function (dispatch, getState) {
        dispatch(surveyPendingBegin());
        return request({
            partialUrl: `/API/Survey/PendingSurveyAction`,
            method: 'POST',
            state: getState().user.request,
            body: {...data}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyPendingSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveyPendingFailure(err));
        });
    }
}

export function surveyWareHouse(WarehouseSurvey,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveyWareHouseBegin());
        return request({
            partialUrl: `/API/Survey/WarehouseSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                WarehouseSurvey,
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyWareHouseSuccess(data.response));
            return true;
        })
        .catch(err => {
            return dispatch(surveyWareHouseFailure(err));
        });
    }
}


export function surveyStatus(status,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveyStatusBegin());
        return request({
            partialUrl: `/API/Survey/SurveyStatus`,
            method: 'POST',
            state: getState().user.request,
            body: {
                SurveyStatus:{
                    Status:status
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyStatusSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveyStatusFailure(err));
        });
    }
}

export function surveyIssueCause(causes,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveyCauseBegin());
        return request({
            partialUrl: `/API/Survey/SurveyIssueCause`,
            method: 'POST',
            state: getState().user.request,
            body: {
                SurveyIssueCause:{
                    BadMedicTechnique: causes.tecnica,
                    OtherCommentary:causes.comOtra,
                    Other: causes.otra,
                    MedicSpecialRequirement: causes.requisito,
                    IncompleteMaterial: causes.material,
                    LowStockLevel: causes.stock,
                    WearByUse: causes.desgaste
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyCauseSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveyCauseFailure(err));
        });
    }
}

export function surveySNC(snc,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveySNCBegin());
        return request({
            partialUrl: `/API/Survey/SNCSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                SNCSurvey:{
                    InadecuateRFCCaptation: snc.capacitacion,
                    InadequateTechnicalAdvice: snc.asesoria,
                    IncompleteMaterial: snc.material,
                    Unpunctuality: snc.impuntualidad,
                    UntimelyDeliveryOfEquipment: snc.entrega
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveySNCSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveySNCFailure(err));
        });
    }
}

export function surveySales(SalesSurvey,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveySalesBegin());
        return request({
            partialUrl: `/API/Survey/SalesSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                SalesSurvey,
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }

            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveySalesSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(surveySalesFailure(err));
        });
    }
}


export function surveyMedic(data) {
    return function (dispatch, getState) {
        dispatch(surveyMedicBegin());
        return request({
            partialUrl: `/API/Survey/MedicSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {...data}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyMedicSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveyMedicFailure(err));
        });
    }
}

export function surveyMaintenance(MaintenanceSurvey,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveyMaintenanceBegin());
        return request({
            partialUrl: `/API/Survey/MaintenanceSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                MaintenanceSurvey,
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyMaintenanceSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(surveyMaintenanceFailure(err));
        });
    }
}

export function setFirstClasification(FirstClasificationSurvey,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(firstClasificationBegin());
        return request({
            partialUrl: `/API/Survey/FirstClasificationSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                
                FirstClasificationSurvey:{
                    Status:FirstClasificationSurvey
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(firstClasificationSuccess(data.response));
        })
        .catch(err => {
            return dispatch(firstClasificationFailure(err));
        });
    }
}

export function surveyQualityManager(commentary,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surQualityManBegin());
        return request({
            partialUrl: `/API/Survey/QualityManagerSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                QualityManagerSurvey:{
                    DirectionCommentary: "",
                    MedicFollowUpCommentary:commentary
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surQualityManSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surQualityManFailure(err));
        });
    }
}
export function surveyDirectorManager(commentary,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surQualityManBegin());
        return request({
            partialUrl: `/API/Survey/QualityManagerSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                QualityManagerSurvey:{
                    DirectionCommentary: commentary,
                    MedicFollowUpCommentary:""
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surQualityManSuccess(data.response));
            return true;
        })
        .catch(err => {
            return dispatch(surQualityManFailure(err));
        });
    }
}

export function surveyClasification(ClasificationSurvey,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveyClasificationBegin());
        return request({
            partialUrl: `/API/Survey/ClasificationSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                ClasificationSurvey:{
                    Id:ClasificationSurvey
                },
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyClasificationSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveyClasificationFailure(err));
        });
    }
}

export function getSurvey( surveyStatus=0, surveyUserrole=0 , currentPage=0, offset=10  ) {
    return function (dispatch, getState) {
        dispatch(getSurveyBegin());
        return request({
            partialUrl: `/API/Survey/GetSurvey?surveyStatus=${surveyStatus}&surveyUserrole=${surveyUserrole}&currentPage=${currentPage}&offset=${offset}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(getSurveySuccess(data.response));
        })
        .catch(err => {
            return dispatch(getSurveyFailure(err));
        });
    }
}


export function surveyATQAdmin(adminATQSurvey,SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveyATQAdminBegin());
        return request({
            partialUrl: `/API/Survey/AdminATQSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {
                adminATQSurvey,
                SurgeryAppointment:{
                    Id: SurgeryAppointment
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyATQAdminSuccess(data.response));
            return true 
        })
        .catch(err => {
            return dispatch(surveyATQAdminFailure(err));
        });
    }
}



export function surveyATQ(ATQSurvey, SurgeryAppointment) {
    return function (dispatch, getState) {
        dispatch(surveyATQBegin());
        return request({
            partialUrl: `/API/Survey/ATQSurvey`,
            method: 'POST',
            state: getState().user.request,
            body: {

                ATQSurvey,
                SurgeryAppointment:{
                    Id: SurgeryAppointment,
                }
            }
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveyATQSuccess(data.response));
            return true
        })
        .catch(err => {
            return dispatch(surveyATQFailure(err));
        });
    }
}

export function getSurveySearchId(Id) {
    return function (dispatch, getState) {
        dispatch(surveySearchATQBegin());
        return request({
            partialUrl: `/API/Survey/GetSurveyFromSurgeryId?Id=${Id}`,
            method: 'GET',
            state: getState().user.request,
            body: {}
        })
        .then(response => response.json())
        .then(data => {
            dispatch(surveySearchATQSuccess(data.response));
        })
        .catch(err => {
            return dispatch(surveySearchATQFailure(err));
        });
    }
}
