import React, { useEffect } from 'react'
import PropTypes from 'prop-types';

export const StoreHomeAvailableMaterialToAddSearchComboBox = ({typeList, setTypeSearch, typeSearch}) => {

        useEffect(() => {
                typeList === 'LOCATIONS' &&
                        setTypeSearch(0);
                typeList === 'PRODUCTS' &&
                        setTypeSearch('productCode');


        }, [typeList])

    return (
        <>
            {
                typeList === 'LOCATIONS' &&
                    <select id="cars" 
                            onChange={(e) => setTypeSearch(e.target.value)} 
                            value={[typeSearch]} 
                            className='quote-navbar-search-input-combobox' 
                            multiple={false}>
                        <option 
                                value={0}>
                                Código producto
                        </option>
                        <option 
                                value={1}>
                                Local
                        </option>
                        <option 
                                value={2}>
                                Propietario
                        </option>
                        <option 
                                value={3}>
                                Condición
                        </option>
                        <option 
                                value={4}>
                                Filial
                        </option>
                        <option 
                                value={5}>
                                Ubicacion Code
                        </option>
                        <option 
                                value={6}>
                                Descripción
                        </option>
                    </select>
            }
            {
                typeList === 'PRODUCTS' &&
                    <select id="cars" 
                            onChange={(e) => setTypeSearch(e.target.value)} 
                            value={[typeSearch]} 
                            className='quote-navbar-search-input-combobox' 
                            multiple={false}>
                        <option 
                                value={'productCode'}>
                                Codigo del producto
                        </option>
                        <option 
                                value={'productDescription'}>
                                Descripción de producto
                        </option>
                    </select>
            }
        </>
    )
}

StoreHomeAvailableMaterialToAddSearchComboBox.propTypes = {
    typeList: PropTypes.any,
    setTypeSearch: PropTypes.any,
    typeSearch: PropTypes.any,
}