import React from 'react';
import { formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import './styleMessageRigth.scss';

export const MessageRigthComponent = ({ messages,userSend,hour,media}) => {

  //const nameSender = useSelector(state => state.notificationChat)
  
  return (
          <div className='container-message-rigth-send-component'>
          <p className='user-name-chat-conversation'>{userSend && userSend}</p>
            <div className='subcontainer-message-rigth-send-component'>
              <p className='contents-msg-title-send'>{messages && messages}</p>
              {
                media && 
                <img className='img-send-chat' src={media}></img>
              }
              <p className='content-hour-message'>{formatHourToPresent(hour && hour)}</p>
            </div>
          </div>
        
  )
}
