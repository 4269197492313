import React, { useEffect, useState } from 'react'
import CustomModal from '../../shared/Modal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';
import './sass/styles.scss';
import { getAtqAvailability, getAtqAvailableSuccess, requestChageAtqToAppiment } from '../../../actions/atqAdministradorAction';
import { ErrorModal } from '../../../pages/ScheduleSurgery/ScheduleModals/ErrorModal/ErrorModal';
import { useHistory } from 'react-router-dom';

export const ModalSelectionATQ = ({isOpen, toggle}) => {
    
    const modalBody = () => {
        const dispatch = useDispatch();
        const surgerySelected = useSelector( (state) => state.atqAdministrador.surgeryChosed);
        const atqAvailable = useSelector( (state) => state.atqAdministrador.atqAvailable);
        const [modalError, setModalError] = useState(null);
        const history = useHistory();

        const toogleError = () => {
            setModalError('CLOSED')
        } 

        useEffect(() => {
            dispatch(getAtqAvailability(true, surgerySelected.surgeryDate));
            return () => {
                dispatch(getAtqAvailableSuccess([]));
            }
        }, [])

        const [ formValuesInput, handleInputChangeInputs] = useForm({
            atqSelected: ''
        });

        const { atqSelected } = formValuesInput;


        const handleAtn = (e) => {
            e.preventDefault();
        }

        const handleSubmit = () => {
            if(atqSelected !== ''){
                history.replace(`/ATQ_messagge_notification_transfered/${surgerySelected.id}`);
                dispatch(requestChageAtqToAppiment(
                    {
                    SurgeryId:surgerySelected.id,
                    ATQOrigin:{
                        UserID:surgerySelected.atq.userID
                    },
                    ATQTarget:{
                        UserID:JSON.parse(atqSelected).userID
                    }
                    }
                ));
                toggle();
            }else{
                setModalError('OPEN');
            }
        }
      

        return (
            <div className="container-ModalATQSelected-all">

                <form className="container-ModalATQSelected-list-items" onSubmit={handleAtn}>
                    <div className="container-ModalATQSelected-items-scroll">

                        <div className='container-ModalATQSelected'>
                            {
                                atqAvailable &&
                                atqAvailable.map((item) => (
                                    <div key={item.id} className="ModalATQSelected-c-item">
                                    <div className={"ModalATQSelected-c-item-status  ModalATQSelected-c-item-status-active"}></div>
                                    {/* <div className={item.status ? "ModalATQSelected-c-item-status  ModalATQSelected-c-item-status-active": "ModalATQSelected-c-item-status ModalATQSelected-c-item-status-inactive"}></div>  */}
                                    <span className="ModalATQSelected-item-name">{item.firstName && item.firstName} {item.secondName && item.secondName} {item.firstLastName && item.firstLastName} {item.secondLastName && item.secondLastName}</span>
                                    <input className="ModalATQSelected-item-check" 
                                            type="radio"
                                            checked={ atqSelected && JSON.parse(atqSelected).userID === item.userID}
                                            value={JSON.stringify(item)}
                                            name='atqSelected'
                                            onChange={handleInputChangeInputs}
                                    />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <button className="ModalATQSelected-button" onClick={()=> handleSubmit()}>
                        TRANSFERIR
                    </button>
                </form>

        {
            modalError === 'OPEN' &&
                <ErrorModal
                    isOpen
                    toggle={toogleError}
                    setOpenModalError={setModalError}
                    messageModal={'¡No se ha seleccionado ATQ!'}
                />
        }
            </div>
        );
    };

    return (
        <CustomModal
                isOpen={isOpen}
                toggle={toggle}
                title="ATQ’S"
                body={modalBody()}
        />
    );
}

ModalSelectionATQ.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    messageModal: PropTypes.any,
    lastOpenModal: PropTypes.any,
    setOpenModal: PropTypes.any,
    setOpenModalError: PropTypes.any,
};