import { combineReducers } from 'redux';
import fuelSavings from './fuelSavingsReducer';
import { connectRouter } from 'connected-react-router'

import userReducer from './usersReducer';
import { sideReducer } from './sideReducer';
import { searchReducer } from './searchReducer';
import { systemsReducer } from './systemsReducer';
import { newQuoteReducer } from './newQuoteReducer';
import { scheduleSurgeryReducer } from './scheduleSurgeryReducer';
import { allUsersReducer } from './allUsersReducer';
import { scheduleReducer } from './scheduleReducer';
import { storeHouseReducer } from './storeHouseReducer';
import { atqAdministradorReducer } from './atqAdministradorReducer';
import { guardGroupHolidayReducer } from './guardGroupHolidayReducer';
import { surveyReducer } from './surveyReducer';
import { NotificationChatReducer } from './notification-chatReducer';




const rootReducer = history => combineReducers({
  router: connectRouter(history),
  fuelSavings,
  sidebar: sideReducer,
  search: searchReducer,
  user:userReducer,
  systems: systemsReducer,
  newQuote: newQuoteReducer,
  scheduleSurgery: scheduleSurgeryReducer,
  allUsers: allUsersReducer,
  schedule: scheduleReducer,
  storeHouse: storeHouseReducer,
  atqAdministrador: atqAdministradorReducer,
  guardGroupHoliday: guardGroupHolidayReducer,
  survey:surveyReducer,
  notificationChat: NotificationChatReducer,
});

export default rootReducer;
