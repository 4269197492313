import shortid from 'shortid';

export const WayToPayData = [
    {
      id: shortid.generate(),
      name:'Tarjeta de crédito/débito'
    },
    {
      id: shortid.generate() ,
      name:'Transferencia'
    },
    {
      id: shortid.generate() ,
      name:'Cheque'
    },
    {
      id: shortid.generate() ,
      name:'Depósito'
    },
    {
      id: shortid.generate() ,
      name:'Efectivo'
    },
]




