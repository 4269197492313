import request from '../utils/request';
import newRequest from '../utils/newRequest'
import {
  USER_LOGIN_BEGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_BEGIN,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_SIGNUP_BEGIN,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILURE,
  USER_SIGNUP_CLEAN,
  GET_PENDING_APPROVAL_USERS_BEGIN,
  GET_PENDING_APPROVAL_USERS_SUCCESS,
  GET_PENDING_APPROVAL_USERS_FAILURE,
  SET_UNAPPROVE_USER_BEGIN,
  SET_UNAPPROVE_USER_SUCCESS,
  SET_UNAPPROVE_USER_FAILURE,
  SET_APPROVE_USER_BEGIN,
  SET_APPROVE_USER_SUCCESS,
  SET_APPROVE_USER_FAILURE,
  SET_USER_PERMISSION_BEGIN,
  SET_USER_PERMISSION_SUCCESS,
  SET_USER_PERMISSION_FAILURE,
  GET_ROLS_BEGIN,
  GET_ROLS_SUCCESS,
  GET_ROLS_FAILURE,
  RECOVERY_PASS_BEGIN,
  RECOVERY_PASS_SUCCESS,
  RECOVERY_PASS_FAILURE,
  CLEAN_UNAPPROVE_USER,
  CLEAN_APPROVE_USER,
  RESET_PASS_BEGIN,
  RESET_PASS_SUCCESS,
  RESET_PASS_FAILURE,
  RESET_PASS_CLEAN,
  CLEAN_APPROVE_UNAPROVE_USER,
  ADD_USER_PERMISSION_BEGIN,
  ADD_USER_PERMISSION_SUCCESS,
  ADD_USER_PERMISSION_FAILURE,
  REMOVE_USER_PERMISSION_BEGIN,
  REMOVE_USER_PERMISSION_SUCCESS,
  REMOVE_USER_PERMISSION_FAILURE,
  GET_PERMISSION_BEGIN,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILURE,
  GET_ALL_USERS_BEGIN,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  SEARCH_USERS_BEGIN,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  GET_CLIENT_BY_RFC_BEGIN,
  GET_CLIENT_BY_RFC_SUCCESS,
  GET_CLIENT_BY_RFC_FAILURE,
  ADD_CLIENT_BEGIN,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  ADD_CONTACT_BEGIN,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
  ADD_CLIENT_BILLING_BEGIN,
  ADD_CLIENT_BILLING_SUCCESS,
  ADD_CLIENT_BILLING_FAILURE,
  ADD_ORDERS_BEGIN,
  ADD_ORDERS_SUCCESS,
  ADD_ORDERS_FAILURE,
  ADD_ORDER_DETAILS_BEGIN,
  ADD_ORDER_DETAILS_SUCCESS,
  ADD_ORDER_DETAILS_FAILURE,
  GET_CONTACT_BEGIN,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
  CREATE_PATIENT_BEGIN,
  CREATE_PATIENT_SUCCESS,
  CREATE_PATIENT_FAILURE,
  GET_PATIENT_FAILURE,
  GET_ORDERS_BEGIN,
  GET_ORDER_SUCCESS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_ORDER_DETAILS_BEGIN,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_CONFIG_BEGIN,
  GET_ORDER_CONFIG_SUCCESS,
  GET_ORDER_CONFIG_FAILURE,
  GET_ORDER_CLIENT_DATA_BEGIN,
  GET_ORDER_CLIENT_DATA_SUCCESS,
  GET_ORDER_CLIENT_DATA_FAILURE,
  GET_ORDER_BILLING_DATA_BEGIN,
  GET_ORDER_BILLING_DATA_FAILURE,
  GET_ORDER_BILLING_DATA_SUCCESS,
  GET_ORDER_DELIVERY_DATA_BEGIN,
  GET_ORDER_DELIVERY_DATA_SUCCESS,
  GET_ORDER_DELIVERY_DATA_FAILURE,
  SET_SHOPPING_CART,
  UPDATE_CLIENT_BILLING_BEGIN,
  UPDATE_CLIENT_BILLING_SUCCESS,
  UPDATE_CLIENT_BILLING_FAILURE,
  UPDATE_CLIENT_BEGIN,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
} from '../constants/actionTypes';


export const userLoginBegin = () => ({
  type: USER_LOGIN_BEGIN,
});

export const userLoginSuccess = (user) => ({
  type: USER_LOGIN_SUCCESS,
  user,
});

export const userLoginFailure = (error) => ({
  type: USER_LOGIN_FAILURE,
  error,
});


// SIGN UP

export const userSignupClean = () => ({
  type: USER_SIGNUP_CLEAN,
});

export const userSignupBegin = () => ({
  type: USER_SIGNUP_BEGIN,
});

export const userSignupSuccess = () => ({
  type: USER_SIGNUP_SUCCESS,
});

export const userSignupFailure = () => ({
  type: USER_SIGNUP_FAILURE
});

// LOG OUT
export const userLogoutBegin = () => ({
  type: USER_LOGOUT_BEGIN,
});

export const userLogoutSuccess = () => ({
  type: USER_LOGOUT_SUCCESS,
});

export const userLogoutFailure = () => ({
  type: USER_LOGOUT_FAILURE
});

// GET PENDING APPROVAL USERS
export const getPendingApprovalUsersBegin = () => ({
  type: GET_PENDING_APPROVAL_USERS_BEGIN,
});

export const getPendingApprovalUsersSuccess = (getPendingApprovalUsers) => ({
  type: GET_PENDING_APPROVAL_USERS_SUCCESS,
  payload: {
    getPendingApprovalUsers
  },
});

export const getPendingApprovalUsersFailure = () => ({
  type: GET_PENDING_APPROVAL_USERS_FAILURE
});

// UNAPPROVE USER
export const setUnapproveUserBegin = () => ({
  type: SET_UNAPPROVE_USER_BEGIN,
});

export const setUnapproveUserSuccess = (unapprove) => ({
  type: SET_UNAPPROVE_USER_SUCCESS,
  payload: {
    unapprove
  }
});

export const setUnapproveUserFailure = () => ({
  type: SET_UNAPPROVE_USER_FAILURE

});


export const cleanStateUserUnapprove = () => ({
  type: CLEAN_UNAPPROVE_USER
});

// APPROVE USER
export const setApproveUserBegin = () => ({
  type: SET_APPROVE_USER_BEGIN,
});

export const setApproveUserSuccess = (aprove) => ({
  type: SET_APPROVE_USER_SUCCESS,
  payload: {
    aprove,
  }
});

export const setApproveUserFailure = () => ({
  type: SET_APPROVE_USER_FAILURE
});

export const cleanStateUserAprove = () => ({
  type: CLEAN_APPROVE_USER
});

// CLEAN USER APPROVE OR UNAPPROVE
export const cleanUserAprove_Unaprove = (pendingUsers) => ({
  type: CLEAN_APPROVE_UNAPROVE_USER,
  payload: {
    pendingUsers
  }
});

// USER PERMISSION
export const setUserPermissionBegin = () => ({
  type: SET_USER_PERMISSION_BEGIN,
});

export const setUserPermissionSuccess = () => ({
  type: SET_USER_PERMISSION_SUCCESS
});

export const setUserPermissionFailure = () => ({
  type: SET_USER_PERMISSION_FAILURE
});

// GET ROLS
export const getRolsBegin = () => ({
  type: GET_ROLS_BEGIN,
});

export const getRolsSuccess = (rols) => ({
  type: GET_ROLS_SUCCESS,
  payload: {
    rols
  },
});

export const getRolsFailure = () => ({
  type: GET_ROLS_FAILURE
});


// Recovery Pass
export const recoveryPassBegin = () => ({
  type: RECOVERY_PASS_BEGIN,
});

export const recoveryPassSuccess = (data) => ({
  type: RECOVERY_PASS_SUCCESS,
  payload: {
    data
  },
});

export const recoveryPassFailure = () => ({
  type: RECOVERY_PASS_FAILURE
});

// Reset Pass
export const resetPassBegin = () => ({
  type: RESET_PASS_BEGIN,
});

export const resetPassSuccess = (data) => ({
  type: RESET_PASS_SUCCESS,
  payload: {
    data
  },
});

export const resetPassFailure = () => ({
  type: RESET_PASS_FAILURE
});

export const resetPassClean = () => ({
  type: RESET_PASS_CLEAN,
});

// ADD PERMISSIONS USER
export const AddUserPermissionBegin = () => ({
  type: ADD_USER_PERMISSION_BEGIN,
});

export const AddUserPermissionSuccess = (data) => ({
  type: ADD_USER_PERMISSION_SUCCESS,
  payload: {
    data
  },
});

export const AddUserPermissionFailure = () => ({
  type: ADD_USER_PERMISSION_FAILURE
});

// REMOVE PERMISSIONS USER
export const RemoveUserPermissionBegin = () => ({
  type: REMOVE_USER_PERMISSION_BEGIN,
});

export const RemoveUserPermissionSuccess = (data) => ({
  type: REMOVE_USER_PERMISSION_SUCCESS,
  payload: {
    data
  },
});

export const RemoveUserPermissionFailure = () => ({
  type: REMOVE_USER_PERMISSION_FAILURE
});

// GET PERMISSION
export const GetPermissionBegin = () => ({
  type: GET_PERMISSION_BEGIN,
});

export const GetPermissionSuccess = (data) => ({
  type: GET_PERMISSION_SUCCESS,
  payload: {
    data
  },
});

export const GetPermissionFailure = () => ({
  type: GET_PERMISSION_FAILURE
});

// GET ALL USERS
export const GetAllUsersBegin = () => ({
  type: GET_ALL_USERS_BEGIN,
});

export const GetAllUsersSuccess = (data) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: {
    data
  },
});

export const GetAllUsersFailure = () => ({
  type: GET_ALL_USERS_FAILURE
});


// GET Search USERS
export const SearchUsersBegin = () => ({
  type: SEARCH_USERS_BEGIN,
});

export const SearchUsersSuccess = (data) => ({
  type: SEARCH_USERS_SUCCESS,
  payload: {
    data
  },
});

export const SearchUsersFailure = () => ({
  type: SEARCH_USERS_FAILURE
});

// GET RFC
export const getClientByRFCBegin = () => ({
  type: GET_CLIENT_BY_RFC_BEGIN,
});

export const getClientByRFCSuccess = (clientWithRFC) => ({
  type: GET_CLIENT_BY_RFC_SUCCESS,
  payload: {
    clientWithRFC
  },
});

export const getClientByRFCFailure = () => ({
  type: GET_CLIENT_BY_RFC_FAILURE
});

// Add Client
export const addClientBegin = () => ({
  type: ADD_CLIENT_BEGIN,
});

export const addClientSuccess = (data) => ({
  type: ADD_CLIENT_SUCCESS,
  payload: {
    data
  },
});

export const addClientFailure = () => ({
  type: ADD_CLIENT_FAILURE
});

// Add CONTACT
export const addContactBegin = () => ({
  type: ADD_CONTACT_BEGIN,
});

export const addContactSuccess = (data) => ({
  type: ADD_CONTACT_SUCCESS,
  payload: {
    data
  },
});

export const addContactFailure = () => ({
  type: ADD_CONTACT_FAILURE
});

// Add Client Billing
export const addClientBillingBegin = () => ({
  type: ADD_CLIENT_BILLING_BEGIN,
});

export const addClientBillingSuccess = (data) => ({
  type: ADD_CLIENT_BILLING_SUCCESS,
  payload: {
    data
  },
});

export const addClientBillingFailure = () => ({
  type: ADD_CLIENT_BILLING_FAILURE
});

// Update Client Billing
export const updateClientBillingBegin = () => ({
  type: UPDATE_CLIENT_BILLING_BEGIN,
});

export const updateClientBillingSuccess = (data) => ({
  type: UPDATE_CLIENT_BILLING_SUCCESS,
  payload: {
    data
  },
});

export const updateClientBillingFailure = () => ({
  type: UPDATE_CLIENT_BILLING_FAILURE
});

// Update Client
export const updateClientBegin = () => ({
  type: UPDATE_CLIENT_BEGIN,
});

export const updateClientSuccess = (data) => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: {
    data
  },
});

export const updateClientFailure = () => ({
  type: UPDATE_CLIENT_FAILURE
});

// GET PATIENT
export const getPatientBegin = () => ({
  type: GET_PATIENT_BEGIN,
});

export const getPatientSuccess = (data) => ({
  type: GET_PATIENT_SUCCESS,
  payload: {
    data
  },
});

export const getPatientFailure = () => ({
  type: GET_PATIENT_FAILURE
});

// ADD ORDERS
export const addOrdersBegin = () => ({
  type: ADD_ORDERS_BEGIN,
});

export const addOrdersSuccess = (data) => ({
  type: ADD_ORDERS_SUCCESS,
  payload: {
    data
  },
});

export const addOrdersFailure = () => ({
  type: ADD_ORDERS_FAILURE
});

// ADD ORDER DETAILS
export const addOrderDetailsBegin = () => ({
  type: ADD_ORDER_DETAILS_BEGIN,
});

export const addOrderDetailsSuccess = (data) => ({
  type: ADD_ORDER_DETAILS_SUCCESS,
  payload: {
    data
  },
});

export const addOrderDetailsFailure = () => ({
  type: ADD_ORDER_DETAILS_FAILURE
});

// GET CONTACT
export const getContactBegin = () => ({
  type: GET_CONTACT_BEGIN,
});

export const getContactSuccess = (data) => ({
  type: GET_CONTACT_SUCCESS,
  payload: {
    data
  },
});

export const getContactFailure = () => ({
  type: GET_CONTACT_FAILURE
});

// CREATE PATIENT
export const createPatientBegin = () => ({
  type: CREATE_PATIENT_BEGIN,
});

export const createPatientSuccess = (data) => ({
  type: CREATE_PATIENT_SUCCESS,
  payload: {
    data
  },
});

export const createPatientFailure = () => ({
  type: CREATE_PATIENT_FAILURE
});

//SetShopping Cart
export const setShoppingCartSuccess = (data) => ({
  type: SET_SHOPPING_CART,
  payload: {
    data
  },
});

// GET ORDERS
export const getOrdersBegin = () => ({
  type: GET_ORDERS_BEGIN,
});

export const getOrdersSuccess = (data) => ({
  type: GET_ORDERS_SUCCESS,
  payload: {
    data
  },
});

export const getOrdersFailure = () => ({
  type: GET_ORDERS_FAILURE
});

// GET ORDER ClientData
export const getOrderClientDataBegin = () => ({
  type: GET_ORDER_CLIENT_DATA_BEGIN,
});

export const getOrderClientDataSuccess = (data) => ({
  type: GET_ORDER_CLIENT_DATA_SUCCESS,
  payload: {
    data
  },
});

export const getOrderClientDataFailure = () => ({
  type: GET_ORDER_CLIENT_DATA_FAILURE
});


// GET ORDER DETAILS
export const getOrderDetailsBegin = () => ({
  type: GET_ORDER_DETAILS_BEGIN,
});

export const getOrderDetailsSuccess = (data) => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: {
    data
  },
});

export const getOrderDetailsFailure = () => ({
  type: GET_ORDER_DETAILS_FAILURE
});

// GET ORDER BILLING
export const getOrderBillingDataBegin = () => ({
  type: GET_ORDER_BILLING_DATA_BEGIN,
});

export const getOrderBillingDataSuccess = (data) => ({
  type: GET_ORDER_BILLING_DATA_SUCCESS,
  payload: {
    data
  },
});

export const getOrderBillingDataFailure = () => ({
  type: GET_ORDER_BILLING_DATA_FAILURE
});

// GET ORDER DELIVERY DATA
export const getOrderDeliveryDataBegin = () => ({
  type: GET_ORDER_DELIVERY_DATA_BEGIN,
});

export const getOrderDeliveryDataSuccess = (data) => ({
  type: GET_ORDER_DELIVERY_DATA_SUCCESS,
  payload: {
    data
  },
});

export const getOrderDeliveryDataFailure = () => ({
  type: GET_ORDER_DELIVERY_DATA_FAILURE
});

// GET ORDER CONFIG
export const getOrderConfigBegin = () => ({
  type: GET_ORDER_CONFIG_BEGIN,
});

export const getOrderConfigSuccess = (data) => ({
  type: GET_ORDER_CONFIG_SUCCESS,
  payload: {
    data
  },
});

export const getOrderConfigFailure = () => ({
  type: GET_ORDER_CONFIG_FAILURE
});


// POST ------- LOGIN USER
export function userLogin(email, password) {
  return (dispatch, getState) => {
    dispatch(userLoginBegin());
    return request(
      {
        partialUrl: `/API/Auth/Login/`,
        method: 'POST',
        state: getState().user.request,
        body: {
          "password": Buffer.from(password).toString("base64"),
          "Email": Buffer.from(email).toString("base64"),
        },
      }
    )
      .then(response => {
        if (response.status !== 200 || response.status !== 200) throw response.status;
        return response.json()
      }).then(data => {
        dispatch(userLoginSuccess(data));
      })
      .catch(err => {
        return dispatch(userLoginFailure(err));
      })
  };
}

// POST ------ ADD USER PERMISSION
export function AddUserPermission(userID, userPermissionTypeID) {
  return (dispatch, getState) => {
    dispatch(AddUserPermissionBegin());
    return request(
      {
        partialUrl: `/API/Account/AddUserPermission`,
        method: 'POST',
        state: getState().user.request,
        body: {
          userID,
          userPermissionTypeID
        },
      }
    )
      .then(response => {
        if (response.status !== 200 || response.status !== 200) throw response.status;
        return response.json()
      }).then(data => {
        dispatch(AddUserPermissionSuccess(data.response));
      })
      .catch(err => {
        return dispatch(AddUserPermissionFailure(err));
      })
  };
}

// POST ------ REMOVE USER PERMISSION
export function RemoveUserPermission(userID, userPermissionTypeID) {
  return (dispatch, getState) => {
    dispatch(RemoveUserPermissionBegin());
    return request(
      {
        partialUrl: `/API/Account/RemoveUserPermission`,
        method: 'POST',
        state: getState().user.request,
        body: {
          userID,
          userPermissionTypeID
        },
      }
    )
      .then(response => {
        if (response.status !== 200 || response.status !== 200) throw response.status;
        return response.json()
      }).then(data => {
        dispatch(RemoveUserPermissionSuccess(data.response));
      })
      .catch(err => {
        return dispatch(RemoveUserPermissionFailure(err));
      })
  };
}


// GET ------ GET PERMISSION
export function GetPermission() {
  return (dispatch, getState) => {
    dispatch(GetPermissionBegin());
    return request(
      {
        partialUrl: `/API/Account/GetPermissions`,
        method: 'GET',
        state: getState().user.request,
        body: {},
      }
    )
      .then(response => response.json())
      .then(data => {
        dispatch(GetPermissionSuccess(data.response));
      })
      .catch(err => {
        return dispatch(GetPermissionFailure(err));
      })
  };
}
// GET ------ ALL USERS
export function GetAllUsers(currentPage = 0, offset = 10) {
  return (dispatch, getState) => {
    dispatch(GetAllUsersBegin());
    return request(
      {
        partialUrl: `/API/Account/GetActiveUsers?currentPage=${currentPage}&offset=${offset}`,
        method: 'GET',
        state: getState().user.request,
        body: {},
      }
    )
      .then(response => response.json())
      .then(data => {
        dispatch(GetAllUsersSuccess(data.response));
      })
      .catch(err => {
        return dispatch(GetAllUsersFailure(err));
      })
  };
}

// GET ------ ALL USERS
export function SearchUsers(key) {
  return (dispatch, getState) => {
    dispatch(SearchUsersBegin());
    return request(
      {
        partialUrl: `/API/Account/SearchUsers?key=${key}`,
        method: 'GET',
        state: getState().user.request,
        body: {},
      }
    )
      .then(response => response.json())
      .then(data => {
        dispatch(SearchUsersSuccess(data.response));
      })
      .catch(err => {
        return dispatch(SearchUsersFailure(err));
      })
  };
}


//Recovery Pass
export function recoveryPass(email, environment) {
  return (dispatch, getState) => {
    dispatch(recoveryPassBegin());
    return request(
      {
        partialUrl: `/API/Account/SendRecoverPasswordMail`,
        method: 'POST',
        state: getState().user.request,
        body: {
          "Email": email,
          Environment: environment
        },
      }
    )
      .then(response => {
        if (response.status !== 200 || response.status !== 200) throw response.status;
        return response.json()
      }).then(response => {
        // console.log(response)
        dispatch(recoveryPassSuccess(response.statusCode));
      })
      .catch(err => {
        // console.log(err)
        return dispatch(recoveryPassFailure(err));
      })
  };
}

//Reset Pass
export function resetPass(pass, token) {
  return (dispatch, getState) => {
    dispatch(resetPassBegin());
    return request(
      {
        partialUrl: `/API/Account/RecoverPasswordByMail`,
        method: 'POST',
        state: getState().user.request,
        body: {
          Password: Buffer.from(pass).toString("base64"),
          Token: token
        },
      }
    )
      .then(response => {
        if (response.status !== 200 || response.status !== 200) throw response.status;
        return response.json()
      }).then(response => {
        // console.log(response)
        dispatch(resetPassSuccess(response.statusCode));
      })
      .catch(err => {
        // console.log(err)
        return dispatch(resetPassFailure(err));
      })
  };
}

// SIGN UP

export function userSignup(FirstName, SecondName, FirstLastName, SecondLastName, Phone, Email, Password, RoleID) {
  return (dispatch, getState) => {
    dispatch(userSignupBegin());
    return request(
      {
        partialUrl: `/API/Account/CreateAccount`,
        method: 'POST',
        state: getState().user.request,
        body: {
          "Password": Buffer.from(Password).toString("base64"),
          "Email": Buffer.from(Email).toString("base64"),
          "FirstLastName": FirstLastName,
          "FirstName": FirstName,
          "Phone": Phone,
          "Role": {
            "RoleID": RoleID
          },
          "SecondName": SecondName,
          "SecondLastName": SecondLastName,
        },
      }
    )
      .then(response => {
        if (response.status !== 201) throw response.status;
        return response.json()
      }).then(data => {
        dispatch(userSignupSuccess(data));
      })
      .catch(err => {
        return dispatch(userSignupFailure(err));
      })
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch(userLogoutBegin());
    return dispatch(userLogoutSuccess());
  }
}

// GET PENDING APPROVAL USERS
export function getPendingApprovalUsers() {

  return function (dispatch, getState) {
    dispatch(getPendingApprovalUsersBegin());
    return request({
      partialUrl: `/API/Account/GetPendingApprovalUsers`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getPendingApprovalUsersSuccess(data.response));
      })
      .catch(err => {
        return dispatch(getPendingApprovalUsersFailure(err));
      });
  }
}

// UNAPPROVE USER
export function setUnapproveUser(id, rolId) {

  return function (dispatch, getState) {
    dispatch(setUnapproveUserBegin());
    return request({
      partialUrl: `/API/Account/UnapproveAccount`,
      method: 'POST',
      state: getState().user.request,
      body: {
        "id": id,
        "role": {
          "roleID": rolId,
          "roleDescription": null
        }
      },
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        dispatch(setUnapproveUserSuccess(data));
      })
      .catch(err => {
        // console.log(err)
        return dispatch(setUnapproveUserFailure(err));
      });
  }
}

// APPROVE USER
export function setApproveUser(id, rolId, sellercode, atqcode, zonescode, distributorcode, biddercode) {

  return function (dispatch, getState) {
    dispatch(setApproveUserBegin());
    return request({
      partialUrl: `/API/Account/ApproveAccount`,
      method: 'POST',
      state: getState().user.request,
      body: {
        "id": id,
        "role": {
          "roleID": parseInt(rolId, 10),
          "roleDescription": null
        },
        "sellerCode": sellercode,
        "atqCode": atqcode,
        "zoneSupervisorCode": zonescode,
        "distributorCode": distributorcode,
        "bidderCode": biddercode
      },
    })
      .then(response => response.json())
      .then(data => {
        dispatch(setApproveUserSuccess(data));
      })
      .catch(err => {
        return dispatch(setApproveUserFailure(err));
      });
  }
}

// USER PERMISSION
export function setUserPermission(id, atqcode, sellercode, zonescode, distributorcode, biddercode) {

  return function (dispatch, getState) {
    dispatch(setUserPermissionBegin());
    return request({
      partialUrl: `/API/Account/AddUserPermission`,
      method: 'POST',
      state: getState().user.request,
      body: {
        "userID": id,
        "sellerCode": sellercode,
        "atqCode": atqcode,
        "zoneSupervisorCode": zonescode,
        "distributorCode": distributorcode,
        "bidderCode": biddercode
      },
    })
      .then(response => response.json())
      .then(() => {
        dispatch(setUserPermissionSuccess());
      })
      .catch(err => {
        return dispatch(setUserPermissionFailure(err));
      });
  }
}

// GET ROLS
export function getRols() {

  return function (dispatch, getState) {
    dispatch(getRolsBegin());
    return request({
      partialUrl: `/API/Utilities/GetRoles`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getRolsSuccess(data.roles));
      })
      .catch(err => {
        return dispatch(getRolsFailure(err));
      });
  }
}

/* Get Client By RFC */
export function getClientByRFC(search) {

  return function (dispatch, getState) {
    dispatch(getClientByRFCBegin());
    return request({
      partialUrl: `/API/Client/GetClientsByRFC?client=${search}`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        console.log('data: ', data)
        dispatch(getClientByRFCSuccess(data.response));
      })
      .catch(err => {
        return dispatch(getClientByRFCFailure(err));
      });
  }
}

export function getOrderClientDataAsync(body, headers) {
  return request({
    partialUrl: `/API/Order/GetOrderClientData?OrderID=${body}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function asyncGetClientByRFC(search, headers) {
  return request({
    partialUrl: `/API/Client/GetClientsByRFC?client=${search}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

/* Add Client */
export function addClient(body) {

  return function (dispatch, getState) {
    dispatch(addClientBegin());
    return request({
      partialUrl: `/API/Client/AddClient`,
      method: 'POST',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(addClientSuccess(data.response));
      })
      .catch(err => {
        return dispatch(addClientFailure(err));
      });
  }
}

/* Add Contact */
export function addContact(body) {

  return function (dispatch, getState) {
    dispatch(addContactBegin());
    return request({
      partialUrl: `/API/Contact/AddContact`,
      method: 'POST',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(addContactSuccess(data.response));
      })
      .catch(err => {
        return dispatch(addContactFailure(err));
      });
  }
}

/* Add Client Billing */
export function addClientBilling(body) {

  return function (dispatch, getState) {
    dispatch(addClientBillingBegin());
    return request({
      partialUrl: `/API/Client/AddClientBilling`,
      method: 'PUT',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(addClientBillingSuccess(data.response));
      })
      .catch(err => {
        return dispatch(addClientBillingFailure(err));
      });
  }
}

/* Add Orders */
export function addOrders(body) {

  return function (dispatch, getState) {
    dispatch(addOrdersBegin());
    return request({
      partialUrl: `/API/Order/AddOrders`,
      method: 'POST',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(addOrdersSuccess(data.response));
      })
      .catch(err => {
        return dispatch(addOrdersFailure(err));
      });
  }
}

/* Add Order Details */
export function addOrderDetails(body) {

  return function (dispatch, getState) {
    dispatch(addOrderDetailsBegin());
    return request({
      partialUrl: `/API/Order/AddOrderDetail`,
      method: 'POST',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(addOrderDetailsSuccess(data.response));
      })
      .catch(err => {
        return dispatch(addOrderDetailsFailure(err));
      });
  }
}

export function asyncAddOrderDetails(body, headers) {
  return request({
    partialUrl: `/API/Order/AddOrderDetail`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function asyncAddOrderConfig(body, headers) {
  return request({
    partialUrl: `/API/Order/AddOrderConfig`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

/* Get Contact */
export function getContact(id) {

  return function (dispatch, getState) {
    dispatch(getContactBegin());
    return request({
      partialUrl: `/API/Contact/getContact?contact=${id}`,
      method: 'POST',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getContactSuccess(data.response));
      })
      .catch(err => {
        return dispatch(getContactFailure(err));
      });
  }
}

/* Create Patient */
export function createPatient(body) {

  return function (dispatch, getState) {
    dispatch(createPatientBegin());
    return request({
      partialUrl: `/API/Patient/CreatePatient`,
      method: 'POST',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(createPatientSuccess(data.response));
      })
      .catch(err => {
        return dispatch(createPatientFailure(err));
      });
  }
}

export function getOrders(body) {
  return function (dispatch, getState) {
    dispatch(getOrdersBegin());
    return request({
      partialUrl: `/API/Order/GetOrders`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getOrdersSuccess(data.response));
      })
      .catch(err => {
        console.log(err)
        return dispatch(getOrdersFailure(err));
      });
  }
}

export function setShoppingCartRedux(shoppingCart) {
  return function (dispatch, getState) {
    dispatch(setShoppingCartSuccess(shoppingCart));
  }
}

export function getOrderDetails(body) {
  return function (dispatch, getState) {
    dispatch(getOrderDetailsBegin());
    return request({
      partialUrl: `/API/Order/GetOrderDetail?OrderID=${body}`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getOrderDetailsSuccess(data.response));
      })
      .catch(err => {
        console.log(err)
        return dispatch(getOrderDetailsFailure(err));
      });
  }
}

export function getOrderDetailsAsync(body, headers) {
  return request({
    partialUrl: `/API/Order/GetOrderDetail?OrderID=${body}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });

}

export function GetOrdersProductAsync(body, headers) {
  return request({
    partialUrl: `/API/Order/GetOrdersProduct?SellerID=${body}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });

}

export function getOrderClientData(body) {
  return function (dispatch, getState) {
    dispatch(getOrderClientDataBegin());
    return request({
      partialUrl: `/API/Order/GetOrderClientData?OrderID=${body}`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getOrderClientDataSuccess(data.response));
      })
      .catch(err => {
        console.log(err)
        return dispatch(getOrderClientDataFailure(err));
      });
  }
}

export function getOrderBillingData(body) {
  return function (dispatch, getState) {
    dispatch(getOrderBillingDataBegin());
    return request({
      partialUrl: `/API/Order/GetOrderBillingData?OrderID=${body}`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getOrderBillingDataSuccess(data.response));
      })
      .catch(err => {
        console.log(err)
        return dispatch(getOrderBillingDataFailure(err));
      });
  }
}

export function getOrderDeliveryData(body) {
  return function (dispatch, getState) {
    dispatch(getOrderDeliveryDataBegin());
    return request({
      partialUrl: `/API/Order/GetOrderDeliveryData?OrderID=${body}`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getOrderDeliveryDataSuccess(data.response));
      })
      .catch(err => {
        console.log(err)
        return dispatch(getOrderDeliveryDataFailure(err));
      });
  }
}

/* GetOrderDeliveryData */

export function getOrderConfig(body) {
  return function (dispatch, getState) {
    dispatch(getOrderConfigBegin());
    return request({
      partialUrl: `/API/Order/GetOrderConfig?OrderID=${body}`,
      method: 'GET',
      state: getState().user.request,
      body: {}
    })
      .then(response => response.json())
      .then(data => {
        dispatch(getOrderConfigSuccess(data.response));
      })
      .catch(err => {
        console.log(err)
        return dispatch(getOrderConfigFailure(err));
      });
  }
}

export function getWarehouseAsync(headers) {
  return request({
    partialUrl: `/API/Warehouse/GetWarehouses`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getLocationsAsync(headers) {
  return request({
    partialUrl: `/API/LocationCatalog/GetLocations`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });

}

export function getShippingAsync(headers) {
  return request({
    partialUrl: `/API/Remission/getShipping`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });

}

export function GetSupplyRequestAsync(headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/GetSupplyRequest`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });

}

export function GetPurchaseOrderCreatedAsync(headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/GetPurchaseOrderCreated`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });

}

export function GetPurchaseOrderAsync(body, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/GetPurchaseOrder?typeRequest=${body}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });

}

export function addRemissionAsync(body, headers) {
  return request({
    partialUrl: `/API/Remission/AddRemission`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function addRemissionDetailAsync(body, headers) {
  return request({
    partialUrl: `/API/Remission/AddRemissionDetail`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function SetPurchaseOrderAsync(body, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/SetPurchaseOrder`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}


export function setSupplyReceiveAsync(body, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/setSupplyReceive`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}



export function SetSupplyDetailAsync(body, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/SetSupplyDetail`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function deleteSupplyDetailAsync(body, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/deleteSupplyDetail`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function setpurchaseOrderDetailAsync(body, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/setpurchaseOrderDetail`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function UpdateStatusOrderDetail(body, headers) {
  return request({
    partialUrl: `/API/Order/UpdateStatusOrderDetail`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function updateStatusRemissionDetailsAsync(body, headers) {
  return request({
    partialUrl: `/API/Remission/UpdateStatusRemissionDetail`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function UpdateShippingRemissionAsync(body, headers) {
  return request({
    partialUrl: `/API/Remission/UpdateShippingRemission`,
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}
getSupplyDetailAsync

export function getSupplyDetailAsync(id, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/getSupplyDetail?purchaseOrderDetailId=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getRemissionByIdAsync(id, headers) {
  return request({
    partialUrl: `/API/Remission/GetRemissionById?Id=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function GetRemissionDetailProductAsync(id, headers) {
  return request({
    partialUrl: `/API/Remission/GetRemissionDetailProduct?RemissionId=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function GetRemissionsPendingAynsc(id, headers) {
  return request({
    partialUrl: `/API/Remission/GetRemissionsPending?typeShipping=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getRemissionDetailAsync(id, headers) {
  return request({
    partialUrl: `/API/Remission/GetRemissionDetail?RemissionId=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function GetSolPurchaseOrderDetailAsync(id, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/GetSolPurchaseOrderDetail?OrderId=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function GetPurchaseOrderDetailRequestAsync(id, headers) {
  return request({
    partialUrl: `/API/PurchaseOrder/GetPurchaseOrderDetailRequest?PuchaseOrderId=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getRemissionsByClientAsync(id = 0, headers) {
  return request({
    partialUrl: `/API/Remission/GetRemissionsByClient?ClientId=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getRemissionsByOrderAsync(id = 0, headers) {
  return request({
    partialUrl: `/API/Remission/GetRemissionsByOrder?OrderId=${id}`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getRemissionsByProductAsync(id = null, headers) {
  return request({
    partialUrl: id ? `/API/Remission/GetRemissionsByProduct?ProductCode=${id}` : `/API/Remission/GetRemissionsByProduct`,
    method: 'GET',
    state: headers,
    body: {}
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function uploadInventoryAsync(body, headers) {
  return newRequest({
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getInventoryAsync(body, headers) {
  return newRequest({
    method: 'POST',
    state: headers,
    body
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getSalesAsync(headers) {
  return request({
    partialUrl: `/API/Sale/getSales`,
    method: 'GET',
    state: headers
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

export function getSaleAsync(headers, id) {
  return request({
    partialUrl: `/API/Sale/getSale?id=${id}`,
    method: 'GET',
    state: headers
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

// BUSCAR POR NOMBRE (datos de facturación)
export function searchByNameAsync(headers, name) {
  return request({
    partialUrl: `/API/Client/GetClientsByName?clientName=${name}`,
    method: 'GET',
    state: headers
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

//CANCELAR VENTA
export function cancelSaleAsync(body, headers) {
  return newRequest({
    method: 'POST',
    state: headers,
    body
  })
  .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(err => {
      return err
    });
}

//REGISTRAR CONCILIACIÓN
export function setBankReconciliationSale (headers, id, bankReconciliation) {
  return request({
    partialUrl: `/API/Sale/SetBankReconciliationSale?id=${id}&bankReconciliation=${bankReconciliation}`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => {
    return data
  })
  .catch(err => {
    return err
  });
}

//FACTURAR PEDIDO
export function setInvoiceSale (headers, id, invoiceNumber, invoiceSerial) {
  return request({
    partialUrl: `/API/Sale/SetInvoiceSale?id=${id}&invoiceNumber=${invoiceNumber}&invoiceSerial=${invoiceSerial}`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => { return data })
  .catch(err => { return err })
}

//CATÁLOGO DE PAQUETERIAS DE ENVÍO
export function getCShippingCompany (headers) {
  return request({
    partialUrl: `/API/Sale/GetCShippingCompany`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

//ENVIAR PEDIDO POR PAQUETERÍA
export function setShippingCompanySale (headers, id, deliveryCscId, deliveryNumber, deliveryOther) {
  return request({
    partialUrl: `/API/Sale/SetShippingCompanySale?id=${id}&deliveryCscId=${deliveryCscId}&deliveryNumber=${deliveryNumber}&DeliveryOther=${deliveryOther}`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

//ACTIVAR/DESACTIVAR PAQUETE PARA VENTA MÓVIL
export function setBundleToVM (headers, id, active) {
  return request({
    partialUrl: `/API/Sale/SetBundleToVM?id=${id}&active=${active}`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

export function getCatCfdi (headers) {
  return request({
    partialUrl: `/API/CatalogosSat/GetCatUsoCfdi`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

export function getCatRegFiscal (headers) {
  return request({
    partialUrl: `/API/CatalogosSat/GetCatRegFiscal`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

//GENERAR QR PLANTILLAS
export function downloadQR (body, headers) {
  return newRequest({
    method: 'POST',
    state: headers,
    body
  })
  .then (response => response.json())
  .then(data => { return data })
  .catch(err => { return err });
}

//GET CONTACTOS PARA PANTALLA CONTACTOS
export function getContacts (headers) {
  return request({
    partialUrl: `/API/Contact/GetContacts?SellerId=0`,
    method: 'GET',
    state: headers
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

/* Update Client Billing */
export function updateClientBilling(body) {
  return function (dispatch, getState) {
    dispatch(updateClientBillingBegin());
    return request({
      partialUrl: `/API/Client/UpdateClientBillData`,
      method: 'PUT',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(updateClientBillingSuccess(data.response));
      })
      .catch(err => {
        return dispatch(updateClientBillingFailure(err));
      });
  }
}

/* Update Client simple */
export function updateClient(body) {
  return function (dispatch, getState) {
    dispatch(updateClientBegin());
    return request({
      partialUrl: `/API/Client/UpdateClientSimple`,
      method: 'PUT',
      state: getState().user.request,
      body
    })
      .then(response => response.json())
      .then(data => {
        dispatch(updateClientSuccess(data.response));
      })
      .catch(err => {
        return dispatch(updateClientFailure(err));
      });
  }
}

//-------------- DATOS DE FACTURACION -------------
//SAVE CLIENT DATA
export function updateClientSData (body, headers) {
  return request({
    partialUrl: `/API/Sale/UpdateClientSData`,
    method: 'POST',
    state: headers,
    body
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

//SAVE BILL DATA
export function updateBillingClientSData (body, headers) {
  return request({
    partialUrl: `/API/Sale/UpdateBillingClientSData`,
    method: 'POST',
    state: headers,
    body
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}

//SAVE SHIP DATA
export function updateEnvioClientSData (body, headers) {
  return request({
    partialUrl: `/API/Sale/UpdateEnvioClientSData`,
    method: 'POST',
    state: headers,
    body
  })
  .then(response => response.json())
  .then(data => { return data } )
  .catch(err => { return err } )
}


