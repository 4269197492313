import React, { useEffect, useRef, useState } from 'react';
import ico_con_Comentarios from '../../../assets/img/126PX/icon_quality_conmentarios.png';
import ico_con_sincomentarios from '../../../assets/img/126PX/Icon_quality_sincomentario.png';
import icon_arrow from '../../../assets/img/126PX/black_row.png';
import { useDispatch, useSelector } from 'react-redux';
import { getSurvey, getSurveySuccess, getSurveyToSearchSuccess, searchSurveyBySurgerySuccess, setSurveySelected, surveySearchATQSuccess } from '../../../actions/surveyAction';
import { formatDateList, formatDates, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

export const QuaListSurvey = ({btnSelected,setBtnSelected,setFrom,from}) => {

    const history = useHistory();
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState(null);
    const survey = useSelector(state => state.survey.getSurvey);
    const search = useSelector(state => state.survey.search);
    const searchDate = useSelector(state => state.survey.searchSurvey)
    const dispatch = useDispatch();
    const render = useRef(0);

    useEffect(() => {
        return () => {
            dispatch(getSurveySuccess(null));
            dispatch(surveySearchATQSuccess(null));
        }
    }, [])

    useEffect(() => {
        //debugger
        if(search && search !== null){
            setFrom('child')
         if(search[0].surveyStatus && search[0].surveyStatus.status){
                     if(search[0].surveyStatus.status === 2){
                        setBtnSelected({
                            sinRespuesta: false,
                            pendiente: true,
                            seguimiento:false,
                            cerradas:false,
                        })
                    }
                    else if(search[0].surveyStatus.status === 3){
                        setBtnSelected({
                            sinRespuesta: false,
                            pendiente: false,
                            seguimiento:true,
                            cerradas:false,
                        })
                    }
                    else if(search[0].surveyStatus.status === 4){
                        setBtnSelected({
                            sinRespuesta: false,
                            pendiente: false,
                            seguimiento:false,
                            cerradas:true,
                        })
                    } 
            }else{
                setBtnSelected({
                    sinRespuesta: true,
                    pendiente: false,
                    seguimiento:false,
                    cerradas:false,
                })
            }

        }
    }, [search])
    

    // ESTE ES PARA USAR EL LIST 
    useEffect(() => {

        if (list && list.length > 0 && survey.length > 0) {
            setList([...list, ...survey])
        } else if (survey && survey.length > 0) {
            setList(survey)
        }

    }, [survey])

    useEffect(() => {
    if(currentPage !== null && from === 'dad'){
        if (currentPage === 0) {
            if (btnSelected.sinRespuesta === true) {
                dispatch(getSurvey(1, 4, currentPage, 10))
            }
            else if (btnSelected.pendiente === true) {
                dispatch(getSurvey(2, 4, currentPage, 10))
                setList(null);
            }
            else if (btnSelected.seguimiento === true) {
                dispatch(getSurvey(3, 4, currentPage, 10))
                setList(null);
            }
            else if (btnSelected.cerradas === true) {
                dispatch(getSurvey(4, 4, currentPage, 10))
                setList(null);
            }

        } else if (currentPage > 0 && survey !== null) {
            if (btnSelected.sinRespuesta === true) {
                dispatch(getSurvey(1, 4, currentPage, 10))
            }
            else if (btnSelected.pendiente === true) {
                dispatch(getSurvey(2, 4, currentPage, 10))
            }
            else if (btnSelected.seguimiento === true) {
                dispatch(getSurvey(3, 4, currentPage, 10))
            }
            else if (btnSelected.cerradas === true) {
                dispatch(getSurvey(4, 4, currentPage, 10))
            }
        }
    }else{
        setPage(0);
    }
    }, [currentPage])


    useEffect(() => {

        if (render.current > 0) {
            setList(null);
            setPage(0);
            dispatch(getSurveySuccess(null));

            //  if(from === 'dad'){
            //    dispatch(searchSurveyBySurgerySuccess(null))
            //     dispatch(getSurveyToSearchSuccess(null))
            //  }

            if(currentPage === 0){
                setPage(null)
            }
          /*   if (currentPage > 0) {
                if (btnSelected.sinRespuesta === true) {
                    dispatch(getSurvey(1, 4, currentPage, 10))
                }
                else if (btnSelected.pendiente === true) {
                    dispatch(getSurvey(2, 4, currentPage, 10))
                    setList(null);
                }
                else if (btnSelected.seguimiento === true) {
                    dispatch(getSurvey(3, 4, currentPage, 10))
                    setList(null);
                }
                else if (btnSelected.cerradas === true) {
                    dispatch(getSurvey(4, 4, currentPage, 10))
                    setList(null);
                }
            } */
        } else {
            setList(null);
            setPage(null)
            render.current = 1;
        }
    }, [btnSelected])

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    const handleHistory = (item) =>{
        let action = '';
        if(btnSelected.sinRespuesta === true)
            action = 'sinRespuesta'
        else if(btnSelected.pendiente === true)
            action = 'pendiente'
        else if(btnSelected.seguimiento === true)
            action = 'seguimiento'
        else if(btnSelected.cerradas === true)
            action = 'cerradas'

        dispatch(setSurveySelected({ ...item }))
        history.replace(`/Quality_InfoEncuestas_Page/${action}`);
    }

  return (
    <div className='container-list' onScroll={handleScroll} >
        {

            !_.isNil(search)
            ?(search.map( (item,i) => (
                <div className='list-encuestas' onClick={() => handleHistory(item)}>
                    <div className='precontainer-encuesta-list' >
                        {/* <img src={`${list.comment === true ? ico_con_Comentarios : ico_con_sincomentarios}` }  className= {`${list.comment === true ? 'ico_con_Comentarios' : 'ico_con_sincomentarios'}` } />  */}
                        <img src={`${ico_con_Comentarios}`}  className= {'ico_con_Comentarios'} /> 
                        <p className='list-encuesta-p'>{`${formatDateList(item && item.surgeryAppointment && item.surgeryAppointment.surgeryDate)} - ${formatHourToPresent(item && item.surgeryAppointment && item.surgeryAppointment.surgeryDate)} hrs`}</p>
                        <p className='item-encuesta-p'>{` - Dr. ${item && item.surgeryAppointment.medic && 
                                                        ((item.surgeryAppointment.medic.name  && item.surgeryAppointment.medic.name)+ ' ' +
                                                        (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName)+ ' ' +
                                                        (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname)+ ' ' +
                                                        (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname))}`}
                                                    </p>
                        <p className='item-encuesta-p'>{` - ${item.surgeryAppointment.hospital && item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name}`}</p>
                        <p className='item-encuesta-p'>{` - CX #${item.surgeryAppointment.id}`}</p>
                    </div>
                    <img src={icon_arrow} className='icon-arrow'/>
                </div>
                ))
            )
           
            :!_.isNil(searchDate) 
                ?searchDate.map( (item,i) => (
                <div className='list-encuestas' onClick={() => handleHistory(item)}>
                    <div className='precontainer-encuesta-list' >
                        {/* <img src={`${list.comment === true ? ico_con_Comentarios : ico_con_sincomentarios}` }  className= {`${list.comment === true ? 'ico_con_Comentarios' : 'ico_con_sincomentarios'}` } />  */}
                        <img src={`${ico_con_Comentarios}`}  className= {'ico_con_Comentarios'} /> 
                        <p className='list-encuesta-p'>{`${formatDateList(item && item.surgeryAppointment && item.surgeryAppointment.surgeryDate)} - ${formatHourToPresent(item && item.surgeryAppointment && item.surgeryAppointment.surgeryDate)} hrs`}</p>
                        <p className='item-encuesta-p'>{` - Dr. ${item && item.surgeryAppointment.medic && 
                                                        ((item.surgeryAppointment.medic.name  && item.surgeryAppointment.medic.name)+ ' ' +
                                                        (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName)+ ' ' +
                                                        (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname)+ ' ' +
                                                        (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname))}`}
                                                    </p>
                        <p className='item-encuesta-p'>{` - ${item.surgeryAppointment.hospital && item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name}`}</p>
                        <p className='item-encuesta-p'>{` - CX #${item.surgeryAppointment.id}`}</p>
                    </div>
                    <img src={icon_arrow} className='icon-arrow'/>
                </div>
            ))
            
            
            :(!_.isNil(list) &&
           list.map( (item,i) => (
                <div className='list-encuestas' onClick={() => handleHistory(item)}>
                    <div className='precontainer-encuesta-list' >
                        {/* <img src={`${list.comment === true ? ico_con_Comentarios : ico_con_sincomentarios}` }  className= {`${list.comment === true ? 'ico_con_Comentarios' : 'ico_con_sincomentarios'}` } />  */}
                        <img src={`${ico_con_Comentarios}`}  className= {'ico_con_Comentarios'} /> 
                        <p className='list-encuesta-p'>{`${formatDateList(item && item.surgeryAppointment && item.surgeryAppointment.surgeryDate)} - ${formatHourToPresent(item && item.surgeryAppointment && item.surgeryAppointment.surgeryDate)} hrs`}</p>
                        <p className='item-encuesta-p'>{` - Dr. ${item && item.surgeryAppointment.medic && 
                                                        ((item.surgeryAppointment.medic.name  && item.surgeryAppointment.medic.name)+ ' ' +
                                                        (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName)+ ' ' +
                                                        (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname)+ ' ' +
                                                        (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname))}`}
                                                    </p>
                        <p className='item-encuesta-p'>{` - ${item.surgeryAppointment.hospital && item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name}`}</p>
                        <p className='item-encuesta-p'>{` - CX #${item.surgeryAppointment.id}`}</p>
                    </div>
                    <img src={icon_arrow} className='icon-arrow'/>
                </div>
            ))
            )

        }
    </div>
  )
}
