import React from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import SyncIcon from '@material-ui/icons/Sync';
import CropFreeIcon from '@material-ui/icons/CropFree';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TablePagination,
  Checkbox,
  FormControlLabel,
  Drawer,
  Button,
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';

import { useState } from "react";
import { NavLink } from "react-router-dom";

import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { getOrders, GetPurchaseOrderAsync, SetPurchaseOrderAsync, setpurchaseOrderDetailAsync, GetPurchaseOrderCreatedAsync } from "../../actions/userActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SearchBar from "material-ui-search-bar";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContentText from '@material-ui/core/DialogContentText';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoomIcon from '@material-ui/icons/Room';
import CreateIcon from '@material-ui/icons/Create';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const Shopping = (props) => {
  const classes = useStyles();
  const requestH = useSelector((state) => state.user.request);
  const [showBy, setShowBy] = React.useState('shoppingRequest'); /* Mostrar Por Variable */
  const [productSelected, setProductSelected] = useState(false)
  const loginData = useSelector((state) => state.user.user.identity)
  /* START Search Bar Section */
  const [searchedValuesGeneral, setSearchedValuesGeneral] = useState("");


  const requestSearch = (searchedVal) => {
    if (showBy == 'shoppingRequest') {
      const filteredArray = PurchaseOrderOriginal.filter((item) => {
        return item.sellerName.toLowerCase().includes(searchedVal.toLowerCase()) ||
          item.orderId.toString().includes(searchedVal.toLowerCase()) ||
          item.productCode.toString().toLowerCase().includes(searchedVal.toLowerCase())
      });
      setPurchaseOrder(filteredArray)
    } else if (showBy == 'shoppingOrder') {
      const filteredArray = PurchaseOrderCreatedOriginal.filter((item) => {
        return item.supplier.toLowerCase().includes(searchedVal.toLowerCase()) ||
          item.id.toString().includes(searchedVal.toLowerCase()) ||
          item.purchaseOrderDate.toString().toLowerCase().includes(searchedVal.toLowerCase())
      });
      setPurchaseOrderCreated(filteredArray)
    }
  };
  const cancelSearch = () => {
    setPurchaseOrderCreated(PurchaseOrderCreatedOriginal)
    setPurchaseOrder(PurchaseOrderOriginal)
  };
  /* END Search Bar Section */

  /* START SHOPPING REQUEST */
  const columnShoppingRequest = [
    { id: 'select', label: '', minWidth: 100 },
    { id: 'pedido', label: '# PEDIDO', minWidth: 170 },
    { id: 'fecha', label: 'FECHA', minWidth: 100 },
    // { id: 'solicitud', label: 'SOLICITUD', minWidth: 100 },
    { id: 'pactado', label: 'PRODUCTO', minWidth: 100 },
    { id: 'productos', label: 'CANTIDAD', minWidth: 170 },
    { id: 'vendedor', label: 'VENDEDOR', minWidth: 170 },
    // { id: 'icon', label: '', minWidth: 170 },
  ];

  function createDataShoppingRequest(select, solicitud, pactado, pedido, productos, vendedor, icon) {
    return { select, solicitud, pactado, pedido, productos, vendedor, icon };
  }

  const rowsShoppingRequestData = [
    createDataShoppingRequest('', '2222222', '19/SEP/2022', '0000026', '5', 'Maria Montes', 'icon'),
    createDataShoppingRequest('', '3333333', '22/SEP/2022', '0000025', '1', 'Carlos Torres Montes', 'icon'),
    createDataShoppingRequest('', '4444444', '22/SEP/2022', '0000023', '10', 'Maria Montes', 'icon')
  ];

  const [rowsShoppingRequest, setRowsShoppingRequest] = useState(rowsShoppingRequestData);
  const [rowsShoppingRequestOriginal, setRowsShoppingRequestOriginal] = useState(rowsShoppingRequestData);
  const [rowsPerPageShoppingRequest, setRowsPerPageShoppingRequest] = useState(7);
  const [pageShoppingRequest, setPageShoppingRequest] = useState(0);

  const handleChangePageShoppingRequest = (event, newPage) => {
    setPageShoppingRequest(newPage);
  };

  const handleChangeRowsPerPageShoppingRequest = (event) => {
    setRowsPerPageShoppingRequest(+event.target.value);
    setPageShoppingRequest(0);
  };
  /* END SHOPPING REQUEST */

  /* START SHOPPING REQUEST - PRODUCT */
  const columnShoppingRequestProducts = [
    { id: 'select', label: '', minWidth: 100 },
    { id: 'solicitud', label: 'SOLICITUD', minWidth: 100 },
    { id: 'pactado', label: 'PACTADO', minWidth: 100 },
    { id: 'pedido', label: '# PEDIDO', minWidth: 170 },
    { id: 'vendedor', label: 'VENDEDOR', minWidth: 170 },
    { id: 'id', label: 'PRODUCTCODE', minWidth: 170 },
    { id: 'productos', label: 'PRODUCTOS', minWidth: 170 },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: 170 },
    { id: 'icon', label: '', minWidth: 170 },
  ];

  function createDataShoppingRequestProducts(select, solicitud, pactado, pedido, vendedor, id, productos, cantidad, icon) {
    return { select, solicitud, pactado, pedido, vendedor, id, productos, cantidad, icon };
  }

  const rowsShoppingRequestProductsData = [
    createDataShoppingRequestProducts('', '2222222', '19/SEP/2022', '00000026', 'Maria Montes', 'CS 8201-08 ', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '5', false),
    createDataShoppingRequestProducts('', '3333333', '22/SEP/2022', '00000022', 'Carlos Torres', 'CS 2600-6-45', 'COSMICMIA - TORNILLO D6.5 MM LONGITUD 45 MM', '60', false),
    createDataShoppingRequestProducts('', '4444444', '22/SEP/2022', '00000026', 'Maria Montes', 'CS 8201-08 ', 'CHALECOS', '2', true)
  ];

  const [rowsShoppingRequestProducts, setRowsShoppingRequestProducts] = useState(rowsShoppingRequestProductsData);
  const [rowsShoppingRequestProductsOriginal, setRowsShoppingRequestProductsOriginal] = useState(rowsShoppingRequestProductsData);
  const [rowsPerPageShoppingRequestProducts, setRowsPerPageShoppingRequestProducts] = useState(7);
  const [pageShoppingRequestProducts, setPageShoppingRequestProducts] = useState(0);

  const handleChangePageShoppingRequestProducts = (event, newPage) => {
    setPageShoppingRequestProducts(newPage);
  };

  const handleChangeRowsPerPageShoppingRequestProducts = (event) => {
    setRowsPerPageShoppingRequestProducts(+event.target.value);
    setPageShoppingRequestProducts(0);
  };
  /* END SHOPPING REQUEST - PRODUCT */

  /* START SHOPPING ORDER */
  const columnShoppingOrder = [
    { id: 'solicitud', label: 'SOLICITUD', minWidth: 100 },
    { id: 'fecha', label: 'FECHA 0.C', minWidth: 100 },
    { id: 'vendedor', label: 'VENDEDOR', minWidth: 170 },
    // { id: 'solicitudes', label: '# SOLICITUDES', minWidth: 170 },
    { id: 'productos', label: '# PRODUCTOS', minWidth: 170 },
    { id: 'icon', label: '', minWidth: 170 },
  ];

  function createDataShoppingOrder(solicitud, fecha, vendedor, solicitudes, productos, icon) {
    return { solicitud, fecha, vendedor, solicitudes, productos, icon };
  }

  const rowsShoppingOrderData = [
    createDataShoppingOrder('2222222', '19/SEP/2022', 'Maria Montes', '5', '10', true),
    createDataShoppingOrder('3333333', '22/SEP/2022', 'Carlos Torres', '10', '50', true),
    createDataShoppingOrder('4444444', '22/SEP/2022', 'Eugenia Martinez', '1', '1', true),
    createDataShoppingOrder('4444444', '22/SEP/2022', 'Carlos Torres', '2', '2', true)
  ];

  const [rowsShoppingOrder, setRowsShoppingOrder] = useState(rowsShoppingOrderData);
  const [rowsShoppingOrderOriginal, setRowsShoppingOrderOriginal] = useState(rowsShoppingOrderData);
  const [rowsPerPageShoppingOrder, setRowsPerPageShoppingOrder] = useState(7);
  const [pageShoppingOrder, setPageShoppingOrder] = useState(0);

  const handleChangePageShoppingOrder = (event, newPage) => {
    setPageShoppingOrder(newPage);
  };

  const handleChangeRowsPerPageShoppingOrder = (event) => {
    setRowsPerPageShoppingOrder(+event.target.value);
    setPageShoppingOrder(0);
  };
  /* END SHOPPING ORDER */

  /* START SHOPPING ORDER - PRODUCTS */
  const columnShoppingOrderProducts = [
    { id: 'solicitud', label: 'SOLICITUD', width: '5%' },
    { id: 'proveedor', label: 'PROVEEDOR', width: '5%', },
    { id: 'fecha', label: 'FECHA O.C', width: '5%' },
    { id: 'pedido', label: '# PEDIDO', width: '5%' },
    { id: 'id', label: 'ID', width: '5%' },
    { id: 'productos', label: 'PRODUCTOS', width: '5%' },
    { id: 'cantidad', label: 'CANTIDAD', width: '5%' },
    { id: 'eta', label: 'ETA', width: '5%' },
    { id: 'proceso', label: 'PROCESO', width: '5%' },
    { id: 'icon', label: '', width: '5%' },
  ];

  function createDataShoppingOrderProducts(solicitud, proveedor, fecha, pedido, id, productos, cantidad, eta, proceso, icon) {
    return { solicitud, proveedor, fecha, pedido, id, productos, cantidad, eta, proceso, icon };
  }

  const rowsShoppingOrderProductsData = [
    createDataShoppingOrderProducts('2222222', '19/SEP/2022', '20/SEP/2022', '00000026', 'CS 8201-08 ', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '5', '29/SEP/2022', 'PENDIENTE', 'pencil'),
    createDataShoppingOrderProducts('3333333', '2/SEP/2022', '22/SEP/2022', '00000023', 'CS 2600-6-45  ', 'COSMICMIA - TORNILLO D6.5 MM, LONGITUD 45 MM', '50', '30/SEP/2022', 'EN PROCESO', 'pencil'),
    createDataShoppingOrderProducts('4444444', '22/SEP/2022', '22/SEP/2022', '00000022', 'CS 8201-08  ', 'ADD - D 12 MM, ALTURA 16-25 MM, 6°', '2', '22/SEP/2022', 'SURTIDO', 'eye')
  ];

  const [rowsShoppingOrderProducts, setRowsShoppingOrderProducts] = useState(rowsShoppingOrderProductsData);
  const [rowsShoppingOrderProductsOriginal, setRowsShoppingOrderProductsOriginal] = useState(rowsShoppingOrderProductsData);
  const [rowsPerPageShoppingOrderProducts, setRowsPerPageShoppingOrderProducts] = useState(7);
  const [pageShoppingOrderProducts, setPageShoppingOrderProducts] = useState(0);

  const handleChangePageShoppingOrderProducts = (event, newPage) => {
    setPageShoppingOrderProducts(newPage);
  };

  const handleChangeRowsPerPageShoppingOrderProducts = (event) => {
    setRowsPerPageShoppingOrderProducts(+event.target.value);
    setPageShoppingOrderProducts(0);
  };
  /* END SHOPPING ORDER PRODUCTS */

  /* START SHOPPING ORDER - SUPLIER */
  const columnShoppingOrderSuplier = [
    { id: 'fecha', label: 'FECHA O.C', width: '5%' },
    { id: 'proveedor', label: 'PROVEEDOR', width: '5%' },
    { id: 'ordenes', label: '# ORDENES', width: '5%' },
    { id: 'solicitudes', label: '# SOLICITUDES', width: '5%' },
    { id: 'productos', label: '# PRODUCTOS', width: '5%' },
    { id: 'proceso', label: 'PROCESO', width: '5%' },
    { id: 'icon', label: '', width: '5%' },
  ];

  function createDataShoppingOrderSuplier(fecha, proveedor, ordenes, solicitudes, productos, proceso, icon) {
    return { fecha, proveedor, ordenes, solicitudes, productos, proceso, icon };
  }

  const rowsShoppingOrderSuplierData = [
    createDataShoppingOrderSuplier('19/SEP/2022', 'Maria Montes', '5', '5', '5', 'PENDIENTE', 'eye'),
    createDataShoppingOrderSuplier('12/SEP/2022', 'Carlos Torres', '10', '50', '50', 'EN PROCESO', 'eye'),
    createDataShoppingOrderSuplier('22/SEP/2022', 'Eugenia MArtinez', '2', '2', '2', '22/SEP/2022', 'SURTIDO', 'eye')
  ];

  const [rowsShoppingOrderSuplier, setRowsShoppingOrderSuplier] = useState(rowsShoppingOrderSuplierData);
  const [rowsShoppingOrderSuplierOriginal, setRowsShoppingOrderSuplierOriginal] = useState(rowsShoppingOrderSuplierData);
  const [rowsPerPageShoppingOrderSuplier, setRowsPerPageShoppingOrderSuplier] = useState(7);
  const [pageShoppingOrderSuplier, setPageShoppingOrderSuplier] = useState(0);

  const handleChangePageShoppingOrderSuplier = (event, newPage) => {
    setPageShoppingOrderSuplier(newPage);
  };

  const handleChangeRowsPerPageShoppingOrderSuplier = (event) => {
    setRowsPerPageShoppingOrderSuplier(+event.target.value);
    setPageShoppingOrderSuplier(0);
  };
  /* END SHOPPING ORDER SUPLIER */

  /* START ZONE */
  const columnZone = [
    { id: 'solicitud', label: 'SOLICITUD', width: '5%' },
    { id: 'proveedor', label: 'PROVEEDOR', width: '5%' },
    { id: 'fecha', label: 'FECHA O.C', width: '5%' },
    { id: 'pedido', label: '# PEDIDO', width: '5%' },
    { id: 'id', label: 'ID', width: '5%' },
    { id: 'productos', label: 'PRODUCTOS', width: '5%' },
    { id: 'cantidad', label: 'CANTIDAD', width: '5%' },
    { id: 'pendiente', label: 'PENDIENTE', width: '5%' },
    { id: 'eta', label: 'ETA', width: '5%' },
    { id: 'proceso', label: 'PROCESO', width: '5%' },
  ];

  function createDataZone(solicitud, proveedor, fecha, pedido, id, productos, cantidad, pendiente, eta, proceso) {
    return { solicitud, proveedor, fecha, pedido, id, productos, cantidad, pendiente, eta, proceso };
  }

  const rowsZoneData = [
    createDataZone('222222', 'Maria', '20/SEP/2022', '0000026', 'CS 8201-08', 'CERV-X - CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '5', '3', '29/SEP/2022', 'PENDIENTE')
  ];

  const [rowsZone, setRowsZone] = useState(rowsZoneData);
  const [rowsZoneOriginal, setRowsZoneOriginal] = useState(rowsZoneData);
  const [rowsPerPageZone, setRowsPerPageZone] = useState(7);
  const [pageZone, setPageZone] = useState(0);

  const handleChangePageZone = (event, newPage) => {
    setPageZone(newPage);
  };

  const handleChangeRowsPerPageZone = (event) => {
    setRowsPerPageZone(+event.target.value);
    setPageZone(0);
  };
  /* END ZONE */

  /* START Products Shopping */
  const columnShoppingOrderProductsModal = [
    { id: 'color', label: 'Color', width: '5%' },
    { id: 'talla', label: 'Talla', width: '5%' },
    { id: 'bordado', label: 'Bordado', width: '5%' },
    { id: 'eta', label: 'ETA', width: '5%' },
    { id: 'cantidad', label: 'Cantidad', width: '5%' },
    { id: 'deleteIcon', label: '', width: '5%' },
    { id: 'editIcon', label: '', width: '5%' },
    { id: 'confirmIcon', label: '', width: '5%' },
  ];

  function createDataShoppingOrderProductsModal(color, talla, bordado, eta, cantidad, deleteIcon, editIcon, confirmIcon) {
    return { color, talla, bordado, eta, cantidad, deleteIcon, editIcon, confirmIcon };
  }

  const rowsShoppingOrderProductsModalData = [
    createDataShoppingOrderProductsModal('Blanco', 'S', 'Dra. Victoria Castro Cortés', '12/10/2022', '5', 'deleteIcon', 'editIcon', 'confirmIcon'),
    createDataShoppingOrderProductsModal('Blanco', 'SM', 'Dra. Victoria Castro Cortés', '12/10/2022', '5', 'deleteIcon', 'editIcon', 'confirmIcon'),
    createDataShoppingOrderProductsModal('Blanco', 'S', 'Dra. Victoria Castro Cortés', '12/10/2022', '5', 'deleteIcon', 'editIcon', 'confirmIcon')
  ];

  const [rowsShoppingOrderProductsModal, setRowsShoppingOrderProductsModal] = useState(rowsShoppingOrderProductsModalData);
  const [rowsShoppingOrderProductsModalOriginal, setRowsShoppingOrderProductsModalOriginal] = useState(rowsShoppingOrderProductsModalData);
  const [rowsPerPageShoppingOrderProductsModal, setRowsPerPageShoppingOrderProductsModal] = useState(7);
  const [pageShoppingOrderProductsModal, setPageShoppingOrderProductsModal] = useState(0);

  const handleChangePageShoppingOrderProductsModal = (event, newPage) => {
    setPageShoppingOrderProductsModal(newPage);
  };

  const handleChangeRowsPerPageShoppingOrderProductsModal = (event) => {
    setRowsPerPageShoppingOrderProductsModal(+event.target.value);
    setPageShoppingOrderProductsModal(0);
  };
  /* END  Products Shopping */

  /* Drawer Client */
  const [stateDrawerClient, setStateDrawerClient] = useState(false);

  const toggleDrawerClient = () => {
    setStateDrawerClient(!stateDrawerClient);
  };

  const [trackingModal, setTrackingModal] = useState(false);
  const [openModalShoppingProducts, setOpenModalShoppingProducts] = React.useState(false);
  const [PurchaseOrder, setPurchaseOrder] = React.useState([]);
  const [PurchaseOrderOriginal, setPurchaseOrderOriginal] = React.useState([]);

  const [PurchaseOrderCreated, setPurchaseOrderCreated] = React.useState([]);
  const [PurchaseOrderCreatedOriginal, setPurchaseOrderCreatedOriginal] = React.useState([]);

  const getOrder = async () => {
    await GetPurchaseOrderAsync(productSelected ? 1 : 2, requestH).then((resp) => {
      setPurchaseOrder(resp.response)
      setPurchaseOrderOriginal(resp.response)
    })
  }


  const getOrderurchaseOrderCreated = async () => {
    await GetPurchaseOrderCreatedAsync(requestH).then((resp) => {
      setPurchaseOrderCreated(resp.response)
      setPurchaseOrderCreatedOriginal(resp.response)
    })
  }

  useEffect(() => {
    getOrder()
    getOrderurchaseOrderCreated()
  }, []);

  const [openconfirm, setOpenconfirm] = React.useState(false);
  const [openerror, setOpenError] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpenConfirm = () => {
    setOpenconfirm(true);
    setTrackingModal(false);

  };

  const handleCloseconfirm = () => {
    setOpenconfirm(false);
    setTrackingModal(false);
    props.history.push({ pathname: '/' });
  };

  // DIALOG DE ERROR
  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const [arraySelected, setArraySelected] = useState([]);
  const onClickSelect = (e, objectToInsert, type) => {
    console.log("MI EVENTO---", e.target.checked)
    console.log("MI OBJETO", objectToInsert)
    console.log("CHECK ???", type)
    const newArray = JSON.parse(JSON.stringify(arraySelected));

    if (e.target.checked) {
      newArray.push(objectToInsert);
    } else {
      newArray.splice(objectToInsert, 1)
    }
    setArraySelected(newArray);

  };

  const [supplierName, setsupplierName] = React.useState('')
  const [supplierContact, setsupplierContact] = React.useState('')

  const savePurchase = async () => {
    if (arraySelected.length > 0) {
      console.log("REQUEST PARA VER SI VIENE ID DE USUARIO", requestH)
      let data = {
        "id": 0,
        "supplier": supplierName,
        "supllierContact": supplierContact,
        "userId": loginData.userID,
        "status": 1,
        "purchaseOrderDate": " "
      }
      console.log("DATA PARA WEB SERVICE--->", data)

      await SetPurchaseOrderAsync(data, requestH).then(async (resp) => {
        console.log("RESPUESTA DEL 1ER SERVICIO --->", resp)
        const saved = resp.response;
        for (let x = 0; x < arraySelected.length; x++) {
          let count = 1
          let datadetail = {
            "id": 0,
            "purchaseOrderId": saved.id,
            "orderDetailId": arraySelected[x].orderDetailID,
            "quantityOrder": arraySelected[x].productCount,
            "status": 0
          };

          await setpurchaseOrderDetailAsync(datadetail, requestH).then(resp => {
            if (resp.sucess) {
              count++
              handleClickOpenConfirm()
              console.log("ACERTÓ")
            } else {
              handleClickError()
              console.log("error")
            }
            if (count == arraySelected.length) {
              handleClickOpenConfirm()
            }
          });
        }
      })
      setArraySelected([])
    }
  }

  function formatearFecha(fechaOriginal) {
    var partes = fechaOriginal.split(" ");
    var fechaParte = partes[0];

    var fechaSplit = fechaParte.split("/");
    var mes = fechaSplit[0];
    var dia = fechaSplit[1];
    var anio = fechaSplit[2];

    var fechaFormateada = dia + "/" + mes + "/" + anio;

    return fechaFormateada;
  }

  return (
    <div style={{
      width: '100%',
      paddingTop: '16vh',
      height: '100%'
    }}>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={`/`} title={`COMPRAS`} />
      <div >

        {/* TopBar */}
        <div style={{
          background: '#F0F0F0',
          display: 'flex'
        }}>
          <div style={{ width: '70%' }}>
            <div style={{ marginTop: '20px', marginLeft: '50px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Filtro</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '35%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                  <FormControl variant="outlined" className={classes.formControl} style={{
                    width: '100%',
                    height: '46px',
                    boxSizing: 'border-box',
                    background: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.5)',
                    borderRadius: '4px'
                  }}>
                    <InputLabel id="demo-simple-select-outlined-label">Mostrar por</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      labelWidth={'Tipo de Generador'}
                      style={{ height: '46px' }}
                      value={showBy}
                      onChange={(e) => {
                        setShowBy(e.target.value)
                        setProductSelected(false)
                      }}
                    >
                      <MenuItem value={'products'} disabled style={{ display: 'none' }}>Mostrar Por</MenuItem>
                      <MenuItem value={'suplier'} disabled style={{ display: 'none' }}>Mostrar Por</MenuItem>
                      <MenuItem value={'zone'} disabled style={{ display: 'none' }}>Mostrar Por</MenuItem>
                      <MenuItem value={'shoppingRequest'}>Solicitud a compras</MenuItem>
                      <MenuItem value={'shoppingOrder'}>Orden de compras</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>


          <div style={{ display: 'flex', flexDirection: 'column', width: '50%', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>

              {/* {(showBy == 'shoppingOrder' || showBy == 'products' || showBy == 'suplier' || showBy == 'zone') &&
                <div style={{ width: '50%' }}>
                  <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                    <FormControl variant="outlined" className={classes.formControl} style={{
                      width: '100%',
                      height: '46px',
                      boxSizing: 'border-box',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px'
                    }}>
                      <InputLabel id="demo-simple-select-outlined-label">Buscar Por</InputLabel>
                      <Select
                        style={{ height: '46px' }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        labelWidth={'Tipo de Generador'}
                        value={showBy}
                        onChange={(e) => { setShowBy(e.target.value) }}
                      >
                        <MenuItem value={'shoppingRequest'} disabled style={{ display: 'none' }}>Buscar Por</MenuItem>
                        <MenuItem value={'shoppingOrder'} disabled style={{ display: 'none' }}>Buscar Por</MenuItem>
                        <MenuItem value={'products'} >Productos</MenuItem>
                        <MenuItem value={'suplier'}>Proveedor</MenuItem>
                        <MenuItem value={'zone'}>Zona de embarque</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              } */}

              {showBy == 'shoppingRequest' &&
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon style={{ color: '#18AA00' }} />}
                      name="checkedH"
                      checked={productSelected}
                      onChange={(e) => {
                        setProductSelected(e.target.checked)
                        getOrder()
                      }}
                    />}
                  label="Productos"
                  style={{ width: '50%', justifyContent: 'flex-end' }}
                />
              }

              <div style={{ width: '100%' }}>

                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px', width: '90%' }}>
                  <SearchBar
                    value={searchedValuesGeneral}
                    onChange={(searchVal) => { requestSearch(searchVal) }}
                    onCancelSearch={() => cancelSearch()}
                    placeholder={'Buscar'}
                    style={{
                      width: '100%',
                      height: '46px',
                      boxSizing: 'border-box',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div style={{ maxHeight: '60vh', overflow: 'scroll', marginBottom: '8rem' }}>
          {/* Tabla Solicitud a Compras */}
          {showBy == 'shoppingRequest' && !productSelected &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Solicitud a compras</p>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnShoppingRequest.map((column) => {
                        // console.log('column: ', column)
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select'}
                              {/* {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                               */}{column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PurchaseOrder.map((row) => {
                      console.log(row)
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingRequest.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            // console.log(row)
                            // console.log(column)
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {
                                  column.id == 'select' ? <Checkbox checked={row.checked} value={row} onChange={e => { onClickSelect(e, row, row.checked) }} style={{ color: 'black' }} /> :
                                    column.id == 'pedido' ?
                                      <NavLink to={{ pathname: `/shoppingRequestDetails`, state: { remission: row } }} >
                                        <p style={{ color: 'black', fontWeight: 'bold', textDecoration: 'underline' }}>{row.orderId}</p>
                                      </NavLink> :
                                      column.id == 'fecha' ? formatearFecha(row.orderDate) :
                                        column.id == 'pactado' ? row.productCode :
                                          column.id == 'productos' ? row.productCount :
                                            column.id == 'vendedor' ? row.sellerName :
                                              value
                                }
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }

          {/* Tabla Solicitud a Compras - Productos */}
          {showBy == 'shoppingRequest' && productSelected &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Solicitud a compras - Productos</p>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columnShoppingRequestProducts.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select'}
                              {/* {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                               */}{column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PurchaseOrder.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingRequestProducts.map((column) => {
                            const value = row[column.id];
                            // console.log(value)
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {
                                  column.id == 'select' ? <Checkbox checked={row.checked} value={row} style={{ color: 'black' }} /> :
                                    column.id == 'solicitud' ? row.orderId :
                                      column.id == 'pactado' ? formatearFecha(row.orderDate) :
                                        column.id == 'pedido' ? row.orderId :
                                          column.id == 'vendedor' ? row.sellerName :
                                            column.id == 'id' ? row.productCode :
                                              column.id == 'productos' ? row.productDescription :
                                                column.id == 'cantidad' ? row.productCount :
                                                  column.id == 'icon' ? <NavLink className='c-footer-copyright' style={{ marginRight: 10 }} to={{ pathname: `/shoppingRequestDetails`, state: { state: 'cotizacionesGuardadas' } }}><VisibilityIcon style={{ color: 'black' }} /></NavLink> :
                                                    value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }

          {/* Tabla Orden de Compras */}
          {showBy == 'shoppingOrder' &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Orden de compras</p>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columnShoppingOrder.map((column) => {
                        console.log('column: ', column)
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                              {/* {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                               */}{column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PurchaseOrderCreated.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingOrder.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {
                                  column.id == 'solicitud' ? row.id :
                                    column.id == 'fecha' ? formatearFecha(row.purchaseOrderDate) :
                                      column.id == 'vendedor' ? row.supplier :
                                        // column.id == 'solicitudes' ? '-----' :
                                        column.id == 'productos' ? row.productos :
                                          column.id == 'icon' ? <NavLink className='c-footer-copyright' style={{ marginRight: 10 }} to={{ pathname: `/shoppingRequestDetails`, state: { state: row } }}><VisibilityIcon style={{ color: 'black' }} /></NavLink> :
                                            value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }

          {/* Tabla Orden de Compras - Productos */}
          {showBy == 'products' &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Orden de compras - Productos</p>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnShoppingOrderProducts.map((column) => {
                        console.log('column: ', column)
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                              {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PurchaseOrderCreated.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingOrderProducts.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                  column.id == 'icon' ? value == 'eye' ? <VisibilityIcon style={{ color: 'black' }} /> : <CreateIcon style={{ color: 'black' }} onClick={() => { setOpenModalShoppingProducts(true) }} /> :
                                    column.id == 'proceso' ? <p style={{ fontWeight: 'bold', color: value == 'PENDIENTE' ? '#EB5500' : value == 'EN PROCESO' ? '#37A7B9' : value == 'SURTIDO' ? '#275300' : '#000' }}>{value}</p> :
                                      value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }

          {/* Tabla Orden de Compras - Proveedor */}
          {showBy == 'suplier' &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Orden de compras - Proveedor</p>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnShoppingOrderSuplier.map((column) => {
                        console.log('column: ', column)
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                              {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsShoppingOrderSuplier && rowsShoppingOrderSuplier.slice(pageShoppingOrderSuplier * rowsPerPageShoppingOrderSuplier, pageShoppingOrderSuplier * rowsPerPageShoppingOrderSuplier + rowsPerPageShoppingOrderSuplier).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingOrderSuplier.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                  column.id == 'icon' ? value == 'eye' ? <VisibilityIcon style={{ color: 'black' }} /> : <CreateIcon style={{ color: 'black' }} /> :
                                    column.id == 'proceso' ? <p style={{ fontWeight: 'bold', color: value == 'PENDIENTE' ? '#EB5500' : value == 'EN PROCESO' ? '#37A7B9' : value == 'SURTIDO' ? '#275300' : '#000' }}>{value}</p> :
                                      value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }

          {/* Tabla Zona de Embarque */}
          {showBy == 'zone' &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>

              <p style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Zona de embarque</p>
              <Accordion style={{ background: '#D9D9D9', borderRadius: '4px', marginBottom: '10px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', lineHeight: '17px', color: '#484F59' }}>ZONA G</Typography>
                    <Typography style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 400, fontSize: '18px', lineHeight: '17px', color: '#000000' }}>OC - G000234</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ background: 'white' }}>
                  <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          {columnZone.map((column) => {
                            console.log('column: ', column)
                            const id = column.id
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                <div style={{ display: 'flex' }}>
                                  {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                                  {id != 'select' && id != 'remision' && <ImportExportIcon style={{ color: '#484f59' }} />}
                                  {column.label}
                                </div>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsZone && rowsZone.slice(pageZone * rowsPerPageZone, pageZone * rowsPerPageZone + rowsPerPageZone).map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                              {columnZone.map((column) => {
                                const type = row.tipo
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                      column.id == 'icon' ? value == 'eye' ? <VisibilityIcon style={{ color: 'black' }} /> : <CreateIcon style={{ color: 'black' }} /> :
                                        column.id == 'proceso' ? <p style={{ fontWeight: 'bold', color: value == 'PENDIENTE' ? '#EB5500' : value == 'EN PROCESO' ? '#37A7B9' : value == 'SURTIDO' ? '#275300' : '#000' }}>{value}</p> :
                                          value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </div>
          }
        </div>


        {/* Drawer Backlog */}
        <Drawer anchor={'right'} open={stateDrawerClient} onClose={toggleDrawerClient} PaperProps={{ sx: { width: "10%" } }}>
          <div style={{ margin: 20, maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontFamily: 'arial', fontSize: '15px', marginTop: 20 }}>CS 8201-08</p>
            <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>CHALECO LARGO CON BOLSAS</p>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', marginTop: 20, fontWeight: 'bold' }}>CHALECO 1</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>COLOR:</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>BLANCO</p>
              </div>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>TALLA:</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>S</p>
              </div>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>BORDADO:</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>Dra. Vctoria Castro Cortés</p>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', marginTop: 20, fontWeight: 'bold' }}>CHALECO 2</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>COLOR:</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>BLANCO</p>
              </div>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>TALLA:</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>S</p>
              </div>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '15px' }}>BORDADO:</p>
                <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>Dra. Vctoria Castro Cortés</p>
              </div>
            </div>


            <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold', marginTop: 100 }}>STATUS DE COMPRAS</p>
            <Divider />

            <div style={{ display: 'flex', marginTop: 5 }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>19/08/2022</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>ENVIO DE SOLICITUD</p>
            </div>

            <div style={{ display: 'flex', marginTop: 5 }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>20/08/2022</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>SE REALIZA LA SOLICITUD AL PROVEEDOR</p>
            </div>

            <div style={{ display: 'flex', marginTop: 5 }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>25 /08/2022</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>PRODUCTOS EN CAMINO, FECHA ESTIMADA DE ENTREGA 30/08/2022</p>
            </div>

            <div style={{ display: 'flex', marginTop: 5 }}>
              <p style={{ fontFamily: 'arial', fontSize: '15px', marginRight: 20 }}>30/08/2022</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px', fontWeight: 'bold' }}>PRODUCTOS ENTREGADOS A ALMACEN, RECIBIO JUAN PEREZ</p>
            </div>



          </div>
        </Drawer>


        {/* GENERATE SHOPPING ORDER */}
        <Dialog
          open={trackingModal}
          onClose={() => { setTrackingModal(false) }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          className="price-list-dialog"
        >
          <DialogTitle id="form-dialog-title" style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '20px',
            textAlign: 'start',
            color: '#000000'
          }}>Agregar Guia</DialogTitle>
          <DialogContent className="price-list-dialog-content" style={{ display: 'flex', alignContent: 'flex-start', alignItems: 'flex-start' }}>
            <div style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 300,
              fontSize: '20px',
              lineHeight: '23px',
              display: 'flex',
              alignItems: 'start',
              color: '#000000',
            }}>GENERAR ORDEN DE COMPRA</div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                <TextField id="outlined-basic" value={supplierName} onChange={(e) => { setsupplierName(e.target.value) }} label="Nombre del proveedor" variant="outlined" style={{ width: '49%' }} />
                <TextField id="outlined-basic" value={supplierContact} onChange={(e) => { setsupplierContact(e.target.value) }} label="Contacto" variant="outlined" style={{ width: '49%' }} />
              </div>
            </div>

          </DialogContent>
          <DialogActions className="price-list-dialog-actions">
            <button className="btn" onClick={savePurchase}>Enviar</button>
          </DialogActions>
        </Dialog>

        {/* Dialog - ShoppingOrder - PRODUCTS */}
        {/* Surtir Modal */}
        <Dialog
          open={openModalShoppingProducts}
          onClose={() => { setOpenModalShoppingProducts(false) }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle id="form-dialog-title"><div style={{ display: 'flex', marginBottom: 40 }}>
            <p style={{ marginRight: 30, fontWeight: 'bold', fontSize: 25 }}>Escanear</p>
          </div></DialogTitle>
          <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '20px', flexDirection: 'column', marginRight: '100px', fontFamily: 'arial' }}>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ width: '24%' }}>
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '20px',
                  lineHeight: '17px',
                  color: '#484F59'
                }}>CS 8201-08</p>
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  lineHeight: '20px',
                  color: '#000000',
                  marginTop: 5
                }}>CHALECO LARGO CON BOLSAS</p>
              </div>
              <TextField id="outlined-basic" label="Fecha" variant="outlined" style={{ width: '24%' }} />
              <TextField id="outlined-basic" label="Cantidad a entregar" variant="outlined" style={{ width: '24%' }} />
              <button className="btn" style={{ width: '10%', fontFamily: 'arial', fontWeight: 'bold' }}>AGREGAR</button>
            </div>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
              <div style={{ width: '24%' }}>
                <p style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#000000'
                }}>Datos del producto</p>
                <Divider style={{ color: '#000' }} />
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnShoppingOrderProductsModal.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <div style={{ display: 'flex' }}>
                              {id == 'select' && <Checkbox checked={false} style={{ color: 'black' }} />}
                              {column.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsShoppingOrderProductsModal && rowsShoppingOrderProductsModal.slice(pageShoppingOrderProductsModal * rowsPerPageShoppingOrderProductsModal, pageShoppingOrderProductsModal * rowsPerPageShoppingOrderProductsModal + rowsPerPageShoppingOrderProductsModal).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnShoppingOrderProductsModal.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id == 'select' ? <Checkbox checked={false} style={{ color: 'black' }} /> :
                                  column.id == 'deleteIcon' ? <DeleteIcon style={{ color: '#C70808' }} /> :
                                    column.id == 'editIcon' ? <EditIcon style={{ color: '#35A6B9' }} /> :
                                      column.id == 'confirmIcon' ? <CheckCircleIcon style={{ color: '#689F38' }} /> :
                                        value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>

            </div>
          </div>
          <DialogContent style={{ alignSelf: 'end' }}>
            <button className="btn" style={{ width: 220, fontFamily: 'arial', fontWeight: 'bold' }}>ACTUALIZAR</button>
          </DialogContent>
          <DialogActions style={{ marginTop: 100 }}>
          </DialogActions>
        </Dialog>

        {/* DIALOG DE CONFIRMACIÓN DE SAVE */}
        <Dialog fullScreen={fullScreen} open={openconfirm} onClose={handleCloseconfirm} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{"Notificación de cambio de Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se han ejecutado los cambios con éxito
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseconfirm} color="primary" autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        {/* DIALOG DE ERROR DE SAVE */}
        <Dialog fullScreen={fullScreen} open={openerror} onClose={handleCloseError} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{"Notificación de cambio de Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ha ocurrido un error, intentalo más tarde.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError} color="primary" autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Bottom Bar */}
        <div style={{
          width: '100%',
          height: '9vh',
          position: 'fixed',
          bottom: '0',
          alignSelf: 'center'
        }}>
          <div style={{
            background: 'linear-gradient(90deg, rgba(232, 232, 232, 1) 0%, rgba(232, 232, 232, 1) 94%)',
            height: '6vh',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }} >
            <div style={{
              marginTop: 10, marginRight: 80,
              background: 'linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%)',
              color: '#fff',
              minWidth: '10%',
              maxWidth: '25%',
              height: '30%',
              padding: '.5vw',
              cursor: 'pointer',
              border: 'none',
              borderRadius: '2vw',
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'Arial',
              fontWeight: 'bold'
            }} onClick={() => { setTrackingModal(true) }}>
              GENERAR ORDEN DE COMPRA
            </div>


          </div>
        </div>

      </div >
    </div >
  );
};
