import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './styleMobileOrders.scss';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { Button, Icon, Snackbar, TextField, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { cancelSaleAsync, getSalesAsync } from '../../../actions/userActions';
import { Alert, TabPanel } from '@material-ui/lab';
import notShipment from '../../../assets/img/126PX/notSend_icon.png'
import shipment from '../../../assets/img/126PX/shipment_icon.png'
import delivered from '../../../assets/img/126PX/delivered_icon.png';
import notDelivered from '../../../assets/img/126PX/notDelivered_icon.png';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import CircularProgress from '@material-ui/core/CircularProgress';
import { encryptData } from '../../../utils/encrypt.service';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import RefreshIcon from '@material-ui/icons/Sync';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const MobileOrdersPage = () => {
    const gridRef = useRef();

    const requestH = useSelector((state) => state.user.request);
    const history = useHistory();

    const [sales, setSales] = useState([]);
    const [cancelSaleAlert, setCancelSaleAlert] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [saleId, setSaleId] = useState(0);
    const [validReason, setValidReason] = useState(false);
    const intervalDuration = 120000;
    const [timeLeft, setTimeLeft] = useState(intervalDuration);
    const [ticketCancelInfo, setTicketCancelInfo] = useState([]);

    useEffect(() => {
        const intervalId = setInterval(() => {
          setTimeLeft(prevTimeLeft => {
            if (prevTimeLeft <= 1000) {
              // Reinicia el temporizador cuando llega a cero
              return intervalDuration;
            }
            return prevTimeLeft - 1000;
          });
        }, 1000);// Cada 1000 ms le quitas 1 segundo al contador general
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        getSales()
        const intervalId = setInterval(() => {
            getSales()
        }, intervalDuration);

        return () => clearInterval(intervalId);
    }, [])

    const getSales = async () => {
        onBtShowLoading()
        await getSalesAsync(requestH).then(resp => {
            if (resp.response && resp.response.length > 0) {
                setSales(resp.response)
                onBtHide()
            }
            else {
                onBtShowNoRows();
            }
        });
    }

    const closeCancelSaleAlert = (event) => {
        setCancelSaleAlert(false);
    }

    const cancelSale = async () => {
        console.log("ID CANCELAR: ", saleId)
        if (motivoCancel.cancelReason)
        {
            const palabra = encryptData(JSON.stringify({ "code": 116, "SaleId": saleId, "motivo": motivoCancel.cancelReason }))
            await cancelSaleAsync({"palabra" : palabra}, requestH).then((res) => {
                if (res.valido === 1) {
                    handleCloseCancelDialog()
                    setCancelSaleAlert(true)
                }
            })
        }
        else
        {
        }
    }

    /* Table */
    const pagination = true;
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [5, 10, 15, 20, 25, 50, 100, 500, 1000];
    const rowHeight = 70;
    const showScroll = true;

    const tableColumns = [
        { 
            field: 'ticketNumber', headerName: 'FOLIO VM', suppressMovable: true, sortable: false, 
            minWidth: 100, maxWidth: 150, flex: 1 
        },
        { 
            field: 'seller.seller.firstName', headerName: 'VENDEDOR', type: 'seller', sortable: false, 
            wrapText: true, autoHeight: true, suppressMovable: true
        },
        { 
            field: 'date', headerName: 'FECHA', type: 'date', suppressMovable: true, wrapText: true, 
            autoHeight: true 
        },
        { 
            field: 'client.clientName', headerName: 'NOMBRE', type: 'client', suppressMovable: true, 
            wrapText: true, autoHeight: true 
        },
        { field: 'total', headerName: 'TOTAL', type: 'total', suppressMovable: true },
        { 
            field: 'status', headerName: 'ESTATUS DEL PEDIDO', cellRenderer: statusTag, sortable: false, 
            suppressMovable: true 
        },
        { 
            field: 'paymentMethod', headerName: 'FORMA DE PAGO', type: 'paymentMethod', sortable: false, 
            suppressMovable: true, wrapText: true, autoHeight: true,
        },
        {
            field: 'billData', headerName: 'DATOS DE FACTURACIÓN', cellRenderer: getFacturationType, 
            sortable: false, suppressMovable: true
        },
        { 
            field: 'billStatus', headerName: 'FACTURADO', cellRenderer: getFacturationBillType, wrapText: true, autoHeight: true,
            sortable: false, suppressMovable: true 
        },
        {
            field: 'bankReconciliation', headerName: 'CONCILIADO', cellRenderer: getBankReconciliation, 
            sortable: false, suppressMovable: true
        },
        { 
            field: 'shipData', headerName: 'DATOS DE ENVÍO', cellRenderer: getShipment, sortable: false, 
            suppressMovable: true 
        },
        { field: 'shipStatus', headerName: 'ENVIADO', cellRenderer: getShipStatus, sortable: false, suppressMovable: true },
        { field: 'delivered', headerName: 'ENTREGADO', cellRenderer: getDeliveryStatus, sortable: false, suppressMovable: true },
        { field: 'cancel', headerName: '', cellRenderer: getCancelButton, sortable: false, suppressMovable: true },
        { field: 'eye', headerName: '', cellRenderer: getEye, sortable: false, suppressMovable: true, }
    ]

    const columnTypes = useMemo(() => {
        return {
            seller: {
                valueFormatter: getSellerName,
            },
            date: {
                valueFormatter: formatearFecha,
            },
            client: {
                valueFormatter: getClientName
            },
            total: {
                valueFormatter: getCurrency
            },
            paymentMethod: {
                valueFormatter: getPaymentType
            },
            billData: {
                valueFormatter: getFacturationType
            }
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            wrapHeaderText: true,
            autoHeaderHeight: true,
            headerStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }),
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            })
        };
    }, []);

    const autoSizeStrategy = {
        type: 'fitGridWidth',
        //type: 'fitCellContents',
        defaultMinWidth: 100,
    };

    /* Funciones de Formateo de Celdas */

    function getSellerName(data) {
        const sellerInfo = data && data.data && data.data.seller
        if (sellerInfo) {
            return `${sellerInfo.firstName || ''} ${sellerInfo.firstLastName || ''} ${sellerInfo.secondLastName || ''}`
        } else {
            return ''
        }
    }

    function formatearFecha(data) {
        const fecha = new Date(data.data.date);
        const dia = String(fecha.getDate()).padStart(2, '0');
        const mes = String(fecha.getMonth() + 1).padStart(2, '0');
        const año = fecha.getFullYear();
        const hora = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');
        const segundos = String(fecha.getSeconds()).padStart(2, '0');

        const fechaFormateada = `${dia}/${mes}/${año} - ${hora}:${minutos}:${segundos}`;
        return fechaFormateada;
    }

    function getClientName(data) {
        if (data.data.client.clientName) {
            if (data.data.client.clientName == 'null') {
                return ''
            }
            return data.data.client.clientName
        } else {
            return ''
        }
    }

    function getCurrency(data) {
        let numero = data.data.total;
        numero = numero.toFixed(2);
        numero = numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const cantidadMoneda = `$ ${numero} MXN`;
        return cantidadMoneda;
    }

    function statusTag(data) {
        if ((data.data.deliveryNumber !== '0' && data.data.deliveryNumber) && (data.data.invoiceNumber !== '0' && data.data.invoiceNumber) && 
            (data.data.bankReconciliation !== '' && data.data.bankReconciliation !== 'null') && data.data.status !== 2)
        {
            return (
                <div className='finishedTag'>CONCLUIDO</div>
            )
        }
        else if (data.data.status === 2)
        {
            return (
                <div className='cancelTag' onClick={() => cancelDialog(data.data)}>CANCELADO <InfoIcon></InfoIcon> </div>
            )
        }
        else
        {
            return (
                <div className='activeTag'>ACTIVO</div>
            )
        }
        /*switch (data.data.status) {
            case 1:
                return (
                    <div className='activeTag'>ACTIVO</div>
                );

            case 2:
                return (
                    <div>
                        <p className='cancelTag'>CANCELADO</p>
                    </div>
                );

            default:
                return (
                    <p className='finishedTag'>CONCLUIDO</p>
                )
        }*/
    }

    function getPaymentType(data) {
        if (data.data.paymentMethod !== '') {
            return data.data.paymentMethod.toUpperCase()
        } else {
            return 'INDEFINIDO'
        }
    }

    function getFacturationType(data) {
        if (data.data.requiresBill) {
            return (<Icon style={{ color: '#004D9C' }}>check</Icon>)
        } else {
            return (<Icon style={{ color: '#c3c3c3' }}>remove_circle</Icon>)
        }
    }

    function getFacturationBillType(data) {
        /*if (!data.data.billData.billingRFC || data.data.billData.billingRFC == '0' || data.data.billData.billingRFC == '') {*/
        if (data.data.invoiceNumber == '0' || data.data.invoiceNumber == '') {
            return (<Icon style={{ color: '#c3c3c3' }}>remove_circle</Icon>)
        } else {
            return (
                <div>
                    <p>{data.data.invoiceSerial} </p>
                    <p>{data.data.invoiceNumber}</p>
                </div>
            ) 
        }
    }

    function getBankReconciliation(data) {
        if (data.data.bankReconciliation && data.data.bankReconciliation !== '' && data.data.bankReconciliation !== 'null') {
            return data.data.bankReconciliation
        } else {
            return (<Icon style={{ color: '#c3c3c3' }}>remove_circle</Icon>)
        }
    }

    function getShipment(data) {
        if (data.data.deliveryNumber !== '' && data.data.cscName !== '') {
            return (
                <div>
                    <p style={{ color: '#004D9C', textAlign: 'center', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }}> {data.data.cscName } </p>
                    <p>{data.data.deliveryNumber}</p>
                </div>
            )
        } else {
            return (<Icon style={{ color: '#c3c3c3' }}>remove_circle</Icon>)
        }
    }

    function getShipStatus(data) {
        if ((data.data.deliveryNumber === '0' || data.data.deliveryNumber === '') /*&& !data.data.delivered*/) {
            return (<img src={notShipment} width={38} height={38} style={{ filter: 'invert(100%)' }} alt='no enviado' />)
        } else {
            return (<img src={shipment} width={32} height={32} style={{ filter: 'invert(100%)' }} alt='enviado' />)
        }
    }

    function getDeliveryStatus(data) {
        if (data.data.delivered) {
            return (<img src={delivered} width={32} height={32} alt='entregado' style={{ filter: 'invert(100%)' }} />)
        } else {
            return (<img src={notDelivered} width={32} height={32} alt='no entregado' />)
        }
    }

    function getCancelButton(data) {
        return (
            <div>
                {/*<Button variant='contained' disabled={data.data.status === 2 || data.data.delivered} onClick={() => cancelDialog(data.data)}
                    style={data.data.status !== 2 && data.data.delivered === false ? { backgroundColor: '#d10a0a', color: '#fff' } : {}}>
                    CANCELAR
                </Button>*/}
                <Button variant='contained' disabled={data.data.status === 2 || (data.data.deliveryNumber !== '0' && data.data.deliveryNumber !== '')} onClick={() => cancelDialog(data.data)}
                    style={data.data.status !== 2 && (data.data.deliveryNumber === '0' || data.data.deliveryNumber === '') ? { backgroundColor: '#d10a0a', color: '#fff' } : {}}>
                    CANCELAR
                </Button>
            </div>
        )
    }

    function getEye(data) {
        return (
            <Icon style={{ color: 'black', cursor: 'pointer' }} onClick={() => { history.push({ pathname: '/OrdersDetails_VentaMovil', state: { row: data.data } }) }}>visibility_icon</Icon>
        )
    }

    const estatusPedido = [
        {
            value: 'none',
            label: ''
        },
        {
            value: '1',
            label: 'ACTIVO'
        },
        {
            value: '2',
            label: 'CANCELADO'
        },
        {
            value: '3',
            label: 'CONCLUIDO'
        }
    ]

    const estatusFacturado = [
        {
            value: '1',
            label: 'FACTURADO'
        },
        {
            value: '0',
            label: 'SIN FACTURAR'
        }
    ]

    const estatusEntrega = [
        {
            value: '1',
            label: 'SIN ENTREGAR'
        },
        {
            value: '2',
            label: 'ENTREGADO'
        }
    ]

    const [folioFilterValue, setFolioFilterValue] = useState('')
    const [clientFilterValue, setClientFilterValue] = useState('')
    const [statusFilterValue, setStatusFilterValue] = useState('')
    const [facturadoFilterValue, setFacturadoFilterValue] = useState('')
    const [entregaFilterValue, setEntregaFilterValue] = useState('')

    var folioFilter = "";
    var clientFilter = "";
    var statusFilter = "";
    var facturadoFilter = "";
    var entregaFilter = "";

    const cleanFilters = () => {
        setFolioFilterValue('');
        setClientFilterValue('');
        setStatusFilterValue('');
        setFacturadoFilterValue('');
        setEntregaFilterValue('');

        folioFilter = "";
        clientFilter = "";
        statusFilter = "";
        facturadoFilter = "";
        entregaFilter = "";

        externalFilterChanged("", "", "", "", "");
        
    }

    const filterStyles = {
        input: {
            "&:invalid": {
                border: "red solid 2px"
            }
        }
    };

    const externalFilterChanged = useCallback((folio, client, status, facturado, entrega) => {
        folioFilter = folio.toUpperCase();
        clientFilter = client;
        statusFilter = status;
        facturadoFilter = facturado;
        entregaFilter = entrega;
        gridRef.current.api.onFilterChanged();
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        if (folioFilter) {
            return folioFilter !== ""
        } else if (clientFilter) {
            return clientFilter !== ""
        } else if (statusFilter) {
            return statusFilter !== ""
        } else if (facturadoFilter) {
            return facturadoFilter !== ""
        } else if (entregaFilter) {
            return entregaFilter !== ""
        } else {
            return false
        }
    }, []);

    const doesExternalFilterPass = useCallback(
        (node) => {
            if (node.data) {
                return (
                    /* 
                    Primero se revisa que statusFilter sea diferente a "", si es igual a "" quiere decir que el filtro no se activa
                    en caso de que sea diferente a "", se comprueba que node.data.status sea igual a statusFilter si es igual regresa true si no false
                    */
                    (statusFilter == "" || node.data.status == statusFilter) &&
                    /*
                    Despues se revisa si entregaFilter es un filtro activo, si es activo reviso que entregaFilter sea 2(Entregado)
                    Si es dos(entregado) primero reviso si delivered es true si si regresa true si no regresa false
                    En caso de que sea 1(No Entregado) reviso que delivered sea false, si es false regresa true.
                    */
                    (entregaFilter == "" ||
                        (entregaFilter == 2 ?
                            (node.data.delivered ? true : false) :
                            (!node.data.delivered ? true : false)
                        )
                    ) &&
                    /* 
                    Despues se revisa si facturadoFilter es un filtro activo, si es activo primero reviso que facturado filter sea 1(Facturado)
                    ahora para poder checar que este facturado reviso el RFC, para eso debe de existir el RFC, no ser "" y no ser 0, (Aqui se pueden agregar las validaciones necesarias)
                    ahora si facturadoFilter es 2 (No Facturado) se usan las mismas validaciones pero inversas, que no exista, o que sea "" o que sea 0
                    */
                    (facturadoFilter == "" ||
                        (facturadoFilter == 1 ?
                            (
                                /*node.data.billData &&
                                node.data.billData.billingRFC &&
                                node.data.billData.billingRFC != "" &&
                                node.data.billData.billingRFC != "0"*/
                                node.data && node.data.invoiceNumber != "" && node.data.invoiceNumber != "0"
                            ) :
                            (
                                /*!node.data.billData ||
                                !node.data.billData.billingRFC ||
                                node.data.billData.billingRFC == "" ||
                                node.data.billData.billingRFC == "0"*/
                                !node.data || node.data.invoiceNumber == "" || node.data.invoiceNumber == "0"
                            )
                        )
                    ) &&
                    /* Aqui solo reviso que los campos de textos coincidan con lo que se tiene en el json */
                    node.data.ticketNumber.includes(folioFilter) &&
                    node.data.client.clientName.toLowerCase().includes(clientFilter.toLowerCase())
                )
            }
            return true;
        },
        []
    );

    const searchValues = () => {
        externalFilterChanged(folioFilterValue, clientFilterValue, statusFilterValue, facturadoFilterValue, entregaFilterValue)
    }

    const [loading, setLoading] = useState(false); 
    //función para implementar loading a la tabla
    const onBtShowLoading = () => {
        //gridRef.current.api.showLoadingOverlay();
        setLoading(true);
    }

    const onBtShowNoRows = () => {
        //gridRef.current.api.showNoRowsOverlay();
        setLoading(false)
    }

    const onBtHide = () => {
        //gridRef.current.api.hideOverlay();
        setLoading(false)
    }

    const cancelDialog = (data) => {
        setSaleId(data.id)
        setTicketCancelInfo(data)
        setOpenCancelDialog(true)
    }
    const handleCloseCancelDialog = () => {
        setOpenCancelDialog(false)
        const cancelJson = {...motivoCancel}
        cancelJson.cancelReason = ''
        setMotivoCancel(cancelJson)
    }

    const [motivoCancel, setMotivoCancel] = useState({
        cancelReason: ''
    })

    const handleCancelReason = (event) => {
        const cancelJson = {...motivoCancel}
        cancelJson[event.target.id] = event.target.value
        setMotivoCancel(cancelJson)

        if (event.target.value < 1)
        {
            setValidReason(false)
        }
        else
        {
            setValidReason(true)
        }
    }

    const minutes = Math.floor(timeLeft / 60000);
    const seconds = Math.floor((timeLeft % 60000) / 1000);

    return (
        <div className='container'>
            <SubNavBarComponent title={'VENTA MÓVIL'} />

            <div style={{ width: '95vw', display:'flex', justifyContent:'space-between', fontSize: '18px', fontFamily: 'Montserrat, sans-serif', color: '#094070', fontWeight: '700',
                padding: '0 20px'
            }}>
                    <p>La tabla se actualizará en: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
                    <Button className='filterButtons' variant='filled' endIcon={<RefreshIcon />} onClick={() => getSales()} style={{borderRadius: '50px', color: 'white'}}>
                        Actualizar tabla
                    </Button>
            </div>
            {/* -------------------------- TABLE ----------------------------- */}
            <div className='tableContainer'>
                <div style={{ width: '100%', display: 'flex', fontSize: '18px', fontFamily: 'Montserrat, sans-serif', color: '#094070',
                            fontWeight: '700' }}>
                    FILTROS DE BÚSQUEDA
                </div>
                <div className='filterContainer'>
                    <TextField
                        margin='dense'
                        label='Folio VM'
                        variant='outlined'
                        type='text'
                        value={folioFilterValue}
                        onChange={(event) => { setFolioFilterValue(event.target.value) }}
                        style={{ marginRight: '10px', width: 'auto' }}
                        inputProps={{ style: { textTransform: "uppercase" }, pattern: '^[\wÀ-ÿ\u00f1\u00d1]+(\s[\wÀ-ÿ\u00f1\u00d1]+)*$' }}
                    />

                    <TextField
                        margin='dense'
                        label='Cliente'
                        variant='outlined'
                        type='text'
                        value={clientFilterValue}
                        onChange={(event) => { setClientFilterValue(event.target.value) }}
                        style={{ marginRight: '10px', width: '20%' }}
                        inputProps={{ pattern: '^[\wÀ-ÿ\u00f1\u00d1]+(\s[\wÀ-ÿ\u00f1\u00d1]+)*$' }}
                    />

                    <TextField
                        id='selectEstatus'
                        variant='outlined'
                        margin='dense'
                        select
                        value={statusFilterValue}
                        onChange={(event) => { setStatusFilterValue(event.target.value) }}
                        label='Estatus del pedido'
                        style={{ width: '26ch', marginRight: '10px' }}>
                        {estatusPedido.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        id='selectFacturado'
                        variant='outlined'
                        margin='dense'
                        select
                        value={facturadoFilterValue}
                        onChange={(event) => { setFacturadoFilterValue(event.target.value) }}
                        label='Facturado '
                        style={{ width: '26ch', marginRight: '10px' }}>
                        {estatusFacturado.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        id='selectEntrega'
                        variant='outlined'
                        margin='dense'
                        select
                        value={entregaFilterValue}
                        onChange={(event) => { setEntregaFilterValue(event.target.value) }}
                        label='Estatus de entrega'
                        style={{ width: '26ch', marginRight: '10px' }}>
                        {estatusEntrega.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <button className='filterButtons' onClick={searchValues}>
                        BUSCAR
                    </button>

                    <button className='filterButtons' onClick={() => cleanFilters()}>
                        LIMPIAR FILTROS
                    </button>
                </div>

                {loading ? 
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
                    <CircularProgress />
                </div> :
                
                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={{ height: 700 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={sales}
                        columnDefs={tableColumns}
                        columnTypes={columnTypes}
                        defaultColDef={defaultColDef}
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        paginationPageSizeSelector={paginationPageSizeSelector}
                        rowHeight={rowHeight}
                        autoSizeStrategy={autoSizeStrategy}
                        alwaysShowHorizontalScroll={showScroll}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                    />
                </div> }
            </div>

            {/* DIALOGO CANCELAR */}
            <Dialog open={openCancelDialog} onClose={handleCloseCancelDialog} aria-labelledby='cancel-title' maxWidth='md' fullWidth={true}>
                {ticketCancelInfo.status === 2 ? 
                    <>
                    <DialogTitle id='cancel-title' className='cancelTitleBg'>MOTIVO CANCELACIÓN</DialogTitle>
                    <DialogContent style={{padding: '20px'}}>
                        <DialogContentText>{ticketCancelInfo.motivo}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={() => handleCloseCancelDialog()} className='cancelBtn' style={{padding: '0.8em', borderRadius: '50px'}}> CERRAR </button>
                    </DialogActions>
                    </>
                :
                <>
                <DialogTitle id='cancel-title' className='cancelTitleBg'>CANCELAR PEDIDO</DialogTitle>
                <DialogContent style={{padding: '20px'}}>
                    <DialogContentText>Ingrese el motivo de cancelación</DialogContentText>
                    <TextField
                        id='cancelReason'
                        label="Motivo de cancelación"
                        variant='outlined'
                        multiline
                        rowsMax={4}
                        style={{width: '100%'}}
                        inputProps={{ maxLength: 250}}
                        value={motivoCancel.cancelReason}
                        onChange={handleCancelReason}
                        error={!validReason}
                    />
                </DialogContent>
                <DialogActions>
                    <button onClick={() => cancelSale()} className={!validReason ? 'disableButton' : 'cancelBtn'} style={{padding: '0.8em', borderRadius: '50px'}} disabled={!validReason}> CANCELAR PEDIDO </button>
                </DialogActions> 
                </>
                }
            </Dialog>

            {/*<div className='bottomBar'></div>*/}
            {/* ALERTAS */}
            <Snackbar open={cancelSaleAlert} autoHideDuration={3000} onClose={closeCancelSaleAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity='success' sx={{ width: '100%' }}>
                    Venta cancelada
                </Alert>
            </Snackbar>

        </div>
    )
}
