import React, { useEffect, useState } from 'react';
import { DateFormat } from '../../shared/DateFormat';
import './styles.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, getSearchListHouseSuccessAtq, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ATQ_Img_View } from '../../ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Img_View';

export const StoreHouseStockListItemsComponent = ({ activeSwitch, list, setList, setActiveSwitch }) => {

    const appointmentsListStore = useSelector(state => state.storeHouse.appointmentsList);
    const [appointmentsList, setAppointmentsList] = useState(null);
    const dispatch = useDispatch();
    const { Logistics } = useParams();
    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';
    const offset = 10;
    const dateToday = new Date();
    const searchList = useSelector(state => state.storeHouse.listSearch);
    const [openModal, setOpenModal] = useState();

    useEffect(() => {
        appointmentsListStore &&
            setAppointmentsList(appointmentsListStore);
    }, [appointmentsListStore, activeSwitch])

    const history = useHistory();

    const handleNivagateToApproveOrder = (appointment) => {
        dispatch(setAppointmentsStoreHouse(appointment))
        if (!Logistics) {
            activeSwitch === 'INACTIVE' &&
                history.replace('/StoreHouse/StoreHouseApproveOrder');
            activeSwitch === 'ACTIVE' &&
                history.replace('/StoreHouse/StoreHouseApproveOrderPreView/complete');
        }
        Logistics &&
            history.replace('/StoreHouse/StoreHouseAssignSystemsScreen');
    }


    const [currentPage, setPage] = useState(0);
    const handleAddDateForm = (date) => {
        return `${moment(date).format('YYYY')}-${moment(date).format('MM')}-${moment(date).format('DD')}T${moment(date).format('HH')}:${moment(date).format('MM')}:${('00' + new Date().getUTCSeconds()).slice(-2)}`
    }

    /* console.warn("params", Logistics) */

    useEffect(() => {
        if (currentPage > 0) {
            setPage(0)
        }
        if (list && list.length > 0) {
            setList(null)
        }
    }, [activeSwitch])

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }

    }

    useEffect(() => {
        if (list && list.length > 0 && appointmentsList.length > 0) {
            setList([...list, ...appointmentsList])
        } else if (appointmentsList && appointmentsList.length > 0) {
            setList(appointmentsList)
        }
    }, [appointmentsList]);


    useEffect(() => {
        if (currentPage === 0) {
            activeSwitch === 'INACTIVE' && !Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    handleAddDateForm(dateToday),
                    endDate, 11, '', currentPage, offset));
            activeSwitch === 'ACTIVE' && !Logistics && //YA NO OCUPAS DECISION EN ESTO YA QUE SE LAS TRAE TODAS DESDE AL PRINCIPIO
                dispatch(getAppointmentsStoreHouse(
                    startDate,
                    endDate, 10, '', currentPage, offset));
            activeSwitch === 'INACTIVE' && Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    startDate,
                    endDate, 12, '', currentPage, offset));
            activeSwitch === 'ACTIVE' && Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    handleAddDateForm(dateToday),
                    endDate, 13, '', currentPage, offset));
        }
        if (currentPage > 0 && appointmentsList !== null) {
            activeSwitch === 'INACTIVE' && !Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    handleAddDateForm(dateToday),
                    endDate, 11, '', currentPage, offset));
            activeSwitch === 'ACTIVE' && !Logistics && //YA NO OCUPAS DECISION EN ESTO YA QUE SE LAS TRAE TODAS DESDE AL PRINCIPIO
                dispatch(getAppointmentsStoreHouse(
                    startDate,
                    endDate, 10, '', currentPage, offset));
            activeSwitch === 'INACTIVE' && Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    startDate,
                    endDate, 12, '', currentPage, offset));
            activeSwitch === 'ACTIVE' && Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    handleAddDateForm(dateToday),
                    endDate, 13, '', currentPage, offset));
        }

    }, [currentPage])


    useEffect(() => {
        if (searchList && searchList.length > 0 && !Logistics) {
            searchList[0].warehouseCompleted ?
                setActiveSwitch('ACTIVE')

                :
                setActiveSwitch('INACTIVE')
        } else if (searchList && searchList.length > 0 && Logistics) {
            searchList[0].logisticCompleted ?
                setActiveSwitch('INACTIVE')

                :
                setActiveSwitch('ACTIVE')

        }

    }, [searchList])

    useEffect(() => {
        dispatch(getAppointmentsStoreHouseSuccess(null));
    }, [activeSwitch])


    const closeModal = () => {
        if (openModal === 'Generate-order') {
            setOpenModal(null);
        }
    }


    return (
        <div className="storeHouseStock_elements_container_info" onScroll={handleScroll}>
            {

                searchList && searchList.length > 0 ?
                    searchList.map(item => (
                        <div key={item.id} className='content-div-list-generate' >

                            <div key={item.id} className="storeHouseStock_elements_info_container" onClick={() => handleNivagateToApproveOrder(item)}>
                                <div className="storeHouseStock_elements_info_elements_container" >
                                    <span className="storeHouseStock_elements_info_date_container">
                                        <h3 className="storeHouseStock_elements_info_date_title">
                                            Fecha:
                                        </h3>
                                        <p className="storeHouseStock_elements_info_date">
                                            {item.surgeryDate && DateFormat(item.surgeryDate)}
                                        </p>
                                    </span>
                                    <span className="storeHouseStock_elements_info_ubicacion_container">
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {item.hospital.name && item.hospital.name}  -
                                        </p>
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {`${item.medic.name} ${item.medic.secondName} ${item.medic.firstLastname} ${item.medic.secondLastname}`}  -
                                        </p>
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {`PQX #${item.id && item.id}`}
                                        </p>
                                    </span>
                                </div>
                                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                            </div>

                              {/*----------- Maquetado Generar pedido --------*/ }
                            {/* 
                            {Logistics === undefined &&

                                <div className='generate-pedido-content' onClick={() => setOpenModal("Generate-order")}>
                                    <p className='txt-generate'>Generar Pedido</p>
                                </div>
                            } */}
                        </div>

                    ))
                    :
                    list &&
                    list.length > 0 &&
                    list.map(item => (

                        <div key={item.id} className='content-div-list-generate' >

                            <div className="storeHouseStock_elements_info_container" onClick={() => handleNivagateToApproveOrder(item)}>
                                <div className="storeHouseStock_elements_info_elements_container" >
                                    <span className="storeHouseStock_elements_info_date_container">
                                        <h3 className="storeHouseStock_elements_info_date_title">
                                            Fecha:
                                        </h3>
                                        <p className="storeHouseStock_elements_info_date">
                                            {item.surgeryDate && DateFormat(item.surgeryDate)}
                                        </p>
                                    </span>
                                    <span className="storeHouseStock_elements_info_ubicacion_container">
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {item.hospital.name && item.hospital.name}  -
                                        </p>
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {`${item.medic.name} ${item.medic.secondName} ${item.medic.firstLastname} ${item.medic.secondLastname}`}  -
                                        </p>
                                        <p className="storeHouseStock_elements_info_ubicacion_element">
                                            {`PQX #${item.id && item.id}`}
                                        </p>
                                    </span>
                                </div>
                                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                            </div>
                            {/*----------- Maquetado Generar pedido -------- */ }
                            {/* {Logistics === undefined &&
                                <div className='generate-pedido-content' onClick={() => setOpenModal("Generate-order")}>
                                    <p className='txt-generate'>Generar Pedido</p>
                                </div>
                            } */}
                        </div>
                    ))
            }


            {
                openModal === "Generate-order" &&
                <ATQ_Img_View
                    isOpen
                    toggle={closeModal}
                    type={"Generate-order"}
                    state={false}
                    textError={"¡ERROR!"}
                />
            }


        </div>
    )
}

StoreHouseStockListItemsComponent.propTypes = {
    activeSwitch: PropTypes.any,
}