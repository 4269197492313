import React, {useState} from 'react';


export const ModifyProductModal = () => {

  const [quantity, setQuantity] = useState(1);
  
  const cxListProduct = 
    {
      id:1,
      codigo:'CS 2253-12-13',
      descripcion:'Addplus -d 12 mm, altura 13-18 mm, 0º.',
    };

  
  return (
    <div className='container-product-modal'>
      <table className='table_productM'>
        <thead className='description_product'>
          <tr className='product_fila'>
              <th className='product_material-title' >Cantidad</th>
              <th className='product_material-title'>ID Producto</th>
              <th className='product_material-title'>Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr >
              <td className='product_body_modal'>
                <input className='inpt_products' min={1} max={10} type='number' value={quantity} onChange={(e) => setQuantity(e.target.value)}/>
              </td>
              <td className='product_body_modal' >{cxListProduct.codigo}</td>
              <td className='product_body_modal'>{cxListProduct.descripcion}</td>
          </tr>
        </tbody>
      </table>

      <div className='container-button'>
      <button className='products-modal-button'>ACEPTAR</button>
      </div>

    </div>
  ) 
  
};
