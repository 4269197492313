import React from 'react';
import { useSelector } from 'react-redux';
import './style_Survey.scss';

export const Survey_Component = ({ tipo, titulo, listadoComentarios,comentario }) => {
    const surveySelected = useSelector(state => state.survey.setSurveyAppoimentSelected)
    return (
        <>

            {
                tipo === 'atq' &&

                <div className="section-question">
                    <span class="title-question">{titulo}</span>
                    <span className="title-question-intern">¿Todo bien en CX?</span>
                    <span className="text-answer"> {
                        surveySelected.atqSurvey === null
                            ?'Sin comentarios de ATQ'
                            :`${surveySelected.atqSurvey.allOk === true ?'Si' :'No'}, ${surveySelected.atqSurvey.allOkCommentary ?surveySelected.atqSurvey.allOkCommentary  :''}`
                    }
                    </span>
                    <span className="title-question-intern" >Desinfección según INS-D6. Lavado de instrumental efectuado.</span>
                    <span className="text-answer"> 
                    {
                        surveySelected.atqSurvey === null
                            ?'Sin comentarios de ATQ'
                            :`${surveySelected.atqSurvey.insD6 === true ?'Si' :'No'}, ${surveySelected.atqSurvey.insD6Commentary ?surveySelected.atqSurvey.insD6Commentary  :''}`
                    }
                    </span>
                </div>

            }
            {
                tipo === 'list-items-notes' &&
                <div className="section-question">
                    <span class="title-question">{titulo}</span>
                    {
                        listadoComentarios.map(item => (
                            <li key={item.id}>
                                {item.comentario}
                            </li>
                        ))
                    }
                </div>
            }
            {
                tipo === "Mantenimiento" &&
                <div className="section-question-mantenimiento">
                    <span class="title-question-mantenimiento">{titulo}</span>
                    <span className="title-question-intern-mantenimiento">Agregar Comentarios:</span>
                    <span className="text-answer-mantenimiento">
                       {comentario}
                    </span>
                </div>

            }

        </>
    )
}
