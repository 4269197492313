import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAtqAvailability, getAtqAvailableSuccess, setATQChoosed } from '../../../actions/atqAdministradorAction';


export const Atq_disponible_List_Component = ({activeSwitch, page}) => {
    const surgerySelected = useSelector( (state) => state.atqAdministrador.surgeryChosed);
    const atqAvailable = useSelector( (state) => state.atqAdministrador.atqAvailable);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getAtqAvailability(true, surgerySelected.surgeryDate));
        return () => {
            dispatch(getAtqAvailableSuccess([]));
        }
    }, [])
    const atqsAvailable = [
        {
            id:1,
            name:'Leonardo Oliva',
            sugerido:true
        },
        {
            id:2,
            name:'Ricardo López',
            sugerido:false
        },
        {
            id:3,
            name:'Jorge Blanco',
            sugerido:false
        },
        {
            id:4,
            name:'Karla Hernández',
            sugerido:true
        },
        {
            id:5,
            name:'María Elena Díaz',
            sugerido:false
        },
        {
            id:6,
            name:'Javier Martínez',
            sugerido:false
        },
        {
            id:7,
            name:'Omar Rodríguez',
            sugerido:true
        },
        {
            id:8,
            name:'Alan Bobadilla',
            sugerido:false
        },
        {
            id:9,
            name:'David Rocha',
            sugerido:false
        },
    ];

    const history = useHistory();
    const handleNivagate = (item) => {

        dispatch(setATQChoosed(item));
        (page && page === 'cambiar') &&
        history.replace(`/ATQAdministrator_atq_availability/Atq_Info/${activeSwitch}/cambiar`);
        (page && page === 'asignar') &&
        history.replace(`/ATQAdministrator_atq_availability/Atq_Info/${activeSwitch}/asignar`);
        (page && page === 'apoyo') &&
        history.replace(`/ATQAdministrator_atq_availability/Atq_Info/${activeSwitch}/apoyo`);
        // (page && page === "asignar2") &&
        // history.replace (`/ATQAdmin_Disponibilidad/asignar2`);
}

    return (
        <div className="preconter_list">
        {
            atqAvailable &&
            atqAvailable.map( item => (
            <div key={item.userID} className="content_info" onClick={() => handleNivagate(item)}>
                <div className="text_info">
                    <span className="info_date_numberPqx">
                        <p>
                            {item.firstName && item.firstName} {item.secondName && item.secondName} {item.firstLastName && item.firstLastName} {item.secondLastName && item.secondLastName}
                        </p>
                       {/*  <p>-</p>
                        {
                            item.sugerido === true &&
                        <p className='letter-green'>
                             Sugerido
                        </p>
                        } */}
                    </span>
                </div>
                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
            </div>
            ))
        }
        </div>
    )
}
