import React from "react";
import './sass/styles.scss';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { QuotesCompletedData, QuotesPendingData } from './QuotesData';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Container } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
  Button,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions
} from "@material-ui/core";
import { useState } from "react";
import { Quotes } from "../quotes/Quotes";
import { QuotesEdit } from "../quotesEdit/QuotesEdit";
import { QuotesPackages } from "../quotesPackages/QuotesPackages";
import { QuotesProducts } from "../quotesProducts/QuotesProducts";

const columns = [
  { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'cotizacionesGuardadas', label: 'COTIZACIONES GUARDADAS', minWidth: 100 },
  { id: 'cliente', label: 'CLIENTE', minWidth: 170 },
  { id: 'tipo', label: 'TIPO', minWidth: 170, },
  { id: 'subtotal', label: 'SUBTOTAL', minWidth: 170, },
  { id: 'iva', label: 'IVA', minWidth: 170, },
  { id: 'total', label: 'TOTAL', minWidth: 170 },
  { id: 'icon', label: 'Ver', minWidth: 20 }
];

const columnsModal = [
  { id: 'id', label: 'ID', minWidth: 200 },
  { id: 'producto', label: 'PRODUCTO', minWidth: 200 },
  { id: 'cantidad', label: 'CANTIDAD', minWidth: 200 },
  { id: 'precio', label: 'PRECIO', minWidth: 200 },
  { id: 'descuento', label: 'DESCUENTO', minWidth: 200 },
  { id: 'total', label: 'TOTAL', minWidth: 200 },
];

function createData(id, cotizacionesGuardadas, cliente, tipo, subtotal, iva, total, icon) {
  return { id, cotizacionesGuardadas, cliente, tipo, subtotal, iva, total, icon };
}

function createDataModal(id, producto, cantidad, precio, descuento, total) {
  return { id, producto, cantidad, precio, descuento, total };
}

const rowsPrincipal = [
  createData('CS 8201-08', 'Productos de cirugia', 'Productos de cirugia', 'Cirugias', '$2,500', '-', '$2,500', '-'),
  createData('CS 2600-6-45', 'Productas de paciente', 'Productas de paciente', 'Complementos y protección', '$1,000', '10%', '$900', '-'),
  createData('170001', 'Batas blancas', 'Batas blancas', 'Batas blacas', '$50', '-', '-', '-'),
];

const rows2 = [
  createDataModal('CS 8201-08', 'CERV-X CONVEX, ALTURA 8MM, ANCHO 14MM, LONGITUD 12MM', '1', '1,500', '99999', '1,500'),
  createDataModal('CS 2600-6-45', 'COSMICMIA- TORNILLO D6.5 MM, LONGITUD 45MM', '50', '1,000', '10%', '900'),
  createDataModal('170001', 'CONTACT - TORNILLO DE FIJACION', '0', '50', '0', '0'),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export const QuotesInfo = () => {
  const { searchQuoteFinished } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rows, setRows] = useState(rowsPrincipal);
  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal.target.value)
    const filteredRows = rowsPrincipal.filter((row) => {
      return Object.keys(row).some((key) =>
        row[key].toLowerCase().includes((searchedVal.target.value).toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  //

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (

    <div className={"quote_finished_c"}>

      <SubNavBarComponent
        historyPage={`/cotizacionesNew`}
        title={`Cotizacion Interna`}
        searchActive={true}
        typeSearch={'none'}
        specificType={'listaPrecios'}
      />

      <div className={"quote_completed_general_c"} style={{ marginTop: 60 }}>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            style={{ backgroundColor: 'white' }}
          >
            <LinkTab label="COTIZACIÓN" href="/drafts" {...a11yProps(0)} />
            <LinkTab label="PAQUETES" href="/trash" {...a11yProps(1)} />
            <LinkTab label="PRODUCTOS" href="/spam" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <QuotesEdit />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <QuotesPackages />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <QuotesProducts />
        </TabPanel>

      </div>
    </div>
  );
};
