import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentLocationsStoreHose, getAppointmentLocationsStoreHoseSuccess, getProductsToAppointmentStoreHose, getProductsToAppointmentStoreHoseSuccess, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { handleCompleteBeginLocations, handleCompleteBeginProducts } from '../../../hooks/useFunctionsStoreHouse';
import './style.scss';
import _ from "lodash";
import { InputComponent } from './Components/InputComponent';
import { ATQ_Modal_with_Img } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_with_Img';

export const StoreHomeReceivingMaterialReportComponent = ({surtido,setSurtido}) => {

    const surgerySelected = useSelector(state => state.atqAdministrador.surgeryChosed);
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const AppointmentWithLocations = useSelector(state => state.storeHouse.appointmentItem);
    const appoimentSearch = useSelector(state => state.storeHouse.appointmentsListATQ)
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const [noSurtido, setNoSurtido] = useState(null);
    const [newProductsReq, setNewProductsReq] = useState(null);

    const toggle = () => {
        setOpenModal(false)
      };
      const closeModal = () => {
        setOpenModal(false);
    }

   
    useEffect(() => {
        if(surgerySelected && surgerySelected.id) {
            dispatch(getProductsToAppointmentStoreHose(surgerySelected.id))
            dispatch(getAppointmentLocationsStoreHose(surgerySelected.id))
        }
        return () => {
            dispatch(setAppointmentsStoreHouse(null));
            dispatch(getAppointmentLocationsStoreHoseSuccess(null))
            dispatch(getProductsToAppointmentStoreHoseSuccess(null))
        }
    }, [])
    //-------------------------------------------------------------
    useEffect(() => {
        if(AppointmentWithLocations) {
            let arraySurtidos = [];
            let arrayNoSurtidos = [];
                AppointmentWithLocations.productRequirements.map(item => {
                if((item.Ubicacion && item.Ubicacion) || (item.notStockedNote && (!item.notStockedNote || item.notStockedNote.length === 0)) || (item.locastionInfo && item.locastionInfo)){
                    arraySurtidos.push(item);
                }else{
                    arrayNoSurtidos.push(item);
                }
            })
            setSurtido([...arraySurtidos]);
            setNoSurtido([...arrayNoSurtidos]);
        }
    }, [AppointmentWithLocations])

    //---------------------------------------------------------------

    useEffect(() => {
        if(AppointmentLocations && AppointmentLocations.length === 0 && AppointmentProducts && AppointmentProducts.length === 0){
            if(appoimentSearch[0] && appoimentSearch[0].productRequirements && appoimentSearch[0].productRequirements.length > 0){
                 setNoSurtido([appoimentSearch[0].productRequirements]);
            }
        }
    }, [AppointmentLocations,AppointmentProducts])
    
     useEffect(() => {
         if(AppointmentLocations){
             handleCompleteBeginLocations(AppointmentLocations,setNewProductsReq,appoimentSearch[0])
         }
     }, [,AppointmentLocations])

     //---------------------------------------------------------------------------------------------

     useEffect(() => {
        
        if( (!_.isNil(AppointmentProducts) && AppointmentProducts.length > 0 && AppointmentProducts != null) || (newProductsReq != null)){   
            {  
                if(!AppointmentWithLocations || AppointmentWithLocations === null){
                    dispatch(setAppointmentsStoreHouse(handleCompleteBeginProducts(AppointmentProducts,newProductsReq,appoimentSearch[0])));
                }
            }
        }
    }, [newProductsReq])

    //-----------------------------------------
    

    const NormalRender = ({el,type}) =>{
        return(
            <tr key={el.id} className={`StoreHouseAssignSystemsTableComponent-table-tr ${type === 'second' && 'plush'}`} >
                <td className="StoreHouseAssignSystemsTableComponent-body-data" >
                    {el.locastionInfo.description ?el.locastionInfo.description :el.locastionInfo.productDescription}
                </td>
                <td className="StoreHouseAssignSystemsTableComponent-body-data">
                    {`${el.locastionInfo.numberOfBoxes ?el.locastionInfo.numberOfBoxes + ' REGISTRADAS' :'N/A'}`}
                </td>
                <td className="StoreHouseAssignSystemsTableComponent-body-data">
                    {el.locationLevels ?el.locationLevels  :el.productQuantity}
                </td>
                <td>
                <InputComponent type='normal' setOpenModal={setOpenModal} value={el.isReturned === true ?el.locastionInfo.ubicacion :''}  productItemSelected={el} list={surtido} setSurtido={setSurtido} />
                </td>
                <td className="StoreHouseAssignSystemsTableComponent-body-data">
                    {el.pickupPlace}
                </td>
            </tr>
        );
    }

    const SecondRender = ({el,arreglo}) => {
        let destructure = Object.keys(arreglo);      
        return(
            destructure.map(key => (
                    <tr key={arreglo[key].id} className={`StoreHouseAssignSystemsTableComponent-table-tr ${arreglo[key] === arreglo[Object.keys(arreglo).length -1] && 'plushBotton'}`} >
                        <td className="StoreHouseAssignSystemsTableComponent-body-data" >
                            {arreglo[key].description.ubicacion}
                        </td>
                        <td className="StoreHouseAssignSystemsTableComponent-body-data">
                        -
                        </td>
                        <td className="StoreHouseAssignSystemsTableComponent-body-data">
                        -
                        </td>
                        <td>
                       <InputComponent type='secondary' setOpenModal={setOpenModal} principal={el} value={arreglo[key].isReturned === true ?arreglo[key].description.ubicacion :''}  productItemSelected={arreglo[key]} setSurtido={setSurtido} list={surtido}/>
                       </td>
                        <td className="StoreHouseAssignSystemsTableComponent-body-data">
                            {el.pickupPlace}
                        </td>
                    </tr>
                                
                )) 
        );
}

    return (
        <div className="StoreHouseAssignSystemsTableComponent-container-table">
            <div className="StoreHouseAssignSystemsTableComponent-precontainer-table">
                <table className="StoreHouseAssignSystemsTableComponent-table">
                    <thead className="StoreHouseAssignSystemsTableComponent-table-head">
                        <tr className="StoreHouseAssignSystemsTableComponent-table-tr">
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">Ubicación</th>
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">No. Cajas Registradas</th>
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">Cantidad</th>
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">Codigos </th>                           
                            <th className="StoreHouseAssignSystemsTableComponent-table-title">Origen</th>
                        </tr>
                    </thead>
                    <tbody className="StoreHouseAssignSystemsTableComponent-table-body">
                    {   surtido &&
                        surtido.length > 0 &&
                        surtido.map( el => (
                            
                            
                        el.locationBoxIDs && Object.keys(el.locationBoxIDs).length > 1
                                
                        ?<>
                            <NormalRender el={el} type={'second'}/> 
                            <SecondRender el={el} arreglo={el.locationBoxes}/> 
                        </>
                          :  <NormalRender el={el}type={'normal'}/>
                            
                        ))
                    }
                    </tbody>
                </table>
            </div>
            {
            openModal == true &&
            <ATQ_Modal_with_Img
                    isOpen
                    toggle={closeModal}
                    type={'onlyText'}
                    text1={'EL CODIGO NO COINCIDE CON EL SISTEMA SELECCIONADO'}/>
            }
        </div>
    )
}
