import React, { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { ATQ_modal_Producst } from './ATQ_modal_Producst';


export const ATQ_description_component = ({item}) => {
    const [estado,setEstado] = useState(true);
    const [openModal, setOpenModal] = useState(null);
    const handleClick = () => {
        setEstado(!estado);
    }

    const toggleModal = () => {
        setOpenModal(null);
    };
    
    return (
    <> 
            {
            estado === true
                ? (<button className="ATQ-button StoreHomeAbailableSystemsItems-c-item-button-Childs ">
                        <span className="ATQ-Ico" onClick={() => handleClick()}>
                            {" "}
                            <IoIosArrowUp/>{" "}
                        </span>
                        <div className="StoreHomeAbailableSystemsItems-item-button-name-container">
                            <span className="StoreHomeAbailableSystemsItems-item-button-name" onClick={() => setOpenModal('MODAL')}> {item.productDescription} </span>
                        </div>
                    </button>)
                :(<div className='second-Component'>
                        <span className="StoreHomeAbailableSystemsItems-item-button-name"> {item.productDescription} </span>
                            <span className="ATQ-Ico" onClick={() => handleClick()}>
                                {" "}
                                <IoIosArrowDown />{" "} 
                            </span>
                </div>)
            }

            {
                    openModal === "MODAL" &&
                    <ATQ_modal_Producst
                                isOpen
                                toggle={toggleModal}
                                name = {item.productDescription}
                            />
                    }
        </>
    )
}
