import React, { useState } from 'react';
import { AccionesComponent } from '../../../../components/EncuestaComponent/AccionesComponent/AccionesComponent';
import { CalificacionComponent } from '../../../../components/EncuestaComponent/Calificacion/CalificacionComponent';
import CausaSurveyComponent from '../../../../components/EncuestaComponent/CausaComponent/CausaSurveyComponent';
import ComentsSurvey from '../../../../components/EncuestaComponent/ComentsSurvey';
import { EstatusComponent } from '../../../../components/EncuestaComponent/EstatusComponent/EstatusComponent';
import { FechaCierreComponent } from '../../../../components/EncuestaComponent/FechaCierreComponent/FechaCierreComponent';
import { PrimeraClasificacion } from '../../../../components/EncuestaComponent/PrimeraClasificacion/PrimeraClasificacion';
import { SeguimientoMedicoComponent } from '../../../../components/EncuestaComponent/SeguimientoMedicoComponent/SeguimientoMedicoComponent';
import { SNCComponent } from '../../../../components/EncuestaComponent/SncComponent/SNCComponent';
import { SubNavBarComponent } from '../../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { QualityInfoEncuestasHeaderComponent } from '../../../../components/QualityManager/QualityInfoEncuestasHeaderComponent';
import { Survey_Medico_Component } from '../../../../components/StoreHouseComponents/StoreHouseReiceivingEncuestasComponents/Survey_Medico_Component';
import { useParams } from 'react-router-dom';
import './styleDetailsQuality.scss';
import { ComentQuality } from '../../../../components/EncuestaComponent/ComentarioGerenteCalidad/ComentQuality';



export const Quality_Details_Page = () => {

    const { type } = useParams();

    const [visita, setVisita] = useState('No');


    return (
        <div >
            <SubNavBarComponent className="navbar" title={'ENCUESTAS'} searchActive={false} historyPage={'/Quality_Analisis_Page'} />



            <div className='container-header-encuesta-analisis'>

                <QualityInfoEncuestasHeaderComponent
                    type={'analisis'}

                />

                <div className='section-requiere-analisis'>

                    <p className='text-requiere-visita-analisis'>Requiere Visita:</p>
                    <p className='text-requiere-visita-analisis-respuesta'>{visita}</p>

                </div>



                <div className='body-page-infoEncuestas-analisis'>
                    {/* Agregar a izquierda dentro de este div */}

                    <div className='container-component-analisis container-left-analisis'>
                        <Survey_Medico_Component
                            titulo={'COMENTARIOS MÉDICO'}
                            tipo={'medico-gerente'} />

                        <ComentsSurvey
                            title={'COMENTARIOS JEFE ATQ'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-mantenimiento'}
                            comments={'Comentarios del jefe de ATQ…'}
                        />

                        <ComentsSurvey
                            title={'COMENTARIOS GERENTE DE OPERACIONES'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-mantenimiento'}
                            comments={'Comentarios del gerente de operaciones…'}
                        />

                    </div>

                    {/* agregar a derecha dentro de este div */}
                    <div className='container-component-analisis container-right-analisis'>
                        <ComentsSurvey
                            title={'COMENTARIOS ATQ'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-atq'}
                        />

                        <ComentsSurvey
                            title={'COMENTARIOS ALMACEN'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-almacen'}
                        />

                        <ComentsSurvey
                            title={'COMENTARIOS VENTAS (COMERCIAL)'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-ventas'}
                        />

                        <ComentsSurvey
                            title={'COMENTARIOS MANTENIMIENTO'}
                            subtitle={'Seguimiento para uso interno TSI'}
                            type={'comentarios-mantenimiento'}
                            comments={'Comentarios de Mantenimiento…'}
                        />
                    </div>


                </div>


                {/* Aqui agregar en todo el nuevo div con las dos secciones - izquierda y derecha*/}

                <div className='body-page-infoEncuestas-analisis'>
                    {/* Agregar a izquierda dentro de este div */}

                    <div className='container-component-analisis'>

                        <div className='clasificacion-body-analisis'>
                            <PrimeraClasificacion
                                type={'analisis'} />

                            <CalificacionComponent
                                type={'analisis'} />
                        </div>

                        <SNCComponent
                            type={'analisis'}
                        />

                        <SeguimientoMedicoComponent />

                        <ComentQuality />

                    </div>

                    {/* agregar a derecha dentro de este div */}
                    <div className='container-component-analisis container-right-analisis'>
                        <CausaSurveyComponent
                            type={'analisis'} />

                        <AccionesComponent
                            type={'analisis'}
                        />

                        <FechaCierreComponent />

                        <EstatusComponent />

                        <div className='cont-btn-footer-analisis'>
                            <button className='btnView-cancelar-analisis'>CERRAR</button>
                            <button className='btnView-guardar-analisis'>SIGUIENTE</button>
                        </div>



                    </div>

                </div>

            </div>

        </div>


    )
}
