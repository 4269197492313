import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';
import { add_chat_group_message_success, add_member_from_chat_group_success, create_chat_group_success, get_chat_group_success } from '../../../actions/notification-chatAction';
import { getAppointmentLocationsStoreHose, getProductsToAppointmentStoreHose, setDestinationPlaceStoreHose, setPickupPlaceStoreHose, updateAppointmentLocationStoreHoseSuccess, updateAppointmentLogisticsStoreHose, updateAppointmentProductStoreHouseSuccess } from '../../../actions/storeHouseAction';
import { ATQ_Modal_Share } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_Share';
import { ATQ_Modal_with_Img } from '../../../components/ATQ/ATQ_CONSUM_REPORT/ATQ_Modals/ATQ_Modal_with_Img';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { handleGetHour } from '../../../components/shared/DateFormat';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { StoreHouseAssignSystemsTableComponent } from '../../../components/StoreHouseComponents/StoreHouseAssignSystemsComponents/StoreHouseAssignSystemsTableComponent';
import './sass/styles.scss';
import { StoreHouseAssignSystemAddNoteModal } from './StoreHouseAssignSystemAddNoteModal';
import { StoreHouseAssignSystemsNotes } from './StoreHouseAssignSystemsNotes';


export const StoreHouseAssignSystemsScreen = () => {
    
    //Eliminar y cambiar por notas de Ligistic
    const listNotesLogistics = useSelector(state => state.storeHouse.listNotesLogistics);
    
    const appointmentItem = useSelector(state => state.storeHouse.appointmentItem);
    const pickupPlaceItemStoreHose = useSelector(state => state.storeHouse.pickupPlaceItemStoreHose);
    const destinationPlaceStoreHose = useSelector(state => state.storeHouse.destinationPlaceStoreHose);
    const updateAppointmentProduct = useSelector(state => state.storeHouse.updateAppointmentProduct);
    const updateAppointmentLocation = useSelector(state => state.storeHouse.updateAppointmentLocation);
    const dispatch = useDispatch();
    const history = useHistory();
    const [cxSelected,setCxSelected] = useState(null)
    const [description, setDescription] = useState(null);

    const [arrayItemFromAppointment, setArrayItemFromAppointment] = useState([]);
    const AppointmentProducts = useSelector(state => state.storeHouse.AppointmentProducts);
    const AppointmentLocations = useSelector(state => state.storeHouse.AppointmentLocations);
    const [result, setResult] = useState(false);

    useEffect(() => {

        if (appointmentItem && appointmentItem.warehouseCompleted) {
            dispatch(getProductsToAppointmentStoreHose(appointmentItem.id));
            dispatch(getAppointmentLocationsStoreHose(appointmentItem.id));
        }
        if (updateAppointmentProduct || updateAppointmentLocation) {
            dispatch(updateAppointmentProductStoreHouseSuccess(null));
            dispatch(updateAppointmentLocationStoreHoseSuccess(null));
        }

        dispatch(getProductsToAppointmentStoreHose(appointmentItem.id));

    }, [appointmentItem, updateAppointmentProduct, updateAppointmentLocation, result])


    // useEffect(() => {
    //     dispatch(getProductsToAppointmentStoreHose(appointmentItem.id));
    // }, [result])



    useEffect(() => {
        let newArrayItemFromAppointment = [];
        if (newArrayItemFromAppointment.length > 0 && AppointmentProducts && AppointmentProducts.length > 0) {
            newArrayItemFromAppointment = [...AppointmentProducts];
        } else if (newArrayItemFromAppointment.length === 0 && AppointmentProducts && AppointmentProducts.length > 0) {
            newArrayItemFromAppointment = [...AppointmentProducts];
        }
        if (newArrayItemFromAppointment.length > 0 && AppointmentLocations && AppointmentLocations.length > 0) {
            newArrayItemFromAppointment = [...newArrayItemFromAppointment, ...AppointmentLocations];
        } else if (newArrayItemFromAppointment.length === 0 && AppointmentLocations && AppointmentLocations.length > 0) {
            newArrayItemFromAppointment = [...AppointmentLocations];
        }
        setArrayItemFromAppointment(newArrayItemFromAppointment);
    }, [AppointmentProducts, AppointmentLocations]);



  
    useEffect(() => {
        dispatch(setPickupPlaceStoreHose('Almacén'));
        dispatch(get_chat_group_success(null));
        dispatch(add_member_from_chat_group_success(null));
        dispatch(create_chat_group_success(null));
        dispatch(add_chat_group_message_success(null));
        if (appointmentItem && appointmentItem.hospital && appointmentItem.hospital.name) {
            dispatch(setDestinationPlaceStoreHose(appointmentItem.hospital.name));
        }
    }, []);


    const [generalPickupPlace, setGeneralPickupPlace] = useState(null);
    const [generalDestinationPlace, setGeneralDestinationPlace] = useState(null);

    useEffect(() => {
        if (pickupPlaceItemStoreHose) {
            setGeneralPickupPlace(pickupPlaceItemStoreHose);
        }

    }, [pickupPlaceItemStoreHose])

    useEffect(() => {
        if (destinationPlaceStoreHose) {
            setGeneralDestinationPlace(destinationPlaceStoreHose);
        }
    }, [destinationPlaceStoreHose])

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Fecha de CX',
            type: 'DATE',
            value: appointmentItem && appointmentItem.surgeryDate
        },
        {
            uid: shortid.generate(),
            title: 'Hora de CX',
            value: appointmentItem && handleGetHour(appointmentItem.surgeryDate)
        },
        {
            uid: shortid.generate(),
            title: 'Origen',
            type: 'STATE',
            typeModify: 'PickupPlace',
            value: generalPickupPlace && generalPickupPlace,
        },
        {
            uid: shortid.generate(),
            title: 'Destino',
            type: 'STATE',
            typeModify: 'DestinationPlace',
            value: generalDestinationPlace && generalDestinationPlace,
        },
    ];





    const [openModal, setOpenModal] = useState(0);


    const closeModal = (item) => {
        setOpenModal(0);
    }

    const toggle = () => {
        setOpenModal((value) => value + 1)
    };


    const handleNavigate = () => {

        const newArrayPickupPlaceWithOutPlaces = arrayItemFromAppointment && arrayItemFromAppointment.filter(el => !el.pickupPlace);
        if (newArrayPickupPlaceWithOutPlaces && newArrayPickupPlaceWithOutPlaces.length > 0) {
            const newArrayPickupPlace = newArrayPickupPlaceWithOutPlaces.map(el => {
                let pickupPlaceObject = {}
                if (el.product) {
                    pickupPlaceObject['Id'] = el.id;
                    pickupPlaceObject['ProductQuantity'] = el.productQuantity;
                    pickupPlaceObject['PickupPlace'] = pickupPlaceItemStoreHose;
                    pickupPlaceObject['DestinationPlace'] = destinationPlaceStoreHose;
                    // dispatch(updateAppointmentProductStoreHose(pickupPlaceObject));
                    return pickupPlaceObject
                } else if (el.location) {
                    pickupPlaceObject['Id'] = el.id;
                    pickupPlaceObject['LocationLevels'] = el.locationLevels;
                    pickupPlaceObject['PickupPlace'] = pickupPlaceItemStoreHose;
                    pickupPlaceObject['DestinationPlace'] = destinationPlaceStoreHose;
                    // dispatch(updateAppointmentLocationStoreHose(pickupPlaceObject));
                    return pickupPlaceObject
                }
                return el;
            });
            const divitionItemsToEndpoint = {
                LogisticProducts: newArrayPickupPlace.filter(el => el.ProductQuantity),
                LogisticLocations: newArrayPickupPlace.filter(el => el.LocationLevels),
            }
            dispatch(updateAppointmentLogisticsStoreHose(divitionItemsToEndpoint));
        }
        history.replace(`/Logistic_complete`);
    }

    return (
        <div className="StoreHouseAssignSystemsScreen-container">
            <SubNavBarComponent title={'LOGÍSTICA'} historyPage={'/AppointmentLogistics/Logistics'} />

            <section className="StoreHouseAssignSystemsScreen-precontainer">
                <div className="StoreHouseAssignSystemsScreen-container-section">
                    <div className="StoreHouseAssignSystemsScreen-container-left-section">

                        <StoreHouseListComponent
                            typeView={{ type: 'STORE', title: `PQX #${appointmentItem && appointmentItem.id}` }}
                            listFields={arrayListFields}
                        />

                        <StoreHouseAssignSystemsNotes
                            asignationNotes={listNotesLogistics && listNotesLogistics}
                            title={'Asignaciones de materiales'}
                        />

                    </div>

                    <div className="StoreHouseAssignSystemsScreen-container-rigth-section">
                        <StoreHouseAssignSystemsTableComponent arrayItemFromAppointment={arrayItemFromAppointment} result={result} setResult={setResult} />
                    </div>
                </div>
                <div className="StoreHouseAssignSystemsScreen-container-bottom-button">
                    <button className="StoreHouseAssignSystemsScreen-bottom-button" onClick={() => setOpenModal(1)} >
                        NOTA DE ASIGNACIÓN
                    </button>
                    <button className="StoreHouseAssignSystemsScreen-bottom-button" onClick={handleNavigate}>
                        ACEPTAR
                    </button>
                </div>
            </section>



            {
                openModal == 1 &&

                <StoreHouseAssignSystemAddNoteModal
                    isOpen
                    toggle={toggle}
                    handleClose={closeModal}
                    setDescription={setDescription}
                />
            }

            {
                openModal == 2 &&

                <ATQ_Modal_Share
                    isOpen
                    toggle={toggle}
                    setCxSelected={setCxSelected}
                    handleClose={closeModal}
                    description={description}
                    type={"chat"}
                />
            }


            {
                openModal == 3 &&
                <ATQ_Modal_with_Img
                    isOpen
                    toggle={closeModal}
                    type={'normal'}
                    text1={'Se ha enviado reporte de consumo al chat live.'} />
            }

        </div>
    )
}
