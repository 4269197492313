import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsStoreHouseSuccess, getProductsStoreHouseSuccess, searchLocationsStoreHouseSuccess } from '../../../actions/storeHouseAction';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent'
import StoreHomeAvailableMaterialToAddItemsList from '../../../components/StoreHouseComponents/StoreHomeAvailableMaterialToAddComponent/StoreHomeAvailableMaterialToAddItemsList';
import { StoreHomeAvailableMaterialToAddSearch } from '../../../components/StoreHouseComponents/StoreHomeAvailableMaterialToAddComponent/StoreHomeAvailableMaterialToAddSearch';
import { StoreHomeAvailableMaterialToAddToggleButtons } from '../../../components/StoreHouseComponents/StoreHomeAvailableMaterialToAddComponent/StoreHomeAvailableMaterialToAddToggleButtons'
import './sass/styles.scss';

export const StoreHomeAvailableMaterialToAddScreen = () => {
    const [typeList, setTypeList] = useState('LOCATIONS');
    const listSearchLocation = useSelector(state => state.storeHouse.searchLocation);
    const dispatch = useDispatch();
    useEffect(() => {

        return () => {
            dispatch(getProductsStoreHouseSuccess(null));
            dispatch(getLocationsStoreHouseSuccess(null));
            dispatch(searchLocationsStoreHouseSuccess(null))
        }
    }, [])

    return (
        <div className="StoreHomeAvailableMaterialToAddScreen-component">
            <SubNavBarComponent title={'DISPONIBILIDAD DE SISTEMAS'} historyPage={'/StoreHouse/StoreHouseApproveOrder'}/>
            
            <div className="StoreHomeAvailableMaterialToAddScreen-precomponent">

                <div className="StoreHomeAvailableMaterialToAddScreen-component-head-items">
                    <StoreHomeAvailableMaterialToAddSearch typeList={typeList}/>
                    <StoreHomeAvailableMaterialToAddToggleButtons typeList={typeList} setTypeList={setTypeList}/>
                </div>

                <div className="StoreHomeAvailableMaterialToAddScreen-list-items">
                    <StoreHomeAvailableMaterialToAddItemsList typeList={typeList} search={listSearchLocation}/>
                </div>

            </div>

        </div>
    )
}
