import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './Structure.scss';
import UserLeftBar from './Bar/UserLeftBar/UserLeftBar';
import UserRightBar from './Bar/UserRightBar/UserRightBar';
import { rolTypeNav } from '../../../pages/Users/Structure/Vars';


export const DoctorsHome = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const data = useSelector(state => state.allUsers);

    return (
        <div className="DoctorsHome-principal-container">
            <SubNavBarComponent title={`Usuarios ${rolTypeNav[data.userRolSelected]}`} historyPage={"/usuarios"} />
            <div className={"DoctorsHome-main-page-container"}>
                <div className={"DoctorsHome-left-bar"}>
                    <UserLeftBar selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser} />
                </div>
                
                <div className={"DoctorsHome-right-bar"}>
                    <UserRightBar selectedSeller={selectedUser}
                        setSelectedSeller={setSelectedUser} />
                </div>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default withRouter(connect(mapStateToProps)(DoctorsHome));
