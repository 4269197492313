import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import Button from '../../../../../../components/shared/Atoms/Button';
import TextShow from '../../../../../../components/shared/Atoms/TextShow.js';
import CustomModal from '../../../../../../components/shared/Modal';
import { formatDateToPresent } from '../../../../../../hooks/useFormatDateInitialAndFinalDay';
import './UserTabProfile.scss';


const UserTabProfile = ({ selectedSeller, setSelectedSeller }) => {
    const [userRol, setUserRol] = useState(null);
    const [openAtqList, setOpenAtqList] = useState(false);
    const data = useSelector(state => state.allUsers);
    const rol = useSelector(state => state.allUsers.userRolSelected);
    const perfilATQ = useSelector(state => state.allUsers.appointmentsList);
    const selectUser = useSelector(state => state.allUsers.selectedUserId)



    useEffect(() => {
        if (data.selectedUserInfo && data.selectedUserInfo.length > 0) {
            setUserInfo(data.selectedUserInfo[0])
        }
        if (data.userRolSelected) {
            setUserRol(data.userRolSelected)
        }
    }, [data,selectUser])

    const profileHeader = {
        item1: 'Nombre del medico',
        item2: 'Ciudad',
        item3: 'Estado',
        item4: 'Nombre del hospital',
        item5: 'Dirección del hospital',
        item6: 'Teléfono del hospital',
        item7: 'Correo',
        item8: 'Celular',
        item9: 'Especialidad',
        item10: 'Ultima CX realizada',
        item11: 'Fecha de ultima CX',
        item12: 'Medico',
        item13: 'Hospital',
        item14: 'Contacto del hospital',
        item15: 'Sucursal',
        item16: 'Dirección',
        item17: 'Contacto',
        item18: 'Almacen',
    }

    const showListOnClick = () => {
        setOpenAtqList(!openAtqList);
    };

    const modalBody = () => {
        return (
            <div className={"modal-atq-list"}>
                <div className={"table-list-header"}>
                    <div className={"header-element"}>
                        <p>Item</p>
                    </div>
                    <div className={"header-element"}>
                        <p>ATQ</p>
                    </div>
                </div>
                {
                    atqlist.map((item) => {
                        return (
                            <div className={"table-list-body"}>
                                <div className={'body-element'}>
                                    <p>{item.id}</p>
                                </div>
                                <div className={'body-element'}>
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    };



    return (
        <div className={'doctor-tab-profile-container'}>
            {
                selectedSeller &&
                <div>
                    <div className={'doctor-tab-profile-row'}>

                        {
                            selectedSeller && selectedSeller.email && rol === 'atq' &&
                            <TextShow title={profileHeader.item10} description={('LUMBAR')} />
                        }
                        {
                            selectedSeller && selectedSeller.email && rol === 'atq' &&
                            <TextShow title={profileHeader.item11} description={(formatDateToPresent(perfilATQ && perfilATQ[0].surgeryDate))} />
                        }

                        {
                            selectedSeller && selectedSeller.email && rol === 'atq' &&
                            <TextShow title={profileHeader.item12} description={( perfilATQ && perfilATQ[0].medic.name + ' ' + perfilATQ[0].medic.secondName + ' ' + perfilATQ[0].medic.firstLastname + ' ' + perfilATQ[0].medic.secondLastname)} />
                        }

                        {
                            selectedSeller && selectedSeller.name && rol === 'doctor' &&
                            <TextShow title={profileHeader.item1} description={(selectedSeller.name).toLowerCase() + " " + (selectedSeller.firstLastname).toLowerCase()} />
                        }
                        {
                            selectedSeller && selectedSeller.city && rol === 'doctor' &&
                            <TextShow title={profileHeader.item2} description={(selectedSeller ? selectedSeller.city : ' - ').toLowerCase()} />
                        }
                        {
                            selectedSeller && selectedSeller.state && rol === 'doctor' &&
                            <TextShow title={profileHeader.item3} description={(selectedSeller.state).toLowerCase()} />
                        }

                        {rol === 'adminAtq' &&
                            <TextShow title={profileHeader.item13} description={"- - -"} />

                        }

                        {rol === 'seller' &&
                            <TextShow title={profileHeader.item13} description={"- - -"} />

                        }
                        {rol === 'seller' &&
                            <TextShow title={profileHeader.item12} description={"- - -"} />

                        }
                        {rol === 'seller' &&
                            <TextShow title={profileHeader.item2} description={"- - -"} />

                        }


                        {rol === 'adminAtq' &&
                            <TextShow title={profileHeader.item5} description={"- - -"} />

                        }
                        {rol === 'adminAtq' &&
                            <TextShow title={profileHeader.item14} description={"- - -"} />

                        }

                        {rol === 'storer' &&
                            <TextShow title={profileHeader.item15} description={"- - -"} />

                        }
                        {rol === 'storer' &&
                            <TextShow title={profileHeader.item16} description={"- - -"} />

                        }
                        {rol === 'storer' &&
                            <TextShow title={profileHeader.item17} description={"- - -"} />

                        }



                    </div>
                    <div className={'doctor-tab-profile-row'}>

                        {rol === 'seller' &&
                            <TextShow title={profileHeader.item3} description={"- - -"} />

                        }
                        {
                            rol === 'doctor' &&
                            <TextShow title={profileHeader.item4} description={" - "} />
                        }
                        {rol === 'doctor' &&
                            <TextShow title={profileHeader.item5} description={" - "} />

                        }

                        {rol === 'doctor' &&
                            <TextShow title={profileHeader.item6} description={" - "} />

                        }

                        {rol === 'atq' &&
                            <TextShow title={profileHeader.item13} description={(perfilATQ && perfilATQ[0].hospital.name)} />

                        }
                        {rol === 'atq' &&
                            <TextShow title={profileHeader.item2} description={(perfilATQ && perfilATQ[0].hospital.city)} />

                        }
                        {rol === 'atq' &&
                            <TextShow title={profileHeader.item3} description={(perfilATQ && perfilATQ[0].hospital.state)} />

                        }

                        {rol === 'adminAtq' &&
                            <TextShow title={profileHeader.item2} description={"- - -"} />

                        }
                        {rol === 'adminAtq' &&
                            <TextShow title={profileHeader.item3} description={"- - -"} />

                        }

                        {
                            rol && rol == "adminAtq" &&
                            <div className={'doctor-tab-profile-button'}>
                                <Button color={"blue_dark"} title={"LISTA DE ATQS"} onClick={() => showListOnClick()} />
                            </div>
                        }

                        {rol === 'storer' &&
                            <TextShow title={profileHeader.item18} description={"- - -"} />

                        }
                        {rol === 'storer' &&
                            <TextShow title={profileHeader.item2} description={"- - -"} />

                        }
                        {rol === 'storer' &&
                            <TextShow title={profileHeader.item3} description={"- - -"} />

                        }


                    </div>
                    <div className={'doctor-tab-profile-row'}>
                        {
                            selectedSeller && selectedSeller.email && rol === 'doctor' &&
                            <TextShow title={profileHeader.item7} description={(selectedSeller.email)} />
                        }
                        {
                            selectedSeller.phone && rol === 'doctor' &&
                            <TextShow title={profileHeader.item8} description={(selectedSeller.phone)} />
                        }
                        {rol === 'doctor' &&
                            <TextShow title={profileHeader.item9} description={" - "} />
                        }
                        {/* {
                            rol && rol == "adminAtq" && 
                            <div className={'doctor-tab-profile-button'}>
                                <Button color={"blue_dark"} title={"LISTA DE ATQS"} onClick={() => showListOnClick()} />
                            </div>
                        } */}
                        {
                            openAtqList &&
                            <CustomModal // Esto se puede ser otro componente
                                title={'LISTA DE TECNICOS A CARGO'}
                                isOpen={openAtqList}
                                toggle={showListOnClick}
                                bodyWidth={'small'}
                                body={modalBody()}  // NOTA: Ya hay un componente que muestra esta info!!  Hay que buscarla antes de escribir todo!
                            />
                        }


                    </div>
                </div>
            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default withRouter(connect(mapStateToProps)(UserTabProfile));
