import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import icoBuscarGris from '../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import PropTypes from 'prop-types';
import { searchLocationsStoreHouse, searchProductsStoreHose } from '../../../actions/storeHouseAction';
import { StoreHomeAvailableMaterialToAddSearchComboBox } from './StoreHomeAvailableMaterialToAddSearchComboBox';
import { ModalProductsComponents } from '../../shared/Modal/ModalProductsComponents';
import scannIcon from '../../../assets/img/126PX/Icono_EscanearCodigoQR_126px.png';

export const StoreHomeAvailableMaterialToAddSearch = ({typeList}) => {

    const dispatch = useDispatch();
    const [typeSearch, setTypeSearch] = useState(0);
    const [searchItems, setSearchItems] = useState('');
    const handleSearch = (e) => {
        e.preventDefault();
        typeList === 'LOCATIONS' &&
            (dispatch(searchLocationsStoreHouse(searchItems, typeSearch ) ), setSearchItems(''));
        typeList === 'PRODUCTS' &&
            (dispatch(searchProductsStoreHose(searchItems, typeSearch ) ), setSearchItems(''));
        
    };
    const openModal = useRef();
    const openModalProducts = useRef();
    
    const handleOpenModal = () => {
        typeList === 'LOCATIONS' &&
            openModal.current.handleOpen();
        typeList === 'PRODUCTS' &&
            openModalProducts.current.handleOpen();
    }

    return (
        <div className="c-StoreHomeAvailableMaterialToAddSearch-c-input-search">
            <form onSubmit={handleSearch} className="StoreHomeAvailableMaterialToAddSearch-c-input-search">
                <button className="c-StoreHomeAvailableMaterialToAddSearch-search-icobuscar">
                    <img src={icoBuscarGris} alt="" />
                </button>
                <input
                    type="text"
                    placeholder={typeList === 'LOCATIONS' ? "Buscar ubicaciones…" : 'Buscar producto individual…'}
                    name="search"
                    onChange={({ target }) => setSearchItems(target.value)}
                    value={searchItems}
                    className="StoreHomeAvailableMaterialToAddSearch-input-search"
                    autoComplete="off"
                />
                {
                    typeList === 'LOCATIONS' &&
                    <StoreHomeAvailableMaterialToAddSearchComboBox typeList={typeList} typeSearch={typeSearch} setTypeSearch={setTypeSearch} />
                }
                {
                    typeList === 'PRODUCTS' &&
                    <StoreHomeAvailableMaterialToAddSearchComboBox typeList={typeList} typeSearch={typeSearch} setTypeSearch={setTypeSearch} />
                }
                
            </form>
            <button className="StoreHomeAvailableMaterialToAddSearch-button-lector" onClick={handleOpenModal}>
                <img src={scannIcon} alt="" className="StoreHomeAvailableMaterialToAddSearch-button-lector-img" />
            </button>

                <ModalProductsComponents ref={openModal && openModal } typeModal={'ProductLocationScanningComponent'}/>
                <ModalProductsComponents ref={openModalProducts && openModalProducts} typeModal={'IndividualProductsScanningComponent'} isScann={'SCANN'}/>

        </div>
    )
}
StoreHomeAvailableMaterialToAddSearch.propTypes = {
    typeList: PropTypes.any,
}
