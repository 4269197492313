import React, { useEffect,useRef, useState } from 'react';
import groupATQ from "../../../assets/img/512PX/GroupATQ.png";
import addImg from "../../../assets/img/126PX/Icono_Mas_126px.png";
import { ModalCreateTeamATQComponent } from './ModalCreateTeamATQComponent';
import { ModalOrderTeamATQComponent } from './ModalOrderTeamATQComponent';
import { useHistory } from 'react-router';
import { ATQViewTeamComponent } from './ATQViewTeamComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupGuard, getHolidaysSuccess, getTeamGroupSuccess} from '../../../actions/atqAdministradorAction';


export const ATQ_GuardRole_CreateTeam_Component = () => {

    const [openModal, setOpenModal] = useState(false);
    const [page, setPage] = useState('');
    const [idGroup, setIdGroup] = useState(0);
    const history = useHistory();
    const listTeamGroup = useSelector( (state) => state.atqAdministrador.group);
    const teamGuard = useSelector( (state) => state.atqAdministrador.teamGuard);
    const dispatch = useDispatch();
    const ban = useRef(0);
    const ban1 = useRef(0);
    const [atqList, setATQList] = useState([]);
    const toggleModal = (modal) => {
        ban.current = 0;
        setOpenModal(modal);
        !openModal
        dispatch(getGroupGuard());
    };


    const changeBan = () => {
         ban.current = 1
    };
    const changeBan1 = () => {
        ban1.current = 1
   };
    const handlecChangePage = () => {
          setOpenModal("CREATE-TEAM");
          setPage('nada')
    };

    useEffect(() => {
        
        dispatch(getGroupGuard());
    }, [teamGuard])

    useEffect(() => {
        dispatch(getGroupGuard());
    }, [])

    const handleChangeView = (typeSelection) => {
        typeSelection === "GUARD_DAYS" &&
        history.replace("/ATQ/ATQ_GuardDays_View");
/*         typeSelection === "CREATE_GUARD_DAYS" &&
        history.replace("ATQ/ATQ_GuardDays_View"); */
        
    };
    const handleNavigate = () => {

        dispatch(getHolidaysSuccess(null));
        dispatch(getTeamGroupSuccess(null)); 
    }


    return (
        <div className="ATQ_GuardRole_CreateTeam_View-items-menu-container">
            <div className="ATQ_GuardRole_CreateTeam_View_container_item" onClick={() => handlecChangePage()}>
                <img src={groupATQ} alt="" className={"ATQ_GuardRole_CreateTeam_View_item_img"} />
                <h3 className={'ATQ_GuardRole_CreateTeam_View_item_title'}>Crear Equipo</h3>
                <img src={addImg} alt="" className={"ATQ_GuardRole_CreateTeam_View_item_img ATQ_GuardRole_CreateTeam_View_item_img-add"} />
            </div>
            <div className="ATQ_GuardRole_ViewTeam">
            {
                listTeamGroup &&
                listTeamGroup.map((item,index) =>(
                    
                    <ATQViewTeamComponent
                        idGroup = {item.id}
                        nameGroup={item.groupName}
                        members = {item.members}
                        setOpenModal = {setOpenModal}
                        setPage = {setPage}
                        setIdGroup = {setIdGroup}
                    />

                )) 
            }       
            </div>
            <div className="ATQ_GuardRole_CreateTeam_View-items-container-buttons">
                <button className="ATQ_GuardRole_CreateTeam_View-items-button" onClick={() => handleChangeView("GUARD_DAYS")}>DÍAS DE GUARDÍA</button>
                <button className="ATQ_GuardRole_CreateTeam_View-items-button" onClick={() => handleNavigate()}>ROL DE GUARDÍAS</button>
            </div>


            {
                openModal === "CREATE-TEAM" && 
                <ModalCreateTeamATQComponent
                    isOpen={!!openModal}
                    toggle={toggleModal}
                    page = {page}
                    idGroup = {idGroup}
                    changeBan1 = {changeBan1}
                    ban1={ban1}
                    atqList = {atqList}
                    setATQList = {setATQList}
                />
            }
            {
                openModal === "ORDER-TEAM" && 
                <ModalOrderTeamATQComponent
                    isOpen={!!openModal}
                    toggle={toggleModal}
                    page = {page}
                    idGroup = {idGroup}
                    changeBan = {changeBan}
                    ban={ban}
                    atqList = {atqList}
                    setATQList = {setATQList}
                />
               }
        </div>
    )
}
