import React,{useState} from 'react';
import { useParams } from 'react-router-dom';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHomeReceivingMaterialReportIncomingLeftInfoComponent } from '../../../components/StoreHouseComponents/StoreHomeReceivingMaterialReportIncomingComponents/StoreHomeReceivingMaterialReportIncomingLeftInfoComponent';
import { StoreHomeReceivingMaterialReportIncomingRigthInfoComponent } from '../../../components/StoreHouseComponents/StoreHomeReceivingMaterialReportIncomingComponents/StoreHomeReceivingMaterialReportIncomingRigthInfoComponent';
import './styles.scss';

export const StoreHomeReceivingMaterialReportIncomingScreen = () => {

    const [openModal, setOpenModal] = useState(null);
    const [cxSelected,setCxSelected] = useState(null);
    const {type} =  useParams();
    const updateMainObject = () => {};

    const closeModal = () => {
        if(openModal === 'OPEN'){
            setOpenModal(null);
        }
        else if(openModal === 'MODAL-LIST'){
            setOpenModal(null);
            setOpenModal('OPEN');
        }
       else if(openModal === 'saveReport'){
            setOpenModal(null);
        }
        else if(openModal === 'chat'){
            setOpenModal(null);
        }
        else if(openModal === 'SHARE'){
            setOpenModal(null);
            setOpenModal('chat');
        }
        else if(openModal === 'delete'){
            setOpenModal(null);
        }
        setOpenModal(null);

    }

    return (
        <div className="StoreHomeReceivingMaterialReportIncomingScreen">

            <SubNavBarComponent title={'ALMACÉN ENTRADA'} />
            <div className="StoreHomeReceivingMaterialReportIncomingScreen-container">


                <StoreHomeReceivingMaterialReportIncomingLeftInfoComponent 
                closeModal = {closeModal}
                setOpenModal = {setOpenModal}
                openModal= {openModal} 
                type = {type}
                />
                <StoreHomeReceivingMaterialReportIncomingRigthInfoComponent 
                    setOpenModal = {setOpenModal}
                    openModal = {openModal}
                    updateMainObject = {updateMainObject}
                    closeModal = {closeModal}
                    cxSelected={cxSelected}
                    setCxSelected={setCxSelected}

                />

                <section className='StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-container'>
                    <div className='StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-container-position'>
                        <button className='StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-quote'  >
                            GUARDAR
                        </button>
                        <button className='StoreHomeReceivingMaterialReportIncomingScreen-section-bottom-button-continue'  >
                            FINALIZAR
                        </button>
                    </div>
                </section>

            </div>

        </div>
    )
}
