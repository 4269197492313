import React from 'react';
import shortid from 'shortid';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseListComponent';
import { StoreHouseApproveStatusRigthListComponent } from '../../../components/StoreHouseComponents/StoreHouseApproveStatusComponent/StoreHouseApproveStatusRigthListComponent';
import './sass/styles.scss';

export const StoreHouseApproveStatusScreen = () => {

    const arrayListFields = [
        {
            uid: shortid.generate(),
            title: 'Ubicación',
            value: 'ADD 1'
        },
        {
            uid: shortid.generate(),
            title: 'Inicio',
            value: '20/07/2021 - 13:00 hrs'
        },
        {
            uid: shortid.generate(),
            title: 'Descripción',
            value: 'Add Plus - 4 tornillos'
        },
        {
            uid: shortid.generate(),
            title: 'Médico',
            value: 'Daniel Ríos'
        },
        {
            uid: shortid.generate(),
            title: 'ATQ',
            value: 'No requiere ATQ'
        },
        {
            uid: shortid.generate(),
            title: 'Institución',
            value: 'Hospital Real San José'
        },
    ];

    return (

        <div className="StoreHouseApproveStatusScreen-container">

            <SubNavBarComponent title={'ALMACÉN'} />

            <section className="StoreHouseApproveStatusScreen-precontainer">
                <div className="StoreHouseApproveStatusScreen-container-section">
                    <div className="StoreHouseApproveStatusScreen-container-left-section">

                        <StoreHouseListComponent 
                            typeView={{type:'STORE', title: 'CX #3'}}
                            listFields={arrayListFields} 
                        />

                    </div>

                    <div className="StoreHouseApproveStatusScreen-container-rigth-section">
                        <StoreHouseApproveStatusRigthListComponent />
                    </div>
                </div>
                <div className="StoreHouseApproveStatusScreen-container-bottom-button">
                    <button className="StoreHouseApproveStatusScreen-bottom-button">
                        ACEPTAR
                    </button>
                </div>
            </section>

        </div>
    )
}
