import React, { useEffect } from 'react'
import { ListActiveUsers } from './ListActiveUsers'
import './styleAddMember.scss'

export const AddMembersComponent = ({setOpenModalAdd}) => {

    // useEffect(() => {
    //     setOpenModalAdd(null)

    // }, [])
    
    return (
        <div className='container-add-members-modal-chat'>

            <div className='subcontainer-add-memebers-modal-chat'>
                <ListActiveUsers
                setOpenModalAdd={setOpenModalAdd} />
            </div>

        </div>
    )
}

