import React from 'react';
import PropTypes from 'prop-types';
import './layouts/layout1/navbar/sass/styles.sass';
import { NavbarComponent } from './layouts/layout1/navbar/NavbarComponent';
import { FooterComponent } from './layouts/layout1/footer/FooterComponent';
import { ModalConversation } from './ChatConversation/ModalConversation';
import { useSelector } from 'react-redux';



export default function ClippedDrawer(props) {

  const statusChat = useSelector(state => state.notificationChat.setChatGroupSelected)

  return (
    <div>
      <NavbarComponent />
      <main style={{ position: 'relative' }}>
        {props.children}


        {statusChat && statusChat.status &&

          <ModalConversation />

        }



        <FooterComponent />
      </main>
    </div>
  );
}

ClippedDrawer.propTypes = {
  children: PropTypes.any,
};
