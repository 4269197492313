import React from 'react';
import { useHistory } from 'react-router-dom';
import { InfoButtonLeft } from '../../ATQAdministrator/ATQAdministrator_Survey/InfoButtonLeft';
import { InfoButton } from '../../ATQAdministrator/ATQ_CX_ASIGNAR_ATQ/InfoButton';
import './sass/styles.scss';
import { infoButtonsStoreHouseLeft } from './storeHouseStockLeftdataButtons';

export const StoreHouseStockLeftButtons = ({page}) => {

    const history = useHistory();

    const handleNavigate =(path) => {
        history.replace(path);
    }
    

    return (
        <aside className=" storeHouseStockLeftButtons_container ">
        {
            page === 'StoreHouseStockScreen' && 
            <>
                {
                    infoButtonsStoreHouseLeft.map(item => (

                        <button key={item.uid} className="storeHouseStockLeftButtons storeHouseStockLeftButtons_logistica" onClick={()=> {
                          if(item.path !== "Encuestas" && item.path !== "Reception"){
                            item.path &&handleNavigate(item.path)} 
                          }  
                        }>
                        {
                            item.img&&
                            <img src={item.img} alt="" className='storeHouseStockLeftButtons_logistica-img' />
                        }
                            <span className=' storeHouseStockLeftButtons_logistica-text'>{item.name}</span>
                            
                        </button>
                        
                    ))
                }
            </>
        }
        {
            page === 'ATQAdministrator_Finally_Survery' && 
            <>
                {
                    InfoButtonLeft.map(item => (

                        <button key={item.uid} className="storeHouseStockLeftButtons storeHouseStockLeftButtons_logistica" onClick={()=> item.path &&handleNavigate(item.path)} >
                        {
                            item.img&&
                            <img src={item.img} alt="" className='storeHouseStockLeftButtons_logistica-img' />
                        }
                            <span className=' storeHouseStockLeftButtons_logistica-text'>{item.name}</span>
                            
                        </button>
                        
                    ))
                }
            </>
        }

        {
            page === 'ATQAdmin_CX_ASIGNAR_ATQ_Page' && 
            <>
                {
                    InfoButton.map(item => (

                        <button key={item.uid} className="storeHouseStockLeftButtons storeHouseStockLeftButtons_logistica" onClick={()=> item.path &&handleNavigate(item.path)} >
                        {
                            item.img&&
                            <img src={item.img} alt="" className='storeHouseStockLeftButtons_logistica-img' />
                        }
                            <span className=' storeHouseStockLeftButtons_logistica-text'>{item.name}</span>
                            
                        </button>
                        
                    ))
                }
            </>
        }
    
        </aside>
    )
}
