import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createChatGroup, create_chat_group_success } from '../../../actions/notification-chatAction';
import './styleAddTalkGroup.scss';


export const AddTalkGroup = (props) => {
  const dispatch = useDispatch();
  const appoimentId = useSelector(state => state.notificationChat.setSurgeryChatSelected);
  const [nameGroupAdd, setNameGroupAdd] = useState();


  const createNewGroup = () => {
    if (nameGroupAdd) {
      dispatch(createChatGroup(appoimentId, nameGroupAdd));
    } else {
      return;
    }
    props.setOpenModalAdd(null)
  }

  return (
    <div className='container-general-modal-chat'>
      <form method='POST' action='createGroup' className='form-chat-modal'>
        <div className='container-first-modal-chat'>
          <input className='input-body-modal-chat'
            type="text" id="name" name="name" size="20"
            onChange={(e) => setNameGroupAdd(e.target.value)}
            value={nameGroupAdd}
            placeholder='Nombre del chat' required />
        </div>

        <div className='container-second-modal-chat'>
          <button type='submit' className='btn-aceptar-modal-chat' onClick={createNewGroup}>CREAR</button>
        </div>

      </form>
    </div>

  );

};


