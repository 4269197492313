import React from 'react';
import CustomModal from '../../../shared/Modal';
import { ModalOrderBody } from './ModalOrderBody';

export const ModalOrderSpecific = (props) => {
    const modalBody = () => {
        
        return (
            <ModalOrderBody 
                toggle={props.toggle} 
                members={props.members} 
                idGroup = {props.idGroup}
                idDay={props.idDay} 

            />
        );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle}
                title="ROL DE EQUPO"
                body={modalBody()}
        />
    );

}
