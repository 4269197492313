import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import black_arrow from '../../assets/img/126PX/black_arrow.png';
import './styleEncuestaList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getSurvey, getSurveySuccess, setSurveySelected, surveySearchATQSuccess } from '../../actions/surveyAction';
import { formatDateToPresent, formatHourToPresent } from '../../hooks/useFormatDateInitialAndFinalDay';



export const ListEncuestasComponent = ({ activeSwitchVentas,setActiveSwitch,setFrom,from }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [list, setList] = useState(null);
    const [currentPage, setPage] = useState(0);
    const survey = useSelector(state => state.survey.getSurvey);
    const listSearch = useSelector(state => state.survey.getSearchSurvey)
    const render = useRef(0);

    const handleNivagateToApproveOrder = (id,item) => {
        dispatch(setSurveySelected({...item}))
        history.replace(`/Survey_Mantenimiento_Detalle/${activeSwitchVentas}/${id}`)
    }
    useEffect(() => {
        return () => {
            dispatch(getSurveySuccess(null));
            dispatch(surveySearchATQSuccess(null));
        }
    }, [])

    useEffect(() => {
        if(currentPage !== null && from === 'dad'){
            if (currentPage === 0) {
                if (activeSwitchVentas === "ACTIVE") {
                    dispatch(getSurvey(1, 17, currentPage, 10))
                }
                if (activeSwitchVentas === "INACTIVE") {
                    dispatch(getSurvey(5, 17, currentPage, 10))
                }

            } else if (currentPage > 0 && survey !== null) {
                if (activeSwitchVentas === "ACTIVE") {
                    dispatch(getSurvey(1, 17, currentPage, 10))
                } else if (activeSwitchVentas === "INACTIVE") {
                    dispatch(getSurvey(5, 17, currentPage, 10))
                }
            }
        }else{
            setPage(0);
        }
    }, [currentPage])


    // ESTE ES PARA USAR EL LIST 
    useEffect(() => {
        if (list && list.length > 0 && survey.length > 0) {
            setList([...list, ...survey])
        } else if (survey && survey.length > 0) {
            setList(survey)
        }
    }, [survey])

    useEffect(() => {
        if (render.current > 0) {
            setList(null);
            setPage(0);
            dispatch(getSurveySuccess(null));

            if(from === 'dad'){
                dispatch(surveySearchATQSuccess(null))
            }

            if(currentPage === 0){
                setPage(null)
            }
        } else {
            setList(null);
            render.current = 1;
        }
    }, [activeSwitchVentas])

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    useEffect(() => {
        if(listSearch && listSearch.length > 0){
            setFrom('child')
            if(listSearch[0].maintenanceSurvey &&  listSearch[0].maintenanceSurvey.id){
                setActiveSwitch('INACTIVE')
            }else{
                setActiveSwitch('ACTIVE')
            }
        }
    }, [listSearch])


    return (
        <div className="Survey_Ventas_elements_container_info" onScroll={handleScroll} >
            {
                !listSearch
                   
                   ?( survey && list !== null && list.length > 0 &&list.map(item => (
                        <div className="Survey_Ventas_elements_info_container" key={item.surgeryAppointment.id} onClick={() => handleNivagateToApproveOrder(item.surgeryAppointment.id,item)}>
                            <div className="Survey_Ventas_elements_info_elements_container" >
                                <span className="Survey_Ventas_elements_info_date_container">
                                    {item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) +
                                        ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' hrs' +
                                        ' - ' + ' Dr. ' + (item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                        (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                        (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                        (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)}
                                </span>
                                <span className="info_date_encuesta">
                                    <p className='Survey_Ventas_elements_info_date'>
                                        {(item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name) + ' - '
                                            + 'CX# ' + (item.surgeryAppointment.id && item.surgeryAppointment.id)}
                                    </p>
                                </span>
                            </div>
                            <img src={black_arrow} alt="arrow" className={'Survey_Ventas_elements_img_arrow'} />
                        </div>
                    ))
                   )
                   :( listSearch && listSearch !== null && listSearch.length > 0 && listSearch.map(item => (
                        <div className="Survey_Ventas_elements_info_container" key={item.surgeryAppointment.id} onClick={() => handleNivagateToApproveOrder(item.surgeryAppointment.id,item)}>
                            <div className="Survey_Ventas_elements_info_elements_container" >
                                <span className="Survey_Ventas_elements_info_date_container">
                                    {item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) +
                                        ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' hrs' +
                                        ' - ' + ' Dr. ' + (item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                        (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                        (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                        (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)}
                                </span>
                                <span className="info_date_encuesta">
                                    <p className='Survey_Ventas_elements_info_date'>
                                        {(item.surgeryAppointment.hospital.name && item.surgeryAppointment.hospital.name) + ' - '
                                            + 'CX# ' + (item.surgeryAppointment.id && item.surgeryAppointment.id)}
                                    </p>
                                </span>
                            </div>
                            <img src={black_arrow} alt="arrow" className={'Survey_Ventas_elements_img_arrow'} />
                        </div>
                    ))
                   )
            }
        </div>
    )
}

ListEncuestasComponent.propTypes = {
    activeSwitchVentas: PropTypes.any,
}


