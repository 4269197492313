import React, { useState } from 'react';
import { ATQAdmin_Tranferir_CX_List_Component } from '../../../components/ATQAdministrator/ATQAdmin_Tranferir_CX/ATQAdmin_Tranferir_CX_List_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';

import './style.scss';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';

export const ATQAdmin_Transferir_CX_Page = () => {

    const [activeSwitch, setActiveSwitch] = useState('ACTIVE');
    return (

        <>
        <ComingSoonComponent/>
        {/*<div className = "precontainer_page">


        { activeSwitch === 'ACTIVE' &&
      
        <SubNavBarComponent 
        title={'TRANSFERIR CX'} 
        searchActive={true}  
        historyPage={'/ATQAdmin_Inicio'}
        typeSearch={"CxToTransfer"}
        specificType = {'id'}
         />
        }


        { activeSwitch === 'INACTIVE' &&
      
        <SubNavBarComponent 
        title={'TRANSFERIR CX'} 
        searchActive={true}  
        historyPage={'/ATQAdmin_Inicio'}
        typeSearch={"CxTransfered"}
        specificType = {'id'}
        />
        }
            <div className="container_page">
                <div className="container_buttons">
                     <nav className="toogle_container_buttons">
                            <button className={ activeSwitch === 'ACTIVE'
                               ? "normal_button-asignar btn_left-asignar btn_active-asignar"
                               : "normal_button-asignar btn_left-asignar"} onClick ={() => setActiveSwitch('ACTIVE')}>
                                CX A TRANSFERIR
                            </button>
                            <button className={ activeSwitch === 'INACTIVE'
                               ? "normal_button-asignar btn_rigth-asignar btn_active-asignar"
                               : "normal_button-asignar btn_rigth-asignar"} onClick ={() => setActiveSwitch('INACTIVE')}>
                                CX TRANSFERIDAS
                            </button>
                     </nav>
                </div>
                <div className="section_list">
                <ATQAdmin_Tranferir_CX_List_Component
                    activeSwitch = {activeSwitch}
                />
                </div>
                
            </div>
        </div>*/}
        </>

    )
}
