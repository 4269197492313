import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as XLSX from "xlsx";
import excelLogo from '../../assets/img/512PX/excelLogo.png';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './styleHomeMobileSales.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { uploadInventoryAsync } from "../../actions/userActions";
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { encryptData } from '../../utils/encrypt.service';

const BlueCheckbox = withStyles({
    root: {
        color: '#004D9C',
        '&$checked': {
            color: '#004D9C',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const HomeMobileSalesPage = () => {

    const vertical = 'top'
    const horizontal = 'right'

    const refExcel = useRef();
    const handleClickExcel = () => {
        refExcel.current.click()
    }
    const requestH = useSelector((state) => state.user.request);

    const [excelInformation, setExcelInformation] = useState()
    const [open, setOpen] = React.useState(false);
    const [openSucess, setOpenSucess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);


    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear()

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setExcelInformation(json)
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
        e.target.value = null;
    }

    const tableColumns = [
        { id: 'id', label: 'NO. PRODUCTO', minWidth: '5%', align: 'center' },
        { id: 'prodDesc', label: 'DESCRIPCIÓN', minWidth: '10%', align: 'center' },
        { id: 'prodLote', label: 'LOTE', minWidth: '10%', align: 'center' },
        { id: 'prodPedi', label: 'PEDIMENTO', minWidth: '5%', align: 'center' },
        { id: 'prodStock', label: 'ALMACÉN CONGRESO', minWidth: '5%', align: 'center' },
        { id: 'prodStockAG', label: 'ALMACÉN AG', minWidth: '5%', align: 'center' },
        { id: 'prodPrice', label: 'PRECIO', minWidth: '20%', align: 'center' },
        { id: 'prodEnable', label: 'HABILITAR PARA CARGA', minWidth: '15%', align: 'center' },
        { id: 'prodColor', label: 'COLOR', minWidth: '20%', align: 'center' },
        { id: 'prodCat', label: 'CATÁLOGO', minWidth: '5%', align: 'center'},
    ]

    function formatearNumeroComoMoneda(numero) {
        if (isNaN(numero)) { return "No es un número válido"; }
        const numeroFormateado = parseFloat(numero).toFixed(2);
        const partes = numeroFormateado.split(".");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const resultado = `$ ${partes.join(".")} MXN`;
        return resultado;
    }

    function formatData(rowData) {
        const booleanValue = rowData["Habilitar Para Carga"] === 'SI' ? true : false
        return {
            "id": rowData["Numero Producto"],
            "prodDesc": rowData["Descripcion"],
            "prodLote": rowData["Lote"],
            "prodPedi": rowData["Pedimento"],
            "prodStock": rowData["Almacen Congreso"],
            "prodStockAG": rowData["Almacen AG"],
            "prodPrice": formatearNumeroComoMoneda(rowData["Precio"]),
            "prodPriceNotFormatted": rowData["Precio"],
            "prodEnable": booleanValue,
            "prodColor": rowData["Color"] ? rowData["Color"] : rowData["COLOR"],
            "prodCat": rowData["Catalogo"] ? rowData["Catalogo"] : rowData["CATALOGO"],
        }
    }

    const handleCheckBoxChange = (index) => {
        const cloneArray = JSON.parse(JSON.stringify(excelInformation));
        const selectedObject = cloneArray[index]
        if (selectedObject["Habilitar Para Carga"] === 'SI') {
            selectedObject["Habilitar Para Carga"] = 'NO'
        } else {
            selectedObject["Habilitar Para Carga"] = 'SI'
        }
        setExcelInformation(cloneArray)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenSucess = () => {
        setOpenSucess(true);
    };

    const handleCloseSucess = () => {
        setOpenSucess(false);
    };

    const handleClickOpenError = () => {
        setOpenError(true);
    };

    const handleCloseError = () => {
        setOpenError(false);
    };

    const handleUploadData = async () => {
        const dataToSend = excelInformation.map((singleElement) => {
            const formattedData = formatData(singleElement)
            const formattedID = (typeof formattedData.id === 'string') ? formattedData.id : JSON.stringify(formattedData.id)
            return {
                "prCode": formattedID, //No. Producto
                "prDescription": formattedData.prodDesc,   //Descripcion
                "prLote": formattedData.prodLote,                  //Lote
                "prPedimento": JSON.stringify(formattedData.prodPedi),             //Pedimento
                "stockCongre": JSON.stringify(formattedData.prodStock),           //Almacen Congreso
                "stockAlm": JSON.stringify(formattedData.prodStockAG),       //Almacen AG
                "price": parseFloat(formattedData.prodPriceNotFormatted),        //Precio
                "cargar": parseInt(formattedData.prodStock),
                "color": formattedData.prodColor,               //Color
                "catalogo": parseInt(formattedData.prodCat), //Catálogo
            }
        })

        const newData = encryptData(JSON.stringify({ "code": 102, "valores": dataToSend }))

        await uploadInventoryAsync({ "palabra": newData }, requestH).then(resp => {
            if (resp.valido && resp.valido === 1) {
                setExcelInformation(null)
                handleClose()
                handleClickOpenSucess()
            } else {
                handleClickOpenError()
                handleClose()
            }
        });
    }

    return (
        <div className='container'>
            <SubNavBarComponent title={'VENTA MÓVIL'} />
            <Snackbar
                open={openSucess}
                autoHideDuration={6000}
                onClose={handleCloseSucess}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert onClose={handleCloseSucess} severity="success">
                    Tus datos fueron agregados con exito.
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="success">
                    Hubo un errro al agregar tus datos.
                </Alert>
            </Snackbar>
            <div className='header'>
                <div className='title'>
                    <p>CARGA DE PRODUCTOS PARA VENTAS MÓVILES</p>
                    <hr />
                </div>
                <button className='importBtn' onClick={handleClickExcel}>
                    <img src={excelLogo} width={20} height={20} />
                    <input
                        ref={refExcel}
                        type="file"
                        hidden
                        onChange={readUploadFile}
                    />
                    <text>Importar</text>
                </button>
            </div>

            <div>
                <div className='dateContainer'>
                    <p>FECHA DE CARGA: {year}-{month}-{day}</p>
                </div>

                {/* -------------------------- TABLE ----------------------------- */}
                <div className='tableContainer'>
                    <TableContainer style={{ maxHeight: 600 }}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => {
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                width={column.minWidth}>
                                                <div style={{ display: 'flex', justifyContent: column.align, whiteSpace: 'pre-line' }}>
                                                    {column.label}
                                                </div>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {excelInformation && excelInformation.map((row, index) => {
                                    const information = formatData(row)
                                    return (
                                        <TableRow key={information.id}>
                                            {tableColumns.map((col) => {
                                                const value = information[col.id]

                                                return (
                                                    <TableCell key={col.id} align='center'>
                                                        {
                                                            col.id == 'prodEnable' ?
                                                                <BlueCheckbox checked={value} onChange={() => { handleCheckBoxChange(index) }} /> :
                                                                value
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Carga de productos"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Estas seguro de subir los datos ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleUploadData} color="primary" autoFocus>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className='bottomBar'>
                <button
                    className='blueBtn'
                    onClick={handleClickOpen}
                    disabled={!(excelInformation && excelInformation.length > 0)}
                >
                    <text>CARGAR PRODUCTOS</text>
                </button>
            </div>
        </div>
    );
};