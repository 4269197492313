import React from 'react';
import { useSelector } from 'react-redux';
import './styleCausa.scss';


const CausaSurveyComponent = ({ type = null,causas,setCausas }) => {

    const user = useSelector(state => state.user.user.identity);
    return (
        <section className='container-component-comments-surveyCausa'>
            <div className='header-commentsCausa'>
                <div className='header-titleCausa'>
                    <p className='title-componentCausa'>CAUSA</p>
                </div>
            </div>

            <div className='body-causa'>

                <div className='container-causa'>
                    <div className='container-causa-left'>
                        <div className='preco-row-causa'>
                            <p className='text-causa'>Desgaste por uso de instrumental</p>
                            <input className='input_check_causa'
                                type="checkbox"
                                name="desgaste"
                                checked={causas.desgaste}
                                disabled={(type !== 'seguimiento')  ?true :user.roleID === 9 ?true  :false}
                                onChange={() => setCausas({...causas,desgaste:!causas.desgaste})}></input>
                        </div>

                        <div className='preco-row-causa'>
                            <p className='text-causa'>Material Incompleto</p>
                            <input className='input_check_causa'
                                type="checkbox"
                                id="cbox2"
                                name="material"
                                disabled={(type !== 'seguimiento')  ?true :user.roleID === 9 ?true  :false}
                                checked={causas.material}
                                onChange={() => setCausas({...causas,material:!causas.material})}></input>
                        </div>

                        <div className='preco-row-causa'>
                            <p className='text-causa'>Bajo nivel de stock</p>
                            <input className='input_check_causa'
                                type="checkbox"
                                id="cbox2"
                                name="stock"
                                checked={causas.stock}
                                disabled={(type !== 'seguimiento')  ?true :user.roleID === 9 ?true  :false}
                                onChange={() => setCausas({...causas,stock:!causas.stock})}></input>
                        </div>

                        <div className='preco-row-causa'>
                            <p className='text-causa'>Mala técnica por parte del médico</p>
                            <input className='input_check_causa'
                                type="checkbox"
                                id="cbox2"
                                name="tecnica"
                                checked={causas.tecnica}
                                disabled={(type !== 'seguimiento')  ? true :user.roleID === 9 ?true  :false}
                                onChange={() => setCausas({...causas,tecnica:!causas.tecnica})}
                            ></input>
                        </div>

                    </div>

                    <div className='container-causa-rigth'>
                        <div className='preco-row-causa'>
                            <p className='text-causa'>Requisito especial por parte del médico</p>
                            <input className='input_check_causa'
                                type="checkbox"
                                id="cbox2"
                                name="requisitos"

                                checked={causas.requisito}
                                disabled={(type !== 'seguimiento')  ?true :user.roleID === 9 ?true  :false}
                                onChange={() => setCausas({...causas,requisito:!causas.requisito})} ></input>
                        </div>

                        <div className='preco-row-causa'>
                            <p className='text-causa'>Otra</p>
                            <input className='input_check_causa'
                                type="checkbox"
                                id="cbox2"
                                name="otra"
                                checked={causas.otra}
                                disabled={(type !== 'seguimiento')  ?true :user.roleID === 9 ?true  :false}
                                onChange={() => setCausas({...causas,otra:!causas.otra})} />
                        </div>

                        <div className='preco-row-causa'>
                            <input placeholder='Agregar otra...' className='input_check_causaAgregar'
                                type="text"
                                id="cbox2"
                                disabled={!causas.otra}
                                value={causas.comOtra}
                                onChange ={(e) => setCausas({...causas,comOtra:e.target.value})}
                                name="agregar"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default CausaSurveyComponent;
