import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import './DoctorTabChatBarRight.scss';
import img_msj_group from '../../../../../../../assets/img/512PX/Icono_GroupMsj_Azul.png'
import { MessageLeftComponent } from '../../../../../../../components/ChatConversation/MessageComponent/MessageLeftComponent';
import { MessageRigthComponent } from '../../../../../../../components/ChatConversation/MessageComponent/MessageRigthComponent';



const DoctorTabChatBarRight = () => {

    const chat = useSelector(state => state.allUsers.getGroup);
    const chatID = useSelector(state => state.allUsers.selectedChatId);
    const messages = useSelector(state => state.allUsers.getMessages);
    const user = useSelector(state => state.user.user.identity)
    const [messageRigth, setMessageRigth] = useState()


    useEffect(() => {
        if (messages) {
            let msg = [...messages]
            const messagesReverse = msg.reverse();
            setMessageRigth(messagesReverse)
        }

        return () => {
            setMessageRigth(null)
        }
    }, [messages])

    useEffect(() => {
        ScrollToBottom()
    })


    const ScrollToBottom = () => {
        const scrollId = document.getElementById("scrollHeigth")
        scrollId.scrollTo(0, scrollId.scrollHeight);
    }

 

    return (
        <div>
            <div className={'doctor-tab-chat-right-profile'}>
                <div className={"image-circle"}>
                    <img src={img_msj_group} alt={"User photo"} />
                </div>
                {
                    chat &&
                        chatID &&
                        chat.length > 0 ?
                        chat.map(item => (
                            item.id === chatID &&
                            <span className={"doctor-tab-chat-right-title"}>{item.chatGroupName}</span>

                        ))

                        :
                        <p className='doctor-tab-chat-right-title'>Sin grupo seleccionado</p>

                }
            </div>

            <div className='container-messages-sesions' id='scrollHeigth'
                onClick={() => ScrollToBottom}>

                {
                    messageRigth &&
                        messageRigth.length > 0 ?
                        messageRigth.map((item) => (
                            item.sender.userID === user.userID ?
                                <MessageRigthComponent
                                    messages={item.message}
                                    userSend={item.sender.firstName}
                                    hour={item.createdAt}
                                    media={item.media ? item.media.mediaURL : null} />
                                :
                                <MessageLeftComponent
                                    messages={item.message}
                                    userSend={item.sender.firstName}
                                    hour={item.createdAt}
                                    media={item.media ? item.media.mediaURL : null}

                                />
                        ))


                        : messageRigth &&
                            messageRigth.length <= 0 ?
                            <p className='txt-member-sinasignar-msj'>Sin mensjaes</p>
                            : !chatID &&
                            <p className='txt-member-sinasignar-msj'>Sin grupo seleccionado</p>
                }
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default withRouter(connect(mapStateToProps)(DoctorTabChatBarRight));
