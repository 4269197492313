import React from 'react'
import PropTypes from 'prop-types';
import CustomModal from '../../shared/Modal';
import { ModalOrderTeamATQ } from './ModalOrderTeamATQ ';

export const ModalOrderTeamATQComponent = (props) => {

    const modalBody = () => {
        
        return (
            <ModalOrderTeamATQ toggle={props.toggle} 
            changeBan={props.changeBan} 
            page = {props.page} 
            idGroup = {props.idGroup} 
            ban={props.ban}
            atqList = {props.atqList}
            setATQList = {props.setATQList}
            />

        );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle}
                title="ROL DE EQUIPO"
                body={modalBody()}
        />
    );
}

ModalOrderTeamATQComponent.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    modalOrSystem: PropTypes.any,
    itemProductBundle: PropTypes.any,
    itemProduct: PropTypes.any,
};

