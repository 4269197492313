import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import ListItem1 from '../../shared/Molecules/ListItem1';
import icoBuscarGris from '../../../assets/img/126PX/Icono_Buscar_Gris_126px.png';
import { getInstitutions, getInstitutionsSucess,searchHospital } from '../../../actions/systemsAction';
import { useDispatch, useSelector } from 'react-redux';
import grayBorderImage from '../../../assets/img/512PX/Logo_TSI_512PX.jpg';


export const Mess_hosp_Req_Comp = ({selected,setSelected,setCurrentPage,list1,setList1}) => {
    const systems = useSelector( state => state.systems);
    const searchHospitalStore = useSelector(state => state.systems.searchHospitals);
    const getHospitalStore = systems.getInstitutions;
    const [selectedTabId, setSelectedTabId] = useState(null);
    const [searchInstitution, setSearchInstitution] = useState("");
    const dispatch = useDispatch();
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState([]); 

    //DE AQUI EN ADELANTE SON FUNCIONES PARA HACER LAS LISTA
    useEffect(() => {
        if(list1 === null){
          setList([])
          dispatch(getInstitutions(0));
          setSelected(null);
          setList1('0')
        }
      },[list1])
  
    useEffect(() => {
      if(list && list.length > 0){
      setList([...list,...getHospitalStore])
      }else if(getHospitalStore && getHospitalStore.length > 0){
        setList(getHospitalStore)
      }
    },[getHospitalStore])
    //-----------------------------------------------------



    useEffect(() => {
        dispatch(getInstitutions(currentPage));
    }, [currentPage])
  
      useEffect(() => {
          setSelected(selectedTabId);
      }, [selectedTabId])
  
    useEffect(() => {
      return () => {
      dispatch(getInstitutionsSucess([]));
      }
  }, [])
    //---------------------------------------------------


    //ESTE ES EL METODO E BUSQUEDA
    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(searchHospital(searchInstitution));
    }
    //-----------------------------------------------------
    
        const selectedTabIdContent = (item) => {
            setSelectedTabId(item.id)
            setSelected(item)
        }

        const handleScroll = (event) => {
            const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
            
            if(scrollHeight-Math.round(scrollTop) === clientHeight){
                setPage(prev => prev + 1)
        
            }
        }
        return (
            <div className={"HospitalHome-left-bar"} onScroll={handleScroll}>
                <div className={"c-medic-c-search"}>
                <form  className="tools-c-input-search" onSubmit={handleSearch}>
                    <div className="c-tools-search-icobuscar">
                        <img src={icoBuscarGris} alt="" />
                    </div>
                    <input
                            type="text"
                            placeholder="Buscar..."
                            name="search"
                            onChange={({target}) => setSearchInstitution(target.value)}
                            value={searchInstitution}
                            className="tools-input-search"
                    />
              </form>
                </div>
                <div className={'profile-list'}>
                    {
                        list &&
                        searchInstitution.trim().length === 0&&
                        list.map((item) => (

                        <div key = {item.id} onClick={() => setSelected(item)}>
                            {
                                <ListItem1 
                                    id={item.id} 
                                    userSeller={selected}
                                    img={grayBorderImage}
                                    key = {item.id}
                                    subtitle={item.name}
                                    subtitle2 = {item.city + ", " + item.state}
                                    selectedTabId={selected && selected.id}
                                />
                            }
                        </div>  
                    ))
                    }

                    {
                    searchHospitalStore &&
                    searchInstitution.trim().length > 0 &&
                    searchHospitalStore.map((item) => (

                        <div key = {item.id} onClick={() => selectedTabIdContent(item)}>
                            {
                                <ListItem1 
                                    id={item.id} 
                                    img={grayBorderImage}
                                    userSeller={selected}
                                    key = {item.id}
                                    subtitle={item.name}
                                    subtitle2 = {item.city + ", " + item.state}
                                    selectedTabId={selectedTabId}
                                />
                            }
                        </div>  
                    ))
                }
                </div>
    
            </div>
        
    )
}
Mess_hosp_Req_Comp.propTypes = {
    selected: PropTypes.any,
    setSelected: PropTypes.any,
}
