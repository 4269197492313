import React, { useState } from 'react';
import StarsComponent from '../../Stars/StarsComponent';
import './styleMedico.scss'


export const Survey_Medico_Component = ({ titulo, tipo, resp }) => {
    const [response, setResponse] = useState(resp ?{...resp} :'' )
    
    /* console.warn('response: ',response) */


    return (
        <>
            {
                tipo === 'medico-almacen' &&

                <div className="section-question-medico">
                    <span class="title-question-medico">{titulo}</span>
                    <span className="title-question-intern-medico">¿Requiere vista del asesor comercial?</span>
                    <span className="text-answer-medico">{response.businessAdvisorVisitRequired === true ?'Si' :'No'}<br />
                        Se agregan los comentarios del medico, en caso de que tenga comentarios.
                    </span>
                    <span className="title-question-intern-medico">¿Existe riesgo infeccioso en el paciente?.</span>
                    <span className="text-answer-medico"> {response.infectiousRisk === true ?'Si' :'No'}<br />
                        Se agregan los comentarios del asesor técnico, en caso de que tenga comentarios.</span>
                    <span className="title-question-intern-medico">¿Como califica el proceso de programación surtido de CX?</span>
                    <div className=''>
                        <StarsComponent valoracion={response.assortmentSupplyRate ? parseInt(response.assortmentSupplyRate) :0} />
                    </div>
                    <span className="title-question-intern-medico">¿Como califica el instrumento?</span>
                    <div className=''>
                        <StarsComponent valoracion={response.instrumentalRate ? parseInt(response.instrumentalRate) :0} />
                    </div>
                    <span className="title-question-intern-medico">¿Como califica los implantes y combustibles?</span>
                    <div className=''>
                        <StarsComponent valoracion={response.implantsAndConsumablesRate ? parseInt(response.implantsAndConsumablesRate) :0} />
                    </div>
                    <span className="title-question-medico-sub">Calificación del Servicio Técnico</span>
                    <span className="title-question-intern-medico">Dominio en técnica quirúrgica.</span>
                    <div className=''>
                        <StarsComponent valoracion={response.atqSurgeryTechniqueRate ? parseInt(response.atqSurgeryTechniqueRate) :0}/>
                    </div>
                    <span className="title-question-intern-medico">Solución de problemas</span>
                    <div className=''>
                        <StarsComponent valoracion={response.atqIssuesSolutionRate ? parseInt(response.atqIssuesSolutionRate) :0} />
                    </div>
                    <span className="title-question-intern-medico">Productividad</span>
                    <div className=''>
                        <StarsComponent valoracion={response.atqProductivityRate ? parseInt(response.atqProductivityRate) :0} />
                    </div>
                    <span className="title-question-intern-medico">Comunicación</span>
                    <div className=''>
                        <StarsComponent valoracion={response.atqCommunicationRate ? parseInt(response.atqCommunicationRate) :0} />
                    </div>
                    <span className="title-question-intern-medico">Puntualidad</span>
                    <div className=''>
                        <StarsComponent valoracion={response.atqPuntualityRate ? parseInt(response.atqPuntualityRate) :0}  />
                    </div>
                    <span className="title-question-intern-medico-finaly">Seguimiento de TSI:</span>
                    <span className="text-answer-medico">Acciones llevadas a cabo y respuestas departe de TSI...</span>
                    <span className="title-question-intern-medico-finaly">Comentario del médico:</span>
                    <span className="text-answer-medico">{response.commentary ?response.commentary :''} </span>

                </div>
            }
            {
                tipo === 'medico-gerente' &&
                <div className="section-question-medico-gerente">
                    <table className='container-table'>
                        <tr >
                            <th className='fila-title' colSpan={2}><span class="title-question-medico">{titulo}</span></th>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">¿Requiere vista del asesor comercial?</span></td>
                            <td className='fila-info-sub'><span className="fila-subtitle-medico">{response.businessAdvisorVisitRequired === true ?'Si' :'No'}</span></td>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">¿Existe riesgo infeccioso en el paciente?.</span></td>
                            <td className='fila-info-sub'><span className="fila-subtitle-medico"> {response.infectiousRisk === true ?'Si' :'No'}</span></td>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">¿Como califica el proceso de programación surtido de CX?</span></td>
                            <td className='fila-info-sub'> <StarsComponent valoracion={response.assortmentSupplyRate ? parseInt(response.assortmentSupplyRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">¿Como califica el instrumental?</span></td>
                            <td className='fila-info-sub'><StarsComponent valoracion={response.instrumentalRate ? parseInt(response.instrumentalRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-info'> <span className="title-medico">¿Como califica los implantes y combustibles?</span></td>
                            <td className='fila-info-sub'><StarsComponent valoracion={response.implantsAndConsumablesRate ? parseInt(response.implantsAndConsumablesRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-info-servicio'><span className="title-medico">Servicio Técnico</span></td>
                            <td><span></span></td>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">Dominio en técnica quirúrgica.</span></td>
                            <td className='fila-info-sub'><StarsComponent valoracion={response.atqSurgeryTechniqueRate ? parseInt(response.atqSurgeryTechniqueRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-info'> <span className="title-medico">Solución de problemas</span></td>
                            <td className='fila-info-sub'><StarsComponent valoracion={response.atqIssuesSolutionRate ? parseInt(response.atqIssuesSolutionRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">Productividad</span></td>
                            <td className='fila-info-sub'><StarsComponent valoracion={response.atqProductivityRate ? parseInt(response.atqProductivityRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">Comunicación</span></td>
                            <td className='fila-info-sub'><StarsComponent valoracion={response.atqCommunicationRate ? parseInt(response.atqCommunicationRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-info'><span className="title-medico">Puntualidad</span></td>
                            <td className='fila-info-sub'><StarsComponent valoracion={response.atqPuntualityRate ? parseInt(response.atqPuntualityRate) :0} /></td>
                        </tr>
                        <tr>
                            <td className='fila-finaly' colSpan={2}><span className="title-medico-finaly">{response.commentary ?response.commentary :'Comentarios del médico...'}</span></td>
                        </tr>
                    </table>
                </div>
            }
        </>
    );
};
