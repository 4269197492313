import shortid from "shortid";

export const storeHomeReceivingMaterialReportIncomingRigthInfoData = [
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
    {
        uid: shortid.generate(),
        Descripción: 'Addplus -d 12 mm, altura 13-18 mm, 0º',
        cantidad: 1,
        id_Producto: 'CS 2253-12-13'
    },
]