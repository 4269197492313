import shortid from "shortid";
import imgDisponibilidadAtq from '../../../assets/img/126PX/Icono_disponibilidadAtq.png';

export const InfoButton = [
    {
        uid: shortid.generate(),
        img: imgDisponibilidadAtq,
        name: 'Disponibilidad de ATQ’S',
        path: '/ATQAdministrator_atq_availability',
        
    },
]
    

