import React from 'react'
import CustomModal from '../../shared/Modal';
import { AddMembersComponent } from './AddMembersComponent';

export const Add_Members_Component_Modal = (props) => {
    const modalBody = () => {
        return (

            <>
                {props.openModalAddMembers === 'openListMembers' &&
                    <AddMembersComponent
                        toggle={props.toggle}
                        setOpenModal={props.setOpenModal}
                        setCxSelected={props.setCxSelected}
                        setOpenModalAddMembers={props.setOpenModalAddMembers}
                        openModalAddMembers={props.openModalAddMembers}
                        setOpenModalAdd={props.setOpenModalAdd}

                    />

                }

            </>


        );
    };


    return (
        <CustomModal
            isOpen={props.isOpen}
            toggle={props.handleClose ? props.handleClose : props.toggle}
            body={modalBody()}
            title={"Usuarios"}
        />

    );
}
