import React, { useState } from 'react'
import shortid from 'shortid';
import { StarQualificationComponent } from '../../components/StarQualification/StarQualificationComponent'
import { useForm } from '../../hooks/useForm';
import './style.scss';

export const SurveyMedic = () => {
  const variables = ['si','no'];
  const [seguimiento, setSeguimiento] = useState();
  const [comentarios, setComentarios] = useState();
  const [ formValuesInput, handleInputChangeInputs] = useForm({
    first: ''
});
const [ formValuesInputSecond, handleInputChangeInputsSecond] = useForm({
    second: ''
});
  const { first } = formValuesInput;
  const { second } = formValuesInputSecond;

  const surveyPoints = 
  [
    {
      uid: shortid.generate(),
      commentary: '¿Cómo califica el proceso de programación surtido de CX?',
      value:0,
      extraTitle:null
    },
    {
      uid: shortid.generate(),
      commentary: '¿Cómo califica el instrumento?',
      value:0,
      extraTitle:null
    },
    {
      uid: shortid.generate(),
      commentary: '¿Cómo califica los implantes y consumibles?',
      value:0,
      extraTitle:null
    },
    {
      uid: shortid.generate(),
      commentary: 'Dominio en técnica quirúrgica.',
      value:0,
      extraTitle:'Calificación del Servicio Técnico'
    },
    {
      uid: shortid.generate(),
      commentary: 'Solución de problemas',
      value:0,
      extraTitle:null
    },
    {
      uid: shortid.generate(),
      commentary: 'Productividad',
      value:0,
      extraTitle:null
    },
    {
      uid: shortid.generate(),
      commentary: 'Comunicación',
      value:0,
      extraTitle:null
    },
    {
      uid: shortid.generate(),
      commentary: 'Puntualidad',
      value:0,
      extraTitle:null
    },
  ]
  return (

    <div className='precontainer'>
        <div className='container-general-medic'>
            <section className='header-title-medic'>
                 <p className='text-title-medic'>Medicos</p>
            </section>
            <section className='body-surtido-medic'>
                <p className='subtext-title-medic' >¿Requiere visita del asesor comercial?</p>
                <div className='container-radio'>
                    {
                        variables.map(item => (
                            <>
                            <span className="item-name1">{item}</span>
                                <input
                                    className='input-radio1'
                                    type="radio"
                                    checked={first&& first === item}
                                    value = {item}
                                    name = 'first' 
                                    onChange={handleInputChangeInputs}
                                />
                            </>    
                        ))
                    }  
                  </div>
                <textarea className='textarea-medic' placeholder='Agregar comentario...'/>
            </section>
            <section className='body-surtido-medic'>
                <p className='subtext-title-medic' >¿Existe riesgo infeccioso en el paciente?</p>
                <div className='container-radio'>
                    {
                        variables.map(item => (
                            <>
                            <span className="item-name1">{item}</span>
                                <input
                                    className='input-radio1'
                                    type="radio"
                                    checked={second&& second === item}
                                    value = {item}
                                    name = 'second' 
                                    onChange={handleInputChangeInputsSecond}
                                />
                            </>    
                        ))
                    }  
                  </div>
                <textarea className='textarea-medic' placeholder='Agregar comentario...'/>
            </section>

            {
              surveyPoints.map( (item) => (
                <section className='body-surtido-medic'>
                {
                    item.extraTitle &&
                    <p className='subtext-extra-title-medic' >{item.extraTitle}</p>
                }
                    <p className='subtext-title-medic' >{item.commentary}</p>
                    <StarQualificationComponent 
                      array = {surveyPoints}
                    />
                </section>
              ))
            }
            <section className='body-surtido-medic'>
                <p className='subtext-title-medic' >Seguimiento de TSI:</p>
                <textarea className='textarea-medic' placeholder='Agregar comentario...'
                  onChange={(e) => setSeguimiento(e.target.value)}
                />
            </section>
            <section className='body-surtido-medic'>
                <p className='subtext-title-medic' >Comentarios del médico:</p>
                <textarea className='textarea-medic' placeholder='Agregar comentario...'
                  onChange={(e) => setComentarios(e.target.value)}
                />
            </section>
            <div className='container-button'>
              <button className='btn-enviar' >
                    ACEPTAR 
              </button>
            </div>
        </div>
    </div>
  )
}
