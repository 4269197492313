import React, { useEffect, useState } from 'react'
import imgUser from '../../assets/img/126PX/imgUser.png';
import { formatOnlyNotificacion } from '../../hooks/useFormatDateInitialAndFinalDay';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './sytleItemsNotification.scss'
import { useDispatch, useSelector } from 'react-redux';
import { getSearchListSurgery, openNotificacionGeneral, setChatGroupSelected, setChatSurgerySelected } from '../../actions/notification-chatAction';
import { getNextAppointments } from '../../actions/storeHouseAction';


export const NotificationItemsComponent = () => {

    const [openItemNotificacion, setOpenItemNotificacion] = useState(null);
    const [chatIdNotification, setChatIdNotification] = useState('');
    const [nameChatNotificacion, setNameChatNotificacion] = useState('')
    const [typeNotification, setTypeNotification] = useState('')
    const setSurgerySelected = useSelector((state) => state.notificationChat.setSurgeryChatSelected);
    const startDate = '2019-01-22T00:00:00';
    const endDate = '2024-01-22T23:59:00';

    const openNotificationGeneral = useSelector(state => state.notificationChat.openNotificationGeneral);

    const history = useHistory();
    const dispatch = useDispatch();


    // console.warn('chatID',chatID)

    const openChatNotification = () => {

        dispatch(openNotificacionGeneral(!openNotificationGeneral, "NOTIFICATION"))
        dispatch(setChatGroupSelected(true, chatIdNotification, nameChatNotificacion))
    }

    const diccionario = {
        1: "/ATQ_Material",
        2: "/ATQ_Material",
        3: "/ATQ_Material"

    }

    let NotificacionesData = []
    if (localStorage.getItem("Newnotificacion")) {
        /* console.log("Notificaciones",JSON.parse(localStorage.getItem("Newnotificacion"))) */
        NotificacionesData = JSON.parse(localStorage.getItem("Newnotificacion") || []);
    }

    const DataChatOpen = (item) => {
        setChatIdNotification(parseInt(item.ChatGroupId))
        setNameChatNotificacion(item.Title)
        setTypeNotification(item.NotificationAction)

    }

    const [notifications, setnotification] = useState(NotificacionesData)

    const openNotification = (item) => {
        DataChatOpen(item)
        dispatch(setChatSurgerySelected(parseInt(item.AppointmentId)))
        setOpenItemNotificacion(item)

        setnotification(notification => {
            return notification.map(i => {
                if (i.uid === item.uid) {
                    return {
                        ...item,
                        Estado: false,
                    }
                }
                return i;
            })
        })
    }



    useEffect(() => {
        return () => {
            localStorage.setItem("Newnotificacion", JSON.stringify(notifications))
        }
    }, [notifications])



    useEffect(() => {

        if (typeNotification && typeNotification === "1") {
            openChatNotification();
        } else if (typeNotification && typeNotification === "2") {
            dispatch(openNotificacionGeneral(!openNotificationGeneral, "NOTIFICATION"))
            dispatch(getNextAppointments(15, setSurgerySelected))
            history.push({
                pathname: "agenda/agenda",
                state: { id: setSurgerySelected }});

        }
        else if (typeNotification && typeNotification === "3") {
            dispatch(openNotificacionGeneral(!openNotificationGeneral, "NOTIFICATION"))
            dispatch(getNextAppointments(15, setSurgerySelected))
            history.push(`Mensajero_Material/${setSurgerySelected}`)
        }
    }, [openItemNotificacion])

   //console.log("setSelectedAgenda",setSelectedAgenda)


    const TimeNotification = (date) => {
        const inicio = moment(formatOnlyNotificacion(date))
        const final = moment().format();

        let resta = inicio.diff(final, 'months')

        if (resta !== 0) {
            let resultado = resta * -1;
            let mensaje = "";
            if (resultado > 1) {
                mensaje = "Hace " + resultado + " meses";
            } else {
                mensaje = "Hace " + resultado + " mes";
            }
            return mensaje;
        } else {
            resta = inicio.diff(final, 'weeks')
            if (resta !== 0) {
                let resultado = resta * -1;
                let mensaje = "";
                if (resultado > 1) {
                    mensaje = "Hace " + resultado + " semanas"
                } else {
                    mensaje = "Hace " + resultado + " semana"
                }
                return mensaje;
            } else {
                resta = inicio.diff(final, 'days')
                if (resta !== 0) {
                    let resultado = resta * -1;
                    let mensaje = "";
                    if (resultado > 1) {
                        mensaje = "Hace " + resultado + " dias";
                    } else {
                        mensaje = "Hace " + resultado + " dia";
                    }

                    return mensaje;
                } else {
                    resta = inicio.diff(final, 'hours')
                    if (resta !== 0) {
                        let resultado = resta * -1;
                        let mensaje = ""
                        if (resultado > 1) {
                            mensaje = "Hace " + resultado + " horas";
                        } else {
                            mensaje = "Hace " + resultado + " hora";
                        }
                        return mensaje;

                    } else {
                        resta = inicio.diff(final, 'minutes')

                        if (resta !== 0) {
                            let resultado = resta * -1;
                            let mensaje = "";

                            if (resultado > 1) {
                                mensaje = "Hace " + resultado + " minutos"
                            } else {
                                mensaje = "Hace " + resultado + " minuto";
                            }
                            return mensaje;
                        } else {
                            resta = inicio.diff(final, 'seconds');
                            let resultado = resta * -1;
                            let mensaje = ""

                            if (resultado !== 0) {

                                mensaje = "Hace un momento";
                                return mensaje;
                            } else {
                                mensaje = "Hace un momento";
                                return mensaje;
                            }
                        }
                    }
                }
            }
        }
    }

    return (
        <div className="MessagesChatComponent-container-notification">
            {
                notifications && notifications.map(item => (

                    <div key={item.uid} className={`${item.Estado ? "MessagesChatComponent-preContainer-notification MessagesChatComponent-preContainer-active-notification" : "MessagesChatComponent-preContainer-notification MessagesChatComponent-preContainer-inactive-notification"}`}
                        onClick={() => openNotification(item)}>
                        <div className="MessagesChatComponent-info-container-notification">
                            <img src={imgUser} alt="" className="MessagesChatComponent-info-imgUser-notification" />
                            <div className="MessagesChatComponent-container-info-notification" >
                                <h2 className="MessagesChatComponent-info-nameUser-notification" >{`${item.NotificationAction === "1"
                                    ? "Nuevo mensaje en " + item.Title
                                    : item.Title}`}</h2>
                                <h3 className="MessagesChatComponent-info-messageUser-notification" >{`${item.NotificationAction === "1"
                                    ? "Mensaje: " + item.Message
                                    : item.Message + ", ID: " + item.AppointmentId}`}</h3>
                                <h4 className="MessagesChatComponent-info-timeMsg-notification" >{TimeNotification(item.Tiempo)}</h4>
                            </div>
                        </div>
                    </div>

                ))
            }
        </div>
    )

}