import React, { useState } from 'react';
import './styleReque.scss';
import icoAdd from '../../../assets/img/126PX/IcoAgregarCi.png';
import PropTypes from 'prop-types';
import { InputRender } from '../Input/inputRender';
import { Messenger_Modal } from '../../../pages/Messenger/modals/Messenger_Modal';
export const Mess_medics_req_der = ({selected=null,page=null,selectedMedics=null,currentPage=0,setList1,selectedHospitals,readonlyPage=0}) => {

    const [openModal, setOpenModal] = useState(null);
    const [textRequirement, setTextRequirements] = useState(null);
    const toggleModal = () => {
        setOpenModal(null);
    };
    
        const handleAvailble = () => {
            setChangeState(!changeState)
        }

    return (
        
        <div className='container_der'>
        {
            selected &&              
                <div className='precontainer'>
                        <ol className='list-style'>
                            {   selected &&
                                selected.requirements &&
                                selected.requirements.map((item,i) =>(
                                    
                                        <li className='style-item'>
                                        
                                            <InputRender
                                               item = {item.requirement}
                                               id = {item.id} 
                                               setList1={setList1}
                                               page={page}
                                               selected = {selected.id}
                                               readonlyPage={readonlyPage}
                                               />
                                       
                                        </li>
                                ))
                            }
                
                        </ol>
                        {
                            readonlyPage === 0 &&
                                (<div className='sectionIcons'>
                                    <img className='icoAdd' src={icoAdd} onClick={() => setOpenModal("MODAL") }/>
                                </div>)

                        }
                        <button className='btnAceptar'>
                            ACEPTAR
                        </button>
                </div>
        }
        {
        openModal === "MODAL" &&
        <Messenger_Modal
                    isOpen
                    toggle={toggleModal}
                    page={page}
                    setTextRequirements ={setTextRequirements}
                    textRequirement = {textRequirement}
                    selectedMedics = {selectedMedics}
                    selectedHospitals = {selectedHospitals}
                    currentPage = {currentPage}
                    setList1={setList1}
                />
        }
        </div>
    )
}

Mess_medics_req_der.propTypes = {
    selected: PropTypes.any,
}
