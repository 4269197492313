/* eslint-disable react/prop-types */
import {
  Drawer,
  TablePagination,
  withStyles
} from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SearchBar from "material-ui-search-bar";
import 'rc-tooltip/assets/bootstrap.css';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSellers, getUserById } from "../../actions/allUsersAction";
import { getOrderBillingData, getOrderClientData, getOrderConfig, getOrderDeliveryData, getOrderDetails, getRemissionByIdAsync, getRemissionDetailAsync } from "../../actions/userActions";
import { GetOrderPDF } from "../../actions/systemsAction";
import regimenCatalog from '../../assets/catalogs/regimen.json';
import { SubNavBarComponent } from '../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import './sass/styles.scss';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#37A7B9',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#000000',
      opacity: 1,
    },
    '&$selected': {
      color: '#000000',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#000000',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
}));

export const RemissionDetails = (props) => {
  const previousRemission = props.location.state.remission || props.location.state.order

  const dispatch = useDispatch();
  const requestH = useSelector((state) => state.user.request);

  const [remissions, setRemissions] = useState()

  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('todos');

  function getArrayByString(cadena) {
    const array = cadena.split(',').map(item => parseInt(item.trim()));
    return array;
  }

  function getArrayByStringString(cadena) {
    const array = cadena.split(',').map(item => item.trim());
    return array;
  }


  const [orderId, setOrderId] = useState()
  const [seller, setSeller] = useState()

  const orderInformation = useSelector((state) => state.user.getOrderDetail);
  const orderClientData = useSelector((state) => state.user.getOrderClientData);
  const orderBilling = useSelector((state) => state.user.getOrderBilling);
  const orderDelivery = useSelector((state) => state.user.getOrderDelivery);
  const orderConfig = useSelector((state) => state.user.getOrderConfig);
  const getSellersInformation = useSelector((state) => state.allUsers.listSellers)
  const userById = useSelector((state) => state.allUsers.getUserById)

  console.log(orderClientData)
  console.log(orderBilling)
  console.log(orderDelivery)
  console.log(userById)


  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetails(orderId))
      dispatch(getOrderClientData(orderId))
      dispatch(getOrderBillingData(orderId))
      dispatch(getOrderDeliveryData(orderId))
      dispatch(getOrderConfig(orderId))
    }
  }, [orderId])

  useEffect(() => {
    if (orderDelivery) {
      if (orderDelivery && orderDelivery.sellerId && Number.isInteger(parseInt(orderDelivery.sellerId))) {
        dispatch(getUserById(orderDelivery.sellerId))
      } else {
        dispatch(getSellers(0, 10000))
      }
    } else {
      dispatch(getSellers(0, 10000))
    }
  }, [orderDelivery])

  useEffect(() => {
    getSellerInArray()
  }, [getSellersInformation])

  const getSellerInArray = () => {
    if (getSellersInformation && orderDelivery) {
      getSellersInformation.map((singleSeller) => {
        if (singleSeller.sellerId == orderDelivery.sellerId) {
          setSeller(singleSeller)
        }
      })
    }
  }

  useEffect(() => {
    getSellerWithId()
  }, [userById])

  const getSellerWithId = () => {
    if (userById) {
      setSeller(userById)
    }
  }

  useEffect(() => {
    if (orderInformation) {
      setRowsAll(groupAndSumProducts(orderInformation))
    }
  }, [orderInformation])

  function groupAndSumProducts(productsArray) {
    const groupedProducts = {};

    // Iterar sobre cada objeto en el array
    for (const product of productsArray) {
      const productCode = product.product.productCode;

      // Si el producto aún no está en el objeto agrupado, crear una entrada para él
      if (!groupedProducts[productCode]) {
        groupedProducts[productCode] = {
          ...product,
          quantity: 0,
          quantityPending: 0
        };
      }

      // Sumar las cantidades y cantidades pendientes para el producto
      groupedProducts[productCode].quantity += product.quantity;
      groupedProducts[productCode].quantityPending += product.quantityPending;
    }

    // Convertir el objeto agrupado en un array de resultados
    const groupedArray = Object.values(groupedProducts);

    return groupedArray;
  }


  useEffect(() => {
    getRemissionInformation()
  }, [])

  function getProductDescriptionByCode(productCode, array) {
    for (const item of array) {
      if (item.product.productCode === productCode) {
        return item.product.productDescription;
      }
    }
    return null; // Retorna null si no se encuentra el productCode en el array
  }


  const getRemissionInformation = async () => {
    const remissions = getArrayByString(previousRemission.remissions)

    const previousDeliveryShipments = getArrayByStringString(previousRemission.deliveryGuides)

    const remissionInformation = []
    for (const [index, singleRemission] of remissions.entries()) {
      await getRemissionByIdAsync(singleRemission, requestH).then(async (res) => {
        await getRemissionDetailAsync(singleRemission, requestH).then((resDetail) => {
          res.response.details = resDetail.response
          res.response.shipmentInformation = previousDeliveryShipments[index]
        })
        remissionInformation.push(res.response)
      })
    }
    setOrderId(remissionInformation[0].orderId)
    setRemissions(remissionInformation)
  }


  function getRegimenByCode(arr, code) {
    const item = arr.find(item => item.code === code);
    return item ? item.value : null;
  }

  function formatearFechaHora(cadena) {
    const meses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const fechaHora = new Date(cadena);
    const dia = fechaHora.getDate();
    const mes = meses[fechaHora.getMonth()];
    const anio = fechaHora.getFullYear();
    const hora = fechaHora.getHours();
    const minutos = fechaHora.getMinutes().toString().padStart(2, '0');

    return `${dia}/${mes}/${anio} ${hora}:${minutos}`;
  }

  /* Drawer Client */
  const [stateDrawerClient, setStateDrawerClient] = useState(false);

  const toggleDrawerClient = () => {
    setStateDrawerClient(!stateDrawerClient);
  };


  /* TAB SECTION */
  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  /* Tabla Clientes */
  const columnsAll = [
    { id: 'select', label: '', minWidth: '5%', align: 'center', background: 'red' },
    { id: 'id', label: 'ID', minWidth: '10%', align: 'left', background: 'green' },
    { id: 'producto', label: 'PRODUCTO', minWidth: '60%', align: 'left', background: 'blue' },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: '10%', align: 'center', background: 'yellow' },
    { id: 'surtir', label: 'POR SURTIR', minWidth: '10%', align: 'center', background: 'orange' },
    /* { id: 'more', label: '', minWidth: '5%', align: 'center', background: 'purple' } */
  ];

  const [rowsAll, setRowsAll] = useState([]);
  const [rowsAllOriginal, setRowsAllOriginal] = useState([]);
  const [rowsPerPageAll, setRowsPerPageAll] = useState(7);
  const [pageAll, setPageAll] = useState(0);

  const handleChangePageAll = (event, newPage) => {
    setPageAll(newPage);
  };

  const handleChangeRowsPerPageAll = (event) => {
    setRowsPerPageAll(+event.target.value);
    setPageAll(0);
  };

  /* Tabla solicitud */
  const columnSolicitud = [
    { id: 'select', label: '', minWidth: '5%', align: 'center', background: 'red' },
    { id: 'id', label: 'ID', minWidth: '10%', align: 'left', background: 'green' },
    { id: 'producto', label: 'PRODUCTO', minWidth: '80%', align: 'left', background: 'yellow' },
    { id: 'cantidad', label: 'CANTIDAD', minWidth: '5%', align: 'center', background: 'blue' },
    /* { id: 'more', label: '', minWidth: '10%', align: 'center', background: 'orange' } */
  ];

  const [rowsSolicitud, setRowsSolicitud] = useState([]);
  const [rowsSolicitudOriginal, setRowsSolicitudOriginal] = useState([]);
  const [rowsPerPageSolicitud, setRowsPerPageSolicitud] = useState(7);
  const [pageSolicitud, setPageSolicitud] = useState(0);

  const handleChangePageSolicitud = (event, newPage) => {
    setPageSolicitud(newPage);
  };

  const handleChangeRowsPerPageSolicitud = (event) => {
    setRowsPerPageSolicitud(+event.target.value);
    setPageSolicitud(0);
  };

  const getPDFByIDOrder = (orderId) => {
    dispatch(GetOrderPDF(orderId));
  };

  return (
    <div style={{
      width: '100%',
      height: '100%'
    }}>
      <SubNavBarComponent searchActive={false} typeSearch={'none'} specificType={'listaPrecios'} historyPage={`/remision`} title={`Detalle Pedido`} />
      <div >

        {/* TopBar */}
        <div style={{ display: 'flex', background: 'transparent', marginTop: '16vh' }}>{/*  background: '#f0f0f0', */}
          <div style={{ width: '70%', marginTop: '2rem' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', justifyContent: 'space-around' }}>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Tipo y número de solicitud</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{`${orderId && orderId || ''} - Pedido`}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Vendedor</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{seller ? `${seller.firstName} ${seller.secondName} ${seller.firstLastName} ${seller.secondLastName}` : 'Martin Miranda Juarez'}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '30%' }}>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Domicilio de Entrega</p>
                    </div>
                    <div style={{ marginBottom: 15, marginLeft: 15 }}>
                      <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderDelivery ? `${orderDelivery.address}` : 'Aguas calientes rep. de Urugay 912...'}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', marginBottom: '20px' }}>
              <div style={{ width: '90%' }}>
                <div style={{ marginTop: '20px', marginLeft: '20px', marginBottom: '20px', width: '90%' }}>
                  <SearchBar
                    placeholder={'Buscar'}
                    style={{
                      width: '100%',
                      height: '46px',
                      boxSizing: 'border-box',
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div style={{ maxHeight: '60vh', overflow: 'scroll', marginBottom: '8rem' }}>
          <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>

            <AntTabs value={valueTab} onChange={handleChangeTab} aria-label="ant example">
              <AntTab label="LISTA DE PRODUCTOS" />
              {remissions && remissions.length > 0 && remissions.map((singleRemission, index) => {
                return (
                  <AntTab label={`REMISION ${index + 1}`} />
                )
              })}
            </AntTabs>
          </div>

          {/* Tabla Cliente */}
          {valueTab == 0 &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columnsAll.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            width={column.minWidth}
                          >
                            <div style={{ display: 'flex', justifyContent: column.align }}>
                              {id == 'select'}
                              {id == 'surtir' && <ImportExportIcon style={{ color: '#484f59' }} />}
                              {column.label}
                              {id == 'producto' && <ImportExportIcon style={{ color: '#484f59' }} />}
                              {/* {id == 'more' && <MoreVertIcon style={{ color: '#484f59' }} />} */}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsAll && rowsAll.slice(pageAll * rowsPerPageAll, pageAll * rowsPerPageAll + rowsPerPageAll).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnsAll.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              width={column.minWidth}
                            >
                              {column.id == 'select'}
                              {column.id == 'id' && row.product.productCode}
                              {column.id == 'producto' && row.product.productDescription}
                              {column.id == 'cantidad' && row.quantity}
                              {column.id == 'surtir' && row.quantityPending}

                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[7, 10, 25, 100]}
                component="div"
                count={rowsAll ? rowsAll.length : 0}
                rowsPerPage={rowsPerPageAll}
                page={pageAll}
                onChangePage={handleChangePageAll}
                onChangeRowsPerPage={handleChangeRowsPerPageAll}
              />
            </div>
          }

          {remissions && remissions.length > 0 && valueTab > 0 &&
            <div style={{ marginTop: 50, margin: '40px auto 40px auto', width: '90%', position: 'relative' }}>
              <div style={{ display: 'flex', background: 'transparent' }}>{/*  background: '#f0f0f0', */}
                <div style={{ width: '70%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px', justifyContent: 'space-around' }}>
                    <div style={{ width: '24%' }}>
                      <div style={{ marginBottom: '20px' }}>
                        <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                          <div style={{ marginTop: 15, marginLeft: 15 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Fecha de surtido</p>
                          </div>
                          <div style={{ marginBottom: 15, marginLeft: 15 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{formatearFechaHora(remissions[valueTab - 1].remissionDate)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '24%' }}>
                      <div style={{ marginBottom: '20px' }}>
                        <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                          <div style={{ marginTop: 15, marginLeft: 15 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Folio</p>
                          </div>
                          <div style={{ marginBottom: 15, marginLeft: 15 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{'231236'}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '24%' }}>
                      <div style={{ marginBottom: '20px' }}>
                        <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                          <div style={{ marginTop: 15, marginLeft: 15 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '15px' }}>Status</p>
                          </div>
                          <div style={{ marginBottom: 15, marginLeft: 15 }}>
                            <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{remissions[valueTab - 1].shipmentInformation}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      remissions[valueTab - 1].shipmentInformation != 'Sin enviar' &&
                      <div style={{ width: '24%' }}>
                        <div style={{ marginBottom: '20px' }}>
                          <div style={{ borderRadius: 8, display: 'flex', flexDirection: 'column', background: '#d9d9d9' }}>
                            <div style={{ marginTop: 15, marginLeft: 15 }}>
                              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>N° Guia</p>
                            </div>
                            <div style={{ marginBottom: 15, marginLeft: 15 }}>
                              <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{'HASD778123K'}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <TableContainer className={classes.container} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" size='small'>
                  <TableHead>
                    <TableRow>
                      {columnSolicitud.map((column) => {
                        const id = column.id
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            width={column.minWidth}
                          >
                            <div style={{ display: 'flex', justifyContent: column.align }}>
                              {id == 'select'}
                              {/* {id == 'surtir' && <ImportExportIcon style={{ color: '#484f59' }} />} */}
                              {column.label}
                              {/* {id == 'producto' && <ImportExportIcon style={{ color: '#484f59' }} />}
                              {id == 'more' && <MoreVertIcon style={{ color: '#484f59' }} />} */}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {remissions && remissions[valueTab - 1].details.length > 0 && remissions[valueTab - 1].details.slice(pageSolicitud * rowsPerPageSolicitud, pageSolicitud * rowsPerPageSolicitud + rowsPerPageSolicitud).map((row) => {
                      console.log(row)
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columnSolicitud.map((column) => {
                            const type = row.tipo
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                width={column.minWidth}
                              >
                                {column.id == 'select'}
                                {column.id == 'id' && row.code}
                                {column.id == 'producto' && getProductDescriptionByCode(row.code, rowsAll)}
                                {column.id == 'cantidad' && row.quantity}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[7, 10, 25, 100]}
                component="div"
                count={rowsSolicitud ? rowsSolicitud.length : 0}
                rowsPerPage={rowsPerPageSolicitud}
                page={pageSolicitud}
                onChangePage={handleChangePageSolicitud}
                onChangeRowsPerPage={handleChangeRowsPerPageSolicitud}
              />
            </div>
          }

        </div>



        {/* Drawer Client */}
        <Drawer anchor={'left'} open={stateDrawerClient} onClose={toggleDrawerClient} PaperProps={{
          sx: { width: "20%" },
        }}>

          <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontFamily: 'arial', fontSize: '30px', marginTop: 20, fontWeight: 'bold' }}>Datos del cliente</p>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Cliente</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.clientName || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', marginTop: 20, fontWeight: 'bold' }}>Correo</p>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <p style={{ fontFamily: 'arial', fontSize: '20px' }}>{orderClientData && orderClientData.email || ''}</p>
              </div>
            </div>

            <div style={{ marginTop: '30px', marginLeft: '30px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Facturación</p>
            </div>

            {/* console.log(orderClientData)
            console.log(orderBilling)
            console.log(orderDelivery)
            console.log(userById) */}

            <div style={{ marginTop: '20px', marginLeft: '0px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>RFC</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderBilling ? orderBilling.billingRFC : ''}</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Uso de CFDI</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.cfdi : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Regimen Fiscal</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderBilling ? orderBilling.billingRaxRegime : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Condicion de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentCondition : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Metodo de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentMethod : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Forma de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentForm : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Numero de Recibo de Pago</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.paymentReceipt : ''}</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '20px', fontWeight: 'bold' }}>Datos de Envio</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Instrucciones o referecias</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.referencesAddress : ''}</p>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Direccion de Envio</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderClientData ? orderClientData.address : ''}</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
              <p style={{ fontFamily: 'arial', fontSize: '14px', fontWeight: 'bold' }}>Obervaciones</p>
              <p style={{ fontFamily: 'arial', fontSize: '15px' }}>{orderDelivery ? orderDelivery.warehouseMessage : ''}</p>
            </div>

          </div>
        </Drawer>

        <div style={{
          width: '100%',
          height: '10vh',
          position: 'fixed',
          bottom: '0',
          alignSelf: 'center',
          zIndex: 1
        }}>
          <div style={{
            background: '#e5e9f2',
            height: '6vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }} >
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>

              <div style={{ width: '25%' }}>
                <div style={{
                  marginTop: 10, marginRight: 80,
                  background: '#d9d9d9',
                  color: '#fff',
                  width: '100%',
                  height: '40%',
                  padding: '.5vw',
                  borderRadius: '50',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 30
                }} onClick={toggleDrawerClient}>
                  <AccountCircleIcon style={{ marginRight: 20, color: 'black' }} />
                  <p style={{ fontFamily: 'arial', fontWeight: 'bold', color: 'black' }}>Datos del Cliente</p>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'end' }}>
              {inputValue == 'todos' &&
                <>
                  <div style={{
                    marginTop: 10, marginRight: 80,
                    background: 'linear-gradient(90deg, rgba(15, 65, 111, 1) 0%, rgba(56, 168, 185, 1) 100%)',
                    color: '#fff',
                    minWidth: '20%',
                    maxWidth: '30%',
                    height: '40%',
                    padding: '.5vw',
                    cursor: 'pointer',
                    border: 'none',
                    borderRadius: '2vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <p style={{ fontFamily: 'arial', fontWeight: 'bold' }} onClick={() => {getPDFByIDOrder(orderId)}}>Descargar</p>
                  </div>
                </>
              }
            </div>

          </div>
        </div>
      </div >
    </div >
  );
};
