import React, { useState } from 'react';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseStockLeftButtons } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockLeftButtons';
import '../../../pages/StoreHouseScreen/StoreHouseStockScreen/sass/styles.scss';
import { StoreHouseRigthComponentEncuestas } from '../../../components/StoreHouseComponents/StoreHouseStockEncuestasComponents/StoreHouseRigthComponentEncuestas';



export const StoreHouseReceivingEncuestas = () => {
  const [activeSwitch, setActiveSwitch] = useState('ACTIVE');
  const [from,setFrom] = useState('dad');
  const handleChange  = (item) => {
    setFrom('dad')
      if(item === 'active'){
        setActiveSwitch('ACTIVE')
      } else{
        setActiveSwitch('INACTIVE')
      }
  }


  return (
    <div className="container_storeHouseStock">
      <SubNavBarComponent title={'ENCUESTA'}
        searchActive={true}
        historyPage={'/Inicio'}
        typeSearch={'SurveyPending'}
        specificType={'id'} />


      <section className="storeHouseStock_container">
        <div className="storeHouseStock_container_toggle">
          <nav className="storeHouseStock_toggle_container_buttons">
            <button className={activeSwitch === 'ACTIVE'
              ? "storeHouseStock_toggle_button storeHouseStock_toggle_button_left storeHouseStock_toggle_button_active "
              : "storeHouseStock_toggle_button storeHouseStock_toggle_button_left "} onClick={() => handleChange('active')}>
              PENDIENTES

            </button>
            <button className={activeSwitch === 'INACTIVE'
              ? "storeHouseStock_toggle_button storeHouseStock_toggle_button_rigth storeHouseStock_toggle_button_active"
              : "storeHouseStock_toggle_button storeHouseStock_toggle_button_rigth"} onClick={() => handleChange('inactive')}>

              FINALIZADAS
            </button>
          </nav>
        </div>

        <div className=" storeHouseStock_container_elements ">
          <StoreHouseStockLeftButtons page={"StoreHouseStockScreen"} />

          <StoreHouseRigthComponentEncuestas activeSwitch={activeSwitch} 
            setActiveSwitch={setActiveSwitch}
            setFrom = {setFrom}
            from = {from}
          />
        </div>

      </section>
    </div>
  )
};
