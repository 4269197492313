import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../../../../components/shared/Modal';
import './style_Share.scss';
import { Search_Share } from './Search_Share';

export const ATQ_Modal_Share = (props) => {
    const modalBody = () => {
        return (
        <Search_Share   
            toggle={props.toggle}
            setOpenModal={props.setOpenModal}
            setCxSelected  = {props.setCxSelected}
            description = {props.description}
            type = {props.type}

        />
    );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.handleClose ? props.handleClose : props.toggle} 
                body={modalBody()}
        />
    );
}

ATQ_Modal_Share.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
};