import React from 'react'
import { useSelector } from 'react-redux';
import './styleEstatus.scss';

export const EstatusComponent = ({typeEstatus,setTypeEstatus,type}) => {
    const user = useSelector(state => state.user.user.identity);
/*     console.warn(typeEstatus); */

    return (

        <section className='container-component-comments-Estatus'>
            <div className='header-commentsEstatus'>
                <div className='header-titleEstatus'>
                    <p className='title-componentEstatus'>ESTATUS *</p>
                </div>
            </div>

            <div className='body-Estatus'>

                <div className='container-Estatus'>

                <select  
                className='select-Estatus'
                value={typeEstatus}
                onChange={(e) => setTypeEstatus(parseInt(e.target.value))}
                disabled={(user.roleID === 9 || type === "sinRespuesta" ) ?true  :false}
                 multiple={false}
                >

                <option selected value={0}>Seleccionar</option>
                    <option value={3} >En Seguimiento</option>
                    <option value={4} >Cerradas</option>
                </select>

                </div>
            </div>

        </section>
    )
}
