import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAppointmentsStoreHouse, getAppointmentsStoreHouseSuccess, setLocationProductToListStoreHose, getProductsToAppointmentStoreHoseSuccess, getAppointmentLocationsStoreHoseSuccess, addSellerNotes, addSurgeryAppointmentLogisticsNoteStoreHouseStore, requerimentsListToAproveState, setListApproveOrderPreViewStoreHose, getSearchListHouseSuccessAtq, setNewProductsAndSystems } from '../../../actions/storeHouseAction';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import { StoreHouseStockLeftButtons } from '../../../components/StoreHouseComponents/StoreHouseStockComponent/StoreHouseStockLeftButtons';
import { StoreHouseStockListItemsComponent } from '../../../components/StoreHouseComponents/StoreHouseStockListItemsComponent/StoreHouseStockListItemsComponent';
import moment from 'moment';
import './sass/styles.scss';
import { useParams } from 'react-router-dom';
import { getSearchList } from '../../../actions/allUsersAction';


export const StoreHouseStockScreen = () => {

    const { Logistics } = useParams();
    const [activeSwitch, setActiveSwitch] = useState('INACTIVE');
    const [searchText, setSearchText] = useState("");
    const [currentPage, setPage] = useState(0);
    const startDate = '1900-01-01T01:01:57'
    const endDate = '2200-05-06T01:01:33';
    const offset = 10;
    const dateToday = new Date();
    const render = useRef(0);
    const [list, setList] = useState();


    useEffect(() => {
        if (Logistics) {
            setActiveSwitch('ACTIVE');
        }
    }, [Logistics])


    const dispatch = useDispatch();

    const handleAddDateForm = (date) => {
        return `${moment(date).format('YYYY')}-${moment(date).format('MM')}-${moment(date).format('DD')}T${moment(date).format('HH')}:${moment(date).format('MM')}:${('00' + new Date().getUTCSeconds()).slice(-2)}`
    }
    useEffect(() => {
        handleChangeList();
        return () => {
            dispatch(getAppointmentsStoreHouseSuccess(null));
        };
    }, [activeSwitch]);

    useEffect(() => {
         dispatch(setNewProductsAndSystems([]))
        dispatch(setLocationProductToListStoreHose(null));
        dispatch(getProductsToAppointmentStoreHoseSuccess(null));
        dispatch(getAppointmentLocationsStoreHoseSuccess(null));
        dispatch(addSellerNotes(null));
        dispatch(addSurgeryAppointmentLogisticsNoteStoreHouseStore(null));
        dispatch(requerimentsListToAproveState(null));
        dispatch(setListApproveOrderPreViewStoreHose(null));
        return () => {
            dispatch(getSearchListHouseSuccessAtq([]))
          }
    }, [])

    

    const handleChangeList = () => {
        if (render.current >= 2) {
            setList(null)
            activeSwitch === 'INACTIVE' && !Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    handleAddDateForm(dateToday),
                    endDate, 11, '', currentPage, offset));
            activeSwitch === 'ACTIVE' && !Logistics && //YA NO OCUPAS DECISION EN ESTO YA QUE SE LAS TRAE TODAS DESDE AL PRINCIPIO
                dispatch(getAppointmentsStoreHouse(
                    startDate,
                    endDate, 10, '', currentPage, offset));
            activeSwitch === 'INACTIVE' && Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    startDate,
                    endDate, 12, '', currentPage, offset));
            activeSwitch === 'ACTIVE' && Logistics &&
                dispatch(getAppointmentsStoreHouse(
                    handleAddDateForm(dateToday),
                    endDate, 13, '', currentPage, offset));
        } else {
            render.current = render.current + 1;
        }
    }
    const handleChangeSwitch = () => {

            if(activeSwitch === 'ACTIVE'){
                setActiveSwitch('INACTIVE')
            }else{
                setActiveSwitch('ACTIVE')
            }
            dispatch(getSearchListHouseSuccessAtq(null))
    }

    /* const handleSearchProduct = (e) => {
        e.preventDefault();
        // console.log(searchText)
    }
     */

    return (
        <div className="container_storeHouseStock">
            {
                !Logistics &&
                <SubNavBarComponent title={'SURTIR CX'} historyPage={`/Inicio`} typeSearch={"surtirCX"} searchActive={true} specificType={'id'} />
            }
            {
                Logistics &&
                <SubNavBarComponent title={'LOGÍSTICA'} historyPage={`/Inicio`} typeSearch={"surtirCX"} searchActive={true} specificType={'id'} />
            }


            <section className="storeHouseStock_container">

                <div className="storeHouseStock_container_toggle">
                    {/* <form   className='storeHouseStock_toggle_container-navbar-c-search'
                        onSubmit={handleSearchProduct}>
                    <div className='storeHouseStock_toggle_container-navbar-c-search-img'> 
                        <img className='storeHouseStock_toggle_container-navbar-search-img'src={icoSearch} alt=""/> 
                    </div>
                    
                    <input className='storeHouseStock_toggle_container-navbar-search-input' 
                        name="searchProduct"
                        value={searchText}
                        type="text"
                        onChange={({ target }) => setSearchText(target.value)}
                        autoComplete='off'
                        placeholder='¿ Qué estas buscando ?'
                    />
                </form> */}
                    <nav className="storeHouseStock_toggle_container_buttons">
                        <button className={activeSwitch === 'ACTIVE'
                            ? "storeHouseStock_toggle_button storeHouseStock_toggle_button_left storeHouseStock_toggle_button_active "
                            : "storeHouseStock_toggle_button storeHouseStock_toggle_button_left "} onClick={() => handleChangeSwitch()}>
                            {!Logistics && `SURTIDAS`}
                            {Logistics && `ASIGNAR`}
                        </button>
                        <button className={activeSwitch === 'INACTIVE'
                            ? "storeHouseStock_toggle_button storeHouseStock_toggle_button_rigth storeHouseStock_toggle_button_active"
                            : "storeHouseStock_toggle_button storeHouseStock_toggle_button_rigth"} onClick={() => handleChangeSwitch()}>
                            {!Logistics && `NO SURTIDAS`}
                            {Logistics && `ASIGNADAS`}
                        </button>
                    </nav>
                </div>

                <div className=" storeHouseStock_container_elements ">

                    <StoreHouseStockLeftButtons page={"StoreHouseStockScreen"} />

                    <StoreHouseStockListItemsComponent activeSwitch={activeSwitch}
                        list={list}
                        setList={setList}
                        setActiveSwitch={setActiveSwitch}
                    />
                </div>
            </section>
        </div>
    )
}
