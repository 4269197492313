import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './sass/styles.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getAppoimentSpecifAtq, setSurgeryChoosed } from '../../../actions/atqAdministradorAction';
import { getAppointmentsStoreHouseAtq, getAppointmentsStoreHouseSuccessAtq, getSearchCxTransferedSuccess, getSearchListHouseSuccessAtq, searchCxTransfered } from '../../../actions/storeHouseAction';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';


export const ATQAdmin_Tranferir_CX_List_Component = ({ activeSwitch }) => {

    const startDate = '1900-01-01T01:01:57';
    const endDate = '2200-05-06T01:01:33';
    const searchBy2 = 'ATQ_ASSIGNED';
    const distpatch = useDispatch();
    const listApoiment = useSelector(state => state.storeHouse.appointmentsListATQ);
    const listSearch = useSelector(state => state.storeHouse.listSearch);
    const appoimentList = useSelector(state => state.atqAdministrador.AppoimentListSpecific);
    const searchList = useSelector(state => state.storeHouse.searchTransfered)

    const offset = 10;
    const render = useRef(0);
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState(null);
    const [listTwo, setListTwo] = useState(null);
    const history = useHistory();

    const handleNivagate = (item) => {
        if (activeSwitch === "ACTIVE") {
            distpatch(setSurgeryChoosed(item));
            history.replace(`/ATQAdministrator_Material_History/transfer_CX/transfer`);
        }
    };

    useEffect(() => {

        return () => {
            distpatch(getAppointmentsStoreHouseSuccessAtq(null));
            distpatch(getSearchListHouseSuccessAtq(null));
            distpatch(getSearchCxTransferedSuccess(null)); 
        }
    }, [])

    useEffect(() => {
        if (currentPage === 0) {
            if (activeSwitch === "ACTIVE") {
                distpatch(getAppointmentsStoreHouseAtq(startDate, endDate, searchBy2, null, currentPage, offset))
            }
            if (activeSwitch === "INACTIVE") {
                distpatch(getAppoimentSpecifAtq(null, 'ANY'))
            }
        } else if (currentPage > 0 && listApoiment !== null) {
            if (activeSwitch === "ACTIVE") {
                distpatch(getAppointmentsStoreHouseAtq(startDate, endDate, searchBy2, null, currentPage, offset))
            }
            if (activeSwitch === "INACTIVE") {
                distpatch(getAppoimentSpecifAtq(null, 'ANY'))
            }
        }
    }, [currentPage])

    //ESTE ES PARA USAR EL LIST 
    useEffect(() => {
        if (list && list.length > 0 && listApoiment.length > 0) {
            setList([...list, ...listApoiment])
        } else if (listApoiment && listApoiment.length > 0) {
            setList(listApoiment)
        }
    }, [listApoiment])


    //EL METODO SIGUIENTE ES PARA CUANDO SE HACE ALGUNA MODIFICACION

    useEffect(() => {
        if (listTwo && listTwo.length > 0 && appoimentList.length > 0) {
            setListTwo([...listTwo, ...appoimentList])
        } else if (appoimentList && appoimentList.length > 0) {
            setListTwo(appoimentList)
        }
    }, [appoimentList]);


    //AQUI ES PARA CUANDO CAMBIA DE BOTON
    useEffect(() => {

        if (render.current >= 0) {
            setList(null);
            setListTwo(null)
            setPage(0);
            distpatch(getAppointmentsStoreHouseSuccessAtq([]));  
            distpatch(getSearchListHouseSuccessAtq(null))    
            distpatch(getSearchCxTransferedSuccess(null))   

            if (currentPage === 0) {
                if (activeSwitch === "ACTIVE") {
                    distpatch(getAppointmentsStoreHouseAtq(startDate, endDate, searchBy2, null, currentPage, offset))
                }
                if (activeSwitch === "INACTIVE") {
                    distpatch(getAppoimentSpecifAtq(null, 'ANY'))
                    setList(null);
                }
            }
        } else {
             setList(null);
             setListTwo(null)
            render.current = 1;
        }
    }, [activeSwitch])

    //scroll
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }
    }

    const convertedStatus = (num) => {
        switch (num) {
            case 1:
                return 'PROCESO';
                break;
            case 2:
                return 'ACEPTADA';
                break;
            case 3:
                return 'REACHAZADA';
                break;

            default:
                return 'PROCESO'
                break;
        }
    }



    return (
        <div className="preconter_list_cx" onScroll={activeSwitch === "INACTIVE" ?'' :handleScroll} >
            {
               
                activeSwitch === "ACTIVE" &&
                (!listSearch
                    ? (listApoiment &&
                        list !== null &&
                        list.map(item => (
                            <div className="content_info_cx" key={item.id} onClick={() => handleNivagate(item)}>
                                <div className="text_info_cx">
                                    <span className="info_date_numberPqx_cx">
                                        <p>{item.surgeryDate && (formatDateToPresent(item.surgeryDate) + ' - ' + formatHourToPresent(item.surgeryDate)) + ' - '}</p>
                                        <p>{item.medic && ((item.medic.name && item.medic.name) + ' ' +
                                            (item.medic.secondName && item.medic.secondName) + ' ' +
                                            (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                            (item.medic.secondLastname && item.medic.secondLastname)) + ' - '}</p>
                                        <p>{item.hospital && item.hospital.name}</p>
                                    </span>
                                    <span className="info_date_numberPqx_cx">
                                        <p>
                                            {'ATQ ' + item.atq !== null && item.atq.length > 0 && ((item.atq.firstName && item.atq.firstName) + ' ' + (item.atq.secondName && item.atq.secondName)
                                                + ' ' + (item.atq.firstLastName && item.atq.firstLastName) + ' ' + (item.atq.secondLastName && item.atq.secondLastName))}
                                        </p>
                                        <p>
                                            {'CX#' + item.id}
                                        </p>
                                    </span>
                                </div>
                                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                            </div>
                        )))

                    :
                    (
                        listSearch.map(item => (
                            <div className="content_info_cx" key={item.id} onClick={() => handleNivagate(item)}>
                                <div className="text_info_cx">
                                    <span className="info_date_numberPqx_cx">
                                        <p>{item.surgeryDate && (formatDateToPresent(item.surgeryDate) + ' - ' + formatHourToPresent(item.surgeryDate)) + ' - '}</p>
                                        <p>{item.medic && ((item.medic.name && item.medic.name) + ' ' +
                                            (item.medic.secondName && item.medic.secondName) + ' ' +
                                            (item.medic.firstLastname && item.medic.firstLastname) + ' ' +
                                            (item.medic.secondLastname && item.medic.secondLastname)) + ' - '}</p>
                                        <p>{item.hospital && item.hospital.name}</p>
                                    </span>
                                    <span className="info_date_numberPqx_cx">
                                        <p>
                                            {'ATQ ' + item && item.atq !== null && ((item.atq.firstName && item.atq.firstName) + ' ' + (item.atq.secondName && item.atq.secondName)
                                                + ' ' + (item.atq.firstLastName && item.atq.firstLastName) + ' ' + (item.atq.secondLastName && item.atq.secondLastName))}
                                        </p>
                                        <p>
                                            {'CX#' + item.id}
                                        </p>
                                    </span>
                                </div>
                                <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                            </div>
                        ))))
            }


            {
                activeSwitch === "INACTIVE" &&
                (!searchList ?
                    (appoimentList &&
                        listTwo !== null &&
                        listTwo.map(item => (
                            <div className="content_info_cx" key={item.id}>
                                <div className="text_info_cx">
                                    <span className="info_date_numberPqx_cx">
                                        <p>{item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) + ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' - '}</p>
                                        <p>{item.surgeryAppointment.medic && ((item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                            (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                            (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                            (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)) + ' - '}</p>
                                        <p>{item.surgeryAppointment.hospital && item.surgeryAppointment.hospital.name}</p>
                                    </span>
                                    <span className="info_date_numberPqx_cx">
                                        <p>
                                            {'ATQ ' + item.atqOrigin !== null && ((item.atqOrigin.firstName && item.atqOrigin.firstName) + ' ' + (item.atqOrigin.secondName && item.atqOrigin.secondName)
                                                + ' ' + (item.atqOrigin.firstLastName && item.atqOrigin.firstLastName) + ' ' + (item.atqOrigin.secondLastName && item.atqOrigin.secondLastName))}
                                        </p>
                                        <p>

                                            {'CX#' + item.surgeryId}
                                        </p>
                                        <p className="txt_status"> {convertedStatus(item.surgeryTransferStatus)}</p>
                                        <p>
                                            {item.atqTarget !== null && ((item.atqTarget.firstName && item.atqTarget.firstName) + ' ' + (item.atqTarget.secondName && item.atqTarget.secondName)
                                                + ' ' + (item.atqTarget.firstLastName && item.atqTarget.firstLastName) + ' ' + (item.atqTarget.secondLastName && item.atqTarget.secondLastName))}
                                        </p>
                                    </span>
                                </div>
                            </div>
                        )))

                    :
                    (searchList.map(item => (
                            <div className="content_info_cx" key={item.id}>
                                <div className="text_info_cx">
                                    <span className="info_date_numberPqx_cx">
                                        <p>{item.surgeryAppointment.surgeryDate && (formatDateToPresent(item.surgeryAppointment.surgeryDate) + ' - ' + formatHourToPresent(item.surgeryAppointment.surgeryDate)) + ' - '}</p>
                                        <p>{item.surgeryAppointment.medic && ((item.surgeryAppointment.medic.name && item.surgeryAppointment.medic.name) + ' ' +
                                            (item.surgeryAppointment.medic.secondName && item.surgeryAppointment.medic.secondName) + ' ' +
                                            (item.surgeryAppointment.medic.firstLastname && item.surgeryAppointment.medic.firstLastname) + ' ' +
                                            (item.surgeryAppointment.medic.secondLastname && item.surgeryAppointment.medic.secondLastname)) + ' - '}</p>
                                        <p>{item.surgeryAppointment.hospital && item.surgeryAppointment.hospital.name}</p>
                                    </span>
                                    <span className="info_date_numberPqx_cx">
                                        <p>
                                            {'ATQ ' + item.atqOrigin !== null && ((item.atqOrigin.firstName && item.atqOrigin.firstName) + ' ' + (item.atqOrigin.secondName && item.atqOrigin.secondName)
                                                + ' ' + (item.atqOrigin.firstLastName && item.atqOrigin.firstLastName) + ' ' + (item.atqOrigin.secondLastName && item.atqOrigin.secondLastName))}
                                        </p>
                                        <p>

                                            {'CX#' + item.surgeryId}
                                        </p>
                                        <p className="txt_status"> {convertedStatus(item.surgeryTransferStatus)}</p>
                                        <p>
                                            {item.atqTarget !== null && ((item.atqTarget.firstName && item.atqTarget.firstName) + ' ' + (item.atqTarget.secondName && item.atqTarget.secondName)
                                                + ' ' + (item.atqTarget.firstLastName && item.atqTarget.firstLastName) + ' ' + (item.atqTarget.secondLastName && item.atqTarget.secondLastName))}
                                        </p>
                                    </span>
                                </div>
                            </div>
                        ))))

            }

        </div>
    )
}

ATQAdmin_Tranferir_CX_List_Component.propTypes = {
    activeSwitch: PropTypes.any,
}