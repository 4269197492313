import React, { useEffect, useState } from 'react';
import addImgButton from '../../../assets/img/512PX/Icono_Mas_512px.png';
import checkImgButton from '../../../assets/img/126PX/Icono_Check_126px.png';
import alertImgButton from '../../../assets/img/126PX/Icono_Warning_126px.png';
import errorImgButton from '../../../assets/img/126PX/Icono_Advertencia_126px.png';
// import { storeHouseStockRigthTableItems } from './storeHouseStockRigthTableItemsData';
import PropTypes from 'prop-types';
import './sass/styles.scss';
import { StoreHouseStockModal } from '../StoreHouseStockModal/StoreHouseStockModal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchLocationsStoreHouse, setAppointmentsStoreHouse } from '../../../actions/storeHouseAction';
import { StoreHouseStockRigthTableItemsForm } from './StoreHouseStockRigthTableItemsForm';
import { StoreHouseNewInputComponent } from './StoreHouseNewInputComponent';
import shortid from 'shortid';

export const StoreHouseStockRigthFistTable = ({ listItems, typeComponent, addItem }) => {
    const [openModal, setOpenModal] = useState();
    const [itemToAddUbication, setItemToAddUbication] = useState(null);
    const [listProducts, setListProducts] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    //ESTE USE SELECTOR ES DONDE SE ENCUENTRA LOS NUEVOS PRODUCTOS QUE SE DEBEN AGREGAR
    const newProducts = useSelector(state => state.storeHouse.newProducts);

    useEffect(() => {

        setListProducts(newProducts)
    }, [newProducts])


    //ESTA FUNCION ES PARA CREAR EL ARREGLO QUE VA A CREAR LAS CAJAS CORRESPONDIENTES EXTRAS, CUANDO NO TIENE INFORMACION
    const newArray = (item) => {
        let arrayFi = [];
        for (let x = 0; x < item.item - 1; x++) {
            arrayFi.push(
                {
                    id: shortid.generate(),
                    description: ''
                }
            );
        }
        return arrayFi;
    }

    //ESTA FUNCION ES PARA CUANDO YA EXISTE EL LOCATIONBOXES Y SE LLENA EL ARREGLO PARA MANDARLO AL COMPONENTE CON LA INFORMACION
    const arrayWithInformation = (item) => {
        let arrayFi = [];
        let locationItems = item.locationBoxes;

        for (let locat in locationItems) {
            if (locationItems[locat] === 'undefined' || !locationItems[locat]) {
                arrayFi.push(
                    {
                        id: shortid.generate(),
                        description: ''
                    }
                );
            } else if (locationItems[locat]) {
                arrayFi.push(
                    {
                        ...locationItems[locat]
                    }
                )
            }
        }
        return arrayFi
    }

    const RenderFunction = (item) => {
        let receiveArray = null
        //ESTA DECISION ES PARA CUANDO NO EXISTE LOCATIONBOXES Y SE VA A CREAR UN ARREGLO VACIO
        if (!item.el.locationBoxes) {
            receiveArray = newArray(item);
        } else if (item.el.locationBoxes) {
            //ESTA DECISION ES PARA CUANDO EXISTE INFORMACINO DE LOCATIONBOXES EN EL REDUX Y SE REQUIERE
            //CREAR UN ARREGLO IGUAL QUE EL DE ARRIBA PERO CON LA INFOMARCION YA CARGADA
            receiveArray = arrayWithInformation(item.el)
        }
        return (
            receiveArray &&
            receiveArray.map((item2, index) => (
                <StoreHouseNewInputComponent
                    productItemSelected={item.el}//ESTE ES PARA MANDAR EL ITEM SELECCIONADO 
                    itemObtained={item2.description && item2.description.ubicacion && item2.description.ubicacion} //PARA UBICACION
                    boxes={receiveArray} //ESTE ES EL ARREGLO ENTERO
                    valorBox={item2}//ESTE ES EL VALOR DEL ARREGLO CADA ITEM
                    ubication={'arriba'}
                />
            ))
        );

    }





    const closeModal = () => {
        setOpenModal(null);
    };


    const handleNavigate = () => {
        history.replace('/StoreHouse/StoreHomeAvailableMaterialToAddScreen');
    }

    const handleClickToAddUbication = (systemToGetLocation) => {
        dispatch(searchLocationsStoreHouse(systemToGetLocation.product.productCode));
        setItemToAddUbication(systemToGetLocation);
        setOpenModal("ModalProducts");
    }


    return (
        <div className={'storeHouseStockRigthTableItems-section-rigth-card-container'}>
            <div className="storeHouseStockRigthTableItems-section-rigth-card-precontainer">
                <table className='storeHouseStockRigthTableItems-section-rigth-table-container'>
                    <thead className='storeHouseStockRigthTableItems-section-rigth-table-head'>
                        <tr className='storeHouseStockRigthTableItems-section-rigth-table-head-row'>

                            <th className='storeHouseStockRigthTableItems-section-rigth-table-title'>
                                Sistema solicitado
                            </th>
                            <th className='storeHouseStockRigthTableItems-section-rigth-table-title'>
                                Equipo
                            </th>
                            <th className='storeHouseStockRigthTableItems-section-rigth-table-title'>
                                Cantidad
                            </th>
                            <th className='storeHouseStockRigthTableItems-section-rigth-table-title'>
                                Ubicación
                            </th>

                        </tr>
                    </thead>
                    <tbody className='storeHouseStockRigthTableItems-section-rigth-table-body'>
                        {
                            listProducts && listProducts.length > 0 ?
                                listProducts.map((el) =>
                                (
                                    <tr className='storeHouseStockRigthTableItems-section-rigth-table-body-row' key={el.id}>
                                        <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                            {el.product && el.product.description ? `${el.product.description}`
                                                : el.product && el.product.productDescription ? `${el.product.productDescription}`
                                                    : el.location && `${el.location.description}`}
                                        </td>
                                        <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                            <span className='storeHouseStockRigthTableItems-section-rigth-table-data-equipo-img' onClick={() => handleClickToAddUbication(el)}>
                                                {el.product && el.product.availableLocations && el.product.availableLocations > 1
                                                    ? <img src={checkImgButton} alt="" className={`storeHouseStockRigthTableItems-section-rigth-table-data-img-check`} />
                                                    : el.product && el.product.availableLocations && el.product.availableLocations === 1
                                                        ? <img src={alertImgButton} alt="" className={`storeHouseStockRigthTableItems-section-rigth-table-data-img-check`} />
                                                        : el.product && el.product.availableLocations === 0
                                                            ? <img src={errorImgButton} alt="" className={`storeHouseStockRigthTableItems-section-rigth-table-data-img-check`} />
                                                            : el.product && el.product.availableLocations === 'NOT'
                                                                ? '-'
                                                                : <img src={checkImgButton} alt="" className={`storeHouseStockRigthTableItems-section-rigth-table-data-img-check`} />
                                                }
                                            </span>

                                        </td>
                                        <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                            {el.systemLevels ? `${el.systemLevels}`
                                                : el.ProductQuantity ? `${el.ProductQuantity}`
                                                    : el.productQuantity ? `${el.productQuantity}`
                                                        : el.locationLevels && `${el.locationLevels}`}

                                        </td>
                                        <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                            {/* !el.locastionItem && !el.ubicacion && !el.location && !el.productScanned&& */}
                                            {
                                                <StoreHouseStockRigthTableItemsForm
                                                    productItemSelected={el}
                                                    itemObtained={el.locastionItem ? el.locastionItem : el.ubicacion ? el.ubicacion : el.location ? el.location.ubicacion : el.productScanned && el.productScanned}

                                                />
                                            }

                                            {/* {
                                                el.product.numberOfBoxes > 1 &&
                                                <RenderFunction
                                                    item={el.product.numberOfBoxes}
                                                    el={el}
                                                />
                                            } */}

                                        </td>
                                    </tr>
                                ))
                                : <tr className='storeHouseStockRigthTableItems-section-rigth-table-body-row'>
                                    <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                        {`-`}

                                    </td>
                                    <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                        <span className='storeHouseStockRigthTableItems-section-rigth-table-data-equipo-img'>
                                            {`-`}
                                        </span>

                                    </td>
                                    <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                        {`-`}

                                    </td>
                                    <td className='storeHouseStockRigthTableItems-section-rigth-table-data'>
                                        {`-`}
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
                {
                    typeComponent === 'ListAddProsducts' &&  addItem == false &&
                    <button className='storeHouseStockRigthTableItems-section-rigth-card-container-add-container' onClick={handleNavigate}>
                        {/* <CloseIcon ></CloseIcon> */}
                        
                         
                            <img className='storeHouseStockRigthTableItems-section-rigth-card-container-add' src={addImgButton} alt="" />
                        
                    </button>

                }
                {
                    openModal === "ModalProducts" &&
                    <StoreHouseStockModal
                        isOpen
                        toggle={closeModal}
                        type={'top'}
                        itemToAddUbication={itemToAddUbication}
                        messageModal={'¡No se ha seleccionado materiales!'}
                    />
                }
            </div>
        </div>
    )
}

StoreHouseStockRigthFistTable.propTypes = {
    typeComponent: PropTypes.any,
    listItems: PropTypes.any,
    setListSystemScann: PropTypes.any,
}
