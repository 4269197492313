import React, { useState } from 'react';
import { Mess_hosp_Req_Comp } from '../Messenger/HospitalRequirements/Mess_hosp_Req_Comp';
import { Mess_medics_req_der } from '../Messenger/MedicsRequirements/Mess_medics_req_der';

import './styleRequerimentsHospital.scss';

export const QualityManagerHospitalRequeriments = () => {
    const [selectedHospital, setSelectedHotpital] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [list1, setList1] = useState('1');  
  return (
    <div className="HospitalHome-principal-container1">
    <div className={"HospitalHome-main-page-container1"}>
            <Mess_hosp_Req_Comp  
            selected={selectedHospital} 
            setSelected={setSelectedHotpital} 
            list1={list1}
            setList1={setList1}
            />
        <div className={"HospitalHome-right-bar1"}>
            <Mess_medics_req_der 
                setSelected = {setSelectedHotpital}
                selected = {selectedHospital}
                page = {'hospital'}
                selectedHospitals = {selectedHospital}
                currentPage = {currentPage}
                setList1={setList1}
                readonlyPage={1}
            />
        </div>
    </div>
</div>
  );
};
