import React from 'react';
import './starComponent.scss';

const StarsComponent = ({ valoracion }) => {

    const arrayValoracion = [];

    for (let i = 0; i < 5; i++) {
        if (i < valoracion) {
            arrayValoracion.push(<span className="star fill">★</span>)
        } else {
            arrayValoracion.push(<span className="star">★</span>)
        }
    }
    
    return <div className='container-star'>{arrayValoracion}</div>

};

export default StarsComponent;
