import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import './stylePrimeraClasificacion.scss';

export const PrimeraClasificacion = ({ type, typePrimeraClasificacion,setTypePrimeraClasificacion,tipo }) => {

    const valueClasificacion = 'Mejora';
    const user = useSelector(state => state.user.user.identity);
    /* console.warn(typePrimeraClasificacion); */
    /* console.log('tipo',tipo) */
    return (
        <section className='container-component-comments-1Clasificacion'>
            <div className='header-comments1Clasificacion'>
                <div className='header-title1Clasificacion'>
                    <p className='title-1Clasificacion'>1RA CLASIFICACIÓN *</p>
                </div>
            </div>
            <div className='body_1Clasificacion'>
                {
                    type === 'analisis'
                        ? <select className='select-1clasificacion-analisis'
                            name="clasificacionAnalisis"
                            disabled>
                            <option value={0}>{valueClasificacion && valueClasificacion}</option>
                        </select>
                        :
                        <select className='select-1clasificacion'
                            name="clasificacion"
                            value={typePrimeraClasificacion}
                            onChange={(e) => setTypePrimeraClasificacion(e.target.value)}
                            disabled={(tipo !== 'seguimiento') ?true :user.roleID === 9 ?true  :false}
                        >
                            <option disabled selected>Seleccionar</option>
                            <option value={1}>Mejora</option>
                            <option value={2}>Positivo</option>
                            <option value={3}>Comentario</option>
                            <option value={4}>Bien</option>
                        </select>
                }
                
            </div>
        </section>
    )
}
