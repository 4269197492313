import React from 'react';
import img_encuesta from '../../../assets/img/512PX/ico_message_encuesta.png';
import { useHistory, useParams } from 'react-router-dom';
import './style.scss';

export const StoreHouseMessage_Encuestas_Notify_Page = () => {

    const {id} = useParams();
    const history = useHistory();

    const handleOk = () => {
      history.push('/inicio')
    }

  return (
    <div className='precontainer-almacen-notify'>
    <div className='container_general_almace-notify'>

      <div className='container-almacen-notify'>
        <img className='img-encuesta-almacen-notify' src={img_encuesta} />
      </div>

      <p className="title_encuesta_almacen-notify">SE HA GUARDADO TU ENCUESTA</p>
      <p className='subtitle-encuesta-almacen-notify'>SE HA NOTIFICADO AL GERENTE DE CALIDAD DE </p>
      <p className='subtitle-encuesta-almacen-notify2'>NUEVO COMENTARIO AGREGADO</p>
      <p className={"numberCx_encuestas_almacen-notify"}>{`CX #${id}`}</p>

      <div className='Cont_button_encuestas_almacen-notify'>
        <button className='button-almacen-notify' onClick={()=> handleOk()}>
          <span>OK</span>
        </button>
      </div>

    </div>
  </div>


  )
};
