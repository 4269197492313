export const SAVE_FUEL_SAVINGS = 'SAVE_FUEL_SAVINGS';
export const CALCULATE_FUEL_SAVINGS = 'CALCULATE_FUEL_SAVINGS';


export const LEO_TEST = 'LEO_TEST';

// LOGIN
export const USER_LOGIN_BEGIN = 'USER_LOGIN_BEGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

// LOGOUT
export const USER_LOGOUT_BEGIN = 'USER_LOGOUT_BEGIN';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

// SIGNUP
export const USER_SIGNUP_CLEAN = 'USER_SIGNUP_CLEAN';
export const USER_SIGNUP_BEGIN = 'USER_SIGNUP_BEGIN';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE';

// PERMISSIONS USER
export const ADD_USER_PERMISSION_BEGIN = 'ADD_USER_PERMISSION_BEGIN';
export const ADD_USER_PERMISSION_SUCCESS = 'ADD_USER_PERMISSION_SUCCESS';
export const ADD_USER_PERMISSION_FAILURE = 'ADD_USER_PERMISSION_FAILURE';
export const REMOVE_USER_PERMISSION_BEGIN = 'REMOVE_USER_PERMISSION_BEGIN';
export const REMOVE_USER_PERMISSION_SUCCESS = 'REMOVE_USER_PERMISSION_SUCCESS';
export const REMOVE_USER_PERMISSION_FAILURE = 'REMOVE_USER_PERMISSION_FAILURE';
export const GET_PERMISSION_BEGIN = 'GET_PERMISSION_BEGIN';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';
export const GET_ALL_USERS_BEGIN = 'GET_ALL_USERS_BEGIN';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';
export const SEARCH_USERS_BEGIN = 'SEARCH_USERS_BEGIN';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';




// RECOVERY 

export const RECOVERY_PASS_BEGIN = 'RECOVERY_PASS_BEGIN';
export const RECOVERY_PASS_SUCCESS = 'RECOVERY_PASS_SUCCESS';
export const RECOVERY_PASS_FAILURE = 'RECOVERY_PASS_FAILURE';

export const CLEAN_UNAPPROVE_USER = 'CLEAN_UNAPPROVE_USER';
export const CLEAN_APPROVE_USER = 'CLEAN_APPROVE_USER';

export const RESET_PASS_BEGIN = 'RESET_PASS_BEGIN';
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RESET_PASS_FAILURE = 'RESET_PASS_FAILURE';
export const RESET_PASS_CLEAN = 'RESET_PASS_CLEAN';

export const CLEAN_APPROVE_UNAPROVE_USER = 'CLEAN_APPROVE_UNAPROVE_USER';





// GET PENDING APPROVAL USERS
export const GET_PENDING_APPROVAL_USERS_BEGIN = 'GET_PENDING_APPROVAL_USERS_BEGIN';
export const GET_PENDING_APPROVAL_USERS_SUCCESS = 'GET_PENDING_APPROVAL_USERS_SUCCESS';
export const GET_PENDING_APPROVAL_USERS_FAILURE = 'GET_PENDING_APPROVAL_USERS_FAILURE';

//UNAPPROVE USER
export const SET_UNAPPROVE_USER_BEGIN = 'SET_UNAPPROVE_USER_BEGIN';
export const SET_UNAPPROVE_USER_SUCCESS = 'SET_UNAPPROVE_USER_SUCCESS';
export const SET_UNAPPROVE_USER_FAILURE = 'SET_UNAPPROVE_USER_FAILURE';

//APPROVE USER
export const SET_APPROVE_USER_BEGIN = 'SET_APPROVE_USER_BEGIN';
export const SET_APPROVE_USER_SUCCESS = 'SET_APPROVE_USER_SUCCESS';
export const SET_APPROVE_USER_FAILURE = 'SET_APPROVE_USER_FAILURE';

//GET ROLS
export const GET_ROLS_BEGIN = 'GET_ROLS_BEGIN';
export const GET_ROLS_SUCCESS = 'GET_ROLS_SUCCESS';
export const GET_ROLS_FAILURE = 'GET_ROLS_FAILURE';

//SET USER PERMISSION
export const SET_USER_PERMISSION_BEGIN = 'SET_USER_PERMISSION_BEGIN';
export const SET_USER_PERMISSION_SUCCESS = 'SET_USER_PERMISSION_SUCCESS';
export const SET_USER_PERMISSION_FAILURE = 'SET_USER_PERMISSION_FAILURE';

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';


export const RESET_TO_INITIAL_STATE_QUOTE = 'RESET_TO_INITIAL_STATE_QUOTE';
export const RESET_TO_INITIAL_STATE_QUOTE_WITHOUT = 'RESET_TO_INITIAL_STATE_QUOTE_WITHOUT';
export const ADD_PENDING_STATE_QUOTE = 'ADD_PENDING_STATE_QUOTE';
export const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';
export const ADD_PENDING_STATE_QUOTE_SYSTEM = 'ADD_PENDING_STATE_QUOTE_SYSTEM';
export const GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_BEGIN = 'GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_BEGIN';
export const GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_SUCCESS = 'GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_SUCCESS';
export const GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_FAILURE = 'GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_FAILURE';
export const GET_MAIN_SURGERY_TYPES_BEGIN = 'GET_MAIN_SURGERY_TYPES_BEGIN';
export const GET_MAIN_SURGERY_TYPES_SUCCESS = 'GET_MAIN_SURGERY_TYPES_SUCCESS';
export const GET_MAIN_SURGERY_TYPES_FAILURE = 'GET_MAIN_SURGERY_TYPES_FAILURE';
export const GET_BUNDLES_FORM_SYSTEM_BEGIN = 'GET_BUNDLES_FORM_SYSTEM_BEGIN';
export const GET_BUNDLES_FORM_SYSTEM_SUCCESS = 'GET_BUNDLES_FORM_SYSTEM_SUCCESS';
export const GET_BUNDLES_FORM_SYSTEM_FAILURE = 'GET_BUNDLES_FORM_SYSTEM_FAILURE';
export const CLEAR_BUNDLES_FORM_SYSTEM_SUCCESS = 'CLEAR_BUNDLES_FORM_SYSTEM_SUCCESS';
export const GET_SYSTEMS_BEGIN = 'GET_SYSTEMS_BEGIN';
export const GET_SYSTEMS_SUCCESS = 'GET_SYSTEMS_SUCCESS';
export const GET_SYSTEMS_FAILURE = "GET_SYSTEMS_FAILURE";
export const GET_BUNDLES_BY_SYSTEM_ID_BEGIN = 'GET_BUNDLES_BY_SYSTEM_ID_BEGIN';
export const GET_BUNDLES_BY_SYSTEM_ID_SUCCESS = 'GET_BUNDLES_BY_SYSTEM_ID_SUCCESS';
export const GET_BUNDLES_BY_SYSTEM_ID_FAILURE = 'GET_BUNDLES_BY_SYSTEM_ID_FAIURE';
export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';


export const GET_CLIENTS_BEGIN = 'GET_CLIENTS_BEGIN';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

export const GET_INSTITUTIONS_BEGIN = 'GET_INSTITUTIONS_BEGIN';
export const GET_INSTITUTIONS_SUCCESS = 'GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_FAILURE = 'GET_INSTITUTIONS_FAILURE';

export const REMOVE_HOSPITAL_REQUERIMENT_BEGIN = 'REMOVE_HOSPITAL_REQUERIMENT_BEGIN';
export const REMOVE_HOSPITAL_REQUERIMENT_SUCCESS = 'REMOVE_HOSPITAL_REQUERIMENT_SUCCESS';
export const REMOVE_HOSPITAL_REQUERIMENT_FAILURE = 'REMOVE_HOSPITAL_REQUERIMENT_FAILURE';

export const REMOVE_MEDIC_REQUERIMENT_BEGIN = 'REMOVE_MEDIC_REQUERIMENT_BEGIN';
export const REMOVE_MEDIC_REQUERIMENT_SUCCESS = 'REMOVE_MEDIC_REQUERIMENT_SUCCESS';
export const REMOVE_MEDIC_REQUERIMENT_FAILURE = 'REMOVE_MEDIC_REQUERIMENT_FAILURE';

export const ADD_MEDIC_REQUERIMENT_BEGIN = 'ADD_MEDIC_REQUERIMENT_BEGIN';
export const ADD_MEDIC_REQUERIMENT_SUCCESS = 'ADD_MEDIC_REQUERIMENT_SUCCESS';
export const ADD_MEDIC_REQUERIMENT_FAILURE = 'ADD_MEDIC_REQUERIMENT_FAILURE';

export const ADD_HOSPITAL_REQUERIMENT_BEGIN = 'ADD_HOSPITAL_REQUERIMENT_BEGIN';
export const ADD_HOSPITAL_REQUERIMENT_SUCCESS = 'ADD_HOSPITAL_REQUERIMENT_SUCCESS';
export const ADD_HOSPITAL_REQUERIMENT_FAILURE = 'ADD_HOSPITAL_REQUERIMENT_FAILURE';

export const GET_MEDICS_BEGIN = 'GET_MEDICS_BEGIN';
export const GET_MEDICS_SUCCESS = 'GET_MEDICS_SUCCESS';
export const GET_MEDICS_FAILURE = 'GET_MEDICS_FAILURE';


// New Quote
export const UPDATE_NEW_QUOTE_BEGIN = 'UPDATE_NEW_QUOTE_BEGIN';
export const UPDATE_NEW_QUOTE_SUCCESS = 'UPDATE_NEW_QUOTE_SUCCESS';
export const UPDATE_NEW_QUOTE_FAILURE = 'UPDATE_NEW_QUOTE_FAILURE';
export const UPDATE_SYSTEMS_NEW_QUOTE_SUCCESS = 'UPDATE_SYSTEMS_NEW_QUOTE_SUCCESS';
export const DELETE_NEW_QUOTE_SYSTEM_BY_ID_SUCCESS = 'DELETE_NEW_QUOTE_SYSTEM_BY_ID_SUCCESS';

export const GET_PRODUCT_PRICE_BEGIN = 'GET_PRODUCT_PRICE_BEGIN';
export const GET_PRODUCT_PRICE_SUCCESS = 'GET_PRODUCT_PRICE_SUCCESS';
export const GET_PRODUCT_PRICE_FAILURE = 'GET_PRODUCT_PRICE_FAILURE';


export const GET_PRICE_LIST_BEGIN = 'GET_PRICE_LIST_BEGIN';
export const GET_PRICE_LIST_SUCCESS = 'GET_PRICE_LIST_SUCCESS';
export const GET_PRICE_LIST_FAILURE = 'GET_PRICE_LIST_FAILURE';

export const POST_NEW_QUOTE_BEGIN = "POST_NEW_QUOTE_BEGIN";
export const POST_NEW_QUOTE_SUCCESS = "POST_NEW_QUOTE_SUCCESS";
export const POST_NEW_QUOTE_FAILURE = "POST_NEW_QUOTE_FAILURE";

export const DELETE_ELEMENT_LIST_PRODUCT_PRICES = "DELETE_ELEMENT_LIST_PRODUCT_PRICES";


// Quote Data
export const UPDATE_DATE_TYPE_SUCCESS = 'POST_DATE_TYPE_SUCCESS';
export const UPDATE_ATN_CLIENT_TYPE_SUCCESS = 'UPDATE_ATN_CLIENT_TYPE_SUCCESS';
export const UPDATE_ATN_MEDIC_TYPE_SUCCESS = 'UPDATE_ATN_MEDIC_TYPE_SUCCESS';
export const UPDATE_ATN_TYPE_BEGIN = 'UPDATE_ATN_TYPE_BEGIN';
export const UPDATE_ATN_TYPE_SUCCESS = 'UPDATE_ATN_TYPE_SUCCESS';
export const UPDATE_ATN_TYPE_FAILURE = 'UPDATE_ATN_TYPE_FAILURE';
export const UPDATE_INSTITUTIONS_TYPE_SUCCESS = 'UPDATE_INSTITUTIONS_TYPE_SUCCESS';
export const UPDATE_NAME_INSTITUTIONS_TYPE_SUCCESS = 'UPDATE_NAME_INSTITUTIONS_TYPE_SUCCESS';
export const UPDATE_WAYTOPAY_TYPE_SUCCESS = 'UPDATE_WAYTOPAY_TYPE_SUCCESS';
export const UPDATE_PAYMENTCONDITIONS_TYPE_SUCCESS = 'UPDATE_PAYMENTCONDITIONS_TYPE_SUCCESS';
export const UPDATE_CITY_TYPE_SUCCESS = 'UPDATE_CITY_TYPE_SUCCESS';
export const UPDATE_OFFERVALIDITY_TYPE_SUCCESS = 'UPDATE_OFFERVALIDITY_TYPE_SUCCESS';
export const UPDATE_EMAIL_TYPE_SUCCESS = 'UPDATE_EMAIL_TYPE_SUCCESS';
export const UPDATE_TEL_TYPE_SUCCESS = 'UPDATE_TEL_TYPE_SUCCESS';
export const UPDATE_FORM_DELIVERY_SUCCESS = "UPDATE_FORM_DELIVERY_SUCCESS";
export const UPDATE_PRICE_LIST_TYPE_SUCCESS = 'UPDATE_PRICE_LIST_TYPE_SUCCESS';
export const UPDATE_PATIENT_TYPE_SUCCESS = 'UPDATE_PATIENT_TYPE_SUCCESS';
export const UPDATE_SPECIFIC_SESCRIPTION_QUOTE = 'UPDATE_SPECIFIC_SESCRIPTION_QUOTE';

export const POST_PATIENT_TYPE_BEGIN = 'POST_PATIENT_TYPE_BEGIN';
export const POST_PATIENT_TYPE_SUCCESS = 'POST_PATIENT_TYPE_SUCCESS';
export const POST_PATIENT_TYPE_FAILURE = 'POST_PATIENT_TYPE_FAILURE';

export const SEARCH_MEDICS_BEGIN = 'SEARCH_MEDICS_BEGIN';
export const SEARCH_MEDICS_SUCCESS = 'SEARCH_MEDICS_SUCCESS';
export const SEARCH_MEDICS_FAILURE = 'SEARCH_MEDICS_FAILURE';
export const SEARCH_CLIENTS_BEGIN = 'SEARCH_CLIENTS_BEGIN';
export const SEARCH_CLIENTS_SUCCESS = 'SEARCH_CLIENTS_SUCCESS';
export const SEARCH_CLIENTS_FAILURE = 'SEARCH_CLIENTS_FAILURE';
export const SEARCH_HOSPITAL_BEGIN = 'SEARCH_HOSPITAL_BEGIN';
export const SEARCH_HOSPITAL_SUCCESS = 'SEARCH_HOSPITAL_SUCCESS';
export const SEARCH_HOSPITAL_FAILURE = 'SEARCH_HOSPITAL_FAILURE';
export const SEARCH_PATIENT_BEGIN = 'SEARCH_PATIENT_BEGIN';
export const SEARCH_PATIENT_SUCCESS = 'SEARCH_PATIENT_SUCCESS';
export const SEARCH_PATIENT_FAILURE = 'SEARCH_PATIENT_FAILURE';

export const GET_CITIES_BEGIN = 'GET_CITIES_BEGIN';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';
export const GET_STATES_BEGIN = 'GET_STATES_BEGIN';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';
export const GET_PATIENT_BEGIN = "GET_PATIENT_BEGIN";
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_FAILURE = 'GET_PATIENT_FAILURE';


// GET QOUTES
export const GET_FINISH_QUOTE_LIST_BEGIN = 'GET_FINISH_QUOTE_LIST_BEGIN';
export const GET_FINISH_QUOTE_LIST_SUCCESS = 'GET_FINISH_QUOTE_LIST_SUCCESS';
export const GET_FINISH_QUOTE_LIST_FAILURE = 'GET_FINISH_QUOTE_LIST_FAILURE';
export const GET_FINISH_ALL_QUOTE_LIST_BEGIN = 'GET_FINISH_ALL_QUOTE_LIST_BEGIN';
export const GET_FINISH_ALL_QUOTE_LIST_SUCCESS = 'GET_FINISH_ALL_QUOTE_LIST_SUCCESS';
export const GET_FINISH_ALL_QUOTE_LIST_FAILURE = 'GET_FINISH_ALL_QUOTE_LIST_FAILURE';
export const SEARCH_IN_ALL_FINISHED_QUOTE_BEGIN = 'SEARCH_IN_ALL_FINISHED_QUOTE_BEGIN';
export const SEARCH_IN_ALL_FINISHED_QUOTE_SUCCESS = 'SEARCH_IN_ALL_FINISHED_QUOTE_SUCCESS';
export const SEARCH_IN_ALL_FINISHED_QUOTE_FAILURE = 'SEARCH_IN_ALL_FINISHED_QUOTE_FAILURE';
export const GET_UNFINISH_QUOTE_LIST_BEGIN = 'GET_UNFINISH_QUOTE_LIST_BEGIN';
export const GET_UNFINISH_QUOTE_LIST_SUCCESS = 'GET_UNFINISH_QUOTE_LIST_SUCCESS';
export const GET_UNFINISH_QUOTE_LIST_FAILURE = 'GET_UNFINISH_QUOTE_LIST_FAILURE';

export const SET_LIST = 'SET_LIST';



// CREATE CONFIGURATION
export const GET_SYSTEM_CONFIGURATION_SUCCESS = 'GET_SYSTEM_CONFIGURATION_SUCCESS';

export const POST_CREATE_CONFIGURATION_BEGIN = 'POST_CREATE_CONFIGURATION_BEGIN';
export const POST_CREATE_CONFIGURATION_SUCCESS = 'POST_CREATE_CONFIGURATION_SUCCESS';
export const POST_CREATE_CONFIGURATION_FAILURE = 'POST_CREATE_CONFIGURATION_FAILURE';
export const POST_NEW_CONFIGURATION_BEGIN = 'POST_NEW_CONFIGURATION_BEGIN';
export const POST_NEW_CONFIGURATION_SUCCESS = 'POST_NEW_CONFIGURATION_SUCCESS';
export const POST_NEW_CONFIGURATION_FAILURE = 'POST_NEW_CONFIGURATION_FAILURE';
export const POST_ADD_CONFIGURATION_TODB_BEGIN = 'POST_ADD_CONFIGURATION_TODB_BEGIN';
export const POST_ADD_CONFIGURATION_TODB_SUCCESS = 'POST_ADD_CONFIGURATION_TODB_SUCCESS';
export const POST_ADD_CONFIGURATION_TODB_FAILURE = 'POST_ADD_CONFIGURATION_TODB_FAILURE';
export const POST_DELETE_CONFIGURATION_TODB_BEGIN = 'POST_DELETE_CONFIGURATION_TODB_BEGIN';
export const POST_DELETE_CONFIGURATION_TODB_SUCCESS = 'POST_DELETE_CONFIGURATION_TODB_SUCCESS';
export const POST_DELETE_CONFIGURATION_TODB_FAILURE = 'POST_DELETE_CONFIGURATION_TODB_FAILURE';
export const POST_ADD_PRODUCT_TO_CONFIG_BEGIN = 'POST_ADD_PRODUCT_TO_CONFIG_BEGIN';
export const POST_ADD_PRODUCT_TO_CONFIG_SUCCESS = 'POST_ADD_PRODUCT_TO_CONFIG_SUCCESS';
export const POST_ADD_PRODUCT_TO_CONFIG_FAILURE = 'POST_ADD_PRODUCT_TO_CONFIG_FAILURE';
export const POST_DELETE_PRODUCT_TO_CONFIG_BEGIN = 'POST_DELETE_PRODUCT_TO_CONFIG_BEGIN';
export const POST_DELETE_PRODUCT_TO_CONFIG_SUCCESS = 'POST_DELETE_PRODUCT_TO_CONFIG_SUCCESS';
export const POST_DELETE_PRODUCT_TO_CONFIG_FAILURE = 'POST_DELETE_PRODUCT_TO_CONFIG_FAILURE';

export const MODIFY_NEW_CONFIGURATION_SUCCESS = 'MODIFY_NEW_CONFIGURATION_SUCCESS';
export const UPDATE_BUNDLES = 'UPDATE_BUNDLES';

export const ADD_NAME_NEW_CONFIGURATION_SUCCESS = 'ADD_NAME_NEW_CONFIGURATION_SUCCESS';
export const ADD_SURGERY_TARGETS_NEW_CONFIGURATION_SUCCESS = 'ADD_SURGERY_TARGETS_NEW_CONFIGURATION_SUCCESS';
export const ADD_SURGERY_TARGETS_SYSTEM_BEGIN = 'ADD_SURGERY_TARGETS_SYSTEM_BEGIN';
export const ADD_SURGERY_TARGETS_SYSTEM_SUCCESS = 'ADD_SURGERY_TARGETS_SYSTEM_SUCCESS';
export const ADD_SURGERY_TARGETS_SYSTEM_FAILURE = 'ADD_SURGERY_TARGETS_SYSTEM_FAILURE';
export const ADD_PRODUCTS_NEW_CONFIGURATION_SUCCESS = 'ADD_PRODUCTS_NEW_CONFIGURATION_SUCCESS';

export const DELETE_SURGERY_TARGETS_SYSTEM_BEGIN = 'DELETE_SURGERY_TARGETS_SYSTEM_BEGIN';
export const DELETE_SURGERY_TARGETS_SYSTEM_SUCCESS = 'DELETE_SURGERY_TARGETS_SYSTEM_SUCCESS';
export const DELETE_SURGERY_TARGETS_SYSTEM_FAILURE = 'DELETE_SURGERY_TARGETS_SYSTEM_FAILURE';
export const DELETE_BUNDLE_TO_CONFIG_BEGIN = 'DELETE_BUNDLE_TO_CONFIG_BEGIN';
export const DELETE_BUNDLE_TO_CONFIG_SUCCESS = 'DELETE_BUNDLE_TO_CONFIG_SUCCESS';
export const DELETE_BUNDLE_TO_CONFIG_FAILURE = 'DELETE_BUNDLE_TO_CONFIG_FAILURE';
export const DELETE_CONSTRUCT_NEWCONFIG = 'DELETE_CONSTRUCT_NEWCONFIG';
export const DELETE_PRODUCT_NEWCONFIG = 'DELETE_PRODUCT_NEWCONFIG';


export const GET_USER_SPECIFIC_PRODUCT_GROUPS_BEGIN = 'GET_USER_SPECIFIC_PRODUCT_GROUPS_BEGIN';
export const GET_USER_SPECIFIC_PRODUCT_GROUPS_SUCCESS = 'GET_USER_SPECIFIC_PRODUCT_GROUPS_SUCCESS';
export const GET_USER_SPECIFIC_PRODUCT_GROUPS_FAILURE = 'GET_USER_SPECIFIC_PRODUCT_GROUPS_FAILURE';

export const GET_PRODUCT_GROUPS_BEGIN = 'GET_PRODUCT_GROUPS_BEGIN';
export const GET_PRODUCT_GROUPS_SUCCESS = 'GET_PRODUCT_GROUPS_SUCCESS';
export const GET_PRODUCT_GROUPS_FAILURE = 'GET_PRODUCT_GROUPS_FAILURE';

export const ADD_USER_SPECIFIC_GROUP_BEGIN = 'ADD_USER_SPECIFIC_GROUP_BEGIN';
export const ADD_USER_SPECIFIC_GROUP_SUCCESS = 'ADD_USER_SPECIFIC_GROUP_SUCCESS';
export const ADD_USER_SPECIFIC_GROUP_FAILURE = 'ADD_USER_SPECIFIC_GROUP_FAILURE';

export const REMOVE_USER_SPECIFIC_PRODUCT_GROUP_BEGIN = 'REMOVE_USER_SPECIFIC_PRODUCT_GROUP_BEGIN';
export const REMOVE_USER_SPECIFIC_PRODUCT_GROUP_SUCCESS = 'REMOVE_USER_SPECIFIC_PRODUCT_GROUP_SUCCESS';
export const REMOVE_USER_SPECIFIC_PRODUCT_GROUP_FAILURE = 'REMOVE_USER_SPECIFIC_PRODUCT_GROUP_FAILURE';

export const GET_SPECIFIC_BUNDLES_BEGIN = 'GET_SPECIFIC_BUNDLES_BEGIN';
export const GET_SPECIFIC_BUNDLES_SUCCESS = 'GET_SPECIFIC_BUNDLES_SUCCESS';
export const GET_SPECIFIC_BUNDLES_FAILURE = 'GET_SPECIFIC_BUNDLES_FAILURE';

export const ADD_USER_SPECIFIC_BUNDLE_BEGIN = 'ADD_USER_SPECIFIC_BUNDLE_BEGIN';
export const ADD_USER_SPECIFIC_BUNDLE_SUCCESS = 'ADD_USER_SPECIFIC_BUNDLE_SUCCESS';
export const ADD_USER_SPECIFIC_BUNDLE_FAILURE = 'ADD_USER_SPECIFIC_BUNDLE_FAILURE';

export const DELETE_SPECIFIC_BUNDLE_BEGIN = 'DELETE_SPECIFIC_BUNDLE_BEGIN';
export const DELETE_SPECIFIC_BUNDLE_SUCCESS = 'DELETE_SPECIFIC_BUNDLE_SUCCESS';
export const DELETE_SPECIFIC_BUNDLE_FAILURE = 'DELETE_SPECIFIC_BUNDLE_FAILURE';

export const GET_USER_SPECIFIC_PRICELISTS_BEGIN = 'GET_USER_SPECIFIC_PRICELISTS_BEGIN';
export const GET_USER_SPECIFIC_PRICELISTS_SUCCESS = 'GET_USER_SPECIFIC_PRICELISTS_SUCCESS';
export const GET_USER_SPECIFIC_PRICELISTS_FAILURE = 'GET_USER_SPECIFIC_PRICELISTS_FAILURE';

export const ADD_USER_SPECIFIC_PRICELIST_BEGIN = 'ADD_USER_SPECIFIC_PRICELIST_BEGIN';
export const ADD_USER_SPECIFIC_PRICELIST_SUCCESS = 'ADD_USER_SPECIFIC_PRICELIST_SUCCESS';
export const ADD_USER_SPECIFIC_PRICELIST_FAILURE = 'ADD_USER_SPECIFIC_PRICELIST_FAILURE';

export const REMOVE_USER_SPECIFIC_PRICELIST_BEGIN = 'REMOVE_USER_SPECIFIC_PRICELIST_BEGIN';
export const REMOVE_USER_SPECIFIC_PRICELIST_SUCCESS = 'REMOVE_USER_SPECIFIC_PRICELIST_SUCCESS';
export const REMOVE_USER_SPECIFIC_PRICELIST_FAILURE = 'REMOVE_USER_SPECIFIC_PRICELIST_FAILURE';

export const GET_USERS_BY_ROLE_ID_BEGIN = 'GET_USERS_BY_ROLE_ID_BEGIN';
export const GET_USERS_BY_ROLE_ID_SUCCESS = 'GET_USERS_BY_ROLE_ID_SUCCESS';
export const GET_USERS_BY_ROLE_ID_FAILURE = 'GET_USERS_BY_ROLE_ID_FAILURE';

export const GET_USERS_BY_ROLE_ID_ATQ_BEGIN = 'GET_USERS_BY_ROLE_ID_ATQ_BEGIN';
export const GET_USERS_BY_ROLE_ID_ATQ_SUCCESS = 'GET_USERS_BY_ROLE_ID_ATQ_SUCCESS';
export const GET_USERS_BY_ROLE_ID_ATQ_FAILURE = 'GET_USERS_BY_ROLE_ID_ATQ_FAILURE';

export const GET_USERS_BY_ROLE_ID_ADMIN_BEGIN = 'GET_USERS_BY_ROLE_ID_ADMIN_BEGIN';
export const GET_USERS_BY_ROLE_ID_ADMIN_SUCCESS = 'GET_USERS_BY_ROLE_ID_ADMIN_SUCCESS';
export const GET_USERS_BY_ROLE_ID_ADMIN_FAILURE = 'GET_USERS_BY_ROLE_ID_ADMIN_FAILURE';

export const GET_USERS_BY_ROLE_ID_STORER_BEGIN = 'GET_USERS_BY_ROLE_ID_STORER_BEGIN';
export const GET_USERS_BY_ROLE_ID_STORER_SUCCESS = 'GET_USERS_BY_ROLE_ID_STORER_SUCCESS';
export const GET_USERS_BY_ROLE_ID_STORER_FAILURE = 'GET_USERS_BY_ROLE_ID_STORER_FAILURE';

export const SEARCH_PRODUCT_GROUPS_BEGIN = 'SEARCH_PRODUCT_GROUPS_BEGIN';
export const SEARCH_PRODUCT_GROUPS_SUCCESS = 'SEARCH_PRODUCT_GROUPS_SUCCESS';
export const SEARCH_PRODUCT_GROUPS_FAILURE = 'SEARCH_PRODUCT_GROUPS_FAILURE';

export const SEARCH_PRODUCT_BEGIN = 'SEARCH_PRODUCT_BEGIN';
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS';
export const SEARCH_PRODUCT_FAILURE = 'SEARCH_PRODUCT_FAILURE';

export const SEARCH_FINISHED_QUOTE_BEGIN = 'SEARCH_FINISHED_QUOTE_BEGIN';
export const SEARCH_FINISHED_QUOTE_SUCCESS = 'SEARCH_FINISHED_QUOTE_SUCCESS';
export const SEARCH_FINISHED_QUOTE_FAILURE = 'SEARCH_FINISHED_QUOTE_FAILURE';



// SCHEDULE CHAT
export const GET_MEDICS_TO_SCHEDULE_BEGIN = 'GET_MEDICS_TO_SCHEDULE_BEGIN';
export const GET_MEDICS_TO_SCHEDULE_SUCCESS = 'GET_MEDICS_TO_SCHEDULE_SUCCESS';
export const GET_MEDICS_TO_SCHEDULE_FAILURE = 'GET_MEDICS_TO_SCHEDULE_FAILURE';
export const GET_MY_MEDICS_TO_SCHEDULE_BEGIN = 'GET_MY_MEDICS_TO_SCHEDULE_BEGIN';
export const GET_MY_MEDICS_TO_SCHEDULE_SUCCESS = 'GET_MY_MEDICS_TO_SCHEDULE_SUCCESS';
export const GET_MY_MEDICS_TO_SCHEDULE_FAILURE = 'GET_MY_MEDICS_TO_SCHEDULE_FAILURE';

export const GET_INSTITUTIONS_TO_SCHEDULE_BEGIN = 'GET_INSTITUTIONS_TO_SCHEDULE_BEGIN';
export const GET_INSTITUTIONS_TO_SCHEDULE_SUCCESS = 'GET_INSTITUTIONS_TO_SCHEDULE_SUCCESS';
export const GET_INSTITUTIONS_TO_SCHEDULE_FAILURE = 'GET_INSTITUTIONS_TO_SCHEDULE_FAILURE';
export const GET_PATIENT_TO_SCHEDULE_BEGIN = 'GET_PATIENT_TO_SCHEDULE_BEGIN';
export const GET_PATIENT_TO_SCHEDULE_SUCCESS = 'GET_PATIENT_TO_SCHEDULE_SUCCESS';
export const GET_PATIENT_TO_SCHEDULE_FAILURE = 'GET_PATIENT_TO_SCHEDULE_FAILURE';
export const GET_CLIENTS_TO_SCHEDULE_BEGIN = 'GET_CLIENTS_TO_SCHEDULE_BEGIN';
export const GET_CLIENTS_TO_SCHEDULE_SUCCESS = 'GET_CLIENTS_TO_SCHEDULE_SUCCESS';
export const GET_CLIENTS_TO_SCHEDULE_FAILURE = 'GET_CLIENTS_TO_SCHEDULE_FAILURE';
export const GET_PRICE_LIST_TO_SCHEDULE_BEGIN = 'GET_PRICE_LIST_TO_SCHEDULE_BEGIN';
export const GET_PRICE_LIST_TO_SCHEDULE_SUCCESS = 'GET_PRICE_LIST_TO_SCHEDULE_SUCCESS';
export const GET_PRICE_LIST_TO_SCHEDULE_FAILURE = 'GET_PRICE_LIST_TO_SCHEDULE_FAILURE';
export const GET_SYSTEM_REQUERIMENTS_TO_SCHEDULE_BEGIN = 'GET_SYSTEM_REQUERIMENTS_TO_SCHEDULE_BEGIN';
export const GET_SYSTEM_REQUERIMENTS_TO_SCHEDULE_SUCCESS = 'GET_SYSTEM_REQUERIMENTS_TO_SCHEDULE_SUCCESS';
export const GET_SYSTEM_REQUERIMENTS_TO_SCHEDULE_FAILURE = 'GET_SYSTEM_REQUERIMENTS_TO_SCHEDULE_FAILURE';
export const GET_SYSTEM_ARRAGEMENT_TO_SCHEDULE_BEGIN = 'GET_SYSTEM_ARRAGEMENT_TO_SCHEDULE_BEGIN';
export const GET_SYSTEM_ARRAGEMENT_TO_SCHEDULE_SUCCESS = 'GET_SYSTEM_ARRAGEMENT_TO_SCHEDULE_SUCCESS';
export const GET_SYSTEM_ARRAGEMENT_TO_SCHEDULE_FAILURE = 'GET_SYSTEM_ARRAGEMENT_TO_SCHEDULE_FAILURE';

export const SEARCH_MEDICS_TO_SCHEDULE_BEGIN = 'SEARCH_MEDICS_TO_SCHEDULE_BEGIN';
export const SEARCH_MEDICS_TO_SCHEDULE_SUCCESS = 'SEARCH_MEDICS_TO_SCHEDULE_SUCCESS';
export const SEARCH_MEDICS_TO_SCHEDULE_FAILURE = 'SEARCH_MEDICS_TO_SCHEDULE_FAILURE';
export const SEARCH_INSTITUTIONS_TO_SCHEDULE_BEGIN = 'SEARCH_INSTITUTIONS_TO_SCHEDULE_BEGIN';
export const SEARCH_INSTITUTIONS_TO_SCHEDULE_SUCCESS = 'SEARCH_INSTITUTIONS_TO_SCHEDULE_SUCCESS';
export const SEARCH_INSTITUTIONS_TO_SCHEDULE_FAILURE = 'SEARCH_INSTITUTIONS_TO_SCHEDULE_FAILURE';
export const SEARCH_PATIENT_TO_SCHEDULE_BEGIN = 'SEARCH_PATIENT_TO_SCHEDULE_BEGIN';
export const SEARCH_PATIENT_TO_SCHEDULE_SUCCESS = 'SEARCH_PATIENT_TO_SCHEDULE_SUCCESS';
export const SEARCH_PATIENT_TO_SCHEDULE_FAILURE = 'SEARCH_PATIENT_TO_SCHEDULE_FAILURE';
export const SEARCH_CLIENT_TO_SCHEDULE_BEGIN = 'SEARCH_CLIENT_TO_SCHEDULE_BEGIN';
export const SEARCH_CLIENT_TO_SCHEDULE_SUCCESS = 'SEARCH_CLIENT_TO_SCHEDULE_SUCCESS';
export const SEARCH_CLIENT_TO_SCHEDULE_FAILURE = 'SEARCH_CLIENT_TO_SCHEDULE_FAILURE';
export const SEARCH_PRICE_LIST_TO_SCHEDULE_BEGIN = 'SEARCH_PRICE_LIST_TO_SCHEDULE_BEGIN';
export const SEARCH_PRICE_LIST_TO_SCHEDULE_SUCCESS = 'SEARCH_PRICE_LIST_TO_SCHEDULE_SUCCESS';
export const SEARCH_PRICE_LIST_TO_SCHEDULE_FAILURE = 'SEARCH_PRICE_LIST_TO_SCHEDULE_FAILURE';
export const SEARCH_PRODUCT_TO_REQUIRE_BEGIN = 'SEARCH_PRODUCT_TO_REQUIRE_BEGIN';
export const SEARCH_PRODUCT_TO_REQUIRE_SUCCESS = 'SEARCH_PRODUCT_TO_REQUIRE_SUCCESS';
export const SEARCH_PRODUCT_TO_REQUIRE_FAILURE = 'SEARCH_PRODUCT_TO_REQUIRE_FAILURE';
export const SEARCH_PRODUCT_TO_OPTIONAL_BEGIN = 'SEARCH_PRODUCT_TO_OPTIONAL_BEGIN';
export const SEARCH_PRODUCT_TO_OPTIONAL_SUCCESS = 'SEARCH_PRODUCT_TO_OPTIONAL_SUCCESS';
export const SEARCH_PRODUCT_TO_OPTIONAL_FAILURE = 'SEARCH_PRODUCT_TO_OPTIONAL_FAILURE';
export const SEARCH_SYSTEM_BEGIN = 'SEARCH_SYSTEM_BEGIN';
export const SEARCH_SYSTEM_SUCCESS = 'SEARCH_SYSTEM_SUCCESS';
export const SEARCH_SYSTEM_FAILURE = 'SEARCH_SYSTEM_FAILURE';

export const SET_MEDICS_TO_SCHEDULE_SUCCESS = 'SET_MEDICS_TO_SCHEDULE_SUCCESS';
export const SET_INSTITUTIONS_TO_SCHEDULE_SUCCESS = 'SET_INSTITUTIONS_TO_SCHEDULE_SUCCESS';
export const SET_PATIENT_TO_SCHEDULE_SUCCESS = 'SET_PATIENT_TO_SCHEDULE_SUCCESS';
export const SET_HOUR_TO_SCHEDULE_SUCCESS = 'SET_HOUR_TO_SCHEDULE_SUCCESS';
export const SET_CLIENT_TO_SCHEDULE_SUCCESS = 'SET_CLIENT_TO_SCHEDULE_SUCCESS';
export const SET_PRICE_LIST_TO_SCHEDULE_SUCCESS = 'SET_PRICE_LIST_TO_SCHEDULE_SUCCESS';
export const SET_ATQ_TO_SCHEDULE_SUCCESS = 'SET_ATQ_TO_SCHEDULE_SUCCESS';
export const SET_QUOTE_TO_SCHEDULE_SUCCESS = 'SET_QUOTE_TO_SCHEDULE_SUCCESS';
export const SET_ELEMENTS_TO_TYPE_SURGERY_SUCCESS = 'SET_ELEMENTS_TO_TYPE_SURGERY_SUCCESS';
export const SET_ELEMENTS_TYPE_SURGERY_BEING = 'SET_ELEMENTS_TYPE_SURGERY_BEING';
export const SET_ELEMENTS_TYPE_SURGERY_PENDING = 'SET_ELEMENTS_TYPE_SURGERY_PENDING';
export const SET_ELEMENTS_TYPE_SURGERY_SUCCES = 'SET_ELEMENTS_TYPE_SURGERY_SUCCES';
export const SET_ELEMENTS_TYPE_SURGERY_COMPLETED = 'SET_ELEMENTS_TYPE_SURGERY_COMPLETED';
export const SET_ELEMENTS_OPTIONAL_MATERIAL_SUCCES = 'SET_ELEMENTS_OPTIONAL_MATERIAL_SUCCES';
export const SET_ELEMENTS_OPTIONAL_MATERIAL_BEING = 'SET_ELEMENTS_OPTIONAL_MATERIAL_BEING';
export const SET_ELEMENTS_REQUIRE_MATERIAL_SUCCES = 'SET_ELEMENTS_REQUIRE_MATERIAL_SUCCES';
export const SET_ELEMENTS_REQUIRE_MATERIAL_BEING = 'SET_ELEMENTS_REQUIRE_MATERIAL_BEING';
export const SET_ELEMENTS_REQUIRE_MATERIAL_SYSTEM_BEING = 'SET_ELEMENTS_REQUIRE_MATERIAL_SYSTEM_BEING';
export const SET_ELEMENTS_REQUIRE_MATERIAL_SYSTEM_SUCCES = 'SET_ELEMENTS_REQUIRE_MATERIAL_SYSTEM_SUCCES';
export const SET_MANAGE_OBSERVATION_SUCCES = 'SET_MANAGE_OBSERVATION_SUCCES';
export const SET_WITH_PRICES_LIST_SUCCES = 'SET_WITH_PRICES_LIST_SUCCES';

export const ADD_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_SUCCESS = 'ADD_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_SUCCESS';
export const ADD_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_BEGIN = 'ADD_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_BEGIN';
export const ADD_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_FAILURE = 'ADD_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_FAILURE';
export const ADD_SYSTEM_SURGERY_ARRANGEMEN_REQUIREMENT_TO_SCHEDULE_BEGIN = 'ADD_SYSTEM_SURGERY_ARRANGEMEN_REQUIREMENT_TO_SCHEDULE_BEGIN';
export const ADD_SYSTEM_SURGERY_ARRANGEMEN_REQUIREMENT_TO_SCHEDULE_SUCCESS = 'ADD_SYSTEM_SURGERY_ARRANGEMEN_REQUIREMENT_TO_SCHEDULE_SUCCESS';
export const ADD_SYSTEM_SURGERY_ARRANGEMEN_REQUIREMENT_TO_SCHEDULE_FAILURE = 'ADD_SYSTEM_SURGERY_ARRANGEMEN_REQUIREMENT_TO_SCHEDULE_FAILURE';
export const DELETE_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_BEGIN = 'DELETE_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_BEGIN';
export const DELETE_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_SUCCESS = 'DELETE_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_SUCCESS';
export const DELETE_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_FAILURE = 'DELETE_SYSTEM_SURGERY_REQUIREMENT_TO_SCHEDULE_FAILURE';
export const DELETE_SYSTEM_SURGERY_ARRANGEMENT_TO_SCHEDULE_BEGIN = 'DELETE_SYSTEM_SURGERY_ARRANGEMENT_TO_SCHEDULE_BEGIN';
export const DELETE_SYSTEM_SURGERY_ARRANGEMENT_TO_SCHEDULE_SUCCESS = 'DELETE_SYSTEM_SURGERY_ARRANGEMENT_TO_SCHEDULE_SUCCESS';
export const DELETE_SYSTEM_SURGERY_ARRANGEMENT_TO_SCHEDULE_FAILURE = 'DELETE_SYSTEM_SURGERY_ARRANGEMENT_TO_SCHEDULE_FAILURE';

export const CREATE_APPOINTMENT_TO_SCHEDULE_SURGERY_BEGIN = 'CREATE_APPOINTMENT_TO_SCHEDULE_SURGERY_BEGIN';
export const CREATE_APPOINTMENT_TO_SCHEDULE_SURGERY_SUCCESS = 'CREATE_APPOINTMENT_TO_SCHEDULE_SURGERY_SUCCESS';
export const CREATE_APPOINTMENT_TO_SCHEDULE_SURGERY_FAILURE = 'CREATE_APPOINTMENT_TO_SCHEDULE_SURGERY_FAILURE';
export const RESET_TO_INITIAL_STATE_SCHEDULE = 'RESET_TO_INITIAL_STATE_SCHEDULE';
export const RESET_TO_INITIAL_STATE_SCHEDULE_WITHOUT = 'RESET_TO_INITIAL_STATE_SCHEDULE_WITHOUT';


export const POST_CREATE_PATIENT_SCHEDULE_BEGIN = 'POST_CREATE_PATIENT_SCHEDULE_BEGIN';
export const POST_CREATE_PATIENT_SCHEDULE_SUCCESS = 'POST_CREATE_PATIENT_SCHEDULE_SUCCESS';
export const POST_CREATE_PATIENT_SCHEDULE_FAILURE = 'POST_CREATE_PATIENT_SCHEDULE_FAILURE';


// USERS
export const SET_USER_ROL_SELECTED_BEGIN = 'SET_USER_ROL_SELECTED_BEGIN';
export const SET_USER_ROL_SELECTED_SUCCESS = 'SET_USER_ROL_SELECTED_SUCCESS';
export const SET_USER_ROL_SELECTED_FAILURE = 'SET_USER_ROL_SELECTED_FAILURE';

export const GET_USERS_MEDICS_LIST_BEGIN = 'GET_USERS_MEDICS_LIST_BEGIN';
export const GET_USERS_MEDICS_LIST_SUCCESS = 'GET_USERS_MEDICS_LIST_SUCCESS';
export const GET_USERS_MEDICS_LIST_FAILURE = 'GET_USERS_MEDICS_LIST_FAILURE';

// SET SELECTED USER
export const SET_SELECTED_USER_BEGIN = 'SET_SELECTED_USER_BEGIN';
export const SET_SELECTED_USER_SUCCESS = 'SET_SELECTED_USER_SUCCESS';
export const SET_SELECTED_USER_FAILURE = 'SET_SELECTED_USER_FAILURE';

// GET SELECTED USER
export const GET_SELECTED_USER_BEGIN = 'GET_SELECTED_USER_BEGIN';
export const GET_SELECTED_USER_SUCCESS = 'GET_SELECTED_USER_SUCCESS';
export const GET_SELECTED_USER_FAILURE = 'GET_SELECTED_USER_FAILURE';

// GET PATIENTS
export const GET_PATIENTS_IN_USER_BEGIN = 'GET_PATIENTS_IN_USER_BEGIN';
export const GET_PATIENTS_IN_SELECTED_USER_SUCCESS = 'GET_PATIENTS_IN_SELECTED_USER_SUCCESS';
export const GET_PATIENTS_IN_SELECTED_USER_FAILURE = 'GET_PATIENTS_IN_SELECTED_USER_FAILURE';

// GET SELLERS
export const GET_SELLERS_BEGIN = 'GET_SELLERS_BEGIN';
export const GET_SELLERS_SUCCESS = 'GET_SELLERS_SUCCESS';
export const GET_SELLERS_FAILURE = 'GET_SELLERS_FAILURE';

export const SET_SELLER_USER = 'SET_SELLER_USER';

// GET PROCESSES
export const GET_PROCESSES_IN_USER_BEGIN = 'GET_PROCESSES_IN_USER_BEGIN';
export const GET_PROCESSES_IN_SELECTED_USER_SUCCESS = 'GET_PROCESSES_IN_SELECTED_USER_SUCCESS';
export const GET_PROCESSES_IN_SELECTED_USER_FAILURE = 'GET_PROCESSES_IN_SELECTED_USER_FAILURE';

// GET STATES IN PROCESS
export const GET_STATES_IN_PROCESSES_BEGIN = 'GET_STATES_IN_PROCESSES_BEGIN';
export const GET_STATES_IN_PROCESSES_SUCCESS = 'GET_STATES_IN_PROCESSES_SUCCESS';
export const GET_STATES_IN_PROCESSES_FAILURE = 'GET_STATES_IN_PROCESSES_FAILURE';

// GET SURGERIES IN USER
export const GET_SURGERIES_IN_USER_BEGIN = 'GET_SURGERIES_IN_USER_BEGIN';
export const GET_SURGERIES_IN_USER_SUCCESS = 'GET_SURGERIES_IN_USER_SUCCESS';
export const GET_SURGERIES_IN_USER_FAILURE = 'GET_SURGERIES_IN_USER_FAILURE';

// GET USER ROLS
export const GET_USER_ROLS_BEGIN = 'GET_USER_ROLS_BEGIN';
export const GET_USER_ROLS_SUCCESS = 'GET_USER_ROLS_SUCCESS';
export const GET_USER_ROLS_FAILURE = 'GET_USER_ROLS_FAILURE';

// GET USERS CHAT
export const GET_USERS_CHAT_BEGIN = 'GET_USERS_CHAT_BEGIN';
export const GET_USERS_CHAT_SUCCESS = 'GET_USERS_CHAT_SUCCESS';
export const GET_USERS_CHAT_FAILURE = 'GET_USERS_CHAT_FAILURE';

// GET CHATS FROM USER
export const GET_CHAT_FROM_USER_BEGIN = 'GET_CHAT_FROM_USER_BEGIN';
export const GET_CHAT_FROM_USER_SUCCESS = 'GET_CHAT_FROM_USER_SUCCESS';
export const GET_CHAT_FROM_USER_FAILURE = 'GET_CHAT_FROM_USER_FAILURE';

// SET USER CHAT SELECTED
export const SET_USER_CHAT_SELECTED_BEGIN = 'SET_USER_CHAT_SELECTED_BEGIN';
export const SET_USER_CHAT_SELECTED_SUCCESS = 'SET_USER_CHAT_SELECTED_SUCCESS';
export const SET_USER_CHAT_SELECTED_FAILURE = 'SET_USER_CHAT_SELECTED_FAILURE';

// SCHEDULE
export const GET_SCHEDULED_SURGERIES_BY_DATE_BEGIN = 'GET_SCHEDULED_SURGERIES_BY_DATE_BEGIN';
export const GET_SCHEDULED_SURGERIES_BY_DATE_SUCCESS = 'GET_SCHEDULED_SURGERIES_BY_DATE_SUCCESS';
export const GET_SCHEDULED_SURGERIES_BY_DATE_FAILURE = 'GET_SCHEDULED_SURGERIES_BY_DATE_FAILURE';

export const GET_STATES_IN_SURGERY_BEGIN = 'GET_STATES_IN_SURGERY_BEGIN';
export const GET_STATES_IN_SURGERY_SUCCESS = 'GET_STATES_IN_SURGERY_SUCCESS';
export const GET_STATES_IN_SURGERY_FAILURE = 'GET_STATES_IN_SURGERY_FAILURE';


// STORE HOUSE
export const ADD_SELLER_NOTES = 'ADD_SELLER_NOTES';

export const GET_QUOTE_STORE_VIEW_BEGIN = 'GET_QUOTE_STORE_VIEW_BEGIN';
export const GET_QUOTE_STORE_VIEW_SUCCES = 'GET_QUOTE_STORE_VIEW_SUCCES';
export const GET_QUOTE_STORE_VIEW_FAILURE = 'GET_QUOTE_STORE_VIEW_FAILURE';

export const GET_MAIN_SURGERY_TYPES_TO_STOREHOUSE_BEGIN = 'GET_MAIN_SURGERY_TYPES_TO_STOREHOUSE_BEGIN';
export const GET_MAIN_SURGERY_TYPES_TO_STOREHOUSE_SUCCESS = 'GET_MAIN_SURGERY_TYPES_TO_STOREHOUSE_SUCCESS';
export const GET_MAIN_SURGERY_TYPES_TO_STOREHOUSE_FAILURE = 'GET_MAIN_SURGERY_TYPES_TO_STOREHOUSE_FAILURE';

export const GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_TO_STOREHOUSE_BEGIN = 'GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_TO_STOREHOUSE_BEGIN';
export const GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_TO_STOREHOUSE_SUCCESS = 'GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_TO_STOREHOUSE_SUCCESS';
export const GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_TO_STOREHOUSE_FAILURE = 'GET_SYSTEM_BY_MAIN_SURGENCY_TYPE_TO_STOREHOUSE_FAILURE';

export const GET_APPOINTMENTS_STOREHOUSE_BEGIN = 'GET_APPOINTMENTS_STOREHOUSE_BEGIN';
export const GET_APPOINTMENTS_STOREHOUSE_SUCCESS = 'GET_APPOINTMENTS_STOREHOUSE_SUCCESS';
export const GET_APPOINTMENTS_STOREHOUSE_FAILURE = 'GET_APPOINTMENTS_STOREHOUSE_FAILURE';

export const GET_APPOINTMENTS_STOREHOUSE_BEGIN_ATQ = 'GET_APPOINTMENTS_STOREHOUSE_BEGIN_ATQ';
export const GET_APPOINTMENTS_STOREHOUSE_SUCCESS_ATQ = 'GET_APPOINTMENTS_STOREHOUSE_SUCCESS_ATQ';
export const GET_APPOINTMENTS_STOREHOUSE_FAILURE_ATQ = 'GET_APPOINTMENTS_STOREHOUSE_FAILURE_ATQ';


export const GET_APPOINTMENTS_CALENDAR_STOREHOUSE_BEGIN = 'GET_APPOINTMENTS_CALENDAR_STOREHOUSE_BEGIN';
export const GET_APPOINTMENTS_CALENDAR_STOREHOUSE_SUCCESS = 'GET_APPOINTMENTS_CALENDAR_STOREHOUSE_SUCCESS';
export const GET_APPOINTMENTS_CALENDAR_STOREHOUSE_FAILURE = 'GET_APPOINTMENTS_CALENDAR_STOREHOUSE_FAILURE';

export const SET_APPOINTMENTS_STOREHOUSE = 'SET_APPOINTMENTS_STOREHOUSE';

export const GET_LOCATIONS_STOREHOUSE_BEGIN = 'GET_LOCATIONS_STOREHOUSE_BEGIN';
export const GET_LOCATIONS_STOREHOUSE_SUCCESS = 'GET_LOCATIONS_STOREHOUSE_SUCCESS';
export const GET_LOCATIONS_STOREHOUSE_FAILURE = 'GET_LOCATIONS_STOREHOUSE_FAILURE';

export const GET_PRODUCTS_STOREHOUSE_BEGIN = 'GET_PRODUCTS_STOREHOUSE_BEGIN';
export const GET_PRODUCTS_STOREHOUSE_SUCCESS = 'GET_PRODUCTS_STOREHOUSE_SUCCESS';
export const GET_PRODUCTS_STOREHOUSE_FAILURE = 'GET_PRODUCTS_STOREHOUSE_FAILURE';

export const SEARCH_PRODUCT_STOREHOUSE_BEGIN = 'SEARCH_PRODUCT_STOREHOUSE_BEGIN';
export const SEARCH_PRODUCT_STOREHOUSE_SUCCESS = 'SEARCH_PRODUCT_STOREHOUSE_SUCCESS';
export const SEARCH_PRODUCT_STOREHOUSE_FAILURE = 'SEARCH_PRODUCT_STOREHOUSE_FAILURE';
export const SEARCH_SCANNING_PRODUCT_STOREHOUSE_SUCCESS = 'SEARCH_SCANNING_PRODUCT_STOREHOUSE_SUCCESS';

export const GET_LOCATION_BY_LOCATION_CODE_STOREHOUSE_BEGIN = 'GET_LOCATION_BY_LOCATION_CODE_STOREHOUSE_BEGIN';
export const GET_LOCATION_BY_LOCATION_CODE_STOREHOUSE_SUCCESS = 'GET_LOCATION_BY_LOCATION_CODE_STOREHOUSE_SUCCESS';
export const GET_LOCATION_BY_LOCATION_CODE_STOREHOUSE_FAILURE = 'GET_LOCATION_BY_LOCATION_CODE_STOREHOUSE_FAILURE';

export const ADD_LOCATIONS_TO_APPOINTMENT_STOREHOSE_BEGIN = 'ADD_LOCATIONS_TO_APPOINTMENT_STOREHOSE_BEGIN';
export const ADD_LOCATIONS_TO_APPOINTMENT_STOREHOSE_SUCCESS = 'ADD_LOCATIONS_TO_APPOINTMENT_STOREHOSE_SUCCESS';
export const ADD_LOCATIONS_TO_APPOINTMENT_STOREHOSE_FAILURE = 'ADD_LOCATIONS_TO_APPOINTMENT_STOREHOSE_FAILURE';

export const ADD_PRODUCTS_TO_APPOINTMENT_STOREHOSE_BEGIN = 'ADD_PRODUCTS_TO_APPOINTMENT_STOREHOSE_BEGIN';
export const ADD_PRODUCTS_TO_APPOINTMENT_STOREHOSE_SUCCESS = 'ADD_PRODUCTS_TO_APPOINTMENT_STOREHOSE_SUCCESS';
export const ADD_PRODUCTS_TO_APPOINTMENT_STOREHOSE_FAILURE = 'ADD_PRODUCTS_TO_APPOINTMENT_STOREHOSE_FAILURE';

export const UPDATE_APPOINTMENT_STATUS_STOREHOSE_BEGIN = 'UPDATE_APPOINTMENT_STATUS_STOREHOSE_BEGIN';
export const UPDATE_APPOINTMENT_STATUS_STOREHOSE_SUCCESS = 'UPDATE_APPOINTMENT_STATUS_STOREHOSE_SUCCESS';
export const UPDATE_APPOINTMENT_STATUS_STOREHOSE_FAILURE = 'UPDATE_APPOINTMENT_STATUS_STOREHOSE_FAILURE';

export const GET_PRODUCTS_TO_APPOINTMENT_STOREHOUSE_BEGIN = 'ADD_PRODUCTS_TO_APPOINTMENT_STOREHOUSE_BEGIN';
export const GET_PRODUCTS_TO_APPOINTMENT_STOREHOUSE_SUCCESS = 'ADD_PRODUCTS_TO_APPOINTMENT_STOREHOUSE_SUCCESS';
export const GET_PRODUCTS_TO_APPOINTMENT_STOREHOUSE_FAILURE = 'ADD_PRODUCTS_TO_APPOINTMENT_STOREHOUSE_FAILURE';

export const GET_APPOINTMENT_LOCATIONS_STOREHOSE_BEGIN = 'GET_APPOINTMENT_LOCATIONS_STOREHOSE_BEGIN';
export const GET_APPOINTMENT_LOCATIONS_STOREHOSE_SUCCESS = 'GET_APPOINTMENT_LOCATIONS_STOREHOSE_SUCCESS';
export const GET_APPOINTMENT_LOCATIONS_STOREHOSE_FAILURE = 'GET_APPOINTMENT_LOCATIONS_STOREHOSE_FAILURE';

export const UPDATE_APPOINTMENT_PRODUCT_STOREHOUSE_BEGIN = 'UPDATE_APPOINTMENT_PRODUCT_STOREHOUSE_BEGIN';
export const UPDATE_APPOINTMENT_PRODUCT_STOREHOUSE_SUCCESS = 'UPDATE_APPOINTMENT_PRODUCT_STOREHOUSE_SUCCESS';
export const UPDATE_APPOINTMENT_PRODUCT_STOREHOUSE_FAILURE = 'UPDATE_APPOINTMENT_PRODUCT_STOREHOUSE_FAILURE';

export const UPDATE_APPOINTMENT_LOCATION_STOREHOSE_BEGIN = 'UPDATE_APPOINTMENT_LOCATION_STOREHOSE_BEGIN';
export const UPDATE_APPOINTMENT_LOCATION_STOREHOSE_SUCCESS = 'UPDATE_APPOINTMENT_LOCATION_STOREHOSE_SUCCESS';
export const UPDATE_APPOINTMENT_LOCATION_STOREHOSE_FAILURE = 'UPDATE_APPOINTMENT_LOCATION_STOREHOSE_FAILURE';

export const UPDATE_APPOINTMENT_LOGISTICS_STOREHOSE_BEGIN = 'UPDATE_APPOINTMENT_LOGISTICS_STOREHOSE_BEGIN';
export const UPDATE_APPOINTMENT_LOGISTICS_STOREHOSE_SUCCESS = 'UPDATE_APPOINTMENT_LOGISTICS_STOREHOSE_SUCCESS';
export const UPDATE_APPOINTMENT_LOGISTICS_STOREHOSE_FAILURE = 'UPDATE_APPOINTMENT_LOGISTICS_STOREHOSE_FAILURE';

export const UPDATE_LOGISTIC_APPOINTMENT_STATUS_STOREHOSE_BEGIN = 'UPDATE_LOGISTIC_APPOINTMENT_STATUS_STOREHOSE_BEGIN';
export const UPDATE_LOGISTIC_APPOINTMENT_STATUS_STOREHOSE_SUCCESS = 'UPDATE_LOGISTIC_APPOINTMENT_STATUS_STOREHOSE_SUCCESS';
export const UPDATE_LOGISTIC_APPOINTMENT_STATUS_STOREHOSE_FAILURE = 'UPDATE_LOGISTIC_APPOINTMENT_STATUS_STOREHOSE_FAILURE';

export const ADD_NOTE_SURGERY_APPOINTMENTS_TOREHOSE_BEGIN = 'ADD_NOTE_SURGERY_APPOINTMENTS_TOREHOSE_BEGIN';
export const ADD_NOTE_SURGERY_APPOINTMENTS_TOREHOSE_SUCCESS = 'ADD_NOTE_SURGERY_APPOINTMENTS_TOREHOSE_SUCCESS';
export const ADD_NOTE_SURGERY_APPOINTMENTS_TOREHOSE_FAILURE = 'ADD_NOTE_SURGERY_APPOINTMENTS_TOREHOSE_FAILURE';

export const ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE_BEGIN = 'ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE_BEGIN';
export const ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE_SUCCESS = 'ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE_SUCCESS';
export const ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE_FAILURE = 'ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE_FAILURE';

export const REMOVE_PRODUCTS_FROM_APPOINTMENT_STOREHOUSE_BEGIN = 'REMOVE_PRODUCTS_FROM_APPOINTMENT_STOREHOUSE_BEGIN';
export const REMOVE_PRODUCTS_FROM_APPOINTMENT_STOREHOUSE_SUCCESS = 'REMOVE_PRODUCTS_FROM_APPOINTMENT_STOREHOUSE_SUCCESS';
export const REMOVE_PRODUCTS_FROM_APPOINTMENT_STOREHOUSE_FAILURE = 'REMOVE_PRODUCTS_FROM_APPOINTMENT_STOREHOUSE_FAILURE';

export const REMOVE_LOCATIONS_FROM_APPOINTMENT_STOREHOUSE_BEGIN = 'REMOVE_LOCATIONS_FROM_APPOINTMENT_STOREHOUSE_BEGIN';
export const REMOVE_LOCATIONS_FROM_APPOINTMENT_STOREHOUSE_SUCCESS = 'REMOVE_LOCATIONS_FROM_APPOINTMENT_STOREHOUSE_SUCCESS';
export const REMOVE_LOCATIONS_FROM_APPOINTMENT_STOREHOUSE_FAILURE = 'REMOVE_LOCATIONS_FROM_APPOINTMENT_STOREHOUSE_FAILURE';

export const SET_LOCATION_PRODUCT_TO_LIST_STOREHOSE = 'SET_LOCATION_PRODUCT_TO_LIST_STOREHOSE';

export const SET_LIST_APPROVE_ORDER_PREVIEW_STOREHOSE = 'SET_LIST_APPROVE_ORDER_PREVIEW_STOREHOSE';

export const RESET_TO_INITIAL_STATE_STOREHOUSE = 'RESET_TO_INITIAL_STATE_STOREHOUSE';

export const ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE = 'ADD_SURGERY_APPOINTMENT_LOGISTICS_NOTE_STOREHOUSE';

export const REQUERIMENTS_LIST_TO_APROVE_STATE_STOREHOUSE = 'REQUERIMENTS_LIST_TO_APROVE_STATE_STOREHOUSE';

export const SET_PICKUP_PLACE_STOREHOSE = 'SET_PICKUP_PLACE_STOREHOSE';
export const SET_DESTINATION_PLACE_STOREHOSE = 'SET_DESTINATION_PLACE_STOREHOSE';

export const SET_INITIAL_DATE_APPOINTENTS = 'SET_INITIAL_DATE_APPOINTENTS';
export const SET_FINAL_DATE_APPOINTENTS = 'SET_FINAL_DATE_APPOINTENTS';

export const SET_LIST_APPOIMENT = 'SET_LIST_APPOIMENT';


// ROLE GUARD

export const GET_USERS_BY_ROLE_LIST_SPECIFIC_BEGIN = ' GET_USERS_BY_ROLE_LIST_SPECIFIC_BEGIN';
export const GET_USERS_BY_ROLE_LIST_SPECIFIC_SUCCESS = 'GET_USERS_BY_ROLE_LIST_SPECIFIC_SUCCESS';
export const GET_USERS_BY_ROLE_LIST_SPECIFIC_FAILURE = 'GET_USERS_BY_ROLE_LIST_SPECIFIC_FAILURE';
export const SET_CREATE_TEAM = 'SET_CREATE_TEAM';

export const SET_TEAM_GROUP_BEGIN = 'SET_TEAM_GROUP_BEGIN';
export const SET_TEAM_GROUP_SUCCESS = 'SET_TEAM_GROUP_SUCCESS';
export const SET_TEAM_GROUP_FAILURE = 'SET_TEAM_GROUP_FAILURE';
export const GET_TEAM_GROUP_BEGIN = 'GET_TEAM_GROUP_BEGIN';
export const GET_TEAM_GROUP_SUCCESS = 'GET_TEAM_GROUP_SUCCESS';
export const GET_TEAM_GROUP_FAILURE = 'GET_TEAM_GROUP_FAILURE';

export const ADD_MEMBER_TO_GUARD_GROUP_BEGIN = 'ADD_MEMBER_TO_GUARD_GROUP_BEGIN';
export const ADD_MEMBER_TO_GUARD_GROUP_SUCCESS = 'ADD_MEMBER_TO_GUARD_GROUP_SUCCESS';
export const ADD_MEMBER_TO_GUARD_GROUP_FAILURE = 'ADD_MEMBER_TO_GUARD_GROUP_FAILURE';
export const REMOVE_MEMBER_TO_GUARD_GROUP_SUCCESS = 'REMOVE_MEMBER_TO_GUARD_GROUP_SUCCESS';
export const REMOVE_MEMBER_TO_GUARD_GROUP_FAILURE = 'REMOVE_MEMBER_TO_GUARD_GROUP_FAILURE';
export const REMOVE_MEMBER_TO_GUARD_GROUP_BEGIN = 'REMOVE_MEMBER_TO_GUARD_GROUP_BEGIN';
export const REODER_PRINCIPAL_ORDER_TO_GUARD_GROUP_BEGIN = 'REODER_PRINCIPAL_ORDER_TO_GUARD_GROUP_BEGIN';
export const REODER_PRINCIPAL_ORDER_TO_GUARD_GROUP_SUCCESS = 'REODER_PRINCIPAL_ORDER_TO_GUARD_GROUP_SUCCESS';
export const REODER_PRINCIPAL_ORDER_TO_GUARD_GROUP_FAILURE = 'REODER_PRINCIPAL_ORDER_TO_GUARD_GROUP_FAILURE';

export const GET_HOLIDAYS_BEGIN = 'GET_HOLIDAYS_BEGIN';
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
export const GET_HOLIDAYS_FAILURE = 'GET_HOLIDAYS_FAILURE';
export const ADD_HOLIDAYS_BEGIN = 'ADD_HOLIDAYS_BEGIN';
export const ADD_HOLIDAYS_SUCCESS = 'ADD_HOLIDAYS_SUCCESS';
export const ADD_HOLIDAYS_FAILURE = 'ADD_HOLIDAYS_FAILURE';
export const REMOVE_HOLIDAYS_BEGIN = 'REMOVE_HOLIDAYS_BEGIN';
export const REMOVE_HOLIDAYS_SUCCESS = 'REMOVE_HOLIDAYS_SUCCESS';
export const REMOVE_HOLIDAYS_FAILURE = 'REMOVE_HOLIDAYS_FAILURE';

export const SET_SURGERY_CHOSEED = 'SET_SURGERY_CHOSEED';


export const GET_ATQ_AVAILABLE_BEGIN = 'GET_ATQ_AVAILABLE_BEGIN';
export const GET_ATQ_AVAILABLE_SUCCESS = 'GET_ATQ_AVAILABLE_SUCCESS';
export const GET_ATQ_AVAILABLE_FAILURE = 'GET_ATQ_AVAILABLE_FAILURE';

export const UPDATE_REQUIREMENTS_HOSPITAL_BEGIN = 'UPDATE_REQUIREMENTS_HOSPITAL_BEGIN';
export const UPDATE_REQUIREMENTS_HOSPITAL_SUCCESS = 'UPDATE_REQUIREMENTS_HOSPITAL_SUCCESS';
export const UPDATE_REQUIREMENTS_HOSPITAL_FAILURE = 'UPDATE_REQUIREMENTS_HOSPITAL_FAILURE';
export const UPDATE_REQUIREMENTS_MEDIC_BEGIN = 'UPDATE_REQUIREMENTS_MEDIC_BEGIN';
export const UPDATE_REQUIREMENTS_MEDIC_SUCCESS = 'UPDATE_REQUIREMENTS_MEDIC_SUCCESS';
export const UPDATE_REQUIREMENTS_MEDIC_FAILURE = 'UPDATE_REQUIREMENTS_MEDIC_FAILURE';


export const GET_LAST_APPOINTMENTS_STOREHOUSE_BEGIN = 'GET_LAST_APPOINTMENTS_STOREHOUSE_BEGIN';
export const GET_LAST_APPOINTMENTS_STOREHOUSE_SUCCESS = 'GET_LAST_APPOINTMENTS_STOREHOUSE_SUCCESS';
export const GET_LAST_APPOINTMENTS_STOREHOUSE_FAILURE = 'GET_LAST_APPOINTMENTS_STOREHOUSE_FAILURE';
export const GET_NEXT_APPOINTMENTS_STOREHOUSE_BEGIN = 'GET_NEXT_APPOINTMENTS_STOREHOUSE_BEGIN';
export const GET_NEXT_APPOINTMENTS_STOREHOUSE_SUCCESS = 'GET_NEXT_APPOINTMENTS_STOREHOUSE_SUCCESS';
export const GET_NEXT_APPOINTMENTS_STOREHOUSE_FAILURE = 'GET_NEXT_APPOINTMENTS_STOREHOUSE_FAILURE';

export const SET_ATQ_CHOSEED = 'SET_ATQ_CHOSEED';
export const SET_DATE_CHOSEED = 'SET_DATE_CHOSEED';
export const ADD_ATQ_BEGIN = 'ADD_ATQ_BEGIN';
export const ADD_ATQ_SUCCESS = 'ADD_ATQ_SUCCESS';
export const ADD_ATQ_FAILURE = 'ADD_ATQ_FAILURE';

export const GET_APPOIMENTS_WITH_SPECIFIC_ATQ_BEGIN = 'GET_APPOIMENTS_WITH_SPECIFIC_ATQ_BEGIN';
export const GET_APPOIMENTS_WITH_SPECIFIC_ATQ_SUCCESS = 'GET_APPOIMENTS_WITH_SPECIFIC_ATQ_SUCCESS';
export const GET_APPOIMENTS_WITH_SPECIFIC_ATQ_FAILLURE = 'GET_APPOIMENTS_WITH_SPECIFIC_ATQ_FAILLURE';
export const ADD_ANSWER_ATQ_ON_APPOIMENT_BEGIN = 'ADD_ANSWER_ATQ_ON_APPOIMENT_BEGIN';
export const ADD_ANSWER_ATQ_ON_APPOIMENT_SUCCESS = 'ADD_ANSWER_ATQ_ON_APPOIMENT_SUCCESS';
export const ADD_ANSWER_ATQ_ON_APPIMENT_FAILURE = 'ADD_ANSWER_ATQ_ON_APPIMENT_FAILURE';
export const REASING_ATQ_WITH_ANSWER_BEGIN = 'REASING_ATQ_WITH_ANSWER_BEGIN';
export const REASING_ATQ_WITH_ANSWER_SUCCESS = 'REASING_ATQ_WITH_ANSWER_SUCCESS';
export const REASING_ATQ_WITH_ANSWER_FAILURE = 'REASING_ATQ_WITH_ANSWER_FAILURE';

export const GET_TEAM_GROUP_BY_ID_BEGIN = 'GET_TEAM_GROUP_BY_ID_BEGIN';
export const GET_TEAM_GROUP_BY_ID_SUCCESS = 'GET_TEAM_GROUP_BY_ID_SUCCESS';
export const GET_TEAM_GROUP_BY_ID_FAILURE = 'GET_TEAM_GROUP_BY_ID_FAILURE';

//Vendedor
export const GET_QUOTATION_SELLER_BEGIN = 'GET_QUOTATION_SELLER_BEGIN';
export const GET_QUOTATION_SELLER_SUCCESS = 'GET_QUOTATION_SELLER_SUCCESS';
export const GET_QUOTATION_SELLER_FAILURE = 'GET_QUOTATION_SELLER_FAILURE';
export const ADD_GUARD_GROUP_HOLIDAY_BEGIN = 'ADD_GUARD_GROUP_HOLIDAY_BEGIN';
export const ADD_GUARD_GROUP_HOLIDAY_SUCCESS = 'ADD_GUARD_GROUP_HOLIDAY_SUCCESS';
export const ADD_GUARD_GROUP_HOLIDAY_FAILURE = 'ADD_GUARD_GROUP_HOLIDAY_FAILURE';

export const DELETE_GUARD_GROUP_HOLIDAY_BEGIN = 'DELETE_GUARD_GROUP_HOLIDAY_BEGIN';
export const DELETE_GUARD_GROUP_HOLIDAY_SUCCESS = 'DELETE_GUARD_GROUP_HOLIDAY_SUCCESS';
export const DELETE_GUARD_GROUP_HOLIDAY_FAILURE = 'DELETE_GUARD_GROUP_HOLIDAY_FAILURE';

export const OVERRIDE_GUARD_GROUP_ORDER_BEGIN = 'OVERRIDE_GUARD_GROUP_ORDER_BEGIN';
export const OVERRIDE_GUARD_GROUP_ORDER_SUCCESS = 'OVERRIDE_GUARD_GROUP_ORDER_SUCCESS';
export const OVERRIDE_GUARD_GROUP_ORDER_FAILURE = 'OVERRIDE_GUARD_GROUP_ORDER_FAILURE';

export const SET_MODIFY_HOLIDAYS = 'SET_MODIFY_HOLIDAYS';
export const UPDATE_LIST_MEMBER = 'UPDATE_LIST_MEMBER';
export const SET_MODIFY_HOLIDAYS_FINISHED = 'SET_MODIFY_HOLIDAYS_FINISHED';

export const CREATE_REPORT_EXPERIENCE_BEGIN = 'CREATE_REPORT_EXPERIENCE_BEGIN';
export const CREATE_REPORT_EXPERIENCE_SUCCESS = 'CREATE_REPORT_EXPERIENCE_SUCCESS';
export const CREATE_REPORT_EXPERIENCE_FAILURE = 'CREATE_REPORT_EXPERIENCE_FAILURE';
export const SET_ADD_PRODUCTS_AND_SYSTEMS = 'SET_ADD_PRODUCTS_AND_SYSTEMS';

export const ADD_NOTE_OF_NOT_AVAILABLE_PRODUCT_REQUERIMENT_BEGIN = 'ADD_NOTE_OF_NOT_AVAILABLE_PRODUCT_REQUERIMENT_BEGIN';
export const ADD_NOTE_OF_NOT_AVAILABLE_PRODUCT_REQUERIMENT_SUCCESS = 'ADD_NOTE_OF_NOT_AVAILABLE_PRODUCT_REQUERIMENT_SUCCESS';
export const ADD_NOTE_OF_NOT_AVAILABLE_PRODUCT_REQUERIMENT_FAILURE = 'ADD_NOTE_OF_NOT_AVAILABLE_PRODUCT_REQUERIMENT_FAILURE';

export const SEARCH_LOCATIONS_STOREHOUSE_BEGIN = 'SEARCH_LOCATIONS_STOREHOUSE_BEGIN';
export const SEARCH_LOCATIONS_STOREHOUSE_SUCCESS = 'SEARCH_LOCATIONS_STOREHOUSE_SUCCESS';
export const SEARCH_LOCATIONS_STOREHOUSE_FAILURE = 'SEARCH_LOCATIONS_STOREHOUSE_FAILURE';

export const UPDATE_PRODUCTS_REQUERIMENT_BEGIN = 'UPDATE_PRODUCTS_REQUERIMENT_BEGIN';
export const UPDATE_PRODUCTS_REQUERIMENT_SUCCESS = 'UPDATE_PRODUCTS_REQUERIMENT_SUCCESS';
export const UPDATE_PRODUCTS_REQUERIMENT_FAILURE = 'UPDATE_PRODUCTS_REQUERIMENT_FAILURE';



export const GET_SEARCH_LIST_BEGIN_ATQ = 'GET_APPOINTMENTS_STOREHOUSE_BEGIN_ATQ';
export const GET_SEARCH_LIST_SUCCESS_ATQ = 'GET_SEARCH_LIST_SUCCESS_ATQ';
export const GET_SEARCH_LIST_FAILURE_ATQ = 'GET_SEARCH_LIST_FAILURE_ATQ';

export const UPDATE_REPORT_EXPERIENCE_BEGIN = 'UPDATE_REPORT_EXPERIENCE_BEGIN';
export const UPDATE_REPORT_EXPERIENCE_SUCCESS = 'UPDATE_REPORT_EXPERIENCE_SUCCESS';
export const UPTADE_REPORT_EXPERIENCE_FAILURE = 'UPDATE_REPORT_EXPERIENCE_FAILURE';

export const DELETE_ELEMENTS_OPTIONAL_MATERIAL = 'DELETE_ELEMENTS_OPTIONAL_MATERIAL';

export const DELETE_ELEMENT_TYPE_SURGERY = 'DELETE_ELEMENT_TYPE_SURGERY';

export const GET_SEARCH_CX_TRANSFERED_BEGIN = 'GET_SEARCH_CX_TRANSFERED_BEGIN';
export const GET_SEARCH_CX_TRANSFERED_SUCCESS = 'GET_SEARCH_CX_TRANSFERED_SUCCESS';
export const GET_SEARCH_CX_TRANSFERED_FAILURE = 'GET_SEARCH_CX_TRANSFERED_FAILURE';
export const SET_REPONSE_SEARCH = 'SET_REPONSE_SEARCH';


//Aqui comienzan los action de encuestas
export const GET_SURVEY_BEGIN = 'GET_SURVEY_BEGIN';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAILURE = 'GET_SURVEY_FAILURE';

export const SURVEY_ADMIN_ATQ_BEGIN = 'SURVEY_ADMIN_ATQ_BEGIN';
export const SURVEY_ADMIN_ATQ_SUCCESS = 'SURVEY_ADMIN_ATQ_SUCCESS';
export const SURVEY_ADMIN_ATQ_FAILURE = 'SURVEY_ADMIN_ATQ_FAILURE';


export const SURVEY_ATQ_BEGIN = 'SURVEY_ATQ_BEGIN';
export const SURVEY_ATQ_SUCCESS = 'SURVEY_ATQ_SUCCESS';
export const SURVEY_ATQ_FAILURE = 'SURVEY_ATQ_FAILURE';



//DE AQUI EN ADELANTE SON PURAS TYPE ACTIONS DE CHAT Y NOTIFICACIONES


export const ADD_SUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_BEGIN = 'ADD_SUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_BEGIN';
export const ADD_SUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_SUCCESS = 'ADD_SUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_SUCCESS';
export const ADD_SUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_FAILURE = 'ADD_SUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_FAILURE';
export const UNSUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_BEGIN = 'UNSUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_BEGIN';
export const UNSUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_SUCCESS = 'UNSUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_SUCCESS';
export const UNSUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_FAILURE = 'UNSUSCRIPTION_FOR_RECEIVE_NOTIFICATIONS_FAILURE';
export const CREATE_CHAT_GROUP_BEGIN = 'CREATE_CHAT_GROUP_BEGIN';
export const CREATE_CHAT_GROUP_SUCCESS = 'CREATE_CHAT_GROUP_SUCCESS';
export const CREATE_CHAT_GROUP_FAILURE = 'CREATE_CHAT_GROUP_FAILURE';
export const GET_CHAT_GROUP_BEGIN = 'GET_CHAT_GROUP_BEGIN';
export const GET_CHAT_GROUP_SUCCESS = 'GET_CHAT_GROUP_SUCCESS';
export const GET_CHAT_GROUP_FAILURE = 'GET_CHAT_GROUP_FAILURE';
export const DELETE_CHAT_GROUP_BEGIN = 'DELETE_CHAT_GROUP_BEGIN';
export const DELETE_CHAT_GROUP_SUCCESS = 'DELETE_CHAT_GROUP_SUCCESS';
export const DELETE_CHAT_GROUP_FAILURE = 'DELETE_CHAT_GROUP_FAILURE';
export const GET_SURGERIES_FOR_CHAT_BEGIN = 'GET_SURGERIES_FOR_CHAT_BEGIN';
export const GET_SURGERIES_FOR_CHAT_SUCCESS = 'GET_SURGERIES_FOR_CHAT_SUCCESS';
export const GET_SURGERIES_FOR_CHAT_FAILURE = 'GET_SURGERIES_FOR_CHAT_FAILURE';
export const REMOVE_MEMBER_FROM_CHAT_GROUP_BEGIN = 'REMOVE_MEMBER_FROM_CHAT_GROUP_BEGIN';
export const REMOVE_MEMBER_FROM_CHAT_GROUP_SUCCESS = 'REMOVE_MEMBER_FROM_CHAT_GROUP_SUCCESS';
export const REMOVE_MEMBER_FROM_CHAT_GROUP_FAILURE = 'REMOVE_MEMBER_FROM_CHAT_GROUP_FAILURE';
export const ADD_MEMBER_FROM_TO_GROUP_BEGIN = 'ADD_MEMBER_FROM_TO_GROUP_BEGIN';
export const ADD_MEMBER_FROM_TO_GROUP_SUCCESS = 'ADD_MEMBER_FROM_TO_GROUP_SUCCESS';
export const ADD_MEMBER_FROM_TO_GROUP_FAILURE = 'ADD_MEMBER_FROM_TO_GROUP_FAILURE';
export const GET_CHAT_GROUP_MEMBERS_BEGIN = 'GET_CHAT_GROUP_MEMBERS_BEGIN';
export const GET_CHAT_GROUP_MEMBERS_SUCCESS = 'GET_CHAT_GROUP_MEMBERS_SUCCESS';
export const GET_CHAT_GROUP_MEMBERS_FAILURE = 'GET_CHAT_GROUP_MEMBERS_FAILURE';
export const ADD_CHAT_GROUP_MESSAGE_BEGIN = 'ADD_CHAT_GROUP_MESSAGE_BEGIN';
export const ADD_CHAT_GROUP_MESSAGE_SUCCESS = 'ADD_CHAT_GROUP_MESSAGE_SUCCESS';
export const ADD_CHAT_GROUP_MESSAGE_FAILURE = 'ADD_CHAT_GROUP_MESSAGE_FAILURE';
export const GET_CHAT_GROUP_MESSAGES_BEGIN = 'GET_CHAT_GROUP_MESSAGES_BEGIN';
export const GET_CHAT_GROUP_MESSAGES_SUCCESS = 'GET_CHAT_GROUP_MESSAGES_SUCCESS';
export const GET_CHAT_GROUP_MESSAGES_FAILURE = 'GET_CHAT_GROUP_MESSAGES_FAILURE';

export const STATE_NEW_NOTIFICATION = 'STATE_NEW_NOTIFICATION';

export const GET_APPOINTMENT_NOTIFICACION_BEGIN = 'GET_APPOINTMENT_NOTIFICACION_BEGIN';
export const GET_APPOINTMENT_NOTIFICACION_SUCCESS = 'GET_APPOINTMENT_NOTIFICACION_SUCCESS';
export const GET_APPOINTMENT_NOTIFICACION_FAILURE = 'GET_APPOINTMENT_NOTIFICACION_FAILURE';

export const SURVEY_CLASIFICATION_BEGIN = 'SURVEY_CLASIFICATION_BEGIN';
export const SURVEY_CLASIFICATION_SUCCESS = 'SURVEY_CLASIFICATION_SUCCESS';
export const SURVEY_CLASIFICATION_FAILURE = 'SURVEY_CLASIFICATION_FAILURE';

export const SURVEY_QUALITY_MANAGER_BEGIN = 'SURVEY_QUALITY_MANAGER_BEGIN';
export const SURVEY_QUALITY_MANAGER_SUCCESS = 'SURVEY_QUALITY_MANAGER_SUCCESS';
export const SURVEY_QUALITY_MANAGER_FAILURE = 'SURVEY_QUALITY_MANAGER_FAILURE';

export const SURVEY_FIRST_CLASIFICATION_BEGIN = 'SURVEY_FIRST_CLASIFICATION_BEGIN';
export const SURVEY_FIRST_CLASIFICATION_SUCCESS = 'SURVEY_FIRST_CLASIFICATION_SUCCESS';
export const SURVEY_FIRST_CLASIFICATION_FAILURE = 'SURVEY_FIRST_CLASIFICATION_FAILURE';

export const SURVEY_MAINTENANCE_BEGIN = 'SURVEY_MAINTENANCE_BEGIN';
export const SURVEY_MAINTENANCE_SUCCESS = 'SURVEY_MAINTENANCE_SUCCESS';
export const SURVEY_MAINTENANCE_FAILURE = 'SURVEY_MAINTENANCE_FAILURE';

export const SURVEY_MEDIC_BEGIN = 'SURVEY_MEDIC_BEGIN';
export const SURVEY_MEDIC_SUCCESS = 'SURVEY_MEDIC_SUCCESS';
export const SURVEY_MEDIC_FAILURE = 'SURVEY_MEDIC_FAILURE';

export const SURVEY_SALES_BEGIN = 'SURVEY_SALES_BEGIN';
export const SURVEY_SALES_SUCCESS = 'SURVEY_SALES_SUCCESS';
export const SURVEY_SALES_FAILURE = 'SURVEY_SALES_FAILURE';

export const SURVEY_SNC_BEGIN = 'SURVEY_SNC_BEGIN';
export const SURVEY_SNC_SUCCESS = 'SURVEY_SNC_SUCCESS';
export const SURVEY_SNC_FAILURE = 'SURVEY_SNC_FAILURE';

export const SURVEY_ISSUE_CAUSE_BEGIN = 'SURVEY_ISSUE_CAUSE_BEGIN';
export const SURVEY_ISSUE_CAUSE_SUCCESS = 'SURVEY_ISSUE_CAUSE_SUCCESS';
export const SURVEY_ISSUE_CAUSE_FAILURE = 'SURVEY_ISSUE_CAUSE_FAILURE';

export const SURVEY_STATUS_BEGIN = 'SURVEY_STATUS_BEGIN';
export const SURVEY_STATUS_SUCCESS = 'SURVEY_STATUS_SUCCESS';
export const SURVEY_STATUS_FAILURE = 'SURVEY_STATUS_FAILURE';

export const SURVEY_WAREHOUSE_BEGIN = 'SURVEY_WAREHOUSE_BEGIN';
export const SURVEY_WAREHOUSE_SUCCESS = 'SURVEY_WAREHOUSE_SUCCESS';
export const SURVEY_WAREHOUSE_FAILURE = 'SURVEY_WAREHOUSE_FAILURE';

export const SURVEY_PENDING_SURVEY_BEGIN = 'SURVEY_PENDING_SURVEY_BEGIN';
export const SURVEY_PENDING_SURVEEY_SUCCESS = 'SURVEY_PENDING_SURVEEY_SUCCESS';
export const SURVEY_PENDING_SURVEEY_FAILURE = 'SURVEY_PENDING_SURVEEY_FAILURE';

export const SURVEY_SET_SURVEY_INFORMATION_BEGIN = 'SURVEY_SET_SURVEY_INFORMATION_BEGIN';
export const SURVEY_SET_SURVEY_INFORMATION_SUCCESS = 'SURVEY_SET_SURVEY_INFORMATION_SUCCESS';
export const SURVEY_SET_SURVEY_INFORMATION_FAILURE = 'SURVEY_SET_SURVEY_INFORMATION_FAILURE';

export const SET_SURVEY_SELECTED = 'SET_SURVEY_SELECTED';

export const SET_SURVEY_COMMENTARY_ATQ_BEGIN = 'SET_SURVEY_COMMENTARY_ATQ_BEGIN';
export const SET_SURVEY_COMMENTARY_ATQ_SUCCESS = 'SET_SURVEY_COMMENTARY_ATQ_SUCCESS';
export const SET_SURVEY_COMMENTARY_ATQ_FAILURE = 'SET_SURVEY_COMMENTARY_ATQ_FAILURE';

export const SET_SURVEY_COMMENTARY_SELLER_BEGIN = 'SET_SURVEY_COMMENTARY_SELLER_BEGIN';
export const SET_SURVEY_COMMENTARY_SELLER_SUCCESS = 'SET_SURVEY_COMMENTARY_SELLER_SUCCESS';
export const SET_SURVEY_COMMENTARY_SELLER_FAILURE = 'SET_SURVEY_COMMENTARY_SELLER_FAILURE';

export const SET_SURVEY_COMMENTARY_MAINTENANCE_BEGIN = 'SET_SURVEY_COMMENTARY_MAINTENANCE_BEGIN';
export const SET_SURVEY_COMMENTARY_MAINTENANCE_SUCCESS = 'SET_SURVEY_COMMENTARY_MAINTENANCE_SUCCESS';
export const SET_SURVEY_COMMENTARY_MAINTENANCE_FAILURE = 'SET_SURVEY_COMMENTARY_MAINTENANCE_FAILURE';
export const SET_SURVEY_COMMENTARY_ADMIN_ATQ_BEGIN = 'SET_SURVEY_COMMENTARY_ADMIN_ATQ_BEGIN';
export const SET_SURVEY_COMMENTARY_ADMIN_ATQ_SUCCESS = 'SET_SURVEY_COMMENTARY_ADMIN_ATQ_SUCCESS';
export const SET_SURVEY_COMMENTARY_ADMIN_ATQ_FAILURE = 'SET_SURVEY_COMMENTARY_ADMIN_ATQ_FAILURE';
export const SET_SURVEY_COMMENTARY_WAREHOUSE_BEGIN = 'SET_SURVEY_COMMENTARY_WAREHOUSE_BEGIN';
export const SET_SURVEY_COMMENTARY_WAREHOUSE_SUCCESS = 'SET_SURVEY_COMMENTARY_WAREHOUSE_SUCCESS';
export const SET_SURVEY_COMMENTARY_WAREHOUSE_FAILURE = 'SET_SURVEY_COMMENTARY_WAREHOUSE_FAILURE';


export const GET_SURVEY_FROM_ID_BEGIN = 'GET_SURVEY_FROM_ID_BEGIN';
export const GET_SURVEY_FROM_ID_SUCCESS = 'GET_SURVEY_FROM_ID_SUCCESS';
export const GET_SURVEY_FROM_ID_FAILURE = 'GET_SURVEY_FROM_ID_FAILURE';

export const GET_APPOINTMENTS_BEGIN_ATQ = 'GET_APPOINTMENTS_BEGIN_ATQ';
export const GET_APPOINTMENTS_SUCCESS_ATQ = 'GET_APPOINTMENTS_SUCCESS_ATQ';
export const GET_APPOINTMENTS_FAILURE_ATQ = 'GET_APPOINTMENTS_FAILURE_ATQ';



export const GET_APPOINTMENTS_STOREHOUSE_REDUCER_BEGIN = 'GET_APPOINTMENTS_STOREHOUSE_REDUCER_BEGIN';
export const GET_APPOINTMENTS_STOREHOUSE_REDUCER_SUCCESS = 'GET_APPOINTMENTS_STOREHOUSE_REDUCER_SUCCESS';
export const GET_APPOINTMENTS_STOREHOUSE_REDUCER_FAILURE = 'GET_APPOINTMENTS_STOREHOUSE_REDUCER_FAILURE';

export const GET_SURGERY_CHAT_SELECTED = 'GET_SURGERY_CHAT_SELECTED';

export const GET_CHAT_GROUP_SELECTED = 'GET_CHAT_GROUP_SELECTED';

export const GET_OPEN_CHAT = 'GET_OPEN_CHAT';
export const GET_OPEN_MESSAGES_GENERAL = 'GET_OPEN_MESSAGES_GENERAL';
export const GET_OPEN_NOTIFICATION_GENERAL = 'GET_OPEN_NOTIFICATION_GENERAL';


export const GET_ACTIVE_USERS_BEGING = 'GET_ACTIVE_USERS_BEGING';
export const GET_ACTIVE_USERS_SUCCESS = 'GET_ACTIVE_USERS_SUCCESS';
export const GET_ACTIVE_USERS_FAILURE = 'GET_ACTIVE_USERS_FAILURE';



export const GET_SEARCH_SURGERY_BEGING = 'GET_SEARCH_SURGERY_BEGING';
export const GET_SEARCH_SURGERY_SUCCESS = 'GET_SEARCH_SURGERY_SUCCESS';
export const GET_SEARCH_SURGERY_FAILURE = 'GET_SEARCH_SURGERY_FAILURE';

export const MODAL_MEMBER_STATUS = 'MODAL_MEMBER_STATUS';

export const SEARCH_GROUP_SURGERY = 'SEARCH_GROUP_SURGERY';


export const SEARCH_GROUP_SURGERY_SESIONS = 'SEARCH_GROUP_SURGERY_SESIONS';

export const OPTION_SELECTED_BEGIN = 'OPTION_SELECTED_BEGIN';
export const OPTION_SELECTED_SUCCESS = 'OPTION_SELECTED_SUCCESS';
export const OPTION_SELECTED_FAILURE = 'OPTION_SELECTED_FAILURE';

export const GET_CHAT_GROUP_SESIONS_BEGIN = 'GET_CHAT_GROUP_SESIONS_BEGIN';
export const GET_CHAT_GROUP_SESIONS_SUCCESS = 'GET_CHAT_GROUP_SESIONS_SUCCESS';
export const GET_CHAT_GROUP_SESIONS_FAILURE = 'GET_CHAT_GROUP_SESIONS_FAILURE';

export const SET_SELECTED_SURGERY_BEGIN = 'SET_SELECTED_SURGERY_BEGIN';
export const SET_SELECTED_SURGERY_SUCCESS = 'SET_SELECTED_SURGERY_SUCCESS';
export const SET_SELECTED_SURGERY_FAILURE = 'SET_SELECTED_SURGERY_FAILURE';

export const GET_OPEN_SURGERY = 'GET_OPEN_SURGERY';

export const SET_SELECTED_CHAT_BEGIN = 'SET_SELECTED_CHAT_BEGIN';
export const SET_SELECTED_CHAT_SUCCESS = 'SET_SELECTED_CHAT_SUCCESS';
export const SET_SELECTED_CHAT_FAILURE = 'SET_SELECTED_CHAT_FAILURE';

export const GET_CHAT_MEMBERS_BEGIN = 'GET_CHAT_MEMBERS_BEGIN';
export const GET_CHAT_MEMBERS_SUCCESS = 'GET_CHAT_MEMBERS_SUCCESS';
export const GET_CHAT_MEMBERS_FAILURE = 'GET_CHAT_MEMBERS_FAILURE';


export const GET_APPOINTMENTS_BEGIN = 'GET_APPOINTMENTS_BEGIN';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAILURE = 'GET_APPOINTMENTS_FAILURE';

export const GET_CHAT_MESSAGES_BEGIN = 'GET_CHAT_MESSAGES_BEGIN';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAILURE = 'GET_CHAT_MESSAGES_FAILURE';

export const GET_INSTITUTIONS_SESIONS_BEGIN = 'GET_INSTITUTIONS_SESIONS_BEGIN';
export const GET_INSTITUTIONS_SESIONS_SUCCESS = 'GET_INSTITUTIONS_SESIONS_SUCCESS';
export const GET_INSTITUTIONS_SESIONS_FAILURE = 'GET_INSTITUTIONS_SESIONS_FAILURE';

export const GET_PATIENT_SESIONS_BEGIN = 'GET_PATIENT_SESIONS_BEGIN';
export const GET_PATIENT_SESIONS_SUCCESS = 'GET_PATIENT_SESIONS_SUCCESS';
export const GET_PATIENT_SESIONS_FAILURE = 'GET_PATIENT_SESIONS_FAILURE';


export const GET_SEARCH_BEGING = 'GET_SEARCH_BEGING';
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS';
export const GET_SEARCH_FAILURE = 'GET_SEARCH_FAILURE';


export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE  '
export const SEARCH_USER_BEGIN = 'SEARCH_USER_BEGIN'
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS'

export const SEARCH_INSTITUTION_BEGIN = 'SEARCH_INSTITUTION_BEGIN';
export const SEARCH_INSTITUTION_SUCCESS = 'SEARCH_INSTITUTION_SUCCESS';
export const SEARCH_INSTITUTION_FAILURE = 'SEARCH_INSTITUTION_FAILURE';

export const SEARCH_PATIENT_SESIONS_BEGIN = 'SEARCH_PATIENT_SESIONS_BEGIN';
export const SEARCH_PATIENT_SESIONS_SUCCESS = 'SEARCH_PATIENT_SESIONS_SUCCESS';
export const SEARCH_PATIENT_SESIONS_FAILURE = 'SEARCH_PATIENT_SESIONS_FAILURE';

export const SEARCH_ATQ_BEGIN = 'SEARCH_ATQ_BEGIN';
export const SEARCH_ATQ_SUCCESS = 'SEARCH_ATQ_SUCCESS';
export const SEARCH_ATQ_FAILURE = 'SEARCH_ATQ_FAILURE';

export const SEARCH_SURVEY_TO_SURGERY_ID_BEGIN = 'SEARCH_SURVEY_TO_SURGERY_ID_BEGIN';
export const SEARCH_SURVEY_TO_SURGERY_ID_SUCCESS = 'SEARCH_SURVEY_TO_SURGERY_ID_SUCCESS';
export const SEARCH_SURVEY_TO_SURGERY_ID_FAILURE = 'SEARCH_SURVEY_TO_SURGERY_ID_FAILURE';


export const GET_USER_BY_USER_ID_BEGIN = 'GET_USER_BY_USER_ID_BEGIN';
export const GET_USER_BY_USER_ID_SUCCESS = 'GET_USER_BY_USER_ID_SUCCESS';
export const GET_USER_BY_USER_ID_FAILURE = 'GET_USER_BY_USER_ID_FAILURE';

export const DELETE_WAREHOUSE_NOTE_BEGIN = 'DELETE_WAREHOUSE_NOTE_BEGIN';
export const DELETE_WAREHOUSE_NOTE_SUCCESS = 'DELETE_WAREHOUSE_NOTE_SUCCESS';
export const DELETE_WAREHOUSE_NOTE_FAILURE = 'DELETE_WAREHOUSE_NOTE_FAILURE';

export const PATIENT_PARAMS_SESIONS_BEGIN = 'PATIENT_PARAMS_SESIONS_BEGIN';
export const PATIENT_PARAMS_SESIONS_SUCCESS = 'PATIENT_PARAMS_SESIONS_SUCCESS';
export const PATIENT_PARAMS_SESIONS_FAILURE = 'PATIENT_PARAMS_SESIONS_FAILURE';

export const POST_ADD_SHIPPING_TO_SYSTEM_BEGIN = 'POST_ADD_SHIPPING_TO_SYSTEM_BEGIN';
export const POST_ADD_SHIPPING_TO_SYSTEM_SUCCESS = 'POST_ADD_SHIPPING_TO_SYSTEM_SUCCESS';
export const POST_ADD_SHIPPING_TO_SYSTEM_FAILURE = 'POST_ADD_SHIPPING_TO_SYSTEM_FAILURE';

//GET CLIENT_BY_RFC
export const GET_CLIENT_BY_RFC_BEGIN = 'GET_CLIENT_BY_RFC_BEGIN';
export const GET_CLIENT_BY_RFC_SUCCESS = 'GET_CLIENT_BY_RFC_SUCCESS';
export const GET_CLIENT_BY_RFC_FAILURE = 'GET_CLIENT_BY_RFC_FAILURE';

//GET CLIENT_BY_RFC
export const ADD_CLIENT_BEGIN = 'ADD_CLIENT_BEGIN';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';

//ADD CONTACT
export const ADD_CONTACT_BEGIN = 'ADD_CONTACT_BEGIN';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAILURE = 'ADD_CONTACT_FAILURE';

// ADD CLIENT BILLING
export const ADD_CLIENT_BILLING_BEGIN = 'ADD_CLIENT_BILLING_BEGIN';
export const ADD_CLIENT_BILLING_SUCCESS = 'ADD_CLIENT_BILLING_SUCCESS';
export const ADD_CLIENT_BILLING_FAILURE = 'ADD_CLIENT_BILLING_FAILURE';

//UPDATE CLIENT BILLING
export const UPDATE_CLIENT_BILLING_BEGIN = 'UPDATE_CLIENT_BILLING_BEGIN';
export const UPDATE_CLIENT_BILLING_SUCCESS = 'UPDATE_CLIENT_BILLING_SUCCESS';
export const UPDATE_CLIENT_BILLING_FAILURE = 'UPDATE_CLIENT_BILLING_FAILURE';

//UPDATE CLIENT
export const UPDATE_CLIENT_BEGIN = "UPDATE_CLIENT_BEGIN";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

// ADD ORDERS
export const ADD_ORDERS_BEGIN = 'ADD_ORDERS_BEGIN';
export const ADD_ORDERS_SUCCESS = 'ADD_ORDERS_SUCCESS';
export const ADD_ORDERS_FAILURE = 'ADD_ORDERS_FAILURE';

// ADD ORDER DETAILS
export const ADD_ORDER_DETAILS_BEGIN = 'ADD_ORDER_DETAILS_BEGIN';
export const ADD_ORDER_DETAILS_SUCCESS = 'ADD_ORDER_DETAILS_SUCCESS';
export const ADD_ORDER_DETAILS_FAILURE = 'ADD_ORDER_DETAILS_FAILURE';

// GET CONTACT
export const GET_CONTACT_BEGIN = 'GET_CONTACT_BEGIN';
export const GET_CONTACT_SUCCESS = 'ADD_ORDER_DETAILS_SUCCESS';
export const GET_CONTACT_FAILURE = 'GET_CONTACT_FAILURE';

// CREATE PATIENT
export const CREATE_PATIENT_BEGIN = 'CREATE_PATIENT_BEGIN';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAILURE = 'CREATE_PATIENT_FAILURE';

// GET ORDERS
export const GET_ORDERS_BEGIN = 'GET_ORDERS_BEGIN';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

// GET ORDERDetails
export const GET_ORDER_DETAILS_BEGIN = 'GET_ORDER_DETAILS_BEGIN';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE';

// GET ORDER CLIENT DATA
export const GET_ORDER_CLIENT_DATA_BEGIN = 'GET_ORDER_CLIENT_DATA_BEGIN';
export const GET_ORDER_CLIENT_DATA_SUCCESS = 'GET_ORDER_CLIENT_DATA_SUCCESS';
export const GET_ORDER_CLIENT_DATA_FAILURE = 'GET_ORDER_CLIENT_DATA_FAILURE';

// GET ORDER BILLING
export const GET_ORDER_BILLING_DATA_BEGIN = 'GET_ORDER_BILLING_DATA_BEGIN';
export const GET_ORDER_BILLING_DATA_SUCCESS = 'GET_ORDER_BILLING_DATA_SUCCESS';
export const GET_ORDER_BILLING_DATA_FAILURE = 'GET_ORDER_BILLING_DATA_FAILURE';

// GET ORDER BILLING
export const GET_ORDER_DELIVERY_DATA_BEGIN = 'GET_ORDER_DELIVERY_DATA_BEGIN';
export const GET_ORDER_DELIVERY_DATA_SUCCESS = 'GET_ORDER_DELIVERY_DATA_SUCCESS';
export const GET_ORDER_DELIVERY_DATA_FAILURE = 'GET_ORDER_DELIVERY_DATA_FAILURE';

// GET ORDER CONFIG
export const GET_ORDER_CONFIG_BEGIN = 'GET_ORDER_CONFIG_BEGIN';
export const GET_ORDER_CONFIG_SUCCESS = 'GET_ORDER_CONFIG_SUCCESS';
export const GET_ORDER_CONFIG_FAILURE = 'GET_ORDER_CONFIG_FAILURE';

// SET SHOPPING CART
export const SET_SHOPPING_CART = 'SET_SHOPPING_CART';

// GET OFFICE PARCELS
export const GET_OFFICE_PARCEL = "GET_OFFICE_PARCEL";
export const GET_OFFICE_PARCELS_SUCCESS = "GET_OFFICE_PARCELS_SUCCESS";
export const GET_OFFICE_PARCELS_FAILURE = "GET_OFFICE_PARCELS_FAILURE";