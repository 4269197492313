import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { searchGroupSurgerySesions } from '../../actions/allUsersAction';
import { searchGroupSurgery } from '../../actions/notification-chatAction';
import icoBuscarGris from '../../assets/img/126PX/Icono_Buscar_Gris_126px.png';


export const SearchChatGroupComponent = ({ tipo }) => {
    const [searchUser, setSearchUser] = useState('');
    const dispatch = useDispatch();
    const chatGroup = useSelector(state => state.notificationChat.getGroup);
    const chaGroupSesions = useSelector(state => state.allUsers.getGroup)
    const [busqueda, setBusqueda] = useState(null)

    useEffect(() => {
        if (busqueda !== null && !tipo) {
            dispatch(searchGroupSurgery(busqueda))
        } else {
            dispatch(searchGroupSurgerySesions(busqueda))
        }
    }, [busqueda])


   /*  console.warn('searchUser', searchUser) */


    const handleSearch = (e) => {
        e.preventDefault();
        if (!tipo) {
            setBusqueda(chatGroup.filter(item =>
                item.chatGroupName.toUpperCase() === searchUser.toUpperCase()

            ))
        } else {
            setBusqueda(chaGroupSesions.filter(item =>
                item.chatGroupName.toUpperCase() === searchUser.toUpperCase()

            ))

        }


    };

    return (
        <form onSubmit={handleSearch} className="SearchChatComponent-tools-c-input-search">
            <button className="c-SearchChatComponent-tools-search-icobuscar" type='submit'>
                <img src={icoBuscarGris} alt="" />
            </button>
            <input
                type="text"
                placeholder="Buscar grupo..."
                name="search"
                onChange={({ target }) => setSearchUser(target.value)}
                value={searchUser}
                className="SearchChatComponent-tools-input-search"
                autoComplete="off"
            />
        </form>
    )
}
