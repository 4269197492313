import React from 'react'

export const DateComp = ({setDate,setFecha,fecha,date}) => {
  return (
    <div className='container-fecha'>
                        <div className='body-fecha'>
                            <p className='text-fech'>Fecha:</p>
                        </div>

                        <div className='body-inputs-fecha'>
                            <input className="input-fecha" type="date" value={date}  onChange={e => setDate(e.target.value)} />
                        </div>
                        <div className='cont-button-fecha'>
                                <button className='btn-fecha' onClick={() => setFecha(!fecha)}>ACEPTAR</button>
                            </div>
                    </div>
  )
}
