export default async function ({method,body, headers=null, state}) {
    // Aca hay que validar que el token no este vencido
    return fetch(`https://tsiapi.mob-tk.com/processrequest/processrequest-add/`, {
        method,
        headers: headers ? headers : state.headers,
        body: method === "GET" ? null : JSON.stringify({
            ...state.body,
            ...body,
        }),
    });
}