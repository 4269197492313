import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ATQ_Modal = (props) => {
    const handleSubmit = () => {
         props.toggle();
     }

     const datos = [{
                registroMe : '151C2003SSA/1605E2003SSA',
                caracteriticasGen:[
                    {
                        descripcion : 'Sistema modular para estabilización y fijación posterior, con un rango de aplicación desde cervical hasta torácica alta y para toda la columna en casos pediátricos El sistema Neon ofrece opciones de estabilización por medio de:',
                        listaCaracteristicas : [
                                'Tornillos para masa lateral de3 3.5 mm no canulado.',
                                'Tornillos pediculares de 4.00 mm canulados.',
                                'Tornillos transarticulares autoperforantes para fusiones C1-C2.',
                                'Tornillos pediculares de 5.0 mm canulados para la columna torácica alta y aplicaciones pediátricas.',
                                'Barras de 4.25 mm.',
                                'Ganchos para estabilizaciones atlanto-axiales.',
                                'Placas occipito-cervicales.',
                        ],
                     }
                    ],
                ventajas:[
                    'Múltiples opciones de aplicación.',
                    'Sistema altamente versátil y fácil de aplicar.',
                    'Sistema poliaxial.',
                    'Sistema altamente versátil y fácil de aplicar.',
                    'Instrumental avanzado para cualquier emergencia.',
                    'Puede ser conectado a nuestros sistemas toraco-lumbares.',
                ],
     }]
     const [data,setData] = useState(datos);

    return (
        <div className="atq-prouct">
            <div className="atq-prouct-c-list-items">
            
            <div className="atq-prouct-c-list-items-scroll">
                    <span className='principal-title'> {`Registro sanitario: ${data[0].registroMe}`}</span>
                    <span className='second-title'> {'Caracteristicas Generales'}</span>
                    <span className='description'> {`${data[0].caracteriticasGen[0].descripcion}`}</span>
                    
                        <ul className='style-list'>
                        {
                         data[0].ventajas.map(item => (
                                <li className='item-list'>{item}</li>
                        ))
                        }
                       </ul>
                    
                    <span className='second-title'> {'Ventajas'}</span>
                    <ul className='style-list'>
                        {
                         data[0].caracteriticasGen[0].listaCaracteristicas.map(item => (
                                <li className='item-list'>{item}</li>
                        ))
                        }
                       </ul>
          </div>
            <button className="atq-prouct-button" onClick={() => handleSubmit()}>
                CONTINUAR
            </button>
        </div>
        </div>
    );
}
ATQ_Modal.propTypes = {
    toggle: PropTypes.any,
  };
