import React from 'react';
import PropTypes from 'prop-types';
import { StoreHouseAssignSystemAddNoteFormModal } from './StoreHouseAssignSystemAddNoteFormModal';
import CustomModal from '../../../components/shared/Modal';


export const StoreHouseAssignSystemAddNoteModal = ({isOpen, toggle,handleClose,setDescription}) => {
    const modalBody = () => {
        return (
            <StoreHouseAssignSystemAddNoteFormModal toggle={toggle} setDescription={setDescription}/>
        );
    };

    return (
        <CustomModal
                isOpen={isOpen}
                toggle={handleClose}
                title={'NOTA DE ASIGNACIÓN'}
                body={modalBody()}
        />
    )

}

StoreHouseAssignSystemAddNoteModal.propTypes = {
    isOpen:             PropTypes.any,
    toggle:             PropTypes.any,
    typeView:           PropTypes.any,
    nameAndTypeModal:   PropTypes.any,
};