import React, { useState } from 'react'
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import ico_con_Comentarios from '../../../assets/img/126PX/icon_quality_conmentarios.png';
import ico_con_sincomentarios from '../../../assets/img/126PX/Icon_quality_sincomentario.png';
import icon_search from '../../../assets/img/126PX/Icono_Buscar_Azul_126px.png';

import { useHistory } from 'react-router-dom';
import './style.scss'
import { QuaListSurvey } from '../../../components/QualityManager/listSurvey/QuaListSurvey';
import { useDispatch } from 'react-redux';
import { formatDataBase, formatDate } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { getSurveyToSearch, searchSurveyBySurgerySuccess } from '../../../actions/surveyAction';
import { ComingSoonComponent } from '../../ComingSoon/ComingSoonComponent';



const Quality_Encuestas_Page = () => {
    const [date, setDate] = useState();
    const [from,setFrom] = useState('dad');
    const dispatch = useDispatch();
    const [btnSelected, setBtnSelected] = useState({
        sinRespuesta: false,
        pendiente: true,
        seguimiento:false,
        cerradas:false,
    });
    const handleChangeBtn = (type) => {
        setFrom('dad')
        if(type === 'SINRESPUESTA') {
            setBtnSelected({
                sinRespuesta: true,
                pendiente: false,
                seguimiento:false,
                cerradas:false,
            })
        }else if(type === 'PENDIENTES') {
            setBtnSelected({
                sinRespuesta: false,
                pendiente:true,
                seguimiento:false,
                cerradas:false,
            })
        }else if(type === 'SEGUIMIENTO') {
            setBtnSelected({
                sinRespuesta: false,
                pendiente: false,
                seguimiento:true,
                cerradas:false,
            })
        }else if(type === 'CERRADAS') {
            setBtnSelected({
                sinRespuesta: false,
                pendiente: false,
                seguimiento:false,
                cerradas:true,
            })
        }
        dispatch(searchSurveyBySurgerySuccess(null))
    }

    const active = {
        background: '#37a7b9',
        background: 'linear-gradient(270deg, #1abc9c 0%, #094070 91%)',
    }

    const handleDate = (value) => {
        setDate(value);
    }
    const handleClickSearch = () => {
        if(date){


            if (btnSelected.sinRespuesta === true) {
                dispatch(getSurveyToSearch(1, 4,formatDataBase(date), 0, 100))
            }
            else if (btnSelected.pendiente === true) {
                dispatch(getSurveyToSearch(2, 4,formatDataBase(date), 0, 100))
            }
            else if (btnSelected.seguimiento === true) {
                dispatch(getSurveyToSearch(3, 4,formatDataBase(date), 0, 100))
            }
            else if (btnSelected.cerradas === true) {
                dispatch(getSurveyToSearch(4, 4,formatDataBase(date), 0, 100))
            }
        }
    }

    return (
        <>
        <ComingSoonComponent />
        {/*<div className='precontainer_encue'>
            <SubNavBarComponent className="navbar" title={'ENCUESTAS'} searchActive={true} historyPage={'/Inicio'} typeSearch={'SurveyQuality'} specificType={'id'}/>
            <div className='container_encue'>
                <div className='container-header'>
                    <div className="container-left">
                        <div className="container-fecha">
                            <span className='span-cx'>Fecha CX</span>
                            <input className="calendar_atq" type="date" onChange={e => handleDate(e.target.value)} />
                        </div>
                        <img src={icon_search} onClick={ () => handleClickSearch()} className="img-search" />
                    </div>
                    <div className="container-right">
                        <div className='comentarios-left'>
                            <img src={ico_con_Comentarios} className="img-comentarios" />
                            <p className='con-p'>Con comentarios</p>
                        </div>
                        <div className='comentarios-rigth'>
                            <img src={ico_con_sincomentarios} className="img-sin-comentarios" />
                            <p className='sin-p'>Sin comentarios</p>
                        </div>
                    </div>
                </div>
                <div className="container-buttons">
                    <div className="respuesta-btn">
                    <button className='button-sin-respuesta' style={ btnSelected.sinRespuesta ? active : {}} onClick={() => handleChangeBtn('SINRESPUESTA')}>SIN RESPUESTA</button>
                    </div>
                    <div className="pendiente-btn">
                    <button className='button-pendientes' style={ btnSelected.pendiente ? active : {}} onClick={() => handleChangeBtn('PENDIENTES')}>PENDIENTES</button>
                    </div>
                    <div className="seguimineto-btn">
                    <button className='button-seguimiento' style={ btnSelected.seguimiento ? active : {}} onClick={() => handleChangeBtn('SEGUIMIENTO')}>SEGUIMINETO</button>
                    </div>
                    <div className="cerradas-btn">
                    <button className='button-cerradas' style={ btnSelected.cerradas ? active : {}} onClick={() => handleChangeBtn('CERRADAS')}>CERRADAS</button>
                    </div>

                </div>
                <QuaListSurvey
                    btnSelected={btnSelected}
                    setBtnSelected={setBtnSelected}
                    setFrom = {setFrom}
                    from = {from}
                />

            </div>
        </div>*/}
        </>
    )
}

export default Quality_Encuestas_Page

