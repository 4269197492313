import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { AddHospitalRequirement, AddMedicRequirement} from '../../../actions/systemsAction';

export const Mess_Mod_Comp = (props) => {
    const [textStatus, setTextStatus] = useState(null);
    const dispatch = useDispatch();
    const handleSubmit = () => {

        
       if (props.page === 'hospital'){
           
        dispatch(AddHospitalRequirement({
            requirement: textStatus,
            hospitalId: props.selectedHospitals.id
        }));
        props.setList1(null);
            
        }
        if (props.page === 'medicos'){

            dispatch(AddMedicRequirement({
                requirement: textStatus,
                medicId: props.selectedMedics.id
            }));
            props.setList1(null);
       }
        props.toggle();
    }
    return (
        <div className="mess_medic">
            <form
            className="mess_medic-c-list-items"
            onSubmit={() => handleSubmit()}
            >
            <div className="mess_medic-c-list-items-scroll">
                
                    <textarea
                        className="mess_medic-modal"
                        type="text"
                        name="institution"
                        value={textStatus && textStatus}
                        onChange={(e) => setTextStatus(e.target.value)}
                    />
                    </div>
            <button className="mess_medic-button" type="submit">
                ACEPTAR
            </button>
        </form>
        </div>
    )
}
Mess_Mod_Comp.propTypes = {
    toggle: PropTypes.any,
  };