import React from 'react';
import { NotificationItemsComponent } from './NotificationItemsComponent';



export const NotificationComponent = () => {

    
    
    return (

        <div className="ChatComponent-container" >
      
        <div className="ChatComponent-subContainer">
            <div className="ChatComponent-header-container">
                <div className="ChatComponent-header-info-container">
                    <h3 className="ChatComponent-header-info">Notificaciones</h3>
                    <img src="" alt="" className="" />
                </div>  
            </div>
            <div className="ChatComponent-messages-info-container">  
            <NotificationItemsComponent/>
            </div>
        </div>
    </div>
    )
}
