import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUsersByRoleId } from '../../../actions/atqAdministradorAction';
import { ATQ_GuardRole_CreateTeam_Component } from '../../../components/ATQAdministrator/ATQ_GuardRole_CreateTeam_Component/ATQ_GuardRole_CreateTeam_Component';
import { SubNavBarComponent } from '../../../components/layouts/layout1/subnavbar/SubNavBarComponent';
import "./sass/styles.scss";


export const ATQ_GuardRole_CreateTeam_View = () => {

    const dispatch = useDispatch();
    useEffect( () => {
        dispatch(getUsersByRoleId({roleId:5}));
    },[])


    return (
        <div className="ATQ_GuardRole_CreateTeam_View-container">
            <SubNavBarComponent searchActive={false} historyPage={"/ATQ_GuardRole_View"} title={"ROL DE GUARDÍAS"}/>
            <ATQ_GuardRole_CreateTeam_Component />
        </div>
    )
}
