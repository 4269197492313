import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addMemberToChatGroup, getActiveUsers } from '../../../actions/notification-chatAction';
import './styleListUser.scss';

export const ListActiveUsers = ({setOpenModalAdd}) => {

    const dispatch = useDispatch();
    const users = useSelector(state => state.notificationChat.userActive);
    const chatId = useSelector(state => state.notificationChat.setChatGroupSelected.chatId);
    

    useEffect(() => {
        dispatch(getActiveUsers(0, 10))

        return () => {

        }
    }, [])



    const TypeRol = (roleID) => {
        switch (roleID) {
            case 1:
                return 'Vendedor';
                break;
            case 2:
                return 'Medico';
                break;
            case 3:
                return 'Totvs';
                break;
            case 4:
                return 'Administrador';
                break;
            case 5:
                return 'ATQ';
                break;
            case 6:
                return 'Supervisor de zona';
                break;
            case 7:
                return 'Distribuidor';
                break;
            case 8:
                return 'Licitador';
                break;
            case 9:
                return 'Director';
                break;
            case 10:
                return 'Departamento Facturacion';
                break;
            case 11:
                return 'Administrador ATQ';
                break;
            case 12:
                return 'Mensajero';
                break;
            default:
        }
    }

    const addMember = (role) => {
        dispatch(addMemberToChatGroup(chatId,role))
        setOpenModalAdd(null)

    }


    return (
        <div className='container-list-add-member-chat-modal'>
            {users &&
                users.length > 0 &&
                users.map((item) => (

                    <div className='card-modal-chat-add-members' onClick={() => addMember(item.userID)}>

                        <div className='name-add-memebers-list-modal-chat'>
                            <p className='title-style-add-members-modal'>{(item.firstName && item.firstName) + ' ' +
                                (item.secondName && item.secondName) + ' ' + (item.firstLastName && item.firstLastName) + ' ' +
                                ((item.secondLastName && item.secondLastName))}</p>
                        </div>

                        <div className='clasification-add-memebers-chat-modal'>
                            <p className='title-clasification-add-members-modal'>{TypeRol(item.roleID)}</p>
                        </div>

                    </div>
                ))
            }
        </div>
    )
}
