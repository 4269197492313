
import React from 'react'
import { useSelector } from 'react-redux';
import './styleFechaCierre.scss';
export const FechaCierreComponent = ({type,date,setDate}) => {
    const user = useSelector(state => state.user.user.identity);
    const handleDate = (value) => {
        setDate(value);
    }
    return (
        <section className='container-component-comments-FechaCierre'>
            <div className='header-commentsFechaCierre'>
                <div className='header-titleFechaCierre'>
                    <p className='title-componentFechaCierre'>FECHA DE CIERRE</p>
                </div>
            </div>

            <div className='body-FechaCierre'>

                <div className='container-FechaCierre'>

                    <section className='container-text-FechaCierre'>
                    <p className='text_FechaCierre'>Fecha</p>
                    </section>

                    <section className='selects-FechaCierre'>
                    <input className="calendar_calidad" type="date" value={date} disabled={(type !== 'seguimiento') ?true :user.roleID === 9 ?true  :false} onChange={e => handleDate(e.target.value)} />
                    </section>

                </div>
            </div>
        </section>
    )
}
