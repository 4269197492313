import React from 'react';
import PropTypes from 'prop-types';
import { StoreHouseAssignSystemTableFormModal } from './StoreHouseAssignSystemTableFormModal';
import CustomModal from '../../shared/Modal';



export const StoreHouseAssignSystemTableModal = (props) => {


    const modalBody = () => {
        return (
            <StoreHouseAssignSystemTableFormModal
                toggle={props.toggle} itemToAddLocation={props.itemToAddLocation} itemToChangeState={props.itemToChangeState} result={props.result} setResult={props.setResult}/>
        );
    };

    return (
        <CustomModal
                isOpen={props.isOpen}
                toggle={props.toggle} 
                title="ELEGIR ORIGEN"
                body={modalBody()}
        />
    );
}

StoreHouseAssignSystemTableModal.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.any,
    itemToAddLocation: PropTypes.any,
    itemToChangeState: PropTypes.any,
};