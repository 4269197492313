import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import black_arrow from '../../../assets/img/126PX/black_arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAppoimentSpecifAtq, getAppoimentsWithSpecificATQSuccess, setSurgeryChoosed } from '../../../actions/atqAdministradorAction';
import { formatDateToPresent, formatHourToPresent } from '../../../hooks/useFormatDateInitialAndFinalDay';
import { getAppointmentsStoreHouseSuccessAtq, searchAppointmentsStoreHouse } from '../../../actions/storeHouseAction';

export const ATQ_Transfer_CX_List_Component = ({ activeSwitch }) => {
    const history = useHistory();
    const roleId = useSelector(state => state.user.user.identity)
    const AppoimentListSpecific = useSelector(state => state.atqAdministrador.AppoimentListSpecific)
    const [currentPage, setPage] = useState(0);
    const [list, setList] = useState(null);
    const distpatch = useDispatch();
    const render = useRef(0);
    const searchBy = 'BY_ID';
    const handleNivagate = (item) => {
        // if(activeSwitch === "INACTIVE"){
        //     history.replace('/ATQAdministrator_Materials_History/Material_Info');
        // }
        if (activeSwitch === "PENDIENTES") {
            distpatch(setSurgeryChoosed(item));
            distpatch(searchAppointmentsStoreHouse(searchBy, item.surgeryId))
            history.replace(`/ATQ_Inicio/Transfer_CX_List/transferir`);
        }
    };
    useEffect(() => {
        return () => {
            distpatch(getAppoimentsWithSpecificATQSuccess(null));
            distpatch(getAppointmentsStoreHouseSuccessAtq(null));
        }
    }, [])

    //DE AQUI EN ADELANTE ES LA LOGICA PARA CUANDO CAMBIA DE BOTON
    useEffect(() => {
        if (render.current > 0) {
            setList(null);
            setPage(0);
            distpatch(getAppoimentsWithSpecificATQSuccess([]));

            if (currentPage === 0) {
                if (roleId.roleID === 5) {
                    if (activeSwitch === "PENDIENTES") {
                        distpatch(getAppoimentSpecifAtq(roleId.userID, 'REQUESTED'))
                    }
                    if (activeSwitch === "RECHAZADAS") {
                        distpatch(getAppoimentSpecifAtq(roleId.userID, 'REJECTED'))
                    }
                    if (activeSwitch === "ACEPTADAS") {
                        distpatch(getAppoimentSpecifAtq(roleId.userID, 'ACCEPTED'))
                    }
                }
            }
        } else {
            setList(null);
            render.current = 1;
        }
    }, [activeSwitch])

    //-------------------------------------------------------

    useEffect(() => {

        if (roleId.roleID === 5) {
            if (activeSwitch === "PENDIENTES") {
                distpatch(getAppoimentSpecifAtq(roleId.userID, 'REQUESTED'))
            }
            if (activeSwitch === "RECHAZADAS") {
                distpatch(getAppoimentSpecifAtq(roleId.userID, 'REJECTED'))
            }
            if (activeSwitch === "ACEPTADAS") {
                distpatch(getAppoimentSpecifAtq(roleId.userID, 'ACCEPTED'))
            }
        }
    }, [])


    //ESTE ES PARA USAR EL LIST 
    useEffect(() => {
        /* console.warn(AppoimentListSpecific) */

        /* console.warn(AppoimentListSpecific); */
        if (list && list.length > 0 && AppoimentListSpecific.length > 0) {
            setList([...list, ...AppoimentListSpecific])
        } else if (AppoimentListSpecific && AppoimentListSpecific.length > 0) {
            setList([...AppoimentListSpecific])
        }
    }, [AppoimentListSpecific])

    //scroll
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if ((scrollHeight - Math.round(scrollTop) - clientHeight) <= 1) {
            setPage(prev => prev + 1)
        }

    }

    return (
        <div className="preconter_list_cx_transfer" onScroll={handleScroll}>
            {
                list &&
                list !== null &&
                list.map((item, i) => (
                    <div className="content_info_cx_transfer" key={item.surgeryId} onClick={() => handleNivagate(item)}>
                        {/* {console.error(item.atqOrigin)} */}
                        <div className="text_info_cx">
                            <span className="info_date_numberPqx_cx_transfer">
                                {'CX#' + item.surgeryId + ' - '}
                                {item.requestedAt && (formatDateToPresent(item.requestedAt) + ' - ')}
                                {'SOLICITA:' + ' ' + (item.requestedByUser.firstName && item.requestedByUser.firstName) + ' ' +
                                    (item.requestedByUser.secondName && item.requestedByUser.secondName) + ' ' +
                                    (item.requestedByUser.firstLastName && item.requestedByUser.firstLastName) + ' ' +
                                    (item.requestedByUser.secondLastName && item.requestedByUser.secondLastName)}
                            </span>
                            <span className="info_date_numberPqx_cx_transfer">
                                <p>{'ATQ A CAMBIAR' + ' ' + (item.atqOrigin.firstName && item.atqOrigin.firstName) + ' ' +
                                    (item.atqOrigin.secondName && item.atqOrigin.secondName) + ' ' +
                                    (item.atqOrigin.firstLastName && item.atqOrigin.firstLastName) + ' ' +
                                    (item.atqOrigin.secondLastName && item.atqOrigin.secondLastName) + ' - '}

                                    {activeSwitch === 'ACEPTADAS' &&
                                        ' ' + 'ACEPTADA ' + ' - ' +
                                        'ATQ NUEVO' + ' ' + (item.atqTarget.firstName && item.atqTarget.firstName) + ' ' +
                                        (item.atqTarget.secondName && item.atqTarget.secondName) + ' ' +
                                        (item.atqTarget.firstLastName && item.atqTarget.firstLastName) + ' ' +
                                        (item.atqTarget.secondLastName && item.atqTarget.secondLastName)}


                                    {activeSwitch === 'PENDIENTES' &&
                                        ' ' + 'PENDIENTE ' + ' - ' +
                                        'ATQ NUEVO' + ' ' + (item.atqTarget.firstName && item.atqTarget.firstName) + ' ' +
                                        (item.atqTarget.secondName && item.atqTarget.secondName) + ' ' +
                                        (item.atqTarget.firstLastName && item.atqTarget.firstLastName) + ' ' +
                                        (item.atqTarget.secondLastName && item.atqTarget.secondLastName)}


                                    {activeSwitch === 'RECHAZADAS' &&
                                        ' ' + 'RECHAZADA ' + ' - ' +
                                        'ATQ NUEVO' + ' ' + (item.atqTarget.firstName && item.atqTarget.firstName) + ' ' +
                                        (item.atqTarget.secondName && item.atqTarget.secondName) + ' ' +
                                        (item.atqTarget.firstLastName && item.atqTarget.firstLastName) + ' ' +
                                        (item.atqTarget.secondLastName && item.atqTarget.secondLastName)}

                                </p>
                            </span>
                        </div>
                        {
                            activeSwitch === 'PENDIENTES' &&
                            <img src={black_arrow} alt="arrow" className={'storeHouseStock_elements_img_arrow'} />
                        }

                    </div>
                ))
            }
        </div>
    )
}


ATQ_Transfer_CX_List_Component.propTypes = {
    activeSwitch: PropTypes.any,
}
