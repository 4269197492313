import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import './DoctorTabChatBarLeft.scss';
import SearchInput from '../../../../../../../components/shared/Atoms/SearchInput';
import { getUsersChat, setUserChatSelected } from '../../../../../../../actions/allUsersAction';


const DoctorTabChatBarLeft = () => {
    const dispatch = useDispatch();
    const [rols, setRols] = useState(null)
    const [chatUsers, setChatUsers] = useState(null)
    const data = useSelector(state => state.allUsers);
    const members = useSelector(state => state.allUsers.getChatMembers)
    const [selectedUserTypeList, setSelectedUserTypeList] = useState(null);
    const [selectedTabId, setSelectedTabId] = useState(null);

    useEffect(() => {
        if (data.rols) {
            setRols(data.rols)
        }

        if (data.usersChat) {
            setChatUsers(data.usersChat)
        }
    }, [data])


    const selectUserToGetInfo = (item) => {
        setSelectedUserTypeList(item)
        dispatch(getUsersChat(item.id));
    }

    const selectSelectedUserId = (item) => {
        setSelectedTabId(item.id)
        dispatch(setUserChatSelected(item.id));
    }
    // const renderUserTypeList = () => {
    //     return (
    //         <ul className={'doctor-tab-chat-user-type-ul'}>
    //             {
    //                 rols &&
    //                 rols.map((item,index) => (
    //                     <li key={item.id} className={'doctor-tab-chat-user-type-li'}>
    //                         <button 
    //                             className={` ${selectedUserTypeList && selectedUserTypeList.id === item.id ? ' active ':''} `} 
    //                             onClick={() => selectUserToGetInfo(item)}
    //                         >
    //                             {userTypes[item.name]}
    //                         </button>
    //                     </li>
    //                 ))
    //             }
    //         </ul>
    //     );
    // };


    const TypeRol = (roleID) => {
        switch (roleID) {
            case 1:
                return 'Vendedor';
            case 2:
                return 'Medico';
            case 3:
                return 'Totvs';
            case 4:
                return 'Administrador';
            case 5:
                return 'ATQ';
            case 6:
                return 'Supervisor de zona';
            case 7:
                return 'Distribuidor';
            case 8:
                return 'Licitador';
            case 9:
                return 'Director';
            case 10:
                return 'Departamento Facturacion';
            case 11:
                return 'Administrador ATQ';
            case 12:
                return 'Mensajero';
            case 13:
                return 'Almacenista'
            case 15:
                return 'Coordinador de Operaciones';

            default:
                return 'Sin asignacion'
        }
    }

    // const renderProfileCard = (item, index) => {
    //     console.error('ee', item)
    //     return (
    //         <div onClick={() => selectSelectedUserId(item)}>
    //             <ListMembers
    //                 item={item}
    //             />
    //         </div>
    //     );
    // };

    return (
        <div className='container-left-tabChat'>
            <p className='title-left-container'>Miembros del grupo</p>
            {/* {
                renderUserTypeList()
            } */}
            <div className={"search_content_base"}>
                {/* <SearchInput /> */}
            </div>
            <div className={'profile-list'}>
                {
                    <div className='sesions-container-list-member-chat-modal'>
                        {members &&
                            members.length > 0 ?
                            members.map((i) => (
                                <div className='sesions-card-modal-chat'>

                                    <div className='sesions-name-memebers-list-modal-chat'>
                                        <p className='sesions-title-style-members-modal'>{(i.user && i.user.firstName && i.user.firstName) + ' ' +
                                            (i.user.secondName && i.user.secondName) + ' ' + (i.user.firstLastName && i.user.firstLastName) + ' ' +
                                            ((i.user.secondLastName && i.user.secondLastName))}</p>
                                    </div>

                                    <div className='sesions-clasification-memebers-chat-modal'>
                                        <p className='sesions-title-clasification-members-modal'>{TypeRol(i.user.roleID)}</p>
                                    </div>

                                </div>
                            ))

                            :
                            <p className='txt-member-sinasignar'>Sin grupo seleccionado</p>
                        }
                    </div>
                }
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        ...state,
    };
}


export default withRouter(connect(mapStateToProps)(DoctorTabChatBarLeft));
